import {
  corpAdmin,
  corpTechAdmin,
  corpTechUser,
  custAdmin,
  custUser,
  personalUser,
  spAdmin,
  spTechAdmin,
  spTechUser,
  techAdmin,
} from 'consts';

export const checkIsMultipleSettings = (role: string) =>
  [spAdmin, spTechAdmin, techAdmin, custAdmin, corpAdmin, corpTechAdmin].includes(role);
export const checkIsHideZeroTrust = (role: string) =>
  [personalUser, spTechUser, custUser, corpTechUser, techAdmin].includes(role);
export const checkIsDropdownRestricted = (role: string) => [corpAdmin, corpTechAdmin, custAdmin].includes(role);
export const checkIsCustomDomain = (domainCurrent: string) => domainCurrent !== 'My Settings';
export const checkIsShowCustDomains = (role: string) => [spAdmin, spTechAdmin, techAdmin].includes(role);
