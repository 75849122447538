export const chartConfig = (
  successfulLoginsStats: { x: Date; y: number }[],
  failedLoginsStats: { x: Date; y: number }[],
) => ({
  datasets: [
    {
      label: 'Failed logins',
      type: 'line',
      data: [...failedLoginsStats],
      fill: false,
      tension: 0,
      pointRadius: 5,
      pointHoverRadius: 7,
      borderColor: '#F3657B',
      backgroundColor: '#F3657B',
      pointBordessrColor: '#F3657B',
      pointBackgroundColor: '#F3657B',
      pointHoverBackgroundColor: '#f1526b',
      pointHoverBorderColor: '#f1526b',
    },
    {
      label: 'Successful logins',
      type: 'line',
      data: [...successfulLoginsStats],
      fill: false,
      tension: 0,
      pointRadius: 5,
      pointHoverRadius: 7,
      borderColor: '#7E74F9',
      backgroundColor: '#7E74F9',
      pointBordessrColor: '#7E74F9',
      pointBackgroundColor: '#7E74F9',
      pointHoverBackgroundColor: '##6c61f7',
      pointHoverBorderColor: '##6c61f7',
    },
  ],
});

export const chartOptions = (successfulLoginsStats: { x: Date; y: number }[]) => {
  const labels = [];

  for (const stat of successfulLoginsStats) {
    const date = new Date(Date.parse(stat.x.toString()));
    const monthName = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
    labels.push(`${monthName} ${date.getDate()}`);
  }

  return {
    responsive: true,
    labels,
    elements: {
      line: { fill: false },
    },
    scales: {
      x: {
        display: true,
        grid: { borderDash: [8, 4] },
        labels,
      },
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        grid: { borderDash: [8, 4] },
        labels: { show: true },
        min: 0,
        ticks: {
          callback: (value: number) => (value % 1 === 0 ? value : null),
        },
      },
    },
    tooltips: { caretPadding: 10 },
  };
};
