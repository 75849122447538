export const daysBetweenDates = (date1: string | Date | null | undefined) => {
  if (!date1) return 0;
  const d1 = new Date(date1);
  const now = new Date();
  const d2 = new Date(
    Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds(),
      now.getUTCMilliseconds(),
    ),
  );
  const timeDiff = d1.getTime() - d2.getTime();
  const daysDifference = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  return daysDifference;
};
