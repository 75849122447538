import { Redirect, Route, RouteProps } from 'react-router-dom';
import Layout from 'components/Layout/Layout';
import { mustSetupMfa, mustSetupPhone } from 'helpers';
import { useAppSelector } from 'store';

interface Props extends RouteProps {
  component: React.ComponentType<any>;
}

const PrivateRoute = ({ component: Component, ...rest }: Props) => {
  const { isAuth, user } = useAppSelector((state) => state.user);

  if (!isAuth) {
    return <Route {...rest} render={() => <Redirect to="/login" />} />;
  }

  if (mustSetupMfa(user)) {
    return <Route {...rest} render={() => <Redirect to="/setup-mfa" />} />;
  }

  if (mustSetupPhone(user)) {
    return <Route {...rest} render={() => <Redirect to="/setup-phone" />} />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

export default PrivateRoute;
