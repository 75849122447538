import { useLocation } from 'react-router-dom';
import Branding from '../Branding';
import { IBrandingDomain } from 'interfaces';

const BrandingCustomer = () => {
  const location = useLocation<{ domain: IBrandingDomain }>();
  const domain = location.state?.domain || {};

  return <Branding domainId={domain.domainId} domainName={domain.dnsName} isCustomer />;
};

export default BrandingCustomer;
