import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { useState, useMemo, useEffect } from 'react';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import { notify } from 'utils';
import { Loader } from 'components';
import EnterPhoneNumber from './components/EnterPhoneNumber/EnterPhoneNumber';
import VerificationCode from './components/VerificationCode/VerificationCode';
import { useAppDispatch, useAppSelector } from 'store';
import { getCountries } from 'store/slices/auth/common';
import styles from './SetupViaSms.module.scss';

const SetupViaSms = () => {
  const { pathname } = useLocation();
  const { path, url } = useRouteMatch();

  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.commonAuth.getCountriesRequest);
  const { countries } = useAppSelector((state) => state.commonAuth);

  const [currentCountry, setCurrentCountry] = useState('US');
  const [phoneNumber, setPhoneNumber] = useState('');

  const getCountriesList = async () => {
    try {
      await dispatch(getCountries()).unwrap();
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  useEffect(() => {
    getCountriesList();
  }, []);

  const dialCode = useMemo(() => countries[currentCountry]?.dial_code, [countries, currentCountry]);

  return (
    <div className={styles.setupMfa}>
      <LoginHeader title="Verify Your Phone" stepCount="2" currentStep={pathname === '/setup-mfa/sms' ? '1' : '2'} />
      {isLoading ? (
        <Loader id="loading-setup-mfa-phone" style={{ top: 'calc(50% + 1.5em)' }} />
      ) : (
        <Switch>
          <Route path={path} exact>
            <EnterPhoneNumber
              countriesList={countries}
              dialCode={dialCode}
              currentCountry={currentCountry}
              setCurrentCountry={setCurrentCountry}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
            />
          </Route>
          <Route path={`${url}/validation-code`}>
            <VerificationCode phoneNumber={phoneNumber} dialCode={dialCode} />
          </Route>
        </Switch>
      )}
    </div>
  );
};

export default SetupViaSms;
