import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import { WorkspaceIcon } from 'assets/img';
import Link from 'components/Link/Link';
import { useAppSelector } from 'store';
import styles from '../NavbarItem.module.scss';

interface Props {
  onNotCompanyClick: () => void;
  blockLeftMenu?: boolean;
}

const WorkspaceItems = ({ onNotCompanyClick, blockLeftMenu }: Props) => {
  const location = useLocation<Location>();
  const { branding } = useAppSelector((state) => state.user);
  const { useInvertedColorScheme: isInvertedColors } = branding || {};
  const color = isInvertedColors ? '#000' : '#fff';

  return (
    <li
      className={cn(styles.item, {
        [styles.active]: location.pathname === '/workspace',
        [styles.inverted]: isInvertedColors,
        [styles.branding]: branding && !isInvertedColors,
        [styles.itemDisabled]: blockLeftMenu,
      })}
      data-tip="Desktops, Apps and Resources"
      data-iscapture="true"
      onClick={onNotCompanyClick}>
      <span style={branding?.backGroundColor ? { background: color } : {}} />
      <Link to="/workspace" id="my-workspace" className={styles.itemLink}>
        <WorkspaceIcon /> My Workspace
      </Link>
    </li>
  );
};

export default WorkspaceItems;
