import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { Modal, Loader, SearchInput, StatusMessage } from 'components';
import { notify, truncateString } from 'utils';
import AssignmentsList from './components/AssignmentsList';
import { IModalData } from 'pages/ResourceAssignments/ResourceAssignments';
import { IAssigmentsList, getAssigmentsList, updateAssigmentsList } from 'store/slices/resourceAssignments';
import styles from './ManageAssignmentsModal.module.scss';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  modalData: IModalData;
}

const ManageAssignmentsModal = ({ isOpen, onRequestClose, modalData }: Props) => {
  const dispatch = useAppDispatch();
  const {
    data: assignmentsInit,
    isLoading,
    isUninitialized,
  } = useAppSelector((state) => state.resourceAssignments.getAssigmentsListRequest);

  const { isLoading: isUpdating } = useAppSelector((state) => state.resourceAssignments.updateAssigmentsListRequest);

  const [searchValue, setSearchValue] = useState<string>('');
  const [assignments, setAssignments] = useState<IAssigmentsList[]>([]);

  const fetchData = async () => {
    try {
      const data = await dispatch(getAssigmentsList(modalData)).unwrap();
      setAssignments(data);
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onUpdateAssignments = async () => {
    try {
      await dispatch(updateAssigmentsList({ data: modalData, assignments }));
      notify.success(`Assignments for ${truncateString(modalData.resourceName, false)} have been updated`);
    } catch (err: any) {
      setAssignments(assignmentsInit!.map((i) => ({ ...i })));
      notify.error(err.message);
    } finally {
      onRequestClose();
    }
  };

  const onToggleAll = (type: 'select' | 'unselect') => {
    const assignmentsNew = assignmentsInit?.map((i) => ({ ...i, isAssigned: type === 'select' }));
    if (assignmentsNew) {
      setAssignments(assignmentsNew);
    }
  };

  const onToggleItem = (resourceName: string, isAssigned: boolean) => {
    const assignmentsNew = assignments.map((assignment) =>
      assignment.resourceName === resourceName ? { ...assignment, isAssigned: isAssigned } : assignment,
    );
    setAssignments(assignmentsNew);
  };

  const isDisabled = JSON.stringify(assignmentsInit) === JSON.stringify(assignments);

  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Manage Assignments"
      contentSubtitle={
        <>
          for <b>{modalData.resourceName}</b>
        </>
      }
      onRequestClose={onRequestClose}
      actionLoading={isUpdating}
      actionDisabled={isDisabled}
      actionFunc={onUpdateAssignments}
      actionTitle="Update">
      <div className={styles.assignmentsWrap}>
        {isLoading || isUninitialized ? (
          <Loader id="loading-manage-assignments" />
        ) : assignmentsInit?.length ? (
          <>
            <SearchInput
              id="dm-modal-search"
              fullWidth
              searchBy={`${modalData.viewBy === 'Users' ? modalData.assignType : 'User'} Name`}
              isNotDebounce
              className={styles.assignmentsSearch}
              onChange={(value) => setSearchValue(value)}
            />
            <AssignmentsList
              assignments={assignments}
              assignmentsInit={assignmentsInit}
              searchValue={searchValue}
              data={modalData}
              onToggleAll={onToggleAll}
              onToggleItem={onToggleItem}
            />
          </>
        ) : (
          <StatusMessage>No resources reported in</StatusMessage>
        )}
      </div>
    </Modal>
  );
};

export default ManageAssignmentsModal;
