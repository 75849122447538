import { useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import VerificationCode from './pages/VerificationCode';
import ValidatePhone from './pages/ValidatePhone';

const ResetViaSMS = () => {
  const { path, url } = useRouteMatch();

  const [phoneDigits, setPhoneDigits] = useState('');

  return (
    <Switch>
      <Route path={path} exact>
        <ValidatePhone phoneDigits={phoneDigits} setPhoneDigits={setPhoneDigits} />
      </Route>
      <Route path={`${url}/verification-code`}>
        <VerificationCode phoneDigits={phoneDigits} />
      </Route>
    </Switch>
  );
};

export default ResetViaSMS;
