import { useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import cn from 'classnames';
import { notify } from 'utils';
import { Loader } from 'components';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import ScanQRCode from './components/ScanQRCode/ScanQRCode';
import VerifyPhoneNumber from './components/VerifyPhoneNumber/VerifyPhoneNumber';
import VerificationCode from './components/VerificationCode/VerificationCode';
import { goBackHandler } from './utils/goBackHandler/goBackHandler';
import { currentStepHandler } from './utils/currentStepHandler/currentStepHandler';
import { useAppDispatch, useAppSelector } from 'store';
import { getCountries } from 'store/slices/auth/common';
import styles from './SetupViaApp.module.scss';

const SetupViaApp = () => {
  const { user } = useAppSelector((state) => state.user);
  const { pathname } = useLocation();
  const history = useHistory();
  const { path, url } = useRouteMatch();

  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { countries } = useAppSelector((state) => state.commonAuth);

  const [currentCountry, setCurrentCountry] = useState<string>('US');
  const [phoneNumber, setPhoneNumber] = useState('');

  const getCountriesList = async () => {
    try {
      await dispatch(getCountries()).unwrap();
      setIsLoading(false);
    } catch (err: any) {
      notify.error(err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCountriesList();
  }, []);

  const dialCode = useMemo(() => countries[currentCountry]?.dial_code || '', [countries, currentCountry]);

  const isSetupMfaWithoutPhone = useMemo(
    () => user.isMfaActive && !user.isPhoneMfaActive && !user.mustSetupMfa,
    [user.isMfaActive, user.isPhoneMfaActive, user.mustSetupMfa],
  );

  const currentStep = currentStepHandler(pathname, path, url, isSetupMfaWithoutPhone);
  const stepTitle = pathname === path ? 'Scan QR Code' : 'Verify Your Phone';
  const goBack = () => goBackHandler(currentStep, history, isSetupMfaWithoutPhone);

  return (
    <div className={cn(styles.setupMfa, { [styles.short]: currentStep !== '1' || isSetupMfaWithoutPhone })}>
      <LoginHeader
        title={stepTitle}
        hideSteps={user.isPhoneMfaActive && user.mustSetupMfa}
        currentStep={currentStep}
        stepCount={isSetupMfaWithoutPhone ? '2' : '3'}
        back={goBack}
      />
      {isLoading ? (
        <Loader id="loading-setup-mfa" style={{ top: 'calc(50% + 1.5em)' }} />
      ) : (
        <Switch>
          <Route path={path} exact>
            <ScanQRCode />
          </Route>
          <Route path={`${url}/verify-phone`}>
            <VerifyPhoneNumber
              currentCountry={currentCountry}
              setCurrentCountry={setCurrentCountry}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              dialCode={dialCode}
            />
          </Route>
          <Route path={`${url}/verification-code`}>
            <VerificationCode dialCode={dialCode} phoneNumber={phoneNumber} />
          </Route>
        </Switch>
      )}
    </div>
  );
};

export default SetupViaApp;
