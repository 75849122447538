import { useState } from 'react';
import { ValidateDomainModal } from 'components';
import { getDomainADStatus, getDomainStatus } from 'helpers';
import ValidateDomain from './components/ValidateDomain.js/ValidateDomain';
import SetupDomain from './components/SetupDomain/SetupDomain';
import styles from './Onboarding.module.scss';
import { useAppDispatch, useAppSelector } from 'store';
import { validateUser } from 'store/slices/userSlice';
import { notify } from 'utils';
import useModal from 'hooks/useModal';
import { IValidateModalDataBase, IValidateType } from 'interfaces';

export type SetupType = 'on-premise' | 'azure' | '';

export type EmailValidationData = {
  type: IValidateType;
  link: string;
  domainId: string;
  domainName: string;
  upn: string;
};

export type TxtValidationData = {
  type: IValidateType;
  domainId: string;
  domainName: string;
};

export type ValidateDomainResponse = {
  data: {
    activationCode: string;
    isMsp: string;
    sentryLink: string;
    secureConnectLink: string;
  };
};

const Onboarding = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const [setupType, setSetupType] = useState<SetupType>('');

  const { isOpen, openModal, closeModal, modalData } = useModal<IValidateModalDataBase>();

  const { domainWasAutovalidated } = user;

  const { isDomainAzure } = getDomainADStatus(user);
  const { isDomainNotValidated, isDomainNotActivated } = getDomainStatus(user);

  const emailValidationData: EmailValidationData = {
    type: 'emailValidation',
    link: '/api/Domains/CreateNotValidSpDomain?activationType=1',
    domainId: user.domainId,
    domainName: user.dnsDomainName,
    upn: user.email,
  };
  const txtValidationData: TxtValidationData = {
    type: 'txtValidation',
    domainId: user.domainId,
    domainName: user.dnsDomainName,
  };

  const onSetupTypeChange = (type: SetupType) => {
    if (type === setupType) {
      setSetupType('');
    } else {
      setSetupType(type);
    }
  };

  const onUpdateModal = (resp: ValidateDomainResponse, domainId: string) => {
    const domainName = user.email.split('@')[1];
    const data = {
      domainId: domainId,
      domainActivationCode: resp.data.activationCode,
      spApplicationWasApproved: resp.data.isMsp,
      downloadSentryLink: resp.data.sentryLink,
      downloadSecureConnectLink: resp.data.secureConnectLink,
    };
    dispatch(validateUser(data));
    notify.success(`Domain ${domainName} has been validated`);
  };

  return (
    <div className={styles.onboarding}>
      <div className={styles.onboardingSteps}>
        Completed: {domainWasAutovalidated ? 0 : user.domainStatus}/{domainWasAutovalidated ? 1 : 2}
      </div>
      <h3 className={styles.onboardingTitle}>Let's Setup TruGrid</h3>
      {!domainWasAutovalidated && (
        <ValidateDomain
          isDomainNotValidated={isDomainNotValidated}
          isDomainNotActivated={isDomainNotActivated}
          openModal={openModal}
          emailValidationData={emailValidationData}
          txtValidationData={txtValidationData}
        />
      )}

      <SetupDomain
        user={user}
        isDomainNotActivated={isDomainNotActivated}
        domainWasAutovalidated={domainWasAutovalidated}
        setupType={setupType}
        onSetupTypeChange={onSetupTypeChange}
        isDomainAzure={isDomainAzure}
      />

      {isOpen && modalData && (
        <ValidateDomainModal isOpen={isOpen} data={modalData} onUpdateModal={onUpdateModal} onClose={closeModal} />
      )}
    </div>
  );
};

export default Onboarding;
