import { useRef, useState } from 'react';
import cn from 'classnames';
import { Button, SearchInput } from 'components';
import { notify } from 'utils';
import { useObserver } from 'hooks';
import { IRoleUser, getRolesMgmtData } from 'store/slices/rolesMgmt';
import { replaceSpecChar } from 'helpers';
import { useAppDispatch, useAppSelector } from 'store';
import styles from './RolesHeader.module.scss';

interface Props {
  domainId: string;
  selectedUsers: IRoleUser[];
  unselectAllUsers: () => void;
  onUpdateUserRoles: () => void;
}

const RolesHeader = ({ domainId, selectedUsers, unselectAllUsers, onUpdateUserRoles }: Props) => {
  const dispatch = useAppDispatch();

  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false);

  const { isLoading } = useAppSelector((state) => state.rolesMgmt.updateUserRoleAssignmentsRequest);
  const total = useAppSelector((state) => state.rolesMgmt.rolesMgmtData?.pageMeta.TotalCount);

  const counterRef = useRef<HTMLDivElement | null>(null);
  const isSticky: boolean = useObserver(counterRef, !!selectedUsers.length, { threshold: 1 });

  const renderBtnText = (): 'Remove Admin' | 'Make Admin' | 'Switch Roles' => {
    const statuses = selectedUsers.reduce(
      (prev, cur) => {
        cur.isAssigned ? prev.true++ : prev.false++;
        return prev;
      },
      { true: 0, false: 0 },
    );

    if (statuses.true && !statuses.false) {
      return 'Remove Admin';
    }
    if (!statuses.true && statuses.false) {
      return 'Make Admin';
    }
    return 'Switch Roles';
  };

  const onSearch = async (value: string) => {
    try {
      setIsSearchLoading(true);
      const valueNew = replaceSpecChar(value);
      const queryConfig = {
        pageNumber: 1,
        searchQuery: valueNew,
      };
      await dispatch(getRolesMgmtData({ domainId, query: queryConfig, _background: true })).unwrap();
      setIsSearchLoading(false);
    } catch (err: any) {
      notify.error(err.message);
      setIsSearchLoading(false);
    }
  };

  return (
    <div
      className={cn(styles.rolesHeader, { [styles.active]: selectedUsers.length, [styles.sticked]: isSticky })}
      ref={counterRef}>
      <div className={cn(styles.rolesHeaderActions, { [styles.hide]: selectedUsers.length < 1 })}>
        <div className={styles.rolesHeaderActionsCount}>
          {selectedUsers.length}/{total} Selected
        </div>
        <Button
          type="button"
          id="unselect-all"
          size="40"
          variant="secondary"
          className={styles.rolesHeaderActionsBtnSecondary}
          onClick={unselectAllUsers}>
          Unselect All
        </Button>
        <Button
          type="button"
          id="action-button"
          size="40"
          variant="primary"
          className={styles.rolesHeaderActionsBtnPrimary}
          isDisabled={isLoading}
          onClick={onUpdateUserRoles}>
          {renderBtnText()}
        </Button>
      </div>
      <div className={cn(styles.rolesHeaderAllSearch, { [styles.hide]: selectedUsers.length > 0 })}>
        <span className={styles.rolesHeaderAllUsers}>All Users</span>
        <b>({total})</b>
        <SearchInput
          id="rm-search"
          searchBy="User Name"
          isLoading={isSearchLoading}
          onChange={(value) => onSearch(value)}
          className={styles.rolesHeaderSearch}
        />
      </div>
    </div>
  );
};

export default RolesHeader;
