import { checkIfNumberInRange } from 'helpers';
import { IForgotPassData } from 'store/slices/securityMgmt/forgotPass';
import { FormErrors } from '../ForgotPasswordMgmt';

export const checkIfSomeChanged = (forgotPassData: IForgotPassData | null, passData: IForgotPassData): boolean => {
  if (!forgotPassData) return false;
  const keysToCheck = ['domainId', 'lockoutDuration', 'retryTimePeriod', 'resetAttemptsCount'];
  return keysToCheck.every(
    (key) => forgotPassData[key as keyof IForgotPassData] === passData[key as keyof IForgotPassData],
  );
};

export const checkIsFormValid = (
  formErrors: FormErrors,
  passData: IForgotPassData,
): {
  errors: FormErrors;
  isValid: boolean;
} => {
  const formErrorsNew = [...formErrors];
  if (!checkIfNumberInRange(passData.resetAttemptsCount, 0, 99)) {
    formErrorsNew[0] = 'You can set from 0 to 99 attempts';
  }
  if (Number(passData.resetAttemptsCount) !== 0) {
    if (!checkIfNumberInRange(Number(passData.retryTimePeriod), 5, 90)) {
      formErrorsNew[1] = 'You can set from 5 to 90 mins';
    }
    if (!checkIfNumberInRange(Number(passData.lockoutDuration), 5, 1440)) {
      formErrorsNew[2] = 'You can set from 5 to 1440 mins (24h)';
    }
  }

  return {
    errors: formErrorsNew,
    isValid: !formErrorsNew.some((i) => i),
  };
};

export const clearError = (formErrors: FormErrors, index: number): FormErrors => {
  const errors = formErrors.map((i, ind) => (index === ind ? '' : i));
  return errors;
};
