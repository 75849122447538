import styles from './MfaNameCell.module.scss';

interface Props {
  displayName: string;
  upn: string;
}

const MfaNameCell = ({ displayName, upn }: Props) => (
  <div className={styles.nameCell}>
    <p className={styles.nameCellValue}>{displayName || 'N/A'}</p>
    <p className={styles.email}>{upn}</p>
  </div>
);

export default MfaNameCell;
