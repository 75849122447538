import { Component, ReactNode } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styles from './ErrorBoundary.module.scss';

interface State {
  hasError: boolean;
}

interface Props extends RouteComponentProps {
  children: ReactNode;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidMount() {
    this.props.history.listen(() => {
      this.setState({ hasError: false });
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.error}>
          <div className={styles.errorWrap}>
            <h1 className={styles.errorTitle}>Oops!</h1>
            <h3 className={styles.errorSubtitle}>Something went wrong</h3>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
