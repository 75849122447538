interface ISupportedBrowsers {
  [key: string]: string;
}

export const supportedBrowsers: ISupportedBrowsers = {
  ie10: 'ie:<=10',
  ie11: 'ie:11',
  edge: 'edge',
  opera: 'opr',
  safari: 'safari',
  chrome: 'chrome',
  chromeIos: 'crios',
  chromeMob: 'crmob',
  webkit: 'applewebkit',
  firefox: 'firefox',
};

export const isWindows = () => (/win/gi).test(window.navigator.platform);

export const isMacOs = () => (/mac/gi).test(window.navigator.platform);

export const getBrowserSupport = () => {
  const result = [];
  const ua = navigator.userAgent;

  if ((/MSIE/gi).test(ua)) {
    result.push(supportedBrowsers.ie10);
    return result;
  }

  if ((/rv:11/gi).test(ua)) {
    result.push(supportedBrowsers.ie11);
    return result;
  }

  if ((/edg(e)?\//gi).test(ua)) {
    result.push(supportedBrowsers.edge);
  }

  if ((/firefox/gi).test(ua)) {
    result.push(supportedBrowsers.firefox);
  }

  if ((/opr/gi).test(ua)) {
    result.push(supportedBrowsers.opera);
  }

  if ((/chrome.*mobile/gi).test(ua)) {
    result.push(supportedBrowsers.chromeMob);
  }

  if ((/crios/gi).test(ua)) {
    result.push(supportedBrowsers.chromeIos);
  }

  if ((/chrome/gi).test(ua)) {
    result.push(supportedBrowsers.chrome);
  }

  if ((/safari/gi).test(ua)) {
    result.push(supportedBrowsers.safari);
  }

  if ((/applewebkit/gi).test(ua)) {
    result.push(supportedBrowsers.webkit);
  }

  return result;
};

export const isBrowserSupported = (val: string) => getBrowserSupport().includes(val);
