import { useEffect, useState } from 'react';
import { Loader, ActivationCode, Alert, Button, ADStatus } from 'components';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { getUserRole, notify } from 'utils';
import { corpTechUser, custUser, spTechUser } from 'consts';
import { TriangleFilledIcon, CloseIcon } from 'assets/img';
import { getDomainADStatus, isSentryDeleted } from 'helpers';
import OnboardingMessage from '../OnboardingMessage/OnboardingMessage';
import { useAppDispatch, useAppSelector } from 'store';
import {
  getCustomerAssignedMachines,
  getDomainSetries,
  getMyDomainApps,
  getMyDomainMachines,
  getPersonalMachines,
  IMyDomainMachines,
} from 'store/slices/workspace';
import WorkspaceMyDomain from './components/WorkspaceMyDomain/WorkspaceMyDomain';
import WorkspaceMyApps from './components/WorkspaceMyApps/WorkspaceMyApps';
import { getWorkspaceDataMap } from 'pages/Workspace/utils/workspaceUtils';
import styles from './WorkspaceTabs.module.scss';
import { IDomainMachine } from 'interfaces';

const WorkspaceTabs = () => {
  const { user } = useAppSelector((state) => state.user);
  const { hasSecureConnects } = useAppSelector((state) => state.workspace);
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [isExceedsLimit, setIsExceedsLimit] = useState(false);

  const { isDomainAzure, isDomainOnPremise, isDomainHybryd } = getDomainADStatus(user);
  const sentryDeleted = isSentryDeleted(user);

  const role = getUserRole(user);
  const { isPersonal, myDomainPage, isShowApps } = getWorkspaceDataMap(user, hasSecureConnects);

  useEffect(() => {
    const fetchSentries = async () => {
      const doNotSendRequest = isPersonal || isDomainAzure || [spTechUser, corpTechUser, custUser].includes(role);
      if (!doNotSendRequest) {
        await dispatch(getDomainSetries(user.domainId)).unwrap();
      }
    };
    fetchSentries();
  }, [dispatch, isPersonal, isDomainAzure, role, user.domainId]);

  useEffect(() => {
    const fetchData = async () => {
      const queryConfig = {
        pageNumber: 1,
        pageSize: 10,
        assigned: 'My',
        orderBy: 'hostname asc',
        searchQuery: '',
      };
      let promises: { name: string; promise: Promise<any> }[] = [];
      if (myDomainPage === 'myDomainCust') {
        promises.push({
          name: 'myDomainCust',
          promise: dispatch(getCustomerAssignedMachines({ query: queryConfig })).unwrap(),
        });
      }
      if (myDomainPage === 'myDomainPersonal') {
        const personalQueryConfig = {
          pageNumber: 1,
          pageSize: 10,
          kind: 'All',
          orderBy: 'hostname asc',
          searchQuery: '',
        };
        promises.push({
          name: 'myDomainPersonal',
          promise: dispatch(getPersonalMachines({ query: personalQueryConfig })).unwrap(),
        });
      }
      if (myDomainPage === 'myDomainWithFilter') {
        const queryConfig = {
          pageNumber: 1,
          pageSize: 10,
          kind: 'All',
          assigned: 'My',
          orderBy: 'hostname asc',
          searchQuery: '',
        };
        promises.push({
          name: 'myDomainWithFilter',
          promise: dispatch(getMyDomainMachines({ query: queryConfig })).unwrap(),
        });
      }
      if (myDomainPage === 'myDomain') {
        const queryConfig = {
          pageNumber: 1,
          pageSize: 10,
          kind: 'Pooled',
          orderBy: 'hostname asc',
          searchQuery: '',
        };
        if (isDomainAzure || ((isDomainHybryd || isDomainOnPremise) && sentryDeleted)) queryConfig.kind = 'All';
        if (user.showOnboarding && hasSecureConnects) queryConfig.kind = 'All';
        promises.push({ name: 'myDomain', promise: dispatch(getMyDomainMachines({ query: queryConfig })).unwrap() });
      }
      if (!(isPersonal || isDomainAzure || !user.isAppPublishingEnabled)) {
        const queryConfig = {
          pageNumber: 1,
          pageSize: 10,
          orderBy: 'appname asc',
          searchQuery: '',
        };
        promises.push({
          name: 'myApps',
          promise: dispatch(getMyDomainApps({ query: queryConfig })).unwrap(),
        });
      }
      if (user.showOnboarding && !hasSecureConnects && !sentryDeleted)
        promises = promises.filter((i) => ![myDomainPage, 'myApps'].includes(i.name));

      const results = await Promise.allSettled(promises.map((item) => item.promise));

      results.forEach((result) => {
        if (result.status === 'fulfilled') {
          const data = result.value as IMyDomainMachines;
          if (
            isPersonal &&
            Array.isArray(data.data) &&
            data.data.some((mashine: IDomainMachine) => mashine.exceedsLimit)
          )
            setIsExceedsLimit(true);
        } else {
          const error = result.reason.response?.data?.message || result.reason.message;
          notify.error(error);
        }
      });

      setIsLoading(false);
    };
    fetchData();
  }, [
    dispatch,
    isDomainAzure,
    isDomainHybryd,
    isDomainOnPremise,
    sentryDeleted,
    isShowApps,
    isPersonal,
    user.isAppPublishingEnabled,
  ]);

  if (user.showOnboarding && user.hasActiveCustomerDomains && !hasSecureConnects) {
    return <OnboardingMessage user={user} />;
  }

  if (isPersonal) {
    return (
      <div className={styles.workspaceContent}>
        {isExceedsLimit && (
          <Alert
            icon={<TriangleFilledIcon />}
            action={
              <Button variant="link" onClick={() => setIsExceedsLimit(false)}>
                <CloseIcon />
              </Button>
            }
            absolute>
            You have more computers than your subscription allows. Please contact help@trugrid.com for more information
          </Alert>
        )}
        <div className={styles.workspaceContentHeader}>
          <h3 className={styles.workspaceContentTitle}>My Computers</h3>
          <ActivationCode
            activationCode={user.domainActivationCode}
            label="Activation Code:"
            copyMessage="Activation code was saved to clipboard"
          />
        </div>
        {isLoading ? (
          <Loader id="loading-workspace-personal-tabs" />
        ) : (
          <WorkspaceMyDomain myDomainPage={myDomainPage} dispatchAction={getPersonalMachines} />
        )}
      </div>
    );
  }

  const dispatchAction = myDomainPage === 'myDomainCust' ? getCustomerAssignedMachines : getMyDomainMachines;

  return (
    <div className={styles.workspaceContent}>
      <div className={styles.workspaceContentHeader}>
        <h3 className={styles.workspaceContentTitle}>My Domain</h3>
        {user.showOnboarding && hasSecureConnects && (
          <ActivationCode
            activationCode={user.domainActivationCode}
            label="Activation Code:"
            copyMessage="Activation code was saved to clipboard"
          />
        )}
        {!!(user.domainOrigin && ['spAdmin', 'corpAdmin', 'spTechAdmin', 'corpTechAdmin'].includes(role)) && (
          <ADStatus link="/company-management/domain-management" domain={user} />
        )}
      </div>
      {isLoading ? (
        <Loader id="loading-workspace-tabs" />
      ) : (
        <Tabs forceRenderTabPanel selectedIndex={tabIndex} onSelect={(index: number) => setTabIndex(index)}>
          <TabList>
            <Tab>
              <span id="all-computers-tab">All Computers</span>
            </Tab>
            {isShowApps && <Tab id="my-apps-tab">My Apps</Tab>}
          </TabList>
          <TabPanel>
            <WorkspaceMyDomain myDomainPage={myDomainPage} dispatchAction={dispatchAction} />
          </TabPanel>
          {isShowApps && (
            <TabPanel>
              <WorkspaceMyApps role={role} />
            </TabPanel>
          )}
        </Tabs>
      )}
    </div>
  );
};

export default WorkspaceTabs;
