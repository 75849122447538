import styles from './AzureAdLoginInfo.module.scss';

const AzureAdLoginInfo = () => (
  <div className={styles.azureLoginInfo}>
    <h4 className={styles.azureLoginInfoTitle}>Stay Signed-In is not currently enabled</h4>
    <p className={styles.azureLoginInfoText}>
      This functionality allows Entra ID Users to stay signed in while using TruGrid Desktop Connector
    </p>
  </div>
);

export default AzureAdLoginInfo;
