import { Button } from 'components';
import SuspendedMessage from '../SuspendedMessage/SuspendedMessage';
import { ArrowLeftIcon } from 'assets/img';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'store';
import styles from './SubscriptionsHeader.module.scss';

interface Props {
  isPersonal: boolean;
  openReactivateModal: () => void;
  role: string;
}

const SubscriptionsHeader = ({ isPersonal, openReactivateModal, role }: Props) => {
  const history = useHistory();

  const { user } = useAppSelector((state) => state.user);
  const { licenses } = useAppSelector((state) => state.subscriptions);

  const goBack = () => history.goBack();

  return (
    <>
      {licenses && !licenses.isSubscriptionActive && !isPersonal && (
        <SuspendedMessage role={role} openReactivateModal={openReactivateModal} />
      )}
      <div className={styles.header}>
        <Button
          variant="link"
          id="subs-go-back"
          className={styles.headerBack}
          icon={<ArrowLeftIcon />}
          onClick={goBack}>
          Back
        </Button>
        <span className={styles.headerDomain}>{user.dnsDomainName && `(${user.dnsDomainName})`}</span>
      </div>
    </>
  );
};

export default SubscriptionsHeader;
