import { Alert, Link } from 'components';
import { AlertWarning } from 'assets/img';
import styles from './NoCustDomainsMessage.module.scss';

const NoCustDomainsMessage = () => (
  <Alert className={styles.alert} icon={<AlertWarning />}>
    Please add and activate&nbsp;
    <Link href="https://www.trugrid.com/docs/addcustomerdomain" external bold>
      On-premises customer domains
    </Link>
  </Alert>
);

export default NoCustDomainsMessage;
