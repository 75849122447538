import cn from 'classnames';
import { Checkbox } from 'components';
import { CheckBoxesChecked, ReportTypes } from '../../Assignments';
import { ILicenseReport } from 'interfaces';
import styles from './ReportColumns.module.scss';

interface Props {
  checkBoxesChecked: CheckBoxesChecked;
  toggleCheckBoxesChecked: (name: string) => void;
  type: ReportTypes;
  isDomainAzure: boolean;
  isLeastOneDomainHasApps: boolean;
  checkedDomains: ILicenseReport[];
}

const ReportColumns = ({
  checkBoxesChecked,
  toggleCheckBoxesChecked,
  type,
  isDomainAzure,
  isLeastOneDomainHasApps,
  checkedDomains,
}: Props) => {
  const disableAppColumn = checkedDomains.every((el) => !el.isAppPublishingEnabled);

  const checkboxes = [
    {
      checked: true,
      label: isDomainAzure ? 'Users' : 'Users or User Groups',
      isDisabled: true,
      onChange: () => {},
      show: true,
    },
    {
      checked: checkBoxesChecked.application,
      label: 'Application',
      isDisabled: type !== ReportTypes.userAssignments || disableAppColumn,
      onChange: () => toggleCheckBoxesChecked('application'),
      show: type !== ReportTypes.machineAssignmnets && isLeastOneDomainHasApps,
    },
    {
      checked: checkBoxesChecked.computersComputerGroups,
      label: isDomainAzure ? 'Computers' : 'Computers or Computer Groups',
      isDisabled: type !== ReportTypes.userAssignments || isDomainAzure || disableAppColumn,
      onChange: () => toggleCheckBoxesChecked('computersComputerGroups'),
      show: type !== ReportTypes.appAssignments,
    },
  ];

  if (type !== ReportTypes.userAssignments || isDomainAzure) {
    checkboxes.reverse();
  }

  return (
    <div className={styles.list}>
      <div className={styles.listColums}>Columns</div>
      <div
        className={cn(styles.listContainer, {
          [styles.row]: type !== ReportTypes.userAssignments || isDomainAzure || !isLeastOneDomainHasApps,
        })}>
        {checkboxes
          .filter(({ show }) => show)
          .map(({ checked, isDisabled, label, onChange }) => (
            <div key={label} className={styles.listContainerItem}>
              <Checkbox
                className={styles.checkBoxWrapperCheckbox}
                checked={checked}
                isDisabled={isDisabled}
                label={label}
                onChange={onChange}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default ReportColumns;
