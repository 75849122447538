import { useState, useRef } from 'react';
import cn from 'classnames';
import { useOutsideClick } from 'hooks';
import { AngleUpIcon, AngleDownIcon, CheckIcon } from 'assets/img';
import { CSSTransition } from 'react-transition-group';
import styles from './DropdownFilterBy.module.scss';

interface Props {
  id: string;
  options: string[];
  onChange: (value: string) => Promise<void>;
  defaultValue: string;
}

const DropdownFilterBy = ({ id, options, onChange, defaultValue }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const nodeRef = useRef<HTMLDivElement>(null);

  const toggleShowDropdown = () => {
    setIsOpen(!isOpen);
  };

  const onSelectHandler = (e: React.MouseEvent<HTMLButtonElement>, value: string) => {
    e.stopPropagation();
    setIsLoading(true);
    onChange(value).then(() => setIsLoading(false));
    toggleShowDropdown();
  };

  useOutsideClick(dropdownRef, () => isOpen && toggleShowDropdown());

  return (
    <div id={id} className={cn(styles.dropdown, { [styles.dropdownDisabled]: isLoading })} ref={dropdownRef}>
      <button
        type="button"
        id="toggle-show-viewby"
        className={cn(styles.dropdownButton, { [styles.dropdownButtonActive]: isOpen })}
        onClick={toggleShowDropdown}>
        <span className={styles.dropdownLabel}>Filter by:</span>
        <span className={styles.dropdownValue}>{defaultValue}</span>
        {isOpen ? (
          <AngleUpIcon />
        ) : isLoading ? (
          <svg className={styles.spinner} viewBox="0 0 50 50">
            <circle className={styles.spinnerPath} cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
          </svg>
        ) : (
          <AngleDownIcon />
        )}
      </button>
      <CSSTransition
        nodeRef={nodeRef}
        in={isOpen}
        timeout={200}
        unmountOnExit
        classNames={{
          enter: styles.dropdownOptionsTransitionEnter,
          enterActive: styles.dropdownOptionsTransitionEnterActive,
          exit: styles.dropdownOptionsTransitionExit,
          exitActive: styles.dropdownOptionsTransitionExitActive,
        }}>
        <div className={styles.dropdownOptions} ref={nodeRef}>
          {options.map((option) => (
            <button
              type="button"
              key={option}
              onClick={(e) => (option !== defaultValue ? onSelectHandler(e, option) : toggleShowDropdown())}
              className={cn(styles.dropdownOptionsItem, {
                [styles.dropdownOptionsItemActive]: option === defaultValue,
              })}>
              {option}
              {option === defaultValue && <CheckIcon />}
            </button>
          ))}
        </div>
      </CSSTransition>
    </div>
  );
};

export default DropdownFilterBy;
