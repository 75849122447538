import braintree from 'braintree-web-drop-in';
import BraintreeDropin from 'braintree-dropin-react';
import { Button, Loader, Modal } from 'components';
import { useHistory } from 'react-router-dom';
import { notify } from 'utils';
import { useAppDispatch, useAppSelector } from 'store';
import { IRegistrationDataResponse, registerPersonalUser } from 'store/slices/auth/auth';
import styles from './PaymentModal.module.scss';

interface Props {
  isModalOpen: boolean;
  toggleShowModal: () => void;
  isLoading: boolean;
  formValues: string[];
  registrationData: IRegistrationDataResponse;
}

const PaymentModal = ({ isModalOpen, toggleShowModal, isLoading, formValues, registrationData }: Props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { isLoading: isDisabled } = useAppSelector((state) => state.auth.registerPersonalUserRequest);

  const renderSubmitButton = (args: any) => (
    <>
      <Button onClick={toggleShowModal} variant="secondary" size="40">
        Cancel
      </Button>
      <Button
        id="login-purchase-plan"
        onClick={args.onClick}
        isDisabled={args.isDisabled}
        isLoading={isDisabled}
        variant="primary"
        size="40"
        style={{ width: '11.3em' }}>
        {args.text}
      </Button>
    </>
  );
  const handlePaymentMethod = async (payload: any) => {
    if (payload) {
      try {
        const { trialPeriod, monthlyPrice, monthlyMachineCount, annualPrice, annualMachineCount } = registrationData;
        const [email, password, , firstName, lastName, plan] = formValues;
        const { nonce: paymentMethodNonce } = payload;
        const isMonthlyPaymentPlan = plan === 'monthly';

        await dispatch(
          registerPersonalUser({
            email,
            firstName,
            lastName,
            password,
            isMonthlyPaymentPlan,
            paymentMethodNonce,
            trialPeriod,
            monthlyPrice,
            monthlyMachineCount,
            annualPrice,
            annualMachineCount,
          }),
        ).unwrap();
        history.push('/email-validation');
      } catch (err: any) {
        notify.error(err.message);
      }
    }
  };
  return (
    <Modal isOpen={isModalOpen} onRequestClose={toggleShowModal} contentLabel="Payment">
      {isLoading ? (
        <Loader id="loading-registration-payment" />
      ) : (
        <>
          {formValues[5] === 'monthly' ? (
            <>
              <p>
                Total amount to pay: <strong>${registrationData?.monthlyPrice}</strong>
              </p>
              <p>You will be charged automatically each month</p>
            </>
          ) : (
            <>
              <p>
                Total amount to pay: <strong>${registrationData?.annualPrice}</strong>
              </p>
              <p>You will be charged automatically each year</p>
            </>
          )}
          <BraintreeDropin
            submitButtonText="Purchase Plan"
            braintree={braintree}
            authorizationToken={registrationData.clientToken}
            renderSubmitButton={renderSubmitButton}
            handlePaymentMethod={handlePaymentMethod}
            paypal={{ flow: 'vault', singleUse: false }}
            className={styles.buttonActions}
          />
        </>
      )}
    </Modal>
  );
};

export default PaymentModal;
