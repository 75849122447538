import cn from 'classnames';
import { Modal } from 'components';
import { AlertWarning, SuccesCircleIcon } from 'assets/img';
import { dateFormatterUtc } from 'helpers';
import styles from './SentryHostsInfoModal.module.scss';
import { IDomain } from 'interfaces';

interface Props {
  onRequestClose: () => void;
  isOpen: boolean;
  domain: IDomain;
}

const SentryHostsInfoModal = ({ onRequestClose, isOpen, domain }: Props) => (
  <Modal
    isOpen={isOpen}
    contentLabel="Sentry Hosts"
    contentSubtitle={
      <>
        for <strong>{domain.dnsName}</strong>
      </>
    }
    enableOverflow
    onRequestClose={onRequestClose}>
    {domain.sentries?.length > 0 ? (
      <ul className={styles.hostList}>
        {domain.sentries.map((sentry) => (
          <li className={cn(styles.hostListItem, { [styles.offline]: !sentry.isOnline })} key={sentry.hostName}>
            <div className={styles.hostListItemInfo}>
              <div className={styles.hostListItemRow}>
                <div className={styles.hostListItemInfoName}>{sentry.hostName}</div>
                <div className={styles.hostListItemInfoStatus}>
                  {sentry.isOnline ? (
                    <span className={cn({ [styles.online]: sentry.isOnline })}>
                      <SuccesCircleIcon /> Online
                    </span>
                  ) : (
                    <span className={cn({ [styles.offline]: !sentry.isOnline })}>
                      <AlertWarning /> Offline
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div>
              <div className={styles.hostListItemVersion}>
                Sentry Version: <b>{sentry.version}</b>
              </div>
              <div className={styles.hostListItemResponse}>
                Last Response: <b>{dateFormatterUtc(sentry.lastResponse)}</b>
              </div>
            </div>
          </li>
        ))}
      </ul>
    ) : (
      <div className={styles.noMessage}>
        <b>There are no sentries installed</b>
      </div>
    )}
  </Modal>
);

export default SentryHostsInfoModal;
