import Modal from 'react-modal';
import cn from 'classnames';
import { CloseIcon } from 'assets/img';
import SwitchCheckbox from 'components/SwitchCheckbox/SwitchCheckbox';
import Button from '../Button/Button';
import styles from './Modal.module.scss';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  contentLabel: any;
  contentSubtitle?: any;
  shouldCloseOnOverlayClick?: boolean;
  contentClass?: any;
  cancelTitle?: string;
  actionFunc?: () => void;
  actionTitle?: string;
  variantBtn?: 'primary' | 'secondary' | 'link' | 'delete' | 'deleteOutline';
  buttonClass?: any;
  cancelButtonClass?: any;
  cancelAction?: () => void;
  actionLoading?: boolean;
  actionDisabled?: boolean;
  style?: any;
  isSwitcherChecked?: boolean;
  onChangeSwitcher?: () => void;
  switcherTitle?: string;
  enableOverflow?: boolean;
  stickyFooter?: boolean;
  children: any;
}

const ModalComponent = ({
  isOpen,
  onRequestClose,
  contentLabel,
  contentSubtitle,
  contentClass,
  actionFunc,
  actionTitle,
  variantBtn = 'primary',
  shouldCloseOnOverlayClick = true,
  buttonClass,
  cancelButtonClass,
  cancelAction,
  cancelTitle = 'Cancel',
  actionLoading,
  actionDisabled,
  style,
  children,
  isSwitcherChecked,
  onChangeSwitcher,
  switcherTitle,
  enableOverflow,
  stickyFooter,
}: Props) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    contentLabel={contentLabel}
    ariaHideApp={false}
    closeTimeoutMS={360}
    style={style}
    shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    className={cn(styles.modalPortal, { [styles.overflow]: enableOverflow })}
    overlayClassName={styles.modalOverlay}>
    <div className={styles.modalHeader}>
      {contentSubtitle ? (
        <div className={styles.modalHeaderTitle}>
          <span>{contentLabel}</span>
          <span className={styles.modalHeaderTitleSubtitle}>{contentSubtitle}</span>
        </div>
      ) : (
        <span>{contentLabel}</span>
      )}
      <Button
        id="close-modal"
        variant="link"
        className={cn(styles.modalClose, { [styles.modalCloseSubtitle]: contentSubtitle })}
        icon={<CloseIcon />}
        onClick={onRequestClose}
      />
    </div>
    <div
      className={cn(styles.modalContent, {
        [styles.modalContentActions]: !(actionFunc && actionTitle),
        [contentClass]: contentClass,
      })}>
      {children}
    </div>
    {actionFunc && actionTitle && (
      <div
        className={cn(styles.modalActions, {
          [styles.modalActionsWithSwitcher]: switcherTitle,
          [styles.sticky]: stickyFooter,
        })}>
        {switcherTitle && (
          <div className={styles.switcher}>
            <SwitchCheckbox checked={isSwitcherChecked} onChange={onChangeSwitcher} />
            <p className={styles.switcherTitle}>{switcherTitle}</p>
          </div>
        )}
        <div className={styles.modalActionsButtons}>
          <Button
            id="modal-close"
            className={cn(styles.modalActionsButton, { [cancelButtonClass]: cancelButtonClass })}
            onClick={cancelAction || onRequestClose}
            size="40"
            variant="secondary">
            {cancelTitle}
          </Button>
          <Button
            id="modal-action"
            className={cn(styles.modalActionsButton, { [buttonClass]: buttonClass })}
            onClick={actionFunc}
            size="40"
            variant={variantBtn}
            isLoading={actionLoading}
            isDisabled={actionDisabled}>
            {actionTitle}
          </Button>
        </div>
      </div>
    )}
  </Modal>
);

export default ModalComponent;
