import {
  corpTechAdmin,
  corpTechUser,
  custAdmin,
  custUser,
  freePersonalUser,
  personalUser,
  spTechAdmin,
  spTechUser,
  techAdmin,
} from 'consts';
import { getDomainADStatus } from 'helpers';
import { ICustomerDomainOutdatedSentry, IOutdatedSentry, IUser } from 'interfaces';
import { getUserRole } from 'utils';

export const checkIsShowApps = (user: IUser, myDomainPage: string, hasSecureConnects: boolean) => {
  const { isDomainAzure } = getDomainADStatus(user);
  return myDomainPage !== 'myDomainPersonal' && !isDomainAzure && !(user.showOnboarding && hasSecureConnects);
};

export const getOutdatedSentryCount = (
  sentries: IOutdatedSentry[] | ICustomerDomainOutdatedSentry[],
  customerDomain?: boolean,
) => {
  return customerDomain
    ? (sentries as ICustomerDomainOutdatedSentry[])
        .map((i: ICustomerDomainOutdatedSentry) => i.sentries)
        .flat()
        .filter((sentry) => sentry.isOutdated).length
    : (sentries as IOutdatedSentry[]).filter((sentry: IOutdatedSentry) => sentry.isOutdated).length;
};

export const getWorkspaceDataMap = (user: IUser, hasSecureConnects: boolean) => {
  const role = getUserRole(user);

  const { isDomainAzure } = getDomainADStatus(user);

  const isCustomer = [custUser, custAdmin].includes(role);
  const isPersonal = [personalUser, freePersonalUser].includes(role);
  const isAdditionalFilter = [spTechAdmin, techAdmin, corpTechAdmin, spTechUser, corpTechUser].includes(role);
  const myDomainPage = isCustomer
    ? 'myDomainCust'
    : isPersonal
    ? 'myDomainPersonal'
    : isAdditionalFilter
    ? 'myDomainWithFilter'
    : 'myDomain';
  const isShowApps =
    myDomainPage !== 'myDomainPersonal' && !isDomainAzure && !(user.showOnboarding && hasSecureConnects);

  return { isPersonal, myDomainPage, isShowApps };
};
