import cn from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { getUserRole } from 'utils/getUserRole';
import {
  HeaderUserIcon,
  HeaderSettingsIcon,
  HeaderLicensesIcon,
  HeaderBillingIcon,
  HeaderSignoutIcon,
  StarIcon,
} from 'assets/img';
import Button from 'components/Button/Button';
import { spAdmin, spTechAdmin, corpAdmin, corpTechAdmin, personalUser, freePersonalUser } from 'consts';
import { signOut } from 'helpers';
import { IUser } from 'interfaces';
import styles from './HeaderDropdown.module.scss';

interface Props {
  user: IUser;
  onClose: () => void;
}

const HeaderDropdown = ({ user, onClose }: Props) => {
  const role = getUserRole(user);
  const isSubsPage = [spAdmin, spTechAdmin, corpAdmin, corpTechAdmin, personalUser, freePersonalUser].includes(role);
  const isBrandingVisible = [spAdmin, spTechAdmin, corpAdmin, corpTechAdmin].includes(role);
  const isPersonalRole = [personalUser, freePersonalUser].includes(role);
  const { pathname } = useLocation();

  const renderLinks = () => {
    const links = [
      {
        to: '/profile/myprofile',
        icon: <HeaderUserIcon />,
        title: 'My Profile',
      },
    ];
    if (isBrandingVisible) {
      links.push({ to: '/profile/branding', icon: <StarIcon />, title: 'Branding' });
    }
    if (user.domainStatus || isPersonalRole) {
      links.push({ to: '/profile/settings', icon: <HeaderSettingsIcon />, title: 'Settings' });
    }
    if (isSubsPage) {
      links.push({ to: '/profile/subscription', icon: <HeaderLicensesIcon />, title: 'Subscriptions' });
    }
    if (isPersonalRole) {
      links.push({ to: '/profile/billing', icon: <HeaderBillingIcon />, title: 'Billing' });
    }
    return links;
  };

  const handleClickMenuItem = (isActive: boolean) => !isActive && onClose();

  return (
    <div className={styles.headerDropdown} onClick={(e) => e.stopPropagation()}>
      <div className={styles.headerDropdownUser}>
        <h4 className={styles.headerDropdownUserName}>{user.displayName}</h4>
        <p className={styles.headerDropdownUserEmail}>{user.email}</p>
      </div>
      <ul className={styles.headerDropdownList}>
        {renderLinks().map(({ to, icon, title }) => (
          <li
            key={to}
            className={cn(styles.headerDropdownListItem, { [styles.headerDropdownListItemActive]: pathname === to })}>
            <Link
              key={to}
              to={to}
              id={to}
              className={styles.headerDropdownListLink}
              onClick={() => handleClickMenuItem(pathname === to)}>
              {icon} {title}
            </Link>
          </li>
        ))}
        <li id="/profile/signout" className={styles.headerDropdownListItem}>
          <Button variant="link" className={styles.headerDropdownListButton} onClick={signOut}>
            <HeaderSignoutIcon /> Sign Out
          </Button>
        </li>
      </ul>
    </div>
  );
};

export default HeaderDropdown;
