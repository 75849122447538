import { StatusMessage } from 'components';
import RemoteAppNotEnabled from './components/RemoteAppNotEnabled/RemoteAppNotEnabled';
import TableActions from './components/TableActions/TableActions';
import AppsHeader from './components/AppsHeader/AppsHeader';
import ToggleRemoteAppsModal from './components/ToggleRemoteAppsModal';
import { IDomain } from 'interfaces';
import { IModalData } from 'pages/ResourceAssignments/ResourceAssignments';
import AppAssignmentsTable from './components/AppAssignmentsTable/AppAssignmentsTable';
import { useAppSelector } from 'store';
import useModal from 'hooks/useModal';
import { SerializedError } from '@reduxjs/toolkit';
import NoApps from './components/NoApps';

interface Props {
  domain: IDomain;
  openManageAssignmentsModal: (data: IModalData) => void;
}

const AppAssignments = ({ domain, openManageAssignmentsModal }: Props) => {
  const appAssignments = useAppSelector((state) => state.resourceAssignments.appAssignmentsData);
  const searchQuery = appAssignments?.queryConfig?.searchQuery as string;

  const { isError, error } = useAppSelector((state) => state.resourceAssignments.getAppAssignmentsRequest);
  const {
    isOpen: showToggleRemoteAppsModal,
    openModal: openToggleRemoteAppsModal,
    closeModal: closeToggleRemoteAppsModal,
  } = useModal();

  const { isAppPublishingEnabled } = domain;

  const hideTableActions = !appAssignments?.data?.length && !searchQuery;

  if (isError) {
    return <StatusMessage error>{(error as SerializedError).message}</StatusMessage>;
  }

  return (
    <>
      <AppsHeader
        isAppPublishingEnabled={isAppPublishingEnabled}
        openToggleRemoteAppsModal={openToggleRemoteAppsModal}
        domain={domain}
      />

      {isAppPublishingEnabled && !hideTableActions && (
        <TableActions domainId={domain.id} isAppPublishingEnabled={isAppPublishingEnabled} />
      )}

      <div>
        {isAppPublishingEnabled ? (
          !hideTableActions ? (
            <AppAssignmentsTable domain={domain} openManageAssignmentsModal={openManageAssignmentsModal} />
          ) : (
            <NoApps />
          )
        ) : (
          <RemoteAppNotEnabled />
        )}
      </div>

      {showToggleRemoteAppsModal && (
        <ToggleRemoteAppsModal
          isOpen={showToggleRemoteAppsModal}
          onRequestClose={closeToggleRemoteAppsModal}
          domain={domain}
        />
      )}
    </>
  );
};

export default AppAssignments;
