import { useRef } from 'react';
import cn from 'classnames';
import { Button } from 'components';
import { useObserver } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store';
import { IBrandingDomainData, setSelectedDomains } from 'store/slices/branding';
import styles from './HeaderActions.module.scss';

interface Props {
  domains: IBrandingDomainData;
  handleChangeDomainBrandingStatus: (isHeader: boolean, domain: any) => void;
  queryViewBy: string;
  headerLoading: boolean;
}

const HeaderActions = ({ domains, handleChangeDomainBrandingStatus, queryViewBy, headerLoading }: Props) => {
  const dispatch = useAppDispatch();
  const { selectedDomains } = useAppSelector((state) => state.branding);

  const counterRef = useRef<HTMLDivElement | null>(null);
  const isSticky: boolean = useObserver(counterRef, !!selectedDomains.length, { threshold: 1 });

  const unselectAll = () => dispatch(setSelectedDomains([]));

  return (
    <div
      className={cn(styles.headerActions, { [styles.active]: selectedDomains.length, [styles.sticked]: isSticky })}
      ref={counterRef}>
      <div className={styles.headerActionsCount}>
        {selectedDomains.length}/{domains.pageMeta?.TotalCount || 0} Selected
      </div>
      <div className={styles.headerActionsWrap}>
        <Button variant="secondary" size="40" onClick={unselectAll}>
          Unselect All
        </Button>
        <Button
          variant="primary"
          className={styles.headerActionsButton}
          isLoading={headerLoading}
          size="40"
          onClick={() => handleChangeDomainBrandingStatus(true, false)}>
          {queryViewBy === 'Enabled' ? 'Disable Branding' : 'Enable Branding'}
        </Button>
      </div>
    </div>
  );
};

export default HeaderActions;
