import cn from 'classnames';
import { Button, Table } from 'components';
import ReactTooltip from 'react-tooltip';
import { useAppDispatch, useAppSelector } from 'store';
import { getBitlockerDevices, IBitlockerDevice } from 'store/slices/bitLockerMgmt';
import { CheckIcon, CloseIcon, DeviceIcon, InfoActiveIcon, InfoIcon } from 'assets/img';
import { useState } from 'react';
import { dataTipHandler } from 'pages/BitLockerMgmt/utils/bitLockerUtils';
import { formattedDate } from 'helpers';
import TableEmpty from 'pages/BitLockerMgmt/components/TableEmpty/TableEmpty';
import { IQueryConfig } from 'interfaces';
import styles from './DevicesTable.module.scss';

interface Props {
  domainId: string;
  selectedDeviceIds: IBitlockerDevice[];
  setSelectedDeviceIds: (deviceIds: IBitlockerDevice[]) => void;
  openDevicesModal: (device: IBitlockerDevice) => void;
  openUninstallAgentsModal: (device: IBitlockerDevice) => void;
}

const DevicesTable = ({
  domainId,
  selectedDeviceIds,
  setSelectedDeviceIds,
  openDevicesModal,
  openUninstallAgentsModal,
}: Props) => {
  const dispatch = useAppDispatch();

  const { bitlockerDevicesData: devices } = useAppSelector((state) => state.bitLockerMgmt);
  const { bitlockerSubscriptionStatus } = useAppSelector((state) => state.bitLockerMgmt);

  const installationStatus = devices?.queryConfig.installationStatus;
  const [isInfoActive, setIsInfoActive] = useState('');

  const isDeviceWithoutLicense = ({ deviceId, hasLicense }: IBitlockerDevice) =>
    cn({
      [styles.tableRowUnlicensed]: !hasLicense,
      [styles.tableRowChecked]: selectedDeviceIds.find((device) => device.deviceId === deviceId),
    });

  const columns = [
    {
      name: '',
      width: '6%',
      checkbox: ({ isOnline, installationStatus: status }: IBitlockerDevice) => !(status === 2 && isOnline),
    },
    {
      name: 'Hostname',
      sortable: 'hostname',
      width: () => (installationStatus === 'AllStatuses' ? '29%' : '23%'),
      data: ({
        deviceId,
        osType,
        hasLicense,
        appVersion,
        osVersion,
        isOnline,
        hostname,
        installationStatus: status,
      }: IBitlockerDevice) => (
        <div className={styles.name}>
          <span className={styles.nameHostname}>
            <DeviceIcon
              className={cn(
                styles.nameHostnameIcon,
                {
                  [styles.nameHostnameIcon]: !hasLicense,
                },
                {
                  [styles.nameHostnameIconOnline]: isOnline,
                },
              )}
            />
            <span className={styles.nameHostnameName}>{hostname}</span>
            <div
              className={styles.nameHostnameWrap}
              onMouseEnter={() => setIsInfoActive(deviceId)}
              onMouseLeave={() => setIsInfoActive('')}>
              <div
                data-tip={dataTipHandler(osType, hasLicense, appVersion, osVersion)}
                data-for="info-tooltip"
                data-iscapture="true"
                className={styles.nameHostnameTooltip}
              />
              {isInfoActive === deviceId ? <InfoActiveIcon /> : <InfoIcon />}
            </div>
          </span>
          <div className={styles.nameStatus}>
            {status === 0 ? (
              <span className={cn(styles.nameStatusInfo, styles.nameStatusInfoInstalled)}>
                <CheckIcon /> Installed
              </span>
            ) : status === 1 ? (
              <span className={cn(styles.nameStatusInfo, styles.nameStatusInfoUninstalled)}>
                <CloseIcon /> Uninstalled
              </span>
            ) : (
              <span className={cn(styles.nameStatusInfo, styles.nameStatusInfoPending)}>
                <CloseIcon /> Pending uninstall
              </span>
            )}
          </div>
        </div>
      ),
    },
    {
      name: 'Status',
      width: '9%',
      data: ({ isOnline }: IBitlockerDevice) =>
        isOnline ? (
          <p className={cn(styles.status, styles.statusOnline)}>Online</p>
        ) : (
          <p className={cn(styles.status, styles.statusOffline)}>Offline</p>
        ),
    },
    {
      name: 'Dates & Times',
      sortable: 'dateAdded',
      width: '30%',
      tooltip: {
        tip: 'Sort by Date Added',
        id: 'sort-by-date',
      },
      data: ({ dateAdded, lastConnectedDate }: IBitlockerDevice) => (
        <>
          <div className={styles.deviceInfo}>
            Added: <span>{formattedDate(dateAdded)}</span>
          </div>
          <div className={styles.deviceInfo}>
            Last Connected: <span>{formattedDate(lastConnectedDate)}</span>
          </div>
        </>
      ),
    },
    {
      name: 'IP Address',
      width: '22%',
      data: ({ externalIp, internalIp }: IBitlockerDevice) => (
        <>
          <div className={styles.deviceInfo}>
            External: <span>{externalIp}</span>
          </div>
          <div className={styles.deviceInfo}>
            Internal: <span>{internalIp}</span>
          </div>
        </>
      ),
    },
    {
      name: 'Actions',
      width: '10%',
      data: (device: IBitlockerDevice) =>
        device.installationStatus === 0 ? (
          <Button
            variant="link"
            id="uninstall-device"
            className={cn(styles.deviceButton, styles.deviceButtonUninstall)}
            onClick={() => openUninstallAgentsModal(device)}>
            Uninstall
          </Button>
        ) : (
          !(device.installationStatus === 2 && device.isOnline) && (
            <Button
              variant="link"
              id="delete-device"
              className={cn(styles.deviceButton, styles.deviceButtonDelete)}
              onClick={() => openDevicesModal(device)}>
              Delete
            </Button>
          )
        ),
    },
  ];

  const dispatchAction = async (query: IQueryConfig) => {
    await dispatch(getBitlockerDevices({ domainId: domainId, query, _background: true })).unwrap();
  };

  return (
    <>
      <Table<IBitlockerDevice>
        columns={columns}
        noData={<TableEmpty isNoLicense={!bitlockerSubscriptionStatus} />}
        data={devices}
        ruleForSelectItems={(d) => !(d.installationStatus === 2 && d.isOnline)}
        selectedItems={selectedDeviceIds}
        setSelectedItems={setSelectedDeviceIds}
        id="dm-devices-table"
        dataKey="deviceId"
        className={cn(styles.table, { [styles.allStatuses]: installationStatus === 'AllStatuses' })}
        classNameRow={(item: IBitlockerDevice) => isDeviceWithoutLicense(item)}
        dispatchAction={dispatchAction}
      />
      <ReactTooltip id="info-tooltip" type="light" place="right" html effect="solid" />
    </>
  );
};

export default DevicesTable;
