import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AsyncThunkConfig, GetThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { AxiosResponse } from 'axios';
import { http, createHttpRequestInitResult, createExtraReducersForResponses } from 'helpers/http';
import { IHttpRequestResult, IPageMeta, IQueryConfig } from 'interfaces';
import { RootState } from 'store/store';
import { queryFormatter } from 'utils';

export interface ITimeoutMgmtUser {
  userId: string;
  upn: string;
  displayName: string;
  isDesktopTimeoutEnabled: boolean;
}

export interface ITimeoutMgmtResponse {
  isTimeoutEnabled: boolean;
  timeoutDuration: number;
  users: ITimeoutMgmtUser[];
}

export interface ITimeoutMgmtData {
  data?: ITimeoutMgmtUser[];
  isTimeoutEnabled?: boolean;
  timeoutDuration?: number;
  pageMeta?: IPageMeta;
  queryConfig?: IQueryConfig;
  isNoData?: boolean;
}

export interface IEnableTimeoutForDomainPayload {
  domainId: string;
  enableForAllUsers: boolean;
  expirationMins: number;
}

export const getTimeoutMgmtData = createAsyncThunk(
  'timeoutMgmt/getTimeoutMgmtData',
  async (
    {
      domainId,
      query,
      _background = false,
    }: {
      domainId: string;
      query: IQueryConfig;
      _background?: boolean;
    },
    thunkAPI,
  ) => {
    const state = thunkAPI.getState() as RootState;
    const queryConfig = state.timeoutMgmt.timeoutMgmtData?.queryConfig || {};
    const queryConfigNew = { ...queryConfig, ...query };

    const response: AxiosResponse<ITimeoutMgmtResponse> = await http.get(
      queryFormatter(`/api/SecurityManagement/DesktopTimeout/Get?domainId=${domainId}`, queryConfigNew),
    );
    const pageMeta = response?.headers['x-pagination'] ? JSON.parse(response.headers['x-pagination']) : null;
    if (response.status === 204) {
      const data: ITimeoutMgmtData = {
        isNoData: true,
      };
      return data;
    }
    const data: ITimeoutMgmtData = {
      data: response.data.users,
      isTimeoutEnabled: response.data.isTimeoutEnabled,
      timeoutDuration: response.data.timeoutDuration,
      pageMeta,
      queryConfig: queryConfigNew,
    };
    return data;
  },
);

export const enableTimeoutForDomain = createAsyncThunk(
  'timeoutMgmt/enableTimeoutForDomain',
  async (
    { domainId, enableForAllUsers, expirationMins }: IEnableTimeoutForDomainPayload,
    thunkAPI: GetThunkAPI<AsyncThunkConfig>,
  ) => {
    await http.post(
      `/api/SecurityManagement/DesktopTimeout/ChangeDomainStatus?domainId=${domainId}&enableForAllUsers=${enableForAllUsers}&timeoutDuration=${expirationMins}`,
    );
    await thunkAPI.dispatch(
      getTimeoutMgmtData({ domainId, query: { pageNumber: 1, pageSize: 10 }, _background: true }),
    );
  },
);

export const disableTimeoutForDomain = createAsyncThunk(
  'timeoutMgmt/disableTimeoutForDomain',
  async ({ domainId }: { domainId: string }) => {
    await http.post(`/api/SecurityManagement/DesktopTimeout/ChangeDomainStatus?domainId=${domainId}`);
  },
);

export const changeTimeoutForUsers = createAsyncThunk(
  'timeoutMgmt/changeTimeoutForUsers',
  async ({ selectedUserIds }: { selectedUserIds: string[] }) => {
    await http.post('/api/SecurityManagement/DesktopTimeout/ChangeUserStatus', selectedUserIds);
  },
);

export const changeTimeoutPeriod = createAsyncThunk(
  'timeoutMgmt/changeTimeoutPeriod',
  async ({ domainId, timeoutDuration }: { domainId: string; timeoutDuration: number }) => {
    await http.post(
      `/api/SecurityManagement/DesktopTimeout/ChangeTimeoutDuration?domainId=${domainId}&timeoutDuration=${timeoutDuration}`,
    );
  },
);

interface ITimeoutMgmtState {
  timeoutMgmtData: ITimeoutMgmtData | null;
  getTimeoutMgmtDataRequest: IHttpRequestResult<ITimeoutMgmtData>;
  enableTimeoutForDomainRequest: IHttpRequestResult<null>;
  disableTimeoutForDomainRequest: IHttpRequestResult<null>;
  changeTimeoutForUsersRequest: IHttpRequestResult<null>;
  changeTimeoutPeriodRequest: IHttpRequestResult<null>;
}

const initialState: ITimeoutMgmtState = {
  timeoutMgmtData: null,
  getTimeoutMgmtDataRequest: createHttpRequestInitResult(),
  enableTimeoutForDomainRequest: createHttpRequestInitResult(),
  disableTimeoutForDomainRequest: createHttpRequestInitResult(),
  changeTimeoutForUsersRequest: createHttpRequestInitResult(),
  changeTimeoutPeriodRequest: createHttpRequestInitResult(),
};

export const timeoutMgmtSlice = createSlice({
  name: 'TimeoutMgmt',
  initialState,
  reducers: {
    updateTimeoutMgmtData: (state, { payload }) => {
      state.timeoutMgmtData = payload;
    },
  },
  extraReducers: (builder) => {
    createExtraReducersForResponses<ITimeoutMgmtState>(
      builder,
      getTimeoutMgmtData,
      'getTimeoutMgmtDataRequest',
      (state, { payload }) => {
        state.timeoutMgmtData = payload;
      },
    );
    createExtraReducersForResponses<ITimeoutMgmtState>(
      builder,
      enableTimeoutForDomain,
      'enableTimeoutForDomainRequest',
    );
    createExtraReducersForResponses<ITimeoutMgmtState>(
      builder,
      disableTimeoutForDomain,
      'disableTimeoutForDomainRequest',
      (state) => {
        state.timeoutMgmtData = null;
      },
    );
    createExtraReducersForResponses<ITimeoutMgmtState>(
      builder,
      changeTimeoutForUsers,
      'changeTimeoutForUsersRequest',
      (state, action) => {
        const selectedUserIds: string[] = action.meta.arg.selectedUserIds;
        state.timeoutMgmtData?.data?.forEach((item: ITimeoutMgmtUser) => {
          if (selectedUserIds.includes(item.userId)) {
            item.isDesktopTimeoutEnabled = !item.isDesktopTimeoutEnabled;
          }
        });
      },
    );
    createExtraReducersForResponses<ITimeoutMgmtState>(
      builder,
      changeTimeoutPeriod,
      'changeTimeoutPeriodRequest',
      (state, action) => {
        if (state.timeoutMgmtData && state.timeoutMgmtData.data) {
          state.timeoutMgmtData.timeoutDuration = action.meta.arg.timeoutDuration;
        }
      },
    );
  },
});

export const { updateTimeoutMgmtData } = timeoutMgmtSlice.actions;

export default timeoutMgmtSlice.reducer;
