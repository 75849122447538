export const isColor = (strColor: string) => {
  const s = new Option().style;
  s.color = strColor;
  return s.color !== '';
};

export const convertHex = (hexcolor: string) => {
  if (!hexcolor) return;
  if (hexcolor.length === 3) {
    return `#${hexcolor
      .split('')
      .map((hex) => hex + hex)
      .join('')}`;
  }
  if (hexcolor.length === 4) {
    return `#${hexcolor
      .slice(0, 3)
      .split('')
      .map((hex) => hex + hex)
      .join('')}`;
  }
  return `#${hexcolor}`;
};

export const invertColor = (hex?: string | null) => {
  if (!hex) return;
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  if (hex.length === 4 || hex.length === 5) {
    hex = hex.slice(0, 3);
  }
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((color) => color + color)
      .join('');
  }
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);
  return r * 0.299 + g * 0.587 + b * 0.114 > 186;
};

export const addAlpha = (color: string, amount: number) => {
  const opacity = Math.round(Math.min(Math.max(amount ?? 1, 0), 1) * 255);
  return color + opacity.toString(16).toUpperCase();
};
