import { useEffect, useState } from 'react';
import cn from 'classnames';
import { Button, Loader } from 'components';
import { AlertWarning } from 'assets/img';
import { notify } from 'utils';
import ForgotPasswordMgmtHeader from './components/ForgotPasswordMgmtHeader/ForgotPasswordMgmtHeader';
import { IDomain } from 'interfaces';
import { useAppDispatch, useAppSelector } from 'store';
import {
  IForgotPassData,
  changeForgotPassData,
  getForgotPassData,
  resetForgotPassData,
} from 'store/slices/securityMgmt/forgotPass';
import { checkIfSomeChanged, checkIsFormValid, clearError } from './utils/forgotPassUtils';
import styles from './ForgotPasswordMgmt.module.scss';

interface Props {
  domain: IDomain;
  isCompanyPage: any;
}

export type FormErrors = string[];

const ForgotPasswordMgmt = ({ domain, isCompanyPage }: Props) => {
  const { isLoading } = useAppSelector((state) => state.forgotPassword.getForgotPassDataRequest);
  const { forgotPassData } = useAppSelector((state) => state.forgotPassword);
  const [passData, setPassData] = useState<IForgotPassData>({
    domainId: domain.id,
    lockoutDuration: forgotPassData?.lockoutDuration || 0,
    retryTimePeriod: forgotPassData?.retryTimePeriod || 0,
    resetAttemptsCount: forgotPassData?.resetAttemptsCount || 0,
  });
  const dispatch = useAppDispatch();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [formErrors, setFormErrors] = useState<FormErrors>(['', '', '']);

  const fetchData = async () => {
    try {
      const data = await dispatch(getForgotPassData({ domainId: domain.id })).unwrap();
      setPassData(data);
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onChangeInput = (type: 'attempts' | 'period' | 'duration') => (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    const { maxLength } = e.target;
    value = value.slice(0, maxLength);
    if (Number(value) < 0) {
      return false;
    }
    if (type === 'attempts') {
      if (Number(value) === 0) {
        setFormErrors(['', '', '']);
      }
      setPassData({ ...passData, resetAttemptsCount: Number(value) });
    }
    if (type === 'period') {
      setPassData({ ...passData, retryTimePeriod: Number(value) });
    }
    if (type === 'duration') {
      setPassData({ ...passData, lockoutDuration: Number(value) });
    }
  };

  const handleClearError = (index: number) => {
    const errors = clearError(formErrors, index);
    setFormErrors(errors);
  };

  const resetToDefault = async () => {
    try {
      setFormErrors(['', '', '']);
      const data = await dispatch(resetForgotPassData({ domainId: domain.id })).unwrap();
      setPassData(data);
      notify.success('Settings reset to default');
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  const savePassMgmt = async () => {
    try {
      const { errors, isValid } = checkIsFormValid(formErrors, passData);
      if (errors.length) {
        setFormErrors(errors);
      }
      if (isValid) {
        setIsButtonLoading(true);
        await dispatch(changeForgotPassData({ data: passData })).unwrap();
        notify.success('Settings saved');
      }
    } catch (err: any) {
      notify.error(err.message);
    } finally {
      setIsButtonLoading(false);
    }
  };

  const disableSaveButton = checkIfSomeChanged(forgotPassData, passData);

  if (isLoading) {
    return <Loader id="loading-forgot-password-mgmt" />;
  }

  return (
    <div className={styles.passMgmt}>
      <div className={styles.passMgmtContent}>
        <ForgotPasswordMgmtHeader domain={domain} isCompanyPage={isCompanyPage} />
        <h3 className={styles.passMgmtTitle}>Forgot Password Management</h3>

        <div className={styles.passMgmtList}>
          <div className={styles.passMgmtMessage}>
            <div className={styles.passMgmtMessageIcon}>
              <AlertWarning />
            </div>
            {passData?.resetAttemptsCount !== 0 ? (
              <>
                After making more than <span>{passData?.resetAttemptsCount || forgotPassData?.resetAttemptsCount}</span>{' '}
                attempts within <span>{passData?.retryTimePeriod || forgotPassData?.retryTimePeriod}</span> mins, AD
                user won't be able to reset password for{' '}
                <span>{passData?.lockoutDuration || forgotPassData?.lockoutDuration}</span> mins
              </>
            ) : (
              <>
                Setting <span>{passData.resetAttemptsCount || forgotPassData?.resetAttemptsCount}</span> attempt means
                failed resets will not be blocked
              </>
            )}
          </div>
          <div className={styles.passMgmtItem}>
            <div className={styles.passMgmtRow}>
              <div className={styles.passMgmtCol}>
                <h4 className={styles.passMgmtInputTitle}>Password Reset Attempts</h4>
                <p className={styles.passMgmtInputSubtitle}>
                  You can set between <strong>0</strong> and <strong>99</strong> failed reset attempts
                </p>
              </div>
              <div className={styles.passMgmtCol}>
                <div className={styles.passMgmtRow}>
                  <input
                    id="attempts"
                    type="number"
                    className={cn(styles.passMgmtInput, { [styles.passMgmtInputError]: formErrors[0] })}
                    value={passData.resetAttemptsCount}
                    maxLength={2}
                    onChange={onChangeInput('attempts')}
                    onFocus={() => handleClearError(0)}
                  />
                  {formErrors[0] && <div className={styles.passMgmtError}>{formErrors[0]}</div>}
                  <label className={styles.passMgmtLabel}>Attempts</label>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.passMgmtItem}>
            <div className={styles.passMgmtRow}>
              <div className={styles.passMgmtCol}>
                <h4 className={styles.passMgmtInputTitle}>Retry Time Period</h4>
                <p className={styles.passMgmtInputSubtitle}>
                  You can set between <strong>5</strong> and <strong>90</strong> mins for retries
                </p>
              </div>
              <div className={styles.passMgmtCol}>
                <div className={styles.passMgmtRow}>
                  <input
                    id="period"
                    type="number"
                    className={cn(styles.passMgmtInput, {
                      [styles.passMgmtInputError]: formErrors[1],
                      [styles.passMgmtInputDisabled]: passData.resetAttemptsCount === 0,
                    })}
                    value={passData.retryTimePeriod}
                    maxLength={2}
                    disabled={passData.resetAttemptsCount === 0}
                    onFocus={() => handleClearError(1)}
                    onChange={onChangeInput('period')}
                  />
                  {formErrors[1] && <div className={styles.passMgmtError}>{formErrors[1]}</div>}
                  <label className={styles.passMgmtLabel}>Mins</label>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.passMgmtItem}>
            <div className={styles.passMgmtRow}>
              <div className={styles.passMgmtCol}>
                <h4 className={styles.passMgmtInputTitle}>Password Reset Lockout Duration</h4>
                <p className={styles.passMgmtInputSubtitle}>
                  You can set between <strong>5</strong> and <strong>1440 mins (24h)</strong> for lockout duration
                </p>
              </div>
              <div className={styles.passMgmtCol}>
                <div className={styles.passMgmtRow}>
                  <input
                    type="number"
                    id="duration"
                    className={cn(styles.passMgmtInput, {
                      [styles.passMgmtInputError]: formErrors[2],
                      [styles.passMgmtInputDisabled]: passData.resetAttemptsCount === 0,
                    })}
                    value={passData.lockoutDuration}
                    maxLength={4}
                    disabled={passData.resetAttemptsCount === 0}
                    onFocus={() => handleClearError(2)}
                    onChange={onChangeInput('duration')}
                  />
                  {formErrors[2] && <div className={styles.passMgmtError}>{formErrors[2]}</div>}
                  <label className={styles.passMgmtLabel}>Mins</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.passMgmtActions}>
        <Button id="close-modal-enable" onClick={resetToDefault} size="40" variant="secondary">
          Reset to Default
        </Button>
        <Button
          id="enable-password-mgmt"
          onClick={savePassMgmt}
          size="40"
          variant="primary"
          isDisabled={disableSaveButton}
          isLoading={isButtonLoading}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default ForgotPasswordMgmt;
