import { useCallback, useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Loader, ActivationCode, DomainStatusMsg } from 'components';
import { getDomainADStatus, getDomainStatus, isSentryDeleted } from 'helpers';
import DesktopAssignments from './components/DesktopAssignments/DesktopAssignments';
import AppAssignments from './components/AppAssignments/AppAssignments';
import ManageAssignmentsModal from './components/ManageAssignmentsModal/ManageAssignmentsModal';
import { useAppDispatch } from 'store';
import { getAppAssignments, getDesktopAssignments } from 'store/slices/resourceAssignments';
import useModal from 'hooks/useModal';
import styles from './ResourceAssignments.module.scss';
import { IDomain } from 'interfaces';
import { notify } from 'utils';

export interface IModalData {
  type: string;
  assignType: string;
  domainId: string;
  viewBy: string;
  resourceName: string;
  resourceId: string;
  isPooled: boolean;
}

interface Props {
  domain: IDomain;
  isDomainNotActivated: boolean;
  userRole: string;
}

const ResourceAssignments = ({ domain, isDomainNotActivated }: Props) => {
  const sentryDeleted = isSentryDeleted(domain);
  const { isDomainAzure } = getDomainADStatus(domain);
  const { isDomainActive } = getDomainStatus(domain);

  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const {
    isOpen: isOpenManageAssignmentsModal,
    openModal: openManageAssignmentsModal,
    closeModal: closeManageAssignmentsModal,
    modalData: manageAssignmentsModalData,
  } = useModal<IModalData>();

  const fetchData = useCallback(
    async (isFetchDesks: boolean, isFetchApps: boolean): Promise<void> => {
      try {
        const queryConfig = {
          pageNumber: 1,
          pageSize: 10,
          viewBy: 'Users',
          orderBy: 'name asc',
          searchQuery: '',
        };
        const promises = [];
        if (isFetchDesks) {
          promises.push(getDesktopAssignments({ domainId: domain.id, query: queryConfig }));
        }
        if (isFetchApps) {
          promises.push(getAppAssignments({ domainId: domain.id, query: queryConfig }));
        }
        await Promise.all(promises.map((p) => dispatch(p)));
        setIsLoading(false);
      } catch (err: any) {
        notify.error(err.message);
        setIsLoading(false);
      }
    },
    [dispatch, domain.id],
  );

  useEffect(() => {
    const isFetchApps: boolean = !isDomainAzure && (domain?.isAppPublishingEnabled || false);
    if ((!sentryDeleted || isDomainAzure) && isDomainActive) {
      fetchData(true, isFetchApps);
    } else {
      setIsLoading(false);
    }
  }, [domain, fetchData, isDomainActive, isDomainAzure, sentryDeleted]);

  if (isLoading) return <Loader id="loading-resource-assignments" />;

  if (isDomainNotActivated || sentryDeleted) {
    return <DomainStatusMsg domainData={domain} isSentryDeleted={sentryDeleted} pageName="Resource Assignment" />;
  }

  return (
    <div className={styles.resourceAssignments}>
      <div className={styles.resourceAssignmentsHeader}>
        <h3 className={styles.resourceAssignmentsTitle}>Manage resource and user associations</h3>
        <ActivationCode
          activationCode={domain.activationCode || ''}
          label="Activation Code:"
          copyMessage="Activation code was saved to clipboard"
        />
      </div>

      <Tabs forceRenderTabPanel>
        <TabList>
          <Tab>
            <span id="tab-desktop-assignment">Desktop Assignment</span>
          </Tab>
          {!isDomainAzure && (
            <Tab>
              <span id="tab-app-assignment">App Assignment</span>
            </Tab>
          )}
        </TabList>

        <TabPanel>
          <DesktopAssignments domain={domain} openManageAssignmentsModal={openManageAssignmentsModal} />
        </TabPanel>
        {!isDomainAzure && (
          <TabPanel>
            <AppAssignments domain={domain} openManageAssignmentsModal={openManageAssignmentsModal} />
          </TabPanel>
        )}
      </Tabs>
      {isOpenManageAssignmentsModal && (
        <ManageAssignmentsModal
          isOpen={isOpenManageAssignmentsModal}
          onRequestClose={closeManageAssignmentsModal}
          modalData={manageAssignmentsModalData!}
        />
      )}
    </div>
  );
};

export default ResourceAssignments;
