import { Checkbox } from 'components';
import styles from './CheckBoxesGroup.module.scss';

interface Props {
  isMFAStatusChecked: boolean;
  setIsMFAStatusChecked: (isMFAStatusChecked: boolean) => void;
  isLicenseChecked: boolean;
  setIsLicenseChecked: (isLicenseChecked: boolean) => void;
  isADGroupChecked: boolean;
  setIsADGroupChecked: (isADGroupChecked: boolean) => void;
  isVisibleMfaCheckbox: boolean;
}

const CheckBoxesGroup = ({
  isMFAStatusChecked,
  setIsMFAStatusChecked,
  isLicenseChecked,
  setIsLicenseChecked,
  isADGroupChecked,
  setIsADGroupChecked,
  isVisibleMfaCheckbox,
}: Props) => {
  const checkboxes = [
    {
      checked: true,
      isDisabled: true,
      label: 'UPN',
      show: true,
    },
    {
      checked: isMFAStatusChecked,
      label: 'MFA Status',
      onChange: () => setIsMFAStatusChecked(!isMFAStatusChecked),
      show: isVisibleMfaCheckbox,
    },
    {
      checked: isLicenseChecked,
      label: 'License issued date',
      onChange: () => setIsLicenseChecked(!isLicenseChecked),
      show: true,
    },
    {
      checked: isADGroupChecked,
      label: 'AD Group Membership',
      onChange: () => setIsADGroupChecked(!isADGroupChecked),
      show: true,
    },
  ];
  return (
    <div className={styles.list}>
      <div className={styles.listColums}>Columns</div>
      <div className={styles.listContainer}>
        {checkboxes.map(
          ({ checked, isDisabled, label, show, onChange }) =>
            show && (
              <div key={label} className={styles.listContainerItem}>
                <Checkbox
                  className={styles.checkBoxWrapperCheckbox}
                  checked={checked}
                  isDisabled={isDisabled}
                  label={label}
                  onChange={onChange}
                />
              </div>
            ),
        )}
      </div>
    </div>
  );
};

export default CheckBoxesGroup;
