import { useCallback, useState, ChangeEvent, FormEvent } from 'react';

interface FormHook<T> {
  formData: T;
  handleInputChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleClearValue: (name: string) => void;
  handleSubmit: (e: FormEvent) => void;
}

export const useForm = <T>(initialState: T, onSubmit?: (formData: T) => void): FormHook<T> => {
  const [formData, setFormData] = useState<T>(initialState);

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      e.persist();
      if (e.target instanceof HTMLInputElement && e.target.type === 'checkbox') {
        setFormData({ ...formData, [e.target.name]: e.target.checked });
      } else if (e.target.name === 'phoneNumber') {
        if (!/^[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~\d]*$/g.test(e.target.value)) return;
        setFormData({ ...formData, [e.target.name]: e.target.value });
      } else if (e.target.maxLength !== -1) {
        setFormData({ ...formData, [e.target.name]: e.target.value.slice(0, e.target.maxLength) });
      } else {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      }
    },
    [formData],
  );

  const handleClearValue = useCallback(
    (name: string) => {
      setFormData({ ...formData, [name]: '' });
    },
    [formData],
  );

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      onSubmit?.(formData);
    },
    [formData, onSubmit],
  );

  return { formData, handleInputChange, handleClearValue, handleSubmit };
};
