import { ActivationCode, Link } from 'components';
import { IUser } from 'interfaces';
import { DownloadIcon } from 'assets/img';
import styles from './SetupOnPremise.module.scss';

interface Props {
  user: IUser;
}

const SetupOnPremise = ({ user }: Props) => (
  <div className={styles.setup}>
    <div className={styles.setupLink}>
      <div>
        <Link href={user.downloadSentryLink} bold external>
          <DownloadIcon />
          Download Sentry
        </Link>
        &nbsp;to any server in your domain
      </div>
    </div>
    <div className={styles.setupActivate}>
      <div>
        Use code to activate TruGrid Sentry
        <ActivationCode
          activationCode={user.domainActivationCode}
          copyMessage="Activation code was saved to clipboard"
          className={styles.activationCode}
        />
      </div>
    </div>
  </div>
);

export default SetupOnPremise;
