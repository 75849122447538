import { useRef } from 'react';
import cn from 'classnames';
import { Button } from 'components';
import { useObserver } from 'hooks';
import { useAppSelector } from 'store';
import { IRememberMeUser } from 'store/slices/securityMgmt/remebmerMe';
import styles from './HeaderActions.module.scss';

interface Props {
  selectedUsers: IRememberMeUser[];
  setSelectedUsers: (users: IRememberMeUser[]) => void;
  handleChangeRememberMeForUser: (isHeaderBtn: boolean, user?: IRememberMeUser) => void;
  isHeaderLoadingChanging: boolean;
}

const HeaderActions = ({
  selectedUsers,
  setSelectedUsers,
  handleChangeRememberMeForUser,
  isHeaderLoadingChanging,
}: Props) => {
  const { rememberMeData } = useAppSelector((state) => state.rememberMe);
  const queryViewBy = rememberMeData?.queryConfig?.viewBy;

  const counterRef = useRef<HTMLDivElement | null>(null);
  const isSticky: boolean = useObserver(counterRef, !!selectedUsers.length, { threshold: 1 });

  return (
    <div
      className={cn(styles.headerActions, { [styles.active]: selectedUsers.length, [styles.sticked]: isSticky })}
      ref={counterRef}>
      <div className={styles.headerActionsCount}>
        {selectedUsers.length}/{rememberMeData?.pageMeta?.TotalCount || 0} Selected
      </div>
      <div className={styles.headerActionsWrap}>
        <Button variant="secondary" size="40" onClick={() => setSelectedUsers([])}>
          Unselect All
        </Button>
        <Button
          variant="primary"
          className={styles.headerActionsButton}
          isLoading={isHeaderLoadingChanging}
          size="40"
          onClick={() => handleChangeRememberMeForUser(true)}>
          {queryViewBy === 'Enabled' ? 'Disable Remember Me' : 'Enable Remember Me'}
        </Button>
      </div>
    </div>
  );
};

export default HeaderActions;
