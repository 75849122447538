import { useState } from 'react';
import { CodeInput, ResendCode } from 'components';
import { useTimer } from 'hooks';
import { notify } from 'utils';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import { useAppDispatch, useAppSelector } from 'store';
import { checkMfaVerificationCode, requestVerificationCodeBySms } from 'store/slices/auth/mfa';
import { changeUserMfa, signOut } from 'store/slices/userSlice';
import styles from '../SetupPhone.module.scss';
import { SerializedError } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';

interface Props {
  phoneNumber: string;
  dialCode: string;
}

const VerifyPhone = ({ phoneNumber, dialCode }: Props) => {
  const { isLoading } = useAppSelector((state) => state.mfa.checkMfaVerificationCodeRequest);
  const { isLoading: isSending } = useAppSelector((state) => state.mfa.checkMfaVerificationCodeRequest);
  const { phoneMask, pinHash } = useAppSelector((state) => state.mfa);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [codeValue, setCodeValue] = useState('');
  const [isError, setIsError] = useState('');

  const clearError = () => setIsError('');
  const { timeLeft, resetCountdown } = useTimer(60);

  const onResendPhoneNumber = async () => {
    try {
      await dispatch(
        requestVerificationCodeBySms({ phone: phoneNumber.replace(/\D+/g, ''), code: dialCode, isMfaSetup: false }),
      ).unwrap();
      resetCountdown(60);
      notify.success('SMS has been sent');
    } catch (err: any) {
      if ((err as SerializedError).code === '409') {
        notify.error(err.message);
        await dispatch(signOut()).unwrap();
        history.replace('/login');
      } else {
        notify.error(err.message);
      }
    }
  };

  const onSubmitCode = async () => {
    try {
      await dispatch(checkMfaVerificationCode({ mfaPin: codeValue, mfaHash: pinHash })).unwrap();
      dispatch(changeUserMfa('none'));
      notify.success('Password Reset Option Successful Added');
    } catch (err: any) {
      setIsError(err.message);
    }
  };

  return (
    <div className={styles.setup}>
      <LoginHeader currentStep="2" stepCount="2" title="Verify Your Phone" />
      <div className={styles.setupInfo}>
        We've sent a verification code to your phone at <strong>{phoneMask}</strong>
      </div>
      <div className={styles.setupWrap}>
        <div className={styles.setupLabel}>Enter Verification Code</div>
        <CodeInput
          fields={4}
          isError={!!isError}
          errorMessage={isError}
          clearError={clearError}
          isDisable={isSending || isLoading}
          value={codeValue}
          setValue={setCodeValue}
          handleSend={onSubmitCode}
        />
        <div className={styles.setupResend}>
          <ResendCode countDown={timeLeft} handleSend={onResendPhoneNumber} isDisabled={isSending || isLoading} />
        </div>
      </div>
    </div>
  );
};

export default VerifyPhone;
