import { useRef } from 'react';
import cn from 'classnames';
import { useObserver } from 'hooks';
import { Button } from 'components';
import { headerButtonAction } from '../../utils/phoneSetupUtils';
import { IResetPhoneData, IResetPhoneUsers } from 'store/slices/securityMgmt/phoneSetup';
import { ActionType } from '../../PhoneSetupManagement';
import styles from './HeaderActions.module.scss';

interface Props {
  resetPhoneData: IResetPhoneData | null;
  selectedUsers: IResetPhoneUsers[];
  setSelectedUsers: (users: IResetPhoneUsers[]) => void;
  toggleShowModalChangeUser: (users: IResetPhoneUsers[], action: ActionType) => void;
}

const HeaderActions = ({ resetPhoneData, selectedUsers, setSelectedUsers, toggleShowModalChangeUser }: Props) => {
  const counterRef = useRef<HTMLDivElement | null>(null);
  const isSticky: boolean = useObserver(counterRef, !!selectedUsers.length, { threshold: 1 });
  const viewBy = resetPhoneData?.queryConfig?.viewBy;

  const action = headerButtonAction(viewBy || 'AllUsers');

  return (
    <header
      className={cn(styles.headerActions, { [styles.active]: selectedUsers.length, [styles.sticked]: isSticky })}
      ref={counterRef}>
      <div className={styles.headerActionsCount}>
        {selectedUsers.length}/{resetPhoneData?.pageMeta?.TotalCount || 0} Selected
      </div>
      <div className={styles.headerActionsWrap}>
        <Button
          variant="secondary"
          size="40"
          onClick={() => setSelectedUsers([])}
          className={styles.headerActionsButton}>
          Unselect All
        </Button>
        <Button
          variant="primary"
          className={styles.headerActionsButton}
          size="40"
          onClick={() => toggleShowModalChangeUser(selectedUsers, action.action)}>
          {action.label}
        </Button>
      </div>
    </header>
  );
};

export default HeaderActions;
