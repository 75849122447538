import { useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import cn from 'classnames';
import { Button, Checkbox } from 'components';
import { useOutsideClick } from 'hooks';
import { LoaderSmallIcon, FilterIcon } from 'assets/img';
import { getFilterValue } from '../../utils/shortcutUtils';
import { MFA_STATUSES, SHORTCUT_STATUSES } from '../../consts/utilsConsts';
import { useAppDispatch } from 'store';
import { IShortcutMgmtQuery, getShortcutMgmtData } from 'store/slices/securityMgmt/shortcutMgmt';
import { notify } from 'utils';
import styles from './ShortcutFilters.module.scss';

interface Props {
  id: string;
  isDomainOnPremise: boolean;
  desktopShortcutState: number;
  shortcutMfaRequired: number;
  domainId: string;
}

const ShortcutFilters = ({ id, isDomainOnPremise, desktopShortcutState, shortcutMfaRequired, domainId }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [selectedShotcutStatus, setSelectedShotcutStatus] = useState(SHORTCUT_STATUSES[desktopShortcutState]);
  const [selectedMfaStatus, setSelectedMfaStatus] = useState(MFA_STATUSES[shortcutMfaRequired]);

  const shortcutFilterRef = useRef<HTMLDivElement | null>(null);
  const nodeRef = useRef<HTMLDivElement | null>(null);

  const isShowMFACodeStatusFilter: boolean = selectedShotcutStatus.key === 1 && isDomainOnPremise;

  const filterValue = getFilterValue(selectedShotcutStatus, selectedMfaStatus, isShowMFACodeStatusFilter);
  const isDisabledClear = selectedShotcutStatus.key === 0;
  const isDisabledDone =
    selectedShotcutStatus.key === SHORTCUT_STATUSES[desktopShortcutState].key &&
    selectedMfaStatus.key === MFA_STATUSES[shortcutMfaRequired].key;

  const onClose = () => {
    setSelectedShotcutStatus(SHORTCUT_STATUSES[desktopShortcutState]);
    setSelectedMfaStatus(MFA_STATUSES[shortcutMfaRequired]);
    setIsOpen(false);
  };

  const onClear = () => {
    setSelectedShotcutStatus(SHORTCUT_STATUSES[0]);
    setSelectedMfaStatus(MFA_STATUSES[0]);
  };

  const onFilterHandler = async () => {
    try {
      setIsLoading(true);
      const query: IShortcutMgmtQuery = {
        pageNumber: 1,
        desktopShortcutState: selectedShotcutStatus.key,
        isShortcutMfaRequired: isShowMFACodeStatusFilter ? !selectedMfaStatus.key : null,
        orderBy: 'displayName asc',
      };
      setIsOpen(false);
      await dispatch(
        getShortcutMgmtData({
          domainId: domainId,
          query,
          _background: true,
        }),
      );
      setIsLoading(false);
    } catch (err: any) {
      notify.error(err.message);
      setIsLoading(false);
    }
  };

  useOutsideClick(shortcutFilterRef, () => isOpen && onClose());

  return (
    <div className={styles.shortcutFilter} ref={shortcutFilterRef}>
      <button
        type="button"
        id={id}
        className={cn(styles.shortcutFilterButton, { [styles.shortcutFilterButtonDisabled]: isLoading })}
        onClick={() => (isOpen ? onClose() : setIsOpen(true))}>
        <div className={cn(styles.shortcutFilterSelect, { [styles.shortcutFilterSelectActive]: isOpen })}>
          {isLoading ? <LoaderSmallIcon /> : <FilterIcon />}
          <span className={styles.shortcutFilterValue}>{filterValue}</span>
        </div>
      </button>
      <CSSTransition
        nodeRef={nodeRef}
        in={isOpen}
        timeout={200}
        unmountOnExit
        classNames={{
          enter: styles.shortcutFilterOptionsTransitionEnter,
          enterActive: styles.shortcutFilterOptionsTransitionEnterActive,
          exit: styles.shortcutFilterOptionsTransitionExit,
          exitActive: styles.shortcutFilterOptionsTransitionExitActive,
        }}>
        <div className={styles.shortcutFilterOptions} ref={nodeRef}>
          <div className={styles.shortcutFilterOptionsHeader}>Desktop Shortcut Status </div>
          <ul className={styles.shortcutFilterOptionsList}>
            {SHORTCUT_STATUSES.map((status) => (
              <li key={status.key} className={styles.shortcutFilterOptionsListItem}>
                <Checkbox
                  id={String(status.key)}
                  label={status.value}
                  checked={selectedShotcutStatus.key === status.key}
                  isCircle
                  onChange={() => setSelectedShotcutStatus(status)}
                />
              </li>
            ))}
          </ul>

          {isShowMFACodeStatusFilter && (
            <>
              <div className={cn(styles.shortcutFilterOptionsHeader, styles.shortcutFilterOptionsHeaderStatus)}>
                MFA Code Status
              </div>
              <ul className={styles.shortcutFilterOptionsList}>
                {MFA_STATUSES.map((status) => (
                  <li key={status.key} className={styles.shortcutFilterOptionsListItem}>
                    <Checkbox
                      id={String(status.key)}
                      label={status.value}
                      checked={selectedMfaStatus.key === status.key}
                      isCircle
                      onChange={() => setSelectedMfaStatus(status)}
                    />
                  </li>
                ))}
              </ul>
            </>
          )}

          <div className={styles.shortcutFilterOptionsActions}>
            <Button
              id="shortcut-filter-clear"
              type="button"
              variant="secondary"
              size="32"
              isDisabled={isDisabledClear}
              style={{ width: '48%' }}
              onClick={onClear}>
              Clear
            </Button>
            <Button
              id="shortcut-filter-done"
              type="button"
              variant="primary"
              size="32"
              isDisabled={isDisabledDone}
              style={{ width: '48%' }}
              onClick={onFilterHandler}>
              Done
            </Button>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default ShortcutFilters;
