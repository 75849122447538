export const getStatusMessage = (status?: string, isPersonalAccount?: boolean) => {
  if (status === 'Email validated successfully') {
    if (isPersonalAccount) {
      return {
        title: 'Email validated successfully',
        message: 'You have activated your account',
      };
    }
    return {
      title: 'Email validated successfully',
      message:
        'You have activated your account. Please check your email with a temporary password to proceed with the registration',
    };
  }
  if (status === 'Email updated successfully') {
    return {
      title: 'Email updated successfully',
      message: 'Please use your new email address to login',
    };
  }
  if (status === 'Link is expired') {
    return {
      title: 'Link is expired',
      message: (
        <>
          Click <b>Resend Email</b> button to get a new verification link
        </>
      ),
    };
  }
  if (status === 'Invalid link') {
    return {
      title: 'Invalid link',
      message: (
        <>
          This verification link has already been used.
          <br />
          Please try to log in again
        </>
      ),
    };
  }
  return {
    title: 'Invalid link',
    message: '',
  };
};
