import cn from 'classnames';
import { Checkbox } from 'components';
import styles from './ResetVariant.module.scss';

interface Props {
  name: string;
  title: any;
  type: string;
  setType: React.Dispatch<React.SetStateAction<string>>;
  Icon: any;
}

const ResetVariant = ({ name, title, type, setType, Icon }: Props) => (
  <button type="button" className={cn(styles.variant, { [styles.active]: type === name })}>
    <Checkbox
      id={`via-${name}`}
      isCircle
      checked={type === name}
      onChange={() => setType(name)}
      label={
        <>
          <Icon />
          <span className={styles.variantName}>{title}</span>
        </>
      }
      labelPosition="left"
    />
  </button>
);

export default ResetVariant;
