import ActiveDirectorySetup from 'components/ActiveDirectorySetup/ActiveDirectorySetup';
import NoSentryInstalled from './components/NoSentryInstalled/NoSentryInstalled';
import { IDomain } from 'interfaces';
import { getUserRole } from 'utils';
import { useAppSelector } from 'store';

interface Props {
  domainData: IDomain;
  isSentryDeleted?: boolean;
  pageName?: string;
}

const DomainStatusMsg = ({ domainData, isSentryDeleted, pageName }: Props) => {
  const { user } = useAppSelector((state) => state.user);
  const userRole = getUserRole(user);
  const activationCode = domainData.activationCode || domainData.domainActivationCode;

  if (isSentryDeleted) {
    return (
      <NoSentryInstalled
        pageName={pageName}
        activationCode={activationCode}
        downloadSentryLink={user.downloadSentryLink}
      />
    );
  }
  return <ActiveDirectorySetup domain={domainData} userRole={userRole} isCompanyPage={domainData.isCompanyPage} />;
};

export default DomainStatusMsg;
