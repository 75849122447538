import styles from './ShortcutMgmtHeader.module.scss';

const ShortcutMgmtHeader = () => (
  <div className={styles.shortcutHeader}>
    <h4 className={styles.shortcutHeaderTitle}>Desktop Shortcut is not currently enabled</h4>
    <p className={styles.shortcutHeaderDesc}>
      A Desktop Shortcut allows end users to shortcut credentials on computers it is created on. Please enable ONLY for
      trusted computers
    </p>
  </div>
);

export default ShortcutMgmtHeader;
