export const SHORTCUT_STATUSES = [
  {
    key: 0,
    value: 'All Desktop Shortcuts',
  },
  {
    key: 1,
    value: 'Desktop Shortcuts Enabled',
  },
  {
    key: 2,
    value: 'Desktop Shortcuts Disabled',
  },
];

export const MFA_STATUSES = [
  {
    key: 0,
    value: 'MFA Code Required ON',
  },
  {
    key: 1,
    value: 'MFA Code Required OFF',
  },
];
