import { useHistory } from 'react-router-dom';
import { Button, CountryListDropdown } from 'components';
import { notify } from 'utils';
import { requestVerificationCodeBySms } from 'store/slices/auth/mfa';
import { useAppDispatch, useAppSelector } from 'store';
import styles from './VerifyPhoneNumber.module.scss';
import { SerializedError } from '@reduxjs/toolkit';

interface Props {
  currentCountry: string;
  setCurrentCountry: (country: string) => void;
  phoneNumber: string;
  setPhoneNumber: (value: string) => void;
  dialCode: string;
}

const VerifyPhoneNumber = ({ currentCountry, setCurrentCountry, phoneNumber, setPhoneNumber, dialCode }: Props) => {
  const { isLoading } = useAppSelector((state) => state.mfa.requestVerificationCodeBySmsRequest);
  const { countries } = useAppSelector((state) => state.commonAuth);

  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleSetCurrentCountry = (value: string) => setCurrentCountry(value);

  const onSubmitPhoneNumber = async (e: React.FormEvent<HTMLFormElement>) => {
    if (phoneNumber && phoneNumber.length > 4) {
      try {
        e.preventDefault();
        await dispatch(
          requestVerificationCodeBySms({ phone: phoneNumber.replace(/\D+/g, ''), code: dialCode, isMfaSetup: true }),
        ).unwrap();
        notify.success('SMS has been sent');
        history.push('/setup-mfa/authentication-app/verification-code');
      } catch (err: any) {
        if ((err as SerializedError).code === '409') {
          notify.error(err.message);
          history.replace('/setup-mfa');
        } else {
          notify.error(err.message);
        }
      }
    }
  };

  return (
    <form className={styles.setupMfa} onSubmit={onSubmitPhoneNumber} noValidate>
      <div className={styles.setupMfaInfo}>Receive a code via text to enter on the next screen</div>
      <CountryListDropdown
        id="phone-code-dd"
        label="Enter Your Phone Number"
        options={countries}
        currentCountry={currentCountry}
        onChange={handleSetCurrentCountry}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
      />
      <Button
        type="submit"
        variant="primary"
        size="40"
        isLoading={isLoading}
        isDisabled={!(phoneNumber && phoneNumber.length > 4)}
        className={styles.setupMfaButton}>
        Get a Code
      </Button>
    </form>
  );
};

export default VerifyPhoneNumber;
