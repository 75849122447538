import cn from 'classnames';
import Link from 'components/Link/Link';
import { EmailIcon, MobilePhoneIcon, WebSiteIcon } from 'assets/img';
import { IBrandingContactInfo } from 'interfaces';
import styles from '../BrandedLinks.module.scss';

interface Props {
  contactInformation: IBrandingContactInfo;
}

const Support = ({ contactInformation }: Props) => {
  const { phoneNumber, emailAddress, website } = contactInformation;
  const validateText = (string: string) => {
    if (/(http(s?)):\/\//i.test(string)) {
      return string;
    }
    return `https://${string}`;
  };

  return (
    <ul className={cn(styles.linksDropdownList, styles.support)}>
      {phoneNumber && (
        <li>
          <MobilePhoneIcon />
          <span>{phoneNumber}</span>
        </li>
      )}
      {emailAddress && (
        <li>
          <EmailIcon />
          <Link href={`mailto:${emailAddress}`} className="truncate-text" external>
            <span>{emailAddress}</span>
          </Link>
        </li>
      )}
      {website && (
        <li>
          <WebSiteIcon />
          <Link href={validateText(website)} className="truncate-text" external>
            <span>{website}</span>
          </Link>
        </li>
      )}
    </ul>
  );
};

export default Support;
