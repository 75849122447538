import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Loader, Button } from 'components';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import { getStatusMessage } from './utils/getStatusMessage';
import { notify } from 'utils';
import { useAppDispatch, useAppSelector } from 'store';
import { resendWhoisValidationEmail, validateByWhois } from 'store/slices/auth/auth';
import styles from './DomainValidationResult.module.scss';

const EmailWhoisResult = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { publicKey }: { publicKey: string } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState('');

  const {
    isError,

    isLoading: isSending,
  } = useAppSelector((state) => state.auth.resendWhoisValidationEmailRequest);

  useEffect(() => {
    const fetchValidateByWhoisData = async () => {
      try {
        const data = await dispatch(validateByWhois(publicKey)).unwrap();
        setStatus(data);
      } catch (err: any) {
        setStatus(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchValidateByWhoisData();
  }, [publicKey]);

  const onResendEmail = async () => {
    await dispatch(resendWhoisValidationEmail(publicKey));
    notify.success('Verification link was resent');
  };

  const renderButton = () => {
    if (status === 'Your domain validated successfully') {
      return (
        <Button
          id="proceed-with-trugrid"
          className={styles.validationButton}
          onClick={() => window.location.replace('/')}
          size="48"
          variant="primary">
          Proceed to TruGrid
        </Button>
      );
    }
    if (status === 'Link is expired') {
      return (
        <Button
          id="resend-email"
          className={styles.validationButton}
          onClick={onResendEmail}
          size="48"
          variant="primary"
          isLoading={isSending}>
          Resend Email
        </Button>
      );
    }
    return (
      <Button
        id="login-next"
        className={styles.validationButton}
        onClick={() => history.replace('/')}
        size="48"
        variant="primary">
        Login
      </Button>
    );
  };

  if (isLoading) return <Loader id="loading-domain-validation" />;

  if (isError) {
    return <div className={styles.validationInfo}>The page is not available. Please try to login again</div>;
  }

  const { title, message } = getStatusMessage(status);

  return (
    <>
      <LoginHeader title={title} hideBack hideSteps />
      <div className={styles.validationInfo}>{message}</div>
      {renderButton()}
      {status === 'Link is expired' && (
        <div className={styles.validationWrap}>
          <Button variant="link" onClick={() => history.replace('/login')}>
            Back to Login
          </Button>
        </div>
      )}
    </>
  );
};

export default EmailWhoisResult;
