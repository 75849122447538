import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Link } from 'components';
import { ArrowLeftIcon, RememberMeIcon, ShorcutIcon, StaySignInIcon, TimeoutManagementIcon } from 'assets/img';
import { useAppSelector } from 'store';
import { useBrandingColors } from 'hooks';
import styles from './DesktopManagement.module.scss';

interface IPages {
  id: string;
  title: string;
  Icon: React.FC;
  description: string;
  uri: string;
}

interface Props {
  isDomainOnPremise: boolean;
}

const DesktopManagement = ({ isDomainOnPremise }: Props) => {
  const { branding } = useAppSelector((state) => state.user);
  const { url } = useRouteMatch();

  const colorStyles = useBrandingColors();

  const [pages, setPages] = useState<IPages[]>([
    {
      id: '0',
      title: 'Desktop Shortcut',
      Icon: ShorcutIcon,
      description:
        'A Desktop Shortcut allows end users to remember credentials on computers it is created on. Please enable ONLY for trusted computers',
      uri: 'shortcut-management',
    },
  ]);

  useEffect(() => {
    setPages((prevState) => [
      ...prevState,
      {
        id: '1',
        title: 'Desktop Connector Session Timeout',
        Icon: TimeoutManagementIcon,
        description: 'Determine if end user Desktop Connector sessions should time out or not',
        uri: 'timeout-management',
      },
    ]);
    if (isDomainOnPremise) {
      setPages((prevState) => [
        ...prevState,
        {
          id: '2',
          title: 'Remember Me',
          Icon: RememberMeIcon,
          description: 'Allow users to remember login and password',
          uri: 'remember-me',
        },
      ]);
    }
    if (!isDomainOnPremise) {
      setPages((prevState) => [
        ...prevState,
        {
          id: '2',
          title: 'Entra ID Login Management',
          Icon: StaySignInIcon,
          description:
            'This functionality allows Entra ID Users to stay signed in while using TruGrid Desktop Connector',
          uri: 'azure-management',
        },
      ]);
    }
  }, [isDomainOnPremise]);

  return (
    <div style={{ paddingTop: '1em' }}>
      {pages.map(({ id, title, Icon, description, uri }) => (
        <div className={styles.pageItem} id={id} key={id}>
          <div className={styles.pageItemWrap}>
            <div>
              <Icon />
            </div>
            <div>
              <h3 className={styles.pageItemTitle}>{title}</h3>
              <p className={styles.pageItemDescription}>{description}</p>
            </div>
          </div>
          <div>
            <Link to={`${url}/${uri}`} className={styles.pageItemButton} style={branding?.isActive ? colorStyles : {}}>
              Manage <ArrowLeftIcon />
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DesktopManagement;
