import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { http, createHttpRequestInitResult, createExtraReducersForResponses } from 'helpers/http';
import { IHttpRequestResult, IPageMeta, IQueryConfig } from 'interfaces';
import { RootState } from 'store/store';
import { queryFormatter } from 'utils';

export interface IResetPhoneUsers {
  userId: string;
  upn: string;
  displayName: string;
  hasPhone: boolean;
  phoneNumber: string;
  phoneCountryCode: string;
  phoneSetup: number;
  phoneSetupEnabled: boolean;
}

export interface IResetPhoneData {
  data?: IResetPhoneUsers[];
  pageMeta?: IPageMeta;
  queryConfig?: IQueryConfig;
}

interface IGetResetPhoneDataPayload {
  domainId: string;
  query?: IQueryConfig;
  _background?: boolean;
}

interface ITogglePhoneSetupStatusPayload {
  userIds: string[];
  enabled: boolean;
  domainId: string;
}

export const getResetPhoneData = createAsyncThunk(
  'phoneSetup/getResetPhoneData',
  async ({ domainId, query, _background }: IGetResetPhoneDataPayload, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const queryConfig = state.phoneSetup.resetPhoneData?.queryConfig || {};
    const queryConfigNew = { ...queryConfig, ...query };
    const baseUrl = `/api/SecurityManagement/AdPasswordReset/GetPhoneInformation?domainId=${domainId}`;
    const response: AxiosResponse = await http.get(queryFormatter(baseUrl, queryConfigNew));
    const pageMeta: IPageMeta = response?.headers['x-pagination'] ? JSON.parse(response.headers['x-pagination']) : null;
    const data: IResetPhoneData = {
      data: response.data,
      pageMeta,
      queryConfig: queryConfigNew,
    };
    return data;
  },
);

export const togglePhoneSetupStatus = createAsyncThunk(
  'phoneSetup/togglePhoneSetupStatus',
  async ({ userIds, enabled, domainId }: ITogglePhoneSetupStatusPayload, thunkAPI) => {
    await http.post('/api/SecurityManagement/AdPasswordReset/ChangePhoneSetupState', {
      userIds,
      enabled,
      domainId,
    });
    const query = { pageNumber: 1 };
    await thunkAPI.dispatch(getResetPhoneData({ domainId, query, _background: true }));
  },
);

export const resetPhoneSetupStatus = createAsyncThunk(
  'phoneSetup/resetPhoneSetupStatus',
  async ({ userIds, domainId }: { userIds: string[]; domainId: string }, thunkAPI) => {
    await http.post('/api/SecurityManagement/AdPasswordReset/ResetPhoneNumbers', {
      userIds,
      domainId,
    });
    const query = { pageNumber: 1 };
    await thunkAPI.dispatch(getResetPhoneData({ domainId, query, _background: true }));
  },
);

interface PhoneSetupState {
  resetPhoneData: IResetPhoneData | null;
  getResetPhoneDataRequest: IHttpRequestResult<IResetPhoneData>;
  togglePhoneSetupStatusRequest: IHttpRequestResult<AxiosResponse>;
  resetPhoneSetupStatusRequest: IHttpRequestResult<AxiosResponse>;
}

const initialState: PhoneSetupState = {
  resetPhoneData: null,
  getResetPhoneDataRequest: createHttpRequestInitResult(),
  togglePhoneSetupStatusRequest: createHttpRequestInitResult(),
  resetPhoneSetupStatusRequest: createHttpRequestInitResult(),
};

export const phoneSetupSlice = createSlice({
  name: 'phoneSetup',
  initialState,
  reducers: {
    updateResetPhoneData: (state, { payload }) => {
      state.resetPhoneData = payload;
    },
  },
  extraReducers: (builder) => {
    createExtraReducersForResponses<PhoneSetupState>(
      builder,
      getResetPhoneData,
      'getResetPhoneDataRequest',
      (state, { payload }) => {
        state.resetPhoneData = payload;
      },
    );
    createExtraReducersForResponses<PhoneSetupState>(builder, togglePhoneSetupStatus, 'togglePhoneSetupStatusRequest');
    createExtraReducersForResponses<PhoneSetupState>(builder, resetPhoneSetupStatus, 'resetPhoneSetupStatusRequest');
  },
});

export const { updateResetPhoneData } = phoneSetupSlice.actions;

export default phoneSetupSlice.reducer;
