import { ArrowLeftIcon } from 'assets/img';
import { Button, IdleBoxLogin } from 'components';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './LoginHeader.module.scss';

interface Props {
  hideSteps?: boolean;
  hideBack?: boolean;
  title: string;
  currentStep?: string;
  stepCount?: string;
  idle?: boolean;
  back?: () => void;
}

const LoginHeader = ({ hideSteps, hideBack, title, currentStep, stepCount, idle, back }: Props) => {
  const history = useHistory();
  const { key } = useLocation();
  const goBack = () => (back ? back() : key ? history.goBack() : history.replace('/login'));

  return (
    <div className={styles.loginHeader}>
      {!hideBack && (
        <Button variant="link" icon={<ArrowLeftIcon />} onClick={goBack} className={styles.loginHeaderBack} />
      )}
      {!hideSteps && (
        <div className={styles.loginHeaderStep}>
          Step {currentStep} of {stepCount}
        </div>
      )}
      <h3 className={styles.loginHeaderTitle}>{title}</h3>
      {idle && (
        <div className={styles.loginHeaderIdle}>
          <IdleBoxLogin />
        </div>
      )}
    </div>
  );
};

export default LoginHeader;
