import { forwardRef, useState } from 'react';
import cn from 'classnames';
import { CloseIcon } from 'assets/img';
import styles from './ClearInput.module.scss';

interface Props {
  id: string;
  type: string;
  value: number;
  min?: number;
  max: number;
  placeholder: string;
  validationRule: (value: number) => string;
  onChange: (val: string) => void;
  onClear: () => void;
  ref?: any;
  className: any;
}

const ClearInput = forwardRef<HTMLInputElement, Props>(
  ({ id, type, value, min, max, placeholder, validationRule, onChange, onClear, className }: Props, ref) => {
    const [isErrorMsg, setIsErrorMsg] = useState('');

    const onBlurHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (validationRule) {
        const isValidMsg = validationRule(+e.target.value);
        setIsErrorMsg(isValidMsg);
      }
    };

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (type === 'number' && e.target.value) {
        if (!e.target.value.match(/^\d+$/g)) {
          return false;
        }
      }
      const val = e.target.value.slice(0, max);
      onChange(val);
    };

    const onClearHandler = () => {
      setIsErrorMsg('');
      onClear();
    };

    return (
      <div className={cn(styles.clearInput, { [styles.clearInputError]: isErrorMsg, [className]: className })}>
        <div className={styles.clearInputWrapper}>
          <input
            id={id}
            ref={ref}
            type={type}
            value={value === 0 ? '' : value}
            minLength={min || 0}
            maxLength={max}
            placeholder={placeholder}
            onChange={onChangeHandler}
            onBlur={onBlurHandler}
            className={styles.clearInputWrapperInput}
          />
          {!!value && <CloseIcon onClick={onClearHandler} />}
        </div>
        {!!isErrorMsg && <span className={styles.clearInputErrorMsg}>{isErrorMsg}</span>}
      </div>
    );
  },
);

ClearInput.displayName = 'ClearInput';

export default ClearInput;
