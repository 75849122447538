import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CodeInput, ResendCode } from 'components';
import { useTimer } from 'hooks';
import { notify } from 'utils';
import { useAppDispatch, useAppSelector } from 'store';
import { checkMfaVerificationCode, requestVerificationCodeBySms } from 'store/slices/auth/mfa';
import { SerializedError } from '@reduxjs/toolkit';
import { changeUserMfa } from 'store/slices/userSlice';
import styles from './VerificationCode.module.scss';

interface Props {
  phoneNumber: string;
  dialCode: string;
}

const VerificationCode = ({ phoneNumber, dialCode }: Props) => {
  const { user } = useAppSelector((state) => state.user);
  const { isLoading } = useAppSelector((state) => state.mfa.checkMfaVerificationCodeRequest);
  const { isLoading: isSending } = useAppSelector((state) => state.mfa.requestVerificationCodeBySmsRequest);

  const { phoneMask, pinHash } = useAppSelector((state) => state.mfa);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [codeValue, setCodeValue] = useState('');
  const [isError, setIsError] = useState('');

  const clearError = () => setIsError('');
  const { timeLeft, resetCountdown } = useTimer(60);

  const onResendPhoneNumber = async () => {
    try {
      await dispatch(requestVerificationCodeBySms({ phone: phoneNumber, code: dialCode, isMfaSetup: true })).unwrap();
      resetCountdown(60);
      notify.success('SMS has been sent');
    } catch (err: any) {
      if ((err as SerializedError).code === '409') {
        notify.error(err.message);
        history.replace('/setup-mfa');
      } else {
        notify.error(err.message);
      }
    }
  };

  const onSubmitCode = async () => {
    try {
      await dispatch(checkMfaVerificationCode({ mfaPin: codeValue, mfaHash: pinHash })).unwrap();
      dispatch(changeUserMfa('app'));
      notify.success('Verification Successful. MFA Setup Completed');
      if (user.roles.includes('trugridadmin')) {
        return window.location.replace('/admin');
      }
    } catch (err: any) {
      setIsError(err.message);
    }
  };

  return (
    <div className={styles.setupMfa}>
      <div className={styles.setupMfaInfo}>
        We've sent a verification code to your phone at <strong>{phoneMask}</strong>
      </div>
      <div className={styles.setupMfaWrap}>
        <div className={styles.setupMfaLabel}>Enter Verification Code</div>
        <CodeInput
          fields={4}
          isError={!!isError}
          errorMessage={isError}
          clearError={clearError}
          isDisable={isSending || isLoading}
          value={codeValue}
          setValue={setCodeValue}
          handleSend={onSubmitCode}
        />
        <div className={styles.setupMfaResend}>
          <ResendCode countDown={timeLeft} handleSend={onResendPhoneNumber} isDisabled={isSending || isLoading} />
        </div>
      </div>
    </div>
  );
};

export default VerificationCode;
