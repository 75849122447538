import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { checkIsPasswordValid } from 'helpers';
import { Button, PasswordComplexity, TextInput } from 'components';
import { useOutsideClick } from 'hooks';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import { notify } from 'utils';
import { useAppDispatch, useAppSelector } from 'store';
import { getInitialUserData } from 'store/slices/userSlice';
import { changeTempPassword } from 'store/slices/auth/resetPassword';
import styles from './ChangeTempPassword.module.scss';

const ChangeTempPassword = () => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.resetPassword.changeTempPasswordRequest);
  const { email, signInData } = useAppSelector((state) => state.auth);
  const { mfaShouldBeSetup } = signInData;
  const history = useHistory();

  const [formValues, setFormValues] = useState(['', '', '']);
  const [formErrors, setFormErrors] = useState(['', '', '']);
  const tempPassRef = useRef<HTMLInputElement | null>(null);
  const newPassRef = useRef<HTMLInputElement | null>(null);
  const confirmPassRef = useRef<HTMLInputElement | null>(null);
  const tooltipPassRef = useRef<HTMLDivElement | null>(null);

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  useOutsideClick(tooltipPassRef, () => setIsTooltipOpen(false));

  useEffect(() => {
    if (email) {
      setTimeout(() => tempPassRef.current?.focus(), 0);
      setTimeout(
        () => notify.success('Your account is activated. Please check email to proceed with the registration'),
        500,
      );
    }
  }, []);

  const onUpdate = (value: string, index: number) => {
    const passValuesNew = formValues.map((i, ind) => (index === ind ? value : i));
    setFormValues(passValuesNew);
  };

  const onClearError = (index: number) => {
    if (index === 1) {
      setIsTooltipOpen(true);
    }
    if (index === 1 || index === 2) {
      const passErrorsNew = [...formErrors];
      passErrorsNew[1] = '';
      passErrorsNew[2] = '';
      setFormErrors(passErrorsNew);
    } else {
      const passErrorsNew = formErrors.map((i, ind) => (index === ind ? '' : i));
      setFormErrors(passErrorsNew);
    }
  };

  const onClearValue = (index: number, ref: React.RefObject<HTMLInputElement>) => {
    ref.current?.focus();
    const passValuesNew = formValues.map((i, ind) => (index === ind ? '' : i));
    setFormValues(passValuesNew);

    if (formErrors[index]) onClearError(index);
  };

  const isFormValid = () => {
    const formErrorsNew = [...formErrors];

    const isNewPassValid = checkIsPasswordValid(formValues[1]);
    const isConfirmPassValid = formValues[2] && formValues[1] === formValues[2];

    if (!isNewPassValid) {
      formErrorsNew[1] = ' ';
      formErrorsNew[2] = 'Password does not match requirements';
    }
    if (isNewPassValid && !isConfirmPassValid) {
      formErrorsNew[1] = ' ';
      formErrorsNew[2] = 'Passwords do not match';
    }

    if (formErrorsNew.length) {
      setFormErrors(formErrorsNew);
    }

    return !formErrorsNew.some((i) => i);
  };

  const loginUser = async () => {
    try {
      await dispatch(getInitialUserData({ _background: false })).unwrap();
      if (mfaShouldBeSetup) {
        return history.replace('/setup-mfa');
      }
      return history.replace('/workspace');
    } catch (error: any) {
      notify.error(error.message);
    }
  };

  const onLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isFormValid()) {
      try {
        await dispatch(changeTempPassword({ email, tempPassword: formValues[0], newPassword: formValues[1] })).unwrap();
        notify.success('Your password was successfully updated');
        await loginUser();
      } catch (err: any) {
        if (err.message) {
          const formErrorsNew = [...formErrors];
          formErrorsNew[0] = err.message;
          if (formErrorsNew.length) {
            setFormErrors(formErrorsNew);
          }
        } else {
          notify.error(err.message);
        }
      }
    }
  };

  return (
    <div className={styles.password}>
      <LoginHeader title="Update your password" hideSteps back={() => history.replace('/')} />
      <form method="POST" onSubmit={onLogin}>
        <div className={styles.passwordInputWrap}>
          <TextInput
            ref={tempPassRef}
            id="enter-temporary-password"
            placeholder="Enter Current Password"
            type="password"
            hasView
            value={formValues[0]}
            error={formErrors[0]}
            onChange={(e) => onUpdate(e.target.value, 0)}
            onClear={() => onClearValue(0, tempPassRef)}
            onFocus={() => onClearError(0)}
            autoComplete="current-password"
          />
        </div>
        <div className={styles.passwordInputWrap} ref={tooltipPassRef}>
          <TextInput
            ref={newPassRef}
            id="enter-new-password"
            placeholder="Enter New Password"
            type="password"
            hasView
            value={formValues[1]}
            error={formErrors[1]}
            onChange={(e) => onUpdate(e.target.value, 1)}
            onClear={() => onClearValue(1, newPassRef)}
            onFocus={() => onClearError(1)}
            autoComplete="new-password"
          />
          {isTooltipOpen && <PasswordComplexity inputValue={formValues[1]} />}
        </div>
        <div className={styles.passwordInputWrap}>
          <TextInput
            ref={confirmPassRef}
            id="confirm-new-password"
            placeholder="Confirm New Password"
            type="password"
            hasView
            value={formValues[2]}
            error={formErrors[2]}
            onChange={(e) => onUpdate(e.target.value, 2)}
            onClear={() => onClearValue(2, confirmPassRef)}
            onFocus={() => onClearError(2)}
            autoComplete="new-password"
          />
        </div>
        <Button
          id="update-temp-password"
          size="48"
          variant="primary"
          type="submit"
          isDisabled={formValues.includes('')}
          isLoading={isLoading}
          className={styles.passwordButton}>
          Update
        </Button>
      </form>
    </div>
  );
};

export default ChangeTempPassword;
