import { Alert, Link } from 'components';
import { DownloadIcon, TriangleFilledIcon } from 'assets/img';
import styles from './UpdateSentryAlert.module.scss';

const UpdateSentryAlert = () => (
  <div className={styles.warning}>
    <Alert type="warning" icon={<TriangleFilledIcon />}>
      To enable&nbsp;<strong>Password Management</strong>, please&nbsp;
      <Link href="https://www.trugrid.com/downloads/sentry" external bold>
        download <DownloadIcon />
      </Link>
      &nbsp; and update Sentry version
    </Alert>
  </div>
);

export default UpdateSentryAlert;
