import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AsyncThunkConfig, GetThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { AxiosResponse } from 'axios';
import { replaceSpecChar } from 'helpers';
import { createExtraReducersForResponses, createHttpRequestInitResult, http } from 'helpers/http';
import { IDomain, IHttpRequestResult, IPageMeta, IQueryConfig, IUser } from 'interfaces';
import { IModalData } from 'pages/ResourceAssignments/ResourceAssignments';
import { queryFormatter } from 'utils';
import { onUpdateAppPublishing, updateUserData } from './userSlice';
import { ICustomerDomainsData, getCustomerDomains, onUpdateDomainAppPublishing } from './customerDomains';
import { RootState } from 'store/store';

export interface IResourceAssignmentsItem {
  resourceId: string;
  name: string;
  assignmentsCount: number;
  domainName: string;
  isPooled: boolean;
}

export interface IResourceAssignmentsData {
  data: IResourceAssignmentsItem[];
  pageMeta: IPageMeta;
  queryConfig: IQueryConfig;
}

export interface IAssigmentsList {
  resourceId: string;
  resourceName: string;
  isAssigned: boolean;
}

export interface IUpdateAssigmentsPayload {
  data: IModalData;
  assignments: IAssigmentsList[];
}

interface IRefreshAssignmentsDataPayload {
  type: 'company' | 'customer';
}

interface IGetAssignmentsPayload {
  domainId: string;
  query?: IQueryConfig;
  _background?: boolean;
}

export const getDesktopAssignments = createAsyncThunk(
  'resourceAssignments/getDesktopAssignments',
  async ({ domainId, query, _background = false }: IGetAssignmentsPayload, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const queryConfig = state.resourceAssignments.desktopAssignmentsData?.queryConfig || {};
    const queryConfigNew = { ...queryConfig, ...query };
    const baseUrl = `/api/ResourceAssignments/GetDesktopAssignments?domainId=${domainId}`;
    const response: AxiosResponse = await http.get(queryFormatter(baseUrl, queryConfigNew));
    const pageMeta = response?.headers['x-pagination'] ? JSON.parse(response.headers['x-pagination']) : null;
    const data: IResourceAssignmentsData = {
      data: response.data,
      pageMeta,
      queryConfig: queryConfigNew,
    };
    return data;
  },
);

export const getAppAssignments = createAsyncThunk(
  'resourceAssignments/getAppAssignments',
  async ({ domainId, query, _background = false }: IGetAssignmentsPayload, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const queryConfig = state.resourceAssignments.appAssignmentsData?.queryConfig || {};
    const queryConfigNew = { ...queryConfig, ...query };
    const baseUrl = `/api/ResourceAssignments/GetAppAssignments?domainId=${domainId}`;
    const response: AxiosResponse = await http.get(queryFormatter(baseUrl, queryConfigNew));
    const pageMeta = response?.headers['x-pagination'] ? JSON.parse(response.headers['x-pagination']) : null;
    const data: IResourceAssignmentsData = {
      data: response.data,
      pageMeta,
      queryConfig: queryConfigNew,
    };
    return data;
  },
);

export const getAssigmentsList = createAsyncThunk('resourceAssignments/getAssigmentsList', async (data: IModalData) => {
  const appName = replaceSpecChar(data.resourceName);
  const url =
    data.assignType === 'Computer'
      ? `/api/ResourceAssignments/GetDesktopDirectAssignments`
      : `/api/ResourceAssignments/GetAppDirectAssignments`;
  const params: { domainId: string; resourceId: string; type: string; isPooled: boolean; appName?: string } = {
    domainId: data.domainId,
    resourceId: data.resourceId,
    type: data.viewBy,
    isPooled: data.isPooled,
  };
  if (data.assignType !== 'Computer') {
    params.appName = appName;
  }
  const response: AxiosResponse<IAssigmentsList[]> = await http.get(url, {
    params,
  });
  return response.data;
});

export const updateAssigmentsList = createAsyncThunk(
  'resourceAssignments/updateAssigments',
  async ({ data, assignments }: IUpdateAssigmentsPayload) => {
    const appName = replaceSpecChar(data.resourceName);
    const params: { resourceId: string; type: string; isPooled: boolean; appName?: string } = {
      resourceId: data.resourceId,
      type: data.viewBy,
      isPooled: data.isPooled,
    };
    if (data.type !== 'Computer') {
      params.appName = appName;
    }
    const url =
      data.assignType === 'Computer'
        ? `/api/ResourceAssignments/UpdateDesktopDirectAssignments`
        : `/api/ResourceAssignments/UpdateAppDirectAssignments`;
    await http.put(url, assignments, {
      params,
    });
  },
);

export const refreshAzureAdData = createAsyncThunk(
  'resourceAssignments/refreshAzureAdData',
  async (
    {
      domainId,
      authToken,
      refreshResourses = true,
    }: { domainId: string; authToken: string; refreshResourses?: boolean },
    thunkAPI: GetThunkAPI<AsyncThunkConfig>,
  ) => {
    await http.post('/api/Domains/ForcedAzureAdDataRefresh', { domainId, authToken });
    if (refreshResourses) {
      await Promise.all([
        thunkAPI.dispatch(getDesktopAssignments({ domainId, _background: true })).unwrap(),
        thunkAPI.dispatch(getAppAssignments({ domainId, _background: true })).unwrap(),
      ]);
    }
  },
);

export interface IRefreshOnPremiseDataRepsonse {
  status: number;
  notify: string;
}

export const refreshOnPremiseData = createAsyncThunk(
  'resourceAssignments/refreshOnPremiseData',
  async ({ domainId }: { domainId: string }, thunkAPI: GetThunkAPI<AsyncThunkConfig>) => {
    const response: AxiosResponse<string | void> = await http.post(
      `/api/Domains/ForcedAgentDataRefresh?domainId=${domainId}`,
    );
    if (response.status === 206) {
      const data: IRefreshOnPremiseDataRepsonse = {
        status: response.status,
        notify: response.data || '',
      };
      return data;
    } else {
      return thunkAPI.dispatch(getDesktopAssignments({ domainId, _background: true })).unwrap();
    }
  },
);

export const refreshAppData = createAsyncThunk(
  'resourceAssignments/refreshAppData',
  async ({ domainId }: { domainId: string }, thunkAPI: GetThunkAPI<AsyncThunkConfig>) => {
    const response: AxiosResponse<string | void> = await http.post(
      `/api/Domains/ForcedAgentLocalAppsDataRefresh?domainId=${domainId}`,
    );
    if (response.status === 206) {
      return response;
    } else {
      return thunkAPI.dispatch(getAppAssignments({ domainId, _background: true })).unwrap();
    }
  },
);

export const toggleAppPublishing = createAsyncThunk(
  'resourceAssignments/toggleAppPublishing',
  async ({ domainId, isCompanyPage }: { domainId: string; isCompanyPage: boolean }, thunkAPI) => {
    const state: RootState = thunkAPI.getState() as RootState;
    const flag = isCompanyPage
      ? state.user?.domain?.isAppPublishingEnabled
      : state.customerDomains.selectedDomain?.isAppPublishingEnabled;
    await http.post('/api/domains/toggleAppPublishing', { id: domainId, flag: !flag });
    if (isCompanyPage) {
      thunkAPI.dispatch(onUpdateAppPublishing({ isAppPublishingEnabled: !flag, appPublishingCurrent: true }));
    } else {
      thunkAPI.dispatch(
        onUpdateDomainAppPublishing({ domainId, isAppPublishingEnabled: !flag, appPublishingCurrent: true }),
      );
    }
  },
);

export const refreshAssignmentsData = createAsyncThunk(
  'resourceAssignments/refreshAssignmentsData',
  async ({ type }: IRefreshAssignmentsDataPayload, thunkAPI) => {
    if (type === 'company') {
      const data = await thunkAPI.dispatch(updateUserData({ _background: true })).unwrap();
      return data;
    }
    if (type === 'customer') {
      const response: ICustomerDomainsData = await thunkAPI.dispatch(getCustomerDomains({})).unwrap();
      const data: IDomain[] = response.data;
      return data;
    }
  },
);

interface IResourceAssignmentsState {
  desktopAssignmentsData: IResourceAssignmentsData | null;
  appAssignmentsData: IResourceAssignmentsData | null;
  getDesktopAssignmentsRequest: IHttpRequestResult<IResourceAssignmentsData>;
  getAppAssignmentsRequest: IHttpRequestResult<IResourceAssignmentsData>;
  getAssigmentsListRequest: IHttpRequestResult<IAssigmentsList[]>;
  updateAssigmentsListRequest: IHttpRequestResult<void>;
  refreshAzureAdDataRequest: IHttpRequestResult<void>;
  refreshOnPremiseDataRequest: IHttpRequestResult<string | void>;
  refreshAppDataRequest: IHttpRequestResult<string | void>;
  toggleAppPublishingRequest: IHttpRequestResult<void>;
  refreshAssignmentsDataRequest: IHttpRequestResult<IUser | IDomain>;
}

const initialState: IResourceAssignmentsState = {
  desktopAssignmentsData: null,
  appAssignmentsData: null,
  getDesktopAssignmentsRequest: createHttpRequestInitResult(),
  getAppAssignmentsRequest: createHttpRequestInitResult(),
  getAssigmentsListRequest: createHttpRequestInitResult(),
  updateAssigmentsListRequest: createHttpRequestInitResult(),
  refreshAzureAdDataRequest: createHttpRequestInitResult(),
  refreshOnPremiseDataRequest: createHttpRequestInitResult(),
  refreshAppDataRequest: createHttpRequestInitResult(),
  toggleAppPublishingRequest: createHttpRequestInitResult(),
  refreshAssignmentsDataRequest: createHttpRequestInitResult(),
};

export const resourceAssignmentsSlice = createSlice({
  name: 'ResourceAssignments',
  initialState,
  reducers: {
    disableAppAssignments: (state) => {
      state.appAssignmentsData = null;
    },
  },
  extraReducers: (builder) => {
    createExtraReducersForResponses<IResourceAssignmentsState>(
      builder,
      getDesktopAssignments,
      'getDesktopAssignmentsRequest',
      (state, { payload }) => {
        state.desktopAssignmentsData = payload;
      },
    );
    createExtraReducersForResponses<IResourceAssignmentsState>(
      builder,
      getAppAssignments,
      'getAppAssignmentsRequest',
      (state, { payload }) => {
        state.appAssignmentsData = payload;
      },
    );
    createExtraReducersForResponses<IResourceAssignmentsState>(builder, getAssigmentsList, 'getAssigmentsListRequest');
    createExtraReducersForResponses<IResourceAssignmentsState>(
      builder,
      updateAssigmentsList,
      'updateAssigmentsListRequest',
      (state, action) => {
        const assignments: IAssigmentsList[] = action.meta.arg.assignments;
        const modalData: IModalData = action.meta.arg.data;
        const count = assignments?.reduce((prev, cur) => (cur.isAssigned ? ++prev : prev), 0);
        if (modalData.assignType === 'Computer') {
          state.desktopAssignmentsData = {
            ...state.desktopAssignmentsData!,
            data: state.desktopAssignmentsData!.data.map((assignment) => {
              if (assignment.resourceId === modalData.resourceId) {
                return {
                  ...assignment,
                  assignmentsCount: count,
                };
              }
              return assignment;
            }),
          };
        } else {
          state.appAssignmentsData = {
            ...state.appAssignmentsData!,
            data: state.appAssignmentsData!.data.map((assignment) => {
              if (assignment.resourceId === modalData.resourceId) {
                return {
                  ...assignment,
                  assignmentsCount: count,
                };
              }
              return assignment;
            }),
          };
        }
      },
    );
    createExtraReducersForResponses<IResourceAssignmentsState>(
      builder,
      refreshAzureAdData,
      'refreshAzureAdDataRequest',
    );
    createExtraReducersForResponses<IResourceAssignmentsState>(
      builder,
      refreshOnPremiseData,
      'refreshOnPremiseDataRequest',
    );
    createExtraReducersForResponses<IResourceAssignmentsState>(builder, refreshAppData, 'refreshAppDataRequest');
    createExtraReducersForResponses<IResourceAssignmentsState>(
      builder,
      toggleAppPublishing,
      'toggleAppPublishingRequest',
    );
    createExtraReducersForResponses<IResourceAssignmentsState>(
      builder,
      refreshAssignmentsData,
      'refreshAssignmentsDataRequest',
    );
  },
});

export const { disableAppAssignments } = resourceAssignmentsSlice.actions;

export default resourceAssignmentsSlice.reducer;
