import Modal from 'components/Modal/Modal';

interface Props {
  type: string;
  isDomainHybryd: boolean;
  domain: string;
  isResetLoading: boolean;
  isOpen: boolean;
  toggleShowModal: (type?: string) => void;
  disconnectAzure: () => void;
  disconnectOnPremise: () => void;
}

const ResetAdModal = ({
  type,
  isDomainHybryd,
  domain,
  isResetLoading,
  isOpen,
  toggleShowModal,
  disconnectAzure,
  disconnectOnPremise,
}: Props) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={toggleShowModal}
    contentLabel={type === 'azure' ? 'Reset Entra ID from TruGrid?' : 'Reset Domain AD?'}
    actionFunc={type === 'azure' ? disconnectAzure : disconnectOnPremise}
    actionTitle={isDomainHybryd && type !== 'azure' ? 'Confirm' : 'Reset'}
    variantBtn="deleteOutline"
    actionLoading={isResetLoading}>
    {type === 'azure' ? (
      <p>
        Entra ID domain <strong>{domain}</strong> will be disconnected from TruGrid. Are you sure?
      </p>
    ) : isDomainHybryd ? (
      <>
        <p style={{ marginBottom: '9px' }}>
          Domain <strong>{domain}</strong> will be removed from this Hybrid Connection. All records and assignments
          about this Active Directory domain will be deleted from TruGrid
        </p>
        <p>
          Please click <strong>CONFIRM</strong> to authenticate into Entra ID to complete this request
        </p>
      </>
    ) : (
      <p>
        Domain <strong>{domain}</strong> will be reset to Pending Activation status. All records and assignments about
        this Active Directory domain will be deleted from TruGrid. Are you sure?
      </p>
    )}
  </Modal>
);

export default ResetAdModal;
