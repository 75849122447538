import { IRememberMeUser } from 'store/slices/securityMgmt/remebmerMe';
import { truncateString } from 'utils';

export const getNotifyMessage = (
  selectedUsers: IRememberMeUser[],
  confirmModalData: IRememberMeUser | IRememberMeUser[],
): string => {
  if (selectedUsers.length >= 1) {
    if (selectedUsers.length > 1) {
      const actionType = (confirmModalData as IRememberMeUser[]).every((i: IRememberMeUser) => i.isRememberMeEnabled)
        ? 'disabled'
        : 'enabled';
      return `Remember Me is ${actionType} for selected users`;
    } else {
      const actionType = (confirmModalData as IRememberMeUser[]).every((i) => i.isRememberMeEnabled)
        ? 'disabled'
        : 'enabled';
      return `Remember Me is ${actionType} for ${truncateString(selectedUsers[0].displayName, false)}`;
    }
  } else {
    const user = confirmModalData as IRememberMeUser;
    return `Remember Me is ${user.isRememberMeEnabled ? 'disabled' : 'enabled'} for ${truncateString(
      user.displayName,
      false,
    )}`;
  }
};

export const getActionType = (selectedUsers: IRememberMeUser[]): string => {
  const actionType = selectedUsers.every((i: IRememberMeUser) => i.isRememberMeEnabled) ? 'disabled' : 'enabled';
  return actionType;
};

export const shouldShowEnableRememberMeForUserModal = (selectedUsers: IRememberMeUser[]): boolean => {
  const actionType = getActionType(selectedUsers);
  const hasUserWithDisabledMfa = selectedUsers.some((i) => !i.isMfaEnabled);
  return hasUserWithDisabledMfa && actionType === 'enabled';
};
