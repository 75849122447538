import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { createExtraReducersForResponses, createHttpRequestInitResult, http } from 'helpers/http';
import { IHttpRequestResult } from 'interfaces';
import { RootState } from 'store/store';

export interface PasswordResetOptions {
  isResetByMfaAvailable: boolean;
  phoneMask: string;
  emailMask: string;
}

export const sendRegisteredUserForgotPasswordEmail = createAsyncThunk(
  'resetPassword/sendRegisteredUserForgotPasswordEmail',
  async (userId: string) => {
    await http.post('/api/RegisteredUser/ForgotPassword', userId);
  },
);

export const getAdPasswordReset = createAsyncThunk('resetPassword/getAdPasswordReset', async (userId: string) => {
  const response: AxiosResponse<PasswordResetOptions> = await http.get(
    `/api/adPasswordReset/ForgotPassword?userId=${userId}`,
  );
  return response.data;
});

export const validateResetByEmail = createAsyncThunk('resetPassword/validateResetByEmail', async (token: string) => {
  const response: AxiosResponse<string> = await http.get(`/api/AdPasswordReset/ValidateResetByEmail?token=${token}`);
  return response.data;
});

export const resetPasswordVerifyMfaCode = createAsyncThunk(
  'resetPassword/resetPasswordVerifyMfaCode',
  async ({ userId, mfaCode }: { userId: string; mfaCode: string }) => {
    await http.post('/api/adPasswordReset/VerifyMfaCode', { userId, mfaCode });
  },
);

export const resetPasswordVerifyPhone = createAsyncThunk(
  'resetPassword/resetPasswordVerifyPhone',
  async ({ userId, lastFour }: { userId: string; lastFour: string }) => {
    const response: AxiosResponse<string> = await http.post('/api/AdPasswordReset/VerifyLastFourDigits', {
      userId,
      lastFour,
    });
    return response.data;
  },
);

export const resetPasswordVerifySmsCode = createAsyncThunk(
  'resetPassword/resetPasswordVerifySmsCode',
  async ({ userId, smsHash, smsCode }: { userId: string; smsHash: string; smsCode: string }) => {
    await http.post('/api/AdPasswordReset/VerifySmsCode', { userId, smsHash, smsCode });
  },
);

export const resetPasswordSendResetEmail = createAsyncThunk(
  'auth/resetPasswordSendResetEmail',
  async ({ userId }: { userId: string }, thunkAPI) => {
    const branding = (thunkAPI.getState() as RootState).user.branding?.isActive;
    await http.post(`/api/AdPasswordReset/SendResetEmail?isBranded=${!!branding}`, userId);
  },
);

export const resetPasswordSetNewPassword = createAsyncThunk(
  'resetPassword/resetPasswordSetNewPassword',
  async ({ userId, password, isEmailRequest }: { userId: string; password: string; isEmailRequest: boolean }) => {
    const response: AxiosResponse<string> = await http.post(
      `/api/AdPasswordReset/ResetPassword?isEmailRequest=${isEmailRequest}`,
      { userId, password },
    );
    return response.data;
  },
);

export const skipSetupPhoneForPasswordReset = createAsyncThunk(
  'resetPassword/skipSetupPhoneForPasswordReset',
  async () => {
    await http.post('api/auth/mfa/SkipSetupPhoneForPasswordReset');
  },
);

export const validatePasswordReset = createAsyncThunk('resetPassword/validatePasswordReset', async (token: string) => {
  await http.get(`/api/RegisteredUser/ValidatePasswordReset?token=${token}`);
});

export const setNewPassword = createAsyncThunk(
  'resetPassword/setNewPassword',
  async ({ token, newPassword }: { token: string; newPassword: string }) => {
    await http.post('/api/RegisteredUser/SetNewPassword', { token, newPassword });
  },
);

export const changeTempPassword = createAsyncThunk(
  'resetPassword/changeTempPassword',
  async ({ email, tempPassword, newPassword }: { email: string; tempPassword: string; newPassword: string }) => {
    await http.post('/api/RegisteredUser/ChangeTempPassword', { email, tempPassword, newPassword });
  },
);

export const changePassword = createAsyncThunk(
  'resetPassword/changePassword',
  async ({ userId, oldPassword, newPassword }: { userId: string; oldPassword: string; newPassword: string }) => {
    const response: AxiosResponse<string> = await http.post('/api/adPasswordReset/ChangePassword', {
      userId,
      oldPassword,
      newPassword,
    });
    return response.data;
  },
);

interface IResetPasswordState {
  smsHash: string;
  getAdPasswordResetRequest: IHttpRequestResult<PasswordResetOptions>;
  validateResetByEmailRequest: IHttpRequestResult<string>;
  resetPasswordVerifyMfaCodeRequest: IHttpRequestResult<void>;
  resetPasswordVerifyPhoneRequest: IHttpRequestResult<string>;
  resetPasswordVerifySmsCodeRequest: IHttpRequestResult<void>;
  resetPasswordSetNewPasswordRequest: IHttpRequestResult<string>;
  skipSetupPhoneForPasswordResetRequest: IHttpRequestResult<void>;
  validatePasswordResetRequest: IHttpRequestResult<void>;
  setNewPasswordRequest: IHttpRequestResult<void>;
  changeTempPasswordRequest: IHttpRequestResult<void>;
  changePasswordRequest: IHttpRequestResult<string>;
  sendRegisteredUserForgotPasswordEmailRequest: IHttpRequestResult<void>;
}

const initialState: IResetPasswordState = {
  smsHash: '',
  getAdPasswordResetRequest: createHttpRequestInitResult(),
  validateResetByEmailRequest: createHttpRequestInitResult(),
  resetPasswordVerifyMfaCodeRequest: createHttpRequestInitResult(),
  resetPasswordVerifyPhoneRequest: createHttpRequestInitResult(),
  resetPasswordVerifySmsCodeRequest: createHttpRequestInitResult(),
  resetPasswordSetNewPasswordRequest: createHttpRequestInitResult(),
  skipSetupPhoneForPasswordResetRequest: createHttpRequestInitResult(),
  validatePasswordResetRequest: createHttpRequestInitResult(),
  setNewPasswordRequest: createHttpRequestInitResult(),
  changeTempPasswordRequest: createHttpRequestInitResult(),
  changePasswordRequest: createHttpRequestInitResult(),
  sendRegisteredUserForgotPasswordEmailRequest: createHttpRequestInitResult(),
};

const ResetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createExtraReducersForResponses<IResetPasswordState>(builder, getAdPasswordReset, 'getAdPasswordResetRequest');
    createExtraReducersForResponses<IResetPasswordState>(builder, validateResetByEmail, 'validateResetByEmailRequest');
    createExtraReducersForResponses<IResetPasswordState>(
      builder,
      resetPasswordVerifyMfaCode,
      'resetPasswordVerifyMfaCodeRequest',
    );
    createExtraReducersForResponses<IResetPasswordState>(
      builder,
      resetPasswordVerifyPhone,
      'resetPasswordVerifyPhoneRequest',
      (state, action) => {
        state.smsHash = action.payload;
      },
    );
    createExtraReducersForResponses<IResetPasswordState>(
      builder,
      resetPasswordVerifySmsCode,
      'resetPasswordVerifySmsCodeRequest',
    );
    createExtraReducersForResponses<IResetPasswordState>(
      builder,
      resetPasswordSetNewPassword,
      'resetPasswordSetNewPasswordRequest',
    );
    createExtraReducersForResponses<IResetPasswordState>(
      builder,
      skipSetupPhoneForPasswordReset,
      'skipSetupPhoneForPasswordResetRequest',
    );
    createExtraReducersForResponses<IResetPasswordState>(
      builder,
      validatePasswordReset,
      'validatePasswordResetRequest',
    );
    createExtraReducersForResponses<IResetPasswordState>(builder, setNewPassword, 'setNewPasswordRequest');
    createExtraReducersForResponses<IResetPasswordState>(builder, changeTempPassword, 'changeTempPasswordRequest');
    createExtraReducersForResponses<IResetPasswordState>(builder, changePassword, 'changePasswordRequest');
    createExtraReducersForResponses<IResetPasswordState>(
      builder,
      sendRegisteredUserForgotPasswordEmail,
      'sendRegisteredUserForgotPasswordEmailRequest',
    );
  },
});

// export const { } = ResetPasswordSlice.actions;

export default ResetPasswordSlice.reducer;
