import cn from 'classnames';
import { useState } from 'react';
import Checkbox from 'components/Checkbox/Checkbox';
import { AngleDownIcon } from 'assets/img';
import {
  checkIfAllSentriesAreOffline,
  getNumberOfOutdatedSentries,
  isAllChecked,
  onToggleAll,
} from 'components/UpdateSentryModal/utils/updateUtils';
import PrimaryItem from '../PrimaryItem/PrimaryItem';
import { ICustomerDomainOutdatedSentry, ISentry } from 'interfaces';
import styles from './CustomerItem.module.scss';

interface Props {
  item: ICustomerDomainOutdatedSentry;
  handleToggleOne: (checked: boolean, item: ISentry) => void;
  checkedSentries: ISentry[];
  setCheckedSentries: (items: ISentry[]) => void;
}

const CustomerItem = ({ item, handleToggleOne, checkedSentries, setCheckedSentries }: Props) => {
  const [isOpenSentries, setIsOpenSentries] = useState(false);
  const handleOpenSentries = () => setIsOpenSentries(!isOpenSentries);

  const handleToggleAll = (isChecked: boolean) => {
    const selectedItems = onToggleAll(isChecked, checkedSentries, item.sentries);
    setCheckedSentries(selectedItems);
  };

  const onToggleMainCheckbox = () => {
    const isChecked = isAllChecked(checkedSentries, item.sentries) === 'empty';
    handleToggleAll(isChecked);
  };

  const allChecked = isAllChecked(checkedSentries, item.sentries);

  const isAllOffline = checkIfAllSentriesAreOffline(item);
  const numberOfOutdatedSentries = getNumberOfOutdatedSentries(item);

  const isOneChecked = () =>
    item.sentries.some((sentry) => checkedSentries.some((value) => value.apiKey === sentry.apiKey));

  return (
    <li className={styles.item}>
      <div className={cn(styles.itemRow, { [styles.checked]: isOneChecked() })}>
        <div className={cn(styles.itemWrap, { [styles.offline]: isAllOffline })}>
          <Checkbox
            id={item.domainName}
            name={item.domainName}
            onChange={onToggleMainCheckbox}
            isDisabled={isAllOffline}
            checked={allChecked}
          />
          <button type="button" className={styles.itemName} onClick={handleOpenSentries}>
            <AngleDownIcon
              width="1.5em"
              height="1.5em"
              className={cn(styles.itemNameIcon, { [styles.open]: isOpenSentries })}
            />
            <span className={cn({ [styles.open]: isOpenSentries })}>{item.domainName}</span>
          </button>
        </div>
        <div className={cn(styles.itemCount, { [styles.open]: isOpenSentries })}>{numberOfOutdatedSentries}</div>
      </div>
      {isOpenSentries && (
        <ul className={styles.itemSentries}>
          {item.sentries.map((sentry) => (
            <PrimaryItem
              key={sentry.apiKey}
              item={sentry}
              handleToggleOne={handleToggleOne}
              checkedSentries={checkedSentries}
              customerDomain
            />
          ))}
        </ul>
      )}
    </li>
  );
};

export default CustomerItem;
