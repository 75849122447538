import { Route, RouteProps } from 'react-router-dom';
import LoginLayout from 'components/LoginLayout/LoginLayout';

interface Props extends RouteProps {
  component: React.ComponentType<any>;
}

const LoginRoute = ({ component: Component, ...rest }: Props): React.ReactElement => (
  <Route
    {...rest}
    render={(props) => (
      <LoginLayout>
        <Component {...props} />
      </LoginLayout>
    )}
  />
);

export default LoginRoute;
