import { useAppDispatch, useAppSelector } from 'store';
import { deleteDevices, IBitlockerDevice } from 'store/slices/bitLockerMgmt';
import { Modal } from 'components';
import { notify } from 'utils';
import styles from './DeleteDevicesModal.module.scss';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  deviceIds: string[];
  setSelectedDeviceIds: (deviceIds: IBitlockerDevice[]) => void;
  domainId: string;
}

const DeleteDevicesModal = ({ isOpen, onRequestClose, deviceIds, setSelectedDeviceIds, domainId }: Props) => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.bitLockerMgmt.deleteDevicesRequest);

  const handleDeleteDevices = async () => {
    try {
      await dispatch(deleteDevices({ deviceIds, domainId })).unwrap();
      if (deviceIds.length === 1) {
        notify.success('Device is deleted');
      } else {
        notify.success('Devices are deleted');
      }
    } catch (err: any) {
      notify.error(err.message);
    } finally {
      setSelectedDeviceIds([]);
      onRequestClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Delete Devices"
      actionFunc={handleDeleteDevices}
      actionTitle={isLoading ? 'Deleting...' : 'Delete'}
      variantBtn="deleteOutline"
      actionDisabled={isLoading}
      style={{ content: { width: '39em' } }}>
      <div className={styles.deleteDevicesInfo}>
        This DELETE feature is recommended for systems that have been re-imaged, decommissioned, lost, or which you
        cannot uninstall the TruGrid device software
      </div>
      <p>
        Are you sure you want to permanently remove<b> {deviceIds.length}</b>{' '}
        {deviceIds.length > 1 ? 'devices' : 'device'} ?
      </p>
    </Modal>
  );
};

export default DeleteDevicesModal;
