import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AsyncThunkConfig, GetThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { AxiosResponse } from 'axios';
import { mustSetupMfa } from 'helpers';
import { http, createHttpRequestInitResult, createExtraReducersForResponses } from 'helpers/http';
import { IBrandingDomainData, IDomain, IHttpRequestResult, IUser } from 'interfaces';

interface IRequest {
  _background?: boolean;
}

export interface ValidationData {
  code: string;
  dnsName: string;
  id: string;
  validationCode: string;
}

export interface IResponseValidationData {
  activationCode: string;
  isMsp: boolean;
  sentryLink: string;
  secureConnectLink: string;
}

export const checkIsUserAuthenticated = createAsyncThunk(
  'userData/checkIsUserAuthenticated',
  async (_, thunkAPI: GetThunkAPI<AsyncThunkConfig>) => {
    const response: AxiosResponse<boolean> = await http.get('/isauthenticated');
    const { host } = window.location;
    if (!process.env.REACT_APP_PUBLIC_URL?.includes(host) && host !== 'localhost:3000') {
      await thunkAPI.dispatch(getBrandingInfo()).unwrap();
    }
    if (response.data) {
      await thunkAPI.dispatch(getInitialUserData({ _background: false })).unwrap();
    }
    return response.data;
  },
);

export const getInitialUserData = createAsyncThunk(
  'userData/getInitialUserData',
  async ({ _background = false }: IRequest, thunkAPI) => {
    try {
      const response: AxiosResponse<IUser> = await http.get('/api/Account/GetInitialUserData');
      if (response.data.roles.includes('trugridadmin') && !mustSetupMfa(response.data)) {
        return window.location.replace('/admin');
      }
      return response.data;
    } catch (err: any) {
      if (err?.response?.status === 401) {
        window.location.replace('/');
      }
      if (err?.response?.status === 406) {
        thunkAPI.dispatch(signOut());
        window.location.replace('/login');
      }
    }
  },
);

export const updateUserData = createAsyncThunk('userData/updateUserData', async ({ _background = false }: IRequest) => {
  const response: AxiosResponse<IUser> = await http.get('/api/Account/GetInitialUserData');
  return response.data;
});

export const signOut = createAsyncThunk('userData/signOut', async () => {
  await http.post('/api/auth/logout');
});

export const getBrandingInfo = createAsyncThunk('userData/getBrandingInfo', async () => {
  const { host } = window.location;
  const response: AxiosResponse<IBrandingDomainData> = await http.get(`/api/Company/GetBrandingByHost?host=${host}`);
  return response.data;
});

export const acceptEula = createAsyncThunk('userData/acceptEula', async () => {
  await http.post('/api/Account/UpdateUserEula?eula=true');
});

export const getWhoisDomainEmails = createAsyncThunk('userData/getWhoisDomainEmails', async (domain: string) => {
  const response: AxiosResponse<string[]> = await http.get(`/api/Domains/GetWhoisDomainEmails?domain=${domain}`);
  return response.data;
});

export const getValidationCode = createAsyncThunk('userData/getValidationCode', async (domainId: string) => {
  const response: AxiosResponse<ValidationData> = await http.get(
    `/api/DomainValidation/GetValidationCode?domainId=${domainId}`,
  );
  return response.data;
});

export const getValidateData = createAsyncThunk('userData/getValidateData', async (link: string) => {
  const response: AxiosResponse<ValidationData> = await http.get(link);
  return response.data;
});

export const validateDataWithAutoValidated = createAsyncThunk(
  'userData/validateDataWithAutoValidated',
  async (link: string) => {
    const response: AxiosResponse<ValidationData> = await http.post(link);
    return response.data;
  },
);

export const createNotValidSpDomain = createAsyncThunk('userData/createNotValidSpDomain', async () => {
  const response: AxiosResponse<ValidationData> = await http.post(
    '/api/Domains/CreateNotValidSpDomain?activationType=2',
  );
  return response.data;
});

interface IValidateByTxtRecordPayload {
  domainId: string;
  dnsName: string;
  validationCode: string;
}

export const validateByTxtRecord = createAsyncThunk(
  'userData/validateByTxtRecord',
  async ({ domainId, dnsName, validationCode }: IValidateByTxtRecordPayload) => {
    const response: AxiosResponse<IResponseValidationData> = await http.post(
      `/api/DomainValidation/validateByTxtRecord?domainId=${domainId}&dnsName=${dnsName}&validationCode=${validationCode}`,
    );
    return response.data;
  },
);

interface IUserState {
  user: IUser;
  domain: IDomain;
  branding: IBrandingDomainData | null;
  isAuth: boolean;
  appPublishingCurrent?: boolean;
  getInitialUserDataRequest: IHttpRequestResult<IUser>;
  checkIsUserAuthenticatedRequest: IHttpRequestResult<boolean>;
  getBrandingInfoRequest: IHttpRequestResult<IBrandingDomainData>;
  acceptEulaRequest: IHttpRequestResult<void>;
  getWhoisDomainEmailsRequest: IHttpRequestResult<string[]>;
  getValidationCodeRequest: IHttpRequestResult<ValidationData>;
  createNotValidSpDomainRequest: IHttpRequestResult<ValidationData>;
  validateDataWithAutoValidatedRequest: IHttpRequestResult<ValidationData>;
  getValidateDataRequest: IHttpRequestResult<ValidationData>;
}

const initialState: IUserState = {
  user: {} as IUser,
  domain: {} as IDomain,
  branding: null,
  appPublishingCurrent: false,
  isAuth: false,
  getInitialUserDataRequest: createHttpRequestInitResult(),
  checkIsUserAuthenticatedRequest: createHttpRequestInitResult(),
  getBrandingInfoRequest: createHttpRequestInitResult(),
  acceptEulaRequest: createHttpRequestInitResult(),
  getWhoisDomainEmailsRequest: createHttpRequestInitResult(),
  getValidationCodeRequest: createHttpRequestInitResult(),
  createNotValidSpDomainRequest: createHttpRequestInitResult(),
  validateDataWithAutoValidatedRequest: createHttpRequestInitResult(),
  getValidateDataRequest: createHttpRequestInitResult(),
};

export const userSlice = createSlice({
  name: 'UserSlice',
  initialState,
  reducers: {
    updateUser(state, action) {
      state.user = action.payload;
    },
    validateUser(state, action) {
      const { domainId, activationCode, isMsp, sentryLink, secureConnectLink } = action.payload;
      state.user = {
        ...state.user,
        domainId: domainId,
        domainActivationCode: activationCode,
        domainStatus: 1,
        roles: ['mspadmin'],
        spApplicationWasApproved: isMsp,
        downloadSentryLink: sentryLink,
        downloadSecureConnectLink: secureConnectLink,
      };
    },
    validateDomain(state, action) {
      const { domainId, activationCode, isMsp, sentryLink, secureConnectLink } = action.payload;
      state.user = {
        ...state.user,
        domainId: domainId,
        domainActivationCode: activationCode,
        domainStatus: 1,
        roles: ['mspadmin'],
        spApplicationWasApproved: isMsp,
        downloadSentryLink: sentryLink,
        downloadSecureConnectLink: secureConnectLink,
        domainWasAutovalidated: false,
      };
    },
    onUpdateAppPublishing(state, action) {
      const {
        isAppPublishingEnabled,
        appPublishingCurrent,
      }: { isAppPublishingEnabled: boolean; appPublishingCurrent?: boolean } = action.payload;
      if (appPublishingCurrent) {
        state.appPublishingCurrent = isAppPublishingEnabled;
      } else {
        if (state.user) {
          state.user = {
            ...state.user,
            isAppPublishingEnabled: isAppPublishingEnabled,
          };
        }
        if (state.domain) {
          state.domain = {
            ...state.domain,
            isAppPublishingEnabled: isAppPublishingEnabled,
          };
        }
        state.appPublishingCurrent = isAppPublishingEnabled;
      }
    },
    changeUserMfaQrCode(state) {
      if (state.user.isPhoneMfaActive) {
        state.user.mustSetupMfa = false;
        state.user.isMfaActive = true;
      }
    },
    changeUserMfa(state, action) {
      const type = action.payload;
      if (type === 'app' || type === 'sms') {
        state.user.mustSetupMfa = false;
        state.user.isPhoneMfaActive = true;
        state.user.isMfaActive = false;
      }
      if (type === 'email') {
        state.user.mustSetupMfa = false;
        state.user.isMfaActive = false;
      }
      if (type === 'none') {
        state.user.shouldSetupPhoneForPasswordReset = false;
      }
    },
    skipForeverSetupPhone(state) {
      state.user.shouldSetupPhoneForPasswordReset = false;
    },
  },
  extraReducers: (builder) => {
    createExtraReducersForResponses(
      builder,
      checkIsUserAuthenticated,
      'checkIsUserAuthenticatedRequest',
      (state, { payload }) => {
        state.isAuth = payload;
      },
    );
    createExtraReducersForResponses(builder, getInitialUserData, 'getInitialUserDataRequest', (state, { payload }) => {
      state.user = payload;
      state.domain = {
        id: payload.domainId,
        dnsName: payload.dnsDomainName,
        sentries: payload.sentries,
        status: payload.domainStatus,
        origin: payload.domainOrigin,
        activationCode: payload.domainActivationCode,
        isAppPublishingEnabled: payload.isAppPublishingEnabled,
        isServiceProvider: payload.spApplicationWasApproved,
        domainActivationCode: payload.domainActivationCode,
        isCompanyPage: true,
      };
      state.appPublishingCurrent = payload.isAppPublishingEnabled;
      state.isAuth = true;
    });
    createExtraReducersForResponses(builder, getBrandingInfo, 'getBrandingInfoRequest', (state, action) => {
      if (action.payload.isActive) {
        state.branding = action.payload;
      }
    });
    createExtraReducersForResponses(builder, acceptEula, 'acceptEulaRequest', (state) => {
      state.user = {
        ...state.user,
        isEulaAccepted: true,
      };
    });
    createExtraReducersForResponses(builder, getWhoisDomainEmails, 'getWhoisDomainEmailsRequest');
    createExtraReducersForResponses(builder, getValidationCode, 'getValidationCodeRequest');
    createExtraReducersForResponses(builder, validateDataWithAutoValidated, 'validateDataWithAutoValidatedRequest');
    createExtraReducersForResponses(builder, createNotValidSpDomain, 'createNotValidSpDomainRequest');
    createExtraReducersForResponses(builder, getValidateData, 'getValidateDataRequest');
  },
});

export const {
  onUpdateAppPublishing,
  validateUser,
  updateUser,
  validateDomain,
  changeUserMfaQrCode,
  changeUserMfa,
  skipForeverSetupPhone,
} = userSlice.actions;

export default userSlice.reducer;
