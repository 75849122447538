import { Link, MsLogin, StatusMessage } from 'components';
import { getDomainADStatus } from 'helpers';
import { DownloadIcon } from 'assets/img';
import { IUser } from 'interfaces';
import styles from './OnboardingMessage.module.scss';

interface Props {
  user: IUser;
}

const OnboardingMessage = ({ user }: Props) => {
  const { isDomainAzure } = getDomainADStatus(user);

  return (
    <StatusMessage header="Your Domain is not Setup">
      <div className={styles.row}>
        <span>Please connect to&nbsp;</span>
        <MsLogin
          isAzureAd={isDomainAzure}
          domainId={user.domainId}
          domainName={user.dnsDomainName}
          isActive
          isOnboarding
        />
        &nbsp;&nbsp;or an&nbsp;&nbsp;
        <Link href={user.downloadSentryLink} external bold>
          on-premises Active Directory
          <DownloadIcon />
        </Link>
      </div>
    </StatusMessage>
  );
};

export default OnboardingMessage;
