import { IDomain, ISentry, IUser } from 'interfaces';

interface IGetDomainStatusPayload {
  status?: number;
  domainStatus?: number;
}
interface IGetDomainStatusResponse {
  isDomainNotValidated: boolean;
  isDomainNotActivated: boolean;
  isDomainActive: boolean;
}

export const getDomainStatus = (domain: IGetDomainStatusPayload): IGetDomainStatusResponse => {
  const status = domain.status ?? domain.domainStatus;

  const isDomainNotValidated = status === 0;
  const isDomainNotActivated = status === 1;
  const isDomainActive = status === 2;

  return { isDomainNotValidated, isDomainNotActivated, isDomainActive };
};

interface IGetDomainADStatusPayload {
  origin?: number;
  domainOrigin?: number;
}

interface IGetDomainADStatusResponse {
  isDomainOnPremise: boolean;
  isDomainAzure: boolean;
  isDomainHybryd: boolean;
  ADStatusMessage: string;
}

export const getDomainADStatus = (domain: IGetDomainADStatusPayload): IGetDomainADStatusResponse => {
  const origin = domain.origin ?? domain.domainOrigin;

  const isDomainOnPremise = origin === 1;
  const isDomainAzure = origin === 2;
  const isDomainHybryd = origin === 3;

  let ADStatusMessage = 'On-premises AD Activated';
  if (isDomainAzure) {
    ADStatusMessage = 'Entra ID Activated';
  }
  if (isDomainHybryd) {
    ADStatusMessage = 'Hybrid Authentication Activated';
  }
  return { isDomainOnPremise, isDomainAzure, isDomainHybryd, ADStatusMessage };
};

export const isSentryDeleted = (
  domain: IDomain | IUser | IGetDomainStatusPayload | IGetDomainADStatusPayload,
  sentries?: ISentry[] | null,
): boolean => {
  const { isDomainOnPremise, isDomainHybryd } = getDomainADStatus(domain as IGetDomainADStatusPayload);
  const { isDomainActive } = getDomainStatus(domain as IGetDomainStatusPayload);
  if (sentries) {
    return !sentries?.length && (isDomainOnPremise || isDomainHybryd) && isDomainActive;
  }
  return !(domain as IDomain | IUser).sentries?.length && (isDomainOnPremise || isDomainHybryd) && isDomainActive;
};
