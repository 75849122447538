import { Table } from 'components';
import { custAdmin } from 'consts';
import { ILicenseReport } from 'interfaces';
import { useState } from 'react';
import { useAppSelector } from 'store';
import styles from './LicensingTable.module.scss';

interface Props {
  licenses: ILicenseReport[];
  primaryDomain: string;
  isCompanyPage: boolean;
  userRole: string;
  selectedLicenseType: string;
}

const LicensingTable = ({ licenses, primaryDomain, isCompanyPage, userRole, selectedLicenseType }: Props) => {
  const { isLoading } = useAppSelector((state) => state.reports.getLicensesReportsRequest);
  const [findValue, setFindValue] = useState('');

  const columns = [
    {
      name: 'Domains',
      width: '40%',
      data: ({ domainName }: ILicenseReport) =>
        `${domainName} ${domainName === primaryDomain && isCompanyPage && userRole !== custAdmin ? '(Primary)' : ''}`,
      search: 'Domain Name',
      searchNotDebounce: setFindValue,
    },
    {
      name: 'Over/Under Allocated',
      width: '20%',
      data: ({ licensesCount, usedLicensesCount }: ILicenseReport) => licensesCount - usedLicensesCount,
    },
    {
      name: selectedLicenseType === 'SecureRDP' ? 'Users Reporting in' : 'Installed IoT Agents',
      width: '20%',
      data: 'usedLicensesCount',
    },
    {
      name: 'Licenses Allocated',
      width: '20%',
      data: 'licensesCount',
    },
  ];

  const licensesFindValue = () => {
    let licensesNew = licenses;
    if (findValue) {
      licensesNew = licensesNew.filter((d) => d.domainName.toLowerCase().includes(findValue.toLowerCase()));
    }
    return { data: licensesNew, queryConfig: { searchQuery: findValue } };
  };

  return (
    <Table
      columns={columns}
      data={licensesFindValue()}
      id="licensing-table"
      isLoading={isLoading}
      dataKey="domainId"
      pagination={false}
      className={styles.licensingTable}
      classNameRow={({ licensesCount, usedLicensesCount }) => {
        const diff = licensesCount - usedLicensesCount;
        return diff !== 0 ? (diff < 0 ? styles.licensingTableRowNegative : styles.licensingTableRowPositive) : '';
      }}
    />
  );
};

export default LicensingTable;
