import { Link, StatusMessage } from 'components';

const NoUsers = () => (
  <StatusMessage header="No users reporting in">
    Please check Active Directory or{' '}
    <Link href="https://www.trugrid.com/docs/usersnotreportingin" external bold>
      add users
    </Link>
  </StatusMessage>
);

export default NoUsers;
