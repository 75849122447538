import { useState } from 'react';
import { Modal } from 'components';
import { notify } from 'utils';
import { useAppDispatch, useAppSelector } from 'store';
import { ILicenseReport } from 'interfaces';
import { getDomainADStatus, getDomainStatus } from 'helpers';
import { corpAdmin, corpTechAdmin, custAdmin, techAdmin } from 'consts';
import CheckBoxesGroup from '../CheckBoxesGroup/CheckBoxesGroup';
import DomainsList from '../DomainsList/DomainsList';
import styles from './ExportDetailsModal.module.scss';
import { generateLicensingReport } from 'store/slices/reports';

interface Props {
  isFastGenerate: boolean;
  setIsFastGenerate: (isFastGenerate: boolean) => void;
  showExportDetailsModal: boolean;
  toggleExportDetailsModal: () => void;
  licenses: ILicenseReport[];
  primaryDomain: string;
  userRole: string;
  saveFile: (data: any) => void;
  isCompanyPage: boolean;
}

const ExportDetailsModal = ({
  showExportDetailsModal,
  toggleExportDetailsModal,
  licenses,
  primaryDomain,
  userRole,
  setIsFastGenerate,
  saveFile,
  isCompanyPage,
}: Props) => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.reports.generateLicensingReportRequest);

  const [isMFAStatusChecked, setIsMFAStatusChecked] = useState(false);
  const [isLicenseChecked, setIsLicenseChecked] = useState(true);
  const [isADGroupChecked, setIsADGroupChecked] = useState(false);

  const primaryDomainData = licenses.find((el) => el.domainName.toLowerCase() === primaryDomain.toLowerCase());

  const [checkedDomains, setCheckedDomains] = useState<string[]>(primaryDomainData ? [primaryDomainData.domainId] : []);

  const domainsForReport = licenses.filter((el: ILicenseReport) => {
    const { isDomainActive } = getDomainStatus(el);
    return isDomainActive;
  });

  const isVisibleMfaCheckbox = domainsForReport.some((item) => {
    const { isDomainOnPremise } = getDomainADStatus(item);
    return isDomainOnPremise;
  });

  const isHideDomainList = [techAdmin, custAdmin, corpAdmin, corpTechAdmin].includes(userRole) || !isCompanyPage;

  const toastMessage = (
    <div>
      <div>Your request is in progress</div>
      <div>Please refresh this page in a few minutes</div>
    </div>
  );

  const confirmResult = async () => {
    try {
      const data = await dispatch(
        generateLicensingReport({
          domainId: primaryDomainData!.domainId,
          domainIds: checkedDomains,
          isMFAStatusChecked,
          isLicenseChecked,
          isADGroupChecked,
        }),
      ).unwrap();
      setIsFastGenerate(true);
      toggleExportDetailsModal();
      saveFile(data);
      notify.success('Your report is generated');
    } catch (err: any) {
      if (err.name === 'AxiosError') {
        setIsFastGenerate(false);
      }
      toggleExportDetailsModal();
      notify.success(toastMessage);
    }
  };

  return (
    <Modal
      isOpen={showExportDetailsModal}
      onRequestClose={toggleExportDetailsModal}
      contentLabel="Generate Report"
      actionTitle="Export"
      actionDisabled={!checkedDomains.length}
      actionLoading={isLoading}
      actionFunc={confirmResult}>
      <div className={styles.modalContent}>
        <CheckBoxesGroup
          isMFAStatusChecked={isMFAStatusChecked}
          setIsMFAStatusChecked={setIsMFAStatusChecked}
          isLicenseChecked={isLicenseChecked}
          setIsLicenseChecked={setIsLicenseChecked}
          isADGroupChecked={isADGroupChecked}
          setIsADGroupChecked={setIsADGroupChecked}
          isVisibleMfaCheckbox={isVisibleMfaCheckbox}
        />
        <DomainsList
          licenses={domainsForReport}
          checkedDomains={checkedDomains}
          primaryDomainData={primaryDomainData!}
          setNewCheckedDomains={(item) => setCheckedDomains(item)}
          isHideDomainList={isHideDomainList}
        />
      </div>
    </Modal>
  );
};

export default ExportDetailsModal;
