import { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button, CodeInput } from 'components';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import { notify } from 'utils';
import { SerializedError } from '@reduxjs/toolkit';
import { resetPasswordVerifyPhone } from 'store/slices/auth/resetPassword';
import { useAppDispatch, useAppSelector } from 'store';
import styles from './ConfirmPhoneNumber.module.scss';

interface Props {
  userId: string;
  phoneMask: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
  phoneDigits: string;
  setPhoneDigits: React.Dispatch<React.SetStateAction<string>>;
}

const ConfirmPhoneNumber = ({ userId, phoneMask, setError, phoneDigits, setPhoneDigits }: Props) => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.resetPassword.resetPasswordVerifyPhoneRequest);

  const [isError, setIsError] = useState('');

  const history = useHistory();
  const { url } = useRouteMatch();

  const clearError = () => setIsError('');

  const handleVerifyPhoneNumber = async () => {
    try {
      await dispatch(resetPasswordVerifyPhone({ userId, lastFour: phoneDigits })).unwrap();
      notify.success('SMS has been sent');
      history.push(`${url}/verification-code`);
    } catch (err: any) {
      if ((err as SerializedError).code === '409') {
        notify.error(err.message);
        history.replace('/login/forgot-password');
      } else if ((err as SerializedError).code === '403') {
        setError('exceeded');
      } else if ((err as SerializedError).code === '406') {
        setIsError(err.message);
      } else {
        notify.error(err.message);
      }
    }
  };

  return (
    <div className={styles.resetPass}>
      <LoginHeader title="Validate Your Identity" stepCount="2" currentStep="1" />
      <div className={styles.resetPassInfo}>
        We will send a verification code to <strong>{phoneMask}</strong>. To verify that this is your phone number,
        enter the last 4 digits
      </div>
      <div className={styles.resetPassForm}>
        <div className={styles.resetPassLabel}>Enter Last 4 Digits</div>
        <CodeInput
          fields={4}
          isError={!!isError}
          errorMessage={isError}
          clearError={clearError}
          isDisable={isLoading}
          value={phoneDigits}
          setValue={setPhoneDigits}
          handleSend={handleVerifyPhoneNumber}
          sendOnKeyDown
        />
        <Button
          id="send-number-phone"
          className={styles.resetPassButton}
          onClick={handleVerifyPhoneNumber}
          size="40"
          variant="primary"
          isDisabled={phoneDigits.length < 4 || !!isError}
          isLoading={isLoading}>
          Send Code
        </Button>
      </div>
      <div className={styles.resetPassContactSupport}>
        Phone is unavailable? Contact your Administrator for assistance
      </div>
    </div>
  );
};

export default ConfirmPhoneNumber;
