import { useHistory } from 'react-router-dom';
import { IDomain } from 'interfaces';
import { Button } from 'components';
import { truncateString } from 'utils';
import { ArrowLeftIcon } from 'assets/img';
import styles from './RememberMeTitle.module.scss';

interface Props {
  isCompanyPage: boolean;
  domain: IDomain;
}

const RememberMeTitle = ({ isCompanyPage, domain }: Props) => {
  const history = useHistory();

  const onBack = () => {
    if (isCompanyPage) {
      return history.replace('/company-management/security-management?tab=desktop-management');
    }
    return history.push(`/customer-management/${domain.dnsName}/security-management?tab=desktop-management`);
  };

  return (
    <>
      <div className={styles.rememberHead}>
        <div className={styles.rememberHeadWrap}>
          <Button variant="link" icon={<ArrowLeftIcon />} className={styles.rememberHeadWrapButton} onClick={onBack}>
            Back to Security Management
          </Button>
          {!isCompanyPage && (
            <h3 className={styles.rememberHeadTitle}>({truncateString(domain?.dnsName, false, 80)})</h3>
          )}
        </div>
      </div>
      <h3 className={styles.rememberTitle}>Remember Me</h3>
    </>
  );
};

export default RememberMeTitle;
