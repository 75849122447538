import { Modal } from 'components';
import { ILogin } from 'interfaces';
import { notify } from 'utils';
import { useAppDispatch, useAppSelector } from 'store';
import { sendRegisteredUserForgotPasswordEmail } from 'store/slices/auth/resetPassword';

interface Props {
  showModal: boolean;
  handleShowModal: () => void;
  signInData: ILogin;
}

const ModalConfirmReset = ({ showModal, handleShowModal, signInData }: Props) => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.resetPassword.sendRegisteredUserForgotPasswordEmailRequest);

  const handleForgotPassword = async () => {
    try {
      await dispatch(sendRegisteredUserForgotPasswordEmail(signInData.userId));
      notify.success('Reset link sent');
      handleShowModal();
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={handleShowModal}
      contentLabel="Password Reset"
      actionFunc={handleForgotPassword}
      actionTitle="Reset"
      actionLoading={isLoading}>
      <p>
        By clicking <strong>RESET</strong> you will reset you password. A link will be sent to your email address
      </p>
    </Modal>
  );
};

export default ModalConfirmReset;
