export const domainMsalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_ID as string,
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage' as const,
    storeAuthStateInCookie: false,
  },
};

export const loginMsalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_ID as string,
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: window.location.origin + '/',
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage' as const,
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = { scopes: ['User.Read'], prompt: 'select_account' as const };

export const apiRequest = {
  scopes: ['API-SCOPE'],
  forceRefresh: false,
  prompt: 'select_account' as const,
};
