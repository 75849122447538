import { DatabaseIcon, DeviceIcon, InfoFilledIcon, InfoIcon, LoaderIcon } from 'assets/img';
import cn from 'classnames';
import { SwitchCheckbox, Table } from 'components';
import TableEmpty from 'pages/BitLockerMgmt/components/TableEmpty/TableEmpty';
import {
  IBitlockerEncryption,
  decryptDevice,
  encryptDevice,
  getBitlockerEncryption,
  getEncryptDetails,
} from 'store/slices/bitLockerMgmt';
import { useAppDispatch, useAppSelector } from 'store';
import styles from './EncryptionsTable.module.scss';
import { useState } from 'react';
import { encryptStatuses } from 'consts';
import { notify } from 'utils';
import { EncryptDetailsModalData } from '../../Encryptions';
import { IQueryConfig } from 'interfaces';

interface Props {
  domainId: string;
  openEcryptPassModal: (encrypt: IBitlockerEncryption) => void;
  openEcryptDetailsModal: (data: EncryptDetailsModalData) => void;
}

const EncryptionsTable = ({ domainId, openEcryptPassModal, openEcryptDetailsModal }: Props) => {
  const dispatch = useAppDispatch();

  const { bitlockerEncryptionData: encrypts } = useAppSelector((state) => state.bitLockerMgmt);
  const { bitlockerSubscriptionStatus } = useAppSelector((state) => state.bitLockerMgmt);

  const [isInfoActive, setIsInfoActive] = useState<string>('');

  const [isStatusLoading, setIsStatusLoading] = useState('');
  const [isDetailsLoading, setIsDetailsLoading] = useState('');

  const handleSwitchEncryption = async (
    e: React.ChangeEvent<HTMLInputElement>,
    encrypt: IBitlockerEncryption,
  ): Promise<void> => {
    try {
      const isChecked = e.target.checked;
      const { deviceId, isTpm } = encrypt;
      setIsStatusLoading(deviceId);
      if (isTpm && isChecked) {
        const data = await dispatch(getEncryptDetails(deviceId)).unwrap();
        const isFewDiscs = data.filter((i) => !i.name?.includes('System')).length;
        if (isFewDiscs) {
          openEcryptPassModal(encrypt);
        } else {
          await dispatch(encryptDevice(deviceId)).unwrap();
        }
      } else if (isChecked) {
        openEcryptPassModal(encrypt);
      } else {
        await dispatch(decryptDevice(deviceId)).unwrap();
      }
    } catch (err: any) {
      notify.error(err.message);
    } finally {
      setIsStatusLoading('');
    }
  };

  const handleOpenEcryptDetailsModal = async (deviceId: string, hostname: string): Promise<void> => {
    try {
      setIsDetailsLoading(deviceId);
      const data = await dispatch(getEncryptDetails(deviceId)).unwrap();
      openEcryptDetailsModal({
        hostname,
        discs: data,
        deviceId,
      });
    } catch (err: any) {
      notify.error(err.message);
    } finally {
      setIsDetailsLoading('');
    }
  };

  const columns = [
    {
      name: 'Hostname',
      sortable: 'hostname',
      width: '40%',
      data: ({ isOnline, hostname, isTpm, isTpmMessage }: IBitlockerEncryption) => (
        <div className={styles.encryptsHostname}>
          <div className={styles.encryptsHostnameWrap}>
            <DeviceIcon className={cn({ [styles.encryptsHostnameOnline]: isOnline })} />
            <span className={styles.encryptsHostnameName}>{hostname}</span>
          </div>
          {isTpm && (
            <div>
              <span className={styles.encryptsHostnameAddInfo}>{isTpmMessage}</span>
            </div>
          )}
        </div>
      ),
    },
    {
      name: 'Encryption Status',
      width: '35%',
      data: ({ deviceId, isLockedDrive, encryptionStatus }: IBitlockerEncryption) => {
        const { statusColor, statusTitle, StatusIcon } = encryptStatuses[encryptionStatus];
        return isStatusLoading === deviceId ? (
          <LoaderIcon className={styles.encryptsStatusLoadIcon} />
        ) : (
          <div
            className={cn(styles.encryptsStatus, {
              [styles.encryptsStatusGrey]: statusColor === 'grey',
              [styles.encryptsStatusOrange]: statusColor === 'orange',
              [styles.encryptsStatusBlue]: statusColor === 'blue',
            })}>
            <StatusIcon className={styles.encryptsStatusIcon} />
            <span>{statusTitle}</span>
            {isLockedDrive && (
              <div
                className={styles.encryptsStatusWrap}
                onMouseEnter={() => setIsInfoActive(deviceId)}
                onMouseLeave={() => setIsInfoActive('')}>
                <div
                  data-tip="Please log into the Computer, select the locked Drive and enter a valid Password to unlock it"
                  data-for="info-tooltip"
                  data-place="bottom"
                  className={styles.encryptsStatusTooltip}
                />
                {isInfoActive === deviceId ? <InfoFilledIcon /> : <InfoIcon />}
              </div>
            )}
          </div>
        );
      },
    },
    {
      name: 'Encryption Action',
      width: '20%',
      data: (encrypt: IBitlockerEncryption) => {
        const { isOnline, encryptionStatus } = encrypt;
        const isChecked = [1, 2, 4, 7].includes(encryptionStatus);

        return (
          <p
            className={cn(styles.encryptsAction, {
              [styles.encryptsActionChecked]: isChecked,
              [styles.encryptsActionDisabled]: !isOnline,
            })}>
            <SwitchCheckbox
              id="toggle-encrypt"
              checked={isChecked}
              disabled={!isOnline}
              onChange={(e) => handleSwitchEncryption(e, encrypt)}
            />
            {isChecked ? 'ON' : 'OFF'}
          </p>
        );
      },
    },
    {
      name: '',
      width: '5%',
      data: ({ deviceId, hostname }: IBitlockerEncryption) =>
        isDetailsLoading === deviceId ? (
          <LoaderIcon className={styles.encryptsDetailsLoading} />
        ) : (
          <button
            data-tip="View Encryption Details"
            data-for="info-tooltip"
            data-place="left"
            type="button"
            onClick={() => handleOpenEcryptDetailsModal(deviceId, hostname)}
            className={styles.encryptsDetailsButton}
            id={`view-encryption-details-${deviceId}`}>
            <DatabaseIcon />
          </button>
        ),
    },
  ];

  const isDeviceWithoutLicense = ({ hasLicense }: IBitlockerEncryption) =>
    cn({
      [styles.encryptsTableRowUnlicensed]: !hasLicense,
    });

  const dispatchAction = async (query: IQueryConfig) => {
    await dispatch(getBitlockerEncryption({ domainId: domainId, query, _background: true })).unwrap();
  };

  return (
    <Table<IBitlockerEncryption>
      columns={columns}
      data={encrypts}
      id="dm-encryption-table"
      noData={<TableEmpty isNoLicense={!bitlockerSubscriptionStatus} />}
      dataKey="deviceId"
      className={styles.encryptsTable}
      classNameRow={(item: any) => isDeviceWithoutLicense(item)}
      dispatchAction={dispatchAction}
    />
  );
};

export default EncryptionsTable;
