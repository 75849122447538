import { TriangleFilledIcon } from 'assets/img';
import { Alert, Button } from 'components';
import { corpTechAdmin, spTechAdmin } from 'consts';
import { useAppSelector } from 'store';
import styles from './SuspendedMessage.module.scss';

interface Props {
  role: string;
  openReactivateModal: () => void;
}

const SuspendedMessage = ({ role, openReactivateModal }: Props) => {
  const { isLoading } = useAppSelector((state) => state.subscriptions.reactivateSubscriptionRequest);

  if ([spTechAdmin, corpTechAdmin].includes(role)) {
    return (
      <Alert icon={<TriangleFilledIcon />} absolute>
        <div className={styles.alertMessage}>
          <p>Subscription suspended. Please contact your company administrator for assistance</p>
        </div>
      </Alert>
    );
  }
  return (
    <Alert
      absolute
      icon={<TriangleFilledIcon />}
      action={
        <Button
          variant="primary"
          id="subs-reactivation"
          size="32"
          isLoading={isLoading}
          style={{ width: '15.5em' }}
          onClick={openReactivateModal}>
          Reactivate subscription
        </Button>
      }>
      <div className={styles.alertMessage}>
        <p>Subscription suspended</p>
        <p>
          Click <b>Reactivate subscription</b> to pay and update subscription
        </p>
      </div>
    </Alert>
  );
};

export default SuspendedMessage;
