import { useState } from 'react';
import cn from 'classnames';
import { Button, Checkbox, SearchInput } from 'components';
import { IActiveDirectoryAssignment } from 'store/slices/activeDirectory';
import styles from './ModalAssignmentsList.module.scss';

interface Props {
  assignments: IActiveDirectoryAssignment[];
  setAssignments: (assignments: IActiveDirectoryAssignment[]) => void;
  assignmentsInit: IActiveDirectoryAssignment[];
  isMfaEnabled: boolean;
}

const ModalAssigmentsList = ({ assignments, setAssignments, assignmentsInit, isMfaEnabled }: Props) => {
  const [findValue, setFindValue] = useState('');

  const onToggleAll = (type: string) => {
    const assignmentsNew = assignments.map((i) => ({ ...i, isAssigned: type === 'select' }));
    setAssignments(assignmentsNew);
  };

  const onToggleItem = (resourceId: string, value: boolean) => {
    const assignmentsNew = assignments.map((i) =>
      i.resourceId === resourceId ? { ...i, isAssigned: value } : { ...i },
    );
    setAssignments(assignmentsNew);
  };

  let assignmentsNew = assignments;

  if (findValue) {
    assignmentsNew = assignmentsNew.filter((i) => i.resourceName.toLowerCase().includes(findValue.toLowerCase()));
  }

  return (
    <div className={styles.assignments}>
      <div className={styles.assignmentsSearch}>
        <SearchInput
          id="ad-modal-search"
          searchBy="Domain Name"
          isNotDebounce
          fullWidth
          onChange={(value) => setFindValue(value)}
        />
      </div>
      {!!assignmentsNew.length && (
        <div className={styles.assignmentsHeader}>
          <span className={styles.assignmentsHeaderTitle}>Select Domains</span>
          {assignmentsNew.filter((i) => i.isAssigned).length === assignmentsNew.length ? (
            <Button
              type="button"
              id="select-all"
              variant="link"
              className={styles.assignmentsHeaderSelect}
              onClick={() => onToggleAll('unselect')}>
              Unselect All
            </Button>
          ) : (
            <Button
              type="button"
              id="unselect-all"
              variant="link"
              className={styles.assignmentsHeaderSelect}
              onClick={() => onToggleAll('select')}>
              Select All
            </Button>
          )}
        </div>
      )}
      <div className={cn(styles.assignmentsContent, { [styles.assignmentsContentMfaEnabled]: isMfaEnabled })}>
        {assignmentsNew.length ? (
          <ul>
            {assignmentsNew.map(({ resourceId, resourceName, isAssigned }) => {
              const isNew =
                assignmentsInit.find((i) => i.resourceId === resourceId)?.isAssigned !==
                assignments.find((i) => i.resourceId === resourceId)?.isAssigned;

              return (
                <li key={resourceId} className={cn(styles.assignmentsItem, { [styles.assignmentsItemNewItem]: isNew })}>
                  <Checkbox
                    id={resourceId}
                    name={resourceName}
                    checked={isAssigned}
                    onChange={(e) => onToggleItem(resourceId, e.target.checked)}
                    className={styles.assignmentsItemCheckbox}
                    label={resourceName}
                    labelPosition="left"
                  />
                </li>
              );
            })}
          </ul>
        ) : (
          <div className={styles.assignmentsNoResultsFound}>No results found</div>
        )}
      </div>
    </div>
  );
};

export default ModalAssigmentsList;
