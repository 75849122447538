import styles from './LicensesControls.module.scss';
import InputControls from '../InputControls/InputControls';
import { IWarning } from '../../PrimaryDomain';
import { ICorporateSubscription } from 'interfaces';

interface Props {
  licensesState: ICorporateSubscription;
  role: string;
  setLicensesState: React.Dispatch<React.SetStateAction<ICorporateSubscription>>;
  warning: IWarning;
  setWarning: React.Dispatch<React.SetStateAction<IWarning>>;
  minimumLicensesCount: number;
}

const LicensesControls = ({
  licensesState,
  role,
  setLicensesState,
  warning,
  setWarning,
  minimumLicensesCount,
}: Props) => (
  <div className={styles.controls}>
    <h6 className={styles.controlsTitle}>Primary Domain Licenses</h6>
    <div className={styles.controlsWrap}>
      <InputControls
        warning={warning}
        setWarning={setWarning}
        licensesState={licensesState}
        setLicensesState={setLicensesState}
        role={role}
        minimumLicensesCount={minimumLicensesCount}
        typeFunc="numberOfRdpLicenses"
        id="subs-primary-rdp-input"
      />
      <InputControls
        warning={warning}
        setWarning={setWarning}
        licensesState={licensesState}
        setLicensesState={setLicensesState}
        role={role}
        minimumLicensesCount={minimumLicensesCount}
        typeFunc="numberOfBitLockerLicenses"
        id="subs-primary-bl-input"
      />
    </div>
    <div className={styles.controlsWarning}>{warning.isActive ? warning.content : null}</div>
  </div>
);

export default LicensesControls;
