import { useEffect, useState } from 'react';
import { signOut } from 'helpers';
import styles from './IdleBoxLogin.module.scss';

const IdleBox = () => {
  const [countDown, setCountDown] = useState(60 * 5);

  useEffect(() => {
    const timerId = setInterval(() => {
      setCountDown((value) => value - 1);
    }, 1000);

    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    if (countDown < 1) {
      setCountDown(0);
      signOut();
    }
  }, [countDown]);

  const seconds = String(countDown % 60).padStart(2, '0');
  const minutes = String(Math.floor(countDown / 60)).padStart(2, '0');

  return (
    <div className={styles.IdleBox}>
      Session expires in:&nbsp;<b>{minutes}:{seconds}</b>
    </div>
  );
};

export default IdleBox;
