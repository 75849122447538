import { DropdownArray, SearchInput } from 'components';
import { notify } from 'utils';
import styles from './TableActions.module.scss';
import { useAppDispatch, useAppSelector } from 'store';
import { getDesktopAssignments } from 'store/slices/resourceAssignments';
import { replaceSpecChar } from 'helpers';

interface Props {
  domainId: string;
}

const TableActions = ({ domainId }: Props) => {
  const dispatch = useAppDispatch();
  const { desktopAssignmentsData } = useAppSelector((state) => state.resourceAssignments);
  const queryViewBy = desktopAssignmentsData?.queryConfig?.viewBy;

  const onChangeView = async (value: string) => {
    try {
      const queryConfig = {
        pageNumber: 1,
        viewBy: value,
        orderBy: 'name asc',
      };
      await dispatch(getDesktopAssignments({ domainId, query: queryConfig, _background: true })).unwrap();
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  const onSearch = async (value: string) => {
    try {
      const valueNew = replaceSpecChar(value);
      const queryConfig = {
        pageNumber: 1,
        searchQuery: valueNew,
      };
      await dispatch(getDesktopAssignments({ domainId, query: queryConfig, _background: true })).unwrap();
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  return (
    <div className={styles.tableActions}>
      View by
      <DropdownArray
        id="ra-desk-select"
        isSmall={false}
        isSearch={false}
        isFilter
        options={['Users', 'Computers']}
        defaultValue={queryViewBy}
        strightRight
        onChange={(value) => onChangeView(value as string)}
        className={styles.tableActionsDropdown}
        width="11.75em"
      />
      <SearchInput
        id="ra_desk-search"
        searchBy={queryViewBy === 'Users' ? 'Name' : 'Title'}
        strightLeft
        onChange={(value) => onSearch(value)}
      />
    </div>
  );
};

export default TableActions;
