import cn from 'classnames';
import { useAppDispatch, useAppSelector } from 'store';
import { editBranding, resetBranding } from 'store/slices/branding';
import { Button } from 'components';
import { notify } from 'utils';
import styles from './BrandingFooter.module.scss';

interface Props {
  isDisableClearBranding: boolean;
  openModalReset: () => void;
  isBrandingInfoChanged: boolean;
  hexError: boolean;
  domainName: string;
}

const BrandingFooter = ({
  isDisableClearBranding,
  openModalReset,
  isBrandingInfoChanged,
  hexError,
  domainName,
}: Props) => {
  const dispatch = useAppDispatch();
  const { brandingStatus } = useAppSelector((state) => state.branding);
  const { isLoading } = useAppSelector((state) => state.branding.editBrandingRequest);

  const updateBranding = async () => {
    try {
      await dispatch(editBranding()).unwrap();
      notify.success(`Branding is updated for ${domainName}`);
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  const cancelAllChanges = () => dispatch(resetBranding());

  return (
    <footer className={styles.footer}>
      <div className={styles.footerRow}>
        <div>
          <Button
            variant="link"
            isDisabled={isDisableClearBranding || !brandingStatus}
            onClick={openModalReset}
            className={styles.footerLink}>
            Reset Branding
          </Button>
        </div>
        <div className={styles.footerActions}>
          <div className={styles.footerActionsButtons}>
            <Button
              variant="secondary"
              id="branding-cancel"
              size="40"
              isDisabled={!isBrandingInfoChanged}
              onClick={cancelAllChanges}>
              Cancel
            </Button>
            <Button
              variant="primary"
              id="branding-save"
              size="40"
              isDisabled={!isBrandingInfoChanged || hexError}
              isLoading={isLoading}
              onClick={updateBranding}
              className={cn(styles.footerActionsSave, { [styles.loading]: isLoading })}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default BrandingFooter;
