import { useAppDispatch, useAppSelector } from 'store';
import { resetDomainBranding } from 'store/slices/branding';
import { Modal } from 'components';
import { notify } from 'utils';
import { AttentionIcon } from 'assets/img';
import styles from './ModalBrandingClear.module.scss';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  domainId: string;
  domainName: string;
}

const ModalBrandingClear = ({ isOpen, onRequestClose, domainId, domainName }: Props) => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.branding.resetDomainBrandingRequest);

  const clearBranding = async () => {
    try {
      await dispatch(resetDomainBranding(domainId)).unwrap();
      notify.success(`Branding is reset for ${domainName}`);
      onRequestClose();
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={
        <div className={styles.modalTitle}>
          <AttentionIcon /> Reset Branding?
        </div>
      }
      actionFunc={clearBranding}
      actionLoading={isLoading}
      actionTitle="Reset"
      variantBtn="delete">
      By clicking <strong>RESET</strong>, all customized data will be cleared
    </Modal>
  );
};

export default ModalBrandingClear;
