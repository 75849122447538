import { SocialFbIcon, SocialLiIcon, SocialXIcon, SocialYtIcon } from 'assets/img';
import { IBrandingContactInfo } from 'interfaces';

export const checkIfObjectsAreNotEqual = (
  brandingContactInfo: IBrandingContactInfo,
  formData: {
    [key: string]: string | boolean | number;
  },
) => JSON.stringify(brandingContactInfo) === JSON.stringify(formData);

export const getContactInputs = (address: any, city: any, state: any, country: any, zipCode: any) => [
  { name: 'address', value: address, placeholder: 'Address' },
  { name: 'city', value: city, placeholder: 'City' },
  { name: 'state', value: state, placeholder: 'State' },
  { name: 'country', value: country, placeholder: 'Country' },
  { name: 'zipCode', value: zipCode, placeholder: 'Zip Code' },
];

export const getSupportInputs = (phoneNumber: any, emailAddress: any, website: any) => [
  {
    name: 'phoneNumber',
    value: phoneNumber,
    placeholder: 'Phone Number',
    maxLength: 32,
  },
  { name: 'emailAddress', value: emailAddress, placeholder: 'Email address' },
  { name: 'website', value: website, placeholder: 'Website' },
];

export const getSocialsInputs = (facebookLink: any, linkedinLink: any, twitterLink: any, youtubeLink: any) => [
  {
    name: 'facebookLink',
    value: facebookLink,
    placeholder: 'Facebook',
    Icon: SocialFbIcon,
  },
  {
    name: 'linkedinLink',
    value: linkedinLink,
    placeholder: 'Linkedin',
    Icon: SocialLiIcon,
  },
  {
    name: 'twitterLink',
    value: twitterLink,
    placeholder: 'X',
    Icon: SocialXIcon,
  },
  {
    name: 'youtubeLink',
    value: youtubeLink,
    placeholder: 'Youtube',
    Icon: SocialYtIcon,
  },
];
