import { Modal } from 'components';
import { checkIsCompanyPage } from 'helpers';
import { IDomain } from 'interfaces';
import { useAppDispatch, useAppSelector } from 'store';
import { toggleAppPublishing } from 'store/slices/resourceAssignments';
import { notify } from 'utils';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  domain: IDomain;
}

const ToggleRemoteAppsModal = ({ isOpen, onRequestClose, domain }: Props) => {
  const isCompanyPage = checkIsCompanyPage();
  const dispatch = useAppDispatch();
  const { appPublishingCurrent } = useAppSelector((state) => (isCompanyPage ? state.user : state.customerDomains));
  const { isLoading } = useAppSelector((state) => state.resourceAssignments.toggleAppPublishingRequest);

  const onRemoteAppChange = async () => {
    try {
      const succMessage = (
        <p>
          <strong>Changes should be applied in a few minutes</strong>
          <br />
          Please refresh this page in 10 seconds
        </p>
      );
      await dispatch(toggleAppPublishing({ domainId: domain.id, isCompanyPage })).unwrap();
      notify.success(succMessage);
    } catch (err: any) {
      notify.error(err.message);
    } finally {
      onRequestClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={appPublishingCurrent ? 'Disable RemoteApp' : 'Enable RemoteApp'}
      actionFunc={onRemoteAppChange}
      actionLoading={isLoading}
      actionTitle={appPublishingCurrent ? 'Disable' : 'Enable'}>
      <p>
        By clicking {appPublishingCurrent ? 'DISABLE' : 'ENABLE'} you will turn {appPublishingCurrent ? 'off' : 'on'}{' '}
        RemoteApp
      </p>
      <p>Changes may be applied in few minutes</p>
    </Modal>
  );
};

export default ToggleRemoteAppsModal;
