import { StatusMessage, Link, SearchInput } from 'components';
import { corpAdmin, corpTechAdmin, custAdmin, spAdmin, spTechAdmin } from 'consts';
import { useAppDispatch, useAppSelector } from 'store';
import WorkspaceTable from '../../../WorkspaceTable/WorkspaceTable';
import { getMyDomainApps, IMyApps } from 'store/slices/workspace';
import { replaceSpecChar } from 'helpers';
import { notify } from 'utils';
import styles from './WorkspaceMyApps.module.scss';

interface Props {
  role: string;
}

const WorkspaceMyApps = ({ role }: Props) => {
  const dispatch = useAppDispatch();

  const { myApps } = useAppSelector((state) => state.workspace);

  const isAdminRole = [spAdmin, spTechAdmin, custAdmin, corpAdmin, corpTechAdmin].includes(role);
  const isEmptyScreen = !myApps?.data.length && !myApps?.queryConfig.searchQuery;

  const renderApps = () => {
    if (!myApps?.data.length && !myApps?.queryConfig.searchQuery) {
      if (isAdminRole) {
        return (
          <StatusMessage header="No apps">
            Please go to{' '}
            <Link to={{ pathname: '/company-management/resource-assignments', search: '?tab=app' }} bold>
              Resource Assignment
            </Link>{' '}
            in order to manage applications
          </StatusMessage>
        );
      }
      return (
        <StatusMessage header="No apps assigned">
          Please contact your company administrator for assistance
        </StatusMessage>
      );
    }

    return <WorkspaceTable<IMyApps> tableData={myApps} isComputers={false} dispatchAction={getMyDomainApps} />;
  };

  const onSearch = async (value: string) => {
    try {
      const valueNew = replaceSpecChar(value);
      const queryConfig = {
        pageNumber: 1,
        searchQuery: valueNew,
      };
      await dispatch(getMyDomainApps({ query: queryConfig })).unwrap();
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  return (
    <>
      <div className={styles.header}>
        {!isEmptyScreen && (
          <div className={styles.headerTotal}>
            Assigned Apps
            <span className={styles.headerTotalCounter}> ({myApps.pageMeta?.TotalCount || 0})</span>
          </div>
        )}
        {!isEmptyScreen && <SearchInput id="ws-search" searchBy="App Title" onChange={(value) => onSearch(value)} />}
      </div>

      {renderApps()}
    </>
  );
};

export default WorkspaceMyApps;
