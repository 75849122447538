import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import LoginPreview from './components/LoginPreview/LoginPreview';
import WorkspacePreview from './components/WorkspacePreview/WorkspacePreview';
import { invertColor } from '../../helpers/colorsHelper';
import styles from './BrandingPreview.module.scss';
import { useAppSelector } from 'store';
import { useMemo } from 'react';

const BrandingPreview = () => {
  const { branding } = useAppSelector((state) => state.branding);

  const invertedColor = useMemo(
    () => invertColor(branding?.modified?.backGroundColor) || false,
    [branding?.modified?.backGroundColor],
  );
  const invertedButtonColor = useMemo(
    () => invertColor(branding?.modified?.buttonColor) || false,
    [branding?.modified?.buttonColor],
  );

  return (
    <Tabs selectedTabClassName={styles.tabListTabSelected}>
      <TabList className={styles.tabList}>
        <Tab className={styles.tabListTab}>
          <span id="login-preview">Login Preview</span>
        </Tab>
        <Tab className={styles.tabListTab}>
          <span id="workspace-preview">Workspace Preview</span>
        </Tab>
      </TabList>
      <TabPanel>
        <LoginPreview invertedButtonColor={invertedButtonColor} />
      </TabPanel>
      <TabPanel>
        <WorkspacePreview invertedColor={invertedColor} />
      </TabPanel>
    </Tabs>
  );
};
export default BrandingPreview;
