import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import ResetViaSMS from './pages/ResetViaSMS/ResetViaSMS';
import ResetViaEmail from './pages/ResetViaEmail/ResetViaEmail';

const ResetMfa = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${url}/sms`}>
        <ResetViaSMS />
      </Route>
      <Route path={`${url}/email`}>
        <ResetViaEmail />
      </Route>
      <Route path="*" render={() => <Redirect to="/login/sign-in" />} />
    </Switch>
  );
};

export default ResetMfa;
