import { useState, useEffect } from 'react';
import { notify } from 'utils';
import ToggleEnableModal from './components/ToggleEnableModal/ToggleEnableModal';
import AzureAdLoginStatus from './components/AzureAdLoginStatus/AzureAdLoginStatus';
import HeaderAction from './components/HeaderAction/HeaderAction';
import AzureAdLoginTitle from './components/AzureAdLoginTitle/AzureAdLoginTitle';
import AzureAdLoginTable from './components/AzureAdLoginTable/AzureAdLoginTable';
import NoUsers from './components/NoUsers/NoUsers';
import AzureAdLoginHeader from './components/AzureAdLoginHeader/AzureAdLoginHeader';
import { checkActionType } from './utils/azureAdLoginUtils';
import AzureAdLoginInfo from './components/AzureAdLoginInfo/AzureAdLoginInfo';
import {
  IAzureLoginUser,
  changeAzureLoginUserStatus,
  getAzureAdLoginData,
} from 'store/slices/securityMgmt/azureAdLogin';
import { useAppDispatch, useAppSelector } from 'store';
import { Loader } from 'components';
import { IDomain } from 'interfaces';
import styles from './AzureAdLogin.module.scss';

interface Props {
  domain: IDomain;
  isCompanyPage: boolean;
}

const AzureAdLogin = ({ domain, isCompanyPage }: Props) => {
  const { isLoading } = useAppSelector((state) => state.azureAdLogin.getAzureAdLoginDataRequest);
  const { azureLoginData } = useAppSelector((state) => state.azureAdLogin);
  const dispatch = useAppDispatch();

  const [showEnableModal, setShowEnableModal] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<IAzureLoginUser[]>([]);
  const [isHeaderBtnLoading, setIsHeaderBtnLoading] = useState<boolean>(false);
  const [btnLoadingCurrent, setBtnLoadingCurrent] = useState<string[]>([]);

  const toggleShowEnableModal = () => setShowEnableModal(!showEnableModal);

  const fetchData = async () => {
    try {
      const queryConfig = {
        pageNumber: 1,
        pageSize: 10,
        searchQuery: '',
        orderBy: 'displayName asc',
      };
      await dispatch(getAzureAdLoginData({ domainId: domain.id, query: queryConfig })).unwrap();
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onChangeStaySignedInForUsers = async (isHeaderBtn: boolean, user?: IAzureLoginUser) => {
    if (isHeaderBtn) {
      setIsHeaderBtnLoading(true);
    } else {
      setBtnLoadingCurrent((val) => [...val, user?.userId ?? '']);
    }
    const selectedUserIds: string[] = isHeaderBtn ? selectedUsers.map((el) => el.userId) : [user?.userId ?? ''];
    try {
      await dispatch(changeAzureLoginUserStatus(selectedUserIds)).unwrap();
      const notifyText = checkActionType(selectedUsers, user);
      notify.success(notifyText);
    } catch (err: any) {
      notify.error(err.message);
    } finally {
      if (isHeaderBtn) {
        setIsHeaderBtnLoading(false);
      } else {
        setBtnLoadingCurrent((val) => val.filter((i) => i !== (user?.userId ?? '')));
      }
      if (selectedUsers.length) setSelectedUsers([]);
    }
  };

  return (
    <div className={styles.azureLogin}>
      <AzureAdLoginHeader domain={domain} isCompanyPage={isCompanyPage} />

      <AzureAdLoginTitle />
      {isLoading ? (
        <Loader id="loading-azure-login" />
      ) : azureLoginData?.isNoData ? (
        <NoUsers />
      ) : (
        <>
          {selectedUsers.length > 0 ? (
            <HeaderAction
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              onChangeStaySignedInForUsers={onChangeStaySignedInForUsers}
              azureLoginData={azureLoginData}
              isHeaderBtnLoading={isHeaderBtnLoading}
            />
          ) : (
            <AzureAdLoginStatus
              isAzureAdLoginEnabled={azureLoginData?.isStaySignedInEnabled || false}
              toggleShowEnableModal={toggleShowEnableModal}
            />
          )}

          {azureLoginData?.isStaySignedInEnabled ? (
            <AzureAdLoginTable
              domainId={domain.id}
              azureLoginData={azureLoginData}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              btnLoadingCurrent={btnLoadingCurrent}
              onChangeStaySignedInForUsers={onChangeStaySignedInForUsers}
            />
          ) : (
            <AzureAdLoginInfo />
          )}
          {showEnableModal && (
            <ToggleEnableModal
              showEnableModal={showEnableModal}
              onRequestClose={toggleShowEnableModal}
              domain={domain}
              isAzureAdLoginEnabled={azureLoginData?.isStaySignedInEnabled || false}
            />
          )}
        </>
      )}
    </div>
  );
};

export default AzureAdLogin;
