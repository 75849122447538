import { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useHistory } from 'react-router-dom';
import { getUserRole, notify, objectsAreNotEqual } from 'utils';
import { Loader, RouteLeavingGuard, StatusMessage } from 'components';
import { SettingTabs, TABS } from 'consts';
import {
  checkIsCustomDomain,
  checkIsMultipleSettings,
  checkIsDropdownRestricted,
  checkIsShowCustDomains,
  checkIsHideZeroTrust,
} from './helpers/settingsHelper';
import SettingsHeader from './components/SettingsHeader/SettingsHeader';
import ModalSaveAs from './components/ModalSaveAs/ModalSaveAs';
import ModalResetAll from './components/ModalResetAll/ModalResetAll';
import ModalConfirmChangeDomain from './components/ModalConfirmChangeDomain/ModalConfirmChangeDomain';
import ModalResetSingleDomain from './components/ModalResetSingleDomain/ModalResetSingleDomain';
import { addCustomResolutions } from './utils/addCustomResolutions';
import { onCustomResSave } from './utils/onCustomResSave';
import TabContent from './components/TabContent';
import { useAppDispatch, useAppSelector } from 'store';
import {
  getDomainSettings,
  getCustomerDomains,
  changeCurrentDomain,
  getCustomerDomainSettings,
  changeSettings,
  saveCustomerDomainSettings,
  saveDomainSettings,
  discardChanges,
} from 'store/slices/settings';
import { ISettings } from 'interfaces';
import styles from './Settings.module.scss';
import { AsyncThunkAction } from '@reduxjs/toolkit';

const Settings = () => {
  const dispatch = useAppDispatch();
  const { isError, error } = useAppSelector((state) => state.settings.getDomainSettingsRequest);
  const { settings, settingDomains, currentDomain } = useAppSelector((state) => state.settings);
  const { user } = useAppSelector((state) => state.user);

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isDomainLoading, setIsDomainLoading] = useState(false);
  const [tabsContent, setTabsContent] = useState(TABS);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [changeDomainValue, setChangeDomainValue] = useState<string>('');
  const [showModalConfirmChangeDomain, setShowModalConfirmChangeDomain] = useState(false);
  const [domainToChange, setDomainToChange] = useState<string>('');
  const [showModalResetAll, setShowModalResetAll] = useState(false);
  const [showModalResetSingleDomain, setShowModalResetSingleDomain] = useState(false);
  const [showModalSaveAs, setShowModalSaveAs] = useState(false);

  const role = getUserRole(user);
  const isMultipleSettings = checkIsMultipleSettings(role);
  const isDropdownRestricted = checkIsDropdownRestricted(role);
  const isCustomDomain = checkIsCustomDomain(currentDomain);
  const isShowCustDomains = checkIsShowCustDomains(role);

  const [isZeroTrustMsgOpen, setIsZeroTrustMsgOpen] = useState(true);
  const isHideZeroTrust = checkIsHideZeroTrust(role);

  const toggleShowModalConfirmChangeDomain = (domainName: string) => {
    if (!showModalConfirmChangeDomain) {
      setShowModalConfirmChangeDomain(true);
      setDomainToChange(domainName);
    } else {
      setDomainToChange('');
      setShowModalConfirmChangeDomain(false);
    }
  };
  const toggleShowModalResetAll = () => setShowModalResetAll(!showModalResetAll);
  const toggleShowModalResetSingleDomain = () => setShowModalResetSingleDomain(!showModalResetSingleDomain);
  const toggleShowModalSaveAs = () => setShowModalSaveAs(!showModalSaveAs);

  const handleAddCustomResolutions = (data: ISettings) => {
    const tabsContentNew = addCustomResolutions(tabsContent, data);
    setTabsContent(tabsContentNew);
  };

  useEffect(() => {
    const fetchData = async () => {
      const promises: AsyncThunkAction<any, any, any>[] = [getDomainSettings({ user, _role: role })];
      if (isShowCustDomains) {
        promises.push(getCustomerDomains(role));
      }
      const data = await Promise.allSettled(promises.map((p) => dispatch(p).unwrap()));
      if (data[0].status === 'fulfilled') {
        if (!data[0].value.rdpConfiguration) {
          notify.error(`Sorry, there no settings for <b>${currentDomain}</b>`);
        }
        handleAddCustomResolutions(data[0].value.rdpConfiguration);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [dispatch, getDomainSettings, getCustomerDomains, isShowCustDomains]);

  const onOpenShowModalSaveAs = (domainName: string) => {
    setChangeDomainValue(domainName);
    toggleShowModalSaveAs();
  };

  const handleChangeDomain = async (domainName: string, discard: boolean) => {
    if (!discard && objectsAreNotEqual(settings)) {
      toggleShowModalConfirmChangeDomain(domainName);
    } else {
      try {
        setIsDomainLoading(true);
        if (domainName === 'My Settings') {
          const settings = await dispatch(getDomainSettings({ user, _role: role })).unwrap();
          handleAddCustomResolutions(settings.rdpConfiguration);
        } else {
          const settings = await dispatch(getCustomerDomainSettings(settingDomains[domainName].id)).unwrap();
          handleAddCustomResolutions(settings);
        }
      } catch (err: any) {
        notify.error(err.message);
      } finally {
        dispatch(changeCurrentDomain(domainName));
        setIsDomainLoading(false);
        setShowModalConfirmChangeDomain(false);
      }
    }
  };

  const onModalClose = (newDomainValue?: any) => {
    setIsModalOpen(false);
    if (newDomainValue && !newDomainValue.target) {
      handleChangeDomain(newDomainValue, true);
    }
  };

  const onCloseShowModalSaveAs = (domainName?: any) => {
    if (domainName && !domainName.target) {
      handleChangeDomain(domainName, true);
    }
    toggleShowModalSaveAs();
  };

  const handleCustomResSave = (customRes: { width: number; height: number }) => {
    const { tabsContentNew, domainSettingsNew } = onCustomResSave(tabsContent, settings, customRes);
    setTabsContent(tabsContentNew);
    dispatch(changeSettings(domainSettingsNew));
  };

  const handleDiscardChanges = (domainName: string) => {
    dispatch(discardChanges());
    setIsModalOpen(false);
    handleChangeDomain(domainName, true);
  };

  const handleSaveSingleSettings = async (value: string, isChangeDomain: string) => {
    if (isModalOpen) {
      onModalClose();
    }
    if (value === 'Save As') {
      onOpenShowModalSaveAs(isChangeDomain);
    } else {
      setIsSaving(true);
      if (isCustomDomain) {
        try {
          await dispatch(
            saveCustomerDomainSettings({
              configuration: settings.modified!,
              domainIds: [settingDomains[currentDomain].id],
            }),
          ).unwrap();
          notify.success('Settings saved');
          if (isChangeDomain) {
            handleChangeDomain(value, true);
          }
          setIsSaving(false);
        } catch (err: any) {
          notify.error(err.message);
          setIsSaving(false);
        }
      } else {
        try {
          await dispatch(saveDomainSettings(settings.modified!)).unwrap();
          notify.success('Settings saved');
          if (isChangeDomain) {
            handleChangeDomain(value, true);
          }
          setIsSaving(false);
        } catch (err: any) {
          notify.error(err.message);
          setIsSaving(false);
        }
      }
    }
  };

  const onResetSettings = () => {
    if (isMultipleSettings) {
      toggleShowModalResetAll();
    } else {
      toggleShowModalResetSingleDomain();
    }
  };

  if (isLoading) {
    return (
      <div className={styles.settings}>
        <Loader id="loading-settings" />
      </div>
    );
  }

  if (isError) {
    return (
      <div className={styles.settings}>
        <StatusMessage error>{error}</StatusMessage>
      </div>
    );
  }

  return (
    <div className={styles.settings}>
      <div>
        <SettingsHeader
          role={role}
          isSaving={isSaving}
          isMultipleSettings={isMultipleSettings}
          onChangeDomain={handleChangeDomain}
          onSaveSettings={handleSaveSingleSettings}
          onResetSettings={onResetSettings}
        />

        <Tabs className={styles.reactTabs}>
          <div className={styles.reactTabsWrapper}>
            {Object.keys(tabsContent).map((key) => (
              <TabPanel key={key}>
                {isDomainLoading ? (
                  <Loader id="loading-setting-domain" />
                ) : (
                  Object.entries(tabsContent[key as keyof SettingTabs]).map((item) => (
                    <TabContent
                      key={item[0]}
                      onCustomResSave={handleCustomResSave}
                      item={item[1]}
                      label={item[0]}
                      isHideZeroTrust={isHideZeroTrust}
                      isZeroTrustMsgOpen={isZeroTrustMsgOpen}
                      setIsZeroTrustMsgOpen={setIsZeroTrustMsgOpen}
                    />
                  ))
                )}
              </TabPanel>
            ))}
          </div>
          <TabList>
            <Tab>
              <span id="setting-general">General</span>
            </Tab>
            <Tab>
              <span id="setting-display">Display</span>
            </Tab>
            <Tab>
              <span id="setting-device-redirection">Device redirection</span>
            </Tab>
            <Tab>
              <span id="setting-audio-video">Audio and video</span>
            </Tab>
          </TabList>
        </Tabs>

        <RouteLeavingGuard
          when={objectsAreNotEqual(settings)}
          navigate={({ pathname }) => history.push(pathname)}
          shouldBlockNavigation={() => objectsAreNotEqual(settings)}
          modalConfig={{
            label: 'Leave Modal',
            title: 'Discard changes',
            message: 'Do you really want to leave and discard changes?',
            btnClose: 'Stay on Page',
            btnAccept: 'Leave',
          }}
        />
      </div>
      {showModalConfirmChangeDomain && (
        <ModalConfirmChangeDomain
          isOpen={showModalConfirmChangeDomain}
          onRequestClose={toggleShowModalConfirmChangeDomain}
          onSaveSettings={handleSaveSingleSettings}
          isCustomDomain={isCustomDomain}
          domainToChange={domainToChange}
          onDiscardChanges={handleDiscardChanges}
        />
      )}
      {showModalResetAll && (
        <ModalResetAll
          isOpen={showModalResetAll}
          onRequestClose={toggleShowModalResetAll}
          userDomainId={user.domainId}
          isDropdownRestricted={isDropdownRestricted}
          setTabsContent={setTabsContent}
        />
      )}
      {showModalResetSingleDomain && (
        <ModalResetSingleDomain
          isOpen={showModalResetSingleDomain}
          onRequestClose={toggleShowModalResetSingleDomain}
          isCustomDomain={isCustomDomain}
          setTabsContent={setTabsContent}
        />
      )}
      {showModalSaveAs && (
        <ModalSaveAs
          isOpen={showModalSaveAs}
          onRequestClose={onCloseShowModalSaveAs}
          role={role}
          userDomainId={user.domainId}
          isDropdownRestricted={isDropdownRestricted}
          changeDomainValue={changeDomainValue}
        />
      )}
    </div>
  );
};

export default Settings;
