import { useAppDispatch } from 'store';
import { notify } from 'utils';
import { DropdownArray } from 'components';
import { IResetPhoneData, getResetPhoneData } from 'store/slices/securityMgmt/phoneSetup';
import { FILTER_OPTIONS } from '../../utils/consts';
import { convertToCamelCase } from 'helpers';
import { DropdownOption } from 'components/DropdownArray/DropdownArray';
import styles from './ViewByFilter.module.scss';

interface Props {
  resetPhoneData: IResetPhoneData | null;
  domainId: string;
}

const ViewByFilter = ({ resetPhoneData, domainId }: Props) => {
  const dispatch = useAppDispatch();
  const viewBy = resetPhoneData?.queryConfig?.viewBy;
  const viewByFrmttd = viewBy?.split(/(?=[A-Z])/).join(' ');

  const viewByFrmttdHtml = (
    <>
      {viewByFrmttd} <b>({resetPhoneData?.pageMeta?.TotalCount})</b>
    </>
  );

  const onChangeView = async (option: DropdownOption) => {
    try {
      const id = option.id as string;
      const viewBy = convertToCamelCase(id);
      const queryConfig = {
        pageNumber: 1,
        viewBy,
        orderBy: 'displayName asc',
      };
      await dispatch(getResetPhoneData({ domainId, query: queryConfig, _background: true })).unwrap();
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  const filterOptions = FILTER_OPTIONS(resetPhoneData?.pageMeta);

  return (
    <div className={styles.filter}>
      View by:
      <DropdownArray
        id="ra-app-select"
        isSmall={false}
        isSearch={false}
        isFilter
        options={filterOptions as unknown as DropdownOption[]}
        defaultValue={viewByFrmttd}
        defaultValueHtml={viewByFrmttdHtml}
        onChange={(value) => onChangeView(value as DropdownOption)}
        className={styles.appsHeaderWrapDropdown}
        width="20em"
      />
    </div>
  );
};

export default ViewByFilter;
