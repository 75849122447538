import cn from 'classnames';
import DomainInfoMsg from '../DomainInfoMsg/DomainInfoMsg';
import { getDomainADStatus, getDomainStatus } from 'helpers';
import styles from './DomainStatus.module.scss';
import { InfoActiveIcon, InfoIcon } from 'assets/img';
import { IDomain } from 'interfaces';

interface Props {
  domain: IDomain;
  infoStatusActive: string;
  setInfoStatusActive: (id: string) => void;
}

const STATUS_MAP: { [key: number]: { status: string } } = {
  0: { status: 'Pending domain validation' },
  1: { status: 'Pending Connection to a Directory Service' },
  2: { status: 'Active' },
};

const DomainStatus = ({ domain, infoStatusActive, setInfoStatusActive }: Props) => {
  const { isDomainNotValidated, isDomainNotActivated, isDomainActive } = getDomainStatus(domain);
  const { isDomainAzure } = getDomainADStatus(domain);
  const isActive = isDomainActive && !isDomainAzure;
  const isOneSentryOff = domain.sentries?.length === 1 && !domain.sentries[0].isOnline;
  const infoIconColor = domain.sentries?.length
    ? domain.sentries.some((i) => !i.isOnline)
      ? styles.warning
      : styles.success
    : styles.error;

  return (
    <>
      <div
        className={cn(styles.status, {
          [styles.notValidated]: isDomainNotValidated,
          [styles.notActivated]: isDomainNotActivated,
          [styles.active]: isDomainActive,
        })}>
        {domain.status !== undefined && STATUS_MAP[domain.status]?.status}
        {isActive &&
          (infoStatusActive === domain.id ? (
            <InfoActiveIcon
              className={cn(styles.status, infoIconColor)}
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                setInfoStatusActive('');
              }}
            />
          ) : (
            <InfoIcon
              className={cn(styles.status, infoIconColor)}
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                setInfoStatusActive(domain.id);
              }}
            />
          ))}
      </div>
      {infoStatusActive === domain.id &&
        (domain.sentries?.length ? (
          <DomainInfoMsg
            header={isOneSentryOff ? 'Sentry Agent is curently offline' : 'Sentry Hosts'}
            data={domain.sentries}
            onClose={() => setInfoStatusActive('')}
          />
        ) : (
          <DomainInfoMsg
            type="assigned"
            data={['No Sentry Agent curently installed']}
            onClose={() => setInfoStatusActive('')}
          />
        ))}
    </>
  );
};

export default DomainStatus;
