import { useRef, useState } from 'react';
import cn from 'classnames';
import { DropdownObject, Modal, SwitchCheckbox } from 'components';
import styles from './ToggleEnableModal.module.scss';
import { notify } from 'utils';
import { disableShortcut, enableShortcut } from 'store/slices/securityMgmt/shortcutMgmt';
import { useAppDispatch, useAppSelector } from 'store';

const SHORTCUT_PERIOD_OPTIONS = {
  0: [0, 'Never'],
  1: [30, '30 days'],
  2: [45, '45 days'],
  3: [90, '90 days'],
  4: [-1, 'Set custom'],
};

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  domainId: string;
}

const ToggleEnableModal = ({ isOpen, onRequestClose, domainId }: Props) => {
  const dispatch = useAppDispatch();
  const isShortcutEnabled = useAppSelector((state) => state.shortcutMgmt.shortcutMgmtData?.isShortcutEnabled);
  const { isLoading } = useAppSelector((state) => state.shortcutMgmt.enableShortcutRequest);
  const { isLoading: isLoadingDisable } = useAppSelector((state) => state.shortcutMgmt.disableShortcutRequest);

  const [isEnableShortcutForAll, setIsEnableShortcutForAll] = useState<boolean>(false);
  const [isCustomPeriodActive, setIsCustomPeriodActive] = useState<boolean>(false);
  const [selectedPeriod, setSelectedPeriod] = useState<number>(0);
  const [customPeriod, setCustomPeriod] = useState<string>('');
  const [customPeriodError, setCustomPeriodError] = useState<string>('');

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleChangeDropdownValue = (value: number) => {
    setSelectedPeriod(value);
    setCustomPeriod('');
    setIsCustomPeriodActive(false);
  };

  const onCustomPeriodOpen = () => {
    setSelectedPeriod(-1);
    setIsCustomPeriodActive(true);
    setTimeout(() => inputRef.current?.focus(), 150);
  };

  const handleInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (/^\d*$/.test(inputValue)) {
      setCustomPeriod(inputValue.slice(0, e.target.maxLength));
    }
  };

  const onEnableShortcut = async (enableForAllUsers: boolean, expirationDays: number) => {
    try {
      await dispatch(enableShortcut({ domainId: domainId, enableForAllUsers, expirationDays })).unwrap();
      notify.success('Desktop Shortcut is enabled for domain', 3000);
    } catch (err: any) {
      notify.error(err.message);
    } finally {
      onRequestClose();
    }
  };

  const onDisableShortcut = async () => {
    try {
      await dispatch(disableShortcut({ domainId: domainId })).unwrap();
      notify.success('Desktop Shortcut is disabled for domain', 3000);
    } catch (err: any) {
      notify.error(err.message);
    } finally {
      onRequestClose();
    }
  };

  const onToggleShortcutHandler = async () => {
    if (!isShortcutEnabled) {
      if (isCustomPeriodActive && (!Number(customPeriod) || Number(customPeriod) < 1 || Number(customPeriod) > 365)) {
        setCustomPeriodError('You can set between 1 to 365 days');
      } else {
        await onEnableShortcut(isEnableShortcutForAll, Number(customPeriod || selectedPeriod));
      }
    } else {
      await onDisableShortcut();
    }
  };

  const onNewDomainKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Backspace' || event.key === 'Delete') {
      setCustomPeriodError('');
    }
    if (event.key === 'Enter') {
      onToggleShortcutHandler();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={isShortcutEnabled ? 'Disable Desktop Shortcut for domain?' : 'Enable Desktop Shortcut for domain?'}
      actionFunc={onToggleShortcutHandler}
      actionTitle={isShortcutEnabled ? 'Disable' : 'Enable'}
      variantBtn="primary"
      cancelTitle="Cancel"
      actionLoading={isLoading || isLoadingDisable}
      shouldCloseOnOverlayClick={false}>
      <>
        {isShortcutEnabled ? (
          <>
            <p>
              By disabling this option, end users won't be able to create Desktop Shortcuts from the TruGrid Desktop
              Connectors
            </p>
            <br />
            <p>Disabling this option will override individual settings for this feature</p>
          </>
        ) : (
          <div className={styles.modalInfo}>
            <p className={styles.modalInfoText}>
              By enabling this option, end users will be able to create Desktop Shortcuts from TruGrid Desktop
              Connectors
            </p>
            <div className={styles.modalInfoRow}>
              <SwitchCheckbox
                checked={isEnableShortcutForAll}
                onChange={() => setIsEnableShortcutForAll((value) => !value)}
              />
              <span className={styles.modalInfoText}>Enable for all users</span>
            </div>
          </div>
        )}
        {!isShortcutEnabled && (
          <div className={styles.modalInfo}>
            <p className={styles.modalInfoText}>
              Allow end users to remember credentials on devices they trust for selected period
            </p>
            <div className={styles.modalWrap}>
              <DropdownObject
                id="enable-shortcut-select"
                options={SHORTCUT_PERIOD_OPTIONS as any}
                defaultPos={selectedPeriod}
                isMulti
                onChange={(value: string | number) => handleChangeDropdownValue(value as number)}
                customField={-1}
                onCustomEvent={onCustomPeriodOpen}
                className={styles.modalDropdown}
                optionsClassName={styles.modalDropdownOptions}
              />
              {isCustomPeriodActive && (
                <div className={styles.modalInputWrap}>
                  <input
                    id="enter-custom-period"
                    type="text"
                    name="custom-period"
                    ref={inputRef}
                    placeholder="Add 1 to 365 days"
                    value={customPeriod}
                    maxLength={3}
                    onChange={handleInputValue}
                    onFocus={() => setCustomPeriodError('')}
                    onKeyDown={onNewDomainKeyDown}
                    className={cn(styles.modalInput, {
                      [styles.hasValue]: customPeriod?.length > 0,
                      [styles.error]: !!customPeriodError,
                    })}
                  />
                  {customPeriodError && <div className={styles.modalInputError}>{customPeriodError}</div>}
                </div>
              )}
            </div>
          </div>
        )}
      </>
    </Modal>
  );
};

export default ToggleEnableModal;
