import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CodeInput, ResendCode } from 'components';
import { notify } from 'utils';
import { useTimer } from 'hooks';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import { useAppDispatch, useAppSelector } from 'store';
import { resetMfaWithPin, verifyPhoneNumber } from 'store/slices/auth/mfa';
import styles from '../ResetViaSMS.module.scss';
import { SerializedError } from '@reduxjs/toolkit';

interface Props {
  phoneDigits: string;
}

const VerificationCode = ({ phoneDigits }: Props) => {
  const dispatch = useAppDispatch();
  const { phoneMask } = useAppSelector((state) => state.auth.signInData);
  const { pinHash } = useAppSelector((state) => state.mfa);
  const { isLoading } = useAppSelector((state) => state.mfa.resetMfaWithPinRequest);
  const { isLoading: isResetLoading } = useAppSelector((state) => state.mfa.verifyPhoneNumberRequest);
  const [codeValue, setCodeValue] = useState('');
  const [isError, setIsError] = useState('');

  const history = useHistory();
  const { timeLeft, resetCountdown } = useTimer(60);

  const clearError = () => setIsError('');

  const resendVerificationCode = async () => {
    try {
      await dispatch(verifyPhoneNumber(phoneDigits)).unwrap();
      notify.success('SMS has been sent');
    } catch (err: any) {
      if ((err as SerializedError).code === '409' || (err as SerializedError).code === '405') {
        notify.error(err.message);
        history.replace('/login');
      } else {
        notify.error(err.message);
      }
    } finally {
      resetCountdown(60);
    }
  };

  const handleSendVerificationCode = async () => {
    try {
      await dispatch(resetMfaWithPin({ mfaPin: codeValue, mfaHash: pinHash })).unwrap();
      notify.success('MFA has been successfully reset');
      history.replace('/login');
    } catch (err: any) {
      if ((err as SerializedError).code === '405') {
        notify.error(err.message);
        history.replace('/login');
      }
      setIsError(err.message);
    }
  };

  return (
    <>
      <LoginHeader title="Reset Your MFA" stepCount="2" currentStep="2" />
      <div className={styles.resetMfaInfo}>
        We’ve sent a verification code to your phone at <strong>{phoneMask}</strong>
      </div>
      <div className={styles.resetMfaForm}>
        <div className={styles.resetMfaLabel}>Enter Verification Code</div>
        <CodeInput
          fields={4}
          isError={!!isError}
          errorMessage={isError}
          clearError={clearError}
          isDisable={isLoading || isResetLoading}
          value={codeValue}
          setValue={setCodeValue}
          handleSend={handleSendVerificationCode}
        />
        <div className={styles.resetMfaResend}>
          <ResendCode
            countDown={timeLeft}
            handleSend={resendVerificationCode}
            isDisabled={isLoading || isResetLoading}
          />
        </div>
      </div>
    </>
  );
};

export default VerificationCode;
