import { IMfaData } from 'store/slices/securityMgmt/mfaMgmt';

export const getViewByOptions = (mfaData: IMfaData) => {
  const isSetupView = mfaData.queryConfig?.viewBy?.toLowerCase() === 'setuppending';
  const isDisabledView = mfaData.queryConfig?.viewBy?.toLowerCase() === 'disabled';
  const isResetView = mfaData.queryConfig?.viewBy?.toLowerCase() === 'inresetmode';
  const isAllView = mfaData.queryConfig?.viewBy?.toLowerCase() === 'all';
  return { isSetupView, isDisabledView, isResetView, isAllView };
};

export const getCurrentPageUserIds = (isSetupView: boolean, isResetView: boolean, mfaData: IMfaData) => {
  const currentPageUserIds =
    isSetupView || isResetView
      ? (mfaData.data ?? []).filter((i) => !i.isAdmin).map((i) => i.userId)
      : (mfaData.data ?? []).map((i) => i.userId);

  return currentPageUserIds;
};
