import { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { DomainStatusMsg, Loader } from 'components';
import { getDomainADStatus, isSentryDeleted } from 'helpers';
import { useLocation } from 'react-router-dom';
import MfaMgmt from './pages/MfaMgmt/MfaMgmt';
import PasswordMgmt from './pages/PasswordMgmt/PasswordMgmt';
import GeoBlocking from './pages/GeoBlocking/GeoBlocking';
import DesktopManagement from './pages/DesktopManagement/DesktopManagement';
import { IDomain } from 'interfaces';
import { useAppDispatch } from 'store';
import { getMfaData } from 'store/slices/securityMgmt/mfaMgmt';
import { getGeoBlockingData } from 'store/slices/securityMgmt/geoBlocking';
import { handleVisibleTabs } from './utils/securityMgmtUtils';
import IPWhitelist from './pages/IPWhitelist/IPWhitelist';
import { getPassMgmtData } from 'store/slices/securityMgmt/passMgmt';
import styles from './SecurityMgmt.module.scss';
import { AsyncThunkAction } from '@reduxjs/toolkit';

interface Props {
  userRole: string;
  domain: IDomain;
  isCompanyPage: boolean;
  isDomainNotActivated: boolean;
}

const SecurityMgmt = ({ userRole, domain, isCompanyPage, isDomainNotActivated }: Props) => {
  const dispatch = useAppDispatch();
  const sentryDeleted: boolean = isSentryDeleted(domain);
  const [isLoading, setIsLoading] = useState<boolean>(!sentryDeleted);
  const { isDomainOnPremise } = getDomainADStatus(domain);
  const { search } = useLocation();

  const [tabIndex, setTabIndex] = useState(0);

  const {
    isVisibleMfaManagement,
    isVisiblePassMgmt,
    isVisibleDesktopMgmt,
    isVisibleGeoBlocking,
    isVisibleIpWhiteList,
  } = handleVisibleTabs(isDomainOnPremise, isDomainNotActivated, sentryDeleted, userRole, isCompanyPage);

  useEffect(() => {
    const params = new URLSearchParams(search);
    if (params.get('tab') === 'desktop-management') {
      if (isDomainOnPremise && isVisiblePassMgmt) {
        setTabIndex(2);
      } else if (isDomainOnPremise) {
        setTabIndex(1);
      } else {
        setTabIndex(0);
      }
    }
    if (params.get('tab') === 'password-management') {
      setTabIndex(1);
    }
  }, [search]);

  const fetchData = async () => {
    const queryConfig = {
      pageNumber: 1,
      pageSize: 10,
      searchQuery: '',
      orderBy: 'displayName asc',
      viewBy: 'All',
    };
    if (!isDomainNotActivated) {
      const promises: AsyncThunkAction<any, any, any>[] = [];
      if (isVisibleMfaManagement) {
        promises.push(getMfaData({ domainId: domain.id, query: queryConfig }));
      }
      if (isVisiblePassMgmt) {
        promises.push(getPassMgmtData({ domainId: domain.id }));
      }
      if (isVisibleGeoBlocking) {
        promises.push(getGeoBlockingData({ domainId: domain.id, query: { orderBy: 'countryName asc' } }));
      }
      await Promise.allSettled(promises.map((p) => dispatch(p).unwrap()));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
    return () => setIsLoading(false);
  }, []);

  if (isLoading) return <Loader id="loading-security-mgmt" />;

  if ((isDomainNotActivated || sentryDeleted) && !isCompanyPage) {
    return <DomainStatusMsg domainData={domain} isSentryDeleted={sentryDeleted} pageName="Security Management" />;
  }

  return (
    <div className={styles.securityMgmt}>
      <h3 className={styles.securityMgmtTitle}>Security Management</h3>
      <Tabs forceRenderTabPanel selectedIndex={tabIndex} onSelect={(index: number) => setTabIndex(index)}>
        <TabList>
          {isVisibleMfaManagement && <Tab>MFA Management</Tab>}
          {isVisiblePassMgmt && <Tab>Password Management</Tab>}
          {isVisibleDesktopMgmt && <Tab>Desktop Management</Tab>}
          {isVisibleGeoBlocking && <Tab>Geo-Blocking</Tab>}
          {isVisibleIpWhiteList && <Tab>IP Whitelist</Tab>}
        </TabList>
        {isVisibleMfaManagement && (
          <TabPanel>
            <MfaMgmt domain={domain} />
          </TabPanel>
        )}
        {isVisiblePassMgmt && (
          <TabPanel>
            <PasswordMgmt domainId={domain.id} />
          </TabPanel>
        )}
        {isVisibleDesktopMgmt && (
          <TabPanel>
            <DesktopManagement isDomainOnPremise={isDomainOnPremise} />
          </TabPanel>
        )}
        {isVisibleGeoBlocking && (
          <TabPanel>
            <GeoBlocking domain={domain} isCompanyPage={isCompanyPage} />
          </TabPanel>
        )}
        {isVisibleIpWhiteList && (
          <TabPanel>
            <IPWhitelist domainId={domain.id} />
          </TabPanel>
        )}
      </Tabs>
    </div>
  );
};

export default SecurityMgmt;
