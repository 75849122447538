import { useState, useRef } from 'react';
import cn from 'classnames';
import { Button, Checkbox, Modal, SwitchCheckbox } from 'components';
import { useOutsideClick } from 'hooks';
import { AngleUpIcon, AngleDownIcon } from 'assets/img';
import { notify } from 'utils';
import { useAppDispatch, useAppSelector } from 'store';
import { resetDomainSettings } from 'store/slices/settings';
import { SettingTabs, TABS } from 'consts';
import styles from './ModalResetAll.module.scss';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  userDomainId: string;
  isDropdownRestricted: boolean;
  setTabsContent: (value: SettingTabs) => void;
}

const ModalResetAll = ({ isOpen, onRequestClose, userDomainId, isDropdownRestricted, setTabsContent }: Props) => {
  const { id } = useAppSelector((state) => state.user.user);
  const { isLoading } = useAppSelector((state) => state.settings.resetDomainSettingsRequest);
  const { currentDomain, settingDomains } = useAppSelector((state) => state.settings);
  const domains = Object.values(settingDomains);
  const dispatch = useAppDispatch();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [filteredDomains, setFilteredDomains] = useState(domains);
  const [selectedDomains, setSelectedDomains] = useState(
    currentDomain === 'My Settings'
      ? isDropdownRestricted
        ? domains
        : []
      : domains.filter((d) => d.dnsName === currentDomain),
  );
  const [applyToMyself, setApplyToMyself] = useState(currentDomain === 'My Settings');
  const [applyToDomains, setApplyToDomains] = useState(currentDomain !== 'My Settings');
  const dropdownRef = useRef<HTMLDivElement>(null);
  const isBtnDisabled =
    (!applyToMyself && !applyToDomains) || (!applyToMyself && applyToDomains && !selectedDomains.length);

  const onCloseDropdown = () => {
    if (isOpen && filteredDomains.length !== domains.length) {
      setFilteredDomains(domains);
    }
    setIsDropdownOpen(false);
  };

  useOutsideClick(dropdownRef, () => isDropdownOpen && onCloseDropdown());

  const onChangeApply = (item: string, value: any) => {
    if (item === 'Apply to myself') {
      setApplyToMyself(value);
    } else {
      setApplyToDomains(value);
    }
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const filtered = domains.filter((d) => d.dnsName.includes(value));

    if (value) {
      setFilteredDomains(filtered);
    } else {
      setFilteredDomains(domains);
    }
  };

  const onChangeDomain = (domainId: string, value: boolean) => {
    let selectedDomainsNew = [...selectedDomains];
    const selectedDomain = domains.find((d) => d.id === domainId);

    if (value && selectedDomain) {
      selectedDomainsNew.push(selectedDomain);
    } else {
      selectedDomainsNew = selectedDomainsNew.filter((d) => d.id !== domainId);
    }

    setSelectedDomains(selectedDomainsNew);
  };

  const onResetAsSettings = async () => {
    try {
      const isApplyToDomains = selectedDomains.length && applyToDomains;
      const applyTo = [];
      if (applyToMyself) {
        applyTo.push(id);
      }
      if (isApplyToDomains) {
        selectedDomains.map((d) => applyTo.push(d.id));
      }

      await dispatch(resetDomainSettings({ applyToMyself, applyTo })).unwrap();
      if (applyToMyself && !isApplyToDomains) {
        notify.success('Settings reset');
      } else {
        notify.success(`Settings reset for the selected domain${selectedDomains.length > 1 ? 's' : ''} `);
      }
      if (applyToMyself) {
        setTabsContent(TABS);
      }
    } catch (err: any) {
      notify.error(err.message);
    } finally {
      onRequestClose();
    }
  };

  const selectedDomainsRender = selectedDomains.length ? (
    selectedDomains.map((d) => d.dnsName).join(', ')
  ) : (
    <span style={{ color: '#848A92' }}>Select domains</span>
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Reset all settings to default"
      shouldCloseOnOverlayClick={false}
      actionFunc={onResetAsSettings}
      actionTitle="Confirm"
      actionLoading={isLoading}
      actionDisabled={isBtnDisabled}>
      {['Apply to myself', 'Apply to selected domains'].map((item, index) => (
        <div key={item} className={styles.resetRow}>
          <span>{item}</span>
          <SwitchCheckbox
            id={`setting-${item}`}
            name={`setting-${item}`}
            checked={index ? applyToDomains : applyToMyself}
            onChange={(e) => onChangeApply(item, e.target.checked)}
          />
        </div>
      ))}

      <div className={cn(styles.resetDropdown, { [styles.disabled]: !applyToDomains })} ref={dropdownRef}>
        <button
          id="setting-reset-all"
          type="button"
          disabled={isDropdownRestricted}
          className={cn(styles.resetDropdownSelect, {
            [styles.active]: isDropdownOpen,
            [styles.disabled]: isDropdownRestricted,
          })}
          onClick={() => (isDropdownOpen ? onCloseDropdown() : setIsDropdownOpen(true))}>
          <span className={styles.resetDropdownSelectValue}>{selectedDomainsRender}</span>
          {!isDropdownRestricted && (isDropdownOpen ? <AngleUpIcon /> : <AngleDownIcon />)}
        </button>
        {isDropdownOpen && (
          <div className={styles.resetDropdownOptions}>
            <input
              id="setting-search-domain"
              type="search"
              className={styles.resetDropdownOptionsSearch}
              placeholder="Search..."
              onChange={onSearch}
            />
            {filteredDomains.length ? (
              <>
                <div className={cn(styles.resetDropdownOptionsRow, styles.selectAll)}>
                  <span className={styles.resetDropdownOptionsRowLabel}>Domains</span>
                  <Button
                    id="toogle-select-domain"
                    variant="link"
                    className={styles.resetDropdownOptionsRowSelectAll}
                    onClick={() =>
                      selectedDomains.length === filteredDomains.length
                        ? setSelectedDomains([])
                        : setSelectedDomains(domains)
                    }>
                    {selectedDomains.length === filteredDomains.length ? 'Unselect All' : 'Select All'}
                  </Button>
                </div>
                {filteredDomains
                  .filter((d) => d.dnsName)
                  .map((domain) => (
                    <div
                      key={domain.id}
                      className={cn(styles.resetDropdownOptionsRow, {
                        [styles.userDomain]: domain.id === userDomainId,
                      })}>
                      <Checkbox
                        id={domain.id}
                        className={styles.resetDropdownOptionsCheckbox}
                        checked={selectedDomains.length ? !!selectedDomains.find((d) => d.id === domain.id) : false}
                        onChange={(e) => onChangeDomain(domain.id, e.target.checked)}
                        label={domain.dnsName}
                        labelPosition="left"
                      />
                    </div>
                  ))}
              </>
            ) : (
              <div className={styles.resetDropdownEmpty}>No results found</div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ModalResetAll;
