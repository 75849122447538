import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'components';
import { signOut } from 'helpers';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import { useAppSelector } from 'store';
import styles from './ResetPasswordError.module.scss';

interface Props {
  error: string;
}

const ResetPasswordError = ({ error }: Props) => {
  const { user } = useAppSelector((state) => state.user);
  const [errorTitle, setErrorTitle] = useState('Invalid link');
  const [errorText, setErrorText] = useState<string | JSX.Element>(
    'The page is not available. Please try to login again',
  );
  const history = useHistory();

  const backToLogin = () => (user ? signOut() : history.replace('/'));

  useEffect(() => {
    if (error === 'used') {
      setErrorTitle('Invalid link');
      setErrorText(
        <>
          Password reset link has already been used.
          <br />
          Please try to login again
        </>,
      );
    }
    if (error === 'expired') {
      setErrorTitle('Link is expired');
      setErrorText(
        <>
          You have clicked an expired or invalid link.
          <br />
          Please try to login again
        </>,
      );
    }
    if (error === 'offline') {
      setErrorTitle('Active Directory not accessible');
      setErrorText('Please contact your administrator for assistance');
    }
    if (error === 'exceeded') {
      setErrorTitle('Password reset attempts exceeded');
      setErrorText('To reset your password, please contact your company administrator for assistance');
    }
  }, [error]);

  return (
    <div className={styles.error}>
      <LoginHeader title={errorTitle} hideSteps hideBack />
      <div className={styles.errorInfo}>{errorText}</div>
      <Button variant="primary" size="40" className={styles.errorButton} onClick={backToLogin}>
        Back to Login
      </Button>
    </div>
  );
};

export default ResetPasswordError;
