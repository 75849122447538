import { getUserRole } from 'utils';
import { getDomainADStatus, replaceSpecChar } from 'helpers';
import UpdateSentryModal from 'components/UpdateSentryModal/UpdateSentryModal';
import OnboardingMessage from '../../../OnboardingMessage/OnboardingMessage';
import AlertNoSentryInstalled from '../../../AlertNoSentryInstalled/AlertNoSentryInstalled';
import DomainsList from '../../../DomainsList/DomainsList';
import WorkspaceTable from '../../../WorkspaceTable/WorkspaceTable';
import AlertSentryUpdate from '../../../AlertSentryUpdate/AlertSentryUpdate';
import { useAppDispatch, useAppSelector } from 'store';
import useModal from 'hooks/useModal';
import { DropdownArray, SearchInput } from 'components';
import {
  checkIsEmptyPage,
  checkIsEmptyScreen,
  checkIsShowAlertNoSentryInstalled,
  checkIsShowAll,
  checkShowSentryUpdateAlert,
  getFilterData,
} from '../../utils/machinesUtils';
import { AsyncThunk } from '@reduxjs/toolkit';
import styles from './WorkspaceMyDomain.module.scss';
import { IMyDomainMachines } from 'store/slices/workspace';

interface Props {
  myDomainPage: string;
  dispatchAction: AsyncThunk<any, any, any>;
}

const WorkspaceMyDomain = ({ myDomainPage, dispatchAction }: Props) => {
  const dispatch = useAppDispatch();
  const { user, domain } = useAppSelector((state) => state.user);
  const { sentries, myDomainMachines } = useAppSelector((state) => state.workspace);

  const hasSecureConnects = !!myDomainMachines?.pageMeta?.AllMachinesCount;

  const { isError } = useAppSelector((state) => state.workspace.getMyDomainMachinesRequest);
  const userRole = getUserRole(user);

  const { isDomainAzure } = getDomainADStatus(user);

  const { isOpen: isOpenUpdateModal, openModal: openUpdateModal, closeModal: closeUpdateModal } = useModal();

  if (user.showOnboarding && !hasSecureConnects) {
    return <OnboardingMessage user={user} />;
  }

  const isEmptyScreen = checkIsEmptyScreen(myDomainMachines!, userRole);
  const isShowAll = checkIsShowAll(user, sentries!, userRole, hasSecureConnects);
  const isEmptyPage = checkIsEmptyPage(myDomainMachines!, userRole);
  const outdatedSentries = sentries?.filter((sentry) => sentry.isOutdated);
  const showSentryUpdateAlert = checkShowSentryUpdateAlert(user, userRole, sentries!);
  const showAlertNoSentryInstalled = checkIsShowAlertNoSentryInstalled(user, userRole, sentries!, myDomainMachines!);

  const { filterValue, filterValueHtml, filterOptions } = getFilterData(myDomainPage, myDomainMachines!);

  const onChangeView = async (value: string) => {
    const isKindField = myDomainPage !== 'myDomainWithFilter';
    const key = isKindField ? 'kind' : 'assigned';
    const queryConfig = {
      pageNumber: 1,
      [key]: value.split(' ')[0],
    };
    await dispatch(dispatchAction({ query: queryConfig, filter: !isKindField })).unwrap();
  };

  const onSearch = async (value: string) => {
    const valueNew = replaceSpecChar(value);
    const queryConfig = {
      pageNumber: 1,
      searchQuery: valueNew,
    };
    await dispatch(dispatchAction({ query: queryConfig })).unwrap();
  };

  return (
    <>
      {showAlertNoSentryInstalled && <AlertNoSentryInstalled domain={domain} />}
      {showSentryUpdateAlert && sentries && <AlertSentryUpdate sentries={sentries} openUpdateModal={openUpdateModal} />}
      <div className={styles.header}>
        {isShowAll
          ? !isEmptyPage && (
              <div className={styles.headerTotal}>
                {`${myDomainMachines?.queryConfig.assigned || myDomainMachines?.queryConfig.kind} Computers`}
                <span className={styles.headerTotalCounter}> ({myDomainMachines?.pageMeta?.TotalCount || 0})</span>
              </div>
            )
          : hasSecureConnects && (
              <DropdownArray
                id="workspace-select"
                isSmall={false}
                isSearch={false}
                isFilter
                isDisabled={isError}
                strightRight
                label="View by:"
                options={filterOptions}
                defaultValue={filterValue}
                defaultValueHtml={filterValueHtml}
                onChange={(value) => onChangeView(value as string)}
                width="17.9em"
              />
            )}
        {!isEmptyScreen && (
          <SearchInput
            id="ws-search"
            searchBy="Computer Name"
            strightLeft={!isShowAll}
            onChange={(value) => onSearch(value)}
          />
        )}
      </div>

      <DomainsList userRole={userRole} domain={domain}>
        <WorkspaceTable<IMyDomainMachines>
          tableData={myDomainMachines!}
          isComputers
          myDomainPage={myDomainPage}
          isAzureAd={isDomainAzure}
          dispatchAction={dispatchAction}
          tableWithAlert={showSentryUpdateAlert}
        />
      </DomainsList>
      {isOpenUpdateModal && outdatedSentries && (
        <UpdateSentryModal isOpen={isOpenUpdateModal} onRequestClose={closeUpdateModal} sentries={outdatedSentries} />
      )}
    </>
  );
};

export default WorkspaceMyDomain;
