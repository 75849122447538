import { Modal } from 'components';
import { useAppDispatch } from 'store';
import { togglePassMgmtStatus } from 'store/slices/securityMgmt/passMgmt';
import { notify } from 'utils';

interface Props {
  domainId: string;
  isEnabled: boolean;
  showModal: boolean;
  closeToggleModal: () => void;
  isLoading: boolean;
}

const ToggleEnableModal = ({ domainId, isEnabled, showModal, closeToggleModal, isLoading }: Props) => {
  const dispatch = useAppDispatch();

  const toggleEnablePassMgmt = async () => {
    try {
      await dispatch(togglePassMgmtStatus({ domainId })).unwrap();
      notify.success(`Forgot Password is ${isEnabled ? 'enabled' : 'disabled'} for domain`);
      closeToggleModal();
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={closeToggleModal}
      contentLabel={`${isEnabled ? 'Disable' : 'Enable'} Forgot Password for domain?`}
      actionFunc={toggleEnablePassMgmt}
      actionTitle={isEnabled ? 'Disable' : 'Enable'}
      actionLoading={isLoading}>
      {isEnabled
        ? "By disabling this option, end users won't be able to reset password"
        : 'By enabling this option, end users will be able to reset password'}
    </Modal>
  );
};

export default ToggleEnableModal;
