import { useState, useRef, useEffect } from 'react';
import { Modal, SearchInput } from 'components';
import CountriesList from '../CountriesList/CountriesList';
import { notify } from 'utils';
import { useAppDispatch, useAppSelector } from 'store';
import { changeCountryAllowedList } from 'store/slices/securityMgmt/geoBlocking';
import {
  getChangeCountryAllowedListMessage,
  isDisabledManageCountriesModalActon,
  manageCountriesModalTitle,
} from '../../utils/geoBlockingUtils';
import { TypeEnableGeoBlocking } from '../../GeoBlocking';
import styles from './ManageCountriesModal.module.scss';

interface Props {
  isManageAllowedList: boolean;
  setIsManageAllowedList: (value: boolean) => void;
  typeEnableGeoBlocking: TypeEnableGeoBlocking;
  checkedCountries: string[];
  setNewCheckedCountries: (newCheckedCountries: string[]) => void;
  onToggleGeoBlocking: () => Promise<void>;
  setShowManageCountriesModal: (value: boolean) => void;
  showManageCountriesModal: boolean;
  setIsGeoBlockingEnabled: (value: boolean) => void;
  setCheckedCountries: (value: string[]) => void;
  domainId: string;
}

const ManageCountriesModal = ({
  isManageAllowedList,
  setIsManageAllowedList,
  typeEnableGeoBlocking,
  checkedCountries,
  setNewCheckedCountries,
  onToggleGeoBlocking,
  setShowManageCountriesModal,
  showManageCountriesModal,
  setIsGeoBlockingEnabled,
  setCheckedCountries,
  domainId,
}: Props) => {
  const initCheckedCountries: React.MutableRefObject<string[]> = useRef(checkedCountries);
  const [inputText, setInputText] = useState('');

  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((store) => store.geoBlocking.toggleGeoBlockingStatusRequest);

  useEffect(() => {
    initCheckedCountries.current = checkedCountries;
    return () => setIsManageAllowedList(false);
  }, []);

  const inputHandler = (e: string) => {
    const lowerCase = e.toLowerCase();
    setInputText(lowerCase);
  };

  const toggleManageCountriesModal = () => {
    setShowManageCountriesModal(!showManageCountriesModal);
  };

  const onUpdateGeoBlocking = async () => {
    try {
      const { data } = await dispatch(changeCountryAllowedList({ domainId, checkedCountries })).unwrap();
      const message = getChangeCountryAllowedListMessage(data);
      notify.success(message);
      toggleManageCountriesModal();
      setIsManageAllowedList(false);
      if (data.countries.length < 1) {
        setIsGeoBlockingEnabled(false);
        setCheckedCountries([]);
      }
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  const modalActionHandler = async () => {
    try {
      if (isManageAllowedList) {
        await onUpdateGeoBlocking();
      } else {
        await onToggleGeoBlocking();
      }
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  const isDisabled = isDisabledManageCountriesModalActon(initCheckedCountries, checkedCountries);
  const modalTitle = manageCountriesModalTitle(typeEnableGeoBlocking, isManageAllowedList);

  return (
    <Modal
      isOpen={showManageCountriesModal}
      onRequestClose={toggleManageCountriesModal}
      contentLabel={modalTitle}
      actionLoading={isLoading}
      actionTitle={typeEnableGeoBlocking === 'priorList' || isManageAllowedList ? 'Update' : 'Enable'}
      actionFunc={modalActionHandler}
      actionDisabled={isManageAllowedList ? isDisabled : !checkedCountries.length}>
      {typeEnableGeoBlocking === 'newList' && (
        <p className={styles.modalHeader}>Before enabling this option, add countries to allowed list </p>
      )}
      <SearchInput
        id="search-by-country"
        isNotDebounce
        fullWidth
        placeholder="Find Country"
        onChange={inputHandler}
        className={styles.modalSearch}
        inputClassName={styles.modalSearchInput}
      />
      <CountriesList
        initCheckedCountries={initCheckedCountries}
        checkedCountries={checkedCountries}
        setNewCheckedCountries={setNewCheckedCountries}
        inputText={inputText}
      />
    </Modal>
  );
};

export default ManageCountriesModal;
