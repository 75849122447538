import { useRef, useState } from 'react';
import { DropdownObject, Modal } from 'components';
import cn from 'classnames';
import styles from './ToggleEnableModal.module.scss';

const TIMEOUT_PERIOD_OPTIONS = {
  0: [5, '5 min'],
  1: [10, '10 min'],
  2: [15, '15 min (default)'],
  3: [30, '30 min'],
  4: [0, 'Set custom'],
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onChangeTimeoutForDomain: (isEnableTimeoutForAll: boolean, customPeriod: number) => void;
  isTimeoutEnabled: boolean;
}

const ToggleEnableModal = ({ isOpen, onChangeTimeoutForDomain, onClose, isTimeoutEnabled }: Props) => {
  const [isButtonAction, setIsButtonAction] = useState(false);
  const [isEnableTimeoutForAll, setIsEnableTimeoutForAll] = useState(false);
  const [isCustomPeriodActive, setIsCustomPeriodActive] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState(15);
  const [customPeriod, setCustomPeriod] = useState('');
  const [customPeriodError, setCustomPeriodError] = useState('');

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleChangeDropdownValue = (value: string) => {
    setSelectedPeriod(+value);
    if (value) {
      setCustomPeriod('');
      setIsCustomPeriodActive(false);
    }
  };

  const onCustomPeriodOpen = () => {
    setSelectedPeriod(0);
    setIsCustomPeriodActive(true);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 150);
  };

  const handleInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (/^\d*$/.test(inputValue)) {
      setCustomPeriod(inputValue.slice(0, e.target.maxLength));
    }
  };

  const onToggleTimeoutHandler = () => {
    if (!isTimeoutEnabled) {
      if (isCustomPeriodActive && (!Number(customPeriod) || Number(customPeriod) < 1 || Number(customPeriod) > 1440)) {
        setCustomPeriodError('You can set between 1 to 1440 min');
      } else {
        setIsButtonAction(true);
        onChangeTimeoutForDomain(isEnableTimeoutForAll, +customPeriod || selectedPeriod);
      }
    } else {
      setIsButtonAction(true);
      onChangeTimeoutForDomain(isEnableTimeoutForAll, +customPeriod || selectedPeriod);
    }
  };

  const onNewDomainKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' || event.key === 'Delete') {
      setCustomPeriodError('');
    }
    if (event.key === 'Enter') {
      onToggleTimeoutHandler();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel={isTimeoutEnabled ? 'Disable Connector Timeout' : 'Enable Session Timeout for domain?'}
      actionFunc={onToggleTimeoutHandler}
      actionTitle={isTimeoutEnabled ? 'Disable' : 'Enable'}
      actionLoading={isButtonAction}
      isSwitcherChecked={isEnableTimeoutForAll}
      onChangeSwitcher={() => setIsEnableTimeoutForAll((value) => !value)}
      switcherTitle={!isTimeoutEnabled ? 'Enable for all users' : ''}
      shouldCloseOnOverlayClick={false}>
      {isTimeoutEnabled ? (
        <p>
          When you disable this feature, end users will not be signed out of TruGrid Desktop Connectors after a period
          of inactivity
        </p>
      ) : (
        <>
          <p>
            By enabling this option, end users will be signed out of TruGrid Desktop Connectors after a period of
            inactivity
          </p>
          <div className={styles.timeoutPeriod}>
            <div className={styles.timeoutPeriodWrap}>
              <DropdownObject
                id="enable-timeout-select"
                options={TIMEOUT_PERIOD_OPTIONS as any}
                defaultPos={selectedPeriod}
                isMulti
                onChange={(value) => handleChangeDropdownValue(value as string)}
                customField={0}
                onCustomEvent={onCustomPeriodOpen}
                className={styles.timeoutPeriodDropdown}
                optionsClassName={styles.timeoutPeriodDropdownOptions}
              />
              {isCustomPeriodActive && (
                <div className={styles.timeoutPeriodInputWrap}>
                  <input
                    id="enter-custom-period"
                    type="text"
                    name="custom-period"
                    ref={inputRef}
                    placeholder="Add 1 to 1440 mins"
                    value={customPeriod}
                    maxLength={4}
                    onChange={handleInputValue}
                    onFocus={() => setCustomPeriodError('')}
                    onKeyDown={onNewDomainKeyDown}
                    className={cn(styles.timeoutPeriodInput, {
                      [styles.hasValue]: customPeriod?.length > 0,
                      [styles.error]: !!customPeriodError,
                    })}
                  />
                  {customPeriodError && <div className={styles.timeoutPeriodInputError}>{customPeriodError}</div>}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </Modal>
  );
};

export default ToggleEnableModal;
