import { Modal } from 'components';
import { TriangleFilledIcon } from 'assets/img';
import styles from './ErrorModal.module.scss';
import { ErrorInfo } from '../../AssignmentsRefresh';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  errorInfo: ErrorInfo;
}

const ErrorModal = ({ isOpen, onRequestClose, errorInfo }: Props) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    contentLabel={
      <div className={styles.modalTitle}>
        <TriangleFilledIcon /> {errorInfo.title}
      </div>
    }>
    {errorInfo.message}
  </Modal>
);

export default ErrorModal;
