import { CopyIcon, ExternalLinkIcon } from 'assets/img';
import { Button, Link } from 'components';
import PropTypes from 'prop-types';
import { copyToClipboard } from 'utils';
import styles from './BrandingLink.module.scss';
import { useAppSelector } from 'store';

const BrandingLink = () => {
  const { branding } = useAppSelector((state) => state.branding);

  const copyLink = () => copyToClipboard(`https://${branding?.modified?.brandingHost}`, 'Link is copied');

  return (
    <div className={styles.link}>
      <p className={styles.linkTitle}>Branded Link</p>
      <div>
        <div className={styles.linkInputWrap}>
          <input
            type="text"
            readOnly
            value={`https://${branding?.modified?.brandingHost}`}
            className={styles.linkInput}
          />
          <div className={styles.linkInputActions}>
            <Button variant="link" icon={<CopyIcon />} onClick={copyLink} />
            <Link href={`https://${branding?.modified?.brandingHost}`} external>
              <ExternalLinkIcon />
            </Link>
          </div>
        </div>
        <div>
          <Link
            href="https://help.trugrid.com/en/article/trugrid-branding-oepcak/#3-customize-trugrid-url"
            external
            className={styles.linkLink}>
            Click HERE to customize your own URL
          </Link>
        </div>
      </div>
    </div>
  );
};

BrandingLink.propTypes = {
  branding: PropTypes.object,
};

export default BrandingLink;
