import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/auth/auth';
import resetPasswordReducer from './slices/auth/resetPassword';
import mfaReducer from './slices/auth/mfa';
import commonReducer from './slices/auth/common';
import mfaMgmtReducer from './slices/securityMgmt/mfaMgmt';
import geoBlockingReducer from './slices/securityMgmt/geoBlocking';
import azureAdLoginReducer from './slices/securityMgmt/azureAdLogin';
import rememberMeLoginReducer from './slices/securityMgmt/remebmerMe';
import forgotPasswordReducer from './slices/securityMgmt/forgotPass';
import phoneSetupReducer from './slices/securityMgmt/phoneSetup';
import passMgmtReducer from './slices/securityMgmt/passMgmt';
import timeoutMgmtReducer from './slices/securityMgmt/timeoutMgmt';
import shortcutMgmtReducer from './slices/securityMgmt/shortcutMgmt';
import ipWhiteListReducer from './slices/securityMgmt/ipWhiteList';
import activeDirectoryReducer from './slices/activeDirectory';
import resourceAssignmentsReducer from './slices/resourceAssignments';
import rolesMgmtReducer from './slices/rolesMgmt';
import customerDomainReducer from './slices/customerDomains';
import bitLockerMgmtReducer from './slices/bitLockerMgmt';
import billingReducer from './slices/billing';
import userReducer from './slices/userSlice';
import workspaceReducer from './slices/workspace';
import brandingReducer from './slices/branding';
import myProfileReducer from './slices/myProfile';
import reportsReducer from './slices/reports';
import subscriptionsReducer from './slices/subscriptions';
import settingsReducer from './slices/settings';
import domainMgmtReducer from './slices/domainMgmt';
import msalReducer from './slices/msal';

const rootReducer = combineReducers({
  mfaMgmt: mfaMgmtReducer,
  geoBlocking: geoBlockingReducer,
  azureAdLogin: azureAdLoginReducer,
  rememberMe: rememberMeLoginReducer,
  forgotPassword: forgotPasswordReducer,
  phoneSetup: phoneSetupReducer,
  passMgmt: passMgmtReducer,
  mfa: mfaReducer,
  activeDirectory: activeDirectoryReducer,
  timeoutMgmt: timeoutMgmtReducer,
  shortcutMgmt: shortcutMgmtReducer,
  user: userReducer,
  auth: authReducer,
  commonAuth: commonReducer,
  resetPassword: resetPasswordReducer,
  ipWhiteList: ipWhiteListReducer,
  resourceAssignments: resourceAssignmentsReducer,
  rolesMgmt: rolesMgmtReducer,
  bitLockerMgmt: bitLockerMgmtReducer,
  domainMgmt: domainMgmtReducer,
  customerDomains: customerDomainReducer,
  workspace: workspaceReducer,
  billing: billingReducer,
  branding: brandingReducer,
  myProfile: myProfileReducer,
  reports: reportsReducer,
  subscriptions: subscriptionsReducer,
  settings: settingsReducer,
  msal: msalReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
