import { useState, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import cn from 'classnames';
import IdleBox from 'components/IdleBox/IdleBox';
import { useOutsideClick } from 'hooks';
import { HeaderUserIcon } from 'assets/img';
import BrandedLinks from 'components/BrandedLinks/BrandedLinks';
import HeaderDropdown from './components/HeaderDropdown';
import { useAppSelector } from 'store';
import styles from './Header.module.scss';

const Header = () => {
  const { user, branding } = useAppSelector((state) => state.user);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const nodeRef = useRef<HTMLDivElement>(null);

  useOutsideClick(dropdownRef, () => setIsDropdownOpen(false));

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  return (
    <header className={styles.header}>
      <IdleBox />
      <div className={styles.headerRow}>
        {branding?.isActive && (
          <BrandedLinks
            contactInformation={branding?.contactInformation}
            isHeader
            className={styles.navbarBrandedLinks}
          />
        )}
        <div
          id="user-menu"
          className={cn(styles.headerMenu, { [styles.headerMenuOpened]: isDropdownOpen })}
          ref={dropdownRef}>
          <button type="button" className={styles.headerMenuPhoto} onClick={toggleDropdown}>
            {user.photo === 'default' ? (
              <HeaderUserIcon />
            ) : (
              <img src={`data:image/jpeg;charset=utf-8;base64,${user.photo}`} alt="User" />
            )}
          </button>

          <CSSTransition
            nodeRef={nodeRef}
            in={isDropdownOpen}
            timeout={200}
            unmountOnExit
            classNames={{
              enter: styles.headerDropdownTransitionEnter,
              enterActive: styles.headerDropdownTransitionEnterActive,
              exit: styles.headerDropdownTransitionExit,
              exitActive: styles.headerDropdownTransitionExitActive,
            }}>
            <div className={styles.headerDropdown} ref={nodeRef}>
              <HeaderDropdown user={user} onClose={toggleDropdown} />
            </div>
          </CSSTransition>
        </div>
      </div>
    </header>
  );
};

export default Header;
