import { Link, StatusMessage } from 'components';
import { getUserRole } from 'utils';
import { corpAdmin, spAdmin } from 'consts';
import styles from '../../Branding.module.scss';
import { useAppSelector } from 'store';

interface Props {
  error: any;
}

const BrandingNotAllowed = ({ error }: Props) => {
  const { user } = useAppSelector((state) => state.user);
  const role = getUserRole(user);
  const isAdmins = [spAdmin, corpAdmin].includes(role);

  return (
    <div className={styles.branding}>
      <div className={styles.brandingWrap}>
        <div className={styles.brandingHeader}>
          <h3 className={styles.brandingTitle}>Branding</h3>
        </div>
        <StatusMessage header="Company branding unavailable">
          {error.code === '406' ? (
            isAdmins ? (
              <>
                Please&nbsp;
                <Link to="/profile/subscription" bold>
                  purchase license
                </Link>
                &nbsp;to proceed with Company Branding
              </>
            ) : (
              <>Please contact your company administrator for assistance</>
            )
          ) : isAdmins ? (
            <>
              Please&nbsp;
              <Link to="/profile/subscription" bold>
                reactivate subscription
              </Link>
              &nbsp;to proceed with Company Branding
            </>
          ) : (
            <>Please contact your company administrator for assistance</>
          )}
        </StatusMessage>
      </div>
    </div>
  );
};

export default BrandingNotAllowed;
