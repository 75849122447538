import cn from 'classnames';
import { Table, ValidateDomainModal } from 'components';
import { spAdmin, spTechAdmin, techAdmin } from 'consts';
import { getDomainADStatus, getDomainStatus } from 'helpers';
import {
  getCustomerDomains,
  ICustomerDomainsData,
  selectCustomerDomain,
  updateDomainsAfterValidation,
} from 'store/slices/customerDomains';
import DomainActions from '../DomainActions/DomainActions';
import DomainStatus from '../DomainStatus/DomainStatus';
import DeleteDomainModal from '../DeleteDomainModal/DeleteDomainModal';
import useModal from 'hooks/useModal';
import { useHistory } from 'react-router-dom';
import { notify, truncateString } from 'utils';
import { useState } from 'react';
import { useAppDispatch } from 'store';
import { IDomain, IQueryConfig, IValidateModalDataBase } from 'interfaces';
import { IResponseValidationData } from 'store/slices/userSlice';
import styles from './CustomersTable.module.scss';

interface Props {
  domains: ICustomerDomainsData;
  role: string;
}

const CustomersTable = ({ domains, role }: Props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [infoStatusActive, setInfoStatusActive] = useState('');

  const showUserComputersColumn = domains.data.some((domain) => getDomainStatus(domain).isDomainActive);
  const showAppsColumn = domains.data.some(
    (domain) => !getDomainADStatus(domain).isDomainAzure && getDomainStatus(domain).isDomainActive,
  );

  const {
    openModal: openDeleteModal,
    isOpen: isDeleteModalOpen,
    closeModal: closeDeleteModal,
    modalData: deleteModalData,
  } = useModal<string>();
  const {
    openModal: openValidateModal,
    isOpen: isOpenValidateModal,
    closeModal: closeValidateModal,
    modalData: validateModalData,
  } = useModal<IValidateModalDataBase>();

  const onUpdateValidationModal = (resp: IResponseValidationData, domainId: string, domainName: string) => {
    dispatch(updateDomainsAfterValidation({ resp, domainId }));
    notify.success(`Domain ${truncateString(domainName, false)} has been validated`);
  };

  const selectDomain = (domain: IDomain) => {
    const { isDomainNotValidated } = getDomainStatus(domain);
    if (!isDomainNotValidated) {
      const { isDomainActive } = getDomainStatus(domain);
      const hasSecureConnect = (domain.machinesCount ?? 0) > 0 && domain.sentries.length === 0;
      let page = 'resource-assignments';
      if ([spAdmin, spTechAdmin, techAdmin].includes(role) && (isDomainActive || hasSecureConnect)) {
        page = 'customer-workspace';
      }

      history.push(`/customer-management/${domain.dnsName}/${page}`);
      dispatch(selectCustomerDomain(domain));
      setInfoStatusActive('');
    }
  };

  const columns = [
    {
      name: 'Domain',
      width: '42%',
      search: 'Domain Name',
      sortable: 'dnsname',
      data: 'dnsName',
    },
    {
      name: 'Domain Status',
      data: (domain: IDomain) => (
        <DomainStatus domain={domain} infoStatusActive={infoStatusActive} setInfoStatusActive={setInfoStatusActive} />
      ),
      width: showAppsColumn || !showUserComputersColumn ? '28%' : '37%',
    },
    {
      name: 'Users',
      disabled: !showUserComputersColumn,
      data: ({ usersCount }: { usersCount: string }) => (
        <p className={role !== techAdmin ? styles.tableRowUsersCount : ''}>{usersCount}</p>
      ),
      width: showAppsColumn ? '10%' : '7%',
    },
    {
      name: 'Computers',
      disabled: !showUserComputersColumn,
      data: (domain: IDomain) => (
        <>
          <p className={role !== techAdmin ? styles.tableRowComputersCount : ''}>{domain.machinesCount}</p>
          {role !== techAdmin && !showAppsColumn && (
            <DomainActions
              domain={domain}
              role={role}
              openDeleteModal={openDeleteModal}
              openValidateModal={openValidateModal}
            />
          )}
        </>
      ),
      width: showAppsColumn ? '10%' : '14%',
    },
    {
      name: 'Apps',
      disabled: !showAppsColumn,
      data: (domain: IDomain) => (
        <>
          <p className={role !== techAdmin ? styles.tableRowComputersCount : ''}>{domain.applicationsCount}</p>
          {role !== techAdmin && showAppsColumn && (
            <DomainActions
              domain={domain}
              role={role}
              openDeleteModal={openDeleteModal}
              openValidateModal={openValidateModal}
            />
          )}
        </>
      ),
      width: '10%',
    },
    {
      name: ' ',
      disabled: showUserComputersColumn,
      data: (domain: IDomain) =>
        role !== techAdmin && (
          <DomainActions
            domain={domain}
            role={role}
            openDeleteModal={openDeleteModal}
            openValidateModal={openValidateModal}
          />
        ),
      width: '30%',
    },
  ];

  const dispatchAction = async (query: IQueryConfig) => {
    await dispatch(getCustomerDomains({ query, _background: true })).unwrap();
  };

  return (
    <>
      <Table<IDomain>
        columns={columns}
        data={domains}
        dataKey="id"
        className={cn(styles.table, {
          [styles.withApps]: showAppsColumn,
          [styles.withoutColumns]: !showUserComputersColumn,
        })}
        onClickRow={(domain: any) => selectDomain(domain)}
        classNameRow={(domain) => cn(styles.tableRow, { [styles.inactive]: domain.status === 0 })}
        dispatchAction={dispatchAction}
      />
      {isDeleteModalOpen && (
        <DeleteDomainModal domainId={deleteModalData!} isOpen={isDeleteModalOpen} onRequestClose={closeDeleteModal} />
      )}
      {isOpenValidateModal && (
        <ValidateDomainModal
          isOpen={isOpenValidateModal}
          data={validateModalData!}
          onUpdateModal={onUpdateValidationModal}
          onClose={closeValidateModal}
          isCustomerDomain
        />
      )}
    </>
  );
};

export default CustomersTable;
