import { Button, DropdownArray, SearchInput } from 'components';
import { FileDownloadIcon, FileIcon } from 'assets/img';
import { notify, returnFileFromResponse } from 'utils';
import { useDevicesEnrollment } from 'hooks';
import DevicesFilter from '../DevicesFilter/DevicesFilter';
import { useAppDispatch } from 'store';
import { replaceSpecChar } from 'helpers';
import {
  IBitlockerDeviceData,
  IBitlockerEncryptionData,
  getBitlockerDevices,
  getBitlockerEncryption,
  getComplianceReport,
} from 'store/slices/bitLockerMgmt';
import styles from './SearchFilter.module.scss';

interface Props {
  devices: IBitlockerDeviceData | IBitlockerEncryptionData;
  domainId: string;
  isEncryptions?: boolean;
}

const SearchFilter = ({ devices, domainId, isEncryptions }: Props) => {
  const dispatch = useAppDispatch();

  const handleGetComplianceReport = async () => {
    try {
      const response = await dispatch(getComplianceReport(domainId)).unwrap();
      returnFileFromResponse(response);
    } catch (err: any) {
      notify.error(err.message);
    }
  };
  const { isEnrollmentLoading, getEnrollmentFile } = useDevicesEnrollment(domainId);

  const { deviceStatus, installationStatus } = devices.queryConfig || {};

  const onSearch = async (value: string) => {
    try {
      const valueNew = replaceSpecChar(value);
      const queryConfig = {
        pageNumber: 1,
        searchQuery: valueNew,
      };
      if (isEncryptions) {
        await dispatch(getBitlockerEncryption({ domainId, query: queryConfig, _background: true })).unwrap();
      } else {
        await dispatch(getBitlockerDevices({ domainId, query: queryConfig, _background: true })).unwrap();
      }
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  const onChangeView = async (value: string[]) => {
    const valuesFrmt: string[] = value.map((v: string) => v.replace(/\s/g, ''));
    const deviceStatus: string = valuesFrmt[0];
    const installationStatus: string = valuesFrmt[1];
    try {
      const queryConfig = {
        pageNumber: 1,
        deviceStatus: deviceStatus,
        installationStatus: installationStatus,
      };
      if (isEncryptions) {
        await dispatch(getBitlockerEncryption({ domainId, query: queryConfig, _background: true })).unwrap();
      } else {
        await dispatch(getBitlockerDevices({ domainId, query: queryConfig, _background: true })).unwrap();
      }
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  return (
    <div className={styles.devicesHeader}>
      <div className={styles.devicesHeaderWrap}>
        <DevicesFilter
          id="dm-devices-select"
          filterOptions={[deviceStatus || '', installationStatus || ''].map((i) =>
            i.startsWith('All') ? `All ${i.slice(3)}` : i,
          )}
          onFilter={(value: string[]) => onChangeView(value)}
        />
        <SearchInput id="dm-devices-search" searchBy="Device Name" strightLeft onChange={(value) => onSearch(value)} />
      </div>
      {!isEncryptions && (
        <div className={styles.devicesHeaderWrap}>
          <Button
            variant="secondary"
            id="compliance-report"
            size="40"
            icon={<FileDownloadIcon />}
            onClick={handleGetComplianceReport}>
            Compliance Report
          </Button>
          <div className={styles.devicesHeaderEnrollment}>
            <DropdownArray
              id="dm-devices-enroll"
              isButton
              isDisabled={isEnrollmentLoading}
              icon={<FileIcon />}
              btnTitle={isEnrollmentLoading ? 'Loading...' : 'Enrollment File'}
              options={[
                { icon: 'download', value: 'Download', id: 'download-enrollment' },
                { icon: 'copy', value: 'Copy Link', id: 'copy-enrollment' },
              ]}
              onChange={(value: any) => getEnrollmentFile(value)}
              className={styles.devicesHeaderEnrollmentDropdown}
              optionsClassName={styles.devicesHeaderEnrollmentDropdownOptions}
              width="13em"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchFilter;
