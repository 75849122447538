import { Button, StatusMessage, Table } from 'components';
import { checkButtonAction, checkIsPhoneSetup } from '../../utils/phoneSetupUtils';
import { getResetPhoneData, IResetPhoneData, IResetPhoneUsers } from 'store/slices/securityMgmt/phoneSetup';
import { ActionType } from '../../PhoneSetupManagement';
import { useAppDispatch } from 'store';
import styles from './PhoneSetupTable.module.scss';
import { IQueryConfig } from 'interfaces';

interface Props {
  phoneMgmtData: IResetPhoneData | null;
  baseUrl: string;
  selectedUsers: IResetPhoneUsers[];
  setSelectedUsers: (users: IResetPhoneUsers[]) => void;
  toggleShowModalChangeUser: (users: IResetPhoneUsers, action: ActionType) => void;
  domainId: string;
}

const PhoneSetupTable = ({
  phoneMgmtData,
  selectedUsers,
  setSelectedUsers,
  toggleShowModalChangeUser,
  domainId,
}: Props) => {
  const dispatch = useAppDispatch();
  const viewBy = phoneMgmtData?.queryConfig?.viewBy;

  const columns = [
    {
      checkbox: viewBy !== 'AllUsers' && viewBy !== 'UsersWithNoPhoneNumbers',
      name: 'Name',
      data: ({ displayName, upn }: { displayName: string; upn: string }) => (
        <div className={styles.tableWrap}>
          <div className={styles.tableName}>{displayName || 'N/A'}</div>
          <div className={styles.tableUpn}>{upn}</div>
        </div>
      ),
      sortable: 'displayName',
      search: 'Name',
      width: '40%',
    },
    {
      name: 'Phone Number',
      data: ({ phoneNumber }: { phoneNumber: string }) => phoneNumber || 'N/A',
      sortable: 'phoneNumber',
      width: '20%',
    },
    {
      name: 'Phone Setup',
      data: ({ phoneSetup }: { phoneSetup: number }) => checkIsPhoneSetup(phoneSetup),
      sortable: 'phoneSetup',
      width: '20%',
    },
    {
      name: '',
      data: (user: IResetPhoneUsers) => {
        const typeAction = checkButtonAction(user.phoneNumber, user.phoneSetup);
        const isShowButton = selectedUsers.length < 1 || !selectedUsers.includes(user);
        if (isShowButton) {
          return (
            <div className={styles.tableActions}>
              <Button variant="primary" size="32" onClick={() => toggleShowModalChangeUser(user, typeAction.type)}>
                {typeAction.text}
              </Button>
            </div>
          );
        }
      },
      width: '20%',
    },
  ];

  const dispatchAction = async (query: IQueryConfig) => {
    await dispatch(getResetPhoneData({ domainId: domainId, query: query, _background: true })).unwrap();
  };

  return phoneMgmtData?.data && phoneMgmtData?.data?.length < 1 && !phoneMgmtData?.queryConfig?.searchQuery ? (
    <div className={styles.statusMessage}>
      <StatusMessage header="No items available">Change view selection</StatusMessage>
    </div>
  ) : (
    <Table<IResetPhoneUsers>
      data={phoneMgmtData}
      selectedItems={selectedUsers}
      setSelectedItems={setSelectedUsers}
      columns={columns}
      id="phone-mgmt-table"
      dataKey="userId"
      className={styles.table}
      dispatchAction={dispatchAction}
    />
  );
};

export default PhoneSetupTable;
