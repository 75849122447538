import { useRef } from 'react';
import cn from 'classnames';
import { useOutsideClick } from 'hooks';
import styles from './DomainInfoMsg.module.scss';
import { ISentry } from 'interfaces';

interface Props {
  header?: string;
  type?: string;
  data: ISentry[] | string[];
  onClose: () => void;
}

const DomainInfoMsg = ({ header, type, data, onClose }: Props) => {
  const messageRef = useRef<HTMLDivElement>(null);

  useOutsideClick(messageRef, () => onClose());

  if (!data) return false;

  return (
    <div className={styles.infoMessage} ref={messageRef}>
      <div className={styles.infoMessageWrapper}>
        {data.length ? (
          <>
            {header && <div className={styles.infoMessageHead}>{header}</div>}
            {type === 'assigned' ? (
              <ul className={styles.infoMessageList}>
                {(data as string[]).map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            ) : (
              <>
                {(data as ISentry[]).filter((item) => item.isOnline).length > 0 && (
                  <ul className={styles.infoMessageList}>
                    <li className={styles.head}>Online</li>
                    {(data as ISentry[])
                      .filter((item) => item)
                      .map((item) => (
                        <li key={item.hostName}>
                          {item.hostName} ({item.version}) {item.isOutdated && <span>Outdated</span>}
                        </li>
                      ))}
                  </ul>
                )}
                {(data as ISentry[]).filter((item) => !item.isOnline).length > 0 && (
                  <ul style={{ marginTop: '0.5em' }} className={styles.infoMessageList}>
                    <li className={cn(styles.head, styles.offline)}>Offline</li>
                    {(data as ISentry[])
                      .filter((item) => !item.isOnline)
                      .map((item) => (
                        <li key={item.hostName}>
                          {item.hostName} ({item.version}) {item.isOutdated && <span>Outdated</span>}
                        </li>
                      ))}
                  </ul>
                )}
              </>
            )}
          </>
        ) : (
          <div className={styles.infoMessageInfo}>No Sentry Agent curently installed</div>
        )}
      </div>
    </div>
  );
};

export default DomainInfoMsg;
