import { IDomain } from 'interfaces';
import { getDomainADStatus } from 'helpers';
import AssignmentsRefresh from '../AssignmentsRefresh/AssignmentsRefresh';
import styles from './AssignmentsEmpty.module.scss';

interface Props {
  domain: IDomain;
}

const AssignmentsEmpty = ({ domain }: Props) => {
  const { isDomainAzure } = getDomainADStatus(domain);

  if (!isDomainAzure) {
    return (
      <div className={styles.assignmentsEmpty}>
        <div className={styles.assignmentsEmptyHeader}>
          <div>
            <p>Entra ID users or computers are not reporting in for this domain</p>
            <p>Once resources are reporting in from your active directory, this area will be available for use</p>
          </div>
          <AssignmentsRefresh domain={domain} />
        </div>
        <div className={styles.assignmentsEmptyInfo}>
          <h4 className={styles.assignmentsEmptyTitle}>Common reasons resources are not reporting in yet:</h4>
          <ul className={styles.assignmentsEmptyList}>
            <li>If a Secure Connect is not yet deployed</li>
            <li>AD users are not yet added into the TG-USERS group</li>
            <li>AD user UPNs are not configured to match this external validated domain</li>
            <li>
              Users or Computers in process of syncing into TruGrid. Select AD icon on top right to force an AD refresh.
            </li>
          </ul>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.assignmentsEmpty}>
      <div className={styles.assignmentsEmptyHeader}>
        <div>
          <p>Active Directory (AD) users or computers are not reporting in for this domain</p>
          <p>Once resources are reporting in from your active directory, this area will be available for use</p>
        </div>
        <AssignmentsRefresh domain={domain} />
      </div>
      <div className={styles.assignmentsEmptyInfo}>
        <h4 className={styles.assignmentsEmptyTitle}>Common reasons resources are not reporting in yet:</h4>
        <ul className={styles.assignmentsEmptyList}>
          <li>If a Sentry agent is not yet deployed</li>
          <li>AD users are not yet added into the TG-USERS group</li>
          <li>AD computers are not yet added into the TG-COMPUTERS group</li>
          <li>AD user UPNs are not configured to match this external validated domain</li>
          <li>
            Users or Computers in process of syncing into TruGrid. Select refresh icon on top right to force an AD
            refresh.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AssignmentsEmpty;
