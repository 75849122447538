import { Button, DropdownArray, Link } from 'components';
import { DownloadIcon, FileIcon, LoaderIcon } from 'assets/img';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';
import { dateFormatterUtc } from 'helpers';
import { custAdmin, techAdmin } from 'consts';
import styles from './LicensesActions.module.scss';
import { useAppSelector } from 'store';

interface Props {
  isCompanyPage: boolean;
  selectedLicenseType: string;
  onChangeLicenseType: (value: string) => void;
  isLoading: boolean;
  licensesCount: number;
  userRole: string;
  setShowExportDetailsModal: (value: boolean) => void;
  isFastGenerate: boolean;
  saveFile: (data: any) => void;
}

const LicensesActions = ({
  isCompanyPage,
  selectedLicenseType,
  onChangeLicenseType,
  isLoading,
  licensesCount,
  userRole,
  setShowExportDetailsModal,
  isFastGenerate,
  saveFile,
}: Props) => {
  const { data: licensingReportData } = useAppSelector((state) => state.reports.generateLicensingReportRequest);

  return (
    <div className={styles.licensesActions}>
      <div className={styles.licensesActionsFilter}>
        <DropdownArray
          id="reports-select-licenses"
          isSmall={false}
          isSearch={false}
          options={['SecureRDP', 'BitLocker']}
          defaultValue={selectedLicenseType}
          onChange={(value: any) => onChangeLicenseType(value)}
          width="12em"
        />
        <LoaderIcon className={cn(styles.licensesActionsLoad, { [styles.licensesActionsLoadActive]: isLoading })} />
        {isCompanyPage && userRole !== custAdmin && (
          <span>
            Total Purchased Licenses <b id="licenses-count">({licensesCount})</b>
          </span>
        )}
      </div>

      <div className={styles.licensesActionsButtons}>
        {![techAdmin, custAdmin].includes(userRole) && (
          <Link to="/profile/subscription" id="adjust-licensing" className={styles.licensesActionsButtonsLink}>
            Adjust Licensing
          </Link>
        )}
        {selectedLicenseType === 'SecureRDP' && (
          <>
            <Button
              className={styles.licensesActionsButtonsGenerate}
              id="generate-report"
              variant="secondary"
              size="32"
              isLoading={!isFastGenerate}
              onClick={() => setShowExportDetailsModal(true)}
              icon={isFastGenerate ? <FileIcon /> : undefined}>
              {isFastGenerate ? 'Generate Report' : 'Generating Report...'}
            </Button>
            {licensingReportData && (
              <>
                <Button
                  type="button"
                  id="download-report"
                  variant="link"
                  onClick={() => saveFile(licensingReportData)}
                  className={styles.licensesActionsButtonsDownload}>
                  <span data-for="download" data-tip={dateFormatterUtc(licensingReportData?.createdAt, true)}>
                    <DownloadIcon />
                  </span>
                </Button>
                <ReactTooltip
                  id="download"
                  type="light"
                  place="top"
                  offset={{ left: 60 }}
                  effect="solid"
                  className={styles.setPassTooltipComponent}
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default LicensesActions;
