import { setupMfaType } from 'helpers';

export const mfaInputPlaceholder = (mfaType: number) => {
  switch (setupMfaType(mfaType)) {
    case 'sms':
      return 'Click here to receive and enter MFA code from phone';
    case 'app':
      return 'Click here to type MFA code or get MFA Push';
    case 'email':
      return 'Click here to receive and enter MFA code from email';
    default:
      return '';
  }
};
