import { IDomain } from 'interfaces';
import { ActiveDirectorySetup } from 'components';

interface Props {
  domain: IDomain;
  userRole: string;
  isCompanyPage: boolean;
}

const DomainMgmt = ({ domain, userRole, isCompanyPage }: Props) => (
  <ActiveDirectorySetup domain={domain} userRole={userRole} isCompanyPage={isCompanyPage} />
);

export default DomainMgmt;
