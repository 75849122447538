import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Loader, PasswordComplexity, TextInput } from 'components';
import { useOutsideClick } from 'hooks';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import { notify } from 'utils';
import { isFormValid } from './utils/isFormValid';
import ResetPasswordError from '../../components/ResetPasswordError/ResetPasswordError';
import { useAppDispatch, useAppSelector } from 'store';
import { setNewPassword, validatePasswordReset } from 'store/slices/auth/resetPassword';
import styles from './ForgotPassword.module.scss';

interface IFormData {
  newPassword: string;
  confirmNewPassword: string;
}

const initFormData: IFormData = {
  newPassword: '',
  confirmNewPassword: '',
};

const initFormErrors: IFormData = {
  newPassword: '',
  confirmNewPassword: '',
};

const ForgotPassword = () => {
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState(initFormData);
  const [formErrors, setFormErrors] = useState(initFormErrors);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const { isLoading: isUpdating } = useAppSelector((state) => state.resetPassword.setNewPasswordRequest);

  const history = useHistory();
  const tooltipPassRef = useRef<HTMLDivElement | null>(null);
  const { token }: { token: string } = useParams();

  useEffect(() => {
    const checkToken = async () => {
      try {
        await dispatch(validatePasswordReset(token)).unwrap();
      } catch (err: any) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    if (token) {
      checkToken();
    } else {
      setIsLoading(false);
      history.replace('/');
    }
  }, [history, token]);

  useOutsideClick(tooltipPassRef, () => setIsTooltipOpen(false));

  const onChangeUseState = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const onInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    if (name === 'newPassword') {
      setIsTooltipOpen(true);
    }
    setFormErrors(initFormErrors);
  };

  const onClearValue = (input: string) => {
    setFormData((prevState) => ({ ...prevState, [input]: '' }));
  };

  const onSetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isFormValid(formData, formErrors, setFormErrors)) {
      try {
        const { newPassword } = formData;
        await dispatch(setNewPassword({ token, newPassword })).unwrap();
        history.replace('/password-success');
      } catch (err: any) {
        notify.error(err.message);
      }
    }
  };

  if (isLoading) {
    return (
      <div className={styles.formContainer}>
        <Loader id="loading-forgot-password" />
      </div>
    );
  }

  if (error) {
    return (
      <ResetPasswordError
        error={error === 'Link is expired.' ? 'expired' : error === 'Link already has been used.' ? 'used' : ''}
      />
    );
  }

  return (
    <div className={styles.formContainer}>
      <LoginHeader title="Reset Your Password" hideSteps idle back={() => history.replace('/')} />
      <form className={styles.form} onSubmit={onSetPassword} noValidate>
        <div className={styles.passwordFormWrap} ref={tooltipPassRef}>
          <TextInput
            type="password"
            id="enter-new-password"
            placeholder="Enter New Password"
            name="newPassword"
            autoComplete="new-password"
            hasView
            value={formData.newPassword}
            error={formErrors.newPassword}
            onChange={onChangeUseState}
            onClear={() => onClearValue('newPassword')}
            onFocus={onInputFocus}
          />
          {isTooltipOpen && <PasswordComplexity inputValue={formData.newPassword} />}
        </div>
        <div className={styles.passwordFormWrap}>
          <TextInput
            type="password"
            id="confirm-new-password"
            placeholder="Confirm New Password"
            name="confirmNewPassword"
            autoComplete="new-password"
            hasView
            value={formData.confirmNewPassword}
            error={formErrors.confirmNewPassword}
            onChange={onChangeUseState}
            onClear={() => onClearValue('confirmNewPassword')}
            onFocus={onInputFocus}
          />
        </div>
        <Button
          type="submit"
          id="set-new-password"
          size="48"
          className={styles.formButton}
          variant="primary"
          isDisabled={Object.values(formData).some((item) => !item)}
          isLoading={isUpdating}>
          Set New Password
        </Button>
      </form>
    </div>
  );
};

export default ForgotPassword;
