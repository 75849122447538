import { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { TrashIcon } from 'assets/img';
import { Button, Table } from 'components';
import { dateFormatterShort } from 'helpers';
import { getGeoBlockingData, ICountries } from 'store/slices/securityMgmt/geoBlocking';
import { useAppDispatch, useAppSelector } from 'store';
import { IQueryConfig } from 'interfaces';
import styles from './DataTable.module.scss';

interface Props {
  isCompanyPage: boolean;
  openDeleteCountryModal: (countryName: string) => void;
  domainId: string;
}

const DataTable = ({ isCompanyPage, openDeleteCountryModal, domainId }: Props) => {
  const [searchValue, setSearchValue] = useState<string>('');

  const dispatch = useAppDispatch();
  const { geoBlockingData } = useAppSelector((store) => store.geoBlocking);

  const geoBlockingFindValue = () => {
    const newData =
      geoBlockingData?.data.countries.filter((el) => {
        if (searchValue === '') return el;
        return el.countryName.toLowerCase().includes(searchValue.toLowerCase());
      }) || [];
    return { data: newData, queryConfig: { ...geoBlockingData?.queryConfig, searchQuery: searchValue } };
  };

  const columns = [
    {
      name: 'Country Name',
      data: 'countryName',
      search: 'Name',
      searchNotDebounce: setSearchValue,
      sortable: 'countryName',
      width: '45%',
    },
    {
      name: 'Date Added',
      data: ({ dateAdded }: { dateAdded: Date }) => dateFormatterShort(dateAdded),
      sortable: 'dateAdded',
      width: !isCompanyPage ? '23%' : '46%',
    },
    {
      name: 'Added by',
      data: 'addedBy',
      sortable: 'addedBy',
      width: '23%',
      disabled: isCompanyPage,
    },
    {
      name: '',
      data: ({ countryName }: { countryName: string }) => (
        <Button
          type="button"
          variant="link"
          id="delete-country"
          className={styles.tableButton}
          onClick={() => openDeleteCountryModal(countryName)}>
          <span data-tip="Click to delete country" data-for="delete-tooltip">
            <TrashIcon />
          </span>
        </Button>
      ),
      width: '8%',
    },
  ];

  const data = geoBlockingFindValue();

  const dispatchAction = async (query: IQueryConfig) => {
    await dispatch(getGeoBlockingData({ domainId: domainId, query: query })).unwrap();
  };

  return (
    <>
      <Table<ICountries>
        data={data}
        id="geoblocking-table"
        pagination={false}
        dataKey="countryName"
        columns={columns}
        className={styles.table}
        dispatchAction={dispatchAction}
      />
      <ReactTooltip
        id="delete-tooltip"
        type="light"
        place="top"
        html
        effect="solid"
        offset={{ top: 10, left: 50 }}
        className={styles.deleteTooltip}
      />
    </>
  );
};

export default DataTable;
