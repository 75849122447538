import { AlertWarning } from 'assets/img';
import styles from './BlockedErrorMsg.module.scss';

const BlockedErrorMsg = () => (
  <p className={styles.wrapper}>
    <AlertWarning />
    Your account is blocked from login. Please contact your administrator
  </p>
);

export default BlockedErrorMsg;
