import cn from 'classnames';
import { SwitchCheckbox } from 'components';
import styles from './AzureAdLoginStatus.module.scss';

interface Props {
  isAzureAdLoginEnabled: boolean;
  toggleShowEnableModal: () => void;
}

const AzureAdLoginStatus = ({ isAzureAdLoginEnabled, toggleShowEnableModal }: Props) => (
  <div className={styles.azureAdLoginStatus}>
    <div className={styles.azureAdLoginStatusSwitchCheckbox}>
      <p className={styles.azureAdLoginStatusSwitchCheckboxTitle}>Stay Signed-In Status</p>
      <SwitchCheckbox
        id="toggle-enable"
        checked={isAzureAdLoginEnabled}
        onChange={toggleShowEnableModal}
        className={styles.azureAdLoginStatusSwitchCheckboxSlide}
      />
      <span className={cn(styles.azureAdLoginStatusSwitchCheckboxText, { [styles.active]: isAzureAdLoginEnabled })}>
        {isAzureAdLoginEnabled ? 'ON' : 'OFF'}
      </span>
    </div>
  </div>
);

export default AzureAdLoginStatus;
