import { IUser } from 'interfaces';
import { ActivationCode, Link } from 'components';
import { WinIcon, MacIcon, DownloadIcon } from 'assets/img';
import styles from './OnboardingPersonal.module.scss';

interface Props {
  user: IUser;
}

const OnboardingPersonal = ({ user }: Props) => (
  <div className={styles.onboarding}>
    <h3 className={styles.onboardingTitle}>Let's Setup TruGrid</h3>

    <div className={styles.onboardingWrap}>
      <div className={styles.onboardingStep}>
        <h3 className={styles.onboardingStepTitle}>
          <span>1</span>Install TruGrid Connector on Your Local Computer
        </h3>
        <div className={styles.onboardingStepInfo}>
          <div>
            Download and install&nbsp;
            <a className={styles.onboardingLink} href={user.downloadWinConnectorLink}>
              <WinIcon />
              Windows Connector
            </a>
            &nbsp;or&nbsp;
            <a className={styles.onboardingLink} href={user.downloadMacConnectorLink}>
              <MacIcon />
              Mac Connector
            </a>
          </div>
        </div>
      </div>

      <div className={styles.onboardingStep}>
        <h3 className={styles.onboardingStepTitle}>
          <span>2</span>Setup Your Remote Computer(s)
        </h3>
        <div className={styles.onboardingStepInfo}>
          <div>
            <Link href={user.downloadSecureConnectLink} bold external>
              <DownloadIcon />
              Download Secure Connect
            </Link>
            &nbsp;to your remote computer(s)
          </div>
          <div>
            Use code to activate TruGrid SecureConnect
            <ActivationCode
              activationCode={user.domainActivationCode}
              copyMessage="Activation code was saved to clipboard"
              className={styles.activationCode}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default OnboardingPersonal;
