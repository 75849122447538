import { Modal } from 'components';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSkipForever: () => void;
  isLoading: boolean;
  email?: string;
}

const SkipForeverModal = ({ isOpen, onClose, onSkipForever, isLoading, email }: Props) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onClose}
    contentLabel="Skip Phone Setup?"
    actionTitle="Skip"
    actionFunc={onSkipForever}
    actionLoading={isLoading}>
    {email
      ? 'You will be able to reset your password only with email. Are you sure you want to skip this step?'
      : "You won't be able to reset your password without assistance. Are you sure you want to skip this step?"}
  </Modal>
);

export default SkipForeverModal;
