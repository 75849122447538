import { useState, useEffect, useCallback } from 'react';

interface TimerHook {
  timeLeft: number;
  resetCountdown: (newTime: number) => void;
}

export const useTimer = (initialTime: number): TimerHook => {
  const [timeLeft, setTimeLeft] = useState(initialTime);

  useEffect(() => {
    if (timeLeft === 0) return;

    const intervalId = setInterval(() => {
      setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const resetCountdown = useCallback((newTime: number) => {
    setTimeLeft(newTime);
  }, []);

  return { timeLeft, resetCountdown };
};
