import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SerializedError } from '@reduxjs/toolkit';
import { CodeInput } from 'components';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import { useAppDispatch, useAppSelector } from 'store';
import { resetPasswordVerifyMfaCode } from 'store/slices/auth/resetPassword';
import styles from './ResetViaMFA.module.scss';

interface Props {
  setError: React.Dispatch<React.SetStateAction<string>>;
}

const ResetViaMFA = ({ setError }: Props) => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.resetPassword.resetPasswordVerifyMfaCodeRequest);
  const { userId } = useAppSelector((state) => state.auth.signInData);

  const history = useHistory();

  const [codeValue, setCodeValue] = useState('');
  const [isError, setIsError] = useState('');

  const clearError = () => setIsError('');

  const handleSendVerificationCode = async () => {
    try {
      await dispatch(resetPasswordVerifyMfaCode({ userId, mfaCode: codeValue })).unwrap();
      history.replace('/login/forgot-password/set-password', { userId });
    } catch (err: any) {
      if ((err as SerializedError).code === '403') {
        setError('exceeded');
      } else {
        setIsError(err.message);
      }
    }
  };

  return (
    <>
      <LoginHeader hideSteps title="Reset Your Password" />
      <div className={styles.resetMfaInfo}>Please enter the 6-digit code from your authenticator</div>
      <div className={styles.resetMfaWrap}>
        <div className={styles.resetMfaLabel}>Enter Verification Code</div>
        <CodeInput
          fields={6}
          isError={!!isError}
          errorMessage={isError}
          clearError={clearError}
          isDisable={isLoading}
          value={codeValue}
          setValue={setCodeValue}
          handleSend={handleSendVerificationCode}
        />
      </div>
    </>
  );
};

export default ResetViaMFA;
