import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Billing from './pages/Billing/Billing';
import MyProfile from './pages/MyProfile/MyProfile';
import Settings from './pages/Settings/Settings';
import Subscriptions from './pages/Subscriptions/Subscriptions';
import BrandingCustomer from './pages/Branding/pages/BrandingCustomer';
import BrandingPrimary from './pages/Branding/pages/BrandingPrimary';

const Profile = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${url}/branding`} exact component={BrandingPrimary} />
      <Route path={`${url}/branding/:domain`} component={BrandingCustomer} />
      <Route path={`${url}/billing`} component={Billing} />
      <Route path={`${url}/myprofile`} component={MyProfile} />
      <Route path={`${url}/settings`} component={Settings} />
      <Route path={`${url}/subscription`} component={Subscriptions} />
    </Switch>
  );
};

export default Profile;
