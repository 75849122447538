import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { notify } from 'utils';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import { Button, Loader } from 'components';
import { useAppDispatch, useAppSelector } from 'store';
import { SerializedError } from '@reduxjs/toolkit';
import { resetPasswordSendResetEmail } from 'store/slices/auth/resetPassword';
import styles from './ResetViaEmail.module.scss';

interface Props {
  setError: React.Dispatch<React.SetStateAction<string>>;
}

const ResetViaEmail = ({ setError }: Props) => {
  const dispatch = useAppDispatch();
  const { userId } = useAppSelector((state) => state.auth.signInData);
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);

  const handleSendResetEmail = async () => {
    try {
      await dispatch(resetPasswordSendResetEmail({ userId })).unwrap();
      notify.success('Reset link sent');
    } catch (err: any) {
      if ((err as SerializedError).code === '403') {
        setError('exceeded');
      } else {
        notify.error(err.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleSendResetEmail();
  }, []);

  const backToLogin = () => history.replace('/login');

  return (
    <div className={styles.reset}>
      {isLoading ? (
        <Loader id="loading-reset-password-email" />
      ) : (
        <>
          <LoginHeader hideSteps title="Reset Password Email Sent" />
          <div className={styles.resetInfo}>Please check your inbox</div>
          <Button variant="primary" size="40" className={styles.resetButton} onClick={backToLogin}>
            Back to Login
          </Button>
        </>
      )}
    </div>
  );
};

export default ResetViaEmail;
