import { useRouteMatch } from 'react-router-dom';
import { Link } from 'components';
import { ArrowLeftIcon, LockIcon, PhoneSetupIcon } from 'assets/img';
import { IGetPassMgmtData } from 'store/slices/securityMgmt/passMgmt';
import { useAppSelector } from 'store';
import { useBrandingColors } from 'hooks';
import styles from './LinkItem.module.scss';

const ITEMS = [
  {
    name: 'Forgot Password Management',
    path: '/password-management',
    Icon: LockIcon,
    description: 'Manage Password Reset Attempts, Retry Period and Lockup Duration',
  },
  {
    name: 'Phone Setup Management',
    path: '/phone-management',
    Icon: PhoneSetupIcon,
    description: (
      <>
        Allows Reset phone number used during Password reset setup or enable phone number setup for
        <br /> reset password if MFA is OFF
      </>
    ),
  },
];

interface Props {
  passMgmtState: IGetPassMgmtData;
}

const LinkItem = ({ passMgmtState }: Props) => {
  const { branding } = useAppSelector((state) => state.user);
  const { url } = useRouteMatch();

  const colorStyles = useBrandingColors();

  return (
    <div>
      {ITEMS.map(({ name, path, Icon, description }) => {
        if (name === 'Phone Setup Management' && !passMgmtState.isAnyUser) return null;
        return (
          <div key={name} className={styles.pageItem}>
            <div className={styles.pageItemWrap}>
              <div>
                <Icon />
              </div>
              <div>
                <h3 className={styles.pageItemTitle}>{name}</h3>
                <p className={styles.pageItemDescription}>{description}</p>
              </div>
            </div>
            <div>
              <Link
                to={`${url}${path}`}
                className={styles.pageItemButton}
                style={branding?.isActive ? colorStyles : {}}>
                Manage <ArrowLeftIcon />
              </Link>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default LinkItem;
