import { AxiosResponse } from 'axios';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createExtraReducersForResponses, createHttpRequestInitResult, http } from 'helpers/http';
import { IHttpRequestResult, ISubscriptionDomain, ISubscriptions } from 'interfaces';

export const getSubscriptions = createAsyncThunk('subscriptions/getSubscriptions', async (isPersonal: boolean) => {
  if (isPersonal) {
    const response: AxiosResponse<ISubscriptions> = await http.get('/api/SubscriptionPlan/Get');
    return response.data;
  }
  const response: AxiosResponse<ISubscriptions> = await http.get('/api/Chargify/GetCurrentSubscriptionStatus');
  return response.data;
});

export const reactivateSubscription = createAsyncThunk('subscriptions/reactivateSubscription', async () => {
  const response: AxiosResponse<ISubscriptions> = await http.post('/api/Chargify/ReactivateSubscription');
  return response.data;
});

export const updateSubscription = createAsyncThunk(
  'subscriptions/updateSubscription',
  async (data: ISubscriptionDomain[]) => {
    const response: AxiosResponse<ISubscriptions> = await http.post('/api/Chargify/UpdateSubscription', data);
    return response.data;
  },
);

interface ISubscriptionsState {
  licenses: ISubscriptions | null;
  getSubscriptionsRequest: IHttpRequestResult<ISubscriptions>;
  reactivateSubscriptionRequest: IHttpRequestResult<ISubscriptions>;
  updateSubscriptionRequest: IHttpRequestResult<ISubscriptions>;
}

const initialState: ISubscriptionsState = {
  licenses: null,
  getSubscriptionsRequest: createHttpRequestInitResult(),
  reactivateSubscriptionRequest: createHttpRequestInitResult(),
  updateSubscriptionRequest: createHttpRequestInitResult(),
};

export const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createExtraReducersForResponses<ISubscriptionsState>(
      builder,
      getSubscriptions,
      'getSubscriptionsRequest',
      (state, actions: PayloadAction<ISubscriptions>) => {
        state.licenses = actions.payload;
      },
    );
    createExtraReducersForResponses<ISubscriptionsState>(
      builder,
      reactivateSubscription,
      'reactivateSubscriptionRequest',
      (state, actions: PayloadAction<ISubscriptions>) => {
        state.licenses = actions.payload;
      },
    );
    createExtraReducersForResponses<ISubscriptionsState>(
      builder,
      updateSubscription,
      'updateSubscriptionRequest',
      (state, actions: PayloadAction<ISubscriptions>) => {
        state.licenses = actions.payload;
      },
    );
  },
});

//export const {} = subscriptionsSlice.actions;
export default subscriptionsSlice.reducer;
