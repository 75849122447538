import { Modal } from 'components';
import { useAppSelector } from 'store';
import { IRoleUser } from 'store/slices/rolesMgmt';
import { truncateString } from 'utils';

interface Props {
  showUpdateUserModal: boolean;
  toggleUpdateUserModal: () => void;
  selectedUsers: IRoleUser[];
  onUpdateUsers: () => void;
}

const UpdateUserModal = ({ showUpdateUserModal, toggleUpdateUserModal, selectedUsers, onUpdateUsers }: Props) => {
  const { isLoading } = useAppSelector((state) => state.rolesMgmt.updateUserRoleAssignmentsRequest);

  return (
    <Modal
      isOpen={showUpdateUserModal}
      onRequestClose={toggleUpdateUserModal}
      contentLabel="Promote to Admin?"
      actionFunc={onUpdateUsers}
      actionTitle="Confirm"
      variantBtn="primary"
      actionLoading={isLoading}
      actionDisabled={isLoading}>
      Promoting users to Admin will automatically enable MFA. By clicking <b>CONFIRM</b>, you will enable MFA for{' '}
      {selectedUsers.length > 1 ? 'selected users' : <b>{truncateString(selectedUsers[0]?.name)}</b>}
    </Modal>
  );
};

export default UpdateUserModal;
