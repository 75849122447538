interface StatusMessage {
  title: string;
  message: JSX.Element | string;
}

export const getStatusMessage = (status: string): StatusMessage => {
  if (status === 'Your domain validated successfully') {
    return {
      title: 'Your domain validated successfully',
      message: (
        <>You have successfully authorized your domain on TruGrid.<br />Click <strong>Proceed to TruGrid</strong> button to continue with onboarding</>
      ),
    };
  }
  if (status === 'Link is expired') {
    return {
      title: 'Link is expired',
      message: (
        <>Click <strong>Resend Email</strong> button to get a new verification link</>
      ),
    };
  }
  if (status === 'Domain is already validated') {
    return {
      title: 'Domain is already validated',
      message: 'Please login to continue',
    };
  }
  return {
    title: 'Invalid link',
    message: '',
  };
};
