import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Loader, StatusMessage, Link } from 'components';
import { getUserRole, notify } from 'utils';
import { custAdmin, spAdmin, spTechAdmin, techAdmin } from 'consts';
import UpdateSentryModal from 'components/UpdateSentryModal/UpdateSentryModal';
import AlertSentryUpdate from 'pages/Workspace/components/AlertSentryUpdate/AlertSentryUpdate';
import AddNewDomain from './components/AddNewDomain/AddNewDomain';
import { useAppDispatch, useAppSelector } from 'store';
import styles from './Customers.module.scss';
import { getCustomerDomains, getListOfOutdatedSentries, selectCustomerDomain } from 'store/slices/customerDomains';
import CustomersTable from './components/CustomersTable/CustomersTable';
import useModal from 'hooks/useModal';
import { ICustomerDomainOutdatedSentry } from 'interfaces';

const Customers = () => {
  const dispatch = useAppDispatch();

  const { customerDomains: domains, outdatedSentries: sentries } = useAppSelector((state) => state.customerDomains);
  const { isError, error } = useAppSelector((state) => state.customerDomains.getCustomerDomainsRequest);
  const { user } = useAppSelector((state) => state.user);

  const { isLoading: popupIsLoading } = useAppSelector((state) => state.msal.msalLoginPopupRequest);
  const { isLoading: msIsLoading } = useAppSelector((state) => state.msal.connectEntraIDRequest);
  const isLoadingMs = popupIsLoading || msIsLoading;

  const { isOpen: showUpdateModal, closeModal: closeUpdateModal, openModal: openUpdateModal } = useModal();

  const [isLoading, setIsLoading] = useState(true);

  const role = getUserRole(user);
  const { showSentryUpdateMessageForAdAdmins } = user;

  const fetchData = async () => {
    try {
      const queryConfig = { pageNumber: 1, pageSize: 10, orderBy: 'dnsname asc', searchQuery: '' };
      await Promise.all([
        dispatch(getCustomerDomains({ query: queryConfig })),
        dispatch(getListOfOutdatedSentries(user.domainId)),
      ]);
      setIsLoading(false);
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  useEffect(() => {
    if (user.domainStatus && !isLoadingMs) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    dispatch(
      selectCustomerDomain({
        isAppPublishingEnabled: null,
      }),
    );
  }, [dispatch]);

  if (!user.domainStatus) return <Redirect to="/workspace" />;

  if (isLoading || isLoadingMs)
    return (
      <div className={styles.customers}>
        <Loader id="loading-customers" />
      </div>
    );

  if (isError) {
    return (
      <div className={styles.customers}>
        <StatusMessage error>{error}</StatusMessage>
      </div>
    );
  }

  const hasOutdatedSentry = sentries
    ?.map((i: ICustomerDomainOutdatedSentry) => i.sentries)
    .flat()
    .some((sentry) => sentry.isOutdated);

  const notShownForAdAdmin = [spTechAdmin, custAdmin, techAdmin].includes(role) && !showSentryUpdateMessageForAdAdmins;
  const showSentryUpdateAlert =
    [spAdmin, spTechAdmin, custAdmin, techAdmin].includes(role) && hasOutdatedSentry && !notShownForAdAdmin;

  return (
    <div className={styles.customers}>
      <div className={styles.customersDomains}>
        <header className={styles.customersDomainsHeader}>
          <div className={styles.customersDomainsTitle}>
            Customer Domains ({domains?.pageMeta?.TotalCount || domains?.data?.length || 0})
          </div>
          {role !== techAdmin && domains && <AddNewDomain domains={domains.data} user={user} />}
        </header>
        {showSentryUpdateAlert && (
          <AlertSentryUpdate
            customerDomain
            sentries={sentries as ICustomerDomainOutdatedSentry[]}
            openUpdateModal={openUpdateModal}
          />
        )}
        {!domains?.data.length && !domains?.queryConfig.searchQuery ? (
          <StatusMessage header="No Customer Domains added">
            Please{' '}
            <Link href="https://www.trugrid.com/docs/addcustomerdomain" external bold>
              add customer domains
            </Link>
          </StatusMessage>
        ) : (
          <CustomersTable domains={domains} role={role} />
        )}
      </div>

      {showUpdateModal && sentries && (
        <UpdateSentryModal
          isOpen={showUpdateModal}
          onRequestClose={closeUpdateModal}
          sentries={sentries}
          customerDomain
        />
      )}
    </div>
  );
};

export default Customers;
