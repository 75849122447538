import styles from './RemoteAppNotEnabled.module.scss';

const RemoteAppNotEnabled = () => (
  <div className={styles.info}>
    <h4 className={styles.infoTitle}>RemoteApp is not currently enabled</h4>
    <p className={styles.infoSubtitle}>Please follow the procedure below to start using RemoteApp:</p>
    <ul className={styles.infoList}>
      <li className={styles.infoListItem}>Create an Active Directory Security Group consisting of machines you would like to publish from</li>
      <li className={styles.infoListItem}>Add the Security Group to the TG-COMPUTERS group</li>
      <li className={styles.infoListItem}>Enable RemoteApp by enabling the option</li>
      <li className={styles.infoListItem}>Refresh Active Directory by clicking the refresh button</li>
      <li className={styles.infoListItem}>Refresh this page to see RemoteApp options</li>
    </ul>
  </div>
);

export default RemoteAppNotEnabled;
