import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Loader, Button } from 'components';
import { MailIcon } from 'assets/img';
import { notify } from 'utils';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import { useAppDispatch, useAppSelector } from 'store';
import { getRegisteredCustomerEmail, resendVerificationEmail } from 'store/slices/auth/auth';
import styles from './EmailValidation.module.scss';

const EmailValidation = () => {
  const { customerId }: { customerId: string } = useParams();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const { isLoading: isSending } = useAppSelector((state) => state.auth.resendVerificationEmailRequest);
  const { email } = useAppSelector((state) => state.auth);
  const [emailValue, setEmailValue] = useState(email || '');

  const handleGetRegisteredCustomerEmail = async () => {
    try {
      const data = await dispatch(getRegisteredCustomerEmail(customerId)).unwrap();
      setEmailValue(data);
      setIsLoading(false);
    } catch (err: any) {
      history.replace('/login');
      notify.error(err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (customerId) {
      notify.success('Your sign up was successful');
      handleGetRegisteredCustomerEmail();
    } else {
      setIsLoading(false);
    }
  }, []);

  const onResendEmail = async () => {
    try {
      await dispatch(resendVerificationEmail({ email })).unwrap();
      notify.success('The email has been sent');
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  return (
    <div className={styles.validation}>
      <LoginHeader title="Email validation" hideBack hideSteps />
      {isLoading ? (
        <Loader id="loading-email-validation" style={{ top: 'calc(50% + 1.5em)' }} />
      ) : (
        <>
          <div className={styles.validationInfo}>
            <div className={styles.validationInfoSent}>We have sent a message to</div>
            <div className={styles.validationInfoEmail}>
              <MailIcon /> {emailValue}
            </div>
          </div>
          <div className={styles.validationInfo}>
            Please click the verification link in that email to prove ownership of this email address
          </div>
          <div className={styles.validationFooter}>
            <span>Can't find the email?</span>
            <Button
              onClick={onResendEmail}
              isDisabled={isSending}
              variant="link"
              id="resend-email"
              className={styles.validationFooterButton}>
              Resend Email
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default EmailValidation;
