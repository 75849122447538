import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { createExtraReducersForResponses, createHttpRequestInitResult, http } from 'helpers/http';
import { IHttpRequestResult } from 'interfaces';

interface ISetMfaPayload {
  pin: string;
  token: string;
  mfaType?: string;
}

export const setMfa = createAsyncThunk('mfa/setMfa', async ({ pin, token, mfaType }: ISetMfaPayload) => {
  await http.post('/api/auth/mfa/setMfa', { pin, token, mfaType });
});

interface ICheckMfaVerificationCodePayload {
  mfaPin: string;
  mfaHash: string;
}

export const checkMfaVerificationCode = createAsyncThunk(
  'mfa/checkMfaVerificationCode',
  async ({ mfaPin, mfaHash }: ICheckMfaVerificationCodePayload) => {
    await http.post('/api/auth/mfa/CheckVerificationCode', { mfaPin, mfaHash });
  },
);

interface IRequestVerificationCodeByEmailResponse {
  pinHash: string;
  emailMask: string;
}

export const requestVerificationCodeByEmail = createAsyncThunk('mfa/requestVerificationCodeByEmail', async () => {
  const response: AxiosResponse<IRequestVerificationCodeByEmailResponse> = await http.get(
    '/api/auth/mfa/RequestVerificationCodeByEmail',
  );
  return response.data;
});

interface IRequestVerificationCodeBySmsPayload {
  phone: string;
  code: string;
  isMfaSetup: boolean;
}

interface IRequestVerificationCodeBySmsResponse {
  pinHash: string;
  phoneMask: string;
}

export const requestVerificationCodeBySms = createAsyncThunk(
  'mfa/requestVerificationCodeBySms',
  async ({ phone, code, isMfaSetup }: IRequestVerificationCodeBySmsPayload) => {
    const response: AxiosResponse<IRequestVerificationCodeBySmsResponse> = await http.get(
      `/api/auth/mfa/RequestVerificationCodeByTextMessage?toPhone=${phone}&code=${code}&isMfaSetup=${isMfaSetup}`,
    );
    return response.data;
  },
);

export const resetMfaWithPin = createAsyncThunk(
  'mfa/resetMfaWithPin',
  async ({ mfaPin, mfaHash }: { mfaPin: string; mfaHash: string }) => {
    await http.post('/api/auth/mfa/ResetWithPin', { mfaPin, mfaHash });
  },
);

export const verifyPhoneNumber = createAsyncThunk('mfa/verifyPhoneNumber', async (phoneNumberLastDigits: string) => {
  const response: AxiosResponse<string> = await http.post('/api/auth/mfa/VerifyPhoneNumber', { phoneNumberLastDigits });
  return response.data;
});

interface IMFAState {
  pinHash: string;
  emailMask: string;
  phoneMask: string;
  setMfaRequest: IHttpRequestResult<void>;
  checkMfaVerificationCodeRequest: IHttpRequestResult<void>;
  requestVerificationCodeByEmailRequest: IHttpRequestResult<IRequestVerificationCodeByEmailResponse>;
  requestVerificationCodeBySmsRequest: IHttpRequestResult<IRequestVerificationCodeBySmsResponse>;
  resetMfaWithPinRequest: IHttpRequestResult<void>;
  verifyPhoneNumberRequest: IHttpRequestResult<void>;
}

const initialState: IMFAState = {
  pinHash: '',
  emailMask: '',
  phoneMask: '',
  setMfaRequest: createHttpRequestInitResult(),
  checkMfaVerificationCodeRequest: createHttpRequestInitResult(),
  requestVerificationCodeByEmailRequest: createHttpRequestInitResult(),
  requestVerificationCodeBySmsRequest: createHttpRequestInitResult(),
  resetMfaWithPinRequest: createHttpRequestInitResult(),
  verifyPhoneNumberRequest: createHttpRequestInitResult(),
};

const MfaSlice = createSlice({
  name: 'mfa',
  initialState,
  reducers: {
    setPinHash(state, action) {
      state.pinHash = action.payload;
    },
  },
  extraReducers: (builder) => {
    createExtraReducersForResponses<IMFAState>(builder, setMfa, 'setMfaRequest');
    createExtraReducersForResponses<IMFAState>(builder, checkMfaVerificationCode, 'checkMfaVerificationCodeRequest');
    createExtraReducersForResponses<IMFAState>(
      builder,
      requestVerificationCodeByEmail,
      'requestVerificationCodeByEmailRequest',
      (state, action) => {
        state.pinHash = action.payload.pinHash;
        state.emailMask = action.payload.emailMask;
      },
    );
    createExtraReducersForResponses<IMFAState>(
      builder,
      requestVerificationCodeBySms,
      'requestVerificationCodeBySmsRequest',
      (state, action) => {
        state.pinHash = action.payload.pinHash;
        state.phoneMask = action.payload.phoneMask;
      },
    );
    createExtraReducersForResponses<IMFAState>(builder, resetMfaWithPin, 'resetMfaWithPinRequest');
    createExtraReducersForResponses<IMFAState>(
      builder,
      verifyPhoneNumber,
      'verifyPhoneNumberRequest',
      (state, action) => {
        state.pinHash = action.payload;
      },
    );
  },
});

//export const {} = MfaSlice.actions;

export default MfaSlice.reducer;
