import { useEffect, useState } from 'react';
import { Loader, StatusMessage } from 'components';
import { notify } from 'utils';
import PhoneSetupHeader from './components/PhoneSetupHeader/PhoneSetupHeader';
import PhoneSetupTitle from './components/PhoneSetupTitle/PhoneSetupTitle';
import ViewByFilter from './components/ViewByFilter/ViewByFilter';
import PhoneSetupTable from './components/PhoneSetupTable/PhoneSetupTable';
import HeaderActions from './components/HeaderActions/HeaderActions';
import ModalChangeUser from './components/ModalChangeUser/ModalChangeUser';
import { IDomain } from 'interfaces';
import {
  IResetPhoneUsers,
  getResetPhoneData,
  resetPhoneSetupStatus,
  togglePhoneSetupStatus,
} from 'store/slices/securityMgmt/phoneSetup';
import { useAppDispatch, useAppSelector } from 'store';
import styles from './PhoneSetupManagement.module.scss';

interface Props {
  domain: IDomain;
  isCompanyPage: boolean;
}
export type ActionType = 'ResetPhoneSetup' | 'PhoneSetupDisabled' | 'PhoneSetupEnabled';

export interface IChangeModalData {
  users: IResetPhoneUsers[];
  type: ActionType;
}

const PhoneSetupManagement = ({ domain, isCompanyPage }: Props) => {
  const { isLoading, isError, error } = useAppSelector((state) => state.phoneSetup.getResetPhoneDataRequest);
  const { resetPhoneData } = useAppSelector((state) => state.phoneSetup);
  const dispatch = useAppDispatch();

  const fetchData = async () => {
    try {
      const queryConfig = {
        pageNumber: 1,
        pageSize: 10,
        orderBy: 'displayName asc',
        searchQuery: '',
        viewBy: 'AllUsers',
      };
      await dispatch(getResetPhoneData({ domainId: domain.id, query: queryConfig })).unwrap();
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [selectedUsers, setSelectedUsers] = useState<IResetPhoneUsers[]>([]);
  const [isModalButtonLoading, setIsModalButtonLoading] = useState<boolean>(false);
  const baseUrl = `/api/SecurityManagement/AdPasswordReset/GetPhoneInformation?domainId=${domain.id}`;

  const [modalChangeUserData, setModalChangeUserData] = useState<IChangeModalData | null>(null);
  const [showModalChangeUser, setShowModalChangeUser] = useState<boolean>(false);
  const toggleShowModalChangeUser = (user?: IResetPhoneUsers | IResetPhoneUsers[], type?: ActionType) => {
    if (showModalChangeUser) {
      setShowModalChangeUser(false);
      setModalChangeUserData(null);
    } else {
      if (!user || !type) return;
      setModalChangeUserData({ users: Array.isArray(user) ? user : [user], type });
      setShowModalChangeUser(true);
    }
  };

  const handleTogglePhoneSetup = async () => {
    try {
      const users = modalChangeUserData?.users;
      if (!users) return;
      const enabled = users[0].phoneSetup === 1;
      setIsModalButtonLoading(true);
      const userIds = users.length === 1 ? [users[0].userId] : users.map((user) => user.userId);
      await dispatch(togglePhoneSetupStatus({ userIds, enabled: !enabled, domainId: domain.id })).unwrap();
      notify.success(
        users.length === 1
          ? `Phone number setup was ${enabled ? 'disabled' : 'enabled'} for ${users[0].displayName}`
          : `Phone number setup was ${enabled ? 'disabled' : 'enabled'} for selected users`,
      );
      setSelectedUsers([]);
      setIsModalButtonLoading(false);
      toggleShowModalChangeUser();
    } catch (err: any) {
      notify.error(err.message);
      setIsModalButtonLoading(false);
    }
  };

  const handleResetPhoneSetup = async () => {
    try {
      const users = modalChangeUserData?.users;
      if (!users) return;
      setIsModalButtonLoading(true);
      const userIds = users.length === 1 ? [users[0].userId] : users.map((user) => user.userId);
      await dispatch(resetPhoneSetupStatus({ userIds, domainId: domain.id })).unwrap();
      notify.success(
        users.length === 1
          ? `Phone number was reset for ${users[0].displayName}`
          : 'Phone number was reset for selected users',
      );
      setSelectedUsers([]);
      setIsModalButtonLoading(false);
      toggleShowModalChangeUser();
    } catch (err: any) {
      notify.error(err.message);
      setIsModalButtonLoading(false);
    }
  };

  if (isLoading) return <Loader id="loading-phone-setup-management" />;

  if (isError) {
    return <StatusMessage error>{error}</StatusMessage>;
  }

  return (
    <div className={styles.phoneSetup}>
      <PhoneSetupHeader domain={domain} isCompanyPage={isCompanyPage} />
      <PhoneSetupTitle />
      {!selectedUsers.length ? (
        <ViewByFilter resetPhoneData={resetPhoneData} domainId={domain.id} />
      ) : (
        <HeaderActions
          resetPhoneData={resetPhoneData}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          toggleShowModalChangeUser={toggleShowModalChangeUser}
        />
      )}
      <PhoneSetupTable
        phoneMgmtData={resetPhoneData}
        baseUrl={baseUrl}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        toggleShowModalChangeUser={toggleShowModalChangeUser}
        domainId={domain.id}
      />
      {showModalChangeUser && (
        <ModalChangeUser
          isOpen={showModalChangeUser}
          onRequestClose={toggleShowModalChangeUser}
          isModalButtonLoading={isModalButtonLoading}
          modalChangeUserData={modalChangeUserData}
          handleResetPhoneSetup={handleResetPhoneSetup}
          handleTogglePhoneSetup={handleTogglePhoneSetup}
        />
      )}
    </div>
  );
};

export default PhoneSetupManagement;
