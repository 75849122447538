import { formatPhoneNumber } from 'helpers';
import MoreButton from './MoreButton/MoreButton';
import { IMfaUser } from 'store/slices/securityMgmt/mfaMgmt';
import MfaNameCell from './MfaNameCell/MfaNameCell';
import MfaRoleCell from './MfaRoleCell/MfaRoleCell';
import MfaStatusCell from './MfaStatusCell/MfaStatusCell';
import MfaDetailsCell from './MfaDetailsCell/MfaDetailsCell';
import ManageBtnMfa from '../../ManageBtnMfa/ManageBtnMfa';
import { TypeAction } from '../../../MfaMgmt';

export const tableColumns = (
  onModalOpen: (type: TypeAction, user: IMfaUser, isHeaderBtn?: boolean) => void,
  isResetView: boolean,
  isAllView: boolean,
  isSetupView: boolean,
  isDisabledView: boolean,
  showEnableMfaModal: boolean,
  selectedUsers: IMfaUser[],
) => {
  if (isAllView) {
    return [
      {
        name: 'Name',
        checkbox: false,
        width: '35%',
        sortable: 'displayName',
        search: 'Name',
        data: ({ displayName, upn }: { displayName: string; upn: string }) => (
          <MfaNameCell displayName={displayName} upn={upn} />
        ),
      },
      {
        name: 'Role',
        checkbox: false,
        sortable: 'isAdmin',
        width: '13%',
        data: (user: IMfaUser) => <MfaRoleCell user={user} />,
      },
      {
        name: 'Status',
        checkbox: false,
        sortable: 'mfaStatus',
        width: '21%',
        data: ({ mfaStatus }: { mfaStatus: number }) => <MfaStatusCell mfaStatus={mfaStatus} />,
      },
      {
        name: 'MFA Details',
        checkbox: false,
        sortable: 'setUpDate',
        tooltip: {
          tip: 'Sort by Date',
          id: 'sort-by-date',
        },
        width: '25%',
        data: (user: IMfaUser) => <MfaDetailsCell user={user} />,
      },
      {
        name: '',
        checkbox: false,
        width: '6%',
        data: (user: IMfaUser) => <MoreButton onModalOpen={onModalOpen} user={user} mfaStatus={user.mfaStatus} />,
      },
    ];
  }

  if (isSetupView) {
    return [
      {
        name: 'Name',
        checkbox: true,
        checkboxDisabled: ({ isAdmin }: { isAdmin: boolean }) => isAdmin,
        width: '53%',
        sortable: 'displayName',
        search: 'Name',
        data: ({ displayName, upn }: { displayName: string; upn: string }) => (
          <MfaNameCell displayName={displayName} upn={upn} />
        ),
      },
      {
        name: 'Role',
        checkbox: false,
        sortable: 'isAdmin',
        width: '29%',
        data: (user: IMfaUser) => <MfaRoleCell user={user} />,
      },
      {
        name: '',
        checkbox: false,
        width: '18%',
        data: (user: IMfaUser) =>
          !user.isAdmin &&
          !selectedUsers.find((item: IMfaUser) => item.userId === user.userId) && (
            <ManageBtnMfa user={user} onModalOpen={onModalOpen} isHeaderBtn={false} />
          ),
      },
    ];
  }

  if (isResetView) {
    return [
      {
        name: 'Name',
        checkbox: true,
        checkboxDisabled: ({ isAdmin }: { isAdmin: boolean }) => isAdmin,
        width: '40%',
        sortable: 'displayName',
        search: 'Name',
        data: ({ displayName, upn }: { displayName: string; upn: string }) => (
          <MfaNameCell displayName={displayName} upn={upn} />
        ),
      },
      {
        name: 'Role',
        checkbox: false,
        sortable: 'isAdmin',
        width: '13%',
        data: (user: IMfaUser) => <MfaRoleCell user={user} />,
      },
      {
        name: 'MFA Details',
        checkbox: false,
        sortable: 'dateOfReset',
        tooltip: {
          tip: 'Sort by Date',
          id: 'sort-by-date',
        },
        width: '29%',
        data: (user: IMfaUser) => <MfaDetailsCell isResetView={isResetView} user={user} />,
      },
      {
        name: '',
        checkbox: false,
        width: '18%',
        data: (user: IMfaUser) =>
          !selectedUsers.find((item: IMfaUser) => item.userId === user.userId) && (
            <ManageBtnMfa user={user} onModalOpen={onModalOpen} isHeaderBtn={false} />
          ),
      },
    ];
  }

  if (isDisabledView) {
    return [
      {
        name: 'Name',
        checkbox: true,
        width: '53%',
        sortable: 'displayName',
        search: 'Name',
        data: ({ displayName, upn }: { displayName: string; upn: string }) => (
          <MfaNameCell displayName={displayName} upn={upn} />
        ),
      },
      {
        name: 'Phone Number',
        checkbox: false,
        sortable: 'phoneNumber',
        width: '29%',
        data: ({ phoneCountryCode, phoneNumber }: { phoneCountryCode: string; phoneNumber: string }) =>
          formatPhoneNumber(phoneCountryCode, phoneNumber),
      },
      {
        name: '',
        checkbox: false,
        width: '18%',
        data: (user: IMfaUser) =>
          !selectedUsers.find((item: IMfaUser) => item.userId === user.userId) && (
            <ManageBtnMfa isDisabled={showEnableMfaModal} user={user} onModalOpen={onModalOpen} isHeaderBtn={false} />
          ),
      },
    ];
  }

  return [
    {
      name: 'Name',
      checkbox: true,
      width: '40%',
      sortable: 'displayName',
      search: 'Name',
      data: ({ displayName, upn }: { displayName: string; upn: string }) => (
        <MfaNameCell displayName={displayName} upn={upn} />
      ),
    },
    {
      name: 'Role',
      checkbox: false,
      sortable: 'isAdmin',
      width: '13%',
      data: (user: IMfaUser) => <MfaRoleCell user={user} />,
    },
    {
      name: 'MFA Details',
      checkbox: false,
      sortable: 'setUpDate',
      tooltip: {
        tip: 'Sort by Date',
        id: 'sort-by-date',
      },
      width: '29%',
      data: (user: IMfaUser) => <MfaDetailsCell user={user} />,
    },
    {
      name: '',
      checkbox: false,
      width: '18%',
      data: (user: IMfaUser) =>
        !selectedUsers.find((item: IMfaUser) => item.userId === user.userId) && (
          <ManageBtnMfa user={user} onModalOpen={onModalOpen} isHeaderBtn={false} />
        ),
    },
  ];
};
