import { dateFormatterShort } from 'helpers';
import { Table } from 'components';
import { IPersonalSubscription } from 'interfaces';
import { useAppSelector } from 'store';
import styles from './LicensesPersonal.module.scss';

interface Props {
  email: string;
}

const LicensesPersonal = ({ email }: Props) => {
  const licenses = useAppSelector((state) => state.subscriptions.licenses as IPersonalSubscription);

  return (
    <>
      <div className={styles.personal}>
        <div className={styles.personalWrap}>
          <div className={styles.personalSection}>
            <h4 className={styles.personalSectionTitle}>Your next billing date</h4>
            <p className={styles.personalSectionData}>{dateFormatterShort(licenses.nextBillingDate)}</p>
          </div>

          <div className={styles.personalSection}>
            <h4 className={styles.personalSectionTitle}>Your next billing amount</h4>
            <div className={styles.personalSectionData}>
              <div>{licenses.price.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</div>
            </div>
          </div>
        </div>
      </div>

      <Table
        data={{ data: [{ email, licenses: licenses.machineCount }] }}
        id="licenses-personal-table"
        dataKey="email"
        columns={[
          {
            name: 'Email',
            data: 'email',
            width: '60%',
          },
          {
            name: 'Computers',
            data: 'licenses',
            width: '20%',
          },
          {
            name: '',
            width: '20%',
          },
        ]}
        pagination={false}
        className={styles.personalTable}
      />
    </>
  );
};

export default LicensesPersonal;
