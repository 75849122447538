import { useAppSelector } from 'store';
import { LogoIcon } from 'assets/img';
import { convertHex } from 'pages/Profile/pages/Branding/helpers/colorsHelper';
import styles from './LoginPreview.module.scss';

const LoginPreview = ({ invertedButtonColor }: { invertedButtonColor: boolean }) => {
  const { branding } = useAppSelector((state) => state.branding);

  const color = invertedButtonColor ? '#000' : '#fff';

  return (
    <div className={styles.preview}>
      <div className={styles.previewLogo}>
        {branding?.modified?.logo ? (
          <img
            src={`data:image/png;base64,${branding.modified.logo}`}
            className={styles.previewLogoCustom}
            width="46"
            alt=""
          />
        ) : (
          <LogoIcon className={styles.previewLogoImage} />
        )}
      </div>
      <div
        className={styles.previewHeader}
        style={
          branding?.modified?.backGroundColor
            ? { background: convertHex(branding.modified.backGroundColor?.slice(1)) }
            : {}
        }
      />
      <div className={styles.previewWrapper}>
        <div className={styles.previewWrapperTitle}>Login</div>
        <div className={styles.previewWrapperInput}>Enter your login email address</div>
        <div
          className={styles.previewWrapperButton}
          style={
            branding?.modified?.buttonColor
              ? {
                  background: convertHex(branding.modified.buttonColor?.slice(1)),
                  borderColor: convertHex(branding.modified.buttonColor?.slice(1)),
                  color: color,
                }
              : {}
          }>
          Continue
        </div>
      </div>
    </div>
  );
};

export default LoginPreview;
