interface IGetDetailedReportStatus {
  isReportGenerated: boolean;
  isReportGenerating: boolean;
  isReportMissing: boolean;
}

export const getDetailedReportStatus = (status: number): IGetDetailedReportStatus => {
  const isReportGenerated = status === 200;
  const isReportGenerating = status === 206;
  const isReportMissing = status === 204;

  return { isReportGenerated, isReportGenerating, isReportMissing };
};
