import { Link } from 'react-router-dom';
import { InfoActiveIcon } from 'assets/img';
import styles from './NoLicensesMessage.module.scss';

interface Props {
  devicesWithoutLicenses: number;
  isCustTechAdmin: boolean;
}

const NoLicensesMessage = ({ devicesWithoutLicenses, isCustTechAdmin }: Props) => (
  <div className={styles.noLicenses}>
    <InfoActiveIcon />
    <span className={styles.noLicensesMessage}>
      There {devicesWithoutLicenses > 1 ? 'are' : 'is'}{' '}
      <span className={styles.noLicensesCount}>{devicesWithoutLicenses}</span>{' '}
      {devicesWithoutLicenses > 1 ? 'devices that do not have licenses' : 'device that does not have license'}.{' '}
      {isCustTechAdmin ? (
        'Please contact your company administrator for assistance'
      ) : (
        <>
          Please{' '}
          <Link to="/profile/subscription" className={styles.noLicensesLink}>
            add licenses
          </Link>
        </>
      )}
    </span>
  </div>
);

export default NoLicensesMessage;
