import cn from 'classnames';
import { Button } from 'components';
import TooltipInfo from '../../../../components/TooltipInfo/TooltipInfo';
import { IWarning } from '../../../PrimaryDomain/PrimaryDomain';
import { ICorporateSubscription } from 'interfaces';
import styles from './TotalLicenses.module.scss';
import { notify } from 'utils';
import { updateSubscription } from 'store/slices/subscriptions';
import { useAppDispatch, useAppSelector } from 'store';

interface Props {
  licensesState: ICorporateSubscription;
  warning: IWarning;
}

const TotalLicenses = ({ licensesState, warning }: Props) => {
  const dispatch = useAppDispatch();

  const licenses = useAppSelector((state) => state.subscriptions.licenses as ICorporateSubscription);
  const { isLoading } = useAppSelector((state) => state.subscriptions.updateSubscriptionRequest);

  const handleUpdateSubscription = async () => {
    try {
      const data = [{ ...licensesState.spDomain }, ...licensesState.customerDomains];
      await dispatch(updateSubscription(data)).unwrap();
      notify.success('Subscription Updated');
    } catch (err: any) {
      notify.error('Subscription update failed');
    }
  };

  const rdpTooltipText = `
    <div class=${styles.totalsSectionsTooltip}><span class=${styles.totalsSectionsTooltipText}>Purchased Licenses: </span><span class=${styles.totalsSectionsTooltipValue}>${licensesState.totalPurchasedRdpLicenses}</span></div>
    <div class=${styles.totalsSectionsTooltip}><span class=${styles.totalsSectionsTooltipText}>Allocated to Customers: </span><span class=${styles.totalsSectionsTooltipValue}>${licensesState.totalAllocatedRdpLicenses}</span></div>
    <div class=${styles.totalsSectionsTooltip}><span class=${styles.totalsSectionsTooltipText}>Used by Primary Domain: </span><span class=${styles.totalsSectionsTooltipValue}>${licensesState.spDomain.numberOfRdpLicenses}</span></div>
  `;

  const blTooltipText = `
    <div class=${styles.totalsSectionsTooltip}><span class=${styles.totalsSectionsTooltipText}>Purchased Licenses: </span><span class=${styles.totalsSectionsTooltipValue}>${licensesState.totalPurchasedBitLockerLicenses}</span></div>
    <div class=${styles.totalsSectionsTooltip}><span class=${styles.totalsSectionsTooltipText}>Allocated to Customers: </span><span class=${styles.totalsSectionsTooltipValue}>${licensesState.totalAllocatedBitLockerLicenses}</span></div>
    <div class=${styles.totalsSectionsTooltip}><span class=${styles.totalsSectionsTooltipText}>Used by Primary Domain: </span><span class=${styles.totalsSectionsTooltipValue}>${licensesState.spDomain.numberOfBitLockerLicenses}</span></div>
  `;

  return (
    <>
      {!licenses.isSubscriptionActive && <div className={styles.totalsSections} />}
      <div className={styles.totals}>
        <div className={styles.totalsSections}>
          <div className={styles.totalsSectionsWrapper}>
            <div
              className={cn(styles.totalsSection, {
                [styles.totalsSectionsWarning]: warning.isActive && warning.field === 'numberOfRdpLicenses',
              })}>
              <h4 className={styles.totalsSectionTitle}>SecureRDP Licenses</h4>
              <div className={styles.totalsSectionDate}>
                <TooltipInfo
                  name="rdpTotal"
                  content={rdpTooltipText}
                  position="bottom"
                  icon="question"
                  isHtml
                  className={styles.totalsSectionDateTooltip}
                />
                <div className={styles.totalsSectionDateAvailable}>
                  <b>{licensesState.spDomain.numberOfRdpLicenses}</b> Available for Allocation
                </div>
              </div>
            </div>

            <div
              className={cn(styles.totalsSection, {
                [styles.totalsSectionsWarning]: warning.isActive && warning.field === 'numberOfBitLockerLicenses',
              })}>
              <h4 className={styles.totalsSectionTitle}>BitLocker Licenses</h4>
              <div className={styles.totalsSectionDate}>
                <TooltipInfo
                  name=" blTotal"
                  content={blTooltipText}
                  position="bottom"
                  icon="question"
                  isHtml
                  className={styles.totalsSectionDateTooltip}
                />
                <div className={styles.totalsSectionDateAvailable}>
                  <b>{licensesState.spDomain.numberOfBitLockerLicenses}</b> Available for Allocation
                </div>
              </div>
            </div>
          </div>
          <div className={styles.totalsWarning}>{warning.isActive ? warning.content : null}</div>
        </div>
        <Button
          id="subs-customer-update"
          variant="primary"
          isLoading={isLoading}
          isDisabled={JSON.stringify(licensesState.customerDomains) === JSON.stringify(licenses.customerDomains)}
          size="40"
          onClick={handleUpdateSubscription}
          style={{ width: '13em' }}>
          Update Allocation
        </Button>
      </div>
    </>
  );
};

export default TotalLicenses;
