export interface TabOption<T> {
  title: string;
  desc: string | boolean;
  collection: T | false;
}

export interface SettingTabs {
  general: {
    screenMode: TabOption<{ [key: number]: string }>;
    dimension: TabOption<{ [key: number]: [string, string] }>;
    quality: TabOption<{ [key: number]: string }>;
    isBitmapCaching: TabOption<boolean>;
    isAutoReconnectionEnabled: TabOption<boolean>;
    keyboardHook: TabOption<{ [key: number]: string }>;
  };
  display: {
    isDynamicResolution: TabOption<boolean>;
    isHighQualityFonts: TabOption<boolean>;
    disableWallpaper: TabOption<boolean>;
    disableMenuAnimation: TabOption<boolean>;
    disableThemes: TabOption<boolean>;
    disableFullWindowDrag: TabOption<boolean>;
    isConnectionBarVisible: TabOption<boolean>;
    isConnectionBarPinned: TabOption<boolean>;
    isDesktopComposition: TabOption<boolean>;
    isWindowContentScaled: TabOption<boolean>;
    disableCursorSettings: TabOption<boolean>;
    isDirectXRendering: TabOption<boolean>;
  };
  deviceRedirection: {
    isZeroTrustEnabled: TabOption<boolean>;
    isSharedPrinters: TabOption<boolean> & { dependsOnZTP: boolean };
    isSharedPosDevices: TabOption<boolean> & { dependsOnZTP: boolean };
    isSharedComPorts: TabOption<boolean> & { dependsOnZTP: boolean };
    isSharedSmartCards: TabOption<boolean> & { dependsOnZTP: boolean };
    isSharedCameras: TabOption<boolean> & { dependsOnZTP: boolean };
    isSharedDevices: TabOption<boolean> & { dependsOnZTP: boolean };
    sharedDrives: TabOption<boolean> & { dependsOnZTP: boolean };
    isSharedClipboard: TabOption<boolean> & { dependsOnZTP: boolean };
  };
  audioAndVideo: {
    isAudioCaptured: TabOption<boolean>;
    sharedAudio: TabOption<{ [key: number]: string }>;
    isRedirectedVideoEncoded: TabOption<boolean>;
    redirectedVideoCompression: TabOption<{ [key: number]: string }>;
    isVideoPlaybackMode: TabOption<boolean>;
  };
}

export const TABS: SettingTabs = {
  general: {
    screenMode: {
      title: 'Screen Mode',
      desc: false,
      collection: {
        1: 'Windowed Mode',
        2: 'Full screen',
        3: 'Full screen (all displays)',
      },
    },
    dimension: {
      title: 'Screen Resolution',
      desc: 'Apply only for Windowed mode',
      collection: {
        1: ['2', '640 x 480'],
        2: ['3', '800 x 600'],
        3: ['4', '1024 x 768'],
        4: ['1', '1400 x 900'],
        5: ['5', '1600 x 1200'],
        6: ['customResLink', 'Set custom'],
      },
    },
    quality: {
      title: 'Video Quality',
      desc: false,
      collection: {
        1: 'Low - 8 bit color',
        2: 'Medium - 16 bit color',
        3: 'High - 24 bit color',
        4: 'Highest - 32 bit color',
      },
    },
    isBitmapCaching: {
      title: 'Cache Bitmaps',
      desc: 'Bitmap caching can improve the performance of your remote session',
      collection: false,
    },
    isAutoReconnectionEnabled: {
      title: 'Auto-Reconnect',
      desc: false,
      collection: false,
    },
    keyboardHook: {
      title: 'Keyboard Redirection',
      desc: 'Windows key combinations',
      collection: {
        0: 'On local computer',
        1: 'On remote computer',
        2: 'In full screen mode only',
      },
    },
  },
  display: {
    isDynamicResolution: {
      title: 'Dynamic Session Resolution',
      desc: 'Remote session resolution is automatically updated when the local window is resized',
      collection: false,
    },
    isHighQualityFonts: {
      title: 'High Quality Fonts',
      desc: false,
      collection: false,
    },
    disableWallpaper: {
      title: 'Disable Wallpaper',
      desc: false,
      collection: false,
    },
    disableMenuAnimation: {
      title: 'Disable Menu and Window Animation',
      desc: false,
      collection: false,
    },
    disableThemes: {
      title: 'Disable Themes',
      desc: false,
      collection: false,
    },
    disableFullWindowDrag: {
      title: 'Disable Full Window Drag',
      desc: false,
      collection: false,
    },
    isConnectionBarVisible: {
      title: 'Display Connection Bar',
      desc: false,
      collection: false,
    },
    isConnectionBarPinned: {
      title: 'Pin Connection Bar When In Full Screen Mode',
      desc: false,
      collection: false,
    },
    isDesktopComposition: {
      title: 'Allow Desktop Composition',
      desc: false,
      collection: false,
    },
    isWindowContentScaled: {
      title: 'Scale Local Window Content',
      desc: 'Local computer scales the content of the remote session to fit the window size',
      collection: false,
    },
    disableCursorSettings: {
      title: 'Disable Cursor Setting',
      desc: false,
      collection: false,
    },
    isDirectXRendering: {
      title: 'Enable DirectX Rendering',
      desc: false,
      collection: false,
    },
  },
  deviceRedirection: {
    isZeroTrustEnabled: {
      title: 'ENABLE ZERO TRUST POLICY',
      desc: 'Enabling Zero Trust provides maximum security and disables all data transfer between local and remote desktops and apps',
      collection: false,
    },
    isSharedPrinters: {
      title: 'Redirect Printers',
      desc: 'The printers on the local computer in the remote session',
      collection: false,
      dependsOnZTP: true,
    },
    isSharedPosDevices: {
      title: 'Redirect POS Devices',
      desc: 'The POS devices on the local computer in the remote session',
      collection: false,
      dependsOnZTP: true,
    },
    isSharedComPorts: {
      title: 'Redirect COM Ports',
      desc: 'The COM ports on the local computer in the remote session',
      collection: false,
      dependsOnZTP: true,
    },
    isSharedSmartCards: {
      title: 'Redirect Smartcard',
      desc: 'The COM ports on the local computer in the remote session',
      collection: false,
      dependsOnZTP: true,
    },
    isSharedCameras: {
      title: 'Redirect Cameras',
      desc: false,
      collection: false,
      dependsOnZTP: true,
    },
    isSharedDevices: {
      title: 'Redirect Plug and Play Devices',
      desc: false,
      collection: false,
      dependsOnZTP: true,
    },
    sharedDrives: {
      title: 'Redirect All Drives',
      desc: false,
      collection: false,
      dependsOnZTP: true,
    },
    isSharedClipboard: {
      title: 'Redirect Clipboard',
      desc: false,
      collection: false,
      dependsOnZTP: true,
    },
  },
  audioAndVideo: {
    isAudioCaptured: {
      title: 'Microphone Redirection',
      desc: false,
      collection: false,
    },
    sharedAudio: {
      title: 'Audio Playback',
      desc: false,
      collection: {
        1: 'Do not record',
        2: 'Play from remote computer',
        3: 'Play from this computer',
      },
    },
    isRedirectedVideoEncoded: {
      title: 'Encode Redirected Video Capture',
      desc: false,
      collection: false,
    },
    redirectedVideoCompression: {
      title: 'Redirected Video Capture Encoding Quality',
      desc: 'Quality may suffer when there is a lot of motion',
      collection: {
        0: 'High',
        1: 'Medium',
        2: 'Low',
      },
    },
    isVideoPlaybackMode: {
      title: 'Video Playback Mode',
      desc: 'Use RDP efficient multimedia streaming for video playback when possible',
      collection: false,
    },
  },
};
