import { IPageMeta } from 'interfaces';

export interface ISetupPhonePageMeta extends IPageMeta {
  AllUsersCount: number;
  UsersWithPhoneNumbers: number;
  UsersWithoutPhoneNumbers: number;
  UsersWithPhoneSetupEnabled: number;
  UsersWithPhoneSetupDisabled: number;
}

export const FILTER_OPTIONS = (pageMeta?: IPageMeta) => {
  const {
    AllUsersCount,
    UsersWithPhoneNumbers,
    UsersWithoutPhoneNumbers,
    UsersWithPhoneSetupEnabled,
    UsersWithPhoneSetupDisabled,
  } = (pageMeta as ISetupPhonePageMeta) || {};
  return [
    {
      value: (
        <>
          All Users <b>{AllUsersCount ? `(${AllUsersCount})` : '(0)'}</b>
        </>
      ),
      id: 'All Users',
    },
    {
      value: (
        <>
          Users with Phone Numbers <b>{UsersWithPhoneNumbers ? `(${UsersWithPhoneNumbers})` : '(0)'}</b>
        </>
      ),
      id: 'Users with Phone Numbers',
    },
    {
      value: (
        <>
          Users with no Phone Numbers <b>{UsersWithoutPhoneNumbers ? `(${UsersWithoutPhoneNumbers})` : '(0)'}</b>
        </>
      ),
      id: 'Users with no Phone Numbers',
    },
    {
      value: (
        <>
          Phone Setup Enabled <b>{UsersWithPhoneSetupEnabled ? `(${UsersWithPhoneSetupEnabled})` : '(0)'}</b>
        </>
      ),
      id: 'Phone Setup Enabled',
    },
    {
      value: (
        <>
          Phone Setup Disabled <b>{UsersWithPhoneSetupDisabled ? `(${UsersWithPhoneSetupDisabled})` : '(0)'}</b>
        </>
      ),
      id: 'Phone Setup Disabled',
    },
  ];
};
