import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CodeInput, ResendCode } from 'components';
import { useTimer } from 'hooks';
import { notify } from 'utils';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import { useAppDispatch, useAppSelector } from 'store';
import { resetPasswordVerifyPhone, resetPasswordVerifySmsCode } from 'store/slices/auth/resetPassword';
import styles from './EnterValidateCode.module.scss';
import { SerializedError } from '@reduxjs/toolkit';

interface Props {
  userId: string;
  phoneDigits: string;
  phoneMask: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
}

const EnterValidateCode = ({ userId, phoneDigits, phoneMask, setError }: Props) => {
  const dispatch = useAppDispatch();
  const { smsHash } = useAppSelector((state) => state.resetPassword);
  const { isLoading } = useAppSelector((state) => state.resetPassword.resetPasswordVerifySmsCodeRequest);
  const { isLoading: isLoadingResend } = useAppSelector((state) => state.resetPassword.resetPasswordVerifyPhoneRequest);
  const [smsCode, setSmsCode] = useState('');
  const [isError, setIsError] = useState('');

  const history = useHistory();
  const { timeLeft, resetCountdown } = useTimer(60);

  const clearError = () => setIsError('');

  const resendVerificationCode = async () => {
    try {
      await dispatch(resetPasswordVerifyPhone({ userId, lastFour: phoneDigits })).unwrap();
      resetCountdown(60);
    } catch (err: any) {
      if ((err as SerializedError).code === '409') {
        notify.error(err.message);
        history.replace('/login/forgot-password');
      } else if ((err as SerializedError).code === '403') {
        setError('exceeded');
      } else if ((err as SerializedError).code === '406') {
        setIsError(err.message);
      } else {
        notify.error(err.message);
      }
    }
  };

  const handleSendVerificationCode = async () => {
    try {
      await dispatch(resetPasswordVerifySmsCode({ userId, smsHash, smsCode })).unwrap();
      history.replace('/login/forgot-password/set-password', { userId });
    } catch (err: any) {
      if ((err as SerializedError).code === '403') {
        setError('exceeded');
      } else {
        setIsError(err.message);
      }
    }
  };

  return (
    <>
      <LoginHeader title="Reset Your Password" stepCount="2" currentStep="2" />
      <div className={styles.resetPassInfo}>
        We’ve sent a verification code to your phone at <strong>{phoneMask}</strong>
      </div>
      <div className={styles.resetPassForm}>
        <div className={styles.resetPassLabel}>Enter Verification Code</div>
        <CodeInput
          fields={4}
          isError={!!isError}
          errorMessage={isError}
          clearError={clearError}
          isDisable={isLoading || isLoadingResend}
          value={smsCode}
          setValue={setSmsCode}
          handleSend={handleSendVerificationCode}
        />
        <div className={styles.resetPassResend}>
          <ResendCode
            countDown={timeLeft}
            handleSend={resendVerificationCode}
            isDisabled={isLoading || isLoadingResend}
          />
        </div>
      </div>
    </>
  );
};

export default EnterValidateCode;
