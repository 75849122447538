import { useEffect, useState, RefObject } from 'react';

interface IntersectionObserverOptions {
  root?: Element | null;
  rootMargin?: string;
  threshold?: number | number[];
}

export const useObserver = (
  ref: RefObject<HTMLElement>,
  condition: boolean,
  options: IntersectionObserverOptions,
): boolean => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    if (condition) {
      const cachedRef = ref.current;
      const observer = new IntersectionObserver(([entry]) => {
        setIsIntersecting(entry.intersectionRatio < 1);
      }, options);

      if (cachedRef) {
        observer.observe(cachedRef);
        return () => observer.unobserve(cachedRef);
      }
    }
  }, [condition, options, ref]);

  return isIntersecting;
};
