import { Button, TextInput } from 'components';
import styles from './ChangeEmail.module.scss';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { notify } from 'utils';
import { changeEmail, IMyAccountData } from 'store/slices/myProfile';
import { SerializedError } from '@reduxjs/toolkit';

interface Props {
  myAccount: IMyAccountData;
  closeUpdateEmail: () => void;
}

const ChangeEmail = ({ myAccount, closeUpdateEmail }: Props) => {
  const dispatch = useAppDispatch();

  const { isLoading } = useAppSelector((state) => state.myProfile.changeEmailRequest);

  const [newUsername, setNewUsername] = useState('');
  const [emailError, setEmailError] = useState('');

  const domainMatch = myAccount.email.match(/@(.*)/g);
  const domainPart = domainMatch ? domainMatch[0] : '';

  const updateEmail = async () => {
    try {
      const email = newUsername + domainPart;
      await dispatch(changeEmail(email)).unwrap();
      notify.success('Confirmation email was sent to complete email update');
      closeUpdateEmail();
    } catch (err: any) {
      if ((err as SerializedError).code === '400') {
        setEmailError(err.message);
      } else {
        notify.error(err.message);
      }
    }
  };

  return (
    <div className={styles.rowEmailEdit}>
      <p className={styles.rowEmailEditInfo}>
        To update, please enter your <span>new</span> email and click <b>Submit</b> to receive confirmation
      </p>
      <div className={styles.rowEmailEditInputWrapper}>
        <TextInput
          type="email"
          id="enter-your-username"
          placeholder="Enter your username"
          autoComplete="off"
          value={newUsername}
          error={emailError}
          onChange={(e) => setNewUsername(e.target.value)}
          onClear={() => setNewUsername('')}
          onFocus={() => setEmailError('')}
          onKeyDown={(e) => e.key === 'Enter' && newUsername && updateEmail()}
        />
        <div className={styles.domainName}>{domainPart}</div>
      </div>

      <Button
        size="32"
        variant="primary"
        id="change-profile-submit"
        isDisabled={!newUsername}
        isLoading={isLoading}
        onClick={updateEmail}
        className={styles.rowEmailEditButton}>
        Submit
      </Button>
    </div>
  );
};

export default ChangeEmail;
