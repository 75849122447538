import { Link, StatusMessage } from 'components';

interface Props {
  isNoLicense: boolean;
}

const TableEmpty = ({ isNoLicense }: Props) => (
  <StatusMessage header="No devices reporting in" absolute={false} disabled={isNoLicense}>
    Change view or{' '}
    <Link href="https://www.trugrid.com/docs/adddevice" external bold>
      add device
    </Link>
  </StatusMessage>
);

export default TableEmpty;
