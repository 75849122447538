import { useEffect, useState } from 'react';
import cn from 'classnames';
import { Button, Loader, RouteLeavingGuard } from 'components';
import { useHistory, useLocation } from 'react-router-dom';
import { getUserRole, notify } from 'utils';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { corpAdmin, corpTechAdmin } from 'consts';
import BrandingHeader from './components/BrandingHeader/BrandingHeader';
import BrandingFooter from './components/BrandingFooter/BrandingFooter';
import BrandingEdit from './components/BrandingEdit/BrandingEdit';
import BrandingPreview from './components/BrandingPreview/BrandingPreview';
import ModalBrandingClear from './components/ModalBrandingClear/ModalBrandingClear';
import ModalBrandingInfo from './components/ModalBrandingInfo/ModalBrandingInfo';
import BrandingNotAllowed from './components/BrandingNotAllowed/BrandingNotAllowed';
import { IBranding } from 'interfaces';
import { checkIsBrandingInfoChanged, checkIsDisableClearBranding } from './utils/brandingUtils';
import { useAppDispatch, useAppSelector } from 'store';
import styles from './Branding.module.scss';
import BrandingCustomers from './components/BrandingCustomers/BrandingCustomers';
import PreventLeaveModal from './components/PreventLeaveModal/PreventLeaveModal';
import { getBranding, getCustomerDomainsBranding, resetBranding, updateBranding } from 'store/slices/branding';
import useModal from 'hooks/useModal';
import { ArrowLeftIcon } from 'assets/img';
import { invertColor } from './helpers/colorsHelper';

export interface IBrandingInfo {
  init: IBranding | null;
  modified: IBranding | null;
}

interface Props {
  isCustomer: boolean;
  domainId: string;
  domainName: string;
}

const Branding = ({ isCustomer, domainId, domainName }: Props) => {
  const { user } = useAppSelector((state) => state.user);
  const { branding } = useAppSelector((state) => state.branding);
  const { error, isError } = useAppSelector((state) => state.branding.getBrandingRequest);

  const history = useHistory();

  const goBack = () => {
    return history.push({
      pathname: '/profile/branding',
      search: '?tab=customer-domains',
    });
  };

  const dispatch = useAppDispatch();

  const role = getUserRole(user);
  const hideCustomersTab = role === corpAdmin || role === corpTechAdmin || isCustomer;

  const [isLoading, setIsLoading] = useState(true);
  const [hexError, setHexError] = useState(false);
  const { isOpen: isOpenModalReset, openModal: openModalReset, closeModal: closeModalReset } = useModal();
  const { isOpen: isOpenModalEdit, openModal: openModalEdit, closeModal: closeModalEdit } = useModal();

  const isBrandingInfoChanged = checkIsBrandingInfoChanged(branding);
  const isDisableClearBranding = checkIsDisableClearBranding(branding);

  const [isShowLeaveModal, setIsShowLeaveModal] = useState(false);
  const toggleShowLeaveModal = () => setIsShowLeaveModal(!isShowLeaveModal);

  const { search } = useLocation();
  const [tabIndex, setTabIndex] = useState(0);
  useEffect(() => {
    const params = new URLSearchParams(search);
    if (params.get('tab') === 'customer-domains') {
      setTabIndex(1);
    }
  }, [search]);

  const onTabSelect = (index: number) => {
    if (isBrandingInfoChanged) {
      toggleShowLeaveModal();
      return;
    }
    const params = new URLSearchParams(search);
    if (params.get('tab')) {
      params.delete('tab');
      history.replace({
        search: params.toString(),
      });
    }
    setTabIndex(index);
  };

  const invertedColor = invertColor(branding?.modified?.backGroundColor);

  useEffect(() => {
    dispatch(updateBranding({ useInvertedColorScheme: invertedColor || false }));
  }, [branding.init, invertedColor]);

  const handleConfirmLeave = () => {
    dispatch(resetBranding());
    toggleShowLeaveModal();
    setTabIndex(1);
  };

  const getBrandingInfo = async () => {
    await dispatch(getBranding(domainId));
    setIsLoading(false);
  };

  useEffect(() => {
    getBrandingInfo();
  }, [dispatch]);

  useEffect(() => {
    const fetchCustomerDomains = async () => {
      try {
        const queryConfig = {
          pageNumber: 1,
          pageSize: 10,
          orderBy: 'dnsName asc',
          searchQuery: '',
          viewBy: 'All',
        };
        await dispatch(getCustomerDomainsBranding({ query: queryConfig })).unwrap();
      } catch (err: any) {
        notify.error(err.message);
      }
    };

    fetchCustomerDomains();
  }, []);

  if (isLoading) {
    return (
      <div className={styles.branding}>
        <Loader id="loading-branding" />
      </div>
    );
  }

  if (isError) {
    return <BrandingNotAllowed error={error} />;
  }

  return (
    <div className={styles.branding}>
      <div>
        {isCustomer ? (
          <h3 className={cn(styles.brandingTitle, { [styles.customer]: true })}>
            <Button variant="link" icon={<ArrowLeftIcon />} onClick={goBack} />
            Branding <span>({domainName})</span>
          </h3>
        ) : (
          <h3 className={styles.brandingTitle}>Branding</h3>
        )}
        <Tabs selectedIndex={tabIndex} onSelect={onTabSelect}>
          <TabList className={`react-tabs__tab-list ${hideCustomersTab ? 'no-tabs' : ''}`}>
            <Tab>Primary Domain</Tab>
            {!hideCustomersTab && <Tab>Customer Domains</Tab>}
          </TabList>
          <TabPanel>
            <BrandingHeader isCustomer={false} domainId={domainId} domainName={domainName} />
            <main className={cn(styles.brandingMain, { [styles.disabled]: !branding.modified?.isActive })}>
              <BrandingEdit openModalEdit={openModalEdit} hexError={hexError} setHexError={setHexError} />
              <BrandingPreview />
            </main>
            <div className={cn({ [styles.brandingFooterDisabled]: !branding.modified?.isActive })}>
              <BrandingFooter
                isDisableClearBranding={isDisableClearBranding}
                openModalReset={openModalReset}
                isBrandingInfoChanged={isBrandingInfoChanged}
                hexError={hexError}
                domainName={domainName}
              />
            </div>
          </TabPanel>
          {!hideCustomersTab && (
            <TabPanel>
              <BrandingCustomers />
            </TabPanel>
          )}
        </Tabs>

        <RouteLeavingGuard
          when={isBrandingInfoChanged}
          navigate={({ pathname }) => {
            if (pathname === '/profile/branding' && isCustomer) {
              return history.push(`${pathname}?tab=customer-domains`);
            }
            return history.push(pathname);
          }}
          shouldBlockNavigation={() => isBrandingInfoChanged}
          modalConfig={{
            label: 'Leave Modal',
            title: 'Discard changes',
            message: 'Do you really want to leave and discard changes?',
            btnClose: 'Stay on Page',
            btnAccept: 'Leave',
          }}
        />
        {isShowLeaveModal && (
          <PreventLeaveModal
            isOpen={isShowLeaveModal}
            closeModal={toggleShowLeaveModal}
            handleConfirmLeave={handleConfirmLeave}
            modalConfig={{
              title: 'Discard changes',
              message: 'Do you really want to leave and discard changes?',
              btnClose: 'Stay on Page',
              btnAccept: 'Leave',
            }}
          />
        )}
        {isOpenModalEdit && (
          <ModalBrandingInfo isOpen={isOpenModalEdit} onRequestClose={closeModalEdit} domainId={domainId} />
        )}
        {isOpenModalReset && (
          <ModalBrandingClear
            isOpen={isOpenModalReset}
            onRequestClose={closeModalReset}
            domainId={domainId}
            domainName={domainName}
          />
        )}
      </div>
    </div>
  );
};

export default Branding;
