import { ITimeoutMgmtUser } from 'store/slices/securityMgmt/timeoutMgmt';
import { truncateString } from 'utils';

type TimeoutActionType = 'Enable Session Timeout' | 'Disable Session Timeout' | 'Update Session Timeout';

export const getActionType = (selectedUsers: ITimeoutMgmtUser[]): TimeoutActionType => {
  switch (true) {
    case selectedUsers.every((i) => !i.isDesktopTimeoutEnabled):
      return 'Enable Session Timeout';
    case selectedUsers.every((i) => i.isDesktopTimeoutEnabled):
      return 'Disable Session Timeout';
    default:
      return 'Update Session Timeout';
  }
};

export const getActionMessage = (selectedUsers: ITimeoutMgmtUser[], user?: ITimeoutMgmtUser) => {
  if (selectedUsers.length >= 1) {
    if (selectedUsers.length > 1) {
      let actionType = 'updated';
      switch (true) {
        case selectedUsers.every((i) => !i.isDesktopTimeoutEnabled):
          actionType = 'enabled';
          break;
        case selectedUsers.every((i) => i.isDesktopTimeoutEnabled):
          actionType = 'disabled';
          break;
        default:
          actionType = 'updated';
      }
      return `Session Timeout is ${actionType} for selected users`;
    } else {
      const actionType = selectedUsers.every((i) => i.isDesktopTimeoutEnabled) ? 'disabled' : 'enabled';
      return `Session Timeout is ${actionType} for ${truncateString(selectedUsers[0].displayName, false)}`;
    }
  } else {
    return `Session Timeout is ${user!.isDesktopTimeoutEnabled ? 'disabled' : 'enabled'} for ${truncateString(
      user!.displayName,
      false,
    )}`;
  }
};
