import { objectsAreNotEqual } from 'utils';
import { IBrandingInfo } from '../Branding';

export const checkIsBrandingInfoChanged = (branding: IBrandingInfo) => {
  if (!branding?.init || !branding?.modified) return false;
  return objectsAreNotEqual({ init: branding.init, modified: branding.modified });
};

export const checkIsDisableClearBranding = (branding: IBrandingInfo) => {
  if (!branding?.init || !branding?.modified) return false;
  return (
    !branding?.init?.backGroundColor &&
    !branding?.init?.buttonColor &&
    !branding?.init?.logo &&
    !branding?.init?.isSetContactInfo &&
    branding?.init?.applyToDesktopConnectors
  );
};
