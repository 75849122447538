import cn from 'classnames';
import Button from 'components/Button/Button';
import styles from './RowNumbers.module.scss';

interface Props {
  pageSize: number;
  onPageChangeHandler: (page: number, pageSize: number) => Promise<void>;
}

const RowNumbers = ({ pageSize, onPageChangeHandler }: Props) => (
  <div className={styles.rows}>
    Rows:
    <div className={styles.rowsNumbers}>
      {[10, 50, 100].map((row) => (
        <Button
          variant="link"
          key={row}
          className={cn(styles.rowsNumbersItem, { [styles.rowsNumbersItemActive]: row === pageSize })}
          onClick={() => onPageChangeHandler(1, row)}>
          {row}
        </Button>
      ))}
    </div>
  </div>
);

export default RowNumbers;
