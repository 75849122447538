import { useState, useRef, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import cn from 'classnames';
import { AngleUpIcon, AngleDownIcon, CheckIcon } from 'assets/img';
import { useOutsideClick } from 'hooks';
import { ICountryList } from 'interfaces';
import styles from './CountryListDropdown.module.scss';
import 'flag-icons/css/flag-icons.min.css';

interface Country {
  name: string;
  dial_code: string;
}

interface Props {
  id?: string;
  options: ICountryList;
  label?: string;
  currentCountry: string;
  onChange: (value: string) => void;
  phoneNumber?: string;
  setPhoneNumber?: (value: string) => void;
}

const CountryListDropdown = ({ id, options, label, currentCountry, onChange, phoneNumber, setPhoneNumber }: Props) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [inputFocus, setInputFocus] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const searchRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const nodeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen && searchRef.current) {
      setTimeout(() => searchRef.current?.focus(), 0);
    } else {
      setTimeout(() => setSearchValue(''), 100);
    }
  }, [isOpen]);

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const filteredData = Object.entries(options).filter(([, country]) => {
    const typedCountry = country as Country;
    if (searchValue === '') return true;
    return (
      typedCountry.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      typedCountry.dial_code.includes(searchValue)
    );
  });

  const toggleOpenDropdown = () => {
    setIsOpen(!isOpen);
  };

  useOutsideClick(dropdownRef, () => isOpen && toggleOpenDropdown());

  const onSelectHandler = (value: string) => {
    onChange(value);
    toggleOpenDropdown();
  };

  const handleSetPhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, maxLength } = e.target;
    const code = value.slice(0, Number(maxLength));
    if (setPhoneNumber) {
      setPhoneNumber(code);
    }
  };

  const toggleInputFocus = () => {
    if (!inputFocus) {
      setIsOpen(false);
      setInputFocus(true);
    } else {
      setInputFocus(false);
    }
  };

  return (
    <div id={id} className={styles.dropdown} ref={dropdownRef}>
      <div className={styles.dropdownLabel}>{label}</div>
      <div className={cn(styles.dropdownWrap, { [styles.focus]: inputFocus || isOpen })}>
        <button
          type="button"
          onClick={toggleOpenDropdown}
          className={cn(styles.dropdownButton, { [styles.active]: isOpen })}>
          <span className={styles.dropdownButtonText}>
            <i className={cn('fi', `fi-${currentCountry.toLowerCase()}`)} />
          </span>
          <span className={styles.dropdownButtonIcon}>{isOpen ? <AngleUpIcon /> : <AngleDownIcon />}</span>
          <span className={styles.dropdownButtonPlaceholder}>{options[currentCountry]?.dial_code || ''}</span>
        </button>
        <input
          type="number"
          id="your-phone-number"
          className={styles.dropdownInput}
          value={phoneNumber || ''}
          maxLength={11}
          onChange={handleSetPhoneNumber}
          onFocus={toggleInputFocus}
          onBlur={toggleInputFocus}
          autoFocus
        />
      </div>
      <CSSTransition
        nodeRef={nodeRef}
        in={isOpen}
        timeout={300}
        unmountOnExit
        classNames={{
          enter: styles.dropdownOptionsTransitionEnter,
          enterActive: styles.dropdownOptionsTransitionEnterActive,
          exit: styles.dropdownOptionsTransitionExit,
          exitActive: styles.dropdownOptionsTransitionExitActive,
        }}>
        <div className={styles.dropdownOptions} ref={nodeRef}>
          <div className={styles.dropdownOptionsInputWrap}>
            <input
              type="search"
              className={styles.dropdownOptionsInput}
              placeholder="Search..."
              value={searchValue}
              onChange={onSearch}
              ref={searchRef}
            />
          </div>
          <div className={styles.dropdownOptionsCountries}>
            {filteredData.length ? (
              filteredData.map(([countryCode, country]) => (
                <button
                  type="button"
                  key={countryCode}
                  className={cn(styles.dropdownOptionsItem, {
                    [styles.active]: countryCode === currentCountry,
                  })}
                  onClick={() => onSelectHandler(countryCode)}>
                  <span className={styles.dropdownOptionsItemText}>
                    <i className={`fi fi-${countryCode.toLowerCase()}`} />
                    <span>{country.name}</span>
                  </span>
                  <span className={styles.dropdownOptionsItemCode}>{country.dial_code}</span>
                  {countryCode === currentCountry && (
                    <span className={styles.dropdownOptionsItemIcon}>
                      <CheckIcon />
                    </span>
                  )}
                </button>
              ))
            ) : (
              <div className={styles.dropdownOptionsInputNoResults}>No results found</div>
            )}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default CountryListDropdown;
