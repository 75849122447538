import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { Button, TextInput } from 'components';
import { ArrowLeftIcon, MsLogoIcon } from 'assets/img';
import { loginMsalConfig, loginRequest } from 'msal-config';
import { notify } from 'utils';
import { useAppDispatch, useAppSelector } from 'store';
import { azureAdLogin } from 'store/slices/auth/auth';
import styles from './LoginAzure.module.scss';
import { SerializedError } from '@reduxjs/toolkit';

const LoginAzure = () => {
  const dispatch = useAppDispatch();
  const { email } = useAppSelector((state) => state.auth);
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const msalInstance = new PublicClientApplication(loginMsalConfig);

  const handleLogin = async () => {
    try {
      setIsLoading(true);
      const response = await msalInstance.loginPopup(loginRequest);
      if (response.accessToken) {
        await dispatch(azureAdLogin({ email, token: response.accessToken })).unwrap();
        window.location.replace('/');
      }
    } catch (err: any) {
      if (!(err as SerializedError).message?.includes('user_cancelled')) {
        notify.error(err.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.login}>
      <div className={styles.loginWrap}>
        <h3 className={styles.loginTitle}>
          <Button
            variant="link"
            icon={<ArrowLeftIcon />}
            onClick={() => history.goBack()}
            className={styles.loginBack}
          />
          <span>Login</span>
        </h3>
      </div>
      <TextInput
        id="enter-your-login-email"
        name="email"
        type="email"
        placeholder="Enter Your Login Email"
        value={email}
        isDisabled
        readOnly
      />
      <button
        id="login-azure"
        className={styles.azureLoginBtn}
        disabled={isLoading}
        type="button"
        onClick={handleLogin}>
        <MsLogoIcon title="Microsoft Sign in" />
        <span>{isLoading ? 'Loading...' : 'Sign in with Microsoft'}</span>
      </button>
    </div>
  );
};

export default LoginAzure;
