import { Checkbox, Modal } from 'components';
import styles from './ToogleEnableModal.module.scss';
import { TypeEnableGeoBlocking } from '../../GeoBlocking';
import { useAppSelector } from 'store';

interface Props {
  type: boolean | null;
  onClose: (type: boolean | null) => void;
  checkedCountriesLength: number;
  typeEnableGeoBlocking: string;
  setTypeEnableGeoBlocking: React.Dispatch<React.SetStateAction<TypeEnableGeoBlocking>>;
  onChangeGeoBlocking: () => Promise<void>;
  dnsName: string;
}

const ToogleEnableModal = ({
  type,
  onClose,
  checkedCountriesLength,
  setTypeEnableGeoBlocking,
  typeEnableGeoBlocking,
  onChangeGeoBlocking,
  dnsName,
}: Props) => {
  const { isLoading } = useAppSelector((store) => store.geoBlocking.toggleGeoBlockingStatusRequest);

  const onCloseHandler = () => {
    if (isLoading) return false;
    onClose(type);
  };

  return (
    <Modal
      isOpen
      onRequestClose={onCloseHandler}
      contentLabel={type ? 'Enable Geo-Blocking for domain?' : 'Disable Geo-Blocking for domain?'}
      actionLoading={isLoading}
      actionTitle={type ? 'Proceed' : 'Disable'}
      actionFunc={onChangeGeoBlocking}>
      {type ? (
        <ul>
          <li className={styles.checkBoxWrapper}>
            <Checkbox
              id="via-priorList"
              isCircle
              checked={typeEnableGeoBlocking === 'priorList'}
              onChange={() => setTypeEnableGeoBlocking('priorList')}
              label={
                <>
                  <span className={styles.checkBoxCheckboxText}>Continue with prior list</span> (
                  {checkedCountriesLength} added {checkedCountriesLength > 1 ? 'countries' : 'country'})
                </>
              }
            />
          </li>
          <li>
            <Checkbox
              id="via-newList"
              isCircle
              checked={typeEnableGeoBlocking === 'newList'}
              onChange={() => setTypeEnableGeoBlocking('newList')}
              label={<span className={styles.checkBoxCheckboxText}>Create a new one</span>}
            />
          </li>
        </ul>
      ) : (
        <p>
          After disabling this option, users in <strong>{dnsName}</strong> domain will not have any login restrictions
        </p>
      )}
    </Modal>
  );
};

export default ToogleEnableModal;
