import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { http, createHttpRequestInitResult, createExtraReducersForResponses } from 'helpers/http';
import { IHttpRequestResult } from 'interfaces';

export interface IForgotPassData {
  domainId: string;
  lockoutDuration: number;
  resetAttemptsCount: number;
  retryTimePeriod: number;
}

export const getForgotPassData = createAsyncThunk(
  'forgotPass/getForgotPassData',
  async ({ domainId }: { domainId: string }) => {
    const response: AxiosResponse = await http.get(`/api/SecurityManagement/AdPasswordReset/Get?domainId=${domainId}`);
    const data: IForgotPassData = response.data;
    return data;
  },
);

export const resetForgotPassData = createAsyncThunk(
  'forgotPass/resetForgotPassData',
  async ({ domainId }: { domainId: string }) => {
    const response: AxiosResponse = await http.post('/api/SecurityManagement/AdPasswordReset/ResetToDefault', domainId);
    const data: IForgotPassData = response.data;
    return data;
  },
);

export const changeForgotPassData = createAsyncThunk(
  'forgotPass/changeForgotPassData',
  async ({ data }: { data: IForgotPassData }) => {
    const response: AxiosResponse = await http.post('/api/SecurityManagement/AdPasswordReset/UpdateStatus', data);
    const updatedData: IForgotPassData = response.data;
    return updatedData;
  },
);

interface ForgotPassState {
  forgotPassData: IForgotPassData | null;
  getForgotPassDataRequest: IHttpRequestResult<IForgotPassData>;
  resetForgotPassDataRequest: IHttpRequestResult<IForgotPassData>;
  changeForgotPassDataRequest: IHttpRequestResult<IForgotPassData>;
}

const initialState: ForgotPassState = {
  forgotPassData: null,
  getForgotPassDataRequest: createHttpRequestInitResult(),
  resetForgotPassDataRequest: createHttpRequestInitResult(),
  changeForgotPassDataRequest: createHttpRequestInitResult(),
};

export const forgotPassSlice = createSlice({
  name: 'forgotPass',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createExtraReducersForResponses<ForgotPassState>(
      builder,
      getForgotPassData,
      'getForgotPassDataRequest',
      (state, { payload }) => {
        state.forgotPassData = payload;
      },
    );
    createExtraReducersForResponses<ForgotPassState>(
      builder,
      resetForgotPassData,
      'resetForgotPassDataRequest',
      (state, { payload }) => {
        state.forgotPassData = payload;
      },
    );
    createExtraReducersForResponses<ForgotPassState>(
      builder,
      changeForgotPassData,
      'changeForgotPassDataRequest',
      (state, action) => {
        const { data } = action.meta.arg;
        state.forgotPassData = data;
      },
    );
  },
});

export default forgotPassSlice.reducer;
