import { useState, forwardRef, ChangeEvent, FocusEvent, KeyboardEvent } from 'react';
import cn from 'classnames';
import { CloseIcon, ShowIconOff, ShowIconOn } from 'assets/img';
import Button from 'components/Button/Button';
import styles from './TextInput.module.scss';

interface Props {
  type?: string;
  value?: string;
  error?: string;
  id?: string;
  name?: string;
  placeholder?: string;
  autoFocus?: boolean;
  readOnly?: boolean;
  isDisabled?: boolean;
  tabIndex?: number;
  maxCharacters?: number;
  autoComplete?: string;
  hasView?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  onClear?: () => void;
}

const TextInput = forwardRef<HTMLInputElement, Props>(({
  type = 'text',
  value = '',
  error,
  id,
  name,
  placeholder,
  autoFocus,
  readOnly,
  isDisabled,
  tabIndex,
  maxCharacters,
  autoComplete,
  hasView,
  onChange,
  onKeyDown,
  onFocus,
  onClear,
}, ref) => {
  const [isFocus, setIsFocus] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const handleShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const setOnFocus = (e: FocusEvent<HTMLInputElement>) => {
    setIsFocus(true);
    if (onFocus) onFocus(e);
  };

  const onBlur = () => {
    setIsFocus(false);
  };

  const placeholderClasses = cn(styles.inputPlaceholder, {
    [styles.active]: isFocus || !!value,
    [styles.disabled]: isDisabled,
    [styles.error]: error,
  });

  return (
    <div className={styles.inputWrapper}>
      <div className={styles.inputContainer}>
        <input
          id={id}
          ref={ref}
          className={cn(styles.input, {
            [styles.hasValue]: value?.length > 0,
            [styles.error]: !!error,
            [styles.disabled]: isDisabled,
            [styles.viewAction]: !isDisabled && value?.length > 0,
            [styles.password]: hasView,
          })}
          value={value}
          onChange={onChange}
          type={isShowPassword ? 'text' : type}
          autoFocus={autoFocus}
          tabIndex={isDisabled ? -1 : tabIndex}
          maxLength={maxCharacters}
          readOnly={readOnly}
          onFocus={setOnFocus}
          onBlur={onBlur}
          autoComplete={autoComplete || 'off'}
          onKeyDown={onKeyDown}
          name={name && name}
        />
        <label className={placeholderClasses}>{placeholder}</label>

        {(!isDisabled && value) && (
          <div className={cn(styles.inputActions, { [styles.showPassIcon]: hasView })}>
            <Button
              variant="link"
              tabIndex={-1}
              className={styles.inputClear}
              icon={<CloseIcon />}
              onClick={onClear || (() => {})}
            />
            {hasView && (
              <Button
                variant="link"
                tabIndex={-1}
                className={styles.inputShowPass}
                onClick={handleShowPassword}
                icon={isShowPassword ? <ShowIconOff /> : <ShowIconOn />}
              />
            )}
          </div>
        )}
      </div>
      {(error?.length ?? 0) > 1 && <div className={styles.inputErrorMessage}>{error}</div>}
    </div>
  );
});

TextInput.displayName = 'TextInput';

export default TextInput;
