import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IHttpRequestResult } from 'interfaces/api';
import { http, createHttpRequestInitResult, createExtraReducersForResponses } from 'helpers/http';
import { queryFormatter } from 'utils';
import { AxiosResponse } from 'axios';
import { IQueryConfig } from 'interfaces';
import store, { RootState } from 'store/store';

interface GetGeoBlockingDataPayload {
  domainId: string;
  query?: IQueryConfig;
}

interface ToggleGeoBlockingStatusPayload {
  domainId: string;
  checkedCountries: string[];
}

interface IChangeCountryAllowedListPayload {
  domainId: string;
  checkedCountries: string[];
}

export interface ICountries {
  addedBy: string;
  countryName: string;
  dateAdded: string;
  isEnabled: boolean;
}

export interface IGeoBlockingData {
  data: {
    isGeoBlockingEnabled: boolean;
    countries: ICountries[];
  };
  queryConfig?: IQueryConfig;
}

export interface IChangeCountriesData {
  countries: ICountries[];
  addedCountries: string[];
  removedCountries: string[];
}

export interface IChangeCountryAllowedListData {
  data: IChangeCountriesData;
  queryConfig?: IQueryConfig;
}

export const getGeoBlockingData = createAsyncThunk(
  'geoBlocking/getGeoBlockingData',
  async ({ domainId, query }: GetGeoBlockingDataPayload, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const queryConfig = state.geoBlocking.geoBlockingData?.queryConfig || {};
    const queryConfigNew = { ...queryConfig, ...query };
    const response: AxiosResponse = await http.get(
      queryFormatter(`/api/SecurityManagement/GeoBlocking/Get?domainId=${domainId}`, queryConfigNew),
    );
    const data: IGeoBlockingData = {
      data: response.data,
      queryConfig: queryConfigNew,
    };
    return data;
  },
);

export const toggleGeoBlockingStatus = createAsyncThunk(
  'geoBlocking/toggleGeoBlockingStatus',
  async ({ domainId, checkedCountries }: ToggleGeoBlockingStatusPayload) => {
    const queryConfig = store.getState().geoBlocking.geoBlockingData?.queryConfig;
    const baseUrl = `/api/SecurityManagement/GeoBlocking/ChangeDomainStatus?domainId=${domainId}`;
    const response: AxiosResponse = await http.post(baseUrl, checkedCountries);
    const data: IGeoBlockingData = {
      data: response.data,
      queryConfig,
    };
    return data;
  },
);

export const changeCountryAllowedList = createAsyncThunk(
  'geoBlocking/changeCountryAllowedList',
  async ({ domainId, checkedCountries }: IChangeCountryAllowedListPayload) => {
    const queryConfig = store.getState().geoBlocking.geoBlockingData?.queryConfig;
    const baseUrl = `/api/SecurityManagement/GeoBlocking/ChangeCountryAllowedList?domainId=${domainId}`;
    const response: AxiosResponse = await http.post(baseUrl, checkedCountries);
    const data: IChangeCountriesData = response.data;
    const returnData: IChangeCountryAllowedListData = {
      data,
      queryConfig,
    };
    return returnData;
  },
);

interface IGeoBlockingState {
  geoBlockingData: IGeoBlockingData | null;
  getGeoBlockingDataRequest: IHttpRequestResult<IGeoBlockingData>;
  toggleGeoBlockingStatusRequest: IHttpRequestResult<IGeoBlockingData>;
  changeCountryAllowedListRequest: IHttpRequestResult<IChangeCountryAllowedListData>;
}

const initialState: IGeoBlockingState = {
  geoBlockingData: null,
  getGeoBlockingDataRequest: createHttpRequestInitResult(),
  toggleGeoBlockingStatusRequest: createHttpRequestInitResult(),
  changeCountryAllowedListRequest: createHttpRequestInitResult(),
};

export const GeoBlockingSlice = createSlice({
  name: 'GeoBlocking',
  initialState,
  reducers: {
    updateGeoBlockingData: (state, { payload }) => {
      state.geoBlockingData = payload;
    },
  },
  extraReducers: (builder) => {
    createExtraReducersForResponses<IGeoBlockingState>(
      builder,
      getGeoBlockingData,
      'getGeoBlockingDataRequest',
      (state, { payload }) => {
        state.geoBlockingData = payload;
      },
    );
    createExtraReducersForResponses<IGeoBlockingState>(
      builder,
      toggleGeoBlockingStatus,
      'toggleGeoBlockingStatusRequest',
      (state, { payload }) => {
        state.geoBlockingData = payload;
      },
    );
    createExtraReducersForResponses<IGeoBlockingState>(
      builder,
      changeCountryAllowedList,
      'changeCountryAllowedListRequest',
      (state, { payload }) => {
        const isGeoBlockingEnabled = payload.data.countries.length > 0;
        const countries = payload.data.countries;
        state.geoBlockingData = {
          data: {
            isGeoBlockingEnabled,
            countries,
          },
          queryConfig: payload.queryConfig,
        };
      },
    );
  },
});

export const { updateGeoBlockingData } = GeoBlockingSlice.actions;

export default GeoBlockingSlice.reducer;
