import { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { useAppDispatch, useAppSelector } from 'store';
import { chartConfig, chartOptions, prepareTimeRangeConfiguration, notify } from 'utils';
import { Button, DropdownArray, StatusMessage } from 'components';
import { CalendarIcon, DownloadIcon } from 'assets/img';
import DropdownCheckUsers from './components/DropdownCheckUsers';
import 'chart.js/auto';
import { downloadAuthReport, getLoginReports, updateSelectedRange, updateSelectedUsers } from 'store/slices/reports';
import styles from './Authentication.module.scss';

interface Stats {
  successful: { x: Date; y: number }[];
  failed: { x: Date; y: number }[];
}

interface Props {
  domainId: string;
}

const Authentication = ({ domainId }: Props) => {
  const dispatch = useAppDispatch();

  const { data: logins, isError, error } = useAppSelector((state) => state.reports.getLoginReportsRequest);
  const { users, selectedUsers, selectedRange } = useAppSelector((state) => state.reports);
  const { isLoading } = useAppSelector((state) => state.reports.downloadAuthReportRequest);

  const timeRange = useMemo(() => prepareTimeRangeConfiguration(), []);

  if (isError) {
    return <StatusMessage error>{error}</StatusMessage>;
  }

  const stats: Stats = { successful: [], failed: [] };
  if (logins?.dailyStats) {
    stats.successful = logins.dailyStats.map((item) => ({
      x: new Date(item.year, item.month, item.day),
      y: item.numberOfSuccessfullConnections,
    }));
    stats.failed = logins.dailyStats.map((item) => ({
      x: new Date(item.year, item.month, item.day),
      y: item.numberOfFailedConnections,
    }));
  }

  const getOptions = (range: string = selectedRange, users: string[] = selectedUsers) => {
    const rangeData = timeRange.find((i) => i.text.toString() === range);
    if (!rangeData) {
      throw new Error('Invalid range');
    }
    return {
      start: rangeData.startDate,
      end: rangeData.endDate,
      timeZone: new Date().getTimezoneOffset(),
      users: users,
    };
  };

  const downloadReport = async () => {
    try {
      const options = getOptions();
      await dispatch(downloadAuthReport({ domainId, ...options })).unwrap();
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  const handleUpdateDateRange = async (range: string) => {
    dispatch(updateSelectedRange(range));
    const options = getOptions(range, selectedUsers);
    await dispatch(getLoginReports({ domainId, ...options })).unwrap();
  };

  const handleUpdateUsers = async (users: string[]) => {
    dispatch(updateSelectedUsers(users));
    const options = getOptions(selectedRange, users);
    await dispatch(getLoginReports({ domainId, ...options })).unwrap();
  };

  const data = chartConfig(stats.successful, stats.failed);
  const options = chartOptions(stats.successful);

  return (
    <>
      <div className={styles.authHeader}>
        <div className={styles.authHeaderWrap}>
          <DropdownArray
            id="reports-select-range"
            isSmall={false}
            isSearch={false}
            isFilter
            icon={<CalendarIcon />}
            options={timeRange.map((i) => i.text.toString())}
            defaultValue={selectedRange}
            onChange={(value) => handleUpdateDateRange(value as string)}
            width="12em"
          />
          <DropdownCheckUsers
            id="reports-select-users"
            options={users}
            optionsChecked={selectedUsers}
            valueKey="upn"
            checkKey="userId"
            defaultValue={
              <>
                All Users <b>({selectedUsers.length})</b>
              </>
            }
            onToggleCheck={handleUpdateUsers}
          />
        </div>

        <Button
          id="download-report"
          className={styles.downloadButton}
          variant="primary"
          onClick={downloadReport}
          isLoading={isLoading}
          icon={!isLoading ? <DownloadIcon /> : undefined}
          style={{ width: '15em' }}>
          Download CSV Report
        </Button>
      </div>

      <div className={styles.authChart}>
        <Line data={data as any} options={options as any} />
      </div>
    </>
  );
};

export default Authentication;
