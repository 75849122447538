import { DownloadIcon, ExternalLinkIcon } from 'assets/img';
import { Alert } from 'components';
import { notify } from 'utils';
import { useAppDispatch, useAppSelector } from 'store';
import { msalLoginPopup } from 'store/slices/msal';
import styles from './AlertDomainNotConnected.module.scss';
import { SerializedError } from '@reduxjs/toolkit';
import { IDomain } from 'interfaces';

interface Props {
  domain: IDomain;
}

const AlertDomainNotConnected = ({ domain }: Props) => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.msal.msalLoginPopupRequest);
  const { downloadSentryLink } = useAppSelector((state) => state.user.user);

  const onLogin = async () => {
    try {
      const { id, dnsName } = domain;
      await dispatch(
        msalLoginPopup({
          scopes: ['User.Read.All', 'Group.Read.All'],
          prompt: 'select_account',
          state: JSON.stringify({ domainId: id, domainName: dnsName, isUpdateUser: true }),
        }),
      ).unwrap();
      notify.success('Successfully connected');
    } catch (err: any) {
      if (!(err as SerializedError).message?.includes('user_cancelled')) {
        notify.error(err.message);
      }
    }
  };

  return (
    <Alert title="You are not connected to the authentication source" type="warning" className={styles.alertAzure}>
      Please connect to&nbsp;
      <button
        type="button"
        id="connect-to-azure-ad"
        onClick={onLogin}
        disabled={isLoading}
        className={styles.alertAzureConnect}>
        Microsoft Entra ID&nbsp;
        <ExternalLinkIcon />
      </button>
      &nbsp;or&nbsp;
      <a href={downloadSentryLink} className={styles.alertAzureConnect}>
        Install Sentry&nbsp;
        <DownloadIcon />
      </a>
      &nbsp; to conect to Active Directory
    </Alert>
  );
};

export default AlertDomainNotConnected;
