import cn from 'classnames';
import { useAppDispatch, useAppSelector } from 'store';
import { setApplyForConnectors, toggleBrandingStatus } from 'store/slices/branding';
import { SwitchCheckbox, Tooltip } from 'components';
import { notify } from 'utils';
import { TooltipIcon } from 'assets/img';
import styles from './BrandingHeader.module.scss';

interface Props {
  domainId: string;
  domainName: string;
  isCustomer: boolean;
}

const BrandingHeader = ({ domainId, domainName, isCustomer }: Props) => {
  const dispatch = useAppDispatch();
  const { brandingStatus, branding } = useAppSelector((state) => state.branding);
  const { isLoading } = useAppSelector((state) => state.branding.toggleBrandingStatusRequest);

  const handleToggleBrandingStatus = async () => {
    try {
      await dispatch(toggleBrandingStatus(domainId)).unwrap();
      notify.success(`Branding Status is ${brandingStatus ? 'disabled' : 'enabled'} for ${domainName}`);
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  const toggleApplyForConnectors = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(setApplyForConnectors(e.target.checked));
  };

  return (
    <header className={cn(styles.header, { [styles.customer]: isCustomer })}>
      <div className={styles.headerWrap}>
        <div className={styles.headerCheckbox}>
          <div className={styles.headerCheckboxWrap}>
            <p className={styles.headerCheckboxTitle}>Branding Status</p>
            <Tooltip
              id="info-tooltip"
              place="top"
              offset={{ top: -3, left: -2 }}
              className={styles.headerCheckboxTooltip}
              wrapClassName={styles.headerCheckboxTooltipWrap}
              content="Customize how your brand appears on TruGrid">
              <TooltipIcon />
            </Tooltip>
          </div>
          <SwitchCheckbox
            id="branding-toggle"
            checked={brandingStatus}
            disabled={isLoading}
            onChange={handleToggleBrandingStatus}
          />
          <span className={cn(styles.headerCheckboxText, { [styles.active]: brandingStatus })}>
            {brandingStatus ? 'ON' : 'OFF'}
          </span>
        </div>
      </div>
      <div className={cn(styles.headerCheckbox, { [styles.disabled]: !branding?.modified?.isActive })}>
        <p>Apply to Desktop Connectors</p>
        <SwitchCheckbox
          id="apply-for-desktop-connectors"
          checked={branding?.modified?.applyToDesktopConnectors || false}
          onChange={toggleApplyForConnectors}
        />
        <span
          className={cn(styles.headerCheckboxText, {
            [styles.active]: branding?.modified?.applyToDesktopConnectors || false,
          })}>
          {branding?.modified?.applyToDesktopConnectors || false ? 'ON' : 'OFF'}
        </span>
      </div>
    </header>
  );
};

export default BrandingHeader;
