import { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Loader } from 'components';
import NoDevices from './pages/Devices/components/NoDevices/NoDevices';
import SuspenededMessage from './components/SuspenededMessage/SuspendedMessage';
import { useAppDispatch, useAppSelector } from 'store';
import { IDomain } from 'interfaces';
import {
  getBitlockerDevices,
  getBitlockerEncryption,
  getBitlockerSubscriptionStatus,
} from 'store/slices/bitLockerMgmt';
import { checkIsNoDevices } from './utils/bitLockerUtils';
import Devices from './pages/Devices/Devices';
import Encryptions from './pages/Encryptions/Encryptions';
import styles from './BitLockerMgmt.module.scss';

interface Props {
  domain: IDomain;
  userRole: string;
}

const BitLockerMgmt = ({ domain, userRole }: Props) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const { bitlockerSubscriptionStatus } = useAppSelector((state) => state.bitLockerMgmt);
  const { bitlockerDevicesData: devices } = useAppSelector((state) => state.bitLockerMgmt);

  useEffect(() => {
    const fetchData = async () => {
      const queryConfig = {
        pageNumber: 1,
        pageSize: 10,
        orderBy: 'dateAdded asc',
        deviceStatus: 'AllDevices',
        installationStatus: 'AllStatuses',
      };
      await Promise.allSettled([
        dispatch(getBitlockerDevices({ domainId: domain.id, query: queryConfig })),
        dispatch(getBitlockerEncryption({ domainId: domain.id, query: queryConfig })),
        dispatch(getBitlockerSubscriptionStatus(domain.id)),
      ]);
      setIsLoading(false);
    };
    fetchData();
    return () => {
      setIsLoading(false);
    };
  }, []);

  if (isLoading) return <Loader id="loading-bitlocker-page" />;

  const isNoDevices = checkIsNoDevices(devices, bitlockerSubscriptionStatus);

  if (isNoDevices) {
    return <NoDevices domainId={domain.id} />;
  }

  return (
    <div className={styles.deviceMgmt}>
      <h3 className={styles.deviceMgmtTitle}>Manage BitLocker Devices</h3>
      <Tabs forceRenderTabPanel>
        {!bitlockerSubscriptionStatus && <SuspenededMessage userRole={userRole} />}
        <TabList>
          <Tab>
            <span id="tab-devices">Devices</span>
          </Tab>
          <Tab>
            <span id="tab-encryption">Encryption</span>
          </Tab>
        </TabList>

        <TabPanel>
          <Devices userRole={userRole} domainId={domain.id} />
        </TabPanel>
        <TabPanel>
          <Encryptions userRole={userRole} domainId={domain.id} />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default BitLockerMgmt;
