import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { http, createHttpRequestInitResult, createExtraReducersForResponses } from 'helpers/http';
import { IHttpRequestResult } from 'interfaces';
import { returnFileFromResponse } from 'utils';

interface IWhiteListData {
  domainId: string;
  displayName: string;
  isCsv: boolean;
}

export const getWhiteListData = createAsyncThunk(
  'ipWhiteList/getWhiteListData',
  async ({ domainId, displayName, isCsv }: IWhiteListData) => {
    const response: AxiosResponse<string> = await http.post(
      '/api/SecurityManagement/IpWhitelist/GetIpWhiteListReport',
      {
        DomainId: domainId,
        UserName: displayName,
        IsCsv: isCsv,
      },
    );
    return returnFileFromResponse(response);
  },
);

interface IPWhiteListState {
  getWhiteListDataRequest: IHttpRequestResult<string>;
}

const initialState: IPWhiteListState = {
  getWhiteListDataRequest: createHttpRequestInitResult(),
};

export const ipWhiteListSlice = createSlice({
  name: 'ipWhiteList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createExtraReducersForResponses<IPWhiteListState>(builder, getWhiteListData, 'getWhiteListDataRequest');
  },
});

export default ipWhiteListSlice.reducer;
