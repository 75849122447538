import cn from 'classnames';
import Link from 'components/Link/Link';
import { SocialFbIcon, SocialLiIcon, SocialXIcon, SocialYtIcon } from 'assets/img';
import { IBrandingContactInfo } from 'interfaces';
import styles from '../BrandedLinks.module.scss';

interface Props {
  contactInformation: IBrandingContactInfo;
}

const Connect = ({ contactInformation }: Props) => {
  const { facebookLink, twitterLink, youtubeLink, linkedinLink } = contactInformation;
  const validateText = (string: string) => {
    if (/(http(s?)):\/\//i.test(string)) {
      return string;
    }
    return `https://${string}`;
  };

  return (
    <ul className={cn(styles.linksDropdownList, styles.connect)}>
      {facebookLink && (
        <li>
          <Link href={validateText(facebookLink)} external>
            <SocialFbIcon />
            Facebook
          </Link>
        </li>
      )}
      {twitterLink && (
        <li>
          <Link href={validateText(twitterLink)} external>
            <SocialXIcon />X
          </Link>
        </li>
      )}
      {youtubeLink && (
        <li>
          <Link href={validateText(youtubeLink)} external>
            <SocialYtIcon />
            YouTube
          </Link>
        </li>
      )}
      {linkedinLink && (
        <li>
          <Link href={validateText(linkedinLink)} external>
            <SocialLiIcon />
            LinkedIn
          </Link>
        </li>
      )}
    </ul>
  );
};

export default Connect;
