import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { createExtraReducersForResponses, createHttpRequestInitResult, http } from 'helpers/http';
import { ICustomerDomainOutdatedSentry, IDomain, IHttpRequestResult, IPageMeta, IQueryConfig } from 'interfaces';
import { RootState } from 'store/store';
import { queryFormatter } from 'utils';

export interface ICustomerDomainsData {
  data: IDomain[];
  pageMeta: IPageMeta;
  queryConfig: IQueryConfig;
}

export const getCustomerDomains = createAsyncThunk(
  'customerDomains/getCustomerDomains',
  async ({ query, _background = false }: { query?: IQueryConfig; _background?: boolean }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const queryConfig = state.customerDomains.customerDomains?.queryConfig || {};
    const queryConfigNew = { ...queryConfig, ...query };
    const response: AxiosResponse<IDomain[]> = await http.get(
      queryFormatter('/api/v2/Domains/GetCustomerDomains', queryConfigNew),
    );
    const data: ICustomerDomainsData = {
      data: response.data,
      pageMeta: response?.headers['x-pagination'] ? JSON.parse(response.headers['x-pagination']) : null,
      queryConfig: queryConfigNew,
    };
    return data;
  },
);

export const getListOfOutdatedSentries = createAsyncThunk(
  'customerDomains/getListOfOutdatedSentries',
  async (domainId: string) => {
    const response: AxiosResponse<ICustomerDomainOutdatedSentry[]> = await http.get(
      `api/sentry/GetListOfOutdatedSentries?spDomainId=${domainId}`,
    );
    const data: ICustomerDomainOutdatedSentry[] = response.data;
    return data;
  },
);

export const createNotValidCustomerDomain = createAsyncThunk(
  'customerDomains/createNotValidCustomerDomain',
  async ({ domainId, validDnsName }: { domainId: string; validDnsName: string }, thunkAPI) => {
    await http.post(`/api/Domains/CreateNotValidCustomerDomain?domainId=${domainId}`, {
      validDnsName,
    });
    const query = { pageNumber: 1 };
    await thunkAPI.dispatch(getCustomerDomains({ query, _background: true }));
  },
);

export const deleteCustomerDomain = createAsyncThunk(
  'customerDomains/deleteCustomerDomain',
  async (domainId: string, thunkAPI) => {
    await http.delete(`/api/Domains/Delete?domainId=${domainId}`);
    await thunkAPI.dispatch(getCustomerDomains({ _background: true }));
  },
);

interface ICustomerDomainsState {
  customerDomains: ICustomerDomainsData | null;
  outdatedSentries: ICustomerDomainOutdatedSentry[] | null;
  selectedDomain: IDomain | null;
  appPublishingCurrent: boolean;
  getCustomerDomainsRequest: IHttpRequestResult<ICustomerDomainsData>;
  getListOfOutdatedSentriesRequest: IHttpRequestResult<ICustomerDomainOutdatedSentry[]>;
  createNotValidCustomerDomainRequest: IHttpRequestResult<void>;
  deleteCustomerDomainRequest: IHttpRequestResult<void>;
}

const initialState: ICustomerDomainsState = {
  customerDomains: null,
  outdatedSentries: null,
  selectedDomain: null,
  appPublishingCurrent: false,
  getCustomerDomainsRequest: createHttpRequestInitResult(),
  getListOfOutdatedSentriesRequest: createHttpRequestInitResult(),
  createNotValidCustomerDomainRequest: createHttpRequestInitResult(),
  deleteCustomerDomainRequest: createHttpRequestInitResult(),
};

export const customerDomainSlice = createSlice({
  name: 'customerDomains',
  initialState,
  reducers: {
    onUpdateDomainAppPublishing(state, action) {
      const { domainId, isAppPublishingEnabled, appPublishingCurrent } = action.payload;

      if (appPublishingCurrent) {
        state.appPublishingCurrent = isAppPublishingEnabled;
      } else {
        if (state.selectedDomain) {
          state.selectedDomain = {
            ...state.selectedDomain,
            isAppPublishingEnabled,
          };
        }
        state.appPublishingCurrent = false;
      }
      if (state.customerDomains) {
        state.customerDomains = {
          ...state.customerDomains,
          data: state.customerDomains.data.map((domain) =>
            domain.id === domainId
              ? {
                  ...domain,
                  isAppPublishingEnabled,
                }
              : domain,
          ),
        };
      }
      state.appPublishingCurrent = isAppPublishingEnabled;
    },
    selectCustomerDomain(state, action) {
      state.selectedDomain = action.payload;
      state.appPublishingCurrent = action.payload.isAppPublishingEnabled;
    },
    updateDomainsAfterValidation(state, action) {
      const { domainId, activationCode } = action.payload;
      if (!state.customerDomains) return;
      state.customerDomains = {
        ...state.customerDomains,
        data: state.customerDomains.data.map((domain) =>
          domain.id === domainId
            ? {
                ...domain,
                activationCode: activationCode,
                status: 1,
              }
            : domain,
        ),
      };
    },
    updateCustomerDomain(state, action) {
      state.selectedDomain = {
        ...state.selectedDomain,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    createExtraReducersForResponses<ICustomerDomainsState>(
      builder,
      getCustomerDomains,
      'getCustomerDomainsRequest',
      (state, { payload }) => {
        state.customerDomains = payload;
      },
    );
    createExtraReducersForResponses<ICustomerDomainsState>(
      builder,
      getListOfOutdatedSentries,
      'getListOfOutdatedSentriesRequest',
      (state, { payload }) => {
        state.outdatedSentries = payload;
      },
    );
    createExtraReducersForResponses<ICustomerDomainsState>(
      builder,
      createNotValidCustomerDomain,
      'createNotValidCustomerDomainRequest',
    );
    createExtraReducersForResponses<ICustomerDomainsState>(
      builder,
      deleteCustomerDomain,
      'deleteCustomerDomainRequest',
    );
  },
});

export const { onUpdateDomainAppPublishing, selectCustomerDomain, updateDomainsAfterValidation, updateCustomerDomain } =
  customerDomainSlice.actions;

export default customerDomainSlice.reducer;
