import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Loader, Button } from 'components';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import { getStatusMessage } from './utils/getStatusMessage';
import { notify } from 'utils';
import { useAppDispatch, useAppSelector } from 'store';
import { signOut } from 'helpers';
import { processEmailVerification, resendVerificationEmail } from 'store/slices/auth/auth';
import styles from './EmailValidationResult.module.scss';

const EmailValidationResult = () => {
  const dispatch = useAppDispatch();
  const { isLoading: isSending } = useAppSelector((state) => state.auth.resendVerificationEmailRequest);
  const { user } = useAppSelector((state) => state.user);
  const history = useHistory();
  const { token }: { token: string } = useParams();

  const [error, setError] = useState({ status: false, message: '' });
  const isPersonalAccount = useAppSelector(
    (state) => state.auth.processEmailVerificationRequest.data?.isPersonalAccount,
  );
  const status = useAppSelector((state) => state.auth.processEmailVerificationRequest.data?.title);

  const [isLoading, setIsLoading] = useState(true);

  const fetchEmailVerification = async () => {
    try {
      await dispatch(processEmailVerification(token)).unwrap();
      setIsLoading(false);
    } catch (err: any) {
      setError({ status: true, message: err.message });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEmailVerification();
  }, [token]);

  const onResendEmail = async () => {
    try {
      await dispatch(resendVerificationEmail({ token }));
      notify.success('The email has been sent');
    } catch (err: any) {
      setError({ status: true, message: err.message });
    }
  };

  const clickAction = () => {
    if (user) {
      signOut();
    } else {
      history.replace('/');
    }
  };

  const renderButton = () => {
    if (status === 'Email validated successfully' || status === 'Email updated successfully') {
      return (
        <Button
          id="proceed-with-truGrid"
          onClick={clickAction}
          size="48"
          variant="primary"
          className={styles.validationButton}>
          Proceed to TruGrid
        </Button>
      );
    }
    if (status === 'Link is expired') {
      return (
        <Button
          id="resend-email"
          onClick={onResendEmail}
          size="48"
          variant="primary"
          isLoading={isSending}
          className={styles.validationButton}>
          Resend Email
        </Button>
      );
    }
    if (status === 'Invalid link') {
      return (
        <Button
          id="back-to-login"
          onClick={() => history.replace('/login')}
          size="48"
          variant="primary"
          isLoading={isSending}
          className={styles.validationButton}>
          Back to Login
        </Button>
      );
    }
  };

  if (error.status) {
    return (
      <div>
        <LoginHeader title="Invalid link" hideBack hideSteps />
        <div className={styles.validationInfo}>The page is not available</div>
      </div>
    );
  }

  const { title, message } = getStatusMessage(status, isPersonalAccount);

  return (
    <div className={styles.validation}>
      {isLoading ? (
        <Loader id="loading-email-validation-result" />
      ) : (
        <div>
          <LoginHeader title={title} hideBack hideSteps />
          <div className={styles.validationInfo}>{message}</div>
          {renderButton()}
        </div>
      )}
    </div>
  );
};

export default EmailValidationResult;
