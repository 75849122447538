import cn from 'classnames';
import Button from 'components/Button/Button';
import styles from './ResendCode.module.scss';

interface Props {
  countDown: number;
  handleSend: () => void;
  isDisabled: boolean;
  message?: string;
  className?: any;
}

const ResendCode = ({ countDown, handleSend, message, isDisabled, className }: Props) => (
  <div className={cn(styles.countDown, { [className]: className })}>
    <span>{message || "Didn't receive a code?"}</span>
    <Button
      id="resend-sms-code"
      variant="link"
      isDisabled={isDisabled || countDown >= 1}
      onClick={handleSend}
      className={cn(styles.countDownButton, { [styles.disabled]: isDisabled })}>
      Resend Code
    </Button>
    {countDown >= 1 && <div className={styles.countDownNumbers}>({countDown} sec)</div>}
  </div>
);

export default ResendCode;
