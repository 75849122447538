import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { Loader } from 'components';
import { notify } from 'utils';
import AddPhone from './pages/AddPhone';
import VerifyPhone from './pages/VerifyPhone';
import { useAppDispatch, useAppSelector } from 'store';
import { getCountries } from 'store/slices/auth/common';
import styles from './SetupPhone.module.scss';

const SetupPhone = () => {
  const { path, url } = useRouteMatch();

  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.commonAuth.getCountriesRequest);
  const { countries } = useAppSelector((state) => state.commonAuth);

  const [phoneNumber, setPhoneNumber] = useState('');
  const [currentCountry, setCurrentCountry] = useState('US');

  const getCountriesList = async () => {
    try {
      await dispatch(getCountries()).unwrap();
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  useEffect(() => {
    getCountriesList();
  }, []);

  const dialCode = useMemo(() => countries?.[currentCountry]?.dial_code, [countries, currentCountry]);

  return (
    <div className={styles.setup}>
      {isLoading ? (
        <Loader id="loading-setup-phone" />
      ) : (
        <Switch>
          <Route path={path} exact>
            <AddPhone
              countriesList={countries}
              dialCode={dialCode}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              currentCountry={currentCountry}
              setCurrentCountry={setCurrentCountry}
            />
          </Route>
          <Route path={`${url}/verify-phone`}>
            <VerifyPhone phoneNumber={phoneNumber} dialCode={dialCode} />
          </Route>
          <Route path="*" render={() => <Redirect to={path} />} />
        </Switch>
      )}
    </div>
  );
};

export default SetupPhone;
