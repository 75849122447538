import { Modal } from 'components';
import styles from './PreventLeaveModal.module.scss';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  modalConfig: {
    title: string;
    message: string;
    btnClose: string;
    btnAccept: string;
  };
  handleConfirmLeave: () => void;
}

const PreventLeaveModal = ({ isOpen, closeModal, modalConfig, handleConfirmLeave }: Props) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={closeModal}
    contentLabel={modalConfig.title}
    cancelTitle={modalConfig.btnClose}
    cancelButtonClass={styles.modalCancel}
    actionFunc={handleConfirmLeave}
    actionTitle={modalConfig.btnAccept}
    variantBtn="deleteOutline">
    {modalConfig.message}
  </Modal>
);

export default PreventLeaveModal;
