import cn from 'classnames';
import { useAppDispatch } from 'store';
import { Table } from 'components';
import { IRoleUser, IRolesMgmtData, getRolesMgmtData } from 'store/slices/rolesMgmt';
import { CheckIcon, MinusIcon } from 'assets/img';
import styles from './RolesMgmtTable.module.scss';
import { IQueryConfig } from 'interfaces';

interface Props {
  assignments: IRolesMgmtData;
  selectedUsers: IRoleUser[];
  domainId: string;
  setSelectedUsers: (data: IRoleUser[]) => void;
}

const RolesMgmtTable = ({ assignments, selectedUsers, domainId, setSelectedUsers }: Props) => {
  const dispatch = useAppDispatch();

  const columns = [
    {
      name: 'User Name',
      sortable: 'name',
      checkbox: true,
      width: '50%',
      data: ({ name }: { name: string }) => name || 'N/A',
    },
    {
      name: 'Domain Name',
      sortable: 'upn',
      checkbox: false,
      width: '35%',
      data: 'upn',
    },
    {
      name: 'Role',
      sortable: 'isAssigned',
      checkbox: false,
      width: '15%',
      data: ({ isAssigned }: { isAssigned: boolean }) =>
        isAssigned ? (
          <div className={cn(styles.rolesRole, styles.rolesRoleAdmin)}>
            <CheckIcon />
            Admin
          </div>
        ) : (
          <div className={cn(styles.rolesRole, styles.rolesRoleNA)}>
            <MinusIcon />
            N/A
          </div>
        ),
    },
  ];

  const handleSelectUsers = (data: IRoleUser[]): void => {
    setSelectedUsers(data);
  };

  const dispatchAction = async (query: IQueryConfig) => {
    await dispatch(getRolesMgmtData({ domainId: domainId, query, _background: true })).unwrap();
  };

  return (
    <Table<IRoleUser>
      columns={columns}
      data={assignments}
      selectedItems={selectedUsers}
      setSelectedItems={handleSelectUsers}
      id="roles-mgmt-table"
      dataKey="userId"
      className={styles.rolesTable}
      dispatchAction={dispatchAction}
    />
  );
};

export default RolesMgmtTable;
