import WorkspaceTable from 'pages/Workspace/components/WorkspaceTable/WorkspaceTable';
import { getDomainADStatus, isSentryDeleted, replaceSpecChar } from 'helpers';
import AlertNoSentryInstalled from 'pages/Workspace/components/AlertNoSentryInstalled/AlertNoSentryInstalled';
import { DropdownArray, Link, SearchInput, StatusMessage } from 'components';
import { getCustomerDomainMachines, IMyDomainMachines } from 'store/slices/workspace';
import { IDomain } from 'interfaces';
import { useAppDispatch, useAppSelector } from 'store';
import { checkIsShowAlertNoSentryInstalled } from '../utils/customerUtils';
import { getFilterData } from 'pages/Workspace/components/WorkspaceTabs/utils/machinesUtils';
import styles from '../CustomersWorkspace.module.scss';

interface Props {
  domain: IDomain;
  custMachines: IMyDomainMachines;
  userRole: string;
}

const WorkspaceDomains = ({ domain, custMachines, userRole }: Props) => {
  const dispatch = useAppDispatch();
  const { isDomainHybryd, isDomainOnPremise, isDomainAzure } = getDomainADStatus(domain);
  const sentryDeleted = isSentryDeleted(domain);

  const { isError, error } = useAppSelector((state) => state.workspace.getCustomerDomainMachinesRequest);

  const isShowAlertNoSentryInstalled = checkIsShowAlertNoSentryInstalled(
    userRole,
    isDomainAzure,
    custMachines,
    sentryDeleted,
  );
  const isShowAll = isDomainAzure || ((isDomainHybryd || isDomainOnPremise) && sentryDeleted);
  const hasSecureConnects = (domain.machinesCount ?? 0) > 0 && domain.sentries.length === 0;
  const { filterOptions, filterValue, filterValueHtml } = getFilterData('myDomainCust', custMachines);

  const onChangeView = async (value: string) => {
    const queryConfig = {
      domainId: domain.id,
      pageNumber: 1,
      kind: value.split(' ')[0],
    };
    await dispatch(getCustomerDomainMachines({ query: queryConfig })).unwrap();
  };

  const onSearch = async (value: string) => {
    const valueNew = replaceSpecChar(value);
    const queryConfig = {
      domainId: domain.id,
      pageNumber: 1,
      searchQuery: valueNew,
    };
    await dispatch(getCustomerDomainMachines({ query: queryConfig })).unwrap();
  };

  const renderDomains = () => {
    if (!custMachines.data.length && custMachines.pageMeta?.AllMachinesCount && !custMachines.queryConfig.searchQuery) {
      return (
        <StatusMessage header="No computers reporting in">
          Change view or{' '}
          <Link href="https://www.trugrid.com/docs/installsentry" external bold>
            add computers
          </Link>
        </StatusMessage>
      );
    }

    return (
      <WorkspaceTable<IMyDomainMachines>
        tableData={custMachines}
        isComputers
        dispatchAction={getCustomerDomainMachines}
        isCustomerDomain
      />
    );
  };

  if (isError) {
    return <StatusMessage error>{error}</StatusMessage>;
  }

  return (
    <>
      {isShowAlertNoSentryInstalled && <AlertNoSentryInstalled domain={domain} />}
      <div className={styles.header}>
        {!hasSecureConnects ? (
          <DropdownArray
            id="workspace-select"
            isSmall={false}
            isSearch={false}
            isFilter
            isDisabled={isError}
            strightRight
            label="View by:"
            options={filterOptions}
            defaultValue={filterValue}
            defaultValueHtml={filterValueHtml}
            onChange={(value) => onChangeView(value as string)}
            width="17.9em"
          />
        ) : (
          <div className={styles.headerTotal}>
            {`${custMachines?.queryConfig.assigned || custMachines?.queryConfig.kind} Computers`}
            <span className={styles.headerTotalCounter}> ({custMachines?.pageMeta?.TotalCount || 0})</span>
          </div>
        )}
        <SearchInput
          id="ws-search"
          searchBy="Computer Name"
          strightLeft={!isShowAll && !hasSecureConnects}
          onChange={(value) => onSearch(value)}
        />
      </div>

      {renderDomains()}
    </>
  );
};

export default WorkspaceDomains;
