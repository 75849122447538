import { useState } from 'react';
import cn from 'classnames';
import { InfoActiveIcon, InfoIcon } from 'assets/img';
import styles from './ShortcutExpiration.module.scss';

interface Props {
  expirationDaysMsg: string;
}

const ShortcutExpiration = ({ expirationDaysMsg }: Props) => {
  const [isInfoActive, setIsInfoActive] = useState<boolean>(false);

  return (
    <div
      className={styles.shortcutExpiration}
      onMouseEnter={() => setIsInfoActive(true)}
      onMouseLeave={() => setIsInfoActive(false)}>
      <p>
        Shortcut expiration: <b>{expirationDaysMsg}</b>
      </p>
      <div className={styles.shortcutExpirationIcon}>{isInfoActive ? <InfoActiveIcon /> : <InfoIcon />}</div>
      <div className={cn(styles.shortcutExpirationTooltip, { [styles.shortcutExpirationTooltipActive]: isInfoActive })}>
        The countdown starts after the user creates a shortcut
      </div>
    </div>
  );
};

export default ShortcutExpiration;
