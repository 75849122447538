import { Modal } from 'components';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  modalMessage: string;
}

const BrowserExtensionModal = ({ isOpen, onRequestClose, modalMessage }: Props) => (
  <Modal isOpen={isOpen} onRequestClose={onRequestClose} contentLabel="Install Browser Extension">
    {modalMessage}
  </Modal>
);

export default BrowserExtensionModal;
