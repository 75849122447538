import { useEffect, useState } from 'react';
import cn from 'classnames';
import { Button, Checkbox } from 'components';
import { countries } from 'consts/countries';
import styles from './CountriesList.module.scss';

interface Props {
  checkedCountries: string[];
  setNewCheckedCountries: (newCheckedCountries: string[]) => void;
  inputText: string;
  initCheckedCountries: React.MutableRefObject<string[]>;
}

const CountriesList = ({ checkedCountries, setNewCheckedCountries, inputText, initCheckedCountries }: Props) => {
  const [selectedAll, setSelectedAll] = useState(checkedCountries.length === countries.length || false);

  useEffect(() => {
    setSelectedAll(checkedCountries.length === countries.length);
  }, [checkedCountries]);

  const filteredData = countries.filter((el) => {
    if (inputText === '') return el;
    return el.toLowerCase().includes(inputText);
  });

  if (!filteredData.length) {
    return (
      <div className={styles.noResults}>
        <p>No results found</p>
      </div>
    );
  }

  const selectAllToggle = () => {
    const newList = [...checkedCountries];
    if (countries.length === newList.length) {
      newList.splice(0, newList.length);
    } else {
      countries.map((country: string) => {
        const index = newList.findIndex((item: string) => item === country);
        if (index === -1) {
          return newList.push(country);
        }
      });
    }
    setNewCheckedCountries(newList);
  };

  const checkCountry = (name: string) => {
    const newList = [...checkedCountries];
    const index = newList.findIndex((item) => item === name);
    if (index === -1) {
      newList.push(name);
    } else {
      newList.splice(index, 1);
    }
    setNewCheckedCountries(newList.sort());
  };

  return (
    <>
      <div className={styles.listRow}>
        <div className={styles.listSelectCountries}>Select Countries</div>
        <Button variant="link" id="toggle-select" onClick={selectAllToggle} className={styles.listSelectAllBtn}>
          {selectedAll ? 'Unselect all' : 'Select all'}
        </Button>
      </div>
      <div className={styles.listWrap}>
        <ul className={styles.list}>
          {filteredData
            .filter((item: string) => initCheckedCountries.current.includes(item))
            .map((country: string) => (
              <li
                key={country}
                className={cn(styles.listItem, {
                  [styles.listItemNew]:
                    checkedCountries.includes(country) !== initCheckedCountries.current.includes(country),
                })}>
                <Checkbox
                  id={country}
                  label={country}
                  labelPosition="left"
                  checked={checkedCountries.includes(country)}
                  onChange={() => checkCountry(country)}
                  className={styles.listCheckbox}
                />
              </li>
            ))}
          {filteredData
            .filter((item) => !initCheckedCountries.current.includes(item))
            .map((country) => (
              <li
                key={country}
                className={cn(styles.listItem, {
                  [styles.listItemNew]:
                    checkedCountries.includes(country) !== initCheckedCountries.current.includes(country),
                })}>
                <Checkbox
                  id={country}
                  label={country}
                  labelPosition="left"
                  checked={checkedCountries.includes(country)}
                  onChange={() => checkCountry(country)}
                  className={styles.listCheckbox}
                />
              </li>
            ))}
        </ul>
      </div>
    </>
  );
};

export default CountriesList;
