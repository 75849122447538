import { LogoIcon } from 'assets/img';
import BrandedLinks from 'components/BrandedLinks/BrandedLinks';
import { useAppSelector } from 'store';
import styles from './LoginLayout.module.scss';

interface Props {
  children: JSX.Element;
}

const LoginLayout = ({ children }: Props) => {
  const { branding } = useAppSelector((state) => state.user);

  const isBrandingActive = branding?.isActive;

  return (
    <div className={styles.login}>
      <div
        className={styles.loginHeader}
        style={isBrandingActive && branding?.backGroundColor ? { background: branding?.backGroundColor } : {}}
      />
      <div className={styles.loginWrap}>
        <div className={styles.loginLogo}>
          {isBrandingActive && branding?.logo ? (
            <img
              src={`data:image/png;base64,${branding?.logo}`}
              width="107px"
              alt="Custom Logo"
              className="logo_icon"
              style={{ height: '46px', width: 'auto', maxWidth: '26em', objectFit: 'contain' }}
            />
          ) : (
            <LogoIcon />
          )}
        </div>
        <div className={styles.loginContainer}>{children}</div>
      </div>
      {isBrandingActive && (
        <div className={styles.loginRow}>
          <BrandedLinks contactInformation={branding?.contactInformation} />
        </div>
      )}
      {isBrandingActive && <div className={styles.loginCopyright}>Powered by trugrid.com</div>}
    </div>
  );
};

export default LoginLayout;
