import { Button, CodeInput, Link } from 'components';
import { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { notify } from 'utils';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import { useAppDispatch, useAppSelector } from 'store';
import { verifyPhoneNumber } from 'store/slices/auth/mfa';
import styles from '../ResetViaSMS.module.scss';
import { SerializedError } from '@reduxjs/toolkit';

interface Props {
  phoneDigits: string;
  setPhoneDigits: React.Dispatch<React.SetStateAction<string>>;
}

const ValidatePhone = ({ phoneDigits, setPhoneDigits }: Props) => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.mfa.verifyPhoneNumberRequest);
  const { isRegisteredUser: isAdmin, phoneMask } = useAppSelector((state) => state.auth.signInData);
  const [isError, setIsError] = useState('');
  const history = useHistory();
  const { url } = useRouteMatch();

  const clearError = () => setIsError('');

  const handleGetResetMfaHash = async () => {
    try {
      await dispatch(verifyPhoneNumber(phoneDigits)).unwrap();
      notify.success('SMS has been sent');
      history.push(`${url}/verification-code`);
    } catch (err: any) {
      if ((err as SerializedError).code === '409' || (err as SerializedError).code === '405') {
        notify.error(err.message);
        history.replace('/login');
      } else {
        notify.error(err.message);
      }
    }
  };

  return (
    <div className={styles.resetMfa}>
      <LoginHeader title="Validate Your Phone" stepCount="2" currentStep="1" />
      <div className={styles.resetMfaInfo}>
        We will send a verification code to <strong>{phoneMask}</strong>. Please enter the last 4 digits of your phone
        number to confirm your identity.
      </div>
      <div className={styles.resetMfaForm}>
        <div className={styles.resetMfaLabel}>Enter Last 4 Digits</div>
        <CodeInput
          fields={4}
          isError={!!isError}
          errorMessage={isError}
          clearError={clearError}
          isDisable={isLoading}
          value={phoneDigits}
          setValue={setPhoneDigits}
          handleSend={handleGetResetMfaHash}
          sendOnKeyDown
        />
        <Button
          id="send-number-phone"
          className={styles.resetMfaButton}
          onClick={handleGetResetMfaHash}
          size="40"
          variant="primary"
          isDisabled={phoneDigits.length < 4 || !!isError}
          isLoading={isLoading}>
          Send Code
        </Button>
      </div>
      <div className={styles.resetMfaContactSupport}>
        {isAdmin ? (
          <>
            Phone is unavailable?&nbsp;
            <Link href="https://www.trugrid.com/contact-us/" external bold className={styles.contactSupportLink}>
              Contact Support
            </Link>
          </>
        ) : (
          <>Phone is unavailable? Contact your Administrator for assistance</>
        )}
      </div>
    </div>
  );
};

export default ValidatePhone;
