import { useState } from 'react';
import { Link, Loader } from 'components';
import { notify } from 'utils';
import { useAppDispatch, useAppSelector } from 'store';
import { changeCustomerDomainBrandingStatus } from 'store/slices/branding';
import { IBrandingDomain } from 'interfaces';
import DomainsTable from './components/DomainsTable/DomainsTable';
import ViewBy from './components/ViewBy/ViewBy';
import HeaderActions from './components/HeaderActions/HeaderActions';
import styles from './BrandingCustomers.module.scss';

const BrandingCustomers = () => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.branding.getCustomerDomainsBrandingRequest);
  const { braindingDomains: domains, selectedDomains } = useAppSelector((state) => state.branding);
  const [btnLoadingCurrent, setBtnLoadingCurrent] = useState<string[]>([]);
  const [headerLoading, setHeaderLoading] = useState(false);

  const queryViewBy = domains?.queryConfig?.viewBy || 'All';

  if (isLoading) {
    return (
      <div className={styles.branding}>
        <Loader id="loading-branding-customer" />
      </div>
    );
  }

  const handleChangeDomainBrandingStatus = async (isHeader: boolean, domain: IBrandingDomain) => {
    try {
      const domainIds = domain ? [domain.domainId] : selectedDomains.map((d) => d.domainId);
      const actionType = selectedDomains.map((d) => d.status).includes(true) ? 'disabled' : 'enabled';

      if (isHeader) {
        setHeaderLoading(true);
      } else {
        setBtnLoadingCurrent((prevState: string[]) => [...prevState, domain.domainId]);
      }
      await dispatch(
        changeCustomerDomainBrandingStatus({
          domainIds: domainIds,
          isActive: domain ? !domain.status : queryViewBy !== 'Enabled',
        }),
      );
      if (isHeader && selectedDomains.length > 1) {
        notify.success(`Branding Status is ${actionType} for domains`);
      } else {
        notify.success(
          `Branding Status is ${
            domain ? (domain.status ? 'disabled' : 'enabled') : selectedDomains[0]?.status ? 'disabled' : 'enabled'
          } for ${domain ? domain?.dnsName : selectedDomains[0]?.dnsName}`,
        );
      }
    } catch (err: any) {
      notify.error(err.message);
    } finally {
      if (isHeader) {
        setHeaderLoading(false);
      } else {
        setBtnLoadingCurrent((prevState) => prevState.filter((id) => id !== domain.domainId));
      }
    }
  };

  if (domains?.isDomainsEmpty) {
    return (
      <div className={styles.noDomains}>
        <div className={styles.noDomainsTitle}>No Customer Domains added</div>
        <div className={styles.noDomainsLink}>
          Pleasee&nbsp;
          <Link href="https://www.trugrid.com/docs/addcustomerdomain" external bold>
            add customer domains
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div>
      {selectedDomains.length > 0 && domains ? (
        <HeaderActions
          domains={domains}
          handleChangeDomainBrandingStatus={handleChangeDomainBrandingStatus}
          queryViewBy={queryViewBy}
          headerLoading={headerLoading}
        />
      ) : (
        <div className={styles.viewBy}>
          <ViewBy />
        </div>
      )}
      <DomainsTable
        btnLoadingCurrent={btnLoadingCurrent}
        handleChangeDomainBrandingStatus={handleChangeDomainBrandingStatus}
      />
    </div>
  );
};

export default BrandingCustomers;
