import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CodeInput, ResendCode, Link } from 'components';
import { useTimer } from 'hooks';
import { notify } from 'utils';
import { useAppDispatch, useAppSelector } from 'store';
import { requestVerificationCodeByEmail, resetMfaWithPin } from 'store/slices/auth/mfa';
import styles from '../ResetViaEmail.module.scss';
import { SerializedError } from '@reduxjs/toolkit';

const SendCode = () => {
  const dispatch = useAppDispatch();
  const { isRegisteredUser: isAdmin } = useAppSelector((state) => state.auth.signInData);
  const { emailMask } = useAppSelector((state) => state.mfa);
  const { pinHash } = useAppSelector((state) => state.mfa);
  const { isLoading } = useAppSelector((state) => state.mfa.resetMfaWithPinRequest);
  const [codeValue, setCodeValue] = useState('');
  const [isError, setIsError] = useState('');

  const history = useHistory();

  const { timeLeft, resetCountdown } = useTimer(60);

  const clearError = () => setIsError('');

  const resendVerificationCode = async () => {
    try {
      await dispatch(requestVerificationCodeByEmail()).unwrap();
      resetCountdown(60);
      notify.success('Email has been sent');
    } catch (err: any) {
      if ((err as SerializedError).code === '409') {
        notify.error(err.message);
        history.replace('/login');
      } else {
        notify.error(err.message);
      }
    }
  };

  const handleSendVerificationCode = async () => {
    try {
      await dispatch(resetMfaWithPin({ mfaPin: codeValue, mfaHash: pinHash })).unwrap();
      notify.success('MFA has been successfully reset');
      history.replace('/login');
    } catch (err: any) {
      if ((err as SerializedError).code === '405') {
        notify.error(err.message);
        history.replace('/login');
      }
      notify.error(err.message);
    }
  };

  return (
    <div className={styles.validateEmailForm}>
      <div className={styles.validateEmailInfo}>
        We've sent a verification code to
        <br />
        <strong>{emailMask}</strong>
      </div>
      <div>
        <div className={styles.validateEmailLabel}>Enter Verification Code</div>
        <CodeInput
          fields={6}
          isError={!!isError}
          errorMessage={isError}
          clearError={clearError}
          isDisable={isLoading}
          value={codeValue}
          setValue={setCodeValue}
          handleSend={handleSendVerificationCode}
        />
        <div className={styles.validateEmailResend}>
          <ResendCode countDown={timeLeft} handleSend={resendVerificationCode} isDisabled={isLoading} />
        </div>
      </div>
      {isAdmin ? (
        <div className={styles.validateEmailInfo}>
          Email is unavailable?&nbsp;
          <Link href="https://www.trugrid.com/contact-us/" bold external>
            Contact Support
          </Link>
        </div>
      ) : (
        <div className={styles.validateEmailInfo}>Email is unavailable? Contact your Administrator for assistance</div>
      )}
    </div>
  );
};

export default SendCode;
