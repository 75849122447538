import { useMemo, useState } from 'react';
import { Modal } from 'components';
import { notify } from 'utils';
import styles from './UpdateSentryModal.module.scss';
import { isAllChecked, onToggleAll, onToggleOne } from './utils/updateUtils';
import ModalHeader from './components/ModalHeader/ModalHeader';
import PrimaryItem from './components/PrimaryItem/PrimaryItem';
import CustomerItem from './components/CustomerItem/CustomerItem';
import { ICustomerDomainOutdatedSentry, ISentry } from 'interfaces';
import { useAppDispatch, useAppSelector } from 'store';
import { updateSentry } from 'store/slices/workspace';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  sentries: ISentry[] | ICustomerDomainOutdatedSentry[];
  customerDomain?: boolean;
}

const UpdateSentryModal = ({ isOpen, onRequestClose, sentries, customerDomain = false }: Props) => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.workspace.updateSentryRequest);

  const [checkedSentries, setCheckedSentries] = useState<ISentry[]>([]);

  const handleToggleAll = (isChecked: boolean, items: ISentry[]) => {
    const selectedItems = onToggleAll(isChecked, checkedSentries, items);
    setCheckedSentries(selectedItems);
  };

  const handleToggleOne = (checked: boolean, item: ISentry) => {
    const selectedItems = onToggleOne(checked, item, checkedSentries);
    setCheckedSentries(selectedItems);
  };

  const onToggleMainCheckbox = () => {
    if (customerDomain) {
      const allSentries: ISentry[] = (sentries as ICustomerDomainOutdatedSentry[])
        .map((i) => i.sentries)
        .flat()
        .filter((i) => i.isOnline);
      const isChecked = isAllChecked(checkedSentries, allSentries) === 'empty';
      handleToggleAll(isChecked, allSentries);
    } else {
      const isChecked = isAllChecked(checkedSentries, sentries as ISentry[]) === 'empty';
      handleToggleAll(isChecked, sentries as ISentry[]);
    }
  };

  const allSentries = useMemo(
    () => (sentries as ICustomerDomainOutdatedSentry[]).map((i) => i.sentries).flat(),
    [sentries],
  );
  const allChecked = isAllChecked(checkedSentries, customerDomain ? allSentries : (sentries as ISentry[]));

  const handleUpdateSentry = async () => {
    try {
      const selectedIds = checkedSentries.map((i) => i.apiKey);
      await dispatch(updateSentry(selectedIds)).unwrap();
      notify.success(
        <>
          <div className={styles.toastTitle}>Your request is in progress</div>
          <div className={styles.toastMessage}>Please refresh this page in a few minutes</div>
        </>,
      );
      onRequestClose();
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  const allCount = sentries.length;
  const customerSentries = (sentries as ICustomerDomainOutdatedSentry[]).map((i) => i.sentries).flat();
  const disableMainCheckbox = useMemo(
    () =>
      customerDomain
        ? customerSentries.filter((i) => i.isOnline).length === 0
        : (sentries as ISentry[]).filter((i) => i.isOnline).length === 0,
    [customerDomain, customerSentries, sentries],
  );
  const isButtonDisabled = checkedSentries.length === 0;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Update Sentry"
      actionFunc={handleUpdateSentry}
      actionDisabled={isButtonDisabled}
      actionLoading={isLoading}
      actionTitle="Update Now"
      enableOverflow
      stickyFooter>
      <div className={styles.modal}>
        <ModalHeader
          customerDomain={customerDomain}
          allChecked={allChecked}
          allCount={allCount}
          disableMainCheckbox={disableMainCheckbox}
          onToggleMainCheckbox={onToggleMainCheckbox}
        />
        <ul className={styles.modalList}>
          {sentries.map((item) =>
            customerDomain ? (
              <CustomerItem
                key={(item as ICustomerDomainOutdatedSentry).domainName}
                item={item as ICustomerDomainOutdatedSentry}
                handleToggleOne={handleToggleOne}
                checkedSentries={checkedSentries}
                setCheckedSentries={setCheckedSentries}
              />
            ) : (
              <PrimaryItem
                key={(item as ISentry).apiKey}
                item={item as ISentry}
                handleToggleOne={handleToggleOne}
                checkedSentries={checkedSentries}
              />
            ),
          )}
        </ul>
      </div>
    </Modal>
  );
};

export default UpdateSentryModal;
