import cn from 'classnames';
import { CircleCloseIcon } from 'assets/img';
import { Button } from 'components';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import styles from './SetButton.module.scss';
import { convertHex, isColor } from '../../../helpers/colorsHelper';
import { useAppDispatch, useAppSelector } from 'store';
import { updateBranding } from 'store/slices/branding';

interface Props {
  hexError: boolean;
  setHexError: (isColor: boolean) => void;
}

const SetButton = ({ hexError, setHexError }: Props) => {
  const { branding } = useAppSelector((state) => state.branding);
  const dispatch = useAppDispatch();

  const [hexInput, setHexInput] = useState(branding?.modified?.backGroundColor?.slice(1) || '');

  useEffect(() => {
    setHexError(!isColor(hexInput ? `#${hexInput}` : '#1f2536') || hexInput?.length === 4);
  }, [hexInput, setHexError]);

  useEffect(() => {
    setHexInput(branding?.modified?.buttonColor?.slice(1) || '');
  }, [branding?.modified?.buttonColor]);

  const handleInputBackground = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value.replace('#', '').slice(0, 6);
    setHexInput(val);
    dispatch(updateBranding({ buttonColor: `#${val}` }));
  };

  const updateBackgroundColor = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateBranding({ buttonColor: e.target.value }));
  };

  const onClearRemove = () => {
    setHexError(false);
    setHexInput('');
    dispatch(updateBranding({ buttonColor: null }));
  };

  return (
    <div className={styles.backgroundRow}>
      <div className={styles.backgroundLabel}>
        <p className={styles.backgroundLabelTitle}>Button Color</p>
        <p className={styles.backgroundLabelInfo}>Customize main buttons color</p>
      </div>
      <div>
        <div className={styles.backgroundWrap}>
          <label htmlFor="profile-button-color" className={cn(styles.backgroundButton, { [styles.error]: hexError })}>
            {branding?.modified?.buttonColor?.slice(1) && branding?.modified?.buttonColor?.slice(1).length > 0 ? (
              <span
                className={styles.backgroundButtonColor}
                style={{ backgroundColor: convertHex(branding?.modified?.buttonColor?.slice(1)) }}
              />
            ) : (
              <span className={styles.backgroundButtonColor} style={{ background: '#4169b8' }} />
            )}
            <div className={styles.backgroundButtonColorWrap}>
              <span style={{ color: '#848A92' }}>#</span>
              <input
                type="text"
                className={styles.backgroundButtonColorInput}
                onChange={handleInputBackground}
                value={hexInput}
                placeholder="4169b8"
              />
            </div>
          </label>
          <input
            type="color"
            className={styles.backgroundInput}
            onChange={updateBackgroundColor}
            id="profile-button-color"
            name="profile-button-color"
            value={branding?.modified?.buttonColor ? convertHex(branding?.modified?.buttonColor.slice(1)) : '#1f2536'}
          />
          {!!branding?.modified?.buttonColor && (
            <Button
              variant="link"
              id="clear-background-color"
              icon={<CircleCloseIcon />}
              className={styles.backgroundClear}
              onClick={onClearRemove}
            />
          )}
        </div>
      </div>
    </div>
  );
};

SetButton.propTypes = {
  updateBrandingInfo: PropTypes.func,
  branding: PropTypes.object,
  hexError: PropTypes.bool,
  setHexError: PropTypes.func,
};

export default SetButton;
