import { useState } from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';
import styles from './Tooltip.module.scss';

interface Props {
  id: string;
  offset?: any;
  content: string | JSX.Element;
  place?: 'top' | 'right' | 'bottom' | 'left';
  className?: any;
  wrapClassName?: any;
  contentClassName?: any;
  children: JSX.Element;
}

const Tooltip = ({ id, offset, content, place, className, wrapClassName, contentClassName, children }: Props) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const tooltipContainer = document.getElementById('tooltip-container');

  return (
    <>
      <div
        data-tip
        data-for={id}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={wrapClassName}>
        {children}
      </div>
      {showTooltip && (
        <>
          {ReactDOM.createPortal(
            <ReactTooltip
              id={id}
              offset={offset}
              className={cn(styles.tooltip, { [className]: className })}
              type="light"
              effect="solid"
              place={place || 'top'}>
              <div className={contentClassName}>{content}</div>
            </ReactTooltip>,
            tooltipContainer as Element,
          )}
        </>
      )}
    </>
  );
};

export default Tooltip;
