import { Table } from 'components';
import { useAppDispatch, useAppSelector } from 'store';
import { getMfaData, IMfaUser } from 'store/slices/securityMgmt/mfaMgmt';
import { getCurrentPageUserIds, getViewByOptions } from '../../utils/mfaMgmtUtils';
import { TypeAction } from '../../MfaMgmt';
import { tableColumns } from './components/tableColumns';
import styles from './MfaMgmtTable.module.scss';
import { IQueryConfig } from 'interfaces';

interface Props {
  domainId: string;
  selectedUsers: IMfaUser[];
  setSelectedUsers: (users: IMfaUser[]) => void;
  onModalOpen: (type: TypeAction, user: IMfaUser, isHeaderBtn?: boolean) => void;
  showEnableMfaModal: boolean;
}

const MfaMgmtTable = ({ domainId, selectedUsers, setSelectedUsers, onModalOpen, showEnableMfaModal }: Props) => {
  const dispatch = useAppDispatch();
  const { mfaData } = useAppSelector((store) => store.mfaMgmt);

  const { isSetupView, isDisabledView, isResetView, isAllView } = getViewByOptions(mfaData);
  const currentPageUserIds = getCurrentPageUserIds(isSetupView, isResetView, mfaData);

  const onCloseDropdown = (element: HTMLElement) => {
    const dropdown = element.querySelector('.dropdown-component__select') as HTMLElement;
    if (dropdown?.classList.contains('active')) dropdown.click();
  };

  const dispatchAction = async (query: IQueryConfig) => {
    await dispatch(getMfaData({ domainId: domainId, query: query })).unwrap();
  };

  return (
    <Table<IMfaUser>
      data={mfaData}
      id="mfa-mgmt-table"
      columns={tableColumns(
        onModalOpen,
        isResetView,
        isAllView,
        isSetupView,
        isDisabledView,
        showEnableMfaModal,
        selectedUsers,
      )}
      selectedItems={selectedUsers}
      setSelectedItems={setSelectedUsers}
      ruleForSelectItems={(a) => !((isSetupView || isResetView) && a.isAdmin)}
      ruleForToggleAll={currentPageUserIds}
      dataKey="userId"
      className={styles.table}
      onMouseLeave={(e) => onCloseDropdown(e.target as HTMLElement)}
      dispatchAction={dispatchAction}
    />
  );
};

export default MfaMgmtTable;
