import {
  corpAdmin,
  corpTechAdmin,
  corpTechUser,
  custAdmin,
  custUser,
  freePersonalUser,
  personalUser,
  spAdmin,
  spTechAdmin,
  spTechUser,
  techAdmin,
} from 'consts';
import { getDomainADStatus, isSentryDeleted } from 'helpers';
import { ISentry, IUser } from 'interfaces';
import { IMyDomainMachines } from 'store/slices/workspace';

export const checkIsEmptyScreen = (machines: IMyDomainMachines, userRole: string) => {
  const isEmptyScreen =
    !machines?.data?.length && !machines?.queryConfig?.searchQuery && ![spTechAdmin, corpTechAdmin].includes(userRole);
  return isEmptyScreen;
};

export const checkIsShowAll = (user: IUser, sentries: ISentry[], userRole: string, hasSecureConnects: boolean) => {
  const { isDomainAzure, isDomainHybryd, isDomainOnPremise } = getDomainADStatus(user);
  const sentryDeleted = isSentryDeleted(user, sentries);

  const isShowAll =
    [custUser, custAdmin, techAdmin, spTechUser, corpTechUser, personalUser, freePersonalUser].includes(userRole) ||
    (user.roles.includes('mspadmin') && isDomainAzure) ||
    ((isDomainHybryd || isDomainOnPremise) && sentryDeleted) ||
    (user.showOnboarding && hasSecureConnects);

  return isShowAll;
};

export const checkIsEmptyPage = (machines: IMyDomainMachines, userRole: string) => {
  let isEmptyPage = !machines?.data?.length && !machines?.queryConfig?.searchQuery;

  if ([spTechAdmin, spTechUser, corpTechAdmin].includes(userRole)) {
    isEmptyPage =
      (machines?.queryConfig?.assigned?.toLowerCase() === 'all' && !machines.pageMeta?.AllMachinesCount) ||
      (machines?.queryConfig?.assigned?.toLowerCase() === 'my' && !machines.pageMeta?.AssignedMachinesCount);
  }

  if (userRole === techAdmin) {
    isEmptyPage = !machines?.pageMeta?.AssignedMachinesCount;
  }

  return isEmptyPage;
};

export const checkShowSentryUpdateAlert = (user: IUser, userRole: string, sentries: ISentry[]) => {
  const hasOutdatedSentry = sentries?.some((sentry) => sentry.isOutdated);
  const { showSentryUpdateMessageForAdAdmins } = user;

  const notShownForAdAdmin =
    [spTechAdmin, corpTechAdmin, custAdmin, techAdmin].includes(userRole) && !showSentryUpdateMessageForAdAdmins;

  const showSentryUpdateAlert =
    [spAdmin, corpAdmin, spTechAdmin, corpTechAdmin, custAdmin].includes(userRole) &&
    hasOutdatedSentry &&
    !notShownForAdAdmin;

  return showSentryUpdateAlert;
};

export const checkIsShowAlertNoSentryInstalled = (
  user: IUser,
  userRole: string,
  sentries: ISentry[],
  machines: IMyDomainMachines,
) => {
  const { isDomainAzure } = getDomainADStatus(user);
  const sentryDeleted = isSentryDeleted(user, sentries);

  const showAlertNoSentryInstalled =
    [spAdmin, corpAdmin].includes(userRole) && !isDomainAzure && !!machines?.data.length && sentryDeleted;

  return showAlertNoSentryInstalled;
};

export const getFilterData = (myDomainPage: string, machines: IMyDomainMachines) => {
  const filterValue =
    myDomainPage === 'myDomainWithFilter'
      ? `${machines?.queryConfig.assigned} Computers`
      : `${machines?.queryConfig.kind} Computers`;

  const filterValueHtml =
    myDomainPage === 'myDomainWithFilter' ? (
      <>
        {machines?.queryConfig.assigned} Computers <b>({machines?.pageMeta?.TotalCount || 0})</b>
      </>
    ) : (
      <>
        {machines?.queryConfig.kind} Computers <b>({machines?.pageMeta?.TotalCount || 0})</b>
      </>
    );

  const filterOptions =
    myDomainPage === 'myDomainWithFilter'
      ? ['My Computers', 'All Computers']
      : ['Pooled Computers', 'NonPooled Computers', 'All Computers'];

  return { filterValue, filterValueHtml, filterOptions };
};
