import { useHistory } from 'react-router-dom';
import { Button, DropdownArray } from 'components';
import { ArrowLeftIcon } from 'assets/img';
import { techAdmin } from 'consts';
import { useAppSelector } from 'store';
import { objectsAreNotEqual } from 'utils';
import styles from './SettingsHeader.module.scss';

interface Props {
  role: string;
  isSaving: boolean;
  isMultipleSettings: boolean;
  onChangeDomain: (domainName: string, discard: boolean) => void;
  onSaveSettings: (value: string, isChangeDomain: any) => void;
  onResetSettings: () => void;
}

const SettingsHeader = ({
  role,
  isSaving,
  isMultipleSettings,
  onChangeDomain,
  onSaveSettings,
  onResetSettings,
}: Props) => {
  const { settings, settingDomains, currentDomain } = useAppSelector((state) => state.settings);
  const isDisabled = !objectsAreNotEqual(settings);

  const isTechAdmin = role === techAdmin;
  const history = useHistory();

  const goToPreviousPage = () => history.goBack();

  return (
    <div className={styles.header}>
      <div className={styles.headerLeft}>
        <Button
          id="settings-go-back"
          variant="link"
          icon={<ArrowLeftIcon />}
          onClick={goToPreviousPage}
          className={styles.headerLeftBackBtn}
        />
        <span className={styles.headerLeftTitle}>Settings</span>
        {isMultipleSettings && (
          <DropdownArray
            id="settings-domains-select"
            isSmall
            isSearch
            options={[
              'My Settings',
              ...Object.values(settingDomains)
                .filter((d) => d.dnsName)
                .map((d) => d.dnsName),
            ]}
            defaultValue={currentDomain}
            onChange={(value) => onChangeDomain(value as string, false)}
            width="18.25em"
            className={styles.headerLeftDropdown}
            optionsClassName={styles.headerLeftDropdownOptions}
          />
        )}
      </div>
      <div className={styles.headerRight}>
        <Button type="button" id="settings-reset" size="40" variant="secondary" onClick={onResetSettings}>
          Reset All to Default
        </Button>
        {isMultipleSettings && (!isTechAdmin || currentDomain !== 'My Settings') ? (
          <DropdownArray
            id="settings-save"
            isDisabled={isDisabled || isSaving}
            isButton
            btnTitle={isSaving ? 'Saving...' : 'Save'}
            options={['Save', 'Save As']}
            onChange={(value) => onSaveSettings(value as string, false)}
            width="7.5em"
            optionsClassName={styles.headerRightDropdownOptions}
          />
        ) : (
          <Button
            type="button"
            id="settings-save"
            size="40"
            variant="primary"
            isDisabled={isDisabled}
            isLoading={isSaving}
            onClick={() => onSaveSettings('Save', false)}>
            Save
          </Button>
        )}
      </div>
    </div>
  );
};

export default SettingsHeader;
