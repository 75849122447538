import { AttentionIcon } from 'assets/img';
import styles from './DomainMgmtInfo.module.scss';

const DomainMgmtInfo = () => (
  <div className={styles.domainMgmtInfo}>
    <div className={styles.domainMgmtIcon}>
      <AttentionIcon />
    </div>
    <div>
      <div className={styles.domainMgmtTitle}>
        Hybrid Authentication Support: requires on-premises Active Directory and Entra ID synchronization
      </div>
      <div className={styles.domainMgmtText}>
        TruGrid supports Entra ID Authentication for access to Active Directory Resources. In order to enable Hybrid
        Authentication,
        <br />
        please connect your TruGrid domain to Active Directory and Entra ID. We recommend using Entra ID Connect
        <br />
        from Microsoft to synchronize your domains
      </div>
    </div>
  </div>
);

export default DomainMgmtInfo;
