import { Link, StatusMessage } from 'components';

const EmptyTable = () => (
  <StatusMessage header="No users reporting in" absolute={false}>
    Change view or{' '}
    <Link href="https://www.trugrid.com/docs/usersnotreportingin" external bold>
      add users
    </Link>
  </StatusMessage>
);

export default EmptyTable;
