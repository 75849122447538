import cn from 'classnames';
import { Button } from 'components';
import { RefreshIcon, RefreshWhiteIcon, InfoIcon } from 'assets/img';
import { notify } from 'utils';
import { getDomainADStatus } from 'helpers';
import ErrorModal from './components/ErrorModal/ErrorModal';
import SentryHostsInfoModal from './components/SentryHostsInfoModal/SentryHostsInfoModal';
import { IDomain } from 'interfaces';
import useModal from 'hooks/useModal';
import { useAppDispatch, useAppSelector } from 'store';
import {
  IRefreshOnPremiseDataRepsonse,
  refreshAppData,
  refreshAzureAdData,
  refreshOnPremiseData,
} from 'store/slices/resourceAssignments';
import { SerializedError } from '@reduxjs/toolkit';
import styles from './AssignmentsRefresh.module.scss';
import { getAuthTokenSilent } from 'store/slices/msal';

export type ErrorInfo = {
  title: string;
  message: number;
};

interface Props {
  domain: IDomain;
  isAppsPage?: boolean;
  title?: string;
  isRefreshing?: boolean;
}

const AssignmentsRefresh = ({ domain, isAppsPage, title, isRefreshing }: Props) => {
  const dispatch = useAppDispatch();

  const { isLoading } = useAppSelector((state) => state.resourceAssignments.refreshAzureAdDataRequest);
  const { isLoading: isOnPremisehLoading } = useAppSelector(
    (state) => state.resourceAssignments.refreshOnPremiseDataRequest,
  );
  const { isLoading: isTokenLoading } = useAppSelector((state) => state.msal.getAuthTokenSilentRequest);

  const { authToken } = useAppSelector((state) => state.msal);

  const isRefreshLoading = isLoading || isRefreshing || isTokenLoading || isOnPremisehLoading;

  const isSentriesHasOffline = domain.sentries?.some((s) => !s.isOnline);
  const { isDomainAzure } = getDomainADStatus(domain);

  const {
    isOpen: showErrorModal,
    openModal: openErrorModal,
    closeModal: closeErrorModal,
    modalData: errorInfo,
  } = useModal<ErrorInfo>();
  const {
    isOpen: showSentryHostsInfoModal,
    openModal: openSentryHostsInfoModal,
    closeModal: closeSentryHostsInfoModal,
  } = useModal();

  const onRefreshAzureAdData = async (domainId: string, authToken: string) => {
    try {
      await dispatch(refreshAzureAdData({ domainId, authToken })).unwrap();
      notify.success('Data from Active Directory refreshed');
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  const onRefreshOnPremiseData = async (domainId: string) => {
    try {
      const response = isAppsPage
        ? await dispatch(refreshAppData({ domainId })).unwrap()
        : await dispatch(refreshOnPremiseData({ domainId })).unwrap();
      if ((response as IRefreshOnPremiseDataRepsonse).status === 206) {
        notify.success((response as IRefreshOnPremiseDataRepsonse).notify);
      } else {
        notify.success('Data from Active Directory refreshed');
      }
    } catch (err: any) {
      if ((err as SerializedError).code === '406') {
        openErrorModal(err.message);
      } else {
        notify.error(err.message);
      }
    }
  };

  const onRefreshDataFromAD = async () => {
    try {
      if (isDomainAzure) {
        if (authToken && authToken[domain.id]) {
          await onRefreshAzureAdData(domain.id, authToken[domain.id]);
        } else {
          try {
            const authToken = await dispatch(
              getAuthTokenSilent({ domainId: domain.id, domainName: domain.dnsName }),
            ).unwrap();
            if (authToken) {
              await onRefreshAzureAdData(domain.id, authToken);
            }
          } catch (err: any) {
            if (!(err as SerializedError).message?.includes('user_cancelled')) {
              notify.error(err.message);
            }
          }
        }
      } else {
        onRefreshOnPremiseData(domain.id);
      }
    } catch (err: any) {
      if (!(err as SerializedError).message?.includes('user_cancelled')) {
        notify.error(err.message);
      }
    }
  };

  return (
    <>
      <div className={styles.refresh}>
        <div className={styles.refreshWrap}>
          <Button
            type="button"
            variant="primary"
            id="refresh-ad"
            size="32"
            outline
            rounded
            icon={isRefreshLoading ? <RefreshWhiteIcon /> : <RefreshIcon />}
            className={cn(styles.refreshWrapButton, { [styles.isLoading]: isRefreshLoading })}
            onClick={onRefreshDataFromAD}>
            {title || 'Refresh data from AD'}
          </Button>
        </div>
        {!isDomainAzure && (
          <InfoIcon
            id="show-sentry-hosts"
            className={
              isSentriesHasOffline
                ? cn(styles.refreshStatus, styles.refreshStatusOffline)
                : cn(styles.refreshStatus, styles.refreshStatusOnline)
            }
            onClick={openSentryHostsInfoModal}
          />
        )}
      </div>

      {showErrorModal && <ErrorModal isOpen={showErrorModal} onRequestClose={closeErrorModal} errorInfo={errorInfo!} />}

      {showSentryHostsInfoModal && (
        <SentryHostsInfoModal
          isOpen={showSentryHostsInfoModal}
          onRequestClose={closeSentryHostsInfoModal}
          domain={domain}
        />
      )}
    </>
  );
};

export default AssignmentsRefresh;
