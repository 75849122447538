import cn from 'classnames';
import { ExclamationIcon, MinusIcon, PlusIcon } from 'assets/img';
import { Button } from 'components';
import styles from './InputControlsCustomer.module.scss';
import { IWarning } from '../../../PrimaryDomain/PrimaryDomain';
import { ICorporateSubscription, ISubscriptionDomain } from 'interfaces';
import { useAppSelector } from 'store';

type LicenseType = 'numberOfRdpLicenses' | 'numberOfBitLockerLicenses';
type PurchasedByType = 'totalPurchasedRdpLicenses' | 'totalPurchasedBitLockerLicenses';
type CanceledByType = 'canceledRdpLicenses' | 'canceledBitLockerLicenses';
type AllocatedByType = 'totalAllocatedRdpLicenses' | 'totalAllocatedBitLockerLicenses';

interface Props {
  initDomain: ISubscriptionDomain;
  licensesState: ICorporateSubscription;
  setLicensesState: React.Dispatch<React.SetStateAction<ICorporateSubscription>>;
  warning: IWarning;
  setWarning: React.Dispatch<React.SetStateAction<IWarning>>;
  typeFunc: LicenseType;
  id: string;
}

const InputControlsCustomer = ({
  warning,
  setWarning,
  licensesState,
  setLicensesState,
  initDomain,
  typeFunc,
  id,
}: Props) => {
  const { user } = useAppSelector((state) => state.user);
  const onIncreasingLicenses = (domainId: string, licenseType: LicenseType) => {
    const licensesStateNew: ICorporateSubscription = JSON.parse(JSON.stringify(licensesState));
    const licenseTypeAllocatedTotal: AllocatedByType = `totalAllocated${licenseType.slice(8)}` as AllocatedByType;

    if (licensesStateNew.spDomain[licenseType] > 0) {
      licensesStateNew[licenseTypeAllocatedTotal] += 1;
      licensesStateNew.spDomain[licenseType] -= 1;
      licensesStateNew.customerDomains.forEach((domain) => {
        if (domain.domainId === domainId) domain[licenseType] += 1;
      });

      setLicensesState(licensesStateNew);
    } else if (!warning.isActive) {
      const content = user.roles.includes('mspadmin') ? (
        <>
          <ExclamationIcon />
          All available licenses have been allocated. Select&nbsp;<b>Primary Domain</b>&nbsp;tab to purchase more
          licenses
        </>
      ) : (
        <>
          <ExclamationIcon />
          You have exceeded the number of available licenses. Contact your company administrator for assistance
        </>
      );

      setWarning({
        isActive: true,
        field: licenseType,
        content,
      });
      setTimeout(() => setWarning({ isActive: false }), 3000);
    }
  };

  const onDecreasingLicenses = (domainId: string, licenseType: LicenseType) => {
    const licensesStateNew: ICorporateSubscription = JSON.parse(JSON.stringify(licensesState));
    const licenseTypeAllocatedTotal: AllocatedByType = `totalAllocated${licenseType.slice(8)}` as AllocatedByType;
    const currentDomain = licensesStateNew.customerDomains.find((d) => d.domainId === domainId);

    if (currentDomain && currentDomain[licenseType] > 0) {
      licensesStateNew[licenseTypeAllocatedTotal] -= 1;
      licensesStateNew.spDomain[licenseType] += 1;
      licensesStateNew.customerDomains.forEach((domain) => {
        if (domain.domainId === domainId) domain[licenseType] -= 1;
      });

      setLicensesState(licensesStateNew);
    }
  };

  const onInputChangeLicenses = (
    domainId: string,
    licenseType: LicenseType,
    isValid: boolean,
    value: string | number,
  ) => {
    const licenseTypePurchasedTotal: PurchasedByType = `totalPurchased${licenseType.slice(8)}` as PurchasedByType;
    const licenseTypeAllocatedTotal: CanceledByType = `totalAllocated${licenseType.slice(8)}` as CanceledByType;
    const currentDomain = licensesState.customerDomains.find((d) => d.domainId === domainId);
    const currentDomainVal = currentDomain ? Number(currentDomain[licenseType]) : 0;
    const isAllowedValue =
      +value + licensesState[licenseTypeAllocatedTotal] - currentDomainVal <= licensesState[licenseTypePurchasedTotal];

    if (isValid) {
      if (isAllowedValue) {
        const licensesStateNew: ICorporateSubscription = JSON.parse(JSON.stringify(licensesState));

        licensesStateNew[licenseTypeAllocatedTotal] += +value - currentDomainVal;
        licensesStateNew.spDomain[licenseType] += currentDomainVal - +value;
        licensesStateNew.customerDomains.forEach((domain) => {
          if (domain.domainId === domainId)
            (domain[licenseType as keyof ISubscriptionDomain] as any) = value === '' ? value : +value;
        });

        setLicensesState(licensesStateNew);
      } else {
        let totalAllocatedByType = 0;
        licensesState.customerDomains.forEach((d) => {
          if (d.domainId !== domainId) totalAllocatedByType += d[licenseType];
        });

        if (!warning.isActive) {
          setWarning({
            isActive: true,
            field: licenseType,
            content: (
              <>
                <ExclamationIcon />
                Maximum possible value: {licensesState[licenseTypePurchasedTotal] - totalAllocatedByType}
              </>
            ),
          });
          setTimeout(() => setWarning({ isActive: false }), 3000);
        }
      }
    }
  };

  return (
    <div className={styles.controls}>
      <Button
        variant="link"
        className={styles.controlsButton}
        id="decreasing-licenses-count"
        onClick={() => onDecreasingLicenses(initDomain.domainId, typeFunc)}>
        <MinusIcon />
      </Button>
      <span className={styles.controlsInputWrap}>
        <input
          id={id}
          type="text"
          pattern="[0-9]*"
          maxLength={7}
          className={cn(styles.controlsInput, {
            [styles.controlsInputWarning]: warning.isActive && warning.field?.includes(typeFunc),
          })}
          value={initDomain[typeFunc]}
          onChange={(e) =>
            onInputChangeLicenses(initDomain.domainId, typeFunc, e.target.validity.valid, e.target.value)
          }
          onBlur={() =>
            String(initDomain.numberOfRdpLicenses) === '' &&
            onInputChangeLicenses(initDomain.domainId, typeFunc, true, 0)
          }
          onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) =>
            e.key === 'Enter' && (e.target as HTMLInputElement).blur()
          }
        />
      </span>
      <Button
        variant="link"
        className={styles.controlsButton}
        id="increasing-licenses-count"
        onClick={() => onIncreasingLicenses(initDomain.domainId, typeFunc)}>
        <PlusIcon />
      </Button>
    </div>
  );
};

export default InputControlsCustomer;
