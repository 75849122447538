import Branding from '../Branding';
import { useAppSelector } from 'store';

const BrandingPrimary = () => {
  const { user } = useAppSelector((state) => state.user);

  return <Branding isCustomer={false} domainId={user.domainId} domainName={user.dnsDomainName} />;
};

export default BrandingPrimary;
