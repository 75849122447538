import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { http, createHttpRequestInitResult, createExtraReducersForResponses } from 'helpers/http';
import {
  IBranding,
  IBrandingContactInfo,
  IBrandingDomain,
  IHttpRequestResult,
  IPageMeta,
  IQueryConfig,
} from 'interfaces';
import { RootState } from 'store/store';
import { queryFormatter } from 'utils';

// interface IRequest {
//   _background?: boolean;
// }

export interface IBrandingDomainData {
  data?: IBrandingDomain[];
  pageMeta?: IPageMeta;
  queryConfig?: IQueryConfig;
  isDomainsEmpty?: boolean;
}

interface IGetCustomerDomainsBrandingPayload {
  query?: IQueryConfig;
  _background?: boolean;
}

export const getBranding = createAsyncThunk('branding/getBranding', async (domainId: string) => {
  const response: AxiosResponse<IBranding> = await http.get(`/api/Company/GetBranding?domainId=${domainId}`);
  return response.data;
});

export const toggleBrandingStatus = createAsyncThunk('branding/toggleBrandingStatus', async (domainId: string) => {
  await http.post('/api/Company/ToggleBrandingStatus', domainId);
});

export const editBranding = createAsyncThunk('branding/editBranding', async (_, thunkAPI) => {
  const postData = (thunkAPI.getState() as RootState).branding.branding.modified;
  const response: AxiosResponse<IBranding> = await http.post('/api/Company/UpdateBranding', postData);
  return response.data;
});

export const getCustomerDomainsBranding = createAsyncThunk(
  'branding/getCustomerDomainsBranding',
  async ({ query, _background = false }: IGetCustomerDomainsBrandingPayload, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const domainId = state.user.user.domainId;
    const queryConfig = state.branding.braindingDomains?.queryConfig || {};
    const queryConfigNew = { ...queryConfig, ...query };
    const response: AxiosResponse<IBrandingDomain[]> = await http.get(
      queryFormatter(`/api/Company/GetCustomerDomainsBranding?domainId=${domainId}`, queryConfigNew),
    );
    const pageMeta = response.headers['x-pagination'] ? JSON.parse(response.headers['x-pagination']) : null;
    if (response.status === 204) {
      return { isDomainsEmpty: true };
    }
    const data: IBrandingDomainData = {
      data: response.data,
      pageMeta,
      queryConfig: queryConfigNew,
      isDomainsEmpty: false,
    };
    return data;
  },
);

export const changeCustomerDomainBrandingStatus = createAsyncThunk(
  'branding/changeCustomerDomainBrandingStatus',
  async ({ domainIds, isActive }: { domainIds: string[]; isActive: boolean }, thunkAPI) => {
    await http.post(`/api/Company/ToggleCustomerDomainsBrandingStatus`, { domainIds, isActive });
    await thunkAPI.dispatch(getCustomerDomainsBranding({ query: { pageNumber: 1 }, _background: true }));
  },
);

export const resetDomainBranding = createAsyncThunk('branding/resetDomainBranding', async (domainId: string) => {
  const response: AxiosResponse<IBranding> = await http.post('/api/Company/ResetBranding', domainId);
  return response.data;
});

export const updateContactInfo = createAsyncThunk(
  'branding/updateContactInfo',
  async ({ formData, domainId }: { formData: IBrandingContactInfo; domainId: string }) => {
    const response: AxiosResponse<boolean> = await http.post('/api/Company/UpdateContactInfo', {
      ...formData,
      domainId,
    });
    return response.data;
  },
);

export const getContactInfo = createAsyncThunk('branding/getContactInfo', async (domainId: string) => {
  const response: AxiosResponse<IBrandingContactInfo> = await http.get(
    `/api/Company/GetContactInfo?domainId=${domainId}`,
  );
  return response.data;
});

interface IBrandingState {
  branding: {
    init: IBranding | null;
    modified: IBranding | null;
  };
  brandingStatus: boolean;
  braindingDomains: IBrandingDomainData | null;
  getBrandingRequest: IHttpRequestResult<IBranding>;
  toggleBrandingStatusRequest: IHttpRequestResult<void>;
  editBrandingRequest: IHttpRequestResult<IBranding>;
  getCustomerDomainsBrandingRequest: IHttpRequestResult<IBrandingDomainData>;
  changeCustomerDomainBrandingStatusRequest: IHttpRequestResult<void>;
  selectedDomains: IBrandingDomain[];
  resetDomainBrandingRequest: IHttpRequestResult<void>;
  updateContactInfoRequest: IHttpRequestResult<boolean>;
  getContactInfoRequest: IHttpRequestResult<IBrandingContactInfo>;
}

const initialState: IBrandingState = {
  branding: {
    init: null,
    modified: null,
  },
  brandingStatus: false,
  braindingDomains: null,
  getBrandingRequest: createHttpRequestInitResult(),
  toggleBrandingStatusRequest: createHttpRequestInitResult(),
  editBrandingRequest: createHttpRequestInitResult(),
  getCustomerDomainsBrandingRequest: createHttpRequestInitResult(),
  changeCustomerDomainBrandingStatusRequest: createHttpRequestInitResult(),
  selectedDomains: [],
  resetDomainBrandingRequest: createHttpRequestInitResult(),
  updateContactInfoRequest: createHttpRequestInitResult(),
  getContactInfoRequest: createHttpRequestInitResult(),
};

export const branding = createSlice({
  name: 'branding',
  initialState,
  reducers: {
    setApplyForConnectors(state, action: PayloadAction<boolean>) {
      if (state.branding.modified) {
        state.branding.modified = {
          ...state.branding.modified,
          applyToDesktopConnectors: action.payload,
        };
      }
    },
    updateBranding(state, action: PayloadAction<Partial<IBranding>>) {
      if (state.branding.modified) {
        state.branding.modified = {
          ...state.branding.modified,
          ...action.payload,
        };
      }
    },
    resetBranding(state) {
      state.branding.modified = state.branding.init;
    },
    setSelectedDomains(state, action: PayloadAction<IBrandingDomain[]>) {
      state.selectedDomains = action.payload;
    },
  },
  extraReducers: (builder) => {
    createExtraReducersForResponses<IBrandingState>(
      builder,
      getBranding,
      'getBrandingRequest',
      (state, actions: PayloadAction<IBranding>) => {
        state.branding.init = actions.payload;
        state.branding.modified = actions.payload;
        state.brandingStatus = actions.payload.isActive;
      },
    );
    createExtraReducersForResponses<IBrandingState>(
      builder,
      toggleBrandingStatus,
      'toggleBrandingStatusRequest',
      (state) => {
        if (state.branding.modified && state.branding.init) {
          state.brandingStatus = !state.brandingStatus;
          state.branding = {
            modified: {
              ...state.branding.modified,
              isActive: !state.branding.modified?.isActive,
            },
            init: {
              ...state.branding.init,
              isActive: !state.branding.init?.isActive,
            },
          };
        }
      },
    );
    createExtraReducersForResponses<IBrandingState>(
      builder,
      editBranding,
      'editBrandingRequest',
      (state, actions: PayloadAction<IBranding>) => {
        state.branding.init = actions.payload;
        state.branding.modified = actions.payload;
      },
    );
    createExtraReducersForResponses<IBrandingState>(
      builder,
      getCustomerDomainsBranding,
      'getCustomerDomainsBrandingRequest',
      (state, actions: PayloadAction<IBrandingDomainData>) => {
        state.braindingDomains = actions.payload;
      },
    );
    createExtraReducersForResponses<IBrandingState>(
      builder,
      changeCustomerDomainBrandingStatus,
      'changeCustomerDomainBrandingStatusRequest',
      (state) => {
        state.selectedDomains = [];
      },
    );
    createExtraReducersForResponses<IBrandingState>(
      builder,
      resetDomainBranding,
      'resetDomainBrandingRequest',
      (state, actions: PayloadAction<IBranding>) => {
        state.branding.init = actions.payload;
        state.branding.modified = actions.payload;
      },
    );
    createExtraReducersForResponses<IBrandingState>(builder, getContactInfo, 'getContactInfoRequest');
    createExtraReducersForResponses<IBrandingState>(
      builder,
      updateContactInfo,
      'updateContactInfoRequest',
      (state, actions: PayloadAction<boolean>) => {
        if (state.branding.modified && state.branding.init) {
          state.branding.init = {
            ...state.branding.init,
            isSetContactInfo: actions.payload,
          };
          state.branding.modified = {
            ...state.branding.modified,
            isSetContactInfo: actions.payload,
          };
        }
      },
    );
  },
});

export const { setApplyForConnectors, updateBranding, resetBranding, setSelectedDomains } = branding.actions;

export default branding.reducer;
