import { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { dateFormatterShort, replaceSpecChar } from 'helpers';
import { TextInput, Loader, Button, PasswordComplexity, SwitchCheckbox } from 'components';
import { useOutsideClick } from 'hooks';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import { notify } from 'utils';
import PaymentModal from './components/PaymentModal';
import { formValidation } from './utils/formValidation';
import { useAppDispatch, useAppSelector } from 'store';
import { checkIfUserCanBeRegistered, getPersonalRegistrationData } from 'store/slices/auth/auth';
import { SerializedError } from '@reduxjs/toolkit';
import styles from './PersonalRegistration.module.scss';

const LONG_INPUTS_MAP = ['Enter Your Login Email Address', 'Enter New Password', 'Confirm New Password'];
const SHORT_INPUTS_MAP = ['First Name', 'Last Name'];

const PersonalRegistration = () => {
  const dispatch = useAppDispatch();

  const [formValues, setFormValues] = useState(['', '', '', '', '', 'monthly']);
  const [formErrors, setFormErrors] = useState(['', '', '', '', '']);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const tooltipPassRef = useRef<HTMLDivElement | null>(null);

  const { isLoading: isLoadingPage, isUninitialized } = useAppSelector(
    (state) => state.auth.getPersonalRegistrationDataRequest,
  );
  const registrationData = useAppSelector((state) => state.auth.getPersonalRegistrationDataRequest.data);
  const { isLoading: isSubmitting } = useAppSelector((state) => state.auth.checkIfUserCanBeRegisteredRequest);

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  useOutsideClick(tooltipPassRef, () => setIsTooltipOpen(false));

  const toggleShowModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    const fetchPersonalRegistrationData = async () => {
      try {
        await dispatch(getPersonalRegistrationData()).unwrap();
      } catch (err: any) {
        notify.error(err.message);
      }
    };
    fetchPersonalRegistrationData();
  }, []);

  const onInputChange = (value: string, index: number) => {
    const formValuesNew = formValues.map((i, ind) => {
      if (index === 1 || index === 2) {
        return index === ind ? value : i;
      }
      return index === ind ? value.trim() : i;
    });
    setFormValues(formValuesNew);
  };

  const onClearError = (index: number) => {
    if (index === 1) {
      setIsTooltipOpen(true);
    }
    if (!formErrors[index]) return false;
    const formErrorsNew = formErrors.map((i, ind) => (ind === index ? '' : i));
    setFormErrors(formErrorsNew);
  };

  const onRegister = async () => {
    const isValid = formValidation(formValues, formErrors, setFormErrors);
    if (isValid) {
      try {
        const email = replaceSpecChar(formValues[0]);
        await dispatch(checkIfUserCanBeRegistered(email)).unwrap();
        toggleShowModal();
        setIsLoading(false);
      } catch (err: any) {
        if ((err as SerializedError).code === '403') {
          const formErrorsNew = [...formErrors];
          formErrorsNew[0] = 'User has been already registered';
          formErrorsNew[1] = '';
          formErrorsNew[2] = '';
          if (formErrorsNew.length) {
            setFormErrors(formErrorsNew);
          }
        } else {
          notify.error(err.message);
        }
      }
    }
  };

  return (
    <div className={styles.registation}>
      <LoginHeader title="Sign up for a TruGrid Personal account" hideBack hideSteps />
      {isLoadingPage || isUninitialized ? (
        <Loader id="loading-personal-registration" />
      ) : (
        <>
          {registrationData?.isPromo && registrationData.promoEndDate && (
            <div className={styles.registationPromo}>
              The promotion expires on {dateFormatterShort(registrationData.promoEndDate)}. Please take action now
            </div>
          )}
          {LONG_INPUTS_MAP.map((item, index) => (
            <div className={styles.registationRow} key={item} ref={index === 1 ? tooltipPassRef : null}>
              <TextInput
                key={item}
                id={item.split(' ').join('-')}
                placeholder={item}
                type={[1, 2].includes(index) ? 'password' : 'text'}
                autoComplete={[1, 2].includes(index) ? 'new-password' : 'off'}
                hasView={[1, 2].includes(index)}
                value={formValues[index]}
                error={formErrors[index]}
                onChange={(e) => onInputChange(e.target.value, index)}
                onFocus={() => onClearError(index)}
                onClear={() => onInputChange('', index)}
              />
              {index === 1 && isTooltipOpen && <PasswordComplexity inputValue={formValues[1]} />}
            </div>
          ))}
          <div className={cn(styles.registationRow, styles.registationRowGroup)}>
            {SHORT_INPUTS_MAP.map((item, index) => {
              index += 3;
              return (
                <TextInput
                  key={item}
                  id={item.split(' ').join('-')}
                  autoComplete="off"
                  placeholder={item}
                  value={formValues[index]}
                  error={formErrors[index]}
                  onChange={(e) => onInputChange(e.target.value, index)}
                  onFocus={() => onClearError(index)}
                  onClear={() => onInputChange('', index)}
                />
              );
            })}
          </div>
          <div className={styles.registationCheckboxes}>
            <div className={styles.registationCheckbox}>
              <span
                className={cn(styles.registationCheckboxText, { [styles.bold]: formValues[5] === 'monthly' })}
                onClick={() => onInputChange('monthly', 5)}>
                Monthly
              </span>
              <SwitchCheckbox
                id="manage-plan"
                checked={formValues[5] === 'annual'}
                onChange={() => onInputChange(formValues[5] === 'monthly' ? 'annual' : 'monthly', 5)}
                className={styles.registationCheckboxSwitch}
              />
              <span
                className={cn(styles.registationCheckboxText, { [styles.bold]: formValues[5] === 'annual' })}
                onClick={() => onInputChange('annual', 5)}>
                Annual
              </span>
            </div>
          </div>

          {formValues[5] === 'monthly' ? (
            <div className={styles.registationInfo}>
              Manage and control up to <strong>{registrationData?.monthlyMachineCount} PCs</strong> at{' '}
              <strong>${registrationData?.monthlyPrice}</strong> per month
            </div>
          ) : (
            <div className={styles.registationInfo}>
              Manage and control up to <strong>{registrationData?.annualMachineCount} PCs</strong> at{' '}
              <strong>${registrationData?.annualPrice}</strong> per year
            </div>
          )}

          <Button
            id="login-personal-registration"
            onClick={onRegister}
            variant="primary"
            size="48"
            isLoading={isSubmitting}
            style={{ marginTop: '1.5em', width: '100%' }}>
            Pay and Register
          </Button>

          {isModalOpen && (
            <PaymentModal
              isModalOpen={isModalOpen}
              toggleShowModal={toggleShowModal}
              isLoading={isLoading}
              formValues={formValues}
              registrationData={registrationData!}
            />
          )}
        </>
      )}
    </div>
  );
};

export default PersonalRegistration;
