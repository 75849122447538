import { memo } from 'react';
import cn from 'classnames';
import styles from './Checkbox.module.scss';

interface Props {
  id?: string;
  name?: string;
  checked?: boolean | string;
  isCircle?: boolean;
  isDisabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: any;
  labelPosition?: 'left' | 'right' | 'top' | 'bottom';
  className?: any;
}

const Checkbox = ({
  id,
  name,
  checked,
  isCircle,
  isDisabled,
  onChange,
  label,
  labelPosition = 'right',
  className,
}: Props) => {
  const isChecked = typeof checked === 'boolean' ? checked : checked === 'checked';
  const isPartial = checked === 'partial';

  return (
    <label
      className={cn(styles.checkbox, {
        [styles.circle]: isCircle,
        [styles.disabled]: isDisabled,
        [styles.left]: labelPosition === 'left',
        [className]: className,
      })}
      id={id}>
      <div className={styles.checkboxBlock}>
        <input type="checkbox" name={name} className={styles.checkboxInput} checked={isChecked} onChange={onChange} />
        <span className={cn(styles.checkboxWrapper, { [styles.checked]: isChecked, [styles.partial]: isPartial })}>
          {isPartial ? (
            <svg className={styles.checkboxWrapperIcon} viewBox="0 0 20 20">
              <line x1="3" y1="10" x2="17" y2="10" />
            </svg>
          ) : (
            <svg className={styles.checkboxWrapperIcon} viewBox="0 0 20 20">
              <polyline points="4 11 8 15 16 6" />
            </svg>
          )}
        </span>
      </div>
      {label && <span className={styles.checkboxLabel}>{label}</span>}
    </label>
  );
};

export default memo(Checkbox);
