import { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { notify } from 'utils';
import { Button, CountryListDropdown } from 'components';
import { ICountryList } from 'interfaces';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import SkipForeverModal from '../components/SkipForeverModal/SkipForeverModal';
import { useAppDispatch, useAppSelector } from 'store';
import { skipSetupPhoneForPasswordReset } from 'store/slices/auth/resetPassword';
import { requestVerificationCodeBySms } from 'store/slices/auth/mfa';
import styles from '../SetupPhone.module.scss';
import { SerializedError } from '@reduxjs/toolkit';
import { signOut, skipForeverSetupPhone } from 'store/slices/userSlice';

interface Props {
  countriesList: ICountryList;
  dialCode: string;
  phoneNumber: string;
  setPhoneNumber: (phoneNumber: string) => void;
  currentCountry: string;
  setCurrentCountry: (country: string) => void;
}

const AddPhone = ({
  countriesList,
  dialCode,
  phoneNumber,
  setPhoneNumber,
  currentCountry,
  setCurrentCountry,
}: Props) => {
  const { user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const { isLoading } = useAppSelector((state) => state.mfa.requestVerificationCodeBySmsRequest);
  const { isLoading: isSendingSkip } = useAppSelector(
    (state) => state.resetPassword.skipSetupPhoneForPasswordResetRequest,
  );

  const { url } = useRouteMatch();
  const history = useHistory();

  const [showSkipForeverModal, setShowSkipForeverModal] = useState(false);
  const toggleSkipForeverModal = () => setShowSkipForeverModal(!showSkipForeverModal);

  const handleSetCurrentCountry = (value: string) => setCurrentCountry(value);

  const onSubmitPhoneNumber = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (phoneNumber && phoneNumber.length > 4) {
      try {
        await dispatch(
          requestVerificationCodeBySms({ phone: phoneNumber.replace(/\D+/g, ''), code: dialCode, isMfaSetup: false }),
        ).unwrap();
        notify.success('SMS has been sent');
        history.push(`${url}/verify-phone`);
      } catch (err: any) {
        if ((err as SerializedError).code === '409') {
          notify.error(err.message);
          await dispatch(signOut()).unwrap();
          history.replace('/login');
        } else {
          notify.error(err.message);
        }
      }
    }
  };

  const skipForever = async () => {
    try {
      await dispatch(skipSetupPhoneForPasswordReset()).unwrap();
      dispatch(skipForeverSetupPhone());
      toggleSkipForeverModal();
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  return (
    <>
      <form className={styles.setup} onSubmit={onSubmitPhoneNumber} noValidate>
        <LoginHeader
          currentStep="1"
          stepCount="2"
          title="Add Password Reset Option"
          back={() => window.location.replace('/login')}
        />
        <div className={styles.setupInfo}>
          Please enter and validate your phone number so that you can reset your password in the future
        </div>
        <CountryListDropdown
          id="phone-code-dd"
          label="Enter Your Phone Number"
          options={countriesList}
          currentCountry={currentCountry}
          onChange={handleSetCurrentCountry}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
        />
        <Button
          type="submit"
          variant="primary"
          size="40"
          className={styles.setupButton}
          isLoading={isLoading}
          isDisabled={!(phoneNumber && phoneNumber.length > 4)}>
          Get a Code
        </Button>
        <Button variant="link" onClick={toggleSkipForeverModal} className={styles.setupLink}>
          Skip Forever
        </Button>
      </form>
      {showSkipForeverModal && (
        <SkipForeverModal
          isOpen={showSkipForeverModal}
          onClose={toggleSkipForeverModal}
          onSkipForever={skipForever}
          isLoading={isSendingSkip}
          email={user.userEmail}
        />
      )}
    </>
  );
};

export default AddPhone;
