import { History } from 'history';

export const goBackHandler = (currentStep: string, history: History, isSetupMfaWithoutPhone?: boolean): void => {
  if (isSetupMfaWithoutPhone) {
    if (currentStep === '1') {
      window.location.replace('/login');
    } else {
      history.goBack();
    }
  } else if (currentStep === '2') {
    window.location.replace('/login');
  } else {
    history.goBack();
  }
};
