import { useState } from 'react';
import { Modal } from 'components';
import { notify } from 'utils';
import { useAppDispatch, useAppSelector } from 'store';
import { disableAzureAdLogin, enableAzureAdLogin } from 'store/slices/securityMgmt/azureAdLogin';
import { IDomain } from 'interfaces';

interface Props {
  showEnableModal: boolean;
  onRequestClose: () => void;
  domain: IDomain;
  isAzureAdLoginEnabled: boolean;
}

const ToggleEnableModal = ({ showEnableModal, onRequestClose, domain, isAzureAdLoginEnabled }: Props) => {
  const dispatch = useAppDispatch();
  const { isLoading: isEnablingLoading } = useAppSelector((state) => state.azureAdLogin.enableAzureAdLoginRequest);
  const { isLoading: isDisablingLoading } = useAppSelector((state) => state.azureAdLogin.disableAzureAdLoginRequest);
  const [isEnableForAll, setIsEnableForAll] = useState(false);

  const toogleSetIsEnableForAll = () => {
    setIsEnableForAll((val) => !val);
  };

  const onEnableStaySignin = async () => {
    try {
      await dispatch(enableAzureAdLogin({ domainId: domain.id, isEnableForAll })).unwrap();
      notify.success('Stay Signed-In is enabled for domain');
    } catch (err: any) {
      notify.error(err.message);
    } finally {
      onRequestClose();
    }
  };

  const onDisableStaySignin = async () => {
    try {
      await dispatch(disableAzureAdLogin({ domainId: domain.id })).unwrap();
      notify.success('Stay Signed-In is disabled for domain');
    } catch (err: any) {
      notify.error(err.message);
    } finally {
      onRequestClose();
    }
  };

  const onChangeStaySignedIn = async () => {
    if (isAzureAdLoginEnabled) {
      await onDisableStaySignin();
    } else {
      await onEnableStaySignin();
    }
  };

  return (
    <Modal
      isOpen={showEnableModal}
      contentLabel={isAzureAdLoginEnabled ? 'Disable Stay Signed-In for domain?' : 'Enable Stay Signed-In for domain?'}
      onRequestClose={onRequestClose}
      actionLoading={isEnablingLoading || isDisablingLoading}
      actionFunc={onChangeStaySignedIn}
      actionTitle={isAzureAdLoginEnabled ? 'Disable' : 'Enable'}
      isSwitcherChecked={isEnableForAll}
      onChangeSwitcher={toogleSetIsEnableForAll}
      switcherTitle={!isAzureAdLoginEnabled ? 'Enable for all users' : ''}>
      {!isAzureAdLoginEnabled ? (
        <>
          By enabling this option, Entra ID users will be able to reduce sign in frequency while using TruGrid Desktop
          Connectors
        </>
      ) : (
        <>
          By disabling this option, Entra ID users will be required to sign in every time they open TruGrid Desktop
          Connectors
        </>
      )}
    </Modal>
  );
};

export default ToggleEnableModal;
