import { useEffect, useState } from 'react';
import { Loader } from 'components';
import { notify } from 'utils';
import TimeoutTitle from './components/TimeoutTitle/TimeoutTitle';
import NoUsers from './components/NoUsers/NoUsers';
import TimeoutStatus from './components/TimeoutStatus/TimeoutStatus';
import TimeoutHeader from './components/TimeoutHeader/TimeoutHeader';
import ToggleEnableModal from './components/ToggleEnableModal/ToggleEnableModal';
import TimeoutTable from './components/TimeoutTable/TimeoutTable';
import HeaderActions from './components/HeaderActions/HeaderActions';
import ChangeTimeoutPeriod from './components/ChangeTimeoutPeriod/ChangeTimeoutPeriod';
import { IDomain } from 'interfaces';
import { useAppDispatch, useAppSelector } from 'store';
import {
  ITimeoutMgmtUser,
  changeTimeoutForUsers,
  disableTimeoutForDomain,
  enableTimeoutForDomain,
  getTimeoutMgmtData,
} from 'store/slices/securityMgmt/timeoutMgmt';
import { getActionMessage } from './utils/timeoutUtils';
import styles from './TimeoutManagement.module.scss';

interface Props {
  domain: IDomain;
  isCompanyPage: boolean;
}

const TimeoutManagement = ({ domain, isCompanyPage }: Props) => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.timeoutMgmt.getTimeoutMgmtDataRequest);
  const { timeoutMgmtData } = useAppSelector((state) => state.timeoutMgmt);

  const [selectedUsers, setSelectedUsers] = useState<ITimeoutMgmtUser[]>([]);
  const [btnLoadingCurrent, setBtnLoadingCurrent] = useState<string[]>([]);
  const [isHeaderLoadingChanging, setIsHeaderLoadingChanging] = useState<boolean>(false);

  const [showToggleModal, setShowToggleModal] = useState<boolean>(false);
  const handleShowToggleModal = () => setShowToggleModal(!showToggleModal);

  const [showChangePeriodModal, setShowChangePeriodModal] = useState<boolean>(false);
  const handleShowChangePeriodModal = () => setShowChangePeriodModal(!showChangePeriodModal);

  const fetchData = async () => {
    const queryConfig = {
      pageNumber: 1,
      pageSize: 10,
      searchQuery: '',
      orderBy: 'displayName asc',
    };
    try {
      await dispatch(getTimeoutMgmtData({ domainId: domain.id, query: queryConfig })).unwrap();
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onEnableTimeoutForDomain = async (enableForAllUsers: boolean, expirationMins: number) => {
    try {
      await dispatch(enableTimeoutForDomain({ domainId: domain.id, enableForAllUsers, expirationMins })).unwrap();
      notify.success('Session Timeout is enabled for domain');
    } catch (err: any) {
      notify.error(err.message);
    } finally {
      handleShowToggleModal();
    }
  };

  const onDisableTimeout = async () => {
    try {
      await dispatch(disableTimeoutForDomain({ domainId: domain.id })).unwrap();
      notify.success('Session Timeout is disabled for domain');
    } catch (err: any) {
      notify.error(err.message);
    } finally {
      handleShowToggleModal();
    }
  };

  const onChangeTimeoutForUsers = async (isHeaderBtn: boolean, user?: ITimeoutMgmtUser) => {
    try {
      if (isHeaderBtn) {
        setIsHeaderLoadingChanging(true);
      } else {
        setBtnLoadingCurrent((itemCurrent) => [...itemCurrent, user!.userId]);
      }
      const selectedUserIds = isHeaderBtn ? selectedUsers.map((item) => item.userId) : [user!.userId];
      await dispatch(changeTimeoutForUsers({ selectedUserIds })).unwrap();
      const actionMessage = getActionMessage(selectedUsers, user);
      notify.success(actionMessage);
    } catch (err: any) {
      notify.error(err.message);
    } finally {
      if (isHeaderBtn) {
        setIsHeaderLoadingChanging(false);
      } else {
        setBtnLoadingCurrent((item) => item.filter((i) => i !== user!.userId));
      }
      if (selectedUsers.length) setSelectedUsers([]);
    }
  };

  const onChangeTimeoutForDomain = (enableForAllUsers: boolean, expirationDays: number) => {
    if (timeoutMgmtData?.isTimeoutEnabled) {
      onDisableTimeout();
    } else {
      onEnableTimeoutForDomain(enableForAllUsers, expirationDays);
    }
  };

  return (
    <div className={styles.timeout}>
      <TimeoutTitle isCompanyPage={isCompanyPage} domain={domain} />
      {isLoading ? (
        <Loader id="loading-timeout" />
      ) : timeoutMgmtData?.isNoData ? (
        <NoUsers />
      ) : (
        <>
          {selectedUsers.length > 0 ? (
            <HeaderActions
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              timeoutMgmtData={timeoutMgmtData}
              isHeaderLoadingChanging={isHeaderLoadingChanging}
              onChangeTimeoutForUsers={onChangeTimeoutForUsers}
            />
          ) : (
            <TimeoutStatus
              handleShowToggleModal={handleShowToggleModal}
              isTimeoutEnabled={timeoutMgmtData?.isTimeoutEnabled || false}
              handleShowChangePeriodModal={handleShowChangePeriodModal}
              timeoutDuration={timeoutMgmtData?.timeoutDuration || 0}
            />
          )}
          {!timeoutMgmtData?.isTimeoutEnabled ? (
            <TimeoutHeader />
          ) : (
            <TimeoutTable
              timeoutMgmtData={timeoutMgmtData}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              btnLoadingCurrent={btnLoadingCurrent}
              onChangeTimeoutForUsers={onChangeTimeoutForUsers}
              domainId={domain.id}
            />
          )}
          {showToggleModal && (
            <ToggleEnableModal
              isOpen={showToggleModal}
              onClose={handleShowToggleModal}
              onChangeTimeoutForDomain={onChangeTimeoutForDomain}
              isTimeoutEnabled={timeoutMgmtData?.isTimeoutEnabled || false}
            />
          )}
          {showChangePeriodModal && (
            <ChangeTimeoutPeriod
              isOpen={showChangePeriodModal}
              onClose={handleShowChangePeriodModal}
              domainId={domain.id}
              timeoutDuration={timeoutMgmtData?.timeoutDuration || 0}
            />
          )}
        </>
      )}
    </div>
  );
};

export default TimeoutManagement;
