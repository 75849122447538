import { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Loader, DomainStatusMsg, ADStatus } from 'components';
import { notify } from 'utils';
import { getDomainADStatus, isSentryDeleted } from 'helpers';
import UpdateSentryModal from 'components/UpdateSentryModal/UpdateSentryModal';
import AlertSentryUpdate from 'pages/Workspace/components/AlertSentryUpdate/AlertSentryUpdate';
import WorkspaceDomains from './components/WorkspaceDomains';
import WorkspaceApps from './components/WorkspaceApps';
import NoComputersMsg from './components/NoComputersMsg';
import { IDomain } from 'interfaces';
import useModal from 'hooks/useModal';
import { useAppDispatch, useAppSelector } from 'store';
import { getCustomerDomainApps, getCustomerDomainMachines, updateCustMachines } from 'store/slices/workspace';
import { checkIsNoMachines, checkIsShowADStatus, checkShowSentryUpdateAlert } from './utils/customerUtils';
import styles from './CustomersWorkspace.module.scss';

interface Props {
  domain: IDomain;
  userRole: string;
}

const CustomersWorkspace = ({ domain, userRole }: Props) => {
  const dispatch = useAppDispatch();
  const { custMachines, custApps } = useAppSelector((state) => state.workspace);
  const { showSentryUpdateMessageForAdAdmins } = useAppSelector((state) => state.user.user);

  const [isLoading, setIsLoading] = useState(true);

  const sentryDeleted = isSentryDeleted(domain);
  const { isDomainAzure } = getDomainADStatus(domain);

  const { isOpen: isOpenUpdateModal, openModal: openUpdateModal, closeModal: closeUpdateModal } = useModal();
  const hasSecureConnect = (domain.machinesCount ?? 0) > 0 && domain.sentries.length === 0;

  useEffect(() => {
    const { id, machinesCount, applicationsCount, isAppPublishingEnabled } = domain;
    const fetchData = async () => {
      try {
        const queryConfig = {
          pageNumber: 1,
          pageSize: 10,
          orderBy: 'hostname asc',
          searchQuery: '',
          domainId: id,
          kind: isDomainAzure || sentryDeleted || hasSecureConnect ? 'All' : 'Pooled',
        };
        await dispatch(getCustomerDomainMachines({ query: queryConfig }));

        if (isAppPublishingEnabled || applicationsCount || !isDomainAzure) {
          const queryAppsConfig = {
            pageNumber: 1,
            pageSize: 10,
            orderBy: 'appname asc',
            searchQuery: '',
            domainId: id,
          };
          await dispatch(getCustomerDomainApps({ query: queryAppsConfig }));
        }
        setIsLoading(false);
      } catch (err: any) {
        notify.error(err.message);
        setIsLoading(false);
      }
    };
    if (machinesCount) {
      fetchData();
    } else {
      setIsLoading(false);
    }

    return () => {
      setIsLoading(false);
      dispatch(updateCustMachines(null));
    };
  }, []);

  if (isLoading) return <Loader id="loading-customers-workspace" />;

  if (sentryDeleted && !custMachines?.pageMeta.AllMachinesCount) {
    return <DomainStatusMsg domainData={domain} isSentryDeleted={sentryDeleted} />;
  }

  const isNoMachines = checkIsNoMachines(custMachines!, isDomainAzure);
  const showSentryUpdateAlert = checkShowSentryUpdateAlert(domain, userRole, showSentryUpdateMessageForAdAdmins);
  const showADStatus = checkIsShowADStatus(domain, userRole);

  const outdatedSentries = domain.sentries?.filter((sentry) => sentry.isOutdated);

  if (isNoMachines) {
    return <NoComputersMsg domain={domain} />;
  }

  return (
    <div className={styles.workspace}>
      <div className={styles.workspaceWrap}>
        <h3 className={styles.workspaceTitle}>Domain Workspace</h3>
        {showADStatus && <ADStatus link="domain-management" domain={domain} />}
      </div>
      <Tabs forceRenderTabPanel>
        <TabList>
          <Tab>{isDomainAzure ? 'All' : ''} Computers</Tab>
          {!isDomainAzure && custApps && !hasSecureConnect && <Tab>Apps</Tab>}
        </TabList>
        <TabPanel>
          {showSentryUpdateAlert && <AlertSentryUpdate sentries={domain.sentries} openUpdateModal={openUpdateModal} />}
          <WorkspaceDomains domain={domain} custMachines={custMachines!} userRole={userRole} />
        </TabPanel>
        {!isDomainAzure && custApps && !hasSecureConnect && (
          <TabPanel>
            <WorkspaceApps domain={domain} custApps={custApps} userRole={userRole} />
          </TabPanel>
        )}
      </Tabs>
      {isOpenUpdateModal && (
        <UpdateSentryModal isOpen={isOpenUpdateModal} onRequestClose={closeUpdateModal} sentries={outdatedSentries} />
      )}
    </div>
  );
};

export default CustomersWorkspace;
