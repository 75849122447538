import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, TextInput } from 'components';
import { useOutsideClick } from 'hooks';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import { notify } from 'utils';
import ResetPasswordError from 'pages/Login/components/ResetPasswordError/ResetPasswordError';
import { useAppDispatch, useAppSelector } from 'store';
import { getInitialUserData } from 'store/slices/userSlice';
import { changePassword } from 'store/slices/auth/resetPassword';
import { SerializedError } from '@reduxjs/toolkit';
import styles from './UpdatePassword.module.scss';

const UpdatePassword = () => {
  const dispatch = useAppDispatch();

  const {
    email,
    signInData: { userId, mfaShouldBeSetup },
    password: oldPassword,
  } = useAppSelector((state) => state.auth);
  const { isLoading } = useAppSelector((state) => state.resetPassword.changePasswordRequest);

  const history = useHistory();
  const [error, setError] = useState('');

  const [formValues, setFormValues] = useState(['', '']);
  const [formErrors, setFormErrors] = useState(['', '']);
  const newPassRef = useRef<HTMLInputElement | null>(null);
  const confirmPassRef = useRef<HTMLInputElement | null>(null);
  const tooltipPassRef = useRef<HTMLInputElement | null>(null);

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  useOutsideClick(tooltipPassRef, () => setIsTooltipOpen(false));

  const onClearError = (index: number) => {
    if (index === 0) {
      setIsTooltipOpen(true);
    }
    setFormErrors(['', '']);
  };

  const onUpdate = (value: string, index: number) => {
    const passValuesNew = formValues.map((i, ind) => (index === ind ? value : i));
    setFormValues(passValuesNew);
  };

  const onClearValue = (index: number, ref: React.RefObject<HTMLInputElement>) => {
    ref.current?.focus();

    const passValuesNew = formValues.map((i, ind) => (index === ind ? '' : i));
    setFormValues(passValuesNew);

    if (formErrors[index]) onClearError(index);
  };

  const isFormValid = () => {
    const formErrorsNew = [...formErrors];
    const isNewPassValid = formValues[0].length > 0;
    const isConfirmPassValid = formValues[1] && formValues[0] === formValues[1];

    if (!isNewPassValid) {
      formErrorsNew[0] = ' ';
      formErrorsNew[1] = 'Password does not match requirements';
    }
    if (isNewPassValid && !isConfirmPassValid) {
      formErrorsNew[0] = ' ';
      formErrorsNew[1] = 'Passwords do not match';
    }

    if (formErrorsNew.length) {
      setFormErrors(formErrorsNew);
    }

    return !formErrorsNew.some((i) => i);
  };

  const loginUser = async () => {
    try {
      await dispatch(getInitialUserData({ _background: false })).unwrap();
      if (mfaShouldBeSetup) {
        return history.replace('/setup-mfa');
      }
      return history.replace('/workspace');
    } catch (error: any) {
      notify.error(error.message);
    }
  };

  const onSetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isFormValid()) {
      try {
        const data = await dispatch(changePassword({ userId, oldPassword, newPassword: formValues[0] })).unwrap();
        localStorage.setItem('TG.SSO.LOGIN', email);
        localStorage.setItem('TG.SSO.PWD', data);
        notify.success('Your password was successfully updated');
        await loginUser();
      } catch (err: any) {
        if ((err as SerializedError).code === '403') {
          setError('exceeded');
        } else if ((err as SerializedError).code === '406') {
          setError('offline');
        } else {
          notify.warning(err.message);
          setFormErrors([' ', ' ']);
        }
      }
    }
  };

  const passwordTooltip = () => (
    <>
      <h5>We recommend:</h5>
      <ul>
        <li>at least 10 characters</li>
        <li>both lower and uppercase letters</li>
        <li>numbers and special characters</li>
        <li>not repeating any previously used password</li>
      </ul>
    </>
  );

  if (error) {
    return <ResetPasswordError error={error} />;
  }

  return (
    <div className={styles.setPass}>
      <LoginHeader title="Update your password" hideSteps back={() => history.replace('/')} />
      <p className={styles.setPassInfo}>
        Your company requires you to update your password. <br />
        Create a new password according to your company's requirements
      </p>
      <form className={styles.setPassForm} method="POST" onSubmit={onSetPassword}>
        <div className={styles.setPassWrap} ref={tooltipPassRef}>
          <TextInput
            ref={newPassRef}
            id="enter-new-password"
            autoComplete="new-password"
            placeholder="Enter New Password"
            type="password"
            hasView
            value={formValues[0]}
            error={formErrors[0]}
            onChange={(e) => onUpdate(e.target.value, 0)}
            onClear={() => onClearValue(0, newPassRef)}
            onFocus={() => onClearError(0)}
          />
          {isTooltipOpen && <div className={styles.setPassTooltip}>{passwordTooltip()}</div>}
        </div>
        <div className={styles.setPassWrap}>
          <TextInput
            ref={confirmPassRef}
            type="password"
            id="confirm-new-password"
            autoComplete="new-password"
            placeholder="Confirm New Password"
            hasView
            value={formValues[1]}
            error={formErrors[1]}
            onChange={(e) => onUpdate(e.target.value, 1)}
            onClear={() => onClearValue(1, confirmPassRef)}
            onFocus={() => onClearError(1)}
          />
        </div>
        <Button
          id="set-new-password"
          size="48"
          type="submit"
          variant="primary"
          isDisabled={formValues.includes('')}
          isLoading={isLoading}>
          Set New Password
        </Button>
      </form>
    </div>
  );
};

export default UpdatePassword;
