import { ComponentType } from 'react';
import { Redirect } from 'react-router-dom';
import { getUserRole } from 'utils';
import { AlertValidateDomain, Loader } from 'components';
import { getDomainStatus } from 'helpers';
import { IDomain } from 'interfaces';
import { useAppSelector } from 'store';
import styles from './withCompany.module.scss';

type CompanyType = {
  domain: IDomain;
  isCompanyPage: boolean;
  userRole: string;
  isDomainNotActivated: boolean;
};

export const withCompany = (Component: ComponentType<CompanyType>) => () => {
  const { user } = useAppSelector((state) => state.user);
  const { isLoading } = useAppSelector((state) => state.msal.connectEntraIDRequest);

  const role = getUserRole(user);
  const domainData: IDomain = {
    id: user.domainId,
    dnsName: user.dnsDomainName,
    sentries: user.sentries,
    status: user.domainStatus,
    origin: user.domainOrigin,
    activationCode: user.domainActivationCode,
    isAppPublishingEnabled: user.isAppPublishingEnabled,
    domainActivationCode: user.domainActivationCode,
    isServiceProvider: user.spApplicationWasApproved,
    isCompanyPage: true,
  };

  const { isDomainNotActivated } = getDomainStatus(domainData);
  const { finalTimeToValidateDomain, domainWasAutovalidated } = user;

  if (!user.domainStatus) {
    return <Redirect to="/workspace" />;
  }

  if (isLoading) {
    return (
      <div className={styles.company}>
        <Loader id="loading-company-page" />
      </div>
    );
  }

  return (
    <>
      {domainWasAutovalidated && finalTimeToValidateDomain && <AlertValidateDomain />}
      <div className={styles.company}>
        <Component domain={domainData} isCompanyPage userRole={role} isDomainNotActivated={isDomainNotActivated} />
      </div>
    </>
  );
};
