import cn from 'classnames';
import { CompletedCircleIcon, MailIcon, PhoneIcon } from 'assets/img';
import { Button } from 'components';
import { EmailValidationData, TxtValidationData } from '../../Onboarding';
import styles from './ValidateDomain.module.scss';
import { IValidateModalDataBase } from 'interfaces';

interface Props {
  isDomainNotValidated: boolean;
  isDomainNotActivated: boolean;
  emailValidationData: EmailValidationData;
  txtValidationData: TxtValidationData;
  openModal: (data: IValidateModalDataBase) => void;
}

const ValidateDomain = ({
  isDomainNotValidated,
  isDomainNotActivated,
  emailValidationData,
  txtValidationData,
  openModal,
}: Props) => (
  <div
    className={cn(styles.onboarding, {
      [styles.active]: isDomainNotValidated,
      [styles.completed]: isDomainNotActivated,
    })}>
    <div className={styles.onboardingTitle}>
      <span>1</span>
      <h5>Validate Your Domain</h5>
    </div>
    {isDomainNotValidated && (
      <div className={styles.onboardingAction}>
        <span>Select one option to validate your domain</span>{' '}
        <Button
          type="button"
          variant="link"
          icon={<MailIcon />}
          onClick={() => openModal(emailValidationData as IValidateModalDataBase)}>
          WHOIS Email
        </Button>
        <span>or</span>
        <Button
          type="button"
          variant="link"
          icon={<PhoneIcon />}
          onClick={() => openModal(txtValidationData as IValidateModalDataBase)}>
          DNS TXT Record
        </Button>
      </div>
    )}
    {isDomainNotActivated && <CompletedCircleIcon />}
  </div>
);

export default ValidateDomain;
