import cn from 'classnames';
import { RefreshIcon } from 'assets/img';
import { Button, SwitchCheckbox } from 'components';
import AssignmentsRefresh from '../../../AssignmentsRefresh/AssignmentsRefresh';
import { notify } from 'utils';
import { useAppDispatch, useAppSelector } from 'store';
import { refreshAssignmentsData } from 'store/slices/resourceAssignments';
import { IDomain, IUser } from 'interfaces';
import { onUpdateAppPublishing } from 'store/slices/userSlice';
import { checkIsCompanyPage } from 'helpers';
import { onUpdateDomainAppPublishing } from 'store/slices/customerDomains';
import styles from './AppsHeader.module.scss';

interface Props {
  isAppPublishingEnabled?: boolean;
  openToggleRemoteAppsModal: () => void;
  domain: IDomain;
}

const AppsHeader = ({ isAppPublishingEnabled, openToggleRemoteAppsModal, domain }: Props) => {
  const isCompanyPage = checkIsCompanyPage();

  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.resourceAssignments.toggleAppPublishingRequest);
  const { isLoading: isRefreshLoading } = useAppSelector(
    (state) => state.resourceAssignments.refreshAssignmentsDataRequest,
  );

  const { appPublishingCurrent } = useAppSelector((state) => (isCompanyPage ? state.user : state.customerDomains));
  const { isLoading: isLoadingApps } = useAppSelector((state) => state.resourceAssignments.refreshAppDataRequest);

  const onRefreshPage = async () => {
    try {
      const data = await dispatch(
        refreshAssignmentsData({
          type: isCompanyPage ? 'company' : 'customer',
        }),
      ).unwrap();
      const isAppPublishingEnabled = isCompanyPage
        ? (data as IUser).isAppPublishingEnabled
        : (data as IDomain[]).find((i: IDomain) => i.id === domain.id)?.isAppPublishingEnabled;

      if (isAppPublishingEnabled === appPublishingCurrent) {
        if (isCompanyPage) {
          dispatch(onUpdateAppPublishing({ isAppPublishingEnabled }));
        } else {
          dispatch(onUpdateDomainAppPublishing({ domainId: domain.id, isAppPublishingEnabled }));
        }
      } else {
        notify.warning('Data is not updated yet...');
      }
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  return (
    <div className={styles.appsHeader}>
      <div className={styles.appsHeaderWrap}>
        <div className={styles.appsHeaderStatus}>
          <p>RemoteApp Status</p>
          <SwitchCheckbox
            id="toggle-app-publishing"
            checked={appPublishingCurrent}
            disabled={isAppPublishingEnabled !== appPublishingCurrent || isLoading}
            onChange={openToggleRemoteAppsModal}
            className={styles.appsHeaderCheckbox}
          />
          <span
            className={cn(styles.appsHeaderCheckboxText, {
              [styles.appsHeaderCheckboxTextActive]: appPublishingCurrent,
            })}>
            {appPublishingCurrent ? 'ON' : 'OFF'}
          </span>
        </div>
      </div>

      {isAppPublishingEnabled === appPublishingCurrent ? (
        isAppPublishingEnabled && (
          <AssignmentsRefresh
            domain={domain}
            isAppsPage
            title="Refresh available applications"
            isRefreshing={isLoadingApps}
          />
        )
      ) : (
        <Button
          className={cn(styles.appsHeaderRefresh, { [styles.appsHeaderRefreshLoading]: isRefreshLoading })}
          id="refresh-apps"
          type="button"
          variant="primary"
          size="40"
          icon={<RefreshIcon />}
          onClick={onRefreshPage}>
          Refresh data
        </Button>
      )}
    </div>
  );
};

export default AppsHeader;
