import cn from 'classnames';
import { Button, StatusMessage, SwitchCheckbox, Table } from 'components';
import { ArrowLeftIcon } from 'assets/img';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styles from './DomainsTable.module.scss';
import { IBrandingDomain, IQueryConfig } from 'interfaces';
import { useAppDispatch, useAppSelector } from 'store';
import { getCustomerDomainsBranding, setSelectedDomains } from 'store/slices/branding';

const TYPES = {
  0: 'Inherited from Primary Domain',
  1: 'TruGrid Default',
  2: 'Custom',
};

interface Props {
  btnLoadingCurrent: string[];
  handleChangeDomainBrandingStatus: (isHeader: boolean, domain: IBrandingDomain) => void;
}

const DomainsTable = ({ btnLoadingCurrent, handleChangeDomainBrandingStatus }: Props) => {
  const dispatch = useAppDispatch();
  const { braindingDomains: domains, selectedDomains } = useAppSelector((state) => state.branding);
  const queryViewBy = domains?.queryConfig?.viewBy || 'All';

  const { url } = useRouteMatch();
  const history = useHistory();

  const goToCustomerManagement = (domain: IBrandingDomain) => {
    history.push(`${url}/${domain.dnsName}`, { domain });
  };

  const domainType = (type: number) => {
    switch (type) {
      case 0:
        return <span className={cn(styles.tableType, styles.inherit)}>{TYPES[0]}</span>;
      case 1:
        return <span className={cn(styles.tableType, styles.default)}>{TYPES[1]}</span>;
      case 2:
        return <span className={cn(styles.tableType, styles.custom)}>{TYPES[2]}</span>;
      default:
        return <span className={cn(styles.tableType, styles.default)}>{TYPES[1]}</span>;
    }
  };

  const setSelectedItems = (items: IBrandingDomain[]) => dispatch(setSelectedDomains(items));

  const columns = [
    {
      name: 'Domain',
      sortable: 'dnsName',
      width: '45%',
      search: 'Domain',
      checkbox: queryViewBy !== 'All',
      data: ({ dnsName }: IBrandingDomain) => <span className={styles.tableName}>{dnsName || 'N/A'}</span>,
    },
    {
      name: 'Branding Status',
      width: '20%',
      sortable: 'status',
      data: (domain: IBrandingDomain) => (
        <div className={styles.tableStatus}>
          <SwitchCheckbox
            id={domain.domainId}
            name={domain.domainId}
            onChange={() => handleChangeDomainBrandingStatus(false, domain)}
            checked={domain.status}
            disabled={selectedDomains.length > 0 || !!btnLoadingCurrent.includes(domain.domainId)}
          />
          {domain.status ? (
            <span className={styles.tableStatusEnabled}>ON</span>
          ) : (
            <span className={styles.tableStatusDisabled}>OFF</span>
          )}
        </div>
      ),
    },
    {
      name: 'Branding Type',
      width: '22%',
      sortable: 'type',
      data: ({ type }: IBrandingDomain) => domainType(type),
    },
    {
      name: '',
      width: '13%',
      data: (domain: IBrandingDomain) => (
        <Button
          id="manage-assignments"
          type="button"
          variant="primary"
          size="32"
          icon={<ArrowLeftIcon />}
          iconPosition="right"
          onClick={() => goToCustomerManagement(domain)}
          className={styles.tableButton}>
          Manage
        </Button>
      ),
    },
  ];

  const dispatchAction = async (query: IQueryConfig) => {
    await dispatch(getCustomerDomainsBranding({ query, _background: true })).unwrap();
  };

  if (domains?.data && domains?.data?.length < 1 && !domains?.queryConfig?.searchQuery) {
    return (
      <div className={styles.statusMessage}>
        <StatusMessage header="No items available">Change view selection</StatusMessage>
      </div>
    );
  }

  return (
    <Table<IBrandingDomain>
      columns={columns}
      data={domains}
      id="branding-cuatomers-table"
      dataKey="domainId"
      selectedItems={selectedDomains}
      setSelectedItems={setSelectedItems}
      className={styles.table}
      dispatchAction={dispatchAction}
    />
  );
};

export default DomainsTable;
