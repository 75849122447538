import { DropdownArray } from 'components';
import { notify } from 'utils';
import { useAppDispatch, useAppSelector } from 'store';
import { getCustomerDomainsBranding } from 'store/slices/branding';

const ViewBy = () => {
  const dispatch = useAppDispatch();
  const { braindingDomains: domains } = useAppSelector((state) => state.branding);
  const queryViewBy = domains?.queryConfig?.viewBy;
  const totalCount = domains?.pageMeta?.TotalCount;

  const onChangeView = async (value: string) => {
    try {
      const valueFrmt = value === 'All' ? 'All' : value.split('Branding ')[1].replace(/\s/g, '');
      const queryConfig = {
        pageNumber: 1,
        viewBy: valueFrmt,
        orderBy: 'dnsName asc',
      };
      await dispatch(getCustomerDomainsBranding({ query: queryConfig, _background: true })).unwrap();
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  return (
    <DropdownArray
      id="ra-app-select"
      isSmall={false}
      isSearch={false}
      isFilter
      label="View by:"
      options={['All', 'Branding Disabled', 'Branding Enabled']}
      defaultValueHtml={
        queryViewBy === 'All' ? (
          <>
            All <b>({totalCount})</b>
          </>
        ) : (
          <>
            Branding {queryViewBy} <b>({totalCount})</b>
          </>
        )
      }
      onChange={(value) => onChangeView(value as string)}
      width="20.3125em"
    />
  );
};

export default ViewBy;
