/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useEffect, useState } from 'react';
import * as signalR from 'aspnet-signalr-reactjs';
import { CodeInput } from 'components';
import { notify } from 'utils';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import styles from './ScanQRCode.module.scss';
import { useAppDispatch, useAppSelector } from 'store';
import { setMfa } from 'store/slices/auth/mfa';
import { changeUserMfaQrCode } from 'store/slices/userSlice';

const ScanQRCode = () => {
  const { user } = useAppSelector((state) => state.user);
  const { isLoading } = useAppSelector((state) => state.mfa.setMfaRequest);
  const dispatch = useAppDispatch();
  const isSetupMfaWithoutPhone = user.isMfaActive && !user.isPhoneMfaActive && !user.mustSetupMfa;

  const [codeValue, setCodeValue] = useState('');
  const [isError, setIsError] = useState('');

  const history = useHistory();
  const { url } = useRouteMatch();

  const clearError = () => setIsError('');

  useEffect(() => {
    const connection = signalR.hubConnection();
    const contosoChatHubProxy = connection.createHubProxy('synchronizationHub');

    contosoChatHubProxy.on('onMfaSetup', (mfa: string) => {
      setCodeValue(mfa);
    });

    connection
      .start()
      .done(() => contosoChatHubProxy.invoke('registerClient', user.id))
      .fail(() => notify.error('SignalR connection error'));
  }, [user]);

  const handleSendVerificationCode = async () => {
    try {
      await dispatch(setMfa({ pin: codeValue, token: user.mfaToken })).unwrap();
      dispatch(changeUserMfaQrCode());
      if (user.isPhoneMfaActive) {
        history.replace('/workspace');
        notify.success('Verification Successful. MFA Setup Completed');
      } else {
        history.replace(`${url}/verify-phone`);
      }
    } catch (err: any) {
      setIsError(err.message);
    }
  };

  if (isSetupMfaWithoutPhone) {
    return <Redirect to={`${url}/verify-phone`} />;
  }

  return (
    <div className={styles.setupMfa}>
      <div className={styles.setupMfaInfo}>
        Download the free <strong>TruGrid Authenticator App</strong> or your preferred authenticator app, then scan this
        QR code to set up your account
      </div>
      <img
        className={styles.setupMfaQrCode}
        src={`data:image/jpeg;charset=utf-8;base64,${user.mfaQrUrl}`}
        alt="application authenticator code"
      />
      <div className={styles.setupMfaInputTitle}>Enter Verification Code</div>
      <CodeInput
        fields={6}
        isError={!!isError}
        errorMessage={isError}
        clearError={clearError}
        isDisable={isLoading}
        value={codeValue}
        setValue={setCodeValue}
        handleSend={handleSendVerificationCode}
      />
    </div>
  );
};

export default ScanQRCode;
