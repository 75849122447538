import { SwitchCheckbox, Table } from 'components';
import { truncateString } from 'utils';
import { getAzureAdLoginData, IAzureAdLoginData, IAzureLoginUser } from 'store/slices/securityMgmt/azureAdLogin';
import { useAppDispatch } from 'store';
import styles from './AzureAdLoginTable.module.scss';
import { IQueryConfig } from 'interfaces';

interface Props {
  domainId: string;
  azureLoginData: IAzureAdLoginData;
  selectedUsers: IAzureLoginUser[];
  setSelectedUsers: (val: IAzureLoginUser[]) => void;
  btnLoadingCurrent: string[];
  onChangeStaySignedInForUsers: (isHeaderBtn: boolean, user?: IAzureLoginUser) => Promise<void>;
}

const AzureAdLoginTable = ({
  domainId,
  azureLoginData,
  selectedUsers,
  setSelectedUsers,
  btnLoadingCurrent,
  onChangeStaySignedInForUsers,
}: Props) => {
  const dispatch = useAppDispatch();

  const columns = [
    {
      checkbox: true,
      name: 'User',
      data: ({ displayName, upn }: { displayName: string; upn: string }) => (
        <div>
          <div className={styles.tableName}>{truncateString(displayName, false) || 'N/A'}</div>
          <div className={styles.tableUpn}>{truncateString(upn, false)}</div>
        </div>
      ),
      sortable: 'displayName',
      search: 'Name',
      width: '40%',
    },
    {
      name: 'Stay Signed-In',
      data: (user: IAzureLoginUser) => (
        <div className={styles.tableStatus}>
          <SwitchCheckbox
            id={user.userId}
            name={user.userId}
            onChange={() => onChangeStaySignedInForUsers(false, user)}
            checked={user.isAzureAdStaySignedInEnabled}
            disabled={selectedUsers.length > 0 || !!btnLoadingCurrent.includes(user.userId)}
          />
          {user.isAzureAdStaySignedInEnabled ? (
            <span className={styles.tableStatusEnabled}>ON</span>
          ) : (
            <span className={styles.tableStatusDisabled}>OFF</span>
          )}
        </div>
      ),
      sortable: 'isAzureAdStaySignedInEnabled',
      width: '60%',
    },
  ];

  const dispatchAction = async (query: IQueryConfig) => {
    await dispatch(getAzureAdLoginData({ domainId: domainId, query: query, _background: true })).unwrap();
  };

  return (
    <Table<IAzureLoginUser>
      data={azureLoginData}
      selectedItems={selectedUsers}
      setSelectedItems={setSelectedUsers}
      columns={columns}
      id="azure-ad-login-table"
      dataKey="userId"
      className={styles.table}
      dispatchAction={dispatchAction}
    />
  );
};

export default AzureAdLoginTable;
