import { memo } from 'react';
import WorkspaceItems from './components/WorkspaceItems';
import CompanyItems from './components/CompanyItems';
import CustomerItems from './components/CustomerItems';

interface Props {
  itemId: string;
  role: string;
  domainStatus: number;
  isCompanyOpen: boolean;
  onCompanyToggle: () => void;
  onNotCompanyClick: () => void;
  blockLeftMenu?: boolean;
}

const NavbarItem = ({
  itemId,
  role,
  domainStatus,
  isCompanyOpen,
  onCompanyToggle,
  onNotCompanyClick,
  blockLeftMenu,
}: Props) => {
  if (itemId === 'workspace') {
    return <WorkspaceItems onNotCompanyClick={onNotCompanyClick} blockLeftMenu={blockLeftMenu} />;
  }

  if (itemId === 'company') {
    return (
      <CompanyItems
        role={role}
        isCompanyOpen={isCompanyOpen}
        domainStatus={domainStatus}
        onCompanyToggle={onCompanyToggle}
        blockLeftMenu={blockLeftMenu}
      />
    );
  }

  if (itemId === 'customers') {
    return (
      <CustomerItems
        role={role}
        domainStatus={domainStatus}
        onNotCompanyClick={onNotCompanyClick}
        blockLeftMenu={blockLeftMenu}
      />
    );
  }
};

NavbarItem.displayName = 'NavbarItem';

export default memo(NavbarItem);
