export const currentStepHandler = (pathname: string, path: string, url: string, isSetupMfaWithoutPhone?: boolean) => {
  let currentStep: string;

  if (pathname === path) {
    currentStep = '1';
  } else if (pathname === `${url}/verify-phone`) {
    currentStep = '2';
  } else {
    currentStep = '3';
  }

  if (isSetupMfaWithoutPhone) {
    if (pathname === `${url}/verify-phone`) {
      currentStep = '1';
    } else {
      currentStep = '2';
    }
  }
  return currentStep;
};
