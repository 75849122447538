import MfaInfo from '../../../MfaInfo/MfaInfo';
import { IMfaUser } from 'store/slices/securityMgmt/mfaMgmt';
import styles from './MfaRoleCell.module.scss';

interface Props {
  user: IMfaUser;
}

const MfaRoleCell = ({ user }: Props) => {
  const { isAdmin, promotedFromAdFederation, promotedFromRolesMngmt } = user;

  if (isAdmin) {
    return (
      <div className={styles.admin}>
        <span className={styles.adminText}>Admin</span>
        <MfaInfo
          isAllView
          name="Enabled"
          promotedFromAdFederation={promotedFromAdFederation}
          promotedFromRolesMngmt={promotedFromRolesMngmt}
        />
      </div>
    );
  }
  return <span className={styles.user}>User</span>;
};

export default MfaRoleCell;
