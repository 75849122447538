import WorkspaceTable from 'pages/Workspace/components/WorkspaceTable/WorkspaceTable';
import { Link, SearchInput, StatusMessage } from 'components';
import { useAppDispatch, useAppSelector } from 'store';
import { getCustomerDomainApps, IMyApps } from 'store/slices/workspace';
import { checkIsAdminRole } from '../utils/customerUtils';
import { replaceSpecChar } from 'helpers';
import { IDomain } from 'interfaces';
import styles from '../CustomersWorkspace.module.scss';

interface Props {
  domain: IDomain;
  custApps: IMyApps;
  userRole: string;
}

const WorkspaceApps = ({ domain, custApps, userRole }: Props) => {
  const dispatch = useAppDispatch();
  const isAdminRole = checkIsAdminRole(userRole);

  const { isError, error } = useAppSelector((state) => state.workspace.getCustomerDomainAppsRequest);

  const onSearch = async (value: string) => {
    const valueNew = replaceSpecChar(value);
    const queryConfig = {
      domainId: domain?.id,
      pageNumber: 1,
      searchQuery: valueNew,
    };
    await dispatch(getCustomerDomainApps({ query: queryConfig })).unwrap();
  };

  const renderApps = () => {
    if (!custApps.data.length && !custApps.queryConfig.searchQuery) {
      return (
        <StatusMessage header="No assigned apps">
          Please check Active Directory or{' '}
          <Link href="https://www.trugrid.com/docs/addapp" external bold>
            add apps
          </Link>
        </StatusMessage>
      );
    }

    return (
      <WorkspaceTable<IMyApps>
        isComputers={false}
        tableData={custApps}
        dispatchAction={getCustomerDomainApps}
        isCustomerDomain
      />
    );
  };

  if (isAdminRole && !custApps?.data?.length && !custApps?.queryConfig.searchQuery) {
    return (
      <StatusMessage header="No apps">
        Please go to{' '}
        <Link
          to={{ pathname: `/customer-management/${domain?.dnsName}/resource-assignments`, search: '?tab=app' }}
          bold>
          Resource Assignment
        </Link>{' '}
        in order to manage applications
      </StatusMessage>
    );
  }

  if (isError) {
    return <StatusMessage error>{error}</StatusMessage>;
  }

  return (
    <>
      <div className={styles.header}>
        <div className={styles.headerTotal}>
          Assigned Apps
          <span className={styles.headerTotalCounter}> ({custApps.pageMeta.TotalCount})</span>
        </div>
        <SearchInput id="ws-search" searchBy="App Title" strightLeft={false} onChange={(value) => onSearch(value)} />
      </div>
      {renderApps()}
    </>
  );
};

export default WorkspaceApps;
