import cn from 'classnames';
import { Button } from 'components';
import { useRef } from 'react';
import { useObserver } from 'hooks';
import { IAzureAdLoginData, IAzureLoginUser } from 'store/slices/securityMgmt/azureAdLogin';
import { getHeaderButtonLabel } from '../../utils/azureAdLoginUtils';
import styles from './HeaderAction.module.scss';

interface Props {
  selectedUsers: IAzureLoginUser[];
  setSelectedUsers: (val: IAzureLoginUser[]) => void;
  onChangeStaySignedInForUsers: (isHeaderBtn: boolean, user?: IAzureLoginUser) => Promise<void>;
  azureLoginData: IAzureAdLoginData | null;
  isHeaderBtnLoading: boolean;
}

const HeaderAction = ({
  selectedUsers,
  setSelectedUsers,
  onChangeStaySignedInForUsers,
  azureLoginData,
  isHeaderBtnLoading,
}: Props) => {
  const counterRef = useRef<HTMLDivElement | null>(null);
  const isSticky: boolean = useObserver(counterRef, !!selectedUsers.length, { threshold: 1 });

  const actionType = getHeaderButtonLabel(selectedUsers);

  return (
    <header
      className={cn(styles.headerActions, { [styles.active]: selectedUsers.length, [styles.sticked]: isSticky })}
      ref={counterRef}>
      <div className={styles.headerActionsCount}>
        {selectedUsers.length}/{azureLoginData?.pageMeta?.TotalCount || 0} Selected
      </div>
      <div className={styles.headerActionsWrap}>
        <Button variant="secondary" id="unselect-users" size="40" onClick={() => setSelectedUsers([])}>
          Unselect All
        </Button>
        <Button
          variant="primary"
          className={styles.headerActionsButton}
          isLoading={isHeaderBtnLoading}
          size="40"
          onClick={() => onChangeStaySignedInForUsers(true)}>
          {actionType}
        </Button>
      </div>
    </header>
  );
};

export default HeaderAction;
