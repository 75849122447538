import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import 'react-tabs/style/react-tabs.css';
import 'react-toastify/dist/ReactToastify.min.css';
import './styles/main.scss';
import App from './App';
import store from 'store/store';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router basename={`${window.location.pathname}#`}>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
