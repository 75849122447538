import { DropdownArray, SearchInput } from 'components';
import { useAppDispatch, useAppSelector } from 'store';
import { getAppAssignments } from 'store/slices/resourceAssignments';
import { replaceSpecChar } from 'helpers';
import styles from './TableActions.module.scss';
import { notify } from 'utils';

interface Props {
  isAppPublishingEnabled: boolean | undefined;
  domainId: string;
}

const TableActions = ({ isAppPublishingEnabled, domainId }: Props) => {
  const dispatch = useAppDispatch();
  const { appPublishingCurrent } = useAppSelector((state) => state.user);
  const { appAssignmentsData } = useAppSelector((state) => state.resourceAssignments);
  const queryViewBy = appAssignmentsData?.queryConfig?.viewBy;

  const onChangeView = async (value: string) => {
    try {
      const queryConfig = {
        pageNumber: 1,
        viewBy: value,
        orderBy: 'name asc',
      };
      await dispatch(getAppAssignments({ domainId, query: queryConfig, _background: true })).unwrap();
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  const onSearch = async (value: string) => {
    try {
      const valueNew = replaceSpecChar(value);
      const queryConfig = {
        pageNumber: 1,
        searchQuery: valueNew,
      };
      await dispatch(getAppAssignments({ domainId, query: queryConfig, _background: true })).unwrap();
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  return (
    <div className={styles.appsHeader}>
      <div className={styles.appsHeaderWrap}>
        View by
        <DropdownArray
          id="ra-app-select"
          isSmall={false}
          isSearch={false}
          isFilter
          strightRight
          isDisabled={isAppPublishingEnabled && !appPublishingCurrent}
          options={['Users', 'Apps']}
          defaultValue={queryViewBy}
          onChange={(value) => onChangeView(value as string)}
          className={styles.appsHeaderWrapDropdown}
          width="10.75em"
        />
        <SearchInput
          id="ra-app-search"
          strightLeft
          searchBy={queryViewBy === 'Users' ? 'Name' : 'Title'}
          onChange={(value) => onSearch(value)}
        />
      </div>
    </div>
  );
};

export default TableActions;
