import cn from 'classnames';
import Button from 'components/Button/Button';
import styles from './PageNumbers.module.scss';

interface Props {
  totalPages: number;
  currentPage: number;
  pageSize: number;
  onPageChangeHandler: (page: number, pageSize: number) => Promise<void>;
}

const PageNumbers = ({ totalPages, pageSize, currentPage, onPageChangeHandler }: Props) => {
  let numbers;

  if (totalPages < 8) {
    numbers = [...Array(totalPages).keys()].map((i) => i + 1);
  } else if (currentPage < 5) {
    numbers = [1, 2, 3, 4, 5, '...', totalPages];
  } else if (totalPages - currentPage < 4) {
    numbers = [1, '...', totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
  } else {
    numbers = [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages];
  }

  return (
    <div className={styles.numbers}>
      {numbers.map((page, index) => (
        <Button
          variant="link"
          key={`${page}-${index}`}
          className={cn(styles.numbersItem, {
            [styles.numbersItemActive]: page === currentPage,
            [styles.numbersItemDots]: page === '...',
          })}
          onClick={() => onPageChangeHandler(+page, pageSize)}>
          {page}
        </Button>
      ))}
    </div>
  );
};

export default PageNumbers;
