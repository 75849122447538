import { Modal } from 'components';
import { useAppDispatch, useAppSelector } from 'store';
import { changeCountryAllowedList } from 'store/slices/securityMgmt/geoBlocking';
import { notify } from 'utils';

interface Props {
  toggleShowDeleteCountryModal: () => void;
  showDeleteCountryModal: boolean;
  checkedCountries: string[];
  deleteCountryName: string;
  setIsGeoBlockingEnabled: (value: boolean) => void;
  setCheckedCountries: (value: string[]) => void;
  domainId: string;
}

const DeleteCountryModal = ({
  toggleShowDeleteCountryModal,
  showDeleteCountryModal,
  checkedCountries,
  deleteCountryName,
  setIsGeoBlockingEnabled,
  setCheckedCountries,
  domainId,
}: Props) => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((store) => store.geoBlocking.changeCountryAllowedListRequest);

  const onDeleteCountry = async () => {
    try {
      const newCheckedCountries = [...checkedCountries];
      const deleteCountryIndex = newCheckedCountries.findIndex((country: string) => country === deleteCountryName);
      newCheckedCountries.splice(deleteCountryIndex, 1);

      const { data } = await dispatch(
        changeCountryAllowedList({ domainId, checkedCountries: newCheckedCountries }),
      ).unwrap();
      const successMsg =
        data.countries.length < 1
          ? 'Geo-Blocking is disabled for domain'
          : `${deleteCountryName} is deleted from the Allowed List`;
      notify.success(successMsg);
      toggleShowDeleteCountryModal();
      if (data.countries.length < 1) {
        setIsGeoBlockingEnabled(false);
        setCheckedCountries([]);
      }
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  return (
    <Modal
      isOpen={showDeleteCountryModal}
      onRequestClose={toggleShowDeleteCountryModal}
      contentLabel="Delete country?"
      actionLoading={isLoading}
      actionTitle="Delete"
      actionFunc={onDeleteCountry}
      variantBtn="deleteOutline">
      {checkedCountries.length > 1 ? (
        <p>
          Do you really want to delete <strong>{deleteCountryName}</strong> from the allowed list?
        </p>
      ) : (
        <p>
          By deleting <strong>{deleteCountryName}</strong>, Geo-Blocking feature will be disabled. <br />
          Are you sure?
        </p>
      )}
    </Modal>
  );
};

export default DeleteCountryModal;
