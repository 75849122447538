import cn from 'classnames';
import { Button, DropdownArray } from 'components';
import ManageBtnMfa from '../ManageBtnMfa/ManageBtnMfa';
import MfaInfo from '../MfaInfo/MfaInfo';
import { useRef } from 'react';
import { useObserver } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store';
import { getViewByOptions } from '../../utils/mfaMgmtUtils';
import { IMfaUser, getMfaData } from 'store/slices/securityMgmt/mfaMgmt';
import { TypeAction } from '../../MfaMgmt';
import { notify } from 'utils';
import styles from './MfaHeader.module.scss';

interface Props {
  domainId: string;
  selectedUsers: IMfaUser[];
  setSelectedUsers: (value: IMfaUser[]) => void;
  onModalOpen: (type: TypeAction, user: IMfaUser, isHeaderBtn: boolean) => void;
}

const MfaHeader = ({ domainId, selectedUsers, setSelectedUsers, onModalOpen }: Props) => {
  const dispatch = useAppDispatch();
  const { mfaData } = useAppSelector((store) => store.mfaMgmt);
  const { isSetupView, isAllView } = getViewByOptions(mfaData);

  const counterRef = useRef<HTMLDivElement | null>(null);
  const isSticky: boolean = useObserver(counterRef, !!selectedUsers.length, { threshold: 1 });

  const FILTER_OPTIONS = ['All', 'MFA Enabled', 'MFA Setup Pending', 'MFA Disabled', 'MFA in Reset Mode'];

  const viewByFrmttd = mfaData.queryConfig?.viewBy?.split(/(?=[A-Z])/).join(' ') || '';
  const filterValue = viewByFrmttd === 'All' ? viewByFrmttd : `MFA ${viewByFrmttd}`;
  const filterValueHtml = (
    <>
      {viewByFrmttd === 'All' ? viewByFrmttd : `MFA ${viewByFrmttd}`} <b>({mfaData?.pageMeta?.TotalCount})</b>
    </>
  );

  const countTotal = () => {
    if (isSetupView) {
      return (mfaData?.pageMeta?.TotalCount ?? 0) - (mfaData?.data?.filter((i) => i.isAdmin)?.length ?? 0);
    }
    return mfaData?.pageMeta?.TotalCount;
  };

  const onChangeView = async (value: string) => {
    try {
      const valueFrmt = value === 'All' ? 'All' : value.split('MFA ')[1].replace(/\s/g, '');
      const queryConfig = {
        pageNumber: 1,
        viewBy: valueFrmt,
        orderBy: 'displayName asc',
      };
      await dispatch(getMfaData({ domainId, query: queryConfig, _background: true })).unwrap();
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  return (
    <header
      className={cn([styles.mfaHeader], { [styles.active]: selectedUsers.length }, { [styles.sticked]: isSticky })}
      ref={counterRef}>
      {selectedUsers.length ? (
        <div className={styles.mfaHeaderCounterActions}>
          <div className={styles.selectedCount}>
            {selectedUsers.length}/{countTotal()} Selected
          </div>
          <Button
            size="40"
            variant="secondary"
            id="unselect-users"
            onClick={() => setSelectedUsers([])}
            className={styles.deselectButton}>
            Unselect All
          </Button>
          <ManageBtnMfa
            isDisabled={false}
            selectedUsers={selectedUsers}
            user={selectedUsers[0]}
            onModalOpen={onModalOpen}
            isHeaderBtn
          />
        </div>
      ) : (
        <div className={styles.mfaHeaderFilter}>
          <DropdownArray
            id="sm-mfa-select"
            isSmall={false}
            isSearch={false}
            isFilter
            label="View by:"
            options={FILTER_OPTIONS}
            defaultValue={filterValue}
            defaultValueHtml={filterValueHtml}
            onChange={(value) => onChangeView(value as string)}
            width="17.7em"
          />
          {!isAllView && <MfaInfo inHead name={viewByFrmttd} />}
        </div>
      )}
    </header>
  );
};

export default MfaHeader;
