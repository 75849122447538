import { Button } from 'components';
import SetBackground from './SetBackground/SetBackground';
import UploadLogo from './UploadLogo/UploadLogo';
import styles from './BrandingEdit.module.scss';
import SetButton from './SetButton/SetButton';
import BrandingLink from '../BrandingLink/BrandingLink';
import { useAppSelector } from 'store';

interface Props {
  openModalEdit: () => void;
  hexError: boolean;
  setHexError: (isColor: boolean) => void;
}

const BrandingEdit = ({ openModalEdit, hexError, setHexError }: Props) => {
  const { branding } = useAppSelector((state) => state.branding);

  return (
    <div className={styles.brandingEdit}>
      <div className={styles.brandingEditWrap}>
        <p className={styles.brandingEditTitle}>Style</p>
        <UploadLogo />
        <SetBackground hexError={hexError} setHexError={setHexError} />
        <SetButton hexError={hexError} setHexError={setHexError} />
      </div>
      <div className={styles.brandingEditInfo}>
        <div className={styles.brandingEditInfoTitle}>Branding Information</div>
        <Button
          className={styles.brandingEditInfoButton}
          id="edit-branding-info"
          variant="link"
          onClick={openModalEdit}>
          {branding?.modified?.isSetContactInfo ? 'Edit' : 'Add'}
        </Button>
      </div>
      <BrandingLink />
    </div>
  );
};

export default BrandingEdit;
