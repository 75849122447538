/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useEffect, useState } from 'react';
import { IUser, IBrandingDomainData } from 'interfaces';

const liveChatElementSelector = '.crisp-client';

interface Props {
  user: IUser;
  branding: IBrandingDomainData | null;
}

const LiveChat = ({ user, branding }: Props) => {
  const [isUserDataUpdated, setIsUserDataUpdated] = useState(false);

  const checkExternalChatLoaded = () => {
    const chatEl = document.querySelector(liveChatElementSelector);
    const delay = 700;

    if (!chatEl) {
      setTimeout(checkExternalChatLoaded, delay);
      return;
    }

    validateLiveChat();
  };

  const validateLiveChat = () => {
    const chatEl = document.querySelector(liveChatElementSelector);
    const hiddenClass = 'hidden';
    const isChatHidden = !chatEl || chatEl.classList.contains(hiddenClass);

    const isRoleAllowed = ['mspadmin', 'sptechadmin', 'msptechnician', 'personaluser', 'freepersonaluser'].some((i) =>
      user.roles.includes(i),
    );

    const isLiveChatAllowed = branding && isRoleAllowed;

    if (isLiveChatAllowed && chatEl && !isUserDataUpdated) {
      window.$crisp.push(['set', 'user:email', [`${user.email}`, `${user.userIdHash}`]]);
      window.$crisp.push(['set', 'user:nickname', [user.displayName]]);
      setIsUserDataUpdated(true);
    }

    if (isLiveChatAllowed && isChatHidden) {
      chatEl?.classList.remove(hiddenClass);
    }

    if (!isLiveChatAllowed && !isChatHidden) {
      chatEl.classList.add(hiddenClass);
    }

    if (!branding) {
      setTimeout(validateLiveChat, 500);
    }
  };

  useEffect(() => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = '710355c5-b792-4ce9-ba54-31d2253039d8';
    (() => {
      const d = document;
      const s = d.createElement('script');
      s.src = 'https://client.crisp.chat/l.js';
      s.async = true;
      d.getElementsByTagName('head')[0].appendChild(s);
    })();

    checkExternalChatLoaded();
  }, []);

  return <div />;
};

export default LiveChat;
