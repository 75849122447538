import cn from 'classnames';
import styles from './StatusMessage.module.scss';

interface Props {
  header?: string;
  absolute?: boolean;
  disabled?: boolean;
  error?: boolean;
  children: any;
}

const StatusMessage = ({ header, absolute = true, disabled, error, children }: Props) => (
  <div
    className={cn(styles.statusContainer, {
      [styles.statusContainerAbsolute]: absolute,
      [styles.statusContainerDisabled]: disabled,
      [styles.statusContainerError]: error,
    })}>
    {header && <p className={styles.statusHeader}>{header}</p>}
    {children}
  </div>
);

export default StatusMessage;
