import { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { DomainStatusMsg, Loader } from 'components';
import { getDomainStatus } from 'helpers';
import Authentication from './components/Authentication/Authentication';
import Licensing from './components/Licensing/Licensing';
import Assignments from './components/Assignments/Assignments';
import styles from './Reports.module.scss';
import { IDomain } from 'interfaces';
import { useAppDispatch } from 'store';
import {
  getAssigmentsReports,
  getLicensesReports,
  getLoginReports,
  getReportStatusData,
  getUsersReports,
} from 'store/slices/reports';

export type ReportLoading = {
  userAssignments: boolean;
  machineAssignmnets: boolean;
  appAssignments: boolean;
};

interface Props {
  domain: IDomain;
  userRole: string;
  isCompanyPage: boolean;
  isDomainNotActivated: boolean;
}

const Reports = ({ domain, userRole, isCompanyPage, isDomainNotActivated }: Props) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [isFastGenerate, setIsFastGenerate] = useState(true);
  const [selectedLicenseType, setSelectedLicenseType] = useState('SecureRDP');
  const [reportLoading, setReportLoading] = useState<ReportLoading>({
    userAssignments: false,
    machineAssignmnets: false,
    appAssignments: false,
  });

  const { isDomainActive } = getDomainStatus(domain);

  const fetchData = async () => {
    await Promise.allSettled([
      dispatch(getAssigmentsReports(domain.id)),
      dispatch(getUsersReports(domain.id)),
      dispatch(getLicensesReports({ domainId: domain.id, licenseType: selectedLicenseType })),
      dispatch(
        getLoginReports({
          domainId: domain.id,
          start: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
          end: new Date(new Date().getTime()),
        }),
      ),
      dispatch(getReportStatusData(domain.id)),
    ]);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isDomainActive) {
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) return <Loader id="loading-report-page" />;
  if (isDomainNotActivated) return <DomainStatusMsg domainData={domain} />;

  // const activatedDomains = licenses.filter((el) => getDomainStatus(el).isDomainActive);

  return (
    <div className={styles.reports}>
      <h3 className={styles.reportsTitle}>Reports</h3>
      <Tabs>
        <TabList>
          <Tab>
            <span id="authentication-tab">Authentication</span>
          </Tab>
          <Tab>
            <span id="licensing-tab">Licensing</span>
          </Tab>
          <Tab>
            <span id="assignments-tab">Assignments</span>
          </Tab>
        </TabList>
        <TabPanel>
          <Authentication domainId={domain.id} />
        </TabPanel>
        <TabPanel>
          <Licensing
            primaryDomain={domain.dnsName}
            userRole={userRole}
            setIsFastGenerate={setIsFastGenerate}
            isFastGenerate={isFastGenerate}
            isCompanyPage={isCompanyPage}
            domainId={domain.id}
            selectedLicenseType={selectedLicenseType}
            setSelectedLicenseType={setSelectedLicenseType}
          />
        </TabPanel>
        <TabPanel>
          <Assignments
            domain={domain}
            userRole={userRole}
            isCompanyPage={isCompanyPage}
            reportLoading={reportLoading}
            setReportLoading={setReportLoading}
          />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Reports;
