import cn from 'classnames';
import { Button, SwitchCheckbox } from 'components';
import { TypeEnableGeoBlocking } from '../../GeoBlocking';
import styles from './GeoBlokingHeader.module.scss';

interface Props {
  isGeoBlockingEnabledCurrent: boolean;
  onSwitchGeoBlocking: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setIsManageAllowedList: (value: boolean) => void;
  setTypeEnableGeoBlocking: (value: TypeEnableGeoBlocking) => void;
  toggleManageCountriesModal: () => void;
}

const GeoBlokingHeader = ({
  isGeoBlockingEnabledCurrent,
  onSwitchGeoBlocking,
  setIsManageAllowedList,
  setTypeEnableGeoBlocking,
  toggleManageCountriesModal,
}: Props) => {
  const handleManageCountryList = () => {
    setIsManageAllowedList(true);
    setTypeEnableGeoBlocking('priorList');
    toggleManageCountriesModal();
  };

  return (
    <header className={styles.header}>
      <div className={styles.headerActions}>
        <div className={styles.headerSwitchCheckbox}>
          <p className={styles.headerSwitchCheckboxTitle}>Geo-Blocking Status</p>
          <SwitchCheckbox
            id="toggle-enable-geoblocking"
            checked={isGeoBlockingEnabledCurrent}
            onChange={onSwitchGeoBlocking}
            className={styles.headerSwitchCheckboxSlide}
          />
          <span
            className={cn(styles.headerSwitchCheckboxText, {
              [styles.active]: isGeoBlockingEnabledCurrent,
            })}>
            {isGeoBlockingEnabledCurrent ? 'ON' : 'OFF'}
          </span>
        </div>
        {isGeoBlockingEnabledCurrent && (
          <Button
            size="32"
            id="open-manage-country-list"
            type="button"
            variant="secondary"
            onClick={handleManageCountryList}>
            Manage country Allowed List
          </Button>
        )}
      </div>
    </header>
  );
};

export default GeoBlokingHeader;
