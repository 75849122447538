import { useLayoutEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { useOutsideClick } from 'hooks';
import Button from 'components/Button/Button';
import { AngleDownIcon, AngleUpIcon } from 'assets/img';
import Contact from './Contact/Contact';
import Support from './Support/Support';
import Connect from './Connect/Connect';
import { IBrandingContactInfo } from 'interfaces';
import styles from './BrandedLinks.module.scss';

interface Props {
  contactInformation: IBrandingContactInfo;
  isHeader?: boolean;
  className?: any;
}

const BrandedLinks = ({ contactInformation, isHeader, className }: Props) => {
  const [contactsOpen, setContactsOpen] = useState(false);
  const [supportOpen, setSupportOpen] = useState(false);
  const [connectOpen, setConnectOpen] = useState(false);

  const [contactsBottom, setContactsBottom] = useState(false);
  const [supportBottom, setSupportBottom] = useState(false);
  const [connectBottom, setConnectBottom] = useState(false);

  const {
    contactInfo,
    address,
    city,
    state,
    zipCode,
    country,
    phoneNumber,
    emailAddress,
    website,
    facebookLink,
    twitterLink,
    youtubeLink,
    linkedinLink,
  } = contactInformation;

  const isShowContactDropdown = [contactInfo, address, city, state, zipCode, country].some((i) => i);
  const isShowSupportDropDown = [phoneNumber, emailAddress, website].some((i) => i);
  const isShowConnectDropDown = [facebookLink, twitterLink, youtubeLink, linkedinLink].some((i) => i);

  const contactsRef = useRef<HTMLDivElement | null>(null);
  const supportRef = useRef<HTMLDivElement | null>(null);
  const connectRef = useRef<HTMLDivElement | null>(null);

  const contactsNodeRef = useRef<HTMLDivElement | null>(null);
  const supportNodeRef = useRef<HTMLDivElement | null>(null);
  const connectNodeRef = useRef<HTMLDivElement | null>(null);

  const calculateDropdownPosition = (
    button: HTMLElement | null,
    node: HTMLElement | null,
    action: (isOpen: boolean) => void,
  ) => {
    if (button && node) {
      const windowHeight = window.innerHeight;
      const menuHeight = node.getBoundingClientRect().height;
      const instOffsetWithMenu = button.getBoundingClientRect().bottom + menuHeight;
      return action(instOffsetWithMenu > windowHeight);
    }
    return true;
  };

  const toggleContactsOpen = () => setContactsOpen(!contactsOpen);
  const toggleSupportOpen = () => setSupportOpen(!supportOpen);
  const toggleConnectOpen = () => setConnectOpen(!connectOpen);

  useOutsideClick(contactsRef, () => contactsOpen && toggleContactsOpen());
  useOutsideClick(supportRef, () => supportOpen && toggleSupportOpen());
  useOutsideClick(connectRef, () => connectOpen && toggleConnectOpen());

  useLayoutEffect(() => {
    calculateDropdownPosition(contactsRef?.current, contactsNodeRef?.current, setContactsBottom);
    calculateDropdownPosition(supportRef?.current, supportNodeRef?.current, setSupportBottom);
    calculateDropdownPosition(connectRef?.current, connectNodeRef?.current, setConnectBottom);
    const handleResize = () => {
      calculateDropdownPosition(contactsRef.current, contactsNodeRef.current, setContactsBottom);
      calculateDropdownPosition(supportRef.current, supportNodeRef.current, setSupportBottom);
      calculateDropdownPosition(connectRef.current, connectNodeRef.current, setConnectBottom);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={cn(styles.links, { [className]: className })}>
      {isShowContactDropdown && (
        <div className={styles.linksWrap} ref={contactsRef}>
          <div
            id="contacts-dropdown"
            className={cn(styles.linksDropdown, {
              [styles.header]: isHeader,
              [styles.opened]: contactsOpen,
              [styles.bottom]: contactsBottom,
            })}
            ref={contactsNodeRef}>
            <Contact contactInformation={contactInformation} />
          </div>
          <Button
            id="toggle-contact-dropdown"
            variant="link"
            onClick={toggleContactsOpen}
            className={cn(styles.linksButton, { [styles.active]: contactsOpen })}>
            Contact{contactsOpen ? <AngleUpIcon /> : <AngleDownIcon />}
          </Button>
        </div>
      )}
      {isShowSupportDropDown && (
        <div className={styles.linksWrap} ref={supportRef}>
          <div
            className={cn(styles.linksDropdown, {
              [styles.header]: isHeader,
              [styles.opened]: supportOpen,
              [styles.bottom]: supportBottom,
            })}
            ref={supportNodeRef}>
            <Support contactInformation={contactInformation} />
          </div>
          <Button
            id="toggle-contact-support"
            variant="link"
            onClick={toggleSupportOpen}
            className={cn(styles.linksButton, { [styles.active]: supportOpen })}>
            Support{supportOpen ? <AngleUpIcon /> : <AngleDownIcon />}
          </Button>
        </div>
      )}
      {isShowConnectDropDown && (
        <div className={styles.linksWrap} ref={connectRef}>
          <div
            className={cn(styles.linksDropdown, {
              [styles.header]: isHeader,
              [styles.opened]: connectOpen,
              [styles.bottom]: connectBottom,
            })}
            ref={connectNodeRef}>
            <Connect contactInformation={contactInformation} />
          </div>
          <Button
            variant="link"
            id="toggle-contact-connect"
            onClick={toggleConnectOpen}
            className={cn(styles.linksButton, { [styles.active]: connectOpen })}>
            Connect with us{connectOpen ? <AngleUpIcon /> : <AngleDownIcon />}
          </Button>
        </div>
      )}
    </div>
  );
};

export default BrandedLinks;
