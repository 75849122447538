import { memo, useState } from 'react';
import cn from 'classnames';
import { DropdownObject, ClearInput, Button } from 'components';
import { TabOption } from 'consts';
import styles from './SettingsCustomRes.module.scss';

interface Props {
  isCustomResOpen: boolean;
  setIsCustomResOpen: (value: boolean) => void;
  options: string | { [key: number]: string | string[] } | false;
  defaultPos: number;
  item: TabOption<any> & { dependsOnZTP?: boolean };
  height: number;
  width: number;
  disabled: boolean;
  onChange: (value: string | number) => void;
  onSave: (customRes: { width: number; height: number }) => void;
}

const SettingsCustomRes = ({
  isCustomResOpen,
  setIsCustomResOpen,
  options,
  defaultPos,
  item,
  height,
  width,
  disabled,
  onChange,
  onSave,
}: Props) => {
  const [customRes, setCustomRes] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
  const [customResError, setCustomResError] = useState({ width: false, height: false });

  const inputValidation = {
    width: (value: number) => {
      switch (true) {
        case value < 640:
          return 'Width cannot be less than 640px';
        case value > 8192:
          return 'Width cannot be more than 8192px';
        default:
          return '';
      }
    },

    height: (value: number) => {
      switch (true) {
        case value < 480:
          return 'Height cannot be less than 480px';
        case value > 8192:
          return 'Height cannot be more than 8192px';
        default:
          return '';
      }
    },
  };

  const onChangeHandler = (key: keyof typeof inputValidation, value: number) => {
    const isError = !!inputValidation[key](value);

    setCustomResError({ ...customResError, [key]: isError });
    setCustomRes({ ...customRes, [key]: value });
  };

  const onCustomResOpen = () => {
    const isCustomSet = !!width || !!height;

    if (isCustomSet) setCustomRes({ width, height });

    setIsCustomResOpen(true);
  };

  const onCustomResClose = () => {
    setCustomRes({ width: 0, height: 0 });
    setIsCustomResOpen(false);
  };

  const onCustomResSave = () => {
    onSave(customRes);
    setIsCustomResOpen(false);
  };

  const isReadyToSave = !Object.values(customResError).includes(true) && !!+customRes.width && !!+customRes.height;

  if (isCustomResOpen) {
    return (
      <div className={cn(styles.custResolution, styles.customDimensions)}>
        <div className={styles.custResolutionInfo}>
          <span className={styles.custResolutionInfoTitle}>{item.title}</span>
          <span className={styles.custResolutionInfoDesc}>You can set the max limit to 8192 x 8192</span>
        </div>
        <div className={styles.custResolutionRowCustom}>
          <div>
            <ClearInput
              id="setting-res-width"
              type="number"
              value={+customRes.width}
              max={4}
              placeholder="Add Width"
              onChange={(value) => onChangeHandler('width', +value)}
              onClear={() => setCustomRes({ ...customRes, width: 0 })}
              validationRule={inputValidation.width}
              className={styles.custResolutionClearInput}
            />
            <ClearInput
              id="setting-res-height"
              type="number"
              value={+customRes.height}
              max={4}
              placeholder="Add Height"
              onChange={(value) => onChangeHandler('height', +value)}
              onClear={() => setCustomRes({ ...customRes, height: 0 })}
              validationRule={inputValidation.height}
              className={styles.custResolutionClearInput}
            />
          </div>
          <div>
            <Button
              id="cancel-custom-resolution"
              type="button"
              size="32"
              variant="secondary"
              onClick={onCustomResClose}
              className={styles.custResolutionCancel}>
              Cancel
            </Button>
            <Button
              id="save-custom-resolution"
              type="button"
              size="32"
              variant="primary"
              outline
              isDisabled={!isReadyToSave}
              onClick={onCustomResSave}>
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.custResolution}>
      <div className={styles.custResolutionInfo}>
        <span className={styles.custResolutionInfoTitle}>{item.title}</span>
        <span className={styles.custResolutionInfoDesc}>{item.desc}</span>
      </div>
      <div className={styles.custResolutionValue}>
        <DropdownObject
          id="cust-resolution-dropdown"
          options={options as any}
          defaultPos={defaultPos}
          isMulti
          disabled={disabled}
          onChange={onChange}
          customField="customResLink"
          width="16em"
          onCustomEvent={onCustomResOpen}
          optionsClassName={styles.custResolutionDropdown}
        />
      </div>
    </div>
  );
};

SettingsCustomRes.displayName = 'SettingsCustomRes';

export default memo(SettingsCustomRes);
