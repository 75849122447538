import cn from 'classnames';
import { TooltipIcon } from 'assets/img';
import Checkbox from 'components/Checkbox/Checkbox';
import Tooltip from 'components/Tooltip/Tooltip';
import { isOneChecked } from 'components/UpdateSentryModal/utils/updateUtils';
import { formattedDate } from 'helpers';
import styles from '../../UpdateSentryModal.module.scss';
import { ISentry } from 'interfaces';

interface Props {
  item: ISentry;
  handleToggleOne: (checked: boolean, item: ISentry) => void;
  checkedSentries: ISentry[];
  customerDomain?: boolean;
}

const PrimaryItem = ({ item, handleToggleOne, checkedSentries, customerDomain }: Props) => (
  <li
    className={cn(styles.modalListItem, {
      [styles.checked]: isOneChecked(item, checkedSentries),
      [styles.customer]: customerDomain,
    })}>
    <div className={styles.modalListItemWrapper}>
      <Checkbox
        id={item.apiKey}
        name={item.apiKey}
        checked={isOneChecked(item, checkedSentries)}
        onChange={(e) => handleToggleOne(e.target.checked, item)}
        isDisabled={!item.isOnline}
      />
      <div className={styles.modalListItemWrap}>
        <span className={cn(styles.modalListItemName, { [styles.offline]: !item.isOnline })}>
          {item.hostName}
          <Tooltip
            id="info-tooltip"
            place="top"
            className={styles.modalListItemTooltip}
            wrapClassName={styles.modalListItemTooltipWrap}
            contentClassName={styles.modalListItemTooltipVersion}
            content={
              <div>
                <div className={styles.modalListItemVersion}>
                  Sentry Version: <b>{item.version}</b>
                </div>
                <div className={styles.modalListItemResponse}>
                  Last Response: <b>{formattedDate(item.lastResponse)}</b>
                </div>
              </div>
            }>
            <TooltipIcon />
          </Tooltip>
        </span>
        <span className={cn(styles.modalListItemStatus, { [styles.offline]: !item.isOnline })}>
          <span className={cn(styles.modalListItemDot, { [styles.offline]: !item.isOnline })} />
          {item.isOnline ? 'Online' : 'Offline'}
        </span>
      </div>

      <div className={cn(styles.modalListItemUpdate, { [styles.offline]: !item.isOnline })}>
        <span>Update required</span>
        {!item.isOnline && (
          <Tooltip
            id="info-tooltip"
            place="top"
            className={styles.modalListItemTooltip}
            wrapClassName={styles.modalListItemTooltipWrap}
            contentClassName={styles.modalListItemTooltipContent}
            content="Sentry must be online to be updated">
            <TooltipIcon />
          </Tooltip>
        )}
        {item.attemptedToUpdateAt && item.isOnline && (
          <Tooltip
            id="info-tooltip"
            place="top"
            className={styles.modalListItemTooltip}
            wrapClassName={cn(styles.modalListItemTooltipWrap, styles.failed)}
            contentClassName={styles.modalListItemTooltipContent}
            content={
              <>
                Last update:&nbsp;<div>{formattedDate(item.attemptedToUpdateAt)}</div>&nbsp;<div>Failed</div>
              </>
            }>
            <TooltipIcon />
          </Tooltip>
        )}
      </div>
    </div>
  </li>
);

export default PrimaryItem;
