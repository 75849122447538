import { useCallback, useState } from 'react';
import { returnFileFromResponse, copyToClipboard, notify } from 'utils';
import { useAppDispatch } from 'store';
import { downloadEnrollFile, getDownloadData } from 'store/slices/bitLockerMgmt';

interface EnrollmentHook {
  isEnrollmentLoading: boolean;
  getEnrollmentFile: (value: string) => void;
}

export const useDevicesEnrollment = (domainId: string): EnrollmentHook => {
  const dispatch = useAppDispatch();
  const [isEnrollmentLoading, setIsEnrollmentLoading] = useState<boolean>(false);
  const { host } = window.location;

  const copyEnrollLink = useCallback(async () => {
    try {
      setIsEnrollmentLoading(true);
      const data = await dispatch(getDownloadData({ domainId })).unwrap();
      setIsEnrollmentLoading(false);
      copyToClipboard(data.agents[0].downloadLink, 'The link copied to clipboard');
    } catch (err: any) {
      notify.error(err.message);
      setIsEnrollmentLoading(false);
    }
  }, [domainId, host]);

  const handleDownloadEnrollFile = useCallback(async () => {
    try {
      setIsEnrollmentLoading(true);
      const data = await dispatch(getDownloadData({ domainId })).unwrap();
      const response = await dispatch(downloadEnrollFile(data.agents[0].downloadLink)).unwrap();
      setIsEnrollmentLoading(false);
      returnFileFromResponse(response);
    } catch (err: any) {
      notify.error(err.message);
      setIsEnrollmentLoading(false);
    }
  }, [domainId, host]);

  const getEnrollmentFile = useCallback(
    (value: string) => {
      if (value === 'Download') {
        handleDownloadEnrollFile();
      } else {
        copyEnrollLink();
      }
    },
    [copyEnrollLink, handleDownloadEnrollFile],
  );

  return { isEnrollmentLoading, getEnrollmentFile };
};
