import { useState } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import ConfirmPhoneNumber from './pages/ConfirmPhoneNumber/ConfirmPhoneNumber';
import EnterValidateCode from './pages/EnterValidateCode/EnterValidateCode';
import { useAppSelector } from 'store';

interface Props {
  setError: React.Dispatch<React.SetStateAction<string>>;
}

const ResetViaSMS = ({ setError }: Props) => {
  const { signInData } = useAppSelector((state) => state.auth);
  const { userId, phoneMask } = signInData;
  const { path, url } = useRouteMatch();

  const [phoneDigits, setPhoneDigits] = useState('');

  return (
    <Switch>
      <Route path={path} exact>
        <ConfirmPhoneNumber
          userId={userId}
          phoneMask={phoneMask}
          setError={setError}
          phoneDigits={phoneDigits}
          setPhoneDigits={setPhoneDigits}
        />
      </Route>
      <Route path={`${url}/verification-code`}>
        <EnterValidateCode userId={userId} phoneDigits={phoneDigits} phoneMask={phoneMask} setError={setError} />
      </Route>
      <Route path="*" render={() => <Redirect to={path} />} />
    </Switch>
  );
};

export default ResetViaSMS;
