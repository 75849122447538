import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { AsyncThunkConfig, GetThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { AxiosResponse } from 'axios';
import { http, createHttpRequestInitResult, createExtraReducersForResponses } from 'helpers/http';
import { IHttpRequestResult, IPageMeta, IQueryConfig } from 'interfaces';
import { RootState } from 'store/store';
import { queryFormatter } from 'utils';

export interface IActiveDirectoryUser {
  userId: string;
  name: string;
  assignmentsCount: number;
  isMfaEnabled: boolean;
}

export interface IActiveDirectoryAssignment {
  isAssigned: boolean;
  resourceId: string;
  resourceName: string;
}

type noDataValue = 'no data' | 'no users';

export interface IActiveDirectoryData {
  data?: IActiveDirectoryUser[];
  pageMeta?: IPageMeta;
  queryConfig?: IQueryConfig;
  isNoData?: boolean;
  noDataValue?: noDataValue;
  isNoCustomerDomains?: boolean;
}

export const getActiveDirectoryData = createAsyncThunk(
  'activeDirectory/getActiveDirectoryData',
  async ({ query, _background }: { query?: IQueryConfig; _background?: boolean }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const queryConfig = state.activeDirectory.activeDirectoryData?.queryConfig || {};
    const queryConfigNew = { ...queryConfig, ...query };
    const response: AxiosResponse = await http.get(
      queryFormatter('/api/MSPCustomerAssignments/GetCustomerDomainsAssignments', queryConfigNew),
    );
    const pageMeta = response?.headers['x-pagination'] ? JSON.parse(response.headers['x-pagination']) : null;
    if (response.status === 204) {
      const data: IActiveDirectoryData = {
        isNoData: true,
        noDataValue: 'no data',
      };
      return data;
    } else if (response.status === 206 && !response.data.length) {
      const data: IActiveDirectoryData = {
        isNoData: true,
        noDataValue: 'no users',
      };
      return data;
    } else if (response.status === 206 && response.data.length) {
      const data: IActiveDirectoryData = {
        data: response.data,
        pageMeta,
        queryConfig: queryConfigNew,
        isNoCustomerDomains: true,
      };
      return data;
    } else {
      const data: IActiveDirectoryData = {
        data: response.data,
        pageMeta,
        queryConfig: queryConfigNew,
      };
      return data;
    }
  },
);

export const getUserAssigments = createAsyncThunk('activeDirectory/getUserAssigments', async (userId: string) => {
  const response: AxiosResponse = await http.get(
    `/api/MSPCustomerAssignments/GetDirectCustomerDomainsAssignments?userId=${userId}`,
  );
  const assignments: IActiveDirectoryAssignment[] = response.data;
  return assignments;
});

export const updateUsersAssignments = createAsyncThunk(
  'activeDirectory/onUpdateAssignments',
  async ({ userId, assignments }: { userId: string; assignments: IActiveDirectoryAssignment[] | null }) => {
    const response: AxiosResponse = await http.put(
      `/api/MSPCustomerAssignments/UpdateCustomerDomainsAssignments?userId=${userId}`,
      assignments,
    );
    const assignmentsCountNew: number = response.data;
    return assignmentsCountNew;
  },
);

interface IActiveDirectoryState {
  activeDirectoryData: IActiveDirectoryData | null;
  getActiveDirectoryDataRequest: IHttpRequestResult<IActiveDirectoryData>;
  getUserAssigmentsRequest: IHttpRequestResult<IActiveDirectoryAssignment[]>;
  updateUsersAssignmentsRequest: IHttpRequestResult<number>;
}

const initialState: IActiveDirectoryState = {
  activeDirectoryData: null,
  getActiveDirectoryDataRequest: createHttpRequestInitResult(),
  getUserAssigmentsRequest: createHttpRequestInitResult(),
  updateUsersAssignmentsRequest: createHttpRequestInitResult(),
};

export const activeDirectorySlice = createSlice({
  name: 'ActiveDirectory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createExtraReducersForResponses<IActiveDirectoryState>(
      builder,
      getActiveDirectoryData,
      'getActiveDirectoryDataRequest',
      (state, { payload }) => {
        state.activeDirectoryData = payload;
      },
    );
    createExtraReducersForResponses<IActiveDirectoryState>(builder, getUserAssigments, 'getUserAssigmentsRequest');
    createExtraReducersForResponses<IActiveDirectoryState>(
      builder,
      updateUsersAssignments,
      'updateUsersAssignmentsRequest',
      (state, action) => {
        const { userId } = action.meta.arg;
        const assignmentsCountNew: number = action.payload;
        state.activeDirectoryData?.data?.forEach((i: IActiveDirectoryUser) => {
          if (i.userId === userId) i.assignmentsCount = assignmentsCountNew;
        });
      },
    );
  },
});

// export const {} = activeDirectorySlice.actions;

export default activeDirectorySlice.reducer;
