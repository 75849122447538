import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { createExtraReducersForResponses, createHttpRequestInitResult, http } from 'helpers/http';
import { IHttpRequestResult } from 'interfaces';

interface IBillingTokenResponse {
  clientToken: string;
}

export const getBillingToken = createAsyncThunk('billing/getBillingToken', async () => {
  const response: AxiosResponse<IBillingTokenResponse> = await http.get('/api/Billing/GetCurrentBillingStatus');
  return response.data;
});

export const changePaymentMethod = createAsyncThunk('billing/changePaymentMethod', async (nonce: string) => {
  await http.post(`/api/Billing/ChangePaymentMethod?nonce=${nonce}`);
});

interface IBillingState {
  billingToken: string;
  getBillingTokenRequest: IHttpRequestResult<IBillingTokenResponse>;
  changePaymentMethodRequest: IHttpRequestResult<void>;
}

const initialState: IBillingState = {
  billingToken: '',
  getBillingTokenRequest: createHttpRequestInitResult(),
  changePaymentMethodRequest: createHttpRequestInitResult(),
};

export const billing = createSlice({
  name: 'BillingSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createExtraReducersForResponses(builder, getBillingToken, 'getBillingTokenRequest');
    createExtraReducersForResponses(builder, changePaymentMethod, 'changePaymentMethodRequest');
  },
});

// export const {} = billing.actions;

export default billing.reducer;
