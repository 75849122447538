import { Modal } from 'components';
import { checkTypeAndUsers } from '../../utils/phoneSetupUtils';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  isModalButtonLoading: boolean;
  modalChangeUserData: any;
  handleResetPhoneSetup: () => void;
  handleTogglePhoneSetup: () => void;
}

const ModalChangeUser = ({
  isOpen,
  onRequestClose,
  isModalButtonLoading,
  modalChangeUserData,
  handleResetPhoneSetup,
  handleTogglePhoneSetup,
}: Props) => {
  const { message, button, title, actionFunc } = checkTypeAndUsers(
    modalChangeUserData,
    handleResetPhoneSetup,
    handleTogglePhoneSetup,
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={title}
      actionFunc={actionFunc}
      actionTitle={button}
      actionLoading={isModalButtonLoading}>
      {message}
    </Modal>
  );
};

export default ModalChangeUser;
