import { Link, StatusMessage } from 'components';
import { getDomainADStatus } from 'helpers';
import { IDomain } from 'interfaces';

interface Props {
  domain: IDomain;
}

const NoComputersMsg = ({ domain }: Props) => {
  const { isDomainAzure } = getDomainADStatus(domain);
  return (
    <StatusMessage header="No computers reporting in">
      {isDomainAzure ? (
        <p>
          Please&nbsp;
          <Link href="https://www.trugrid.com/docs/addsecureconnect" external bold>
            add TruGrid SecureConnect
          </Link>
        </p>
      ) : (
        <p>
          Please check Active Directory or&nbsp;
          <Link href="https://www.trugrid.com/docs/installsentry" external bold>
            add computers
          </Link>
        </p>
      )}
    </StatusMessage>
  );
};

export default NoComputersMsg;
