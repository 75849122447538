import { useRef, useState } from 'react';
import { checkIsPasswordValid } from 'helpers';
import { Modal, TextInput } from 'components';
import styles from './EncryptPassModal.module.scss';
import { IBitlockerEncryption, createEncryptionPassword } from 'store/slices/bitLockerMgmt';
import { useAppDispatch, useAppSelector } from 'store';
import { notify } from 'utils';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  modalData: IBitlockerEncryption;
}

const EncryptPassModal = ({ isOpen, onRequestClose, modalData }: Props) => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.bitLockerMgmt.createEncryptionPasswordRequest);

  const [formValues, setFormValues] = useState(['', '']);
  const [formErrors, setFormErrors] = useState(['', '']);
  const newPassRef = useRef<HTMLInputElement>(null);
  const confirmPassRef = useRef<HTMLInputElement>(null);
  const { deviceId } = modalData;

  const isFormValid = () => {
    const formErrorsNew = [...formErrors];
    const isNewPassValid = checkIsPasswordValid(formValues[0]);
    const isConfirmPassValid = formValues[1] && formValues[0] === formValues[1];

    if (!isNewPassValid) {
      formErrorsNew[0] = ' ';
      formErrorsNew[1] = 'Password does not match requirements';
    }
    if (isNewPassValid && !isConfirmPassValid) {
      formErrorsNew[0] = ' ';
      formErrorsNew[1] = 'Passwords do not match';
    }

    if (formErrorsNew.length) {
      setFormErrors(formErrorsNew);
    }

    return !formErrorsNew.some((i) => i);
  };

  const onUpdateInput = (value: string, index: number) => {
    const passValuesNew = formValues.map((i, ind) => (index === ind ? value.trim() : i));
    setFormValues(passValuesNew);
  };

  const onClearError = (index: number) => {
    const passErrorsNew = formErrors.map((i, ind) => (index === ind ? '' : i));
    setFormErrors(passErrorsNew);
  };

  const onClearValue = (index: number) => {
    const passValuesNew = formValues.map((i, ind) => (index === ind ? '' : i));
    setFormValues(passValuesNew);

    if (formErrors[index]) onClearError(index);
  };

  const handleEncrypt = async (e?: React.FormEvent<HTMLFormElement>): Promise<void> => {
    try {
      e?.preventDefault();
      if (isFormValid()) {
        const password: string = formValues[0];
        const confirmedPassword: string = formValues[1];
        const params: { deviceId: string; password: string; confirmedPassword: string } = {
          deviceId,
          password,
          confirmedPassword,
        };

        await dispatch(createEncryptionPassword(params)).unwrap();
        onRequestClose();
      }
    } catch (err: any) {
      notify.error(err.message);
      onRequestClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Create encryption password for drives"
      style={{ content: { width: '39em' } }}
      actionTitle="Create"
      actionFunc={handleEncrypt}
      actionLoading={isLoading}
      actionDisabled={formValues.includes('')}>
      <form className={styles.encryptPass} method="POST" onSubmit={handleEncrypt}>
        <p className={styles.encryptPassTitle}>
          By creating an Encryption Password, you will be able to encrypt Drives on your PCs and make your data secure
        </p>
        <div className={styles.encryptPassWrap}>
          <TextInput
            ref={newPassRef}
            id={'enter new password'.split(' ').join('-')}
            type="password"
            hasView
            autoComplete="new-password"
            placeholder="Enter New Password"
            value={formValues[0]}
            error={formErrors[0]}
            onChange={(e) => onUpdateInput(e.target.value, 0)}
            onClear={() => onClearValue(0)}
            onFocus={() => onClearError(0)}
          />
        </div>
        <div className={styles.encryptPassWrap}>
          <TextInput
            ref={confirmPassRef}
            id={'confirm new password'.split(' ').join('-')}
            placeholder="Confirm New Password"
            type="password"
            hasView
            autoComplete="new-password"
            value={formValues[1]}
            error={formErrors[1]}
            onChange={(e) => onUpdateInput(e.target.value, 1)}
            onClear={() => onClearValue(1)}
            onFocus={() => onClearError(1)}
          />
        </div>
        <div className={styles.encryptPassPassInfo}>
          <p className={styles.encryptPassPassInfoTitle}>Encryption Password should contain:</p>
          <ul className={styles.encryptPassPassInfoList}>
            <li>- at least 8 characters</li>
            <li>- at least one number</li>
            <li>- both lower and uppercase letters</li>
            <li>- special characters</li>
          </ul>
        </div>
      </form>
    </Modal>
  );
};

export default EncryptPassModal;
