import { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import ToggleEnableModal from './components/ToogleEnableModal/ToogleEnableModal';
import ManageCountriesModal from './components/ManageCountriesModal/ManageCountriesModal';
import DeleteCountryModal from './components/DeleteCountryModal/DeleteCountryModal';
import DataTable from './components/DataTable/DataTable';
import GeoBlokingHeader from './components/GeoBlokingHeader/GeoBlokingHeader';
import { useAppDispatch, useAppSelector } from 'store';
import { toggleGeoBlockingStatus } from 'store/slices/securityMgmt/geoBlocking';
import { IDomain } from 'interfaces';
import { notify } from 'utils';
import styles from './GeoBlocking.module.scss';

interface Props {
  domain: IDomain;
  isCompanyPage: boolean;
}
export type TypeEnableGeoBlocking = 'priorList' | 'newList';

const GeoBlocking = ({ domain, isCompanyPage }: Props) => {
  const dispatch = useAppDispatch();
  const { geoBlockingData } = useAppSelector((store) => store.geoBlocking);
  const { id, dnsName } = domain;

  const [modalData, setModalData] = useState<boolean | null>(null);
  const [isGeoBlockingEnabledCurrent, setIsGeoBlockingEnabled] = useState(
    geoBlockingData?.data.isGeoBlockingEnabled || false,
  );
  const [checkedCountries, setCheckedCountries] = useState<string[]>([]);
  const [typeEnableGeoBlocking, setTypeEnableGeoBlocking] = useState<TypeEnableGeoBlocking>('priorList');
  const [deleteCountryName, setDeleteCountryName] = useState<string>('');
  const [showManageCountriesModal, setShowManageCountriesModal] = useState<boolean>(false);
  const [showToogleModal, setShowToogleModal] = useState<boolean>(false);
  const [showDeleteCountryModal, setShowDeleteCountryModal] = useState<boolean>(false);
  const [isManageAllowedList, setIsManageAllowedList] = useState<boolean>(false);

  const toggleShowEnableModal = () => {
    setShowToogleModal(!showToogleModal);
  };

  const toggleShowDeleteCountryModal = () => {
    setShowDeleteCountryModal(!showDeleteCountryModal);
  };

  const toggleManageCountriesModal = () => {
    setShowManageCountriesModal(!showManageCountriesModal);
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  useEffect(() => {
    const array: string[] = [];
    if (typeEnableGeoBlocking === 'priorList') {
      if (geoBlockingData?.data?.countries.length !== 0) {
        geoBlockingData?.data?.countries.map(({ countryName }: { countryName: string }) => {
          array.push(countryName);
          return setCheckedCountries(array);
        });
      }
    } else {
      setCheckedCountries([]);
    }
  }, [geoBlockingData?.data?.countries, showManageCountriesModal, showDeleteCountryModal, typeEnableGeoBlocking]);

  const onSwitchGeoBlocking = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModalData(e.target.checked);
    if (
      !isGeoBlockingEnabledCurrent &&
      (geoBlockingData?.data?.countries === null || geoBlockingData?.data?.countries.length === 0)
    ) {
      setTypeEnableGeoBlocking('newList');
      toggleManageCountriesModal();
    } else {
      setTypeEnableGeoBlocking('priorList');
      toggleShowEnableModal();
    }
  };

  const openDeleteCountryModal = (countryName: string) => {
    setDeleteCountryName(countryName);
    setShowDeleteCountryModal(true);
  };

  const onChangeGeoBlocking = async () => {
    if (isGeoBlockingEnabledCurrent) {
      await onToggleGeoBlocking();
    } else {
      toggleShowEnableModal();
      toggleManageCountriesModal();
    }
  };

  const onToggleGeoBlocking = async () => {
    try {
      const { data } = await dispatch(toggleGeoBlockingStatus({ domainId: id, checkedCountries })).unwrap();
      notify.success(`Geo-Blocking is ${data.isGeoBlockingEnabled ? 'enabled' : 'disabled'} for domain`);
      setIsGeoBlockingEnabled((isGeoBlockingEnabled) => !isGeoBlockingEnabled);
      if (isGeoBlockingEnabledCurrent) {
        toggleShowEnableModal();
        setIsManageAllowedList(false);
      } else {
        toggleManageCountriesModal();
        setIsManageAllowedList(false);
      }
      setTypeEnableGeoBlocking('priorList');
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  return (
    <>
      <GeoBlokingHeader
        isGeoBlockingEnabledCurrent={isGeoBlockingEnabledCurrent}
        onSwitchGeoBlocking={onSwitchGeoBlocking}
        setIsManageAllowedList={setIsManageAllowedList}
        setTypeEnableGeoBlocking={setTypeEnableGeoBlocking}
        toggleManageCountriesModal={toggleManageCountriesModal}
      />
      {isGeoBlockingEnabledCurrent &&
      geoBlockingData?.data?.countries &&
      geoBlockingData?.data?.countries?.length > 0 ? (
        <DataTable isCompanyPage={isCompanyPage} openDeleteCountryModal={openDeleteCountryModal} domainId={id} />
      ) : (
        <div className={styles.geoBlockInfo}>
          <h4 className={styles.geoBlockInfoTitle}>Geo-Blocking is not currently enabled</h4>
          <p className={styles.geoBlockInfoText}>
            Geo-Blocking allows Administrators to determine countries where users can connect from
          </p>
        </div>
      )}

      {showToogleModal && (
        <ToggleEnableModal
          typeEnableGeoBlocking={typeEnableGeoBlocking}
          setTypeEnableGeoBlocking={setTypeEnableGeoBlocking}
          checkedCountriesLength={geoBlockingData?.data?.countries?.length || 0}
          type={modalData}
          onClose={toggleShowEnableModal}
          onChangeGeoBlocking={onChangeGeoBlocking}
          dnsName={dnsName}
        />
      )}

      {showManageCountriesModal && (
        <ManageCountriesModal
          isManageAllowedList={isManageAllowedList}
          setIsManageAllowedList={setIsManageAllowedList}
          typeEnableGeoBlocking={typeEnableGeoBlocking}
          checkedCountries={checkedCountries}
          setNewCheckedCountries={(items) => setCheckedCountries(items)}
          setShowManageCountriesModal={setShowManageCountriesModal}
          showManageCountriesModal={showManageCountriesModal}
          onToggleGeoBlocking={() => onToggleGeoBlocking()}
          setIsGeoBlockingEnabled={setIsGeoBlockingEnabled}
          setCheckedCountries={setCheckedCountries}
          domainId={id}
        />
      )}

      {showDeleteCountryModal && (
        <DeleteCountryModal
          toggleShowDeleteCountryModal={toggleShowDeleteCountryModal}
          showDeleteCountryModal={showDeleteCountryModal}
          checkedCountries={checkedCountries}
          deleteCountryName={deleteCountryName}
          setIsGeoBlockingEnabled={setIsGeoBlockingEnabled}
          setCheckedCountries={setCheckedCountries}
          domainId={id}
        />
      )}
    </>
  );
};

export default GeoBlocking;
