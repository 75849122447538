import styles from './NoData.module.scss';

interface Props {
  isNoData: string;
}

const NoData = ({ isNoData }: Props) => (
  <div className={styles.noData}>
    <div className={styles.noDataMessage}>
      <p className={styles.noDataTitle}>No data reporting in</p>
      <p>
        Please check Active Directory or{' '}
        {isNoData === 'no data' ? (
          <a
            href="https://www.trugrid.com/docs/setupad"
            className={styles.noDataLink}
            target="_blank"
            rel="noopener noreferrer">
            add users & customer domains
          </a>
        ) : (
          <a
            href="https://www.trugrid.com/docs/usersnotreportingin"
            className={styles.noDataLink}
            target="_blank"
            rel="noopener noreferrer">
            add users
          </a>
        )}
      </p>
    </div>
  </div>
);

export default NoData;
