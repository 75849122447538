import { useState } from 'react';
import { saveAs } from 'file-saver';
import LicensesActions from './components/LicensesActions/LicensesActions';
import ExportDetailsModal from './components/ExportDetailsModal/ExportDetailsModal';
import { useAppDispatch, useAppSelector } from 'store';
import LicensingTable from './components/LicensingTable/LicensingTable';
import { getLicensesReports } from 'store/slices/reports';

interface Props {
  primaryDomain: string;
  domainId: string;
  userRole: string;
  setIsFastGenerate: (isFastGenerate: boolean) => void;
  isFastGenerate: boolean;
  isCompanyPage: boolean;
  selectedLicenseType: string;
  setSelectedLicenseType: (selectedLicenseType: string) => void;
}

const Licensing = ({
  primaryDomain,
  domainId,
  userRole,
  setIsFastGenerate,
  isFastGenerate,
  isCompanyPage,
  selectedLicenseType,
  setSelectedLicenseType,
}: Props) => {
  const dispatch = useAppDispatch();
  const { licenses } = useAppSelector((state) => state.reports);

  const { isLoading } = useAppSelector((state) => state.reports.getLicensesReportsRequest);

  const allLicensesCount = licenses.reduce((prev, cur) => prev + cur.licensesCount, 0);

  const [showExportDetailsModal, setShowExportDetailsModal] = useState(false);

  const toggleExportDetailsModal = () => {
    setShowExportDetailsModal(!showExportDetailsModal);
  };

  const saveFile = ({ downloadLink }: { downloadLink: string }) => {
    const fileName = downloadLink.split('/').slice(-1).toString();
    saveAs(`${downloadLink}`, `${fileName}`);
  };

  const onChangeLicenseType = async (selectedLicenseTypeNew: string) => {
    await dispatch(getLicensesReports({ domainId: domainId, licenseType: selectedLicenseTypeNew }));
    setSelectedLicenseType(selectedLicenseTypeNew);
  };

  return (
    <>
      <LicensesActions
        isCompanyPage={isCompanyPage}
        selectedLicenseType={selectedLicenseType}
        onChangeLicenseType={onChangeLicenseType}
        isLoading={isLoading}
        licensesCount={allLicensesCount}
        userRole={userRole}
        setShowExportDetailsModal={setShowExportDetailsModal}
        isFastGenerate={isFastGenerate}
        saveFile={saveFile}
      />

      <LicensingTable
        licenses={licenses}
        primaryDomain={primaryDomain}
        isCompanyPage={isCompanyPage}
        userRole={userRole}
        selectedLicenseType={selectedLicenseType}
      />

      {showExportDetailsModal && (
        <ExportDetailsModal
          isFastGenerate={isFastGenerate}
          setIsFastGenerate={setIsFastGenerate}
          primaryDomain={primaryDomain}
          licenses={licenses}
          showExportDetailsModal={showExportDetailsModal}
          toggleExportDetailsModal={toggleExportDetailsModal}
          userRole={userRole}
          saveFile={saveFile}
          isCompanyPage={isCompanyPage}
        />
      )}
    </>
  );
};

export default Licensing;
