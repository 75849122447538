import { useState } from 'react';
import Alert from 'components/Alert/Alert';
import Button from 'components/Button/Button';
import ValidateDomainModal from 'components/ValidateDomainModal/ValidateDomainModal';
import { getUserRole, notify } from 'utils';
import ValidateModal from './ValidateModal/ValidateModal';
import { daysBetweenDates } from './utils/daysBetweenDates';
import { useAppDispatch, useAppSelector } from 'store';
import { IResponseValidationData, validateDomain } from 'store/slices/userSlice';
import {
  IValidateModalDataBase,
  IValidateModalDataWithAutoValidated,
  IValidateModalDataWithoutAutoValidated,
} from 'interfaces';
import styles from './AlertValidateDomain.module.scss';

const AlertValidateDomain = () => {
  const { user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const { finalTimeToValidateDomain } = user;

  const role = getUserRole(user);
  const isAdmin = role === 'spAdmin' || role === 'corpAdmin';
  const daysLeft = daysBetweenDates(finalTimeToValidateDomain);

  const [isShowValidateModal, setIsShowValidateModal] = useState(daysLeft < 1 || false);
  const [isShowSendValidationData, setIsShowSendValidationData] = useState(false);
  const [validationData, setValidationData] = useState<IValidateModalDataBase | null>(null);

  const onModalOpen = (data: IValidateModalDataBase) => {
    setValidationData(data);
    setIsShowValidateModal(false);
    setIsShowSendValidationData(true);
  };

  const onModalClose = () => {
    setIsShowSendValidationData(false);
    setIsShowValidateModal(true);
    setValidationData(null);
  };

  const toggleShowValidateModal = () => {
    setIsShowValidateModal(!isShowValidateModal);
  };

  const emailValidationData: IValidateModalDataWithoutAutoValidated = {
    type: 'emailValidation',
    link: `api/DomainValidation/SendValidationEmail?domainId=${user.domainId}&domainName=${user.dnsDomainName}`,
    domainId: user.domainId,
    domainName: user.dnsDomainName,
    upn: user.email,
  };
  const txtValidationData: IValidateModalDataWithAutoValidated = {
    type: 'txtValidation',
    link: `api/DomainValidation/GetValidationCode?domainId=${user.domainId}`,
    autoValidated: true,
    domainId: user.domainId,
    domainName: user.dnsDomainName,
  };

  const onUpdateModal = (resp: IResponseValidationData, domainId: string) => {
    const domainName = user.email.split('@')[1];
    dispatch(validateDomain({ ...resp, domainId }));
    notify.success(`Domain ${domainName} has been validated`);
    toggleShowValidateModal();
  };

  return (
    <>
      {isAdmin && (
        <Alert
          icon={false}
          className={styles.alert}
          action={
            <Button size="40" style={{ width: '14em' }} onClick={toggleShowValidateModal}>
              Validate domain
            </Button>
          }>
          <p>
            For your security, please validate your domain within <span>{daysLeft < 1 ? 0 : daysLeft}</span>{' '}
            {daysLeft > 1 ? 'days' : 'day'}.
          </p>
        </Alert>
      )}
      {isShowValidateModal && (
        <ValidateModal
          isShowValidateModal={isShowValidateModal}
          toggleShowValidateModal={toggleShowValidateModal}
          onModalOpen={onModalOpen}
          emailValidationData={emailValidationData}
          txtValidationData={txtValidationData}
          daysLeft={daysLeft}
          user={user}
        />
      )}
      {isShowSendValidationData && (
        <ValidateDomainModal
          isOpen={isShowSendValidationData}
          data={validationData!}
          onClose={onModalClose}
          onUpdateModal={onUpdateModal}
          isCustomerDomain={false}
        />
      )}
    </>
  );
};

export default AlertValidateDomain;
