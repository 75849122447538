import styles from './Loader.module.scss';

interface Props {
  style?: React.CSSProperties;
  id: string;
}

const Loader = ({ style, id }: Props) => (
  <div className={styles.loader} style={style} id={id || 'loading'}>
    <div className={styles.loaderIcon} />
    <div className={styles.loaderText}>Loading</div>
  </div>
);

export default Loader;
