import { Button, Alert } from 'components';
import { AttentionIcon } from 'assets/img';
import styles from './AlertSentryUpdate.module.scss';
import { ICustomerDomainOutdatedSentry, IOutdatedSentry } from 'interfaces';
import { getOutdatedSentryCount } from 'pages/Workspace/utils/workspaceUtils';

interface Props {
  customerDomain?: boolean;
  sentries: IOutdatedSentry[] | ICustomerDomainOutdatedSentry[];
  openUpdateModal: () => void;
}

const AlertSentryUpdate = ({ customerDomain, sentries, openUpdateModal }: Props) => {
  const outdatedSentries = getOutdatedSentryCount(sentries, customerDomain);

  return (
    <div className={styles.alert}>
      <Alert type="warning" icon={<AttentionIcon />}>
        There {outdatedSentries === 1 ? 'is' : 'are'}&nbsp;<span className={styles.alertCount}>{outdatedSentries}</span>
        &nbsp;outdated Sentry software{customerDomain ? ' in Customer Domains' : ''}. Please&nbsp;
        <Button variant="link" onClick={openUpdateModal} style={{ fontWeight: 600 }}>
          update
        </Button>
        &nbsp;TruGrid {outdatedSentries === 1 ? 'Sentry' : 'Sentries'} to access the latest features
      </Alert>
    </div>
  );
};

export default AlertSentryUpdate;
