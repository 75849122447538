import cn from 'classnames';
import { getMfaStatus } from 'utils';
import { DropdownArray } from 'components';
import { MoreDotsIcon } from 'assets/img';
import { TypeAction } from 'pages/SecurityMgmt/pages/MfaMgmt/MfaMgmt';
import { IMfaUser } from 'store/slices/securityMgmt/mfaMgmt';
import styles from './MoreButton.module.scss';

interface Props {
  onModalOpen: (type: TypeAction, user: IMfaUser) => void;
  user: IMfaUser;
  mfaStatus: number;
}

const MoreButton = ({ onModalOpen, user, mfaStatus }: Props) => {
  const { isMfaEnabled, isMfaSetupPending, isMfaDisabled, isMfaResetMode } = getMfaStatus(mfaStatus);

  const { isAdmin } = user;
  const onMoreChange = (value: TypeAction) => {
    if (value === 'Reset MFA') {
      onModalOpen('Reset MFA', user);
    }
    if (value === 'Disable MFA') {
      onModalOpen('Disable MFA', user);
    }
    if (value === 'Enable MFA') {
      onModalOpen('Enable MFA', user);
    }
  };

  const options = [];

  if (isMfaEnabled) {
    if (isAdmin) {
      options.push('Reset MFA');
    } else {
      options.push('Disable MFA');
      options.push('Reset MFA');
    }
  } else if (isMfaSetupPending) {
    if (!isAdmin) {
      options.push('Disable MFA');
    }
  } else if (isMfaDisabled) {
    options.push('Enable MFA');
  } else if (isMfaResetMode) {
    if (!isAdmin) {
      options.push('Disable MFA');
    }
  }

  return (
    !!options.length && (
      <div className={styles.dropdownWrapper}>
        <DropdownArray
          openIcon={false}
          icon={<MoreDotsIcon />}
          options={options}
          onChange={(value) => onMoreChange(value as TypeAction)}
          className={cn(styles.dropdownWrapperDropdown, 'dropdown-component__select')}
          allowPositionTop
          optionsClassName={styles.dropdownWrapperDropdownOptions}
        />
      </div>
    )
  );
};

export default MoreButton;
