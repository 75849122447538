import cn from 'classnames';
import { useAppSelector } from 'store';
import {
  AngleDownIcon,
  CompanyIcon,
  CustomersIcon,
  HeaderUserIcon,
  LogoIcon,
  PlusIcon,
  WorkspaceIcon,
} from 'assets/img';
import WorkspacePreviewImage from 'assets/img/workspace-preview.svg';
import { convertHex } from 'pages/Profile/pages/Branding/helpers/colorsHelper';
import styles from './WorkspacePreview.module.scss';

const WorkspacePreview = ({ invertedColor }: { invertedColor: boolean }) => {
  const { branding } = useAppSelector((state) => state.branding);

  const color = invertedColor ? '#000' : '#fff';

  return (
    <div className={styles.preview}>
      <nav
        className={styles.previewNav}
        style={
          branding?.modified?.backGroundColor
            ? { background: convertHex(branding.modified.backGroundColor.slice(1)) }
            : {}
        }>
        <div>
          {branding?.modified?.logo ? (
            <img
              className={cn(styles.previewNavLogo, styles.previewNavLogoCustom)}
              src={`data:image/png;base64,${branding.modified.logo}`}
              width="46"
              alt=""
            />
          ) : (
            <LogoIcon className={cn(styles.previewNavLogo, styles.previewNavLogoImage)} />
          )}
          <ul className={styles.previewNavMenu} style={{ color: color }}>
            <li className={cn(styles.previewNavMenuItem, styles.active)}>
              <span style={branding?.modified?.backGroundColor ? { background: color } : {}} />
              <WorkspaceIcon style={{ fill: color }} />
              My Workspace
            </li>
            <li className={styles.previewNavMenuItem}>
              <span />
              <CompanyIcon style={{ fill: color }} />
              Company
              <PlusIcon style={{ fill: color }} className={styles.previewNavMenuItemPlus} />
            </li>
            <li className={styles.previewNavMenuItem}>
              <span />
              <CustomersIcon style={{ fill: color }} />
              Customers
            </li>
          </ul>
        </div>
        <div className={styles.previewNavFooter}>
          <div className={styles.previewNavFooterCopyright} style={{ color: color }}>
            Powered by trugrid.com
          </div>
          <div className={styles.previewNavFooterDownloads} style={{ color: color }}>
            Downloads
            <AngleDownIcon style={{ fill: color }} />
          </div>
        </div>
      </nav>
      <main className={styles.previewMain}>
        <div className={styles.previewMainWrap}>
          <div className={styles.previewMainTimer}>
            For your security, this website will be logged off after <span>15:00</span> mins of inactivity
          </div>
          <div className={styles.previewMainAvatarWrap}>
            <div className={styles.previewMainAvatar}>
              <HeaderUserIcon />
            </div>
            <AngleDownIcon className={styles.previewMainAvatarDown} />
          </div>
        </div>
        <div>
          <img className={styles.previewMainImage} src={WorkspacePreviewImage} alt="" />
        </div>
      </main>
    </div>
  );
};

export default WorkspacePreview;
