import { corpAdmin, corpTechAdmin, custAdmin, spAdmin, spTechAdmin, techAdmin } from 'consts';
import { IBitlockerDeviceData } from 'store/slices/bitLockerMgmt';
import windows from 'assets/img/windows-os.svg';
import mac from 'assets/img/mac-os.svg';

export const checkIsVisibleNoLicensesMsg = (userRole: string) =>
  [spTechAdmin, spAdmin, corpAdmin, corpTechAdmin, techAdmin, custAdmin].includes(userRole);

export const checkIsCustTechAdmin = (userRole: string) => [techAdmin, custAdmin].includes(userRole);

export const checkIsNoDevices = (devices: IBitlockerDeviceData | null, subscriptionStatus: boolean | null) => {
  return (
    !devices?.data?.length &&
    !devices?.queryConfig.searchQuery &&
    devices?.queryConfig.deviceStatus === 'AllDevices' &&
    devices?.queryConfig.installationStatus === 'AllStatuses' &&
    subscriptionStatus
  );
};

export const dataTipHandler = (osType: number, hasLicense: boolean, appVersion: string, osVersion: string) => {
  const icon = osType === 0 ? windows : mac;
  if (hasLicense) {
    return `
      <p>Agent Version: <span>${appVersion}</span></p>
      <p style="display: inline-flex;align-items: center;">OS Version: <span style="display: inline-flex;align-items: center;"><img src="${icon}" alt="" /> ${osVersion}</span></p>
    `;
  }
  return 'Unlicensed';
};
