import { useAppDispatch, useAppSelector } from 'store';
import { Modal } from 'components';
import { notify } from 'utils';
import { onToggleRememberMe } from 'store/slices/securityMgmt/remebmerMe';

interface Props {
  isShowToggleModal: boolean;
  handleShowToggleModal: () => void;
  domainId: string;
}

const EnableToggleModal = ({ isShowToggleModal, handleShowToggleModal, domainId }: Props) => {
  const dispatch = useAppDispatch();
  const { isRememberMeEnabled } = useAppSelector(({ rememberMe }) => rememberMe.rememberMeData) || {};
  const { isLoading } = useAppSelector(({ rememberMe }) => rememberMe.onToggleRememberMeRequest);

  const handleToggleRememberMe = async () => {
    try {
      await dispatch(onToggleRememberMe({ domainId, isRememberMeEnabled: isRememberMeEnabled! })).unwrap();
      if (isRememberMeEnabled) {
        notify.success('Remember Me is disabled for domain');
      } else {
        notify.success('Remember Me is enabled for domain');
      }
    } catch (err: any) {
      notify.error(err.message);
    } finally {
      handleShowToggleModal();
    }
  };

  return (
    <Modal
      isOpen={isShowToggleModal}
      onRequestClose={handleShowToggleModal}
      contentLabel={isRememberMeEnabled ? 'Disable Remember Me for domain?' : 'Enable Remember Me for domain?'}
      actionTitle={isRememberMeEnabled ? 'Disable' : 'Enable'}
      actionLoading={isLoading}
      variantBtn="primary"
      actionFunc={handleToggleRememberMe}>
      {isRememberMeEnabled ? (
        <>
          <p>By disabling this option, all Users won’t have "Remember Me" on TruGrid Desktop Connectors</p>
          <br />
          <p>Disabling this option will override individual settings for this feature</p>
        </>
      ) : (
        <p>By enabling this option, you can allow users to use "Remember Me" on TruGrid Desktop Connectors</p>
      )}
    </Modal>
  );
};

export default EnableToggleModal;
