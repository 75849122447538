/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-async-promise-executor */
import { ActionReducerMapBuilder, AsyncThunk, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export interface IRequestConfig<D = any> extends AxiosRequestConfig {
  _retry?: boolean;
  _disableErrorHandler?: boolean;
}

export interface IAxiosError<D = any> extends AxiosError {
  config: IRequestConfig<D>;
}

export const instance: AxiosInstance = axios.create({
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

if (process.env.REACT_APP_LOCAL === 'true') {
  instance.defaults.baseURL = process.env.REACT_APP_PUBLIC_URL;
}

const onRequestSent = (config: AxiosRequestConfig<any>) => {
  const event = new CustomEvent('axios-request-sent', {
    detail: config,
  });
  window.dispatchEvent(event);
};

const onResponseReceived = (response: AxiosResponse<any, any>) => {
  const event = new CustomEvent('axios-response-received', {
    detail: response,
  });
  window.dispatchEvent(event);
};

export const subscribeToRequestSent = (callback: (arg0: any) => void) => {
  window.addEventListener('axios-request-sent', (event: any) => {
    callback(event.detail);
  });
};

export const subscribeToResponseReceived = (callback: (arg0: any) => void) => {
  window.addEventListener('axios-response-received', (event: any) => {
    callback(event.detail);
  });
};

export const unsubscribeFromResponseReceived = (callback: EventListenerOrEventListenerObject) => {
  window.removeEventListener('axios-response-received', callback);
};

export const unsubscribeFromRequestSent = (callback: EventListenerOrEventListenerObject) => {
  window.removeEventListener('axios-request-sent', callback);
};

const errorHandler = (error: { response: { status: number } }) => {
  if (error.response.status === 401) {
    window.location.href = '/login';
  }
  return Promise.reject(error);
};

instance.interceptors.request.use(
  (request) => {
    onRequestSent(request);
    return request;
  },
  (error) => errorHandler(error),
);

instance.interceptors.response.use(
  (response) => {
    onResponseReceived(response);
    return response;
  },
  (error) => errorHandler(error),
);

const handleHttpError = (error: IAxiosError) => {
  const message: string =
    typeof error.response?.data === 'string'
      ? error.response.data
      : error.response?.data && typeof (error.response.data as { message?: string }).message === 'string'
      ? (error.response.data as { message: string }).message
      : 'Something Failed. Try again?';

  // Create an instance of the custom error type
  const newError: SerializedError = new Error(message);

  // Set the code property
  newError.code = `${error.response?.status}`;
  newError.name = JSON.stringify(error.response?.data);

  return newError;
};

const makeHttpRequest = (apiCall: () => Promise<AxiosResponse>) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response: AxiosResponse = await apiCall();
      resolve(response);
    } catch (e: any) {
      reject(handleHttpError(e as IAxiosError));
    }
  });
};

export const http = {
  get: (url: string, options?: any): Promise<any> => makeHttpRequest(() => instance.get(url, options)),
  post: (url: string, data?: any, options?: any): Promise<any> =>
    makeHttpRequest(() => instance.post(url, data, options)),
  put: (url: string, data?: any, options?: any): Promise<any> =>
    makeHttpRequest(() => instance.put(url, data, options)),
  patch: (url: string, data?: any, options?: any): Promise<any> =>
    makeHttpRequest(() => instance.patch(url, data, options)),
  delete: (url: string, data?: any, options?: any): Promise<any> =>
    makeHttpRequest(() => instance.delete(url, { options, ...data })),
};

export const createHttpRequestInitResult = () => ({
  data: undefined,
  error: undefined,
  isUninitialized: false,
  isLoading: false,
  isSuccess: false,
  isError: false,
});

export const createExtraReducersForResponses = <TState>(
  builder: ActionReducerMapBuilder<TState>,
  asyncThunk: AsyncThunk<any, any, any>,
  reduxField: string,
  successCallback?: (state: TState, action: PayloadAction<any, string, any, never>) => void,
) => {
  builder.addCase(asyncThunk.pending, (state: any, action) => {
    state[reduxField].isUninitialized = true;
    state[reduxField].error = undefined;
    state[reduxField].isError = false;
    state[reduxField].isSuccess = false;
    if (!action.meta.arg?._background) {
      state[reduxField].isLoading = true;
    }
  });
  builder.addCase(asyncThunk.fulfilled, (state: any, action: PayloadAction<any, string, any, never>) => {
    state[reduxField].isUninitialized = false;
    state[reduxField].isSuccess = true;
    state[reduxField].data = action.payload;
    state[reduxField].isLoading = false;
    if (successCallback) {
      successCallback(state as TState, action);
    }
  });
  builder.addCase(asyncThunk.rejected, (state: any, action: PayloadAction<any, string, any, any>) => {
    state[reduxField].isUninitialized = false;
    state[reduxField].data = undefined;
    state[reduxField].isLoading = false;
    state[reduxField].isError = true;
    state[reduxField].error = action.error;
  });
};
