import cn from 'classnames';
import { TooltipIcon } from 'assets/img';
import { SwitchCheckbox, Tooltip } from 'components';
import { IRememberMeUser } from 'store/slices/securityMgmt/remebmerMe';
import styles from './RememberMeStatus.module.scss';

interface Props {
  handleShowToggleModal: () => void;
  isRememberMeEnabled?: boolean;
  selectedUsers: IRememberMeUser[];
}

const RememberMeStatus = ({ handleShowToggleModal, isRememberMeEnabled, selectedUsers }: Props) => (
  <div className={styles.rememberStatus}>
    <div className={styles.rememberWrap}>
      <span>Remember Me Status</span>
      {isRememberMeEnabled && (
        <Tooltip
          id="info-tooltip"
          place="top"
          className={styles.rememberTooltip}
          wrapClassName={styles.rememberTooltipWrap}
          content="For your Security, REMEMBER ME feature is only enabled for users with MFA">
          <TooltipIcon />
        </Tooltip>
      )}
    </div>
    <div className={styles.rememberStatusWrap}>
      <SwitchCheckbox
        checked={isRememberMeEnabled}
        id="is-rememeber-me-enabled"
        name="is-rememeber-me-enabled"
        onChange={handleShowToggleModal}
        disabled={selectedUsers.length > 0}
        className={styles.rememberWrapSwitch}
      />
      <span
        className={cn(styles.rememberStatusWrapText, {
          [styles.disabled]: !isRememberMeEnabled,
          [styles.inactive]: selectedUsers.length > 0,
        })}>
        {isRememberMeEnabled ? 'ON' : 'OFF'}
      </span>
    </div>
  </div>
);

export default RememberMeStatus;
