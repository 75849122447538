import { dateFormatterShort } from 'helpers';
import { Button } from 'components';
import styles from './LicensesInfo.module.scss';
import TooltipInfo from '../../../../components/TooltipInfo/TooltipInfo';
import { useAppSelector } from 'store';
import { ICorporateSubscription } from 'interfaces';

interface Props {
  licensesState: ICorporateSubscription;
  updateSubscription: () => void;
}

const LicensesInfo = ({ licensesState, updateSubscription }: Props) => {
  const licenses = useAppSelector((state) => state.subscriptions.licenses as ICorporateSubscription);
  const { isLoading } = useAppSelector((state) => state.subscriptions.updateSubscriptionRequest);

  return (
    <>
      {!licenses.isSubscriptionActive && <div className={styles.totalsBackdrop} />}
      <div className={styles.totals}>
        <div className={styles.totalsSections}>
          <div className={styles.totalsSection}>
            <h4 className={styles.totalsSectionTitle}>Your next billing date</h4>
            <p className={styles.totalsSectionDate}>{dateFormatterShort(licenses.nextBillingDate)}</p>
          </div>

          <div className={styles.totalsSection}>
            <h4 className={styles.totalsSectionTitle}>Your next billing amount</h4>
            <div className={styles.totalsSectionDate}>
              <div>{licenses.autoMonthlyTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</div>
              <TooltipInfo
                name="billingAmount"
                content="Select <strong>View Billing Portal</strong> link below for invoice details"
                position="right"
                icon="question"
                isHtml
                className={styles.totalsSectionDateTooltip}
              />
            </div>
          </div>
        </div>
        <Button
          id="subs-primary-update"
          variant="primary"
          isLoading={isLoading}
          isDisabled={JSON.stringify(licenses.spDomain) === JSON.stringify(licensesState.spDomain)}
          size="40"
          onClick={updateSubscription}
          style={{ width: '14em' }}>
          Update Subscription
        </Button>
      </div>
    </>
  );
};

export default LicensesInfo;
