import { useState } from 'react';
import { PlusIcon } from 'assets/img';
import { Button } from 'components';
import { notify, truncateString } from 'utils';
import styles from './AddNewDomain.module.scss';
import { createNotValidCustomerDomain } from 'store/slices/customerDomains';
import { IDomain, IUser } from 'interfaces';
import { useAppDispatch } from 'store';

interface Props {
  domains: IDomain[];
  user: IUser;
}

const AddNewDomain = ({ domains, user }: Props) => {
  const dispatch = useAppDispatch();

  const [newDomainName, setNewDomainName] = useState('');
  const [addDomainState, setAddDomainState] = useState('disabled');

  const onNewDomainChange = (value: string) => {
    setNewDomainName(value);
    const isValid = value.split('.')[1]?.length;
    setAddDomainState(isValid ? '' : 'disabled');
  };

  const onNewDomainAdd = async () => {
    try {
      const domainNames = domains.map((d: IDomain) => d.dnsName);
      if (domainNames.includes(newDomainName)) {
        notify.error(`${newDomainName} already exists`);
      } else {
        setAddDomainState('loading');
        await dispatch(createNotValidCustomerDomain({ domainId: user.domainId, validDnsName: newDomainName })).unwrap();
        notify.success(`Domain ${truncateString(newDomainName, false)} has been added`);
      }
    } catch (err: any) {
      notify.warning(err.message);
    } finally {
      setNewDomainName('');
      setAddDomainState('disabled');
    }
  };

  const onNewDomainKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const isValid = newDomainName?.split('.')[1]?.length;
    if (event.key === 'Enter' && isValid) {
      onNewDomainAdd();
    }
  };

  return (
    <div className={styles.addDomain}>
      <div className={styles.addDomainWrap}>
        <input
          type="search"
          id="customers-new-domain"
          className={styles.addDomainInput}
          placeholder="Add New Domain"
          value={newDomainName}
          onChange={(e) => onNewDomainChange(e.target.value)}
          onKeyDown={onNewDomainKeyDown}
        />
        <PlusIcon />
      </div>
      <Button
        type="button"
        id="save-new-customer-domain"
        variant="primary"
        size="40"
        outline
        isLoading={addDomainState === 'loading'}
        isDisabled={addDomainState === 'disabled'}
        onClick={onNewDomainAdd}>
        Save
      </Button>
    </div>
  );
};

export default AddNewDomain;
