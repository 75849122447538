import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { createExtraReducersForResponses, createHttpRequestInitResult, http } from 'helpers/http';
import { ICountryList, IHttpRequestResult } from 'interfaces';

export const getCountries = createAsyncThunk('common/getCountries', async () => {
  const response: AxiosResponse<ICountryList> = await http.get('/api/Countries');
  return response.data;
});

interface ICommonState {
  countries: ICountryList;
  getCountriesRequest: IHttpRequestResult<ICountryList>;
}

const initialState: ICommonState = {
  countries: {},
  getCountriesRequest: createHttpRequestInitResult(),
};

const CommonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createExtraReducersForResponses<ICommonState>(builder, getCountries, 'getCountriesRequest', (state, action) => {
      state.countries = JSON.parse(action.payload);
    });
  },
});

//export const {} = CommonSlice.actions;

export default CommonSlice.reducer;
