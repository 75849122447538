import { ISettings } from 'interfaces';

interface IDevices {
  isSharedPrinters: string;
  isSharedPosDevices: string;
  isSharedComPorts: string;
  isSharedSmartCards: string;
  isSharedCameras: string;
  isSharedDevices: string;
  isSharedClipboard: string;
}
[];

export const onChangeSettings = (
  label: string,
  value: string | boolean | number,
  settings: { init: ISettings | null; modified: ISettings | null },
) => {
  value = typeof value === 'boolean' || label === 'sharedDrives' ? value : +value;
  const domainSettingsNew: { init: ISettings; modified: ISettings } = JSON.parse(JSON.stringify(settings));

  if (label === 'isZeroTrustEnabled' && value) {
    const { modified } = domainSettingsNew;
    const devices: string[] = [
      'isSharedPrinters',
      'isSharedPosDevices',
      'isSharedComPorts',
      'isSharedSmartCards',
      'isSharedCameras',
      'isSharedDevices',
      'isSharedClipboard',
    ];

    Object.keys(modified).forEach((key) => {
      if (key === 'isZeroTrustEnabled') {
        modified[key] = !!value;
        return;
      }

      if (key === 'sharedDrives') {
        modified[key] = '';
        return;
      }

      if (devices.includes(key)) {
        modified[key as keyof IDevices] = false;
      }
    });
  } else {
    (domainSettingsNew.modified as any)[label as keyof ISettings] = value;
  }

  return domainSettingsNew;
};
