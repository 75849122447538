import { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { Button, TextInput } from 'components';
import { checkIsEmailValid } from 'helpers';
import { notify } from 'utils';
import { login, setEmail } from 'store/slices/auth/auth';
import BlockedErrorMsg from 'pages/Login/components/BlockedErrorMsg/BlockedErrorMsg';
import styles from './LoginDefault.module.scss';
import { SerializedError } from '@reduxjs/toolkit';

const LoginDefault = () => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.auth.loginRequest);
  const { email } = useAppSelector((state) => state.auth);
  const [isEmailError, setIsEmailError] = useState('');

  const history = useHistory();
  const { url } = useRouteMatch();

  const setEmailValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    value = value.replace(/\s/g, '');
    dispatch(setEmail(value));
  };

  const clearEmailValue = () => {
    dispatch(setEmail(''));
  };

  const onEmailCheck = async (e?: React.FormEvent<HTMLFormElement>) => {
    try {
      e?.preventDefault();
      const isEmailValueValid = checkIsEmailValid(email);
      if (isEmailValueValid) {
        const { requiresEmailValidation, hasTempPassword, isAzureAdUser } = await dispatch(login(email)).unwrap();
        if (requiresEmailValidation) {
          history.push('/email-validation');
        } else if (hasTempPassword) {
          history.push(`${url}/temp-password`);
        } else if (isAzureAdUser) {
          history.push(`${url}/azure`);
        } else {
          history.push(`${url}/sign-in`);
        }
      } else {
        setIsEmailError('Please enter a valid email address');
      }
    } catch (err: any) {
      if ((err as SerializedError).code === '403') {
        notify.error(<BlockedErrorMsg />);
      } else if ((err as SerializedError).code === '405') {
        notify.warning(
          'Please login at trugrid.net with your primary login in order to access TruGrid as an Administrator',
          5000,
        );
      } else if ((err as SerializedError).code === '406') {
        setIsEmailError('Invalid login email address');
      } else {
        notify.error(err.message);
      }
    }
  };

  return (
    <form method="POST" className={styles.login} onSubmit={onEmailCheck} noValidate autoComplete="off">
      <h3 className={styles.loginTitle}>Login</h3>
      <TextInput
        id="enter-your-login-email-address"
        name="email"
        type="email"
        placeholder="Enter Your Login Email"
        value={email}
        error={isEmailError}
        onChange={setEmailValue}
        onClear={clearEmailValue}
        onFocus={() => isEmailError && setIsEmailError('')}
        isDisabled={isLoading}
        autoComplete="email"
      />
      <Button
        type="submit"
        id="continue-login"
        size="48"
        variant="primary"
        isLoading={isLoading}
        className={styles.loginButton}>
        Continue
      </Button>
    </form>
  );
};

export default LoginDefault;
