import { PublicClientApplication } from '@azure/msal-browser';
import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { domainMsalConfig } from 'msal-config';
import { createExtraReducersForResponses, createHttpRequestInitResult, http } from 'helpers/http';
import { IApiRequest, IHttpRequestResult } from 'interfaces';
import { getInitialUserData } from './userSlice';
import { updateCustomerDomain } from './customerDomains';
import { AxiosResponse } from 'axios';

interface IConnectEntraIDPayload {
  state: {
    domainId: string;
    domainName: string;
    isUpdateUser: boolean;
  };
  username: string;
  authToken: string;
  tenantId: string;
}

interface IDisconnectEntraIDPayload {
  isCompanyPage: boolean;
  domainId: string;
}

interface IConnectEntraIDResponse {
  domainOrigin: number;
  domainStatus: number;
}

const msalInstance = new PublicClientApplication(domainMsalConfig);

export const msalLoginPopup = createAsyncThunk('msal/msalLoginPopup', async (apiRequest: IApiRequest, thunkAPI) => {
  const { state, tenantId, accessToken, account } = await msalInstance.loginPopup(apiRequest);
  const parsedState = state ? JSON.parse(state) : {};
  const username = account?.username ?? '';

  return await thunkAPI
    .dispatch(connectEntraID({ state: parsedState, username, authToken: accessToken, tenantId }))
    .unwrap();
});

export const connectEntraID = createAsyncThunk(
  'msal/connectEntraID',
  async ({ state, username, authToken, tenantId }: IConnectEntraIDPayload, thunkAPI) => {
    const { domainId, domainName, isUpdateUser } = state;
    if (domainName.toLowerCase() === username.split('@')[1].toLowerCase()) {
      const response: AxiosResponse<IConnectEntraIDResponse> = await http.post(
        '/api/DomainManagement/ConnectToAzureAd',
        { authToken, domainId, tenantId },
      );
      if (isUpdateUser) {
        await thunkAPI.dispatch(getInitialUserData({ _background: true }));
      } else {
        thunkAPI.dispatch(updateCustomerDomain(response.data));
      }
    } else {
      return thunkAPI.rejectWithValue(`Please use account with ${domainName} domain`);
    }
  },
);

export const disconnectEntraID = createAsyncThunk(
  'msal/disconnectEntraID',
  async ({ isCompanyPage, domainId }: IDisconnectEntraIDPayload, thunkAPI) => {
    const response: AxiosResponse<IConnectEntraIDResponse> = await http.post(
      '/api/DomainManagement/DisconnectAzureAd',
      { domainId },
    );
    if (isCompanyPage) {
      await thunkAPI.dispatch(getInitialUserData({ _background: true }));
    } else {
      thunkAPI.dispatch(updateCustomerDomain(response.data));
    }
  },
);

export const getAuthTokenSilent = createAsyncThunk(
  'msal/getAuthTokenSilent',
  async ({ domainId, domainName }: { domainId: string; domainName: string }, thunkAPI) => {
    const account = msalInstance.getAllAccounts().filter((acc) => acc.username.split('@')[1] === domainName);
    if (account.length) {
      try {
        const response = await msalInstance.acquireTokenSilent({
          scopes: ['User.Read.All', 'Group.Read.All'],
          account: account[0],
          forceRefresh: false,
        });
        return response.accessToken;
      } catch (err: any) {
        const errors = ['consent_required', 'interaction_required', 'login_required', 'invalid_grant'];
        if (errors.includes((err as SerializedError).message ?? '')) {
          const response = await msalInstance.acquireTokenPopup({
            scopes: ['User.Read.All', 'Group.Read.All'],
            prompt: 'select_account',
            state: JSON.stringify({ domainId, domainName, isUpdateUser: false }),
          });
          return response.accessToken;
        }
        return thunkAPI.rejectWithValue({ message: err.message, name: err.name });
      }
    } else {
      try {
        const response = await msalInstance.loginPopup({
          scopes: ['User.Read.All', 'Group.Read.All'],
          prompt: 'select_account',
          state: JSON.stringify({ domainId, domainName, isUpdateUser: false }),
        });
        if (domainName.toLowerCase() === response.account?.username.split('@')[1].toLowerCase()) {
          return response.accessToken;
        } else {
          return thunkAPI.rejectWithValue(new Error(`Please use account with ${domainName} domain`));
        }
      } catch (err: any) {
        return thunkAPI.rejectWithValue({ message: err.message, name: err.name });
      }
    }
  },
);

export const getDomainToken = createAsyncThunk(
  'msal/getDomainToken',
  async ({ domainId, domainName }: { domainId: string; domainName: string }, thunkAPI) => {
    try {
      const response = await msalInstance.acquireTokenPopup({
        scopes: ['User.Read.All', 'Group.Read.All'],
        prompt: 'select_account',
        state: JSON.stringify({ domainId, domainName, isUpdateUser: true }),
      });
      if (domainName.toLowerCase() === response.account?.username.split('@')[1].toLowerCase()) {
        return response.accessToken;
      } else {
        return thunkAPI.rejectWithValue(`Please use account with ${domainName} domain`);
      }
    } catch (err: any) {
      return thunkAPI.rejectWithValue({ message: err.message, name: err.name });
    }
  },
);

interface IMsalState {
  authToken: { [key: string]: string } | null;
  msalLoginPopupRequest: IHttpRequestResult<void>;
  connectEntraIDRequest: IHttpRequestResult<void>;
  disconnectEntraIDRequest: IHttpRequestResult<void>;
  getAuthTokenSilentRequest: IHttpRequestResult<string>;
  getDomainTokenRequest: IHttpRequestResult<string>;
}

const initialState: IMsalState = {
  authToken: null,
  msalLoginPopupRequest: createHttpRequestInitResult(),
  connectEntraIDRequest: createHttpRequestInitResult(),
  disconnectEntraIDRequest: createHttpRequestInitResult(),
  getAuthTokenSilentRequest: createHttpRequestInitResult(),
  getDomainTokenRequest: createHttpRequestInitResult(),
};

export const msal = createSlice({
  name: 'msal',
  initialState,
  reducers: {
    setAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    createExtraReducersForResponses<IMsalState>(builder, msalLoginPopup, 'msalLoginPopupRequest');
    createExtraReducersForResponses<IMsalState>(builder, connectEntraID, 'connectEntraIDRequest', (state, action) => {
      const {
        state: { domainId },
        authToken,
      } = action.meta.arg as IConnectEntraIDPayload;
      state.authToken = { [domainId]: authToken };
    });
    createExtraReducersForResponses<IMsalState>(builder, disconnectEntraID, 'disconnectEntraIDRequest');
    createExtraReducersForResponses<IMsalState>(builder, getAuthTokenSilent, 'getAuthTokenSilentRequest');
    createExtraReducersForResponses<IMsalState>(builder, getDomainToken, 'getDomainTokenRequest');
  },
});

export const { setAuthToken } = msal.actions;

export default msal.reducer;
