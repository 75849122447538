import { memo } from 'react';
import cn from 'classnames';
import styles from './SwitchCheckbox.module.scss';

interface Props {
  checked?: boolean;
  id?: string;
  disabled?: boolean;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: any;
}

const SwitchCheckbox = ({ checked, id, disabled, name, onChange, className }: Props) => (
  <label id={id} className={cn(styles.switchCheckbox, { [className]: className })}>
    <input
      type="checkbox"
      className={styles.switchCheckboxInput}
      name={name}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
    />
    <span className={styles.switchCheckboxSlider} />
  </label>
);

SwitchCheckbox.displayName = 'SwitchCheckbox';

export default memo(SwitchCheckbox);
