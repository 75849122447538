import { useRef } from 'react';
import cn from 'classnames';
import { useObserver } from 'hooks';
import { useAppSelector } from 'store';
import { Button, DropdownArray } from 'components';
import { handleUserCase } from '../../utils/shortcutUtils';
import { IShortcutMgmtData, IShortcutMgmtUser } from 'store/slices/securityMgmt/shortcutMgmt';
import { ChangeShortcutUserType } from '../../ShortcutMgmt';
import styles from './HeaderActions.module.scss';
import { DropdownOption } from 'components/DropdownArray/DropdownArray';

interface Props {
  shortcutData: IShortcutMgmtData;
  selectedUsers: IShortcutMgmtUser[];
  setSelectedUsers: (selectedUsers: any[]) => void;
  onChangeShortcutForUser: (
    isHeaderBtn: boolean,
    user: IShortcutMgmtUser,
    type: ChangeShortcutUserType,
    actionType?: string,
    extra?: boolean,
  ) => void;
  isHeaderBtnLoading: boolean;
  isDomainOnPremise: boolean;
}

const HeaderActions = ({
  shortcutData,
  selectedUsers,
  setSelectedUsers,
  onChangeShortcutForUser,
  isHeaderBtnLoading,
  isDomainOnPremise,
}: Props) => {
  const isShortcutMfaRequired = useAppSelector((state) => state.shortcutMgmt.shortcutMgmtData?.isShortcutMfaRequired);

  const counterRef = useRef<HTMLDivElement | null>(null);
  const isSticky: boolean = useObserver(counterRef, !!selectedUsers.length, { threshold: 1 });

  const getActionType = () => {
    switch (true) {
      case selectedUsers.every((i) => !i.isDesktopShortcutEnabled):
        return 'Enable Desktop Shortcut';
      case selectedUsers.every((i) => i.isDesktopShortcutEnabled):
        return 'Disable Desktop Shortcut';
      default:
        return 'Edit Desktop Shortcut';
    }
  };

  const actionType = handleUserCase(selectedUsers);

  const dropdownOptions = [];
  if (actionType === 'disabledAll') {
    if (isShortcutMfaRequired) {
      dropdownOptions.push({ id: 'isDesktopShortcutEnabled', value: 'Enable Desktop Shortcut' });
    } else {
      dropdownOptions.push(
        { id: 'isDesktopShortcutEnabled', value: 'Enable Desktop Shortcut' },
        {
          id: 'isShortcutMfaRequired',
          value: 'Enable Desktop Shortcut with MFA Code Required',
          extra: true,
        },
      );
    }
  }
  if (actionType === 'enabledAll') {
    dropdownOptions.push(
      { id: 'isDesktopShortcutEnabled', value: 'Disable Desktop Shortcut' },
      { id: 'isShortcutMfaRequired', value: 'Disable MFA Code' },
    );
  }
  if (actionType === 'enabledShortcut') {
    dropdownOptions.push(
      { id: 'isDesktopShortcutEnabled', value: 'Disable Desktop Shortcut' },
      { id: 'isShortcutMfaRequired', value: 'Require MFA Code' },
    );
  }

  return (
    <header
      className={cn(styles.headerActions, { [styles.active]: selectedUsers.length, [styles.sticked]: isSticky })}
      ref={counterRef}>
      <div className={styles.headerActionsCount}>
        {selectedUsers.length}/{shortcutData.pageMeta?.TotalCount || 0} Selected
      </div>
      <div className={styles.headerActionsWrap}>
        <Button
          variant="secondary"
          size="40"
          onClick={() => setSelectedUsers([])}
          className={styles.headerActionsButton}>
          Unselect All
        </Button>
        {dropdownOptions.length > 1 && isDomainOnPremise ? (
          <DropdownArray
            id="settings-save"
            isButton
            btnTitle="Manage Desktop Shortcut"
            options={dropdownOptions}
            width="15.8em"
            onChange={(value: string | DropdownOption) => {
              value = value as DropdownOption;
              const id = value.id as ChangeShortcutUserType;
              onChangeShortcutForUser(true, {} as IShortcutMgmtUser, id, actionType);
            }}
            optionsClassName={cn(styles.dropdownOptions, {
              [styles.short]: actionType === 'enabledAll' || actionType === 'enabledShortcut',
            })}
          />
        ) : (
          <Button
            variant="primary"
            className={styles.headerActionsButton}
            isLoading={isHeaderBtnLoading}
            size="40"
            onClick={() =>
              onChangeShortcutForUser(true, {} as IShortcutMgmtUser, 'isDesktopShortcutEnabled', actionType, false)
            }>
            {getActionType()}
          </Button>
        )}
      </div>
    </header>
  );
};

export default HeaderActions;
