interface IQuery {
  pageNumber?: number | string;
  pageSize?: number;
  orderBy?: string;
  searchQuery?: string;
}

export const queryFormatter = (url: string, query?: IQuery) => {
  return query
    ? `${url}${url.includes('?') ? '&' : '?'}${Object.entries(query)
        .map(i => `${i[0]}=${i[1]}`)
        .join('&')}`
    : url;
};
