import { Button, Modal } from 'components';
import styles from './ModalConfirmChangeDomain.module.scss';
import { useAppSelector } from 'store';

interface Props {
  isOpen: boolean;
  onRequestClose: (domainName: string) => void;
  onDiscardChanges: (domain: string) => void;
  onSaveSettings: (domain: string, isSaveAs: string) => void;
  isCustomDomain: boolean;
  domainToChange: string;
}

const ModalConfirmChangeDomain = ({
  isOpen,
  onRequestClose,
  onDiscardChanges,
  onSaveSettings,
  isCustomDomain,
  domainToChange,
}: Props) => {
  const { isLoading } = useAppSelector((state) =>
    isCustomDomain ? state.settings.saveCustomerDomainSettingsRequest : state.settings.saveDomainSettingsRequest,
  );
  const { currentDomain } = useAppSelector((state) => state.settings);
  const saveSettings = () => {
    onSaveSettings(domainToChange, 'true');
  };

  const saveAsSettings = () => {
    onSaveSettings('Save As', domainToChange);
    onRequestClose(domainToChange);
  };
  const discardChanges = () => onDiscardChanges(domainToChange);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onRequestClose(domainToChange)}
      contentLabel="Save changes?"
      actionFunc={saveSettings}
      cancelButtonClass={styles.cancelButton}
      cancelAction={saveAsSettings}
      actionTitle="Save"
      variantBtn="primary"
      actionLoading={isLoading}
      shouldCloseOnOverlayClick={false}
      cancelTitle="Save As">
      <Button
        id="setting-discard-all"
        variant="deleteOutline"
        size="32"
        className={styles.discardButton}
        onClick={discardChanges}>
        Discard All
      </Button>
      Do you want to save changes for <strong>{currentDomain}</strong> {isCustomDomain && 'domain'}?
    </Modal>
  );
};

export default ModalConfirmChangeDomain;
