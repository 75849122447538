import { DomainStatusMsg, Link, StatusMessage } from 'components';
import { getDomainADStatus, isSentryDeleted } from 'helpers';
import { corpAdmin, corpTechAdmin, custAdmin, freePersonalUser, personalUser, spAdmin, spTechAdmin } from 'consts';
import { IDomain } from 'interfaces';
import { IMyDomainMachines } from 'store/slices/workspace';

interface Props {
  domain: IDomain;
  userRole: string;
  myDomain: IMyDomainMachines;
}

const EmptyPages = ({ domain, userRole, myDomain }: Props) => {
  const { isDomainAzure, isDomainOnPremise, isDomainHybryd } = getDomainADStatus(domain);
  const sentryDeleted = isSentryDeleted(domain);

  if ([spAdmin, corpAdmin].includes(userRole)) {
    if ((isDomainHybryd || isDomainOnPremise) && sentryDeleted) {
      return <DomainStatusMsg domainData={domain} isSentryDeleted={sentryDeleted} />;
    }
    if (myDomain.pageMeta?.AllMachinesCount) {
      return (
        <StatusMessage header="No computers reporting in">
          {isDomainAzure ? (
            <p>
              Change view or&nbsp;
              <Link href="https://www.trugrid.com/docs/addsecureconnect" external bold>
                add TruGrid SecureConnect
              </Link>
            </p>
          ) : (
            <p>
              Change view or&nbsp;
              <Link href="https://www.trugrid.com/docs/assignusertodesktop" external bold>
                add computers
              </Link>
            </p>
          )}
        </StatusMessage>
      );
    }
    return (
      <StatusMessage header="No computers reporting in">
        {isDomainAzure ? (
          <p>
            Please&nbsp;
            <Link href="https://www.trugrid.com/docs/addsecureconnect" external bold>
              add TruGrid SecureConnect
            </Link>
          </p>
        ) : (
          <p>
            Please check Active Directory or&nbsp;
            <Link href="https://www.trugrid.com/docs/installsentry" external bold>
              add computers
            </Link>
          </p>
        )}
      </StatusMessage>
    );
  }
  if ([spTechAdmin, corpTechAdmin].includes(userRole)) {
    if (myDomain.queryConfig?.assigned?.toLowerCase() === 'all') {
      return (
        <StatusMessage header="No computers reporting in">
          {isDomainAzure ? (
            <p>
              Please&nbsp;
              <Link href="https://www.trugrid.com/docs/addsecureconnect" external bold>
                add TruGrid SecureConnect
              </Link>
            </p>
          ) : (
            <p>
              Please check Active Directory or&nbsp;
              <Link href="https://www.trugrid.com/docs/installsentry" external bold>
                Manage assignments
              </Link>
            </p>
          )}
        </StatusMessage>
      );
    }
    if (myDomain.queryConfig.searchQuery) {
      return <StatusMessage>No results found</StatusMessage>;
    }
    return (
      <StatusMessage header="No computers assigned">
        {isDomainAzure ? (
          <p>
            Change view or&nbsp;
            <Link href="https://www.trugrid.com/docs/addsecureconnect" external bold>
              add TruGrid SecureConnect
            </Link>
          </p>
        ) : (
          <p>
            Change view or&nbsp;
            <Link href="https://www.trugrid.com/docs/assignusertodesktop" external bold>
              add computers
            </Link>
          </p>
        )}
      </StatusMessage>
    );
  }

  if ([personalUser, freePersonalUser].includes(userRole)) {
    return (
      <StatusMessage header="No personal computers reporting in">
        <p>
          Please&nbsp;
          <Link href="https://www.trugrid.com/docs/addsecureconnect" external bold>
            add TruGrid SecureConnect
          </Link>
        </p>
      </StatusMessage>
    );
  }

  if (userRole === custAdmin) {
    const link = isDomainAzure
      ? 'https://www.trugrid.com/docs/addsecureconnect'
      : 'https://www.trugrid.com/docs/assignusertodesktop';

    return (
      <StatusMessage header="No computers assigned">
        <p>
          Please&nbsp;
          <Link href={link} external bold>
            Manage assignments
          </Link>
        </p>
      </StatusMessage>
    );
  }

  return (
    <StatusMessage header="No computers assigned">
      <p>Please contact your company administrator for assistance</p>
    </StatusMessage>
  );
};

export default EmptyPages;
