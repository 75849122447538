import cn from 'classnames';
import { MsLogoIcon, NotAllowIcon, SuccesCircleIcon } from 'assets/img';
import { getDomainADStatus } from 'helpers';
import Button from 'components/Button/Button';
import { notify } from 'utils';
import { toast } from 'react-toastify';
import { corpTechAdmin, spTechAdmin } from 'consts';
import { IDomain } from 'interfaces';
import { IDomainStatus } from 'store/slices/domainMgmt';
import { useAppDispatch, useAppSelector } from 'store';
import { msalLoginPopup } from 'store/slices/msal';
import { SerializedError } from '@reduxjs/toolkit';
import styles from '../../ActiveDirectorySetup.module.scss';

interface Props {
  domainData: IDomainStatus;
  domain: IDomain;
  userRole: string;
  isCompanyPage: boolean;
  toggleShowModal: (type: string) => void;
  loadInitDomainData: () => Promise<void>;
}

const AzureAD = ({ domainData, domain, userRole, toggleShowModal, isCompanyPage, loadInitDomainData }: Props) => {
  const dispatch = useAppDispatch();
  const { isDomainHybryd, isDomainAzure } = getDomainADStatus(domainData);
  const { isLoading } = useAppSelector((state) => state.msal.msalLoginPopupRequest);
  const { dnsName: domainName } = domain;
  const { id: domainId } = domain;

  const connectToAzure = async () => {
    try {
      const state = JSON.stringify({ domainId, domainName, isUpdateUser: isCompanyPage });
      await dispatch(
        msalLoginPopup({
          scopes: ['User.Read.All', 'Group.Read.All'],
          prompt: 'select_account',
          state: state,
        }),
      ).unwrap();
      if (!isCompanyPage) {
        await loadInitDomainData();
      }
      toast.success('Successfully connected');
    } catch (err: any) {
      if (!(err as SerializedError).message?.includes('user_cancelled')) {
        notify.error(err.message);
      }
    }
  };

  return (
    <tr className={styles.domainMgmtTableWrap}>
      <td className={styles.domainMgmtTableItem}>
        <div className={styles.domainMgmtTableItemName}>Microsoft Entra ID</div>
      </td>
      <td className={styles.domainMgmtTableItem}>
        <div
          className={cn([styles.domainMgmtTableItemStatus], {
            [styles.notConnected]: !(isDomainAzure || isDomainHybryd),
          })}>
          {isDomainAzure || isDomainHybryd ? <SuccesCircleIcon /> : <NotAllowIcon />}
          <span>{isDomainAzure || isDomainHybryd ? 'Connected' : 'Not connected'}</span>
        </div>
      </td>
      <td className={styles.domainMgmtTableItem}>
        {!(userRole === corpTechAdmin || (userRole === spTechAdmin && isCompanyPage)) &&
          (isDomainAzure || isDomainHybryd ? (
            <Button
              size="32"
              variant="secondary"
              icon={<MsLogoIcon />}
              onClick={() => toggleShowModal('azure')}
              className={styles.domainMgmtTableItemAzureButton}>
              Reset Entra ID
            </Button>
          ) : (
            <Button
              size="32"
              variant="secondary"
              icon={<MsLogoIcon />}
              isDisabled={isLoading}
              onClick={connectToAzure}
              className={styles.domainMgmtTableItemAzureButton}>
              {isLoading ? 'Connecting...' : 'Connect to Entra ID'}
            </Button>
          ))}
      </td>
    </tr>
  );
};

export default AzureAD;
