import { Modal } from 'components';
import { useAppDispatch, useAppSelector } from 'store';
import { AttentionIcon } from 'assets/img';
import { changeMfaRequiredForDomain } from 'store/slices/securityMgmt/shortcutMgmt';
import { notify } from 'utils';
import styles from './ToggleMfaRequiredModal.module.scss';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  domainId: string;
}

const ToggleMfaRequiredModal = ({ isOpen, onRequestClose, domainId }: Props) => {
  const dispatch = useAppDispatch();
  const isShortcutMfaRequired = useAppSelector((state) => state.shortcutMgmt.shortcutMgmtData?.isShortcutMfaRequired);
  const { isLoading } = useAppSelector((state) => state.shortcutMgmt.changeMfaRequiredForDomainRequest);

  const onChangeMfaRequiredForDomain = async () => {
    try {
      await dispatch(changeMfaRequiredForDomain({ domainId })).unwrap();
      const actionType = !isShortcutMfaRequired;
      notify.success(
        actionType ? 'MFA is required for Desktop Shortcuts' : 'MFA is not required for Desktop Shortcuts',
      );
      onRequestClose();
    } catch (err: any) {
      notify.error(err.message);
      onRequestClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={
        <div className={styles.title}>
          {isShortcutMfaRequired ? (
            <>Disable "Require MFA for Shortcut"?</>
          ) : (
            <>
              <AttentionIcon />
              Enable "Require MFA for Shortcut"?
            </>
          )}
        </div>
      }
      actionFunc={onChangeMfaRequiredForDomain}
      actionTitle={isShortcutMfaRequired ? 'Disable' : 'Enable'}
      variantBtn={!isShortcutMfaRequired ? 'delete' : 'primary'}
      actionLoading={isLoading}>
      {isShortcutMfaRequired ? (
        <p>
          Disabling this feature will no longer require MFA code when using Shortcuts. Are you sure you wish to
          continue?
        </p>
      ) : (
        <p>Enabling this feature will turn on MFA for all user logins that have Shortcuts enabled</p>
      )}
    </Modal>
  );
};

export default ToggleMfaRequiredModal;
