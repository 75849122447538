import { Modal } from 'components';
import { needToShowResetPhone, userName } from './utils/resetMfaUtils';
import { useAppSelector } from 'store';
import { IModalData, TypeAction } from '../../MfaMgmt';

interface Props {
  showResetMfaModal: boolean;
  toggleShowResetMfaModal: (type: TypeAction) => void;
  modalData: IModalData;
  onChangeMfa: (type: TypeAction, data: IModalData, isResetPhone: boolean) => void;
  setIsResetPhone: React.Dispatch<React.SetStateAction<boolean>>;
  isResetPhone: boolean;
}

const ResetMfaModal = ({
  showResetMfaModal,
  toggleShowResetMfaModal,
  modalData,
  onChangeMfa,
  setIsResetPhone,
  isResetPhone,
}: Props) => {
  const { isLoading } = useAppSelector((store) => store.mfaMgmt.changeUserMfaStatusRequest);

  const showResetPhone = needToShowResetPhone(modalData.usersToUpdate);
  const user = userName(modalData.usersToUpdate);
  const resetMfa = () => onChangeMfa('Reset MFA', modalData, true);

  const onChangeSwitcher = () => setIsResetPhone((value: boolean) => !value);
  const switcherTitle = showResetPhone ? 'Also reset phone number' : '';

  return (
    <Modal
      isOpen={showResetMfaModal}
      onRequestClose={() => toggleShowResetMfaModal(modalData.type)}
      contentLabel="Reset MFA"
      actionLoading={isLoading}
      actionTitle="Reset MFA"
      actionFunc={resetMfa}
      onChangeSwitcher={onChangeSwitcher}
      isSwitcherChecked={isResetPhone}
      switcherTitle={switcherTitle}
      style={{ content: { width: '35em' } }}>
      <div className="truncate-text">
        By clicking <b>RESET</b>, you will reset MFA for {user}
      </div>
    </Modal>
  );
};

export default ResetMfaModal;
