import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { http, createHttpRequestInitResult, createExtraReducersForResponses } from 'helpers/http';
import { IDomainApp, IDomainMachine, IHttpRequestResult, IPageMetaWorkspace, IQueryConfig, ISentry } from 'interfaces';
import { RootState } from 'store/store';
import { queryFormatter } from 'utils';

// interface IRequest {
//   _background?: boolean;
// }

export interface IMyDomainMachines {
  data: IDomainMachine[];
  pageMeta: IPageMetaWorkspace;
  queryConfig: IQueryConfig;
}

export interface IMyApps {
  data: IDomainApp[];
  pageMeta: IPageMetaWorkspace;
  queryConfig: IQueryConfig;
}

export interface IGetConnectorUriPayload {
  machineId: string;
  userLogin: any;
  passwordHash: any;
  appName?: string;
}

export const fetchSecureConnects = createAsyncThunk('workspace/fetchSecureConnects', async (query?: IQueryConfig) => {
  const queryConfig = {
    pageNumber: 1,
    pageSize: 10,
    kind: 'All',
    orderBy: 'hostname asc',
    searchQuery: '',
    ...query,
  };
  const response: AxiosResponse<IDomainMachine[]> = await http.get(
    queryFormatter('/api/v2/Machines/DomainMachines', queryConfig),
  );
  return response.data;
});

export const getDomainSetries = createAsyncThunk('workspace/getDomainSetries', async (domainId: string) => {
  const response: AxiosResponse<ISentry[]> = await http.get(`/api/sentry/GetSentriesForDomain?domainId=${domainId}`);
  return response.data;
});

export const getCustomerAssignedMachines = createAsyncThunk(
  'workspace/getCustomerAssignedMachines',
  async ({ query }: { query?: IQueryConfig }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const queryConfig = state.workspace.getCustomerAssignedMachinesRequest.data?.queryConfig || {};
    const queryConfigNew = { ...queryConfig, ...query };

    const response: AxiosResponse<IDomainMachine[]> = await http.get(
      queryFormatter('/api/v2/Machines/AssignedMachines', queryConfigNew),
    );
    const pageMeta = response?.headers['x-pagination'] ? JSON.parse(response.headers['x-pagination']) : null;
    const data: IMyDomainMachines = {
      data: response.data,
      pageMeta,
      queryConfig: queryConfigNew,
    };
    return data;
  },
);

export const getMyDomainMachines = createAsyncThunk(
  'workspace/getMyDomainMachines',
  async ({ query }: { query?: IQueryConfig }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const queryConfig = state.workspace.getMyDomainMachinesRequest.data?.queryConfig || {};
    const queryConfigNew = { ...queryConfig, ...query };
    const response: AxiosResponse<IDomainMachine[]> = await http.get(
      queryFormatter('/api/v2/Machines/DomainMachines', queryConfigNew),
    );
    const pageMeta = response?.headers['x-pagination'] ? JSON.parse(response.headers['x-pagination']) : null;
    const data: IMyDomainMachines = {
      data: response.data,
      pageMeta,
      queryConfig: queryConfigNew,
    };
    return data;
  },
);

export const getMyDomainApps = createAsyncThunk(
  'workspace/getMyDomainApps',
  async ({ query }: { query?: IQueryConfig }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const queryConfig = state.workspace.getMyDomainAppsRequest.data?.queryConfig || {};
    const queryConfigNew = { ...queryConfig, ...query };

    const response: AxiosResponse<IDomainApp[]> = await http.get(
      queryFormatter('/api/v2/MachinesApplications/GetAssignedPublishedApplications', queryConfigNew),
    );
    const pageMeta = response?.headers['x-pagination'] ? JSON.parse(response.headers['x-pagination']) : null;
    const data: IMyApps = {
      data: response.data,
      pageMeta,
      queryConfig: queryConfigNew,
    };
    return data;
  },
);

export const getPersonalMachines = createAsyncThunk(
  'workspace/getPersonalMachines',
  async ({ query }: { query?: IQueryConfig }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const queryConfig = state.workspace.getPersonalMachinesRequest.data?.queryConfig || {};
    const queryConfigNew = { ...queryConfig, ...query };
    const response: AxiosResponse<IDomainMachine[]> = await http.get(
      queryFormatter('/api/v2/Machines/PersonalMachines', queryConfigNew),
    );
    const pageMeta = response?.headers['x-pagination'] ? JSON.parse(response.headers['x-pagination']) : null;
    const data: IMyDomainMachines = {
      data: response.data,
      pageMeta,
      queryConfig: queryConfigNew,
    };
    return data;
  },
);

export const getCustomerDomainMachines = createAsyncThunk(
  'workspace/getCustomerDomainMachines',
  async ({ query }: { query?: IQueryConfig }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const queryConfig = state.workspace.getCustomerDomainMachinesRequest.data?.queryConfig || {};
    const queryConfigNew = { ...queryConfig, ...query };
    const response: AxiosResponse<IDomainMachine[]> = await http.get(
      queryFormatter('/api/v2/Machines/CustomerDomainMachines', queryConfigNew),
    );
    const pageMeta = response?.headers['x-pagination'] ? JSON.parse(response.headers['x-pagination']) : null;
    const data: IMyDomainMachines = {
      data: response.data,
      pageMeta,
      queryConfig: queryConfigNew,
    };
    return data;
  },
);

export const getCustomerDomainApps = createAsyncThunk(
  'workspace/getCustomerDomainApps',
  async ({ query }: { query?: IQueryConfig }, thunkAPI) => {
    const state: RootState = thunkAPI.getState() as RootState;
    const queryConfig = state.workspace.getCustomerDomainAppsRequest.data?.queryConfig || {};
    const queryConfigNew = { ...queryConfig, ...query };
    const response: AxiosResponse<IDomainApp[]> = await http.get(
      queryFormatter('/api/v2/MachinesApplications/GetCustomerPublishedApplications', queryConfigNew),
    );
    const pageMeta = response?.headers['x-pagination'] ? JSON.parse(response.headers['x-pagination']) : null;
    const data: IMyApps = {
      data: response.data,
      pageMeta,
      queryConfig: queryConfigNew,
    };
    return data;
  },
);

export const getConnectorUri = createAsyncThunk('workspace/getConnectUri', async (options: IGetConnectorUriPayload) => {
  const response: AxiosResponse<{ connectorUri: string }> = await http.post('/api/Connectors/GetConnectorUri', options);
  return response.data;
});

export const getRdpExtensionId = createAsyncThunk('workspace/getRdpExtensionId', async () => {
  const { host } = window.location;
  const response: AxiosResponse<string> = await http.get('/api/extensions/GetRdpExtnId', {
    params: {
      brandingHost: !process.env.REACT_APP_PUBLIC_URL?.includes(host) ? host : null,
    },
  });
  return response.data;
});

export const updateSentry = createAsyncThunk('workspace/updateSentry', async (sentryIds: string[]) => {
  await http.post('api/sentry/UpdateSentries', sentryIds);
});

interface IWorkspaceState {
  hasSecureConnects: boolean;
  sentries: ISentry[] | null;
  myDomainMachines: IMyDomainMachines | null;
  myApps: IMyApps | null;
  custMachines: IMyDomainMachines | null;
  custApps: IMyApps | null;
  currentDeviceLoading: string;
  rdpExtensionId: string;
  checkUsersSecureConnectsRequest: IHttpRequestResult<IDomainMachine[]>;
  getDomainSetriesRequest: IHttpRequestResult<ISentry[]>;
  getMyDomainMachinesRequest: IHttpRequestResult<IMyDomainMachines>;
  getCustomerAssignedMachinesRequest: IHttpRequestResult<IMyDomainMachines>;
  getPersonalMachinesRequest: IHttpRequestResult<IMyDomainMachines>;
  getMyDomainAppsRequest: IHttpRequestResult<IMyApps>;
  getCustomerDomainMachinesRequest: IHttpRequestResult<IMyDomainMachines>;
  getCustomerDomainAppsRequest: IHttpRequestResult<IMyApps>;
  getConnectorUriRequest: IHttpRequestResult<string>;
  getRdpExtensionIdRequest: IHttpRequestResult<string>;
  updateSentryRequest: IHttpRequestResult<void>;
}

const initialState: IWorkspaceState = {
  hasSecureConnects: false,
  sentries: null,
  myDomainMachines: null,
  myApps: null,
  custMachines: null,
  custApps: null,
  currentDeviceLoading: '',
  rdpExtensionId: '',
  checkUsersSecureConnectsRequest: createHttpRequestInitResult(),
  getDomainSetriesRequest: createHttpRequestInitResult(),
  getMyDomainMachinesRequest: createHttpRequestInitResult(),
  getCustomerAssignedMachinesRequest: createHttpRequestInitResult(),
  getPersonalMachinesRequest: createHttpRequestInitResult(),
  getMyDomainAppsRequest: createHttpRequestInitResult(),
  getCustomerDomainMachinesRequest: createHttpRequestInitResult(),
  getCustomerDomainAppsRequest: createHttpRequestInitResult(),
  getConnectorUriRequest: createHttpRequestInitResult(),
  getRdpExtensionIdRequest: createHttpRequestInitResult(),
  updateSentryRequest: createHttpRequestInitResult(),
};

export const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    updateMachines: (state, { payload }) => {
      state.myDomainMachines = payload;
    },
    updateApps: (state, { payload }) => {
      state.myApps = payload;
    },
    updateCustMachines: (state, { payload }) => {
      state.custMachines = payload;
    },
    updateCustApps: (state, { payload }) => {
      state.custApps = payload;
    },
    setCurrentDeviceLoading: (state, { payload }) => {
      state.currentDeviceLoading = payload;
    },
  },
  extraReducers: (builder) => {
    createExtraReducersForResponses(
      builder,
      fetchSecureConnects,
      'checkUsersSecureConnectsRequest',
      (state, action) => {
        state.hasSecureConnects = action.payload.length > 0;
      },
    );
    createExtraReducersForResponses(builder, getDomainSetries, 'getDomainSetriesRequest', (state, action) => {
      state.sentries = action.payload;
    });
    createExtraReducersForResponses(builder, getMyDomainMachines, 'getMyDomainMachinesRequest', (state, action) => {
      state.myDomainMachines = action.payload;
    });
    createExtraReducersForResponses(
      builder,
      getCustomerAssignedMachines,
      'getCustomerAssignedMachinesRequest',
      (state, action) => {
        state.myDomainMachines = action.payload;
      },
    );
    createExtraReducersForResponses(builder, getPersonalMachines, 'getPersonalMachinesRequest', (state, action) => {
      state.myDomainMachines = action.payload;
    });
    createExtraReducersForResponses(builder, getMyDomainApps, 'getMyDomainAppsRequest', (state, action) => {
      state.myApps = action.payload;
    });
    createExtraReducersForResponses(
      builder,
      getCustomerDomainMachines,
      'getCustomerDomainMachinesRequest',
      (state, action) => {
        state.custMachines = action.payload;
      },
    );
    createExtraReducersForResponses(builder, getCustomerDomainApps, 'getCustomerDomainAppsRequest', (state, action) => {
      state.custApps = action.payload;
    });
    createExtraReducersForResponses(builder, getConnectorUri, 'getConnectorUriRequest');
    createExtraReducersForResponses(builder, getRdpExtensionId, 'getRdpExtensionIdRequest');
    createExtraReducersForResponses(builder, updateSentry, 'updateSentryRequest');
  },
});

export const { updateMachines, updateApps, updateCustMachines, updateCustApps, setCurrentDeviceLoading } =
  workspaceSlice.actions;

export default workspaceSlice.reducer;
