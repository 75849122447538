import { corpAdmin, corpTechAdmin, custAdmin, spAdmin, spTechAdmin, techAdmin } from 'consts';

export const handleVisibleTabs = (
  isDomainOnPremise: boolean,
  isDomainNotActivated: boolean,
  sentryDeleted: boolean,
  userRole: string,
  isCompanyPage: boolean,
) => {
  const isVisibleMfaManagement = isDomainOnPremise && !isDomainNotActivated && !sentryDeleted;
  const isVisiblePassMgmt =
    [techAdmin, spTechAdmin, spAdmin, custAdmin, corpAdmin, corpTechAdmin].includes(userRole) &&
    isDomainOnPremise &&
    !isDomainNotActivated &&
    !sentryDeleted;
  const isVisibleDesktopMgmt = !isDomainNotActivated && !sentryDeleted;
  const isVisibleGeoBlocking =
    ([spAdmin, corpAdmin].includes(userRole) && !isDomainNotActivated && !sentryDeleted) ||
    (userRole === spTechAdmin && !isCompanyPage);
  const isVisibleIpWhiteList = [spAdmin, corpAdmin].includes(userRole) && isCompanyPage;

  return {
    isVisibleMfaManagement,
    isVisiblePassMgmt,
    isVisibleDesktopMgmt,
    isVisibleGeoBlocking,
    isVisibleIpWhiteList,
  };
};
