import cn from 'classnames';
import { useAppDispatch, useAppSelector } from 'store';
import { updateBranding } from 'store/slices/branding';
import { Button } from 'components';
import { notify } from 'utils';
import { CircleCloseIcon, UploadIcon } from 'assets/img';
import styles from './UploadLogo.module.scss';

const acceptedFileTypes = 'image/jpeg, image/gif, image/png';

const UploadLogo = () => {
  const { branding } = useAppSelector((state) => state.branding);
  const dispatch = useAppDispatch();

  const onLogoUpload = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const { files } = event.currentTarget;
    if (!files || !files.length) return;

    if (files.length > 1) {
      notify.error('Only one file is allowed');
      return;
    }

    const file = files[0];

    if (!file) {
      notify.error('No valid file selected');
      return;
    }

    const acceptedFileTypes: string[] = ['image/jpeg', 'image/png', 'image/gif'];

    if (!acceptedFileTypes.includes(file.type.toLowerCase())) {
      notify.error(
        `File should be one of such types: ${acceptedFileTypes.map((type) => type.replace('image/', '.')).join(', ')}`,
      );
      return;
    }

    if (file.size > 1048576) {
      notify.error('File should be up to 1 Mb');
      return;
    }

    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target && typeof e.target.result === 'string') {
        const fileBytes64 = e.target.result.replace(/^data:image\/[a-z]+;base64,/, '');
        dispatch(
          updateBranding({
            logo: fileBytes64,
            logoName: file.name,
            hasLogoChanged: true,
          }),
        );
      }
    };

    reader.readAsDataURL(file);
  };

  const onLogoRemove = () => {
    dispatch(
      updateBranding({
        logo: null,
        logoName: 'default',
        hasLogoChanged: false,
      }),
    );
  };

  return (
    <div className={styles.uploadLogoRow}>
      <div className={styles.uploadLogoLabel}>
        <p className={styles.uploadLogoLabelTitle}>Logo</p>
        <p className={styles.uploadLogoLabelInfo}>JPG, PNG or GIF (max size: 1 Mb)</p>
      </div>
      {!branding.modified?.logo ? (
        <>
          <label htmlFor="profile-logo-upload" className={styles.uploadLogoButton} title="Upload logo">
            <UploadIcon /> Upload logo
          </label>
          <input
            id="profile-logo-upload"
            type="file"
            className={styles.uploadLogoInput}
            accept={acceptedFileTypes}
            onChange={onLogoUpload}
          />
        </>
      ) : (
        <div className={styles.uploadLogoRow}>
          <div className={cn(styles.uploadLogoButton, styles.uploaded)}>
            <img
              className={styles.uploadLogoImage}
              src={`data:image/png;base64,${branding.modified.logo}`}
              alt="Branding"
            />
            <Button
              variant="link"
              id="clear-logo"
              icon={<CircleCloseIcon />}
              className={styles.uploadLogoRemove}
              onClick={onLogoRemove}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadLogo;
