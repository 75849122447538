import { useEffect } from 'react';
import { notify } from 'utils';
import { getDomainStatus, isSentryDeleted } from 'helpers';
import { Loader, DomainStatusMsg } from 'components';
import NoData from './components/NoData/NoData';
import NoCustDomainsMessage from './components/NoCustDomainsMessage/NoCustDomainsMessage';
import ManageAssignmentsModal from './components/ManageAssignmentsModal/ManageAssignmentsModal';
import { IDomain } from 'interfaces';
import { useAppDispatch, useAppSelector } from 'store';
import { IActiveDirectoryUser, getActiveDirectoryData } from 'store/slices/activeDirectory';
import DataTable from './components/DataTable/DataTable';
import useModal from 'hooks/useModal';
import styles from './ActiveDirectory.module.scss';

interface Props {
  domain: IDomain;
  isDomainNotActivated: boolean;
}

const ActiveDirectory = ({ domain, isDomainNotActivated }: Props) => {
  const dispatch = useAppDispatch();

  const { isLoading } = useAppSelector((state) => state.activeDirectory.getActiveDirectoryDataRequest);
  const { activeDirectoryData } = useAppSelector((state) => state.activeDirectory);

  const { isOpen, openModal, closeModal, modalData } = useModal<IActiveDirectoryUser>();

  const sentryDeleted = isSentryDeleted(domain);
  const { isDomainActive } = getDomainStatus(domain);

  const fetchData = async () => {
    try {
      const queryConfig = {
        pageNumber: 1,
        pageSize: 10,
        orderBy: 'name asc',
        searchQuery: '',
      };
      await dispatch(getActiveDirectoryData({ query: queryConfig })).unwrap();
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  useEffect(() => {
    if (isDomainActive && !sentryDeleted) {
      fetchData();
    }
  }, [isDomainActive, sentryDeleted]);

  if (isDomainNotActivated || sentryDeleted) {
    return (
      <DomainStatusMsg domainData={domain} isSentryDeleted={sentryDeleted} pageName="Active Directory Federation" />
    );
  }

  if (isLoading || !activeDirectoryData) return <Loader id="loading-active-directory" />;

  if (activeDirectoryData?.isNoData) {
    return <NoData isNoData={activeDirectoryData.noDataValue!} />;
  }

  return (
    <div className={styles.activeDirectory}>
      {activeDirectoryData?.isNoCustomerDomains && <NoCustDomainsMessage />}
      <h3 className={styles.activeDirectoryTitle}>Assign internal users to customer domains</h3>
      <div className={styles.activeDirectoryCounter}>
        <span>Employees </span>
        <b id="active-directory-counter">
          ({activeDirectoryData?.pageMeta?.TotalCount || activeDirectoryData?.data?.length || 0})
        </b>
      </div>

      <DataTable openModal={openModal} />

      {modalData && <ManageAssignmentsModal isOpen={isOpen} data={modalData} onClose={closeModal} />}
    </div>
  );
};

export default ActiveDirectory;
