import { useCallback, useEffect, useState } from 'react';
import { Button, CodeInput, Loader, ResendCode } from 'components';
import { ArrowLeftIcon } from 'assets/img';
import { useHistory } from 'react-router-dom';
import { notify } from 'utils';
import { useTimer } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store';
import { checkMfaVerificationCode, requestVerificationCodeByEmail } from 'store/slices/auth/mfa';
import styles from './SetupViaEmail.module.scss';
import { SerializedError } from '@reduxjs/toolkit';
import { changeUserMfa } from 'store/slices/userSlice';

const SetupViaEmail = () => {
  const { user } = useAppSelector((state) => state.user);
  const { isLoading: isDisabled } = useAppSelector((state) => state.mfa.checkMfaVerificationCodeRequest);
  const { emailMask, pinHash } = useAppSelector((state) => state.mfa);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { timeLeft, resetCountdown } = useTimer(60);

  const [codeValue, setCodeValue] = useState('');
  const [isError, setIsError] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const handleSendEmailAddress = useCallback(async () => {
    try {
      await dispatch(requestVerificationCodeByEmail()).unwrap();
      resetCountdown(60);
      notify.success('Email has been sent');
      setIsLoading(false);
    } catch (err: any) {
      if ((err as SerializedError).code === '409') {
        notify.error(err.message);
        history.replace('/setup-mfa');
      }
      notify.error(err.message);
      if ((err as SerializedError).code === '403') {
        history.goBack();
      }
      setIsLoading(false);
    }
  }, [history, resetCountdown]);

  useEffect(() => {
    handleSendEmailAddress();
  }, [handleSendEmailAddress]);

  const clearError = () => setIsError('');

  const goBack = () => history.goBack();

  const handleSendVerificationCode = async () => {
    try {
      await dispatch(checkMfaVerificationCode({ mfaPin: codeValue, mfaHash: pinHash })).unwrap();
      dispatch(changeUserMfa('email'));
      notify.success('Verification Successful. MFA Setup Completed');
      if (user.roles.includes('trugridadmin')) {
        return window.location.replace('/admin');
      }
    } catch (err: any) {
      setCodeValue('');
      setIsError(err.message);
    }
  };

  return (
    <div className={styles.setupMfa}>
      <div className={styles.setupMfaWrap}>
        <h3 className={styles.setupMfaTitle}>
          <Button
            variant="link"
            icon={<ArrowLeftIcon />}
            onClick={goBack}
            className={styles.setupMfaBack}
            id="back-btn"
          />
          <span>Verify Your Email</span>
        </h3>
      </div>
      {isLoading ? (
        <Loader id="loading-setup-mfa-email" style={{ top: 'calc(50% + 1.5em)' }} />
      ) : (
        <>
          <div className={styles.setupMfaInfo}>
            We've sent a verification code to
            <br />
            <strong>{emailMask}</strong>
          </div>
          <div className={styles.setupMfaInputTitle}>Enter Verification Code</div>
          <CodeInput
            fields={6}
            isError={!!isError}
            errorMessage={isError}
            clearError={clearError}
            isDisable={isDisabled}
            value={codeValue}
            setValue={setCodeValue}
            handleSend={handleSendVerificationCode}
          />
          <div className={styles.setupMfaResend}>
            <ResendCode countDown={timeLeft} handleSend={handleSendEmailAddress} isDisabled={isDisabled} />
          </div>
        </>
      )}
    </div>
  );
};

export default SetupViaEmail;
