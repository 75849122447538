import cn from 'classnames';
import { Button } from 'components';
import { useRef } from 'react';
import { useObserver } from 'hooks';
import styles from './DevicesBulkActions.module.scss';
import { useAppSelector } from 'store';
import { IBitlockerDevice } from 'store/slices/bitLockerMgmt';

interface Props {
  selectedDeviceIds: IBitlockerDevice[];
  setSelectedDeviceIds: (deviceIds: IBitlockerDevice[]) => void;
  openUninstallAgentsModal: () => void;
  openDevicesModal: () => void;
}

const DevicesBulkActions = ({
  selectedDeviceIds,
  setSelectedDeviceIds,
  openUninstallAgentsModal,
  openDevicesModal,
}: Props) => {
  const { bitlockerDevicesData: devices } = useAppSelector((state) => state.bitLockerMgmt);

  const { installationStatus } = devices?.queryConfig || {};
  const totalDevices = devices?.pageMeta?.TotalCount || devices?.data?.length || 0;

  const counterRef = useRef<HTMLDivElement | null>(null);
  const isSticky: boolean = useObserver(counterRef, !!selectedDeviceIds.length, { threshold: 1 });

  return (
    <div
      className={cn(styles.bulkActions, { [styles.active]: selectedDeviceIds.length, [styles.sticked]: isSticky })}
      ref={counterRef}>
      <div className={styles.bulkActionsWrap}>
        <div className={styles.bulkActionsCount}>
          {selectedDeviceIds.length}/{totalDevices} Selected
        </div>
        <div className={styles.bulkActionsButtonsWrap}>
          <Button
            type="submit"
            id="unselect-all"
            variant="secondary"
            size="40"
            style={{ width: '7.75em' }}
            onClick={() => setSelectedDeviceIds([])}>
            Unselect All
          </Button>
          {installationStatus === 'Installed' ? (
            <Button
              type="submit"
              id="uninstall-devices"
              variant="primary"
              size="40"
              style={{ width: '7.75em' }}
              onClick={() => openUninstallAgentsModal()}>
              Uninstall
            </Button>
          ) : (
            <Button
              type="submit"
              id="delete-devices"
              variant="deleteOutline"
              size="40"
              style={{ width: '7.75em' }}
              onClick={() => openDevicesModal()}>
              Delete
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DevicesBulkActions;
