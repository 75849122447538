import { useEffect, useState } from 'react';
import Loader from 'components/Loader/Loader';
import Modal from 'components/Modal/Modal';
import { TriangleFilledIcon } from 'assets/img';
import { notify } from 'utils';
import { IValidateModalDataBase, IValidateModalDataWithAutoValidated } from 'interfaces';
import styles from './ValidateDomainModal.module.scss';
import {
  createNotValidSpDomain,
  getValidateData,
  getValidationCode,
  getWhoisDomainEmails,
  validateByTxtRecord,
  validateDataWithAutoValidated,
  ValidationData,
} from 'store/slices/userSlice';
import { useAppDispatch } from 'store';

interface Props {
  isOpen: boolean;
  data: IValidateModalDataBase;
  onUpdateModal: (resp: any, id: string, domainName: string) => void;
  onClose: () => void;
  isCustomerDomain?: boolean;
}

const ValidateDomainModal = ({ isOpen, data, onUpdateModal, onClose, isCustomerDomain }: Props) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [isBtnAction, setIsBtnAction] = useState(false);
  const [validationEmails, setValidationEmails] = useState('');
  const [validationData, setValidationData] = useState<ValidationData>({
    code: '',
    dnsName: '',
    id: '',
    validationCode: '',
  });
  const [isError, setIsError] = useState(false);

  const fetchData = async () => {
    try {
      if (data.type === 'emailValidation') {
        const domain = isCustomerDomain ? data.domainName : data.upn?.split('@')[1];
        if (domain) {
          const response = await dispatch(getWhoisDomainEmails(domain)).unwrap();
          setValidationEmails(response.join(', '));
        } else {
          throw new Error('Domain is undefined');
        }
      } else if (data.type === 'txtValidation') {
        let response;
        if (isCustomerDomain) {
          response = await dispatch(getValidationCode(data.domainId)).unwrap();
        } else if ((data as IValidateModalDataWithAutoValidated).autoValidated) {
          response = await dispatch(getValidateData((data as IValidateModalDataWithAutoValidated).link)).unwrap();
        } else {
          response = await dispatch(createNotValidSpDomain()).unwrap();
        }
        setValidationData(response);
      }
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onValidateViaEmail = async () => {
    try {
      setIsBtnAction(true);
      await dispatch(validateDataWithAutoValidated((data as IValidateModalDataWithAutoValidated).link)).unwrap();
      notify.success('Domain validation email has been sent');
      setIsBtnAction(false);
      onClose();
    } catch (error) {
      setIsBtnAction(false);
      setIsError(true);
    }
  };

  const onValidateViaTxt = async () => {
    try {
      setIsBtnAction(true);
      const payload = {
        domainId: '',
        dnsName: '',
        validationCode: '',
      };
      if (isCustomerDomain || (data as IValidateModalDataWithAutoValidated).autoValidated) {
        payload.domainId = data.domainId;
        payload.dnsName = data.domainName;
        payload.validationCode = validationData.code;
      } else {
        payload.domainId = validationData.id;
        payload.dnsName = validationData.dnsName;
        payload.validationCode = validationData.validationCode;
      }
      const response = await dispatch(validateByTxtRecord(payload)).unwrap();
      onUpdateModal(response, data.domainId || validationData.id, data.domainName);
      setIsBtnAction(false);
      onClose();
    } catch (error) {
      setIsBtnAction(false);
      setIsError(true);
    }
  };

  const modalTitle = () => {
    if (data.type === 'emailValidation') {
      if (isError) {
        return (
          <span className={styles.modalHeader}>
            <TriangleFilledIcon /> WHOIS Validation Issue
          </span>
        );
      }
      return 'Send a validation email?';
    }
    if (data.type === 'txtValidation') {
      if (isError) {
        return (
          <span className={styles.modalHeader}>
            <TriangleFilledIcon />
            Did not find the correct TXT record ({validationData?.code || validationData?.validationCode})
          </span>
        );
      }
      return 'Validate domain';
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      contentLabel={!isLoading ? modalTitle() : ''}
      actionTitle={!isLoading && !isError ? (data.type === 'emailValidation' ? 'Send' : 'Validate') : ''}
      actionFunc={!isLoading && !isError && data.type === 'emailValidation' ? onValidateViaEmail : onValidateViaTxt}
      actionLoading={isBtnAction}
      onRequestClose={onClose}>
      {isLoading ? (
        <Loader id="loading-validate-domain" />
      ) : (
        <>
          {data.type === 'emailValidation' &&
            (isError ? (
              <>
                There appears to be a problem performing WHOIS validation on your domain. Sometimes this occurs for
                private WHOIS registrations. Please use the DNS TXT validation approach instead
              </>
            ) : (
              <>
                By clicking <b>SEND</b>, a validation email will be sent to owner <b>{validationEmails}</b>
              </>
            ))}
          {data.type === 'txtValidation' &&
            (isError ? (
              <>Please update your DNS, wait for it to propagate further, or use email WHOIS validation instead.</>
            ) : (
              <>
                <p>
                  1. Add a TXT record to <b>{data.domainName}</b>'s public DNS with a value of{' '}
                  <b>{validationData.code || validationData.validationCode}</b>
                </p>
                <br />
                <p>
                  2. Once the TXT record is added and propagated via DNS, then click <b>VALIDATE</b> to validate your
                  domain
                </p>
              </>
            ))}
        </>
      )}
    </Modal>
  );
};

export default ValidateDomainModal;
