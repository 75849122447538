import { Link, StatusMessage } from 'components';

const NoApps = () => (
  <StatusMessage header="No apps reporting in">
    Please check Active Directory or{' '}
    <Link href="https://www.trugrid.com/docs/addapp" external bold>
      add apps & users
    </Link>
  </StatusMessage>
);

export default NoApps;
