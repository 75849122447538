import cn from 'classnames';
import { Link } from 'react-router-dom';
import { IUser } from 'interfaces';
import { Checkbox } from 'components';
import SetupOnPremise from './components/SetupOnPremise/SetupOnPremise';
import SetupAzure from './components/SetupAzure/SetupAzure';
import styles from './SetupDomain.module.scss';
import { SetupType } from '../../Onboarding';

interface Props {
  user: IUser;
  isDomainNotActivated: boolean;
  domainWasAutovalidated?: boolean;
  setupType: SetupType;
  onSetupTypeChange: (type: SetupType) => void;
  isDomainAzure: boolean;
}

const SetupDomain = ({
  user,
  isDomainNotActivated,
  domainWasAutovalidated,
  setupType,
  onSetupTypeChange,
  isDomainAzure,
}: Props) => (
  <div
    className={cn(styles.onboarding, {
      [styles.active]: isDomainNotActivated,
      [styles.isAutovalidaton]: domainWasAutovalidated,
    })}>
    <div className={styles.onboardingTitle}>
      {!domainWasAutovalidated && <span>2</span>}
      <h5>Setup Your Domain</h5>
    </div>
    {isDomainNotActivated && (
      <>
        <div className={styles.onboardingInfo}>
          <span>
            You can enable Hybrid Authentication between Active Directory and Entra ID after connecting to a directory
          </span>
        </div>
        <div className={styles.onboardingVariants}>
          <div
            className={cn(styles.onboardingVariant, styles.onPremise, {
              [styles.active]: setupType === 'on-premise',
            })}>
            <div className={styles.onboardingVariantCheckbox}>
              <Checkbox
                id="on-premise"
                checked={setupType === 'on-premise'}
                isCircle
                onChange={() => onSetupTypeChange('on-premise')}
                label="Proceed with on-premises Active Directory installation"
              />
            </div>
            {setupType === 'on-premise' && <SetupOnPremise user={user} />}
          </div>
          <div
            className={cn(styles.onboardingVariant, styles.azure, {
              [styles.active]: setupType === 'azure',
            })}>
            <div className={styles.onboardingVariantCheckbox}>
              <Checkbox
                id="azure"
                checked={setupType === 'azure'}
                isCircle
                onChange={() => onSetupTypeChange('azure')}
                label="Proceed with Microsoft Entra ID"
              />
            </div>
            {setupType === 'azure' && <SetupAzure isDomainAzure={isDomainAzure} user={user} />}
          </div>
        </div>
        <div className={styles.onboardingLink}>
          or Proceed with <Link to="/company-management/device-management">BitLocker Management Setup</Link>
        </div>
      </>
    )}
  </div>
);

export default SetupDomain;
