import { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { notify } from 'utils';
import { getDomainADStatus, getDomainStatus } from 'helpers';
import ActivationCode from 'components/ActivationCode/ActivationCode';
import Button from 'components/Button/Button';
import { AlertWarning } from 'assets/img';
import Loader from '../Loader/Loader';
import ResetAdModal from './components/ResetAdModal';
import DomainMgmtInfo from './components/DomainMgmtInfo/DomainMgmtInfo';
import styles from './ActiveDirectorySetup.module.scss';
import OnPremiseAD from './components/OnPremiseAD/OnPremiseAD';
import AzureAD from './components/AzureAD/AzureAD';
import { IDomain } from 'interfaces';
import { useAppDispatch, useAppSelector } from 'store';
import { updateCustomerDomain } from 'store/slices/customerDomains';
import { disconnectOnPremise, getDomainConnectStatus } from 'store/slices/domainMgmt';
import { refreshAzureAdData } from 'store/slices/resourceAssignments';
import { getInitialUserData } from 'store/slices/userSlice';
import { disconnectEntraID, getDomainToken } from 'store/slices/msal';
import { SerializedError } from '@reduxjs/toolkit';

interface Props {
  domain: IDomain;
  userRole: string;
  isCompanyPage: boolean;
}

const ActiveDirectorySetup = ({ domain, userRole, isCompanyPage }: Props) => {
  const dispatch = useAppDispatch();

  const [type, setType] = useState('');
  const [showToggleModal, setShowToggleModal] = useState(false);
  const [isShowNotSyncMessage, setIsShowNotSyncMessage] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const { data: domainData } = useAppSelector((state) => state.domainMgmt.getDomainConnectStatusRequest);
  const { isLoading: isResetLoading } = useAppSelector((state) => state.domainMgmt.disconnectOnPremiseRequest);
  const { isLoading: isResetEntraLoading } = useAppSelector((state) => state.msal.disconnectEntraIDRequest);

  const isDomainHybryd = domainData ? getDomainADStatus(domainData).isDomainHybryd : false;
  const isDomainActive = domainData ? getDomainStatus(domainData).isDomainActive : false;
  const { isLoading: isLoadingMs } = useAppSelector((state) => state.resourceAssignments.refreshAzureAdDataRequest);
  const { dnsName: domainName, id: domainId } = domain;

  const loadInitDomainData = async () => {
    try {
      const data = await dispatch(getDomainConnectStatus(domain.id)).unwrap();
      setIsShowNotSyncMessage(data.isAdSynced);
      if (!isCompanyPage) {
        dispatch(updateCustomerDomain(data));
      }
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      notify.error(err.message);
    }
  };

  useEffect(() => {
    loadInitDomainData();
  }, []);

  const toggleShowModal = (modalType = '') => {
    setType(modalType);
    setShowToggleModal(!showToggleModal);
  };

  const azureAdRefreshCall = async (id: string, authToken: string) => {
    try {
      setIsLoading(true);
      await dispatch(refreshAzureAdData({ domainId: id, authToken, refreshResourses: false })).unwrap();
      await dispatch(getInitialUserData({ _background: true })).unwrap();
      notify.success('Entra ID re-authentication is successful');
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      notify.warning(err.message);
    }
  };

  const disconnectAzure = async () => {
    try {
      await dispatch(disconnectEntraID({ isCompanyPage, domainId })).unwrap();
      if (!isCompanyPage) {
        await loadInitDomainData();
      }
      notify.success('Microsoft Entra ID is disconnected');
    } catch (err: any) {
      notify.error(err.message);
    } finally {
      setShowToggleModal(!showToggleModal);
    }
  };

  const refreshAd = async () => {
    try {
      const authToken = await dispatch(getDomainToken({ domainId, domainName })).unwrap();
      if (authToken) {
        await azureAdRefreshCall(domainId, authToken);
      }
    } catch (err: any) {
      if ((err as SerializedError).message?.includes('user_cancelled')) {
        setIsShowNotSyncMessage(true);
      } else {
        notify.error(err.message);
      }
    }
  };

  const handleDisconnectOnPremise = async () => {
    try {
      await dispatch(disconnectOnPremise(domainId)).unwrap();
      setShowToggleModal(!showToggleModal);
      if (isDomainHybryd) {
        await refreshAd();
      }
      await dispatch(getInitialUserData({ _background: false })).unwrap();
      notify.success('On-premises Active Directory is disconnected');
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  if (isLoading || isLoadingMs) {
    return <Loader id="loading-azure-ad" />;
  }

  return (
    <>
      <div className={styles.domainMgmt}>
        <div className={styles.domainMgmtRow}>
          <h3 className={styles.domainMgmtTitle}>Active Directory Setup</h3>
          <ActivationCode
            activationCode={domain.activationCode}
            label="Activation Code:"
            copyMessage="Activation code was saved to clipboard"
          />
        </div>
        {!isShowNotSyncMessage && isDomainActive && (
          <div className={styles.domainMgmtAdSync}>
            <AlertWarning />{' '}
            <span>
              You are no longer connected to Active Directory. Please{' '}
              <Button variant="link" onClick={refreshAd} className={styles.domainMgmtAdSyncLink}>
                re-authenticate against Entra ID
              </Button>{' '}
              to continue to support user logins
            </span>
          </div>
        )}
        <table className={styles.domainMgmtTable}>
          <tbody>
            <OnPremiseAD
              domainData={domainData!}
              userRole={userRole}
              isCompanyPage={isCompanyPage}
              toggleShowModal={toggleShowModal}
            />
            <AzureAD
              domainData={domainData!}
              domain={domain}
              userRole={userRole}
              toggleShowModal={toggleShowModal}
              isCompanyPage={isCompanyPage}
              loadInitDomainData={loadInitDomainData}
            />
          </tbody>
        </table>
        <DomainMgmtInfo />
        <ReactTooltip id="reset-tooltip" type="light" place="bottom" html effect="solid" />
      </div>
      {showToggleModal && (
        <ResetAdModal
          type={type}
          isDomainHybryd={isDomainHybryd}
          domain={domain.dnsName}
          isResetLoading={isResetLoading || isResetEntraLoading}
          isOpen={showToggleModal}
          toggleShowModal={toggleShowModal}
          disconnectAzure={disconnectAzure}
          disconnectOnPremise={handleDisconnectOnPremise}
        />
      )}
    </>
  );
};

export default ActiveDirectorySetup;
