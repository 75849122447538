import { Modal } from 'components';
import { IShortcutMgmtUser } from 'store/slices/securityMgmt/shortcutMgmt';
import { useAppSelector } from 'store';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  onToggleMfaRequiredForUser: (isBulk: boolean, action: string) => Promise<void>;
  user: IShortcutMgmtUser;
}

const EnableMFACodeModal = ({ isOpen, onRequestClose, onToggleMfaRequiredForUser, user }: Props) => {
  const { isLoading } = useAppSelector((state) => state.shortcutMgmt.toggleMfaRequiredForUserRequest);

  const handleDisableMfaRequiredForUser = async () => {
    await onToggleMfaRequiredForUser(!!Array.isArray(user), 'enable');
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Require MFA Code?"
      actionFunc={handleDisableMfaRequiredForUser}
      actionTitle="Confirm"
      variantBtn="primary"
      actionLoading={isLoading}>
      "Requiring MFA Code" will automatically enable MFA for selected users who do not already have MFA?
    </Modal>
  );
};

export default EnableMFACodeModal;
