import { addAlpha, invertColor } from 'pages/Profile/pages/Branding/helpers/colorsHelper';
import { useAppSelector } from 'store';

export const useBrandingColors = () => {
  const { branding } = useAppSelector((state) => state.user);
  const isInverted = invertColor(branding?.buttonColor);
  const buttonColor = branding?.buttonColor || '#4169b8';

  const colorStyles = {
    '--button-bg': buttonColor,
    '--button-border': buttonColor,
    '--button-color': isInverted ? '#000' : '#fff',
    '--button-bg-hover': addAlpha(buttonColor, 0.85),
    '--button-border-hover': addAlpha(buttonColor, 0.85),
    '--button-bg-active': addAlpha(buttonColor, 1),
    '--button-border-active': addAlpha(buttonColor, 1),
    '--button-bg-disabled': addAlpha(buttonColor, 1),
    '--button-border-disabled': addAlpha(buttonColor, 1),
    '--button-opacity': buttonColor ? '0.5' : '1',
  };

  return colorStyles;
};
