import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Button, CountryListDropdown } from 'components';
import { notify } from 'utils';
import styles from './EnterPhoneNumber.module.scss';
import { useAppDispatch, useAppSelector } from 'store';
import { requestVerificationCodeBySms } from 'store/slices/auth/mfa';
import { ICountryList } from 'interfaces';
import { SerializedError } from '@reduxjs/toolkit';

interface Props {
  countriesList: ICountryList;
  dialCode: string;
  currentCountry: string;
  setCurrentCountry: (country: string) => void;
  phoneNumber: string;
  setPhoneNumber: (value: string) => void;
}

const EnterPhoneNumber = ({
  countriesList,
  dialCode,
  currentCountry,
  setCurrentCountry,
  phoneNumber,
  setPhoneNumber,
}: Props) => {
  const { isLoading } = useAppSelector((state) => state.mfa.requestVerificationCodeBySmsRequest);

  const dispatch = useAppDispatch();

  const history = useHistory();
  const { url } = useRouteMatch();

  const handleSetCurrentCountry = (value: string) => setCurrentCountry(value);

  const onSubmitPhoneNumber = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      if (phoneNumber && phoneNumber.length > 4) {
        await dispatch(
          requestVerificationCodeBySms({ phone: phoneNumber.replace(/\D+/g, ''), code: dialCode, isMfaSetup: true }),
        ).unwrap();
        notify.success('SMS has been sent');
        history.push(`${url}/validation-code`);
      }
    } catch (err: any) {
      if ((err as SerializedError).code === '409') {
        notify.error(err.message);
        history.replace('/setup-mfa');
      } else {
        notify.error(err.message);
      }
    }
  };

  return (
    <form method="POST" noValidate onSubmit={onSubmitPhoneNumber} className={styles.setupMfa}>
      <div className={styles.setupMfaInfo}>Receive a code via text to enter on the next screen</div>
      <CountryListDropdown
        id="phone-code-dd"
        label="Enter Your Phone Number"
        options={countriesList}
        currentCountry={currentCountry}
        onChange={handleSetCurrentCountry}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
      />
      <Button
        type="submit"
        variant="primary"
        size="40"
        isLoading={isLoading}
        isDisabled={!(phoneNumber && phoneNumber.length > 4)}
        className={styles.setupMfaButton}>
        Get a Code
      </Button>
    </form>
  );
};

EnterPhoneNumber.propTypes = {
  countriesList: PropTypes.object,
  dialCode: PropTypes.string,
  currentCountry: PropTypes.string,
  setCurrentCountry: PropTypes.func,
  phoneNumber: PropTypes.string,
  setPhoneNumber: PropTypes.func,
  setPinHash: PropTypes.func,
  setPhoneMask: PropTypes.func,
};

export default EnterPhoneNumber;
