import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { createExtraReducersForResponses, createHttpRequestInitResult, http } from 'helpers/http';
import { IHttpRequestResult, IPageMeta, IQueryConfig } from 'interfaces';
import { RootState } from 'store/store';
import { queryFormatter } from 'utils';

export interface IBitlockerDeviceData {
  data: IBitlockerDevice[];
  pageMeta: IPageMeta;
  queryConfig: IQueryConfig;
}

export interface IBitlockerEncryptionData {
  data: IBitlockerEncryption[];
  pageMeta: IPageMeta;
  queryConfig: IQueryConfig;
}

export interface IBitlockerDevice {
  deviceId: string;
  hostname: string;
  dateAdded: Date;
  installationStatus: number;
  isOnline: boolean;
  hasLicense: boolean;
  isServer: boolean;
  osType: number;
  osVersion: string;
  appVersion: string;
  externalIp: string;
  internalIp: string;
  lastConnectedDate: Date;
}

export interface IBitlockerEncryption {
  deviceId: string;
  hostname: string;
  dateAdded: Date;
  installationStatus: number;
  isOnline: boolean;
  hasLicense: boolean;
  isServer: boolean;
  encryptionStatus: number;
  isTpm: boolean;
  isTpmMessage: string;
  isLockedDrive: boolean;
}

export interface IBitlockerEncryptionDetails {
  id: string;
  name: string;
  encryptionStatus: number;
  encryptionStatusMessage: string;
  isValidEncryptionPassword: boolean;
  isValidRecoveryKey: boolean;
  letter: string;
  showLockWarning: boolean;
}

export interface EncryptDriveDetails {
  [key: string]: {
    isOpen: boolean;
    encryptPass?: string;
    encryptKey?: string;
  };
}

interface CreateEncryptionPasswordPayload {
  deviceId: string;
  password: string;
  confirmedPassword: string;
}

interface IGetBitlockerDataPayload {
  domainId: string;
  query?: IQueryConfig;
  _background?: boolean;
}

interface DeleteDevicesPayload {
  deviceIds: string[];
  domainId: string;
}

export const getBitlockerDevices = createAsyncThunk(
  'bitLockerMgmt/getBitlockerDevices',
  async ({ domainId, query, _background }: IGetBitlockerDataPayload, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const queryConfig = state.bitLockerMgmt.bitlockerDevicesData?.queryConfig || {};
    const queryConfigNew = { ...queryConfig, ...query };
    const response: AxiosResponse<IBitlockerDevice[]> = await http.get(
      queryFormatter(`/api/IoTAgent/GetDevices?domainId=${domainId}`, queryConfigNew),
    );
    const pageMeta = response?.headers['x-pagination'] ? JSON.parse(response.headers['x-pagination']) : null;
    const data: IBitlockerDeviceData = {
      data: response.data,
      pageMeta,
      queryConfig: queryConfigNew,
    };
    return data;
  },
);

export const getBitlockerEncryption = createAsyncThunk(
  'bitLockerMgmt/getBitlockerEncryption',
  async ({ domainId, query }: IGetBitlockerDataPayload, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const queryConfig = state.bitLockerMgmt.bitlockerEncryptionData?.queryConfig || {};
    const queryConfigNew = { ...queryConfig, ...query };
    const response: AxiosResponse<IBitlockerEncryption[]> = await http.get(
      queryFormatter(`/api/IoTAgent/GetEncryptionData?domainId=${domainId}`, queryConfigNew),
    );
    const pageMeta = response?.headers['x-pagination'] ? JSON.parse(response.headers['x-pagination']) : null;
    const data: IBitlockerEncryptionData = {
      data: response.data,
      pageMeta,
      queryConfig: queryConfigNew,
    };
    return data;
  },
);

export const getBitlockerSubscriptionStatus = createAsyncThunk(
  'bitLockerMgmt/getBitlockerSubscriptionStatus',
  async (domainId: string) => {
    const baseUrl = `/api/Chargify/GetSubscriptionActiveStatus?domainId=${domainId}`;
    const response: AxiosResponse<boolean> = await http.get(baseUrl);
    return response.data;
  },
);

export const deleteDevices = createAsyncThunk(
  'bitLockerMgmt/deleteDevices',
  async ({ deviceIds, domainId }: DeleteDevicesPayload, thunkAPI) => {
    await http.delete('/api/AgentUninstallation/DeleteDevices', {
      data: deviceIds,
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
    });
    await thunkAPI.dispatch(getBitlockerDevices({ domainId: domainId, query: { pageNumber: 1 }, _background: true }));
  },
);

export const uninstallAgents = createAsyncThunk(
  'bitLockerMgmt/uninstallAgents',
  async ({ deviceIds, domainId }: DeleteDevicesPayload, thunkAPI) => {
    await http.put('/api/AgentUninstallation/SetPendingDeleteStatus', deviceIds);
    await thunkAPI.dispatch(getBitlockerDevices({ domainId: domainId, query: { pageNumber: 1 }, _background: true }));
  },
);

export const getEncryptDetails = createAsyncThunk('bitLockerMgmt/getEncryptDetails', async (deviceId: string) => {
  const baseUrl = `/api/WindowsDrives/Get?deviceId=${deviceId}`;
  const response: AxiosResponse<IBitlockerEncryptionDetails[]> = await http.get(baseUrl);
  return response.data;
});

export const encryptDevice = createAsyncThunk('bitLockerMgmt/encryptDevice', async (deviceId: string) => {
  const response: AxiosResponse<Partial<IBitlockerEncryption>> = await http.put('/api/IoTAgent/Encrypt', { deviceId });
  return response.data;
});

export const decryptDevice = createAsyncThunk('bitLockerMgmt/decryptDevice', async (deviceId: string) => {
  const response: AxiosResponse<Partial<IBitlockerEncryption>> = await http.put('/api/IoTAgent/Decrypt', { deviceId });
  return response.data;
});

export const createEncryptionPassword = createAsyncThunk(
  'bitLockerMgmt/createEncryptionPassword',
  async ({ deviceId, password, confirmedPassword }: CreateEncryptionPasswordPayload, thunkAPI) => {
    await http.post('/api/EncryptionPassword/Create', { deviceId, password, confirmedPassword });
    await thunkAPI.dispatch(encryptDevice(deviceId));
  },
);

export const getEncryptPassword = createAsyncThunk(
  'bitLockerMgmt/getEncryptPassword',
  async ({ deviceId, discId }: { deviceId: string; discId: string }) => {
    const response: AxiosResponse<string> = await http.get(
      `/api/WindowsDrives/GetEncryptionPassword?driveId=${discId}&deviceId=${deviceId}`,
    );
    return response.data;
  },
);

export const getRecoveryKey = createAsyncThunk(
  'bitLockerMgmt/getRecoveryKey',
  async ({ deviceId, discId }: { deviceId: string; discId: string }) => {
    const response: AxiosResponse<string> = await http.get(
      `/api/WindowsDrives/GetRecoveryKey?driveId=${discId}&deviceId=${deviceId}`,
    );
    return response.data;
  },
);

export const getEncryptDriveDetails = createAsyncThunk(
  'bitLockerMgmt/getEncryptDriveDetails',
  async (
    { deviceId, discId, isPass, isKey }: { deviceId: string; discId: string; isPass: boolean; isKey: boolean },
    thunkAPI,
  ) => {
    if (isPass) {
      await thunkAPI.dispatch(getEncryptPassword({ deviceId, discId })).unwrap();
    }
    if (isKey) {
      await thunkAPI.dispatch(getRecoveryKey({ deviceId, discId })).unwrap();
    }
  },
);

export const getComplianceReport = createAsyncThunk('bitLockerMgmt/getComplianceReport', async (domainId: string) => {
  const response: AxiosResponse<Blob> = await http.get(`/api/ComplianceReport/Get?domainId=${domainId}`, {
    responseType: 'blob',
  });
  return response;
});

interface IGetDownloadDataResponse {
  agents: [{ downloadLink: string }];
}
interface IGetDownloadDataPayload {
  domainId: string;
}

export const getDownloadData = createAsyncThunk(
  'bitLockerMgmt/getDownloadData',
  async ({ domainId }: IGetDownloadDataPayload) => {
    const { host } = window.location;
    const response: AxiosResponse<IGetDownloadDataResponse> = await http.get(
      `/api/IoTAgent/GetDownloadData?domainId=${domainId}${
        !process.env.REACT_APP_PUBLIC_URL?.includes(host) ? `&brandingHost=${host}` : ''
      }`,
    );
    return response.data;
  },
);

export const downloadEnrollFile = createAsyncThunk('bitLockerMgmt/downloadEnrollFile', async (link: string) => {
  const response: AxiosResponse<Blob> = await http.get(link, {
    responseType: 'blob',
  });
  return response;
});

interface IBitLockerMgmtState {
  bitlockerDevicesData: IBitlockerDeviceData | null;
  bitlockerEncryptionData: IBitlockerEncryptionData | null;
  bitlockerSubscriptionStatus: boolean | null;
  encryptDriveDetails: EncryptDriveDetails;
  getBitlockerDevicesRequest: IHttpRequestResult<IBitlockerDeviceData>;
  getBitlockerEncryptionRequest: IHttpRequestResult<IBitlockerEncryptionData>;
  getBitlockerSubscriptionStatusRequest: IHttpRequestResult<boolean>;
  deleteDevicesRequest: IHttpRequestResult<void>;
  uninstallAgentsRequest: IHttpRequestResult<void>;
  getEncryptDetailsRequest: IHttpRequestResult<IBitlockerEncryptionDetails[]>;
  encryptDeviceRequest: IHttpRequestResult<Partial<IBitlockerEncryption>>;
  decryptDeviceRequest: IHttpRequestResult<Partial<IBitlockerEncryption>>;
  getEncryptPasswordRequest: IHttpRequestResult<string>;
  getRecoveryKeyRequest: IHttpRequestResult<string>;
  createEncryptionPasswordRequest: IHttpRequestResult<void>;
  getEncryptDriveDetailsRequest: IHttpRequestResult<void>;
  getComplianceReportRequest: IHttpRequestResult<AxiosResponse<Blob>>;
  getDownloadDataRequest: IHttpRequestResult<IGetDownloadDataResponse>;
  downloadEnrollFileRequest: IHttpRequestResult<AxiosResponse<Blob>>;
}

const initialState: IBitLockerMgmtState = {
  bitlockerDevicesData: null,
  bitlockerEncryptionData: null,
  bitlockerSubscriptionStatus: null,
  encryptDriveDetails: {},
  getBitlockerDevicesRequest: createHttpRequestInitResult(),
  getBitlockerEncryptionRequest: createHttpRequestInitResult(),
  getBitlockerSubscriptionStatusRequest: createHttpRequestInitResult(),
  deleteDevicesRequest: createHttpRequestInitResult(),
  uninstallAgentsRequest: createHttpRequestInitResult(),
  getEncryptDetailsRequest: createHttpRequestInitResult(),
  encryptDeviceRequest: createHttpRequestInitResult(),
  decryptDeviceRequest: createHttpRequestInitResult(),
  getEncryptPasswordRequest: createHttpRequestInitResult(),
  getRecoveryKeyRequest: createHttpRequestInitResult(),
  createEncryptionPasswordRequest: createHttpRequestInitResult(),
  getEncryptDriveDetailsRequest: createHttpRequestInitResult(),
  getComplianceReportRequest: createHttpRequestInitResult(),
  getDownloadDataRequest: createHttpRequestInitResult(),
  downloadEnrollFileRequest: createHttpRequestInitResult(),
};

export const bitLockerMgmtSlice = createSlice({
  name: 'bitLockerMgmt',
  initialState,
  reducers: {
    openEncryptDriveDetails: (state, { payload }) => {
      state.encryptDriveDetails = {
        ...state.encryptDriveDetails,
        [payload]: {
          ...state.encryptDriveDetails[payload],
          isOpen: true,
        },
      };
    },
    closeEncryptDriveDetails: (state, { payload }: { payload: string }) => {
      state.encryptDriveDetails = {
        ...state.encryptDriveDetails,
        [payload]: {
          ...state.encryptDriveDetails[payload],
          isOpen: false,
        },
      };
    },
    clearEncryptDriveDetails: (state) => {
      state.encryptDriveDetails = {};
    },
  },
  extraReducers: (builder) => {
    createExtraReducersForResponses<IBitLockerMgmtState>(
      builder,
      getBitlockerDevices,
      'getBitlockerDevicesRequest',
      (state, action: PayloadAction<IBitlockerDeviceData>) => {
        state.bitlockerDevicesData = action.payload;
      },
    );
    createExtraReducersForResponses<IBitLockerMgmtState>(
      builder,
      getBitlockerEncryption,
      'getBitlockerEncryptionRequest',
      (state, action: PayloadAction<IBitlockerEncryptionData>) => {
        state.bitlockerEncryptionData = action.payload;
      },
    );
    createExtraReducersForResponses<IBitLockerMgmtState>(
      builder,
      getBitlockerSubscriptionStatus,
      'getBitlockerSubscriptionStatusRequest',
      (state, action: PayloadAction<boolean>) => {
        state.bitlockerSubscriptionStatus = action.payload;
      },
    );
    createExtraReducersForResponses<IBitLockerMgmtState>(builder, deleteDevices, 'deleteDevicesRequest');
    createExtraReducersForResponses<IBitLockerMgmtState>(builder, uninstallAgents, 'uninstallAgentsRequest');
    createExtraReducersForResponses<IBitLockerMgmtState>(builder, getEncryptDetails, 'getEncryptDetailsRequest');
    createExtraReducersForResponses<IBitLockerMgmtState>(
      builder,
      encryptDevice,
      'encryptDeviceRequest',
      (state, action) => {
        const { deviceId, encryptionStatus, isLockedDrive } = action.payload;
        if (state.bitlockerEncryptionData) {
          state.bitlockerEncryptionData = {
            ...state.bitlockerEncryptionData,
            data: state.bitlockerEncryptionData.data.map((item) =>
              item.deviceId === deviceId ? { ...item, encryptionStatus, isLockedDrive } : item,
            ),
          };
        }
      },
    );
    createExtraReducersForResponses<IBitLockerMgmtState>(
      builder,
      decryptDevice,
      'decryptDeviceRequest',
      (state, action) => {
        const { deviceId, encryptionStatus, isLockedDrive } = action.payload;
        if (state.bitlockerEncryptionData) {
          state.bitlockerEncryptionData = {
            ...state.bitlockerEncryptionData,
            data: state.bitlockerEncryptionData.data.map((item) =>
              item.deviceId === deviceId ? { ...item, encryptionStatus, isLockedDrive } : item,
            ),
          };
        }
      },
    );
    createExtraReducersForResponses<IBitLockerMgmtState>(builder, getEncryptPassword, 'getEncryptPasswordRequest');
    createExtraReducersForResponses<IBitLockerMgmtState>(builder, getRecoveryKey, 'getRecoveryKeyRequest');
    createExtraReducersForResponses<IBitLockerMgmtState>(
      builder,
      createEncryptionPassword,
      'createEncryptionPasswordRequest',
    );
    createExtraReducersForResponses<IBitLockerMgmtState>(
      builder,
      getEncryptDriveDetails,
      'getEncryptDriveDetailsRequest',
      (state, action) => {
        const { discId, isKey } = action.meta.arg;
        state.encryptDriveDetails = {
          ...state.encryptDriveDetails,
          [discId]: {
            isOpen: true,
            encryptPass: state.getEncryptPasswordRequest.data,
            encryptKey: isKey ? state.getRecoveryKeyRequest.data : '',
          },
        };
      },
    );
    createExtraReducersForResponses<IBitLockerMgmtState>(builder, getComplianceReport, 'getComplianceReportRequest');
    createExtraReducersForResponses<IBitLockerMgmtState>(builder, getDownloadData, 'getDownloadDataRequest');
    createExtraReducersForResponses<IBitLockerMgmtState>(builder, downloadEnrollFile, 'downloadEnrollFileRequest');
  },
});

export const { openEncryptDriveDetails, closeEncryptDriveDetails, clearEncryptDriveDetails } =
  bitLockerMgmtSlice.actions;

export default bitLockerMgmtSlice.reducer;
