import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { http, createHttpRequestInitResult, createExtraReducersForResponses } from 'helpers/http';
import { IHttpRequestResult, IPageMeta, IQueryConfig } from 'interfaces';
import { RootState } from 'store/store';
import { queryFormatter } from 'utils';

export interface IRoleUser {
  userId: string;
  name: string;
  upn: string;
  isAssigned: boolean;
  isMfaEnabled: boolean;
}

export interface IRolesMgmtData {
  data: IRoleUser[];
  pageMeta: IPageMeta;
  queryConfig: IQueryConfig;
}

interface IGetRolesMgmtDataPayload {
  domainId: string;
  query?: IQueryConfig;
  _background?: boolean;
}

interface IUpdateUserRoleAssignmentsPayload {
  domainId: string;
  userIdList: string[];
}

export const getRolesMgmtData = createAsyncThunk(
  'rolesMgmt/getRolesMgmtData',
  async ({ domainId, query, _background }: IGetRolesMgmtDataPayload) => {
    const queryConfig: IQueryConfig = {
      pageNumber: 1,
      pageSize: 10,
      orderBy: 'name asc',
      searchQuery: '',
      ...query,
    };
    const baseUrl: string = `/api/RoleAssignments/GetRoleAssignments?domainId=${domainId}`;
    const response: AxiosResponse = await http.get(queryFormatter(baseUrl, queryConfig));
    const pageMeta: IPageMeta = response?.headers['x-pagination'] ? JSON.parse(response.headers['x-pagination']) : null;
    const data = {
      data: response.data,
      pageMeta,
      queryConfig,
    };
    return data;
  },
);

export const updateUserRoleAssignments = createAsyncThunk(
  'rolesMgmt/updateUserRoleAssignments',
  async ({ domainId, userIdList }: IUpdateUserRoleAssignmentsPayload, thunkAPI) => {
    const url: string = `/api/RoleAssignments/UpdateRoleAssignments?domainId=${domainId}`;
    const state: RootState = thunkAPI.getState() as RootState;
    const queryConfig = state.rolesMgmt.rolesMgmtData?.queryConfig;
    const response: AxiosResponse = await http.put(queryFormatter(url, queryConfig), userIdList);
    const pageMeta: IPageMeta = response?.headers['x-pagination'] ? JSON.parse(response.headers['x-pagination']) : null;
    const data = {
      data: response.data,
      pageMeta,
      queryConfig,
    };
    return data;
  },
);

interface IRolesMgmtState {
  rolesMgmtData: IRolesMgmtData | null;
  getRolesMgmtDataRequest: IHttpRequestResult<IRolesMgmtData>;
  updateUserRoleAssignmentsRequest: IHttpRequestResult<IRolesMgmtData>;
}

const initialState: IRolesMgmtState = {
  rolesMgmtData: null,
  getRolesMgmtDataRequest: createHttpRequestInitResult(),
  updateUserRoleAssignmentsRequest: createHttpRequestInitResult(),
};

export const rolesMgmtSlice = createSlice({
  name: 'RolesMgmt',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createExtraReducersForResponses<IRolesMgmtState>(
      builder,
      getRolesMgmtData,
      'getRolesMgmtDataRequest',
      (state, { payload }) => {
        state.rolesMgmtData = payload;
      },
    );
    createExtraReducersForResponses<IRolesMgmtState>(
      builder,
      updateUserRoleAssignments,
      'updateUserRoleAssignmentsRequest',
      (state, { payload }) => {
        state.rolesMgmtData = payload;
      },
    );
  },
});

//export const { } = rolesMgmtSlice.actions;

export default rolesMgmtSlice.reducer;
