import { Fragment, useEffect, useState } from 'react';
import cn from 'classnames';
import { AngleDownIcon, AngleRightIcon, InfoActiveIcon, InfoIcon, LoaderIcon } from 'assets/img';
import { Modal, ActivationCode } from 'components';
import { encryptStatuses } from 'consts';
import { EncryptDetailsModalData } from '../../Encryptions';
import styles from './EncryptDetailsModal.module.scss';
import { useAppDispatch, useAppSelector } from 'store';
import {
  IBitlockerEncryptionDetails,
  clearEncryptDriveDetails,
  closeEncryptDriveDetails,
  getEncryptDriveDetails,
  openEncryptDriveDetails,
} from 'store/slices/bitLockerMgmt';
import { notify } from 'utils';
import ReactTooltip from 'react-tooltip';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  modalData: EncryptDetailsModalData;
}

const EncryptDetailsModal = ({ isOpen, onRequestClose, modalData }: Props) => {
  const dispatch = useAppDispatch();

  const { encryptDriveDetails } = useAppSelector((state) => state.bitLockerMgmt);

  const [isExpandedLoading, setIsExpandedLoading] = useState('');
  const [isInfoActive, setIsInfoActive] = useState('');

  const { hostname, discs, deviceId } = modalData;

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const fetchEncryptionData = async (discId: string, isPass: boolean, isKey: boolean) => {
    setIsExpandedLoading(discId);
    await dispatch(getEncryptDriveDetails({ deviceId, discId, isPass, isKey })).unwrap();
    setIsExpandedLoading('');
  };

  const onToggleDisc = async (actionType: 'open' | 'close', discId: string, isPass?: boolean, isKey?: boolean) => {
    try {
      if (actionType === 'open') {
        if (!isPass && !isKey) return;
        if (encryptDriveDetails[discId]) {
          dispatch(openEncryptDriveDetails(discId));
        } else {
          await fetchEncryptionData(discId, !!isPass, !!isKey);
        }
      } else {
        dispatch(closeEncryptDriveDetails(discId));
      }
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  const onOpenExpandedList = ({ id, isValidEncryptionPassword, isValidRecoveryKey }: IBitlockerEncryptionDetails) => {
    if (encryptDriveDetails[id]?.isOpen) {
      onToggleDisc('close', id);
    } else {
      onToggleDisc('open', id, isValidEncryptionPassword, isValidRecoveryKey);
    }
  };

  const expandedListIcon = ({ id, isValidEncryptionPassword, isValidRecoveryKey }: IBitlockerEncryptionDetails) => {
    if (isValidEncryptionPassword || isValidRecoveryKey) {
      if (isExpandedLoading === id) {
        return <LoaderIcon />;
      }
      if (encryptDriveDetails[id]?.isOpen) {
        return <AngleDownIcon />;
      }
      return <AngleRightIcon />;
    }
    return null;
  };

  useEffect(() => {
    return () => {
      dispatch(clearEncryptDriveDetails());
    };
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Encryption Details"
      contentSubtitle={hostname}
      style={{ content: { width: '39em' } }}>
      <table className={styles.encryptTable}>
        <thead className={styles.encryptTableThead}>
          <tr className={styles.encryptTableTheadRow}>
            <th className={styles.encryptTableTheadCol} />
            <th className={styles.encryptTableTheadCol}>Drive</th>
            <th className={styles.encryptTableTheadCol}>Name</th>
            <th className={styles.encryptTableTheadCol}>Status</th>
          </tr>
        </thead>
        <tbody className={styles.encryptTableTbody}>
          {discs.map((disc) => {
            const statusCurrent = [0, 1, 2, 3, 4, 5, 6, 7].includes(disc.encryptionStatus) ? disc.encryptionStatus : 8;
            const { statusColor, statusTitle, StatusIcon } = encryptStatuses[statusCurrent];
            const isExpandedCurrent = encryptDriveDetails[disc.id]?.isOpen;

            return (
              <Fragment key={disc.id}>
                <tr
                  className={cn(styles.encryptTableTbodyRow, {
                    [styles.encryptTableTbodyRowSelected]: isExpandedCurrent,
                  })}
                  onClick={() => onOpenExpandedList(disc)}>
                  <td className={styles.encryptTableTbodyCol}>{expandedListIcon(disc)}</td>
                  <td className={styles.encryptTableTbodyCol}>{disc.letter} </td>
                  <td className={styles.encryptTableTbodyCol}>{disc.name || 'N/A'}</td>
                  <td className={styles.encryptTableTbodyCol}>
                    <span
                      className={cn(styles.encryptTableTbodyColStatus, {
                        [styles.encryptTableTbodyColStatusGrey]: statusColor === 'grey',
                        [styles.encryptTableTbodyColStatusOrange]: statusColor === 'orange',
                        [styles.encryptTableTbodyColStatusBlue]: statusColor === 'blue',
                      })}>
                      {!(statusCurrent === 8 && !disc.showLockWarning) && <StatusIcon />}
                      <span>{statusTitle}</span>
                      {disc.showLockWarning && (
                        <span onMouseEnter={() => setIsInfoActive(disc.id)} onMouseLeave={() => setIsInfoActive('')}>
                          <span
                            data-tip="Please log into the computer, select the locked drive and enter a valid password to unlock it"
                            data-for="info-tooltip"
                            data-iscapture="true"
                            data-place="bottom"
                            style={{ display: 'flex', alignItems: 'center' }}>
                            {isInfoActive === disc.id ? <InfoActiveIcon /> : <InfoIcon />}
                          </span>
                        </span>
                      )}
                    </span>
                  </td>
                </tr>
                {isExpandedCurrent && (
                  <>
                    <tr className={styles.encryptTableTbodyPassRow}>
                      <td>
                        <ActivationCode
                          activationCode={
                            disc.isValidEncryptionPassword ? encryptDriveDetails[disc.id].encryptPass : ''
                          }
                          label="Encryption password"
                          id="encrypt-pass"
                          copyMessage="The password copied to clipboard"
                          isLongVersion
                          showCode={!!(disc.isValidEncryptionPassword && encryptDriveDetails[disc.id].encryptPass)}
                        />
                      </td>
                    </tr>

                    {encryptDriveDetails[disc.id].encryptKey && (
                      <tr className={styles.encryptTableTbodyPassRow}>
                        <td>
                          <ActivationCode
                            activationCode={encryptDriveDetails[disc.id].encryptKey}
                            label="Recovery Key"
                            id="recovery-key"
                            copyMessage="Recovery Key copied to clipboard"
                            isLongVersion
                          />
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </Modal>
  );
};

export default EncryptDetailsModal;
