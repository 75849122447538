import { SettingTabs } from 'consts';
import { ISettings } from 'interfaces';

interface CustomResolutions {
  height: number;
  width: number;
}

interface Settings {
  init: ISettings | null;
  modified: ISettings | null;
}

export const onCustomResSave = (tabsContent: SettingTabs, domainSettings: Settings, customRes: CustomResolutions) => {
  const dimensionsCollRaw = tabsContent.general.dimension.collection;
  if (typeof dimensionsCollRaw !== 'object' || Array.isArray(dimensionsCollRaw)) {
    throw new Error('Invalid dimensions collection');
  }
  const dimensionsColl: { [key: string]: [string, string] } = dimensionsCollRaw as { [key: string]: [string, string] };
  const customResPresentKey = Object.values(dimensionsColl).findIndex((i: [string, string]) => i[1].includes('custom'));
  const customResKey = customResPresentKey ? customResPresentKey + 1 : Object.keys(dimensionsColl).length;
  const customResVal = `${customRes.width} x ${customRes.height} (custom)`;
  const dimensionsCollNew = {
    ...dimensionsColl,
    [customResKey]: [`${customResKey}`, customResVal],
    [customResKey + 1]: ['customResLink', 'Change custom resolution'],
  };

  const tabsContentNew = JSON.parse(JSON.stringify(tabsContent));
  tabsContentNew.general.dimension.collection = dimensionsCollNew;

  const domainSettingsNew: Settings = JSON.parse(JSON.stringify(domainSettings));
  domainSettingsNew.modified = {
    ...domainSettings.modified!,
    desktopHeight: customRes.height,
    desktopWidth: customRes.width,
    dimension: customResKey,
  };

  return { tabsContentNew, domainSettingsNew };
};
