import cn from 'classnames';
import { IBrandingContactInfo } from 'interfaces';
import styles from '../BrandedLinks.module.scss';

interface Props {
  contactInformation: IBrandingContactInfo;
}

const Contact = ({ contactInformation }: Props) => {
  const { contactInfo, address, city, state, zipCode, country } = contactInformation;

  return (
    <ul className={cn(styles.linksDropdownList, styles.contact)}>
      {contactInfo && <li>{contactInfo}</li>}
      {address && (
        <li>
          <span>Address: </span>
          <span>{address}</span>
        </li>
      )}
      {city && (
        <li>
          <span>City: </span>
          <span>{city}</span>
        </li>
      )}
      {state && (
        <li>
          <span>State: </span>
          <span>{state}</span>
        </li>
      )}
      {zipCode && (
        <li>
          <span>Zip Code: </span>
          <span>{zipCode}</span>
        </li>
      )}
      {country && (
        <li>
          <span>Country: </span>
          <span>{country}</span>
        </li>
      )}
    </ul>
  );
};

export default Contact;
