import { useEffect, useMemo, useState } from 'react';
import { Modal } from 'components';
import { getDomainStatus } from 'helpers';
import { corpAdmin, corpTechAdmin, custAdmin, techAdmin } from 'consts';
import { saveAs } from 'file-saver';
import { notify } from 'utils';
import ReportColumns from '../ReportColumns/ReportColumns';
import ReportDomains from '../ReportDomains/ReportDomains';
import { IDomain, ILicenseReport } from 'interfaces';
import { CheckBoxesChecked, ReportTypes } from '../../Assignments';
import { useAppDispatch, useAppSelector } from 'store';
import {
  generateApplicationAssignmentsReport,
  generateMachineAssignmentsReport,
  generateUserAssignmentsReport,
} from 'store/slices/reports';
import { ReportLoading } from 'pages/Reports/Reports';
import { SerializedError } from '@reduxjs/toolkit';

interface Props {
  domainId: string;
  isOpen: boolean;
  onRequestClose: () => void;
  type: ReportTypes;
  isDomainAzure: boolean;
  userRole: string;
  isCompanyPage: boolean;
  isLeastOneDomainHasApps: boolean;
  checkBoxesChecked: CheckBoxesChecked;
  setCheckBoxesChecked: React.Dispatch<React.SetStateAction<CheckBoxesChecked>>;
  toggleCheckBoxesChecked: (name: string) => void;
  checkedDomains: ILicenseReport[];
  setCheckedDomains: React.Dispatch<React.SetStateAction<ILicenseReport[]>>;
  primaryDomainData: ILicenseReport;
  setReportLoading: React.Dispatch<React.SetStateAction<ReportLoading>>;
}

const GenerateReportModal = ({
  domainId,
  isOpen,
  onRequestClose,
  type,
  isDomainAzure,
  userRole,
  isCompanyPage,
  isLeastOneDomainHasApps,
  checkBoxesChecked,
  toggleCheckBoxesChecked,
  checkedDomains,
  setCheckedDomains,
  primaryDomainData,
  setReportLoading,
  setCheckBoxesChecked,
}: Props) => {
  const dispatch = useAppDispatch();
  const { licenses } = useAppSelector((state) => state.reports);

  const [isLoading, setIsLoading] = useState(false);
  const isHideDomainList = [techAdmin, custAdmin, corpAdmin, corpTechAdmin].includes(userRole) || !isCompanyPage;
  const disableAppColumn = useMemo(() => checkedDomains.every((el) => !el.isAppPublishingEnabled), [checkedDomains]);

  useEffect(() => {
    if (type === ReportTypes.appAssignments) {
      setCheckedDomains((prevValue: ILicenseReport[]) =>
        prevValue.filter((el: ILicenseReport) => el.isAppPublishingEnabled),
      );
    }
  }, [licenses, setCheckedDomains, type]);

  useEffect(() => {
    if (checkedDomains.length > 0 && disableAppColumn) {
      setCheckBoxesChecked((prevValue) => ({
        ...prevValue,
        application: false,
        computersComputerGroups: true,
      }));
    }
    if (checkedDomains.length < 1 && disableAppColumn) {
      setCheckBoxesChecked((prevValue: CheckBoxesChecked) => ({ ...prevValue, application: false }));
    }

    if (type === ReportTypes.appAssignments) {
      setCheckBoxesChecked((prevValue: CheckBoxesChecked) => ({ ...prevValue, application: true }));
    }
  }, [checkedDomains, disableAppColumn, setCheckBoxesChecked, type]);

  const domainsForReport = licenses.filter((el) => {
    const { isDomainActive } = getDomainStatus(el as Partial<IDomain>);
    return isDomainActive;
  });

  const saveFile = (link: string) => {
    const fileName = link.split('/').slice(-1).toString();
    saveAs(`${link}`, `${fileName}`);
  };

  const confirmResult = async () => {
    try {
      setIsLoading(true);
      const domainIds = checkedDomains.map((el) => el.domainId);
      if (type === ReportTypes.userAssignments) {
        const exportReport =
          userRole === custAdmin || userRole === corpAdmin || userRole === corpTechAdmin || !isCompanyPage;
        const includeMachines = isDomainAzure || exportReport ? true : checkBoxesChecked.computersComputerGroups;
        const includeApplications = checkBoxesChecked.application;
        const data = { domainIds, includeMachines, includeApplications };
        const response = await dispatch(generateUserAssignmentsReport({ domainId, ...data })).unwrap();
        saveFile(response.downloadLink);
      }
      if (type === ReportTypes.machineAssignmnets) {
        const response = await dispatch(generateMachineAssignmentsReport({ domainId, domainIds })).unwrap();
        saveFile(response.downloadLink);
      }
      if (type === ReportTypes.appAssignments) {
        const response = await dispatch(generateApplicationAssignmentsReport({ domainId, domainIds })).unwrap();
        saveFile(response.downloadLink);
      }
      setIsLoading(false);
      notify.success('Your report is generated');
      onRequestClose();
    } catch (err: any) {
      if ((err as SerializedError).code === 'ECONNABORTED') {
        setReportLoading((prevValue: ReportLoading) => ({ ...prevValue, [type]: true }));
        setIsLoading(false);
        onRequestClose();
        notify.success(
          <div>
            <div>Your request is in progress</div>
            <div>Please refresh this page in a few minutes</div>
          </div>,
        );
      } else {
        notify.error(err?.message);
      }
    }
  };

  const disabled =
    !checkedDomains.length ||
    (type === ReportTypes.userAssignments &&
      !checkBoxesChecked.computersComputerGroups &&
      !checkBoxesChecked.application &&
      !isDomainAzure);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Generate Report"
      actionTitle="Export"
      actionDisabled={disabled}
      actionLoading={isLoading}
      enableOverflow
      actionFunc={confirmResult}>
      <ReportColumns
        checkBoxesChecked={checkBoxesChecked}
        toggleCheckBoxesChecked={toggleCheckBoxesChecked}
        type={type}
        isDomainAzure={isDomainAzure}
        isLeastOneDomainHasApps={isLeastOneDomainHasApps}
        checkedDomains={checkedDomains}
      />
      <ReportDomains
        licenses={domainsForReport}
        type={type}
        checkedDomains={checkedDomains}
        primaryDomainData={primaryDomainData}
        setNewCheckedDomains={(item: ILicenseReport[]) => setCheckedDomains(item)}
        isHideDomainList={isHideDomainList}
      />
    </Modal>
  );
};

export default GenerateReportModal;
