import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { createExtraReducersForResponses, createHttpRequestInitResult, http } from 'helpers/http';
import { IHttpRequestResult, ILogin } from 'interfaces';

interface ISignInPayload {
  mfaPin: string;
  password: string;
  userId: string;
}

interface IRegisterPersonalUserPayload {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  isMonthlyPaymentPlan: boolean;
  paymentMethodNonce: string;
  trialPeriod: number;
  monthlyPrice: number;
  monthlyMachineCount: number;
  annualPrice: number;
  annualMachineCount: number;
}

export const login = createAsyncThunk('auth/login', async (email: string) => {
  const { host } = window.location;
  const hostName = !process.env.REACT_APP_PUBLIC_URL?.includes(host) ? host : null;
  const response: AxiosResponse<ILogin> = await http.post('/api/login/performSigninValidation', { email, hostName });
  return response.data;
});

export const signIn = createAsyncThunk('auth/signIn', async ({ mfaPin, password, userId }: ISignInPayload) => {
  const response: AxiosResponse<string> = await http.post('/api/login/authenticateUser', {
    mfaPin,
    password,
    userId,
  });
  return response.data;
});

export const sendSignInNotification = createAsyncThunk('auth/sendSignInNotification', async (userId: string) => {
  await http.get(`/api/auth/RequestMfaCode?userId=${userId}`);
});

export const azureAdLogin = createAsyncThunk(
  'auth/azureAdLogin',
  async ({ email, token }: { email: string; token: string }) => {
    await http.post('/api/login/azureadlogin', { email, token });
  },
);

export const getRegisteredCustomerEmail = createAsyncThunk('auth/validateEmail', async (customerId: string) => {
  const response: AxiosResponse<string> = await http.get(
    `/api/RegisteredUser/GetJustRegisteredCustomerEmail/?customerId=${customerId}`,
  );
  return response.data;
});

export const resendVerificationEmail = createAsyncThunk(
  'auth/resendVerificationEmail',
  async ({ email, token }: { email?: string; token?: string }) => {
    const data = {
      email: email ? email : null,
      VerificationToken: token ? token : null,
    };
    await http.post('/api/auth/ResendVerificationEmail', data);
  },
);

export const registerPersonalUser = createAsyncThunk(
  'common/registerPersonalUser',
  async (payload: IRegisterPersonalUserPayload) => {
    await http.post('/api/RegisteredUser/RegisterPersonalUser', payload);
  },
);

export const logout = createAsyncThunk('auth/logout', async () => {
  await http.post('/api/auth/logout');
});

type IProcessEmailVerificationStatus =
  | 'Email validated successfully'
  | 'Email updated successfully'
  | 'Link is expired'
  | 'Invalid link';

export interface IProcessEmailVerificationResponse {
  title: IProcessEmailVerificationStatus;
  email: string;
  isPersonalAccount: boolean;
}

export const processEmailVerification = createAsyncThunk('auth/processEmailVerification', async (token: string) => {
  const response: AxiosResponse<IProcessEmailVerificationResponse> = await http.post(
    '/api/auth/processEmailVerification',
    token,
  );
  return response.data;
});

export const checkIfUserCanBeRegistered = createAsyncThunk('auth/checkIfUserCanBeRegistered', async (email: string) => {
  await http.get(`/api/RegisteredUser/UserCanBeRegistered?email=${email}`);
});

export interface IRegistrationDataResponse {
  trialPeriod: number;
  monthlyPrice: number;
  monthlyMachineCount: number;
  annualPrice: number;
  annualMachineCount: number;
  isPromo: boolean;
  promoEndDate: Date | null;
  clientToken: string;
}

export const getPersonalRegistrationData = createAsyncThunk('auth/getPersonalRegistrationData', async () => {
  const response: AxiosResponse<IRegistrationDataResponse> = await http.get(
    '/api/RegisteredUser/GetPersonalRegistrationData',
  );
  return response.data;
});

export const validateByWhois = createAsyncThunk('auth/validateByWhois', async (publicKey: string) => {
  const response: AxiosResponse<string> = await http.post('/api/DomainValidation/ValidateByWhois', publicKey);
  return response.data;
});

export const resendWhoisValidationEmail = createAsyncThunk(
  'auth/resendWhoisValidationEmail',
  async (publicKey: string) => {
    await http.get(`/api/DomainValidation/ResendWhoisValidationEmail?publicKey=${publicKey}`);
  },
);

interface IAuthState {
  signInData: ILogin;
  email: string;
  password: string;
  loginRequest: IHttpRequestResult<ILogin>;
  azureAdLoginRequest: IHttpRequestResult<void>;
  getRegisteredCustomerEmailRequest: IHttpRequestResult<string>;
  resendVerificationEmailRequest: IHttpRequestResult<void>;
  signInRequest: IHttpRequestResult<string>;
  sendSignInNotificationRequest: IHttpRequestResult<void>;
  registerPersonalUserRequest: IHttpRequestResult<void>;
  logoutRequest: IHttpRequestResult<void>;
  processEmailVerificationRequest: IHttpRequestResult<IProcessEmailVerificationResponse>;
  checkIfUserCanBeRegisteredRequest: IHttpRequestResult<void>;
  getPersonalRegistrationDataRequest: IHttpRequestResult<IRegistrationDataResponse>;
  validateByWhoisRequest: IHttpRequestResult<string>;
  resendWhoisValidationEmailRequest: IHttpRequestResult<void>;
}

const initialState: IAuthState = {
  signInData: {} as ILogin,
  email: '',
  password: '',
  loginRequest: createHttpRequestInitResult(),
  azureAdLoginRequest: createHttpRequestInitResult(),
  getRegisteredCustomerEmailRequest: createHttpRequestInitResult(),
  resendVerificationEmailRequest: createHttpRequestInitResult(),
  signInRequest: createHttpRequestInitResult(),
  sendSignInNotificationRequest: createHttpRequestInitResult(),
  registerPersonalUserRequest: createHttpRequestInitResult(),
  logoutRequest: createHttpRequestInitResult(),
  processEmailVerificationRequest: createHttpRequestInitResult(),
  checkIfUserCanBeRegisteredRequest: createHttpRequestInitResult(),
  getPersonalRegistrationDataRequest: createHttpRequestInitResult(),
  validateByWhoisRequest: createHttpRequestInitResult(),
  resendWhoisValidationEmailRequest: createHttpRequestInitResult(),
};

const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setEmail(state, action) {
      state.email = action.payload;
    },
    setPassword(state, action) {
      state.password = action.payload;
    },
  },
  extraReducers: (builder) => {
    createExtraReducersForResponses<IAuthState>(builder, login, 'loginRequest', (state, action) => {
      state.signInData = action.payload;
    });
    createExtraReducersForResponses<IAuthState>(builder, azureAdLogin, 'azureAdLoginRequest');
    createExtraReducersForResponses<IAuthState>(
      builder,
      getRegisteredCustomerEmail,
      'getRegisteredCustomerEmailRequest',
      (state, action) => {
        state.email = action.payload;
      },
    );
    createExtraReducersForResponses<IAuthState>(builder, resendVerificationEmail, 'resendVerificationEmailRequest');
    createExtraReducersForResponses<IAuthState>(builder, signIn, 'signInRequest', (state, action) => {
      const { email } = state;
      const pass = action.payload;
      if (email && pass) {
        localStorage.setItem('TG.SSO.LOGIN', email);
        localStorage.setItem('TG.SSO.PWD', action.payload);
      }
    });
    createExtraReducersForResponses<IAuthState>(builder, sendSignInNotification, 'sendSignInNotificationRequest');
    createExtraReducersForResponses<IAuthState>(
      builder,
      registerPersonalUser,
      'registerPersonalUserRequest',
      (state, action) => {
        state.email = action.meta.arg.email;
      },
    );
    createExtraReducersForResponses<IAuthState>(builder, logout, 'logoutRequest');
    createExtraReducersForResponses<IAuthState>(
      builder,
      processEmailVerification,
      'processEmailVerificationRequest',
      (state, action) => {
        state.email = action.payload.email;
      },
    );
    createExtraReducersForResponses<IAuthState>(
      builder,
      checkIfUserCanBeRegistered,
      'checkIfUserCanBeRegisteredRequest',
    );
    createExtraReducersForResponses<IAuthState>(
      builder,
      getPersonalRegistrationData,
      'getPersonalRegistrationDataRequest',
    );
    createExtraReducersForResponses<IAuthState>(builder, validateByWhois, 'validateByWhoisRequest');
    createExtraReducersForResponses<IAuthState>(
      builder,
      resendWhoisValidationEmail,
      'resendWhoisValidationEmailRequest',
    );
  },
});

export const { setEmail, setPassword } = AuthSlice.actions;

export default AuthSlice.reducer;
