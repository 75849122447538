import { CloseIcon, TriangleIcon } from 'assets/img';
import { DropdownObject, SwitchCheckbox } from 'components';
import SettingsCustomRes from './SettingsCustomRes/SettingsCustomRes';
import SettingsRedirectDrives from './SettingsRedirectDrives/SettingsRedirectDrives';
import styles from '../Settings.module.scss';
import { useAppDispatch, useAppSelector } from 'store';
import { onChangeSettings } from '../utils/onChangeSettings';
import { changeSettings } from 'store/slices/settings';
import { useMemo, useState } from 'react';
import { TabOption } from 'consts';

interface Props {
  onCustomResSave: (customRes: { width: number; height: number }) => void;
  item: TabOption<any> & { dependsOnZTP?: boolean };
  label: string;
  isHideZeroTrust: boolean;
  isZeroTrustMsgOpen: boolean;
  setIsZeroTrustMsgOpen: (isOpen: boolean) => void;
}

const TabContent = ({
  onCustomResSave,
  item,
  label,
  isHideZeroTrust,
  isZeroTrustMsgOpen,
  setIsZeroTrustMsgOpen,
}: Props) => {
  const dispatch = useAppDispatch();
  const { settings, currentDomain } = useAppSelector((state) => state.settings);
  const data = useMemo(() => settings.modified!, [settings.modified]);

  const [isCustomResOpen, setIsCustomResOpen] = useState(false);

  const changeSetting = (label: string, value: string | number | boolean) => {
    const domainSettingsNew = onChangeSettings(label, value, settings);
    dispatch(changeSettings(domainSettingsNew));
  };

  if (label === 'dimension') {
    return (
      <SettingsCustomRes
        key="item-custom-res"
        setIsCustomResOpen={setIsCustomResOpen}
        isCustomResOpen={isCustomResOpen}
        options={item.collection}
        defaultPos={+data.dimension}
        item={item}
        height={+data.desktopHeight}
        width={+data.desktopWidth}
        disabled={data.screenMode !== 1}
        onChange={(value: string | number) => changeSetting('dimension', value)}
        onSave={onCustomResSave}
      />
    );
  }

  if (label === 'sharedDrives') {
    return (
      <SettingsRedirectDrives
        key="item-custom-red"
        options={data.sharedDrives}
        item={item}
        isZeroTrustEnabled={data.isZeroTrustEnabled}
        disabled={item.dependsOnZTP && data.isZeroTrustEnabled}
        onChange={(value: string) => changeSetting('sharedDrives', value)}
      />
    );
  }

  if (label === 'isZeroTrustEnabled' && currentDomain === 'My Settings' && isHideZeroTrust) {
    if (data[label] && isZeroTrustMsgOpen) {
      return (
        <div key={label} className={styles.settingsMessage}>
          <div>
            <TriangleIcon />
            <p>You have limited access due to administrator settings</p>
          </div>
          <CloseIcon id="set-is-zero-trust-msg-open" onClick={() => setIsZeroTrustMsgOpen(false)} />
        </div>
      );
    }
    return false;
  }

  return (
    <div key={label} className={styles.settingsRow}>
      <div className={styles.settingsRowInfo}>
        <span className={styles.settingsRowInfoTitle}>{item.title}</span>
        {item.desc && <span className={styles.settingsRowInfoDesc}>{item.desc}</span>}
      </div>
      {item.collection ? (
        <DropdownObject
          id={`setting-${label}`}
          options={item.collection}
          defaultPos={data[label as keyof typeof data] as number}
          onChange={(key) => changeSetting(label, key)}
          width="16em"
          disabled={label === 'screenMode' && data[label] === 1 && isCustomResOpen}
        />
      ) : (
        <SwitchCheckbox
          id={label}
          checked={!!data[label as keyof typeof data]}
          disabled={item.dependsOnZTP && data.isZeroTrustEnabled}
          onChange={(e) => changeSetting(label, e.target.checked)}
        />
      )}
    </div>
  );
};

export default TabContent;
