import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Loader } from 'components';
import { notify } from 'utils';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import SendCode from './components/SendCode';
import { useAppDispatch } from 'store';
import { requestVerificationCodeByEmail } from 'store/slices/auth/mfa';
import styles from './ResetViaEmail.module.scss';
import { SerializedError } from '@reduxjs/toolkit';

const ResetViaEmail = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const fetchVerificationCode = async () => {
      try {
        await dispatch(requestVerificationCodeByEmail()).unwrap();
        notify.success('Email has been sent');
        setIsLoading(false);
      } catch (err: any) {
        setIsLoading(false);
        if ((err as SerializedError).code === '409') {
          notify.error(err.message);
          history.replace('/login');
        } else {
          notify.error(err.message);
        }
      }
    };
    fetchVerificationCode();
  }, []);

  return (
    <>
      <LoginHeader title="Reset Your MFA" hideSteps />
      {isLoading ? (
        <div className={styles.resetMfa}>
          <Loader id="loading-reset-mfa-email" />
        </div>
      ) : (
        <SendCode />
      )}
    </>
  );
};

export default ResetViaEmail;
