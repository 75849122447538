import { useState } from 'react';
import cn from 'classnames';
import { MsLogoIcon, ExternalLinkIcon } from 'assets/img';
import { useAppDispatch } from 'store';
import { notify } from 'utils';
import { msalLoginPopup } from 'store/slices/msal';
import styles from './MsLogin.module.scss';

interface Props {
  isAzureAd?: boolean;
  domainId: string;
  domainName: string;
  isActive: boolean;
  isOnboarding?: boolean;
}

const MsLogin = ({ isAzureAd = false, domainId, domainName, isActive, isOnboarding }: Props) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const isUpdateUser = ['workspace', 'company-management'].some((i) => window.location.hash.includes(i));

  const onLogin = async () => {
    if (isAzureAd) return false;
    try {
      setIsLoading(true);
      await dispatch(
        msalLoginPopup({
          scopes: ['User.Read.All', 'Group.Read.All'],
          prompt: 'select_account',
          state: JSON.stringify({ domainId, domainName, isUpdateUser }),
        }),
      ).unwrap();
      notify.success('Successfully connected');
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      notify.error(err.message);
    }
  };

  if (isOnboarding) {
    return (
      <button type="button" className={styles.msloginOnboarding} onClick={onLogin}>
        Microsoft Entra ID&nbsp;
        <ExternalLinkIcon />
      </button>
    );
  }

  return (
    <button type="button" className={cn(styles.mslogin, { [styles.msloginPositive]: isAzureAd })} onClick={onLogin}>
      <MsLogoIcon title="Microsoft Sign in" />
      <span>{isLoading && isActive ? 'Loading...' : isAzureAd ? 'Connected' : 'Connect to Entra ID'}</span>
    </button>
  );
};

export default MsLogin;
