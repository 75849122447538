import { DropdownArray, StatusMessage, SwitchCheckbox, Table } from 'components';
import { notify } from 'utils';
import { useAppDispatch, useAppSelector } from 'store';
import { IRememberMeUser, getRememberMeData } from 'store/slices/securityMgmt/remebmerMe';
import styles from './RememberDropdownTable.module.scss';
import { IQueryConfig } from 'interfaces';

interface Props {
  domainId: string;
  handleChangeRememberMeForUser: (isHeaderBtn: boolean, user?: IRememberMeUser) => void;
  selectedUsers: IRememberMeUser[];
  setSelectedUsers: (users: IRememberMeUser[]) => void;
  userWhoseStatusChanging: string;
}

const RememberDropdownTable = ({
  domainId,
  handleChangeRememberMeForUser,
  selectedUsers,
  setSelectedUsers,
  userWhoseStatusChanging,
}: Props) => {
  const dispatch = useAppDispatch();
  const { rememberMeData } = useAppSelector((state) => state.rememberMe);

  const queryViewBy = rememberMeData?.queryConfig?.viewBy;

  const onChangeView = async (value: string) => {
    try {
      const valueFrmt = value === 'All Users' ? 'All' : value.split('Remember Me ')[1].replace(/\s/g, '');
      const queryConfig = {
        pageNumber: 1,
        viewBy: valueFrmt,
        orderBy: 'displayName asc',
      };
      await dispatch(getRememberMeData({ domainId, query: queryConfig, _background: true })).unwrap();
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  const columns = [
    {
      checkbox: queryViewBy !== 'All',
      name: 'User',
      data: ({ displayName, upn }: { displayName: string; upn: string }) => (
        <div className={styles.tableWrap}>
          <div className={styles.tableName}>{displayName || 'N/A'}</div>
          <div className={styles.tableUpn}>{upn}</div>
        </div>
      ),
      sortable: 'displayName',
      search: 'Name',
      width: '40%',
    },
    {
      name: 'Remember Me Option',
      data: (user: IRememberMeUser) => (
        <div className={styles.tableStatus}>
          <SwitchCheckbox
            id={user.userId}
            name={user.userId}
            onChange={() => handleChangeRememberMeForUser(false, user)}
            checked={user.isRememberMeEnabled}
            disabled={selectedUsers.length > 0 || userWhoseStatusChanging === user.userId}
          />
          {user.isRememberMeEnabled ? (
            <span className={styles.tableStatusEnabled}>ON</span>
          ) : (
            <span className={styles.tableStatusDisabled}>OFF</span>
          )}
        </div>
      ),
      sortable: 'isRememberMeEnabled',
      width: '60%',
    },
  ];

  const dispatchAction = async (query: IQueryConfig) => {
    await dispatch(getRememberMeData({ domainId: domainId, query: query, _background: true })).unwrap();
  };

  return (
    <>
      {selectedUsers.length <= 0 && (
        <div className={styles.dropdown}>
          <DropdownArray
            id="ra-app-select"
            isSmall={false}
            isSearch={false}
            isFilter
            label="View by:"
            options={['All Users', 'Remember Me Disabled', 'Remember Me Enabled']}
            defaultValueHtml={
              queryViewBy === 'All' ? (
                <>
                  All Users <b>({rememberMeData?.pageMeta?.TotalCount})</b>
                </>
              ) : (
                <>
                  Remember Me {queryViewBy} <b>({rememberMeData?.pageMeta?.TotalCount})</b>
                </>
              )
            }
            onChange={(value) => onChangeView(value as string)}
            width="20.3125em"
          />
        </div>
      )}
      {(!rememberMeData?.data || rememberMeData.data.length < 1) && !rememberMeData?.queryConfig?.searchQuery ? (
        <div className={styles.statusMessage}>
          <StatusMessage header="No items available">Change view selection</StatusMessage>
        </div>
      ) : (
        <Table<IRememberMeUser>
          data={rememberMeData}
          selectedItems={selectedUsers}
          setSelectedItems={setSelectedUsers}
          columns={columns}
          id="remember-me-table"
          dataKey="userId"
          dispatchAction={dispatchAction}
          className={styles.table}
        />
      )}
    </>
  );
};

export default RememberDropdownTable;
