import { forwardRef, useRef } from 'react';
import cn from 'classnames';
import { useBrandingColors } from 'hooks';
import styles from './Button.module.scss';
import { useAppSelector } from 'store';
import { invertColor } from 'pages/Profile/pages/Branding/helpers/colorsHelper';

export type ButtonType = 'submit' | 'button' | 'reset';
export type ButtonVariant = 'primary' | 'secondary' | 'link' | 'delete' | 'deleteOutline';

interface Props {
  id?: string;
  type?: ButtonType;
  variant?: ButtonVariant;
  outline?: boolean;
  rounded?: boolean;
  icon?: JSX.Element;
  iconPosition?: 'left' | 'right';
  isLoading?: boolean;
  isDisabled?: boolean;
  style?: object;
  tabIndex?: number;
  size?: '32' | '40' | '48';
  onClick?: () => void;
  className?: any;
  children?: any;
}

const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      id,
      size,
      className,
      type = 'button',
      variant = 'primary',
      rounded,
      outline,
      icon,
      iconPosition = 'left',
      isLoading,
      isDisabled,
      style,
      tabIndex,
      onClick,
      children,
    }: Props,
    ref,
  ) => {
    const { branding } = useAppSelector((state) => state.user);
    const isInverted = invertColor(branding?.buttonColor);

    const nodeRef = useRef<HTMLDivElement | null>(null);
    const btnClass = cn({
      [styles.button]: variant !== 'link',
      [styles.buttonSize32]: size === '32',
      [styles.buttonSize40]: size === '40',
      [styles.buttonSize48]: size === '48',
      [styles.buttonPrimary]: variant === 'primary',
      [styles.buttonSecondary]: variant === 'secondary',
      [styles.buttonDeleteOutline]: variant === 'deleteOutline',
      [styles.buttonDelete]: variant === 'delete',
      [styles.buttonLink]: variant === 'link',
      [styles.buttonRounded]: rounded,
      [styles.buttonOutline]: outline,
      [styles.buttonLoading]: isLoading,
      [styles.buttonDisabled]: isDisabled || isLoading,
      [styles.buttonIconRight]: iconPosition === 'right',
      [className]: className,
    });

    const colorStyles = useBrandingColors();

    const brandingStyles = {
      ...colorStyles,
      ...style,
    };

    return (
      <button
        ref={ref}
        id={id}
        type={type === 'button' ? 'button' : 'submit'}
        onClick={onClick}
        style={branding?.isActive && variant === 'primary' && !outline ? brandingStyles : style}
        tabIndex={isDisabled || isLoading ? -1 : tabIndex || undefined}
        className={btnClass}
        disabled={isDisabled || isLoading}>
        {icon ? (
          <span className={styles.buttonIcon}>
            {isLoading && (
              <span className={styles.buttonLoader} ref={nodeRef}>
                <svg className={styles.spinner} viewBox="0 0 50 50">
                  <circle
                    className={styles.spinnerPath}
                    style={branding?.isActive && isInverted ? { stroke: '#000' } : { stroke: '#fff' }}
                    cx="25"
                    cy="25"
                    r="20"
                    fill="none"
                    strokeWidth="5"
                  />
                </svg>
              </span>
            )}
            {icon} {children}
          </span>
        ) : (
          <span>
            {isLoading && (
              <span className={styles.buttonLoader} ref={nodeRef}>
                <svg id="loading" className={styles.spinner} viewBox="0 0 50 50">
                  <circle
                    className={styles.spinnerPath}
                    style={branding?.isActive && isInverted ? { stroke: '#000' } : { stroke: '#fff' }}
                    cx="25"
                    cy="25"
                    r="20"
                    fill="none"
                    strokeWidth="5"
                  />
                </svg>
              </span>
            )}
            {children}
          </span>
        )}
      </button>
    );
  },
);

Button.displayName = 'Button';

export default Button;
