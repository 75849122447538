import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ErrorBoundary, Loader } from 'components';
import { toastSettings } from 'consts';
import { handleEmailValidation } from 'helpers';
import { useAppDispatch } from 'store';
import { useEffect, useState } from 'react';
import { checkIsUserAuthenticated } from 'store/slices/userSlice';
import Routes from 'containers/Routes';

const App = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    await dispatch(checkIsUserAuthenticated());
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (handleEmailValidation(pathname) || isLoading) return <Loader id="loading-app" />;

  return (
    <ErrorBoundary>
      <Routes />
      <ToastContainer {...toastSettings} />
      <div id="tooltip-container" />
    </ErrorBoundary>
  );
};

export default App;
