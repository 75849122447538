export const spAdmin = 'spAdmin';
export const spAdminNotActivated = 'spAdminNotActivated';
export const spTechAdmin = 'spTechAdmin';
export const techAdmin = 'techAdmin';
export const spTechUser = 'spTechUser';
export const custAdmin = 'custAdmin';
export const custUser = 'custUser';
export const corpAdmin = 'corpAdmin';
export const corpTechAdmin = 'corpTechAdmin';
export const corpTechUser = 'corpTechUser';
export const personalUser = 'personalUser';
export const freePersonalUser = 'freePersonalUser';
export const trugridAdmin = 'trugridAdmin';
