import { Modal } from 'components';
import { useAppSelector } from 'store';
import { IModalData, TypeAction } from '../../MfaMgmt';

interface Props {
  modalData: IModalData;
  toggleShowMfaModal: (type: TypeAction) => void;
  onChangeMfa: (type: TypeAction, modalData: IModalData) => void;
  showEnableMfaModal: boolean;
  showDisableMfaModal: boolean;
}

const ToggleEnableMfaModal = ({
  modalData,
  toggleShowMfaModal,
  onChangeMfa,
  showEnableMfaModal,
  showDisableMfaModal,
}: Props) => {
  const { isLoading } = useAppSelector((store) => store.mfaMgmt.changeUserMfaStatusRequest);

  const user =
    modalData?.usersToUpdate.length > 1 ? 'selected users' : <b>{modalData?.usersToUpdate[0].displayName}</b>;

  const toggleMfa = () => onChangeMfa(modalData.type, modalData);
  const actionEnable = modalData.type === 'Enable MFA';

  return (
    <Modal
      isOpen={showEnableMfaModal || showDisableMfaModal}
      onRequestClose={() => toggleShowMfaModal(modalData.type)}
      contentLabel={actionEnable ? 'Enable MFA' : 'Disable MFA'}
      actionLoading={isLoading}
      actionTitle={actionEnable ? 'Enable MFA' : 'Disable MFA'}
      actionFunc={toggleMfa}
      variantBtn="primary">
      <div style={{ display: 'flex' }}>
        <div className="truncate-text">
          Do you really want to {actionEnable ? 'enable' : 'disable'} MFA for {user}
        </div>
        ?
      </div>
    </Modal>
  );
};

export default ToggleEnableMfaModal;
