import { copyToClipboard } from 'utils';
import { useState } from 'react';
import { CopyIcon, PassHideIcon, PassShowIcon } from 'assets/img';
import Button from 'components/Button/Button';
import cn from 'classnames';
import styles from './ActivationCode.module.scss';

interface Props {
  activationCode?: string;
  label?: string;
  id?: string;
  copyMessage: string;
  isLongVersion?: boolean;
  showCode?: boolean;
  className?: any;
}

const ActivationCode = ({
  label,
  activationCode,
  id,
  copyMessage,
  isLongVersion,
  showCode = true,
  className,
}: Props) => {
  const [isHidden, setIsHidden] = useState(showCode);

  const toggleShowCode = () => setIsHidden(!isHidden);
  const handleCopyCode = () => copyToClipboard(activationCode || '', copyMessage);

  const activation = cn(styles.activation, { [styles.activationLong]: isLongVersion, [className]: className });
  const textWrapper = cn(styles.textWrapper, { [styles.textWrapperColumn]: isLongVersion });
  const activationActions = cn(styles.activationActions, { [styles.activationActionsLong]: isLongVersion });

  return (
    <div className={activation}>
      <div className={textWrapper}>
        {label && label}
        <div className={styles.activationInput} id={id || 'activation-code'}>
          {!isHidden ? activationCode || 'Not applicable due to TPM' : '•••••••••'}
        </div>
      </div>
      {!!activationCode && (
        <div className={activationActions}>
          <Button
            type="button"
            id="show-activation-code"
            variant="link"
            onClick={toggleShowCode}
            className={styles.activationActionsBtn}>
            {isHidden ? <PassShowIcon /> : <PassHideIcon />}
          </Button>
          <Button
            type="button"
            id="copy-activation-code"
            variant="link"
            onClick={handleCopyCode}
            className={styles.activationActionsBtn}>
            <CopyIcon />
          </Button>
        </div>
      )}
    </div>
  );
};

export default ActivationCode;
