import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Loader, TextInput } from 'components';
import { isFormValid } from 'helpers';
import { useOutsideClick } from 'hooks';
import { notify } from 'utils';
import LoginHeader from 'pages/Login/components/LoginHeader/LoginHeader';
import { useAppDispatch, useAppSelector } from 'store';
import { resetPasswordSetNewPassword, validateResetByEmail } from 'store/slices/auth/resetPassword';
import { signOut } from 'store/slices/userSlice';
import styles from './SetNewPassword.module.scss';
import { SerializedError } from '@reduxjs/toolkit';

interface Props {
  setError: React.Dispatch<React.SetStateAction<string>>;
}

const SetNewPassword = ({ setError }: Props) => {
  const dispatch = useAppDispatch();
  const { isAuth } = useAppSelector((state) => state.user);
  const { userId: signInUserId } = useAppSelector((state) => state.auth.signInData);
  const { isLoading } = useAppSelector((state) => state.resetPassword.validateResetByEmailRequest);
  const { isLoading: isUpdating } = useAppSelector((state) => state.resetPassword.resetPasswordSetNewPasswordRequest);
  const { token }: { token: string } = useParams();

  const isEmailRequest = !!token;

  const history = useHistory();
  const [formValues, setFormValues] = useState(['', '']);
  const [formErrors, setFormErrors] = useState(['', '']);
  const [userId, setUserId] = useState(signInUserId || '');
  const newPassRef = useRef<HTMLInputElement | null>(null);
  const confirmPassRef = useRef<HTMLInputElement | null>(null);
  const tooltipPassRef = useRef<HTMLDivElement | null>(null);

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  useOutsideClick(tooltipPassRef, () => setIsTooltipOpen(false));

  const signOutUser = async () => {
    await dispatch(signOut()).unwrap();
    history.replace('/login');
  };

  useEffect(() => {
    if (isAuth) {
      signOutUser();
    }
  }, [isAuth]);

  useEffect(() => {
    const handleValidateResetByEmail = async () => {
      try {
        const data = await dispatch(validateResetByEmail(token)).unwrap();
        setUserId(data);
      } catch (err: any) {
        if (err.message === 'Link is expired') {
          setError('expired');
        }
        if (err.message === 'Invalid link') {
          setError('used');
        } else {
          setError('error');
        }
      }
    };
    if (token) {
      handleValidateResetByEmail();
    }
  }, [setError, token]);

  const onClearError = (index: number) => {
    if (index === 0) {
      setIsTooltipOpen(true);
    }
    setFormErrors(['', '']);
  };

  const onUpdate = (value: string, index: number) => {
    const passValuesNew = formValues.map((i, ind) => (index === ind ? value : i));
    setFormValues(passValuesNew);
  };

  const onClearValue = (index: number, ref: React.RefObject<HTMLInputElement>) => {
    ref.current?.focus();

    const passValuesNew = formValues.map((i, ind) => (index === ind ? '' : i));
    setFormValues(passValuesNew);

    if (formErrors[index]) onClearError(index);
  };

  const onSetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();

      const { errors, isValid } = isFormValid(formValues, formErrors);
      if (errors) {
        setFormErrors(errors);
      }
      if (isValid) {
        const data = await dispatch(
          resetPasswordSetNewPassword({ userId, password: formValues[0], isEmailRequest }),
        ).unwrap();
        history.replace('/password-success', { message: data });
      }
    } catch (err: any) {
      if ((err as SerializedError).code === '403') {
        setError('exceeded');
      } else if ((err as SerializedError).code === '406') {
        setError('offline');
      } else {
        notify.error(err.message);
        setFormErrors([' ', ' ']);
      }
    }
  };

  const passwordTooltip = () => (
    <>
      <h5>We recommend:</h5>
      <ul>
        <li>at least 10 characters</li>
        <li>both lower and uppercase letters</li>
        <li>numbers and special characters</li>
        <li>not repeating any previously used password</li>
      </ul>
    </>
  );

  if (isLoading) {
    return (
      <div className={styles.setPass}>
        <Loader id="loading-set-new-password" />
      </div>
    );
  }

  return (
    <form method="POST" onSubmit={onSetPassword} className={styles.setPass} noValidate>
      <LoginHeader title="Set New Password" hideSteps idle back={() => history.replace('/login')} />
      <div className={styles.setPassWrap} ref={tooltipPassRef}>
        <TextInput
          ref={newPassRef}
          placeholder="Enter New Password"
          id="enter-new-password"
          type="password"
          autoComplete="new-password"
          hasView
          value={formValues[0]}
          error={formErrors[0]}
          onChange={(e) => onUpdate(e.target.value, 0)}
          onClear={() => onClearValue(0, newPassRef)}
          onFocus={() => onClearError(0)}
        />
        {isTooltipOpen && <div className={styles.setPassTooltip}>{passwordTooltip()}</div>}
      </div>
      <div className={styles.setPassWrap}>
        <TextInput
          ref={confirmPassRef}
          id="confirm-new-password"
          placeholder="Confirm New Password"
          autoComplete="new-password"
          type="password"
          hasView
          value={formValues[1]}
          error={formErrors[1]}
          onChange={(e) => onUpdate(e.target.value, 1)}
          onClear={() => onClearValue(1, confirmPassRef)}
          onFocus={() => onClearError(1)}
        />
      </div>
      <Button
        id="set-new-password"
        size="48"
        type="submit"
        variant="primary"
        isDisabled={formValues.includes('')}
        isLoading={isUpdating}
        className={styles.setPassButton}>
        Set New Password
      </Button>
    </form>
  );
};

export default SetNewPassword;
