import { useState, useCallback, useEffect } from 'react';

interface IUseModal<T> {
  isOpen: boolean;
  openModal: (data?: T) => void;
  closeModal: () => void;
  modalData: T | null;
}

const useModal = <T>(): IUseModal<T> => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState<T | null>(null);

  const openModal = useCallback((data?: T) => {
    if (data) {
      setModalData(data);
    }
    setIsOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setModalData(null);
    }
  }, [isOpen]);

  return { isOpen, openModal, closeModal, modalData };
};

export default useModal;
