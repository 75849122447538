import { useEffect, useState } from 'react';
import { Loader, Modal } from 'components';
import ModalForm from './components/ModalForm';
import { notify } from 'utils';
import { useAppDispatch, useAppSelector } from 'store';
import { getContactInfo } from 'store/slices/branding';
import styles from './ModalBrandingInfo.module.scss';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  domainId: string;
}

const ModalBrandingInfo = ({ isOpen, onRequestClose, domainId }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state) => state.branding.getContactInfoRequest);

  useEffect(() => {
    const getBrandingContactInfo = async () => {
      try {
        await dispatch(getContactInfo(domainId)).unwrap();
      } catch (err: any) {
        notify.error(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    getBrandingContactInfo();
    return () => setIsLoading(false);
  }, []);

  if (!data) return null;

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} contentLabel="Add Branding Information" enableOverflow>
      {isLoading ? (
        <div className={styles.loaderWrap}>
          <Loader id="loading-branding-info" />
        </div>
      ) : (
        <ModalForm brandingContactInfo={data} onRequestClose={onRequestClose} domainId={domainId} />
      )}
    </Modal>
  );
};

export default ModalBrandingInfo;
