import cn from 'classnames';
import { useForm, useOutsideClick } from 'hooks';
import { Button, SwitchCheckbox } from 'components';
import { toast } from 'react-toastify';
import { useRef, useState } from 'react';
import { CloseIcon } from 'assets/img';
import { notify, truncateString } from 'utils';
import styles from '../ModalBrandingInfo.module.scss';
import { IBrandingContactInfo } from 'interfaces';
import {
  checkIfObjectsAreNotEqual,
  getContactInputs,
  getSocialsInputs,
  getSupportInputs,
} from '../helpers/brandingHelpers';
import { useAppDispatch, useAppSelector } from 'store';
import { updateContactInfo } from 'store/slices/branding';

interface Props {
  brandingContactInfo: IBrandingContactInfo;
  onRequestClose: () => void;
  domainId: string;
}

const ModalForm = ({ brandingContactInfo, onRequestClose, domainId }: Props) => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.branding.updateContactInfoRequest);
  const [socialActive, setSocialActive] = useState('');
  const { formData, handleInputChange, handleClearValue } = useForm<IBrandingContactInfo>(brandingContactInfo);

  const socialInputRef = useRef<HTMLDivElement | null>(null);
  useOutsideClick(socialInputRef, () => socialActive && setSocialActive(''));

  const objectsAreNotEqual = checkIfObjectsAreNotEqual(brandingContactInfo, formData);

  const {
    contactInfo,
    address,
    city,
    state,
    country,
    zipCode,
    phoneNumber,
    emailAddress,
    website,
    showOnDesktopConnectors,
    facebookLink,
    linkedinLink,
    twitterLink,
    youtubeLink,
  } = formData;

  const contactInputs = getContactInputs(address, city, state, country, zipCode);
  const supportInputs = getSupportInputs(phoneNumber, emailAddress, website);
  const socialsInputs = getSocialsInputs(facebookLink, linkedinLink, twitterLink, youtubeLink);

  const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    try {
      e?.preventDefault();
      await dispatch(updateContactInfo({ formData, domainId })).unwrap();
      toast.success('Company branding has been updated');
      onRequestClose();
    } catch (err: any) {
      notify.error(err.message);
    }
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.formTitle}>Contact</div>
      <div className={styles.formControl}>
        <div className={styles.formWrap}>
          <textarea
            onChange={handleInputChange}
            value={typeof contactInfo === 'string' ? contactInfo : ''}
            id="contactInfo"
            maxLength={169}
            name="contactInfo"
            placeholder="Contact Information"
            className={cn(styles.formInput, styles.textarea)}
          />
          <div className={styles.formMaxLegth}>
            {contactInfo?.length || 0}
            <span>/169</span>
          </div>
        </div>
        {contactInputs.map(({ name, value, placeholder }) => (
          <div key={name} className={cn(styles.formWrap)}>
            <input
              onChange={handleInputChange}
              value={typeof value === 'string' ? value : ''}
              type="text"
              id={name}
              name={name}
              placeholder={placeholder}
              className={styles.formInput}
            />
          </div>
        ))}
      </div>
      <div className={styles.formTitle}>Support</div>
      <div className={styles.formControl}>
        {supportInputs.map(({ name, value, maxLength, placeholder }) => (
          <div key={name} className={cn(styles.formWrap)}>
            <input
              type="text"
              onChange={handleInputChange}
              value={typeof value === 'string' ? value : ''}
              maxLength={maxLength}
              id={name}
              name={name}
              placeholder={placeholder}
              className={styles.formInput}
            />
          </div>
        ))}
      </div>
      <div className={styles.formTitle}>Connect with us</div>
      <div className={styles.formControl}>
        {socialsInputs.map(({ name, value, placeholder, Icon }) => (
          <div key={name} className={cn(styles.formWrap)}>
            <div className={styles.formSocials}>
              <div>
                <Icon />
              </div>
              <div className={styles.formSocialsLink}>
                <div className={styles.formSocialsName}>{placeholder}</div>
                {socialActive === name ? (
                  <div className={styles.formSocialsWrap} ref={socialInputRef}>
                    <input
                      autoFocus
                      onChange={handleInputChange}
                      value={typeof value === 'string' ? value : ''}
                      id={name}
                      name={name}
                      className={styles.formSocialsInput}
                    />
                    {value && (
                      <Button
                        variant="link"
                        id={`remove-${name.toLowerCase()}`}
                        icon={<CloseIcon />}
                        className={styles.formSocialsRemove}
                        onClick={() => handleClearValue(name)}
                      />
                    )}
                  </div>
                ) : (
                  <div className={styles.formSocialsWrap} style={{ gap: 0 }}>
                    <Button
                      variant="link"
                      id={`add-${name.toLowerCase()}`}
                      className={styles.formSocialsValue}
                      onClick={() => setSocialActive(name)}>
                      {truncateString(value, false, 50) || 'Add link'}
                    </Button>
                    {value && (
                      <Button
                        variant="link"
                        id={`remove-${name.toLowerCase()}`}
                        icon={<CloseIcon />}
                        className={styles.formSocialsRemove}
                        onClick={() => handleClearValue(name)}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={cn(styles.formActions, styles.withSwitcher)}>
        <div className={styles.switcher}>
          <SwitchCheckbox
            id="showOnDesktopConnectors"
            name="showOnDesktopConnectors"
            checked={showOnDesktopConnectors}
            onChange={handleInputChange}
          />
          <p className={styles.switcherTitle}>Show on Desktop Connectors</p>
        </div>
        <div className={styles.formActionsButtons}>
          <Button variant="secondary" id="modal-cancel" size="40" onClick={onRequestClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            id="modal-action"
            className={cn(styles.formActionsButton, {
              [styles.loading]: isLoading,
            })}
            isLoading={isLoading}
            isDisabled={objectsAreNotEqual}
            variant="primary"
            size="40">
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};

export default ModalForm;
