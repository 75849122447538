import { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { uuidv4 } from 'utils';
import { LoaderSmallIcon } from 'assets/img';
import styles from './CodeInput.module.scss';

const ENTER_KEY = 13;
const BACKSPACE_KEY = 8;
const LEFT_ARROW_KEY = 37;
const UP_ARROW_KEY = 38;
const RIGHT_ARROW_KEY = 39;
const DOWN_ARROW_KEY = 40;
const E_KEY = 69;

interface Props {
  fields: number;
  value: string;
  setValue: (value: string) => void;
  errorMessage: string;
  isError: boolean;
  clearError: () => void;
  autoFocus?: boolean;
  isDisable: boolean;
  handleSend: (value: string) => void;
  sendOnKeyDown?: boolean;
}

const CodeInput = ({
  fields = 6,
  value,
  setValue,
  errorMessage,
  isError,
  clearError,
  autoFocus = true,
  isDisable,
  handleSend,
  sendOnKeyDown,
}: Props) => {
  const [input, setInput] = useState<string[]>([]);
  const uuid = uuidv4();
  const textInput: Array<HTMLInputElement | null> = Array(fields).fill(null);
  const firstInputRef = useRef<HTMLInputElement | null>(null);
  const hasSent = useRef(false);

  useEffect(() => {
    if (value.length === fields && !sendOnKeyDown && !hasSent.current) {
      handleSend(value);
      hasSent.current = true;
    }
  }, [value, fields, handleSend, sendOnKeyDown]);

  useEffect(() => {
    if (isError) {
      setInput(new Array(fields).fill(''));
      firstInputRef.current?.focus();
      hasSent.current = false;
    }
  }, [isError, fields]);

  useEffect(() => {
    if (input.length < 1) {
      for (let i = 0; i < Number(fields); i += 1) {
        setInput((oldArray) => [...oldArray, value[i] || '']);
      }
    }
  }, []);

  const handleSendOnKeyDown = () => {
    if (sendOnKeyDown && value.length === fields && !hasSent.current) {
      handleSend(value);
      hasSent.current = true;
    }
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isError) clearError();

    let val = e.target.value;
    val = val.replace(/[^\d]/g, '');

    if (val !== '') {
      const newInput = input.slice();

      if (val.length > 1) {
        val.split('').forEach((chart, i) => {
          if (i < fields) {
            newInput[i] = chart;
          }
        });
      } else {
        newInput[Number(e.target.dataset.id)] = val;
      }

      newInput.forEach((s, i) => {
        if (textInput[i]) {
          textInput[i]!.value = s;
        }
      });

      const newTarget =
        textInput[
          (e.target.dataset.id as any) < input.length ? Number(e.target.dataset.id) + 1 : (e.target.dataset.id as any)
        ];

      if (newTarget) {
        newTarget.focus();
        newTarget.select();
      }

      setValue(newInput.join(''));
      setInput(newInput);
      hasSent.current = false;
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const target = Number(e.currentTarget.dataset.id);
    const nextTarget = textInput[target + 1];
    const prevTarget = textInput[target - 1];

    let newInput;
    let val;

    switch (e.keyCode) {
      case ENTER_KEY:
        e.preventDefault();
        handleSendOnKeyDown();
        break;

      case BACKSPACE_KEY:
        e.preventDefault();
        if (textInput[target]) {
          textInput[target]!.value = '';
          newInput = input.slice();
          newInput[target] = '';
          val = newInput.join('');

          setInput(newInput);
          setValue(val);
          hasSent.current = false;

          if (textInput[target]!.value === '') {
            if (prevTarget) {
              prevTarget.focus();
              prevTarget.select();
            }
          }
        }
        break;

      case LEFT_ARROW_KEY:
        e.preventDefault();
        if (prevTarget) {
          prevTarget.focus();
          prevTarget.select();
        }
        break;

      case RIGHT_ARROW_KEY:
        e.preventDefault();
        if (nextTarget) {
          nextTarget.focus();
          nextTarget.select();
        }
        break;

      case UP_ARROW_KEY:
        e.preventDefault();
        break;

      case DOWN_ARROW_KEY:
        e.preventDefault();
        break;

      case E_KEY:
        if (e.currentTarget.type === 'number') {
          e.preventDefault();
        }
        break;

      default:
        break;
    }
  };

  return (
    <div>
      <div className={styles.codeInputWrap}>
        {input.map((val, i) => (
          <input
            ref={(ref) => {
              textInput[i] = ref;
              if (i === 0) {
                firstInputRef.current = ref;
              }
            }}
            id={`${uuid}-${i}`}
            key={`input_${i}`}
            data-id={i}
            min={1}
            max={9}
            maxLength={input.length === i + 1 ? 1 : input.length}
            value={val}
            autoFocus={autoFocus && i === 0}
            className={cn(styles.codeInput, { [styles.disabled]: isDisable, [styles.error]: isError })}
            onChange={handleChangeInput}
            onKeyDown={handleKeyDown}
          />
        ))}
        {isDisable && (
          <div className={styles.codeInputLoader}>
            <LoaderSmallIcon />
          </div>
        )}
      </div>
      {isError && <div className={styles.codeInputError}>{errorMessage || 'The code you entered is invalid'}</div>}
    </div>
  );
};

export default CodeInput;
