import { corpAdmin, corpTechAdmin } from 'consts';
import TooltipInfo from '../../../../components/TooltipInfo/TooltipInfo';
import styles from './TotalLicenses.module.scss';
import { ICorporateSubscription } from 'interfaces';

interface Props {
  licensesState: ICorporateSubscription;
  role: string;
  canceledLicensesMsg: string;
}

const TotalLicenses = ({ licensesState, role, canceledLicensesMsg }: Props) => (
  <div className={styles.info}>
    <div className={styles.infoSection}>
      <div className={styles.infoSectionText}>
        Total Purchased Licenses:{' '}
        <b>{licensesState.totalPurchasedBitLockerLicenses + licensesState.totalPurchasedRdpLicenses}</b>
      </div>
      {![corpAdmin, corpTechAdmin].includes(role) && (
        <TooltipInfo
          name="totalPurchased"
          content="Includes total allocated to customer domains"
          position="right"
          icon="question"
          isHtml={false}
        />
      )}
    </div>

    {![corpAdmin, corpTechAdmin].includes(role) && (
      <div className={styles.infoSection}>
        <div className={styles.infoSectionText}>
          SecureRDP Free Licenses: <b>{licensesState.numberOfFreeLicenses}</b>
        </div>
      </div>
    )}

    {!!licensesState.canceledBitLockerLicenses && (
      <div className={styles.infoSection}>
        <div className={styles.infoSectionText}>
          Canceled BitLocker Licenses: <b>{licensesState.canceledBitLockerLicenses}</b>
        </div>
        <TooltipInfo name="totalCanceled" content={canceledLicensesMsg} position="right" icon="question" isHtml />
      </div>
    )}

    {!!licensesState.canceledRdpLicenses && (
      <div className={styles.infoSection}>
        <div className={styles.infoSectionText}>
          Canceled SecureRDP Licenses: <b>{licensesState.canceledRdpLicenses}</b>
        </div>
        <TooltipInfo name="totalCanceled" content={canceledLicensesMsg} position="right" icon="question" isHtml />
      </div>
    )}
  </div>
);

export default TotalLicenses;
