import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IHttpRequestResult } from 'interfaces/api';
import { http, createHttpRequestInitResult, createExtraReducersForResponses } from 'helpers/http';
import { ISentry } from 'interfaces';
import { AxiosResponse } from 'axios';

export interface IDomainStatus {
  isAdSynced: boolean;
  origin: number;
  status: number;
  sentries: ISentry[];
}

export const getDomainConnectStatus = createAsyncThunk(
  'domainMgmt/getDomainConnectStatus',
  async (domainId: string) => {
    const response: AxiosResponse<IDomainStatus> = await http.get(`/api/domains/GetDomainStatus?domainId=${domainId}`);
    return response.data;
  },
);

export const refreshAzureAdData = createAsyncThunk(
  'domainMgmt/refreshAzureAdData',
  async ({ domainId, authToken }: { domainId: string; authToken: string }) => {
    await http.post('/api/Domains/ForcedAzureAdDataRefresh', { domainId, authToken });
  },
);

export const disconnectOnPremise = createAsyncThunk('domainMgmt/disconnectOnPremise', async (domainId: string) => {
  await http.post('/api/DomainManagement/DisconnectOnPremiseAd', {
    domainId,
  });
});

interface IDomainMgmtState {
  getDomainConnectStatusRequest: IHttpRequestResult<IDomainStatus>;
  refreshAzureAdDataRequest: IHttpRequestResult<void>;
  disconnectOnPremiseRequest: IHttpRequestResult<void>;
}

const initialState: IDomainMgmtState = {
  getDomainConnectStatusRequest: createHttpRequestInitResult(),
  refreshAzureAdDataRequest: createHttpRequestInitResult(),
  disconnectOnPremiseRequest: createHttpRequestInitResult(),
};

export const domainMgmtSlice = createSlice({
  name: 'domainMgmt',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createExtraReducersForResponses<IDomainMgmtState>(builder, getDomainConnectStatus, 'getDomainConnectStatusRequest');
    createExtraReducersForResponses<IDomainMgmtState>(builder, refreshAzureAdData, 'refreshAzureAdDataRequest');
    createExtraReducersForResponses<IDomainMgmtState>(builder, disconnectOnPremise, 'disconnectOnPremiseRequest');
  },
});

// export const {} = domainMgmtSlice.actions;

export default domainMgmtSlice.reducer;
