import { useRef } from 'react';
import cn from 'classnames';
import { Button } from 'components';
import { useObserver } from 'hooks';
import styles from './HeaderActions.module.scss';
import { getActionType } from '../../utils/timeoutUtils';
import { ITimeoutMgmtData } from 'store/slices/securityMgmt/timeoutMgmt';

interface Props {
  selectedUsers: any[];
  setSelectedUsers: (users: any[]) => void;
  timeoutMgmtData: ITimeoutMgmtData | null;
  onChangeTimeoutForUsers: (isEnable: boolean) => void;
  isHeaderLoadingChanging: boolean;
}

const HeaderActions = ({
  selectedUsers,
  setSelectedUsers,
  timeoutMgmtData,
  onChangeTimeoutForUsers,
  isHeaderLoadingChanging,
}: Props) => {
  const counterRef = useRef<HTMLDivElement | null>(null);
  const isSticky: boolean = useObserver(counterRef, !!selectedUsers.length, { threshold: 1 });

  const actionType = getActionType(selectedUsers);

  const unselectUsers = () => setSelectedUsers([]);

  return (
    <div
      className={cn(styles.headerActions, { [styles.active]: selectedUsers.length, [styles.sticked]: isSticky })}
      ref={counterRef}>
      <div className={styles.headerActionsCount}>
        {selectedUsers.length}/{timeoutMgmtData?.pageMeta?.TotalCount || 0} Selected
      </div>
      <div className={styles.headerActionsWrap}>
        <Button variant="secondary" size="40" onClick={unselectUsers}>
          Unselect All
        </Button>
        <Button
          variant="primary"
          className={styles.headerActionsButton}
          isLoading={isHeaderLoadingChanging}
          size="40"
          onClick={() => onChangeTimeoutForUsers(true)}>
          {actionType}
        </Button>
      </div>
    </div>
  );
};

export default HeaderActions;
