import { corpAdmin } from 'consts';
import { IDomain } from 'interfaces';
import { IRoleUser } from 'store/slices/rolesMgmt';
import { truncateString } from 'utils';

export const getPageTitle = (userRole: string, isCompanyPage: boolean) =>
  userRole === corpAdmin
    ? 'Assign Corporate Tech Administrator'
    : isCompanyPage
    ? 'Assign Service Provider Tech Administrators'
    : 'Assign Customer Administrators';

export const getSuccessfulMessage = (isCompanyPage: boolean, domain: IDomain, selectedUsers: IRoleUser[]) => {
  let roleName: string = 'Customer Administrator';
  if (isCompanyPage) {
    if (domain.isServiceProvider) {
      roleName = 'Service Provider Tech Administrator';
    } else {
      roleName = 'Corporate Tech Administrator';
    }
  }

  if (selectedUsers.length === 1) {
    const user = selectedUsers[0];
    const userName = user && user.name;
    const truncatedString = String(truncateString(userName, false)) || 'N/A';
    return selectedUsers[0].isAssigned
      ? `${truncatedString} is removed from ${roleName}`
      : `${truncatedString} is promoted to ${roleName}`;
  }
  const statuses = selectedUsers.reduce(
    (prev, cur) => {
      cur.isAssigned ? prev.true++ : prev.false++;
      return prev;
    },
    { true: 0, false: 0 },
  );

  if (statuses.true && statuses.true === selectedUsers.length) {
    return `${selectedUsers.length} users are removed from ${roleName}`;
  }
  if (statuses.false && statuses.false === selectedUsers.length) {
    return `${selectedUsers.length} users are promoted to ${roleName}`;
  }
  return `${selectedUsers.length} users are updated`;
};
