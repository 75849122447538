import { IChangeCountriesData } from 'store/slices/securityMgmt/geoBlocking';
import { TypeEnableGeoBlocking } from '../GeoBlocking';
import { MutableRefObject } from 'react';

export const getChangeCountryAllowedListMessage = (data: IChangeCountriesData): string => {
  const { addedCountries, removedCountries } = data;
  const addedCount = addedCountries.length;
  const removedCount = removedCountries.length;

  if (addedCount && removedCount) {
    return 'Allowed List is updated';
  }
  if (removedCount > 1) {
    return `${removedCount} countries are removed from the Allowed List`;
  }
  if (addedCount > 1) {
    return `${addedCount} countries are added to the Allowed List`;
  }
  if (addedCount === 1) {
    return `${addedCountries[0]} is added to the Allowed List`;
  }
  if (removedCount === 1) {
    return `${removedCountries[0]} is deleted from the Allowed List`;
  }

  return 'No changes were made to the Allowed List';
};

export const manageCountriesModalTitle = (
  typeEnableGeoBlocking: TypeEnableGeoBlocking,
  isManageAllowedList: boolean,
) => {
  if (typeEnableGeoBlocking === 'priorList' || isManageAllowedList) {
    return 'Add countries to Allowed list';
  }
  return 'Enable Geo-Blocking for domain?';
};

export const isDisabledManageCountriesModalActon = (
  initCheckedCountries: MutableRefObject<string[]>,
  checkedCountries: string[],
): boolean => {
  return (
    (initCheckedCountries.current.length === checkedCountries.length &&
      initCheckedCountries.current.every((val, index) => val === checkedCountries[index])) ||
    checkedCountries.length < 1
  );
};
