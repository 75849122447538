import { useEffect, useState } from 'react';
import cn from 'classnames';
import { AlertValidateDomain, Loader } from 'components';
import { getDomainStatus } from 'helpers';
import WorkspaceTabs from './components/WorkspaceTabs/WorkspaceTabs';
import Onboarding from './components/Onboarding/Onboarding';
import OnboardingPersonal from './components/OnboardingPersonal/OnboardingPersonal';
import AlertDomainNotConnected from './components/AlertDomainNotConnected/AlertDomainNotConnected';
import { useAppDispatch, useAppSelector } from 'store';
import { fetchSecureConnects } from 'store/slices/workspace';
import styles from './Workspace.module.scss';

const Workspace = () => {
  const dispatch = useAppDispatch();

  const { user, domain } = useAppSelector((state) => state.user);
  const { hasSecureConnects } = useAppSelector((state) => state.workspace);

  const [isLoading, setIsLoading] = useState(true);
  const { isLoading: msIsLoading } = useAppSelector((state) => state.msal.connectEntraIDRequest);
  const { isDomainNotActivated } = getDomainStatus(user);
  const { finalTimeToValidateDomain, domainWasAutovalidated } = user;

  useEffect(() => {
    if (isDomainNotActivated) {
      const checkSecureConnects = async () => {
        await dispatch(fetchSecureConnects({})).unwrap();
        setIsLoading(false);
      };
      checkSecureConnects();
    } else {
      setIsLoading(false);
    }
  }, [isDomainNotActivated]);

  if (msIsLoading || isLoading) {
    return (
      <div className={styles.workspace}>
        <Loader id="loading-workspace" />
      </div>
    );
  }

  if (user.showOnboarding && !user.hasActiveCustomerDomains && !hasSecureConnects) {
    if (user.roles.some((role) => ['personaluser', 'freepersonaluser'].includes(role))) {
      return (
        <>
          {finalTimeToValidateDomain && domainWasAutovalidated && <AlertValidateDomain />}
          <div className={styles.workspace}>
            <OnboardingPersonal user={user} />
          </div>
        </>
      );
    }
    return (
      <>
        {finalTimeToValidateDomain && domainWasAutovalidated && <AlertValidateDomain />}
        <div className={styles.workspace}>
          <Onboarding />
        </div>
      </>
    );
  }

  return (
    <>
      {finalTimeToValidateDomain && domainWasAutovalidated && <AlertValidateDomain />}
      {user.showOnboarding && hasSecureConnects && <AlertDomainNotConnected domain={domain} />}
      <div className={cn(styles.workspace, { [styles.hasSecureConnects]: user.showOnboarding && hasSecureConnects })}>
        <WorkspaceTabs />
      </div>
    </>
  );
};

export default Workspace;
