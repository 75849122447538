import { useEffect, useState } from 'react';
import { Modal, Loader } from 'components';
import { notify, truncateString } from 'utils';
import ModalAssigmentsList from './components/ModalAssignmentsList/ModalAssignmentsList';
import EmptyAssigments from './components/EmptyAssigments/EmptyAssigments';
import {
  IActiveDirectoryAssignment,
  IActiveDirectoryUser,
  getUserAssigments,
  updateUsersAssignments,
} from 'store/slices/activeDirectory';
import { useAppDispatch, useAppSelector } from 'store';
import styles from './ManageAssignmentsModal.module.scss';

interface Props {
  isOpen: boolean;
  data: IActiveDirectoryUser;
  onClose: () => void;
}

const ManageAssignmentsModal = ({ isOpen, data, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { isLoading: isUpdating } = useAppSelector((state) => state.activeDirectory.updateUsersAssignmentsRequest);

  const [assignments, setAssignments] = useState<IActiveDirectoryAssignment[] | null>(null);
  const [assignmentsInit, setAssignmentsInit] = useState<IActiveDirectoryAssignment[] | null>(null);
  const isUpdateDisabled =
    isUpdating ||
    assignments?.map((i) => i.isAssigned).toString() === assignmentsInit?.map((i) => i.isAssigned).toString();

  const fetchData = async () => {
    try {
      const assignments = await dispatch(getUserAssigments(data.userId)).unwrap();
      setAssignments(assignments);
      setAssignmentsInit(assignments);
    } catch (err: any) {
      notify.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onUpdateAssignments = async () => {
    try {
      await dispatch(updateUsersAssignments({ userId: data.userId, assignments })).unwrap();
      notify.success('Assignments updated');
    } catch (err: any) {
      notify.error(err.message);
    } finally {
      onClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Manage Assignments"
      contentSubtitle={
        data.isMfaEnabled && (
          <>
            for <b>{data.name || 'N/A'}</b>
          </>
        )
      }
      actionFunc={onUpdateAssignments}
      actionDisabled={isUpdateDisabled}
      actionLoading={isUpdating}
      actionTitle="Confirm">
      <div className={styles.assigmentsModal}>
        {isLoading ? (
          <Loader id="loading-active-directory-manage-assigments" />
        ) : assignments?.length ? (
          <>
            {!data.isMfaEnabled && (
              <div className={styles.mfaEnabled}>
                Promoting users to Admin will automatically enable MFA. By clicking <b>CONFIRM</b>, you will enable MFA
                for <b>{truncateString(data.name, false) || 'N/A'}</b>
              </div>
            )}
            <ModalAssigmentsList
              assignments={assignments}
              setAssignments={setAssignments}
              assignmentsInit={assignmentsInit!}
              isMfaEnabled={data.isMfaEnabled}
            />
          </>
        ) : (
          <EmptyAssigments />
        )}
      </div>
    </Modal>
  );
};

export default ManageAssignmentsModal;
