import { ICorporateSubscription } from 'interfaces';

export const checkLicenses = (
  licenses: ICorporateSubscription,
  licensesState: ICorporateSubscription,
  minimumLicensesCount: number,
) => {
  const isRdpChanged = licenses.totalPurchasedRdpLicenses !== licensesState.totalPurchasedRdpLicenses;
  const isBitLockerChanged = licenses.totalPurchasedBitLockerLicenses !== licensesState.totalPurchasedBitLockerLicenses;

  const isCanceledFromRdp = licensesState.totalPurchasedRdpLicenses === 0;
  const isCanceledFromBitlocker = licensesState.totalPurchasedBitLockerLicenses === 0;

  const isLessRdp =
    isRdpChanged &&
    ((licensesState.totalPurchasedRdpLicenses < minimumLicensesCount && !isCanceledFromRdp) ||
      (licensesState.totalPurchasedBitLockerLicenses === 0 && !isCanceledFromBitlocker) ||
      (licensesState.totalPurchasedRdpLicenses === 0 && licensesState.totalPurchasedBitLockerLicenses === 0));

  const isLessBitlocker =
    isBitLockerChanged &&
    ((licensesState.totalPurchasedBitLockerLicenses < minimumLicensesCount && !isCanceledFromBitlocker) ||
      (licensesState.totalPurchasedRdpLicenses === 0 && !isCanceledFromRdp) ||
      (licensesState.totalPurchasedBitLockerLicenses === 0 && licensesState.totalPurchasedRdpLicenses === 0));

  const isMoreRdp = isRdpChanged && licensesState.totalPurchasedRdpLicenses > 1000000;
  const isMoreBitlocker = isBitLockerChanged && licensesState.totalPurchasedBitLockerLicenses > 1000000;

  return { isLessRdp, isLessBitlocker, isMoreRdp, isMoreBitlocker };
};
