import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { techAdmin } from 'consts';
import { createExtraReducersForResponses, createHttpRequestInitResult, http } from 'helpers/http';
import {
  IHttpRequestResult,
  ISettingDomains,
  ISettings,
  ISettingsCustomerDomain,
  ISettingsResponse,
  IUser,
} from 'interfaces';
import { checkIsShowCustDomains } from 'pages/Profile/pages/Settings/helpers/settingsHelper';
import { RootState } from 'store/store';
import { getUserRole } from 'utils';

interface ISaveCustomerDomainSettingsPayload {
  configuration: ISettings;
  domainIds: string[];
}

interface IGetDomainSettingsPayload {
  user: IUser;
  _role: string;
}

export const getDomainSettings = createAsyncThunk(
  'settings/getDomainSettings',
  async ({ user, _role }: IGetDomainSettingsPayload) => {
    const response: AxiosResponse<ISettingsResponse> = await http.get(
      `/api/Connectors/GetRdpSessionConfiguration?userId=${user.id}`,
    );
    return response.data;
  },
);

export const getCustomerDomainSettings = createAsyncThunk(
  'settings/getCustomerDomainSettings',
  async (domainId: string) => {
    const response: AxiosResponse<ISettings> = await http.get(
      `/api/Connectors/GetRdpConfigurationForDomain?domainId=${domainId}`,
    );
    return response.data;
  },
);

export const getCustomerDomains = createAsyncThunk('settings/getCustomerDomains', async (_role: string) => {
  const response: AxiosResponse<ISettingsCustomerDomain[]> = await http.get(
    '/api/Domains/GetCustomerDomains?onlyShortData=true&onlyActiveDomains=true',
  );
  return response.data;
});

export const saveDomainSettings = createAsyncThunk('settings/saveDomainSettings', async (settings: ISettings) => {
  const response: AxiosResponse<ISettingsResponse> = await http.post('/api/Connectors/UpdateUserRdpConfigs', settings);
  return response.data;
});

export const saveCustomerDomainSettings = createAsyncThunk(
  'settings/saveCustomerDomainSettings',
  async ({ configuration, domainIds }: ISaveCustomerDomainSettingsPayload) => {
    const response: AxiosResponse<ISettingsCustomerDomain> = await http.post(
      '/api/Connectors/UpdateRdpConfigsForDomains',
      {
        configuration,
        domainIds,
      },
    );
    return response.data;
  },
);

export const resetDomainSettings = createAsyncThunk(
  'settings/resetDomainSettings',
  async ({ applyToMyself, applyTo }: { applyToMyself: boolean; applyTo: string[] }, thunkApi) => {
    const response: AxiosResponse<ISettingsResponse> = await http.post(
      '/api/Connectors/ResetRdpConfiguration',
      applyTo,
    );
    if (applyToMyself) {
      const { user } = (thunkApi.getState() as RootState).user;
      const role = getUserRole(user);
      await thunkApi.dispatch(getDomainSettings({ user, _role: role }));
    }
    return response.data;
  },
);

interface ISettingsState {
  settings: {
    init: ISettings | null;
    modified: ISettings | null;
  };
  settingDomains: ISettingDomains;
  currentDomain: string;
  getDomainSettingsRequest: IHttpRequestResult<ISettingsResponse>;
  getCustomerDomainsRequest: IHttpRequestResult<ISettingsCustomerDomain[]>;
  saveDomainSettingsRequest: IHttpRequestResult<ISettingsResponse>;
  saveCustomerDomainSettingsRequest: IHttpRequestResult<ISettingsCustomerDomain>;
  resetDomainSettingsRequest: IHttpRequestResult<ISettingsResponse>;
  getCustomerDomainSettingsRequest: IHttpRequestResult<ISettingsCustomerDomain>;
}

const initialState: ISettingsState = {
  settings: {
    init: null,
    modified: null,
  },
  settingDomains: {},
  currentDomain: 'My Settings',
  getDomainSettingsRequest: createHttpRequestInitResult(),
  getCustomerDomainsRequest: createHttpRequestInitResult(),
  saveDomainSettingsRequest: createHttpRequestInitResult(),
  saveCustomerDomainSettingsRequest: createHttpRequestInitResult(),
  resetDomainSettingsRequest: createHttpRequestInitResult(),
  getCustomerDomainSettingsRequest: createHttpRequestInitResult(),
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    changeCurrentDomain: (state, action: PayloadAction<string>) => {
      state.currentDomain = action.payload;
    },
    changeSettings: (
      state,
      action: PayloadAction<{
        init: ISettings | null;
        modified: ISettings | null;
      }>,
    ) => {
      state.settings = action.payload;
      if (state.settings.modified) {
        state.settings.modified = { ...state.settings.modified };
      }
    },
    discardChanges: (state) => {
      state.settings = {
        init: state.settings.init,
        modified: state.settings.init,
      };
    },
  },
  extraReducers: (builder) => {
    createExtraReducersForResponses<ISettingsState>(
      builder,
      getDomainSettings,
      'getDomainSettingsRequest',
      (state, actions: PayloadAction<ISettingsResponse, any, { arg: IGetDomainSettingsPayload }>) => {
        const { user, _role } = actions.meta.arg;
        state.settings = {
          init: actions.payload.rdpConfiguration,
          modified: actions.payload.rdpConfiguration,
        };
        const domain =
          _role === techAdmin ? {} : { [user.dnsDomainName]: { id: user.domainId, dnsName: user.dnsDomainName } };
        state.settingDomains = {
          ...domain,
          ...state.settingDomains,
        };
      },
    );
    createExtraReducersForResponses<ISettingsState>(
      builder,
      getCustomerDomains,
      'getCustomerDomainsRequest',
      (state, actions: PayloadAction<ISettingsCustomerDomain[], any, { arg: string }>) => {
        const role = actions.meta.arg;
        const isShowCustDomains = checkIsShowCustDomains(role);
        if (isShowCustDomains) {
          const data: { [key: string]: ISettingsCustomerDomain } = state.settingDomains;
          actions.payload.forEach((d) => {
            data[d.dnsName] = d;
          });
          state.settingDomains = { ...data };
        }
      },
    );
    createExtraReducersForResponses<ISettingsState>(
      builder,
      saveDomainSettings,
      'saveDomainSettingsRequest',
      (state) => {
        state.settings = {
          init: state.settings.modified,
          modified: state.settings.modified,
        };
      },
    );
    createExtraReducersForResponses<ISettingsState>(
      builder,
      saveCustomerDomainSettings,
      'saveCustomerDomainSettingsRequest',
      (state) => {
        state.settings = {
          init: state.settings.modified,
          modified: state.settings.modified,
        };
      },
    );
    createExtraReducersForResponses<ISettingsState>(builder, resetDomainSettings, 'resetDomainSettingsRequest');
    createExtraReducersForResponses<ISettingsState>(
      builder,
      getCustomerDomainSettings,
      'getCustomerDomainSettingsRequest',
      (state, actions) => {
        state.settings = {
          init: actions.payload,
          modified: actions.payload,
        };
      },
    );
  },
});

export const { changeCurrentDomain, changeSettings, discardChanges } = settingsSlice.actions;
export default settingsSlice.reducer;
