import { Modal } from 'components';
import { AttentionIcon } from 'assets/img';
import { truncateString } from 'utils';
import { IShortcutMgmtUser } from 'store/slices/securityMgmt/shortcutMgmt';
import { useAppSelector } from 'store';
import styles from './DisableMFACodeModal.module.scss';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  onToggleMfaRequiredForUser: (isBulk: boolean, action: string) => Promise<void>;
  user: IShortcutMgmtUser;
}

const DisableMFACodeModal = ({ isOpen, onRequestClose, onToggleMfaRequiredForUser, user }: Props) => {
  const { isLoading } = useAppSelector((state) => state.shortcutMgmt.toggleMfaRequiredForUserRequest);

  const handleDisableMfaRequiredForUser = async () => {
    await onToggleMfaRequiredForUser(!!Array.isArray(user), 'disable');
  };

  const message =
    Array.isArray(user) && user.length > 1 ? (
      'selected users'
    ) : Array.isArray(user) && user.length === 1 ? (
      <span className={styles.selectedUser}>{truncateString(user[0].displayName, false)}</span>
    ) : (
      <span className={styles.selectedUser}>{truncateString(user.displayName, false)}</span>
    );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={
        <div className={styles.title}>
          <AttentionIcon />
          Disable MFA Code?
        </div>
      }
      actionFunc={handleDisableMfaRequiredForUser}
      actionTitle="Disable"
      variantBtn="delete"
      actionLoading={isLoading}>
      Do you really want to disable MFA Code for Desktop Shortcut for {message}?
    </Modal>
  );
};

export default DisableMFACodeModal;
