import { StatusMessage, SwitchCheckbox, Table } from 'components';
import { getTimeoutMgmtData, ITimeoutMgmtData, ITimeoutMgmtUser } from 'store/slices/securityMgmt/timeoutMgmt';
import { useAppDispatch } from 'store';
import { IQueryConfig } from 'interfaces';
import styles from './TimeoutTable.module.scss';

interface Props {
  timeoutMgmtData: ITimeoutMgmtData;
  btnLoadingCurrent: string[];
  selectedUsers: ITimeoutMgmtUser[];
  setSelectedUsers: (users: ITimeoutMgmtUser[]) => void;
  onChangeTimeoutForUsers: (isHeaderBtn: boolean, user?: ITimeoutMgmtUser) => void;
  domainId: string;
}

const TimeoutTable = ({
  timeoutMgmtData,
  btnLoadingCurrent,
  selectedUsers,
  setSelectedUsers,
  onChangeTimeoutForUsers,
  domainId,
}: Props) => {
  const dispatch = useAppDispatch();

  const columns = [
    {
      checkbox: true,
      name: 'User',
      data: ({ displayName, upn }: { displayName: string; upn: string }) => (
        <div className={styles.tableWrap}>
          <div className={styles.tableName}>{displayName || 'N/A'}</div>
          <div className={styles.tableUpn}>{upn}</div>
        </div>
      ),
      sortable: 'displayName',
      search: 'Name',
      width: '40%',
    },
    {
      name: 'Session Timeout',
      data: (user: ITimeoutMgmtUser) => (
        <div className={styles.tableStatus}>
          <SwitchCheckbox
            id={user.userId}
            name={user.userId}
            onChange={() => onChangeTimeoutForUsers(false, user)}
            checked={user.isDesktopTimeoutEnabled}
            disabled={selectedUsers.length > 0 || !!btnLoadingCurrent.includes(user.userId)}
          />
          {user.isDesktopTimeoutEnabled ? (
            <span className={styles.tableStatusEnabled}>ON</span>
          ) : (
            <span className={styles.tableStatusDisabled}>OFF</span>
          )}
        </div>
      ),
      sortable: 'isDesktopTimeoutEnabled',
      width: '60%',
    },
  ];

  const dispatchAction = async (query: IQueryConfig) => {
    await dispatch(getTimeoutMgmtData({ domainId: domainId, query: query, _background: true })).unwrap();
  };

  return (timeoutMgmtData?.data?.length ?? 0) < 1 && !timeoutMgmtData?.queryConfig?.searchQuery ? (
    <div className={styles.statusMessage}>
      <StatusMessage header="No items available">Change view selection</StatusMessage>
    </div>
  ) : (
    <Table<ITimeoutMgmtUser>
      data={timeoutMgmtData}
      columns={columns}
      id="timeout-me-table"
      dataKey="userId"
      dispatchAction={dispatchAction}
      selectedItems={selectedUsers}
      setSelectedItems={setSelectedUsers}
      className={styles.table}
    />
  );
};

export default TimeoutTable;
