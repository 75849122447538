export const truncateString = (str: string, wrap: boolean = true, limit: number = 45) => {
  if (str && str?.length > limit) {
    if (wrap) {
      return (
        <div title={str} className="truncate-text">
          {str.substring(0, limit)}...
        </div>
      );
    }
    return `${str.substring(0, limit)}...`;
  }
  return str;
};
