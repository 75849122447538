import { Modal } from 'components';
import { ICorporateSubscription } from 'interfaces';
import { useAppDispatch, useAppSelector } from 'store';
import { reactivateSubscription } from 'store/slices/subscriptions';
import { notify } from 'utils';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
}

const ReactivateModal = ({ isOpen, onRequestClose }: Props) => {
  const dispatch = useAppDispatch();
  const { licenses } = useAppSelector((state) => state.subscriptions);

  const handleReactivateSubscription = async () => {
    try {
      await dispatch(reactivateSubscription()).unwrap();
      notify.success('Subscription reactivated');
      onRequestClose();
    } catch (err: any) {
      notify.error('Subscription reactivation failed');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Reactivate subscription"
      onRequestClose={onRequestClose}
      actionFunc={handleReactivateSubscription}
      actionTitle="Confirm">
      By clicking <b>CONFIRM</b> you agree to pay an outstanding total of{' '}
      <b>${(licenses as ICorporateSubscription).reactivationCost}</b>. For invoice details select{' '}
      <b>View Billing Portal</b>
    </Modal>
  );
};

export default ReactivateModal;
