import { SettingTabs } from 'consts';

interface CustomResolutions {
  desktopHeight: number;
  desktopWidth: number;
}

export const addCustomResolutions = (tabsContent: SettingTabs, data: CustomResolutions): SettingTabs => {
  if (data.desktopHeight && data.desktopWidth) {
    const dimensionsColl = tabsContent.general.dimension.collection;
    const customResPresentKey = Object.values(dimensionsColl).findIndex((i: [string, string]) =>
      i[1].includes('custom'),
    );
    const customResKey = customResPresentKey ? customResPresentKey + 1 : Object.keys(dimensionsColl).length;
    const customResVal = `${data.desktopWidth} x ${data.desktopHeight} (custom)`;
    const dimensionsCollNew: { [key: number]: [string, string] } = {
      ...(typeof dimensionsColl === 'object' ? dimensionsColl : {}),
      [customResKey]: [`${customResKey} `, customResVal],
      [customResKey + 1]: ['customResLink', 'Change custom resolution'],
    };

    return {
      ...tabsContent,
      general: {
        ...tabsContent.general,
        dimension: {
          ...tabsContent.general.dimension,
          collection: dimensionsCollNew,
        },
      },
    };
  }

  const dimensionsColl = tabsContent.general.dimension.collection as { [key: string]: string[] };
  const customResPresentKey = Object.keys(dimensionsColl).find(
    (key) => dimensionsColl[key]?.includes('(custom)') && key,
  );

  if (customResPresentKey) {
    const tabsContentNew: SettingTabs = JSON.parse(JSON.stringify(tabsContent));

    delete (tabsContentNew.general.dimension.collection as { [key: string]: string | string[] })[customResPresentKey];
    return tabsContentNew;
  }

  return tabsContent;
};
