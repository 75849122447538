import { useDevicesEnrollment } from 'hooks';
import { FileIcon } from 'assets/img';
import { DropdownArray } from 'components';
import styles from './NoDevices.module.scss';

interface Props {
  domainId: string;
}

const NoDevices = ({ domainId }: Props) => {
  const { isEnrollmentLoading, getEnrollmentFile } = useDevicesEnrollment(domainId);

  return (
    <div className={styles.noDevices}>
      <p className={styles.noDevicesTitle}>No devices reporting in</p>
      <p className={styles.noDevicesSubtitle}>Please install agent using Enrollment File below</p>
      <DropdownArray
        id="dm-devices-enroll"
        isButton
        isDisabled={isEnrollmentLoading}
        icon={<FileIcon />}
        btnTitle={isEnrollmentLoading ? 'Loading...' : 'Enrollment File'}
        options={[
          { id: 'download', icon: 'download', value: 'Download' },
          { id: 'copy', icon: 'copy', value: 'Copy Link' },
        ]}
        onChange={(value) => getEnrollmentFile(value as string)}
        className={styles.devicesHeaderEnrollment}
        optionsClassName={styles.devicesHeaderEnrollmentDropdown}
        width="13em"
      />
    </div>
  );
};

export default NoDevices;
