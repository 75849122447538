import { checkIsEmailValid, checkIsPasswordValid } from 'helpers';

export const formValidation = (formValues: string[], formErrors: string[], callback: (formErrorsNew: string[]) => void) => {
  const formErrorsNew = [...formErrors];

  const isEmailValid = checkIsEmailValid(formValues[0]);
  const isPassValid = checkIsPasswordValid(formValues[1]);
  const isConfirmPassValid = formValues[2] && formValues[1] === formValues[2];

  if (!isEmailValid) formErrorsNew[0] = 'Invalid email address';
  if (!isPassValid) {
    formErrorsNew[1] = ' ';
    formErrorsNew[2] = 'Password does not match requirements';
  }
  if (isPassValid && !isConfirmPassValid) {
    formErrorsNew[1] = ' ';
    formErrorsNew[2] = 'Passwords do not match';
  }
  if (isPassValid && isConfirmPassValid) {
    formErrorsNew[1] = '';
    formErrorsNew[2] = '';
  }
  if (!formValues[3]) formErrorsNew[3] = 'The First Name field is required';
  if (!formValues[4]) formErrorsNew[4] = 'The Last Name field is required';

  if (formErrorsNew.length) {
    callback(formErrorsNew);
  }

  return !formErrorsNew.some((i) => i);
};
