import { Slide } from 'react-toastify';

interface ToastSettings {
  position: 'top-center' | 'top-right' | 'top-left' | 'bottom-center' | 'bottom-right' | 'bottom-left';
  theme: 'light' | 'dark' | 'colored';
  autoClose: number | false;
  hideProgressBar: boolean;
  closeOnClick: boolean;
  pauseOnHover: boolean;
  draggable: boolean;
  icon: boolean;
  delay: boolean;
  transition: typeof Slide;
}

export const toastSettings: ToastSettings = {
  position: 'top-center',
  theme: 'colored',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  icon: false,
  delay: false,
  transition: Slide,
};
