import { Link, StatusMessage } from 'components';

const NoUsers = () => (
  <StatusMessage header="No users reporting in">
    <p>
      Please check Active Directory or{' '}
      <Link href="https://www.trugrid.com/docs/usersnotreportingin" external bold>
        add users
      </Link>
    </p>
  </StatusMessage>
);

export default NoUsers;
