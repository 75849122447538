import { useEffect, useState } from 'react';
import cn from 'classnames';
import { Loader } from 'components';
import { getUserRole } from 'utils';
import { corpAdmin, spAdmin, spAdminNotActivated } from 'consts';
import AccountInfo from './components/AccountInfo/AccountInfo';
import { useAppDispatch, useAppSelector } from 'store';
import styles from './MyProfile.module.scss';
import { getMyAccountData } from 'store/slices/myProfile';

const MyProfile = () => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAppSelector((state) => state.user);
  const role = getUserRole(user);
  const isUpdateEmailHidden = ![spAdmin, spAdminNotActivated, corpAdmin].includes(role);

  const fetchData = async () => {
    await dispatch(getMyAccountData()).unwrap();
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading)
    return (
      <div className={styles.myProfile}>
        <Loader id="loading-my-profile" />
      </div>
    );

  return (
    <div className={styles.myProfile}>
      <div>
        <h3 className={cn(styles.myProfileTitle, { [styles.personal]: isUpdateEmailHidden })}>My Profile</h3>
        <AccountInfo isUpdateEmailHidden={isUpdateEmailHidden} />
      </div>
    </div>
  );
};

export default MyProfile;
