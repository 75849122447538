import { Button, DropdownArray } from 'components';
import cn from 'classnames';
import { useAppSelector } from 'store';
import { getViewByOptions } from '../../utils/mfaMgmtUtils';
import { IMfaUser } from 'store/slices/securityMgmt/mfaMgmt';
import { ButtonVariant } from 'components/Button/Button';
import { TypeAction } from '../../MfaMgmt';
import styles from './ManageBtnMfa.module.scss';

interface Props {
  isDisabled?: boolean;
  selectedUsers?: IMfaUser[];
  user: IMfaUser;
  onModalOpen: (type: TypeAction, user: IMfaUser, isHeaderBtn: boolean) => void;
  isHeaderBtn: boolean;
}

const ManageBtnMfa = ({ isDisabled, selectedUsers, user, onModalOpen, isHeaderBtn }: Props) => {
  const { mfaData } = useAppSelector((store) => store.mfaMgmt);
  const { isSetupView, isDisabledView, isResetView } = getViewByOptions(mfaData);

  const renderButton = (type: string, variant?: ButtonVariant) => (
    <Button
      variant={variant}
      size={isHeaderBtn ? '40' : '32'}
      isDisabled={isDisabled}
      onClick={() => onModalOpen(type as TypeAction, user, isHeaderBtn)}>
      {type}
    </Button>
  );

  if (!isSetupView && !isDisabledView && !isResetView) {
    if ((isHeaderBtn && selectedUsers?.some((i) => i.isAdmin)) || user.isAdmin) {
      return renderButton('Reset MFA', 'primary');
    }
    return (
      <DropdownArray
        isButton
        isButton32={!isHeaderBtn}
        id="manage-mfa"
        btnTitle="Manage MFA"
        options={['Disable MFA', 'Reset MFA']}
        onChange={(value) => onModalOpen(value as TypeAction, user, isHeaderBtn)}
        className={cn(styles.dropdown, 'dropdown-component__select')}
        allowPositionTop
        optionsClassName={styles.dropdownOptions}
      />
    );
  }

  if (isSetupView || (isResetView && !user.isAdmin)) {
    return renderButton('Disable MFA');
  }

  if (isResetView && user.isAdmin) {
    return '';
  }

  return renderButton('Enable MFA', 'primary');
};

export default ManageBtnMfa;
