import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AsyncThunkConfig, GetThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { AxiosResponse } from 'axios';
import { http, createHttpRequestInitResult, createExtraReducersForResponses } from 'helpers/http';
import { IHttpRequestResult, IPageMeta, IQueryConfig } from 'interfaces';
import { RootState } from 'store/store';
import { queryFormatter } from 'utils';

export interface IAzureLoginUser {
  userId: string;
  upn: string;
  displayName: string;
  isAzureAdStaySignedInEnabled: boolean;
}

export interface IAzureLoginData {
  isStaySignedInEnabled: boolean;
  users: IAzureLoginUser[];
}

export interface IAzureAdLoginData {
  data?: IAzureLoginUser[];
  pageMeta?: IPageMeta;
  queryConfig?: IQueryConfig;
  isStaySignedInEnabled?: boolean;
  isNoData?: boolean;
}

export const getAzureAdLoginData = createAsyncThunk(
  'azureAdLogin/getAzureAdLoginData',
  async (
    { domainId, query, _background }: { domainId: string; query: IQueryConfig; _background?: boolean },
    thunkAPI,
  ) => {
    const state = thunkAPI.getState() as RootState;
    const queryConfig = state.azureAdLogin.azureLoginData?.queryConfig || {};
    const queryConfigNew = { ...queryConfig, ...query };
    const response: AxiosResponse<IAzureLoginData> = await http.get(
      queryFormatter(`/api/SecurityManagement/AzureAdLogin/Get?domainId=${domainId}`, queryConfigNew),
    );
    const pageMeta = response?.headers['x-pagination'] ? JSON.parse(response.headers['x-pagination']) : null;
    if (response.status === 204) {
      const data: IAzureAdLoginData = {
        isNoData: true,
      };
      return data;
    } else {
      const data: IAzureAdLoginData = {
        data: response.data.users,
        isStaySignedInEnabled: response.data.isStaySignedInEnabled,
        pageMeta,
        queryConfig: queryConfigNew,
      };
      return data;
    }
  },
);

export const enableAzureAdLogin = createAsyncThunk(
  'azureAdLogin/enableAzureAdLogin',
  async (
    { domainId, isEnableForAll }: { domainId: string; isEnableForAll: boolean },
    thunkAPI: GetThunkAPI<AsyncThunkConfig>,
  ) => {
    await http.post(
      `/api/SecurityManagement/AzureAdLogin/ChangeDomainStatus?domainId=${domainId}&enableForAllUsers=${isEnableForAll}`,
    );
    await thunkAPI.dispatch(
      getAzureAdLoginData({ domainId, query: { pageNumber: 1, pageSize: 10 }, _background: true }),
    );
  },
);

export const disableAzureAdLogin = createAsyncThunk(
  'azureAdLogin/disableAzureAdLogin',
  async ({ domainId }: { domainId: string }) => {
    await http.post(`/api/SecurityManagement/AzureAdLogin/ChangeDomainStatus?domainId=${domainId}`);
  },
);

export const changeAzureLoginUserStatus = createAsyncThunk(
  'azureAdLogin/changeAzureLoginUserStatus',
  async (selectedUserIds: string[]) => {
    await http.post('/api/SecurityManagement/AzureAdLogin/ChangeUserStatus', selectedUserIds);
  },
);

interface IAzureAdLoginState {
  azureLoginData: IAzureAdLoginData | null;
  getAzureAdLoginDataRequest: IHttpRequestResult<IAzureLoginData>;
  changeAzureLoginUserStatusRequest: IHttpRequestResult<any>;
  enableAzureAdLoginRequest: IHttpRequestResult<any>;
  disableAzureAdLoginRequest: IHttpRequestResult<any>;
}

const initialState: IAzureAdLoginState = {
  azureLoginData: null,
  getAzureAdLoginDataRequest: createHttpRequestInitResult(),
  changeAzureLoginUserStatusRequest: createHttpRequestInitResult(),
  enableAzureAdLoginRequest: createHttpRequestInitResult(),
  disableAzureAdLoginRequest: createHttpRequestInitResult(),
};

export const azureAdLoginSlice = createSlice({
  name: 'AzureAdLogin',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createExtraReducersForResponses<IAzureAdLoginState>(
      builder,
      getAzureAdLoginData,
      'getAzureAdLoginDataRequest',
      (state, { payload }) => {
        state.azureLoginData = payload;
      },
    );
    createExtraReducersForResponses<IAzureAdLoginState>(
      builder,
      changeAzureLoginUserStatus,
      'changeAzureLoginUserStatusRequest',
      (state, action) => {
        const selectedUserIds: string[] = action.meta.arg;
        if (state.azureLoginData && state.azureLoginData.data) {
          const users: IAzureLoginUser[] = state.azureLoginData.data;
          const updatedUsers: IAzureLoginUser[] = users.map((user: IAzureLoginUser) => {
            if (selectedUserIds.includes(user.userId)) {
              return {
                ...user,
                isAzureAdStaySignedInEnabled: !user.isAzureAdStaySignedInEnabled,
              };
            }
            return user;
          });
          state.azureLoginData.data = updatedUsers;
        }
      },
    );
    createExtraReducersForResponses<IAzureAdLoginState>(builder, enableAzureAdLogin, 'enableAzureAdLoginRequest');
    createExtraReducersForResponses<IAzureAdLoginState>(
      builder,
      disableAzureAdLogin,
      'disableAzureAdLoginRequest',
      (state) => {
        state.azureLoginData = null;
      },
    );
  },
});

//export const { } = azureAdLoginSlice.actions;

export default azureAdLoginSlice.reducer;
