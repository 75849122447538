import { memo } from 'react';
import { SuccesCircleIcon } from 'assets/img';
import InfoPopover from 'components/InfoPopover/InfoPopover';
import { Link } from 'react-router-dom';
import { getDomainADStatus } from 'helpers';
import { IDomain, IUser } from 'interfaces';
import styles from './ADStatus.module.scss';

interface Props {
  link: string;
  domain: IDomain | IUser;
}

const ADStatus = ({ link, domain }: Props) => {
  const { ADStatusMessage } = getDomainADStatus(domain);

  return (
    <div className={styles.adStatus}>
      <SuccesCircleIcon />
      <span className={styles.adMessage}>{ADStatusMessage}</span>
      <InfoPopover className={styles.adPopover} position="down">
        <p>
          Go to{' '}
          <Link id="go-to-domain-mgmt" to={link}>
            Domain Management
          </Link>{' '}
          page to manage
          <br /> Active Directory setup
        </p>
      </InfoPopover>
    </div>
  );
};

export default memo(ADStatus);
