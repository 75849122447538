import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { createExtraReducersForResponses, createHttpRequestInitResult, http } from 'helpers/http';
import { IHttpRequestResult } from 'interfaces';

export interface IMyAccountData {
  displayName: string;
  domainIsValidated: boolean;
  email: string;
  isBrandingAllowed: boolean;
  requestedEmail: string;
}

export const getMyAccountData = createAsyncThunk('myAccount/getMyAccountData', async () => {
  const response: AxiosResponse<IMyAccountData> = await http.get('/api/account/GetUserData');
  return response.data;
});

export const changeEmail = createAsyncThunk('myAccount/changeEmail', async (email: string) => {
  const response: AxiosResponse<string> = await http.post(`/api/UserProfile/UpdateEmail?newEmail=${email}`);
  return response.data;
});

interface IMyAccountState {
  myAccount: IMyAccountData | null;
  getMyAccountDataRequest: IHttpRequestResult<IMyAccountData>;
  changeEmailRequest: IHttpRequestResult<string>;
}

const initialState: IMyAccountState = {
  myAccount: null,
  getMyAccountDataRequest: createHttpRequestInitResult(),
  changeEmailRequest: createHttpRequestInitResult(),
};

export const myAccount = createSlice({
  name: 'myAccount',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createExtraReducersForResponses(builder, getMyAccountData, 'getMyAccountDataRequest', (state, action) => {
      state.myAccount = action.payload;
    });
    createExtraReducersForResponses(builder, changeEmail, 'changeEmailRequest', (state, action) => {
      if (state.myAccount) {
        state.myAccount.requestedEmail = action.payload;
      }
    });
  },
});

// export const {} = myAccount.actions;

export default myAccount.reducer;
