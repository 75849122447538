import { useState } from 'react';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';
import { QuestionIcon, QuestionFilledIcon, InfoIcon, InfoActiveIcon } from 'assets/img';
import styles from './TooltipInfo.module.scss';

interface Icons {
  question: {
    active: JSX.Element;
    default: JSX.Element;
  };
  info: {
    active: JSX.Element;
    default: JSX.Element;
  };
}

const ICONS: Icons = {
  question: {
    active: <QuestionFilledIcon />,
    default: <QuestionIcon />,
  },
  info: {
    active: <InfoActiveIcon width="1em" height="1em" />,
    default: <InfoIcon width="1em" height="1em" />,
  },
};

interface Props {
  name: string;
  content: string;
  position: ReactTooltip['props']['place'];
  icon: string;
  isHtml: boolean;
  className?: string;
}

const TooltipInfo = ({ name, content, position, icon, isHtml, className }: Props) => {
  const [isInfoActive, setIsInfoActive] = useState(false);

  return (
    <>
      <div
        className={cn(styles.wrapper, { [`${className}`]: className })}
        onMouseEnter={() => setIsInfoActive(true)}
        onMouseLeave={() => setIsInfoActive(false)}>
        <div data-for={`sit-${name}`} data-tip={content} data-iscapture="true" className={styles.wrapperTooltip} />
        {isInfoActive ? ICONS[icon as keyof Icons].active : ICONS[icon as keyof Icons].default}
      </div>
      <ReactTooltip id={`sit-${name}`} type="light" place={position} html={isHtml} effect="solid" delayShow={200} />
    </>
  );
};

export default TooltipInfo;
