import { useState } from 'react';
import cn from 'classnames';
import { Button, Checkbox, SearchInput } from 'components';
import { ReportTypes } from '../../Assignments';
import { ILicenseReport } from 'interfaces';
import styles from './ReportDomains.module.scss';

interface Props {
  licenses: ILicenseReport[];
  type: ReportTypes;
  primaryDomainData: ILicenseReport;
  checkedDomains: ILicenseReport[];
  setNewCheckedDomains: (data: ILicenseReport[]) => void;
  isHideDomainList: boolean;
}

const ReportDomains = ({
  licenses,
  type,
  primaryDomainData,
  checkedDomains,
  setNewCheckedDomains,
  isHideDomainList,
}: Props) => {
  const [selectedAll, setSelectedAll] = useState(false);
  const [inputText, setInputText] = useState('');

  const checkedDomainsIds = checkedDomains
    .filter((item) => (type === ReportTypes.appAssignments ? item.isAppPublishingEnabled : true))
    .map((el) => el.domainId);

  const inputHandler = (value: string) => {
    const lowerCase = value.toLowerCase();
    setInputText(lowerCase);
  };

  const filteredData = licenses.filter((el) => {
    if (inputText === '') return el;
    return el.domainName.toLowerCase().includes(inputText);
  });

  const selectAllToggle = () => {
    const filteredLicenses = licenses.filter((item) =>
      type === ReportTypes.appAssignments ? item.isAppPublishingEnabled : true,
    );
    const newList = filteredLicenses.length === checkedDomains.length ? [] : [...filteredLicenses];
    setSelectedAll(newList.length === filteredLicenses.length);
    setNewCheckedDomains(newList);
  };

  const checkDomain = (el: ILicenseReport) => {
    const filteredLicenses = licenses.filter((item) =>
      type === ReportTypes.appAssignments ? item.isAppPublishingEnabled : true,
    );
    const newList = [...checkedDomains];
    const index = newList.findIndex((item) => item.domainId === el.domainId);
    if (index === -1) {
      newList.push(el);
      if (filteredLicenses.length === newList.length) {
        setSelectedAll(true);
      }
    } else {
      newList.splice(index, 1);
      setSelectedAll(false);
    }
    setNewCheckedDomains(newList.sort());
  };

  const isOnlyOneDomain = licenses.length === 1;

  return (
    <div className={styles.domainList}>
      {isHideDomainList ? (
        <>
          <div className={styles.domainListTitle}>Domain</div>
          <div className={cn(styles.domainListPrimary, 'truncate-text')}>{primaryDomainData.domainName}</div>
        </>
      ) : (
        <>
          {!isOnlyOneDomain && (
            <SearchInput
              id="search-by-domain"
              isNotDebounce
              fullWidth
              placeholder="Find Domains"
              onChange={inputHandler}
              className={styles.domainListSearch}
              inputClassName={styles.domainListSearch}
            />
          )}
          {filteredData.length ? (
            <>
              <div className={styles.domainListRow}>
                <div className={styles.domainListTitle}>{isOnlyOneDomain ? 'Domain' : 'Domains'}</div>
                {!isOnlyOneDomain && (
                  <Button
                    type="button"
                    id="domain-toggle-all"
                    variant="link"
                    onClick={selectAllToggle}
                    className={styles.domainListButton}>
                    {selectedAll ? 'Unselect all' : 'Select all'}
                  </Button>
                )}
              </div>
              <div className={cn(styles.domainListWrap, { [styles.short]: isOnlyOneDomain })}>
                <ul className={styles.domainListList}>
                  {filteredData
                    .filter((item) => (type === ReportTypes.appAssignments ? item.isAppPublishingEnabled : true))
                    .filter((item) => primaryDomainData === item)
                    .map((domain) =>
                      isOnlyOneDomain ? (
                        <li key={domain.domainId} className={cn(styles.domainListListItem)}>
                          <span
                            style={{ display: 'block' }}
                            className={cn(styles.domainListListItemCheckbox)}>{`${domain.domainName} (Primary)`}</span>
                        </li>
                      ) : (
                        <li key={domain.domainId} className={cn(styles.domainListListItem)}>
                          <Checkbox
                            className={cn(styles.domainListListItemCheckbox)}
                            id={domain.domainId}
                            label={`${domain.domainName} (Primary)`}
                            labelPosition="left"
                            checked={checkedDomainsIds.includes(domain.domainId)}
                            onChange={() => checkDomain(domain)}
                          />
                        </li>
                      ),
                    )}
                  {filteredData
                    .filter((item) => (type === ReportTypes.appAssignments ? item.isAppPublishingEnabled : true))
                    .filter((item) => primaryDomainData !== item && licenses.includes(item))
                    .map((domain) =>
                      isOnlyOneDomain ? (
                        <li key={domain.domainId} className={cn(styles.domainListListItem)}>
                          <span style={{ display: 'block' }} className={cn(styles.domainListListItemCheckbox)}>
                            {domain.domainName}
                          </span>
                        </li>
                      ) : (
                        <li key={domain.domainId} className={cn(styles.domainListListItem)}>
                          <Checkbox
                            className={cn(styles.domainListListItemCheckbox)}
                            id={domain.domainId}
                            label={domain.domainName}
                            labelPosition="left"
                            checked={checkedDomainsIds.includes(domain.domainId)}
                            onChange={() => checkDomain(domain)}
                          />
                        </li>
                      ),
                    )}
                </ul>
              </div>
            </>
          ) : (
            <div className={styles.domainListNoResults}>
              <p>No results found</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ReportDomains;
